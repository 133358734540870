/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { useQuery } from '@tanstack/react-query';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { API as ACCOUNT_API } from 'utilities/api/account';
import { handleDefaultErrorNotification } from 'utilities/methods/commonActions';
import { createQueryBoilerPlate } from 'utilities/methods/tanstack';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
/**
 * @deprecated use v5 format, see katanaQuery
 */
const useGetPaymentMethodTokenBoilerPlate = createQueryBoilerPlate({
    service: 'billing',
    serviceKey: 'GET_PAYMENT_METHOD_TOKEN',
    APIRoute: (payment_method) => () => ACCOUNT_API.user.payment_method.token.GET(payment_method),
    defaultOptions: {
        staleTime: 60_000 * 10,
        onError: () => handleDefaultErrorNotification,
        enabled: (paymentMethodId) => Boolean(paymentMethodId)
    }
});

const { getDefaults } = useGetPaymentMethodTokenBoilerPlate;

/**********************************************************************************************************
 *   HOOK START
 **********************************************************************************************************/
/**
 * @deprecated use v5 format, see katanaQuery
 * User Payment Method Token
 */
function useGetPaymentMethodTokenQuery(payment_method, options = {}) {
    return useQuery({
        ...getDefaults(payment_method),
        ...options
    });
}
/**********************************************************************************************************
 *   HOOK END
 **********************************************************************************************************/

export { useGetPaymentMethodTokenBoilerPlate, useGetPaymentMethodTokenQuery };

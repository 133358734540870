/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React from 'react';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import { Flex } from 'components/Utils/Flex';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import './_NXBoxBottomColumns.scss';

/**********************************************************************************************************
 *   TYPE IMPORTS
 **********************************************************************************************************/
import type { FlexNamespace } from 'components/Utils/Flex/types';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
interface NXBoxBottomColumnsProps {
    columns?: React.ReactNode[];
    children?: React.ReactNode;
    gap?: FlexNamespace.Props['gap'];
}

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * Provides a column layout to its children. Either children or columns can be provided (for backwards compatibility). Since
 * the list is not iterated over, using children is recommended.
 */
export const NXBoxBottomColumns: React.FC<NXBoxBottomColumnsProps> = ({ columns, gap = 4, children }) => {
    /***** RENDER *****/
    return (
        <Flex justify="between" gap={gap} items="center" className="NXBoxBottomColumns">
            {children ?? columns}
        </Flex>
    );
};

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { type PathObject } from 'router/utils';
import { resolveRouteConfig } from 'router/utils/resolveRouteConfig';

/**********************************************************************************************************
 *   TYPE IMPORTS
 **********************************************************************************************************/
import { getNotificationList, pushNotification } from 'components/Toast/functions';
import type { AnyRouteOptions, GetOptionTypeFromRouteId, IsRouteActive, ResolvedRouteConfig } from 'router/types';
import { getFullPathname } from 'router/utils/getFullPathname';
import { getRouteConfig } from 'router/utils/getRouteConfig';
import { handleInactive } from 'router/utils/handleInactive';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type TIsRouteActive = <TThis extends AnyRouteOptions | PathObject>(
    options: TThis,
    opts: Pick<GetOptionTypeFromRouteId<IsRouteActive.GetPathFromRouteOptions<TThis>, 'beforeLoad'>, 'params'>,
    callback?: (config: ResolvedRouteConfig) => void
) => void;

/**********************************************************************************************************
 *   FUNCTION START
 **********************************************************************************************************/
/**
 * @description
 * Determines if the route is active based on the configuration in the `/src/router/config` file.
 *
 * When used in beforeLoad, the first parameter should be the `this` keyword which will allow the function to automatically
 * access the routeId based on the object. When calling .throws() on the returned object, the function will either
 *
 * 1. Do nothing if the route is active in the config
 * 2. Throw a notFound if the config is disabled and it is set to notFound
 * 3. Throw a redirect if the config is disabled and it is set to redirect.
 *
 * @see {@link https://nexigendigital.atlassian.net/wiki/spaces/DEV/pages/3544842242/CCP-2603+Routing+and+component+configuration+for+multiple+brands?search_id=e10a64f7-0322-4cda-822e-f161dfb3a2b9}
 *
 * If this function is used to access the brand state directly within a component, template or anywhere other than a route, then
 * a manual object with a "path" key can be passed. When doing this, it is recommended to use the `select` function on the returned
 * object which will return you the resolved config object as well as the pathname of the matched route.
 *
 * Further detail on the exact behaviour is documented in the confluence page.
 *
 * @example
 * ```tsx
 * // inside a route object
 * createRoute({
 *   ...
 *   beforeLoad(opts) {
 *     routeMiddleware.business(this, opts)
 *   }
 * })
 * ```
 *
 * @Notes
 * **1.** If a path is passed that does not exist in the configObject, this is automatically assumed to be disabled and therefore the
 * resolved route object will be treated as a disabled route and default to a "not found" object.
 *
 * Since all routes should be defined in the config object, this should not happen.
 *
 * **2.** Inside beforeLoad/loaders The "location" option from the route options cannot be passed as the path, because this represents the full path rather
 * than the path at which the route is being checked (ie. checked at `/` but the route is `/test` which is a child). If the location is
 * passed when the parent is meant to be accessible then the user may be redirected instead of being shown a 404 for example.
 */
export const businessMiddleware: TIsRouteActive = (options, { params }, callback) => {
    const retrievedConfig = getRouteConfig(options);
    const resolvedConfig = resolveRouteConfig(retrievedConfig, params as any);

    /***** RESULTS *****/
    try {
        handleInactive(resolvedConfig);
    } catch (e) {
        callback?.(resolvedConfig);
        defaultErrorCallback(resolvedConfig);
        handleDevelopmentDisabledRouteLog(options, params);

        throw e;
    }
};

/**********************************************************************************************************
 *   FUNCTION START
 **********************************************************************************************************/
export function handleDevelopmentDisabledRouteLog(opts: AnyRouteOptions | PathObject, params: any) {
    const options: any = opts;
    if (import.meta.env.DEV) {
        console.warn(
            `Attempted to access ${getFullPathname(options)} that is not enabled for this business. Consider checking src/router/config.ts to ensure the route is enabled.\n\n`,
            '------------- DEBUG INFORMATION --------------',
            '\noptions:        ',
            options,
            '\ngetFullPath:       ',
            getFullPathname(options),
            '\ngetRouteConfig: ',
            getRouteConfig(options),
            '\nresolveRouteConfig: ',
            resolveRouteConfig(getRouteConfig(options), params as never),
            '\ngetParentRoute: ',
            options.getParentRoute?.()
        );
    }
}

function defaultErrorCallback(config: ResolvedRouteConfig) {
    if (!getNotificationList().length) {
        if (import.meta.env.DEV) {
            pushNotification({
                status: 500,
                details: `[Development Only] This route is not active and the resolved config returned a ${config.type} status. Check your console for more information`
            });
        }
    }
}

/**********************************************************************************************************
 *   BASE IMPORTS
 **********************************************************************************************************/
import classNames from 'classnames';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import './_StyledLoader.scss';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type StyledLoaderProps = {
    rightAligned: boolean;
};

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const StyledLoader: React.FC<StyledLoaderProps> = ({ rightAligned }) => {
    /***** RENDER *****/
    return (
        <div className="styledLoader">
            <div className="styledLoader__top">
                <div className={classNames('styledLoader__title', 'animate', { 'styledLoader__title--rightAligned': rightAligned })}>
                    <div className="el"></div>
                </div>
                <div className="styledLoader__desc animate">
                    <div className="el"></div>
                </div>
            </div>
            <div className="styledLoader__bottom">
                <div className="styledLoader__action">
                    <div className="el"></div>
                </div>
            </div>
        </div>
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

import {
    DASHBOARD_ALERTS_FAIL,
    DASHBOARD_ALERTS_REQUEST,
    DASHBOARD_ALERTS_SUCCESS,
    DASHBOARD_INITIAL_STATE,
    DASHBOARD_REFERRAL_BANNER_FAIL,
    DASHBOARD_REFERRAL_BANNER_REQUEST,
    DASHBOARD_REFERRAL_BANNER_SUCCESS,
    DASHBOARD_TICKET_FAIL,
    DASHBOARD_TICKET_REQUEST,
    DASHBOARD_TICKET_SUCCESS,
    REFERRAL_CODE_CREATE_FAIL,
    REFERRAL_CODE_CREATE_REQUEST,
    REFERRAL_CODE_CREATE_SUCCESS,
    REFERRAL_CODE_SEND_FAIL,
    REFERRAL_CODE_SEND_REQUEST,
    REFERRAL_CODE_SEND_SUCCESS
} from './action';

const initialState = {
    dashboard_notification_status: null,
    dashboard_notification_data: null,
    dashboard_notification_error: null,
    dashboard_tickets_status: null,
    dashboard_tickets_data: null,
    dashboard_tickets_error: null,
    dashboard_alerts_status: null,
    dashboard_alerts_data: null,
    dashboard_alerts_error: null,
    dashboard_services_data: null,
    dashboard_services_error: null,
    dashboard_services_set_error: null,
    dashboard_referral_banner_status: null,
    dashboard_referral_banner_data: null,
    dashboard_referral_banner_error: null,
    referral_code_create_status: null,
    referral_code_create_data: null,
    referral_code_create_error: null,
    referral_code_send_status: null,
    referral_code_send_data: null,
    referral_code_send_error: null
};

const dashboardReducer = (state = initialState, action) => {
    switch (action.type) {
        /**********************************************************************************************************
         *   RESET
         **********************************************************************************************************/
        case DASHBOARD_INITIAL_STATE:
            return initialState;

        /**********************************************************************************************************
         *   ETICKET
         **********************************************************************************************************/
        case DASHBOARD_TICKET_REQUEST:
            return {
                ...state,
                dashboard_tickets_status: 'loading',
                dashboard_tickets_data: null,
                dashboard_tickets_error: null
            };

        case DASHBOARD_TICKET_SUCCESS:
            return {
                ...state,
                dashboard_tickets_status: 'success',
                dashboard_tickets_data: action.dashboard_tickets_data
            };

        case DASHBOARD_TICKET_FAIL:
            return {
                ...state,
                dashboard_tickets_status: 'error',
                dashboard_tickets_error: action.dashboard_tickets_error
            };

        /**********************************************************************************************************
         *   ALERTS / NOTIFICATIONS
         **********************************************************************************************************/
        case DASHBOARD_ALERTS_REQUEST:
            return {
                ...state,
                dashboard_alerts_status: 'loading',
                dashboard_alerts_data: null,
                dashboard_alerts_error: null
            };

        case DASHBOARD_ALERTS_SUCCESS:
            return {
                ...state,
                dashboard_alerts_status: 'success',
                dashboard_alerts_data: action.dashboard_alerts_data
            };

        case DASHBOARD_ALERTS_FAIL:
            return {
                ...state,
                dashboard_alerts_status: 'error',
                dashboard_alerts_error: action.dashboard_alerts_error
            };

        /**********************************************************************************************************
         *   REFERRAL
         **********************************************************************************************************/
        case DASHBOARD_REFERRAL_BANNER_REQUEST:
            return {
                ...state,
                dashboard_referral_banner_status: 'loading',
                dashboard_referral_banner_data: null,
                dashboard_referral_banner_error: null
            };

        case DASHBOARD_REFERRAL_BANNER_SUCCESS:
            return {
                ...state,
                dashboard_referral_banner_status: 'success',
                dashboard_referral_banner_data: action.dashboard_referral_banner_data
            };

        case DASHBOARD_REFERRAL_BANNER_FAIL:
            return {
                ...state,
                dashboard_referral_banner_status: 'error',
                dashboard_referral_banner_error: action.dashboard_referral_banner_error
            };

        case REFERRAL_CODE_CREATE_REQUEST:
            return {
                ...state,
                referral_code_create_status: 'loading',
                referral_code_create_data: null,
                referral_code_create_error: null
            };

        case REFERRAL_CODE_CREATE_SUCCESS:
            return {
                ...state,
                referral_code_create_status: 'success',
                referral_code_create_data: action.referral_code_create_data
            };

        case REFERRAL_CODE_CREATE_FAIL:
            return {
                ...state,
                referral_code_create_status: 'error',
                referral_code_create_error: action.referral_code_create_error
            };

        case REFERRAL_CODE_SEND_REQUEST:
            return {
                ...state,
                referral_code_send_status: 'loading',
                referral_code_send_data: action.referral_code_send_data,
                referral_code_send_error: action.referral_code_send_error
            };

        case REFERRAL_CODE_SEND_SUCCESS:
            return {
                ...state,
                referral_code_send_status: 'success',
                referral_code_send_data: action.referral_code_send_data
            };

        case REFERRAL_CODE_SEND_FAIL:
            return {
                ...state,
                referral_code_send_status: 'error',
                referral_code_send_error: action.referral_code_send_error
            };

        default:
            return state;
    }
};

export default dashboardReducer;

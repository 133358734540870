import { get, has } from 'lodash';

/**
 * @description This function is used to recursively walk an object to find a key by path.
 * it will return the object that has the key if it is found, otherwise it will return undefined.
 * This function only works in a direct path and does not continue to find the key in every possible route
 */
export function recursivelyWalkObjectToFindKeyByPath(object: Record<string, unknown>, keyToFind: string, startingPath: string, depth: number = 0) {
    const newDepth = depth + 1;
    const newPath = startingPath.split('.').slice(0, newDepth);

    if (newPath.length === 0) {
        return undefined;
    }

    const foundValue = get(object, newPath);

    if (foundValue && has(foundValue, keyToFind)) {
        return foundValue;
    }

    if (newDepth > newPath.length) {
        return undefined;
    }

    return recursivelyWalkObjectToFindKeyByPath(object, keyToFind, startingPath, newDepth);
}

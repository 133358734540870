/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { useMatches } from '@tanstack/react-router';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import { KatanaPageRouteMap } from 'containers/katana/consts';

/**
 * As much as I would love to convert everything in katana away from using 4 path params and instead working with hard coded routes, I think the best middle ground
 * is to create each of the hard coded routes and then use helper hooks to get the equivalent of what we had before. This should cut down on development time
 * while still allowing us to get the most out of the new router and then consider how we are determining what to show later on.
 */
export const useKatanaPage = () => {
    return useMatches({
        select: (matches) => {
            const pageRouteId = matches.find((match) => Object.keys(KatanaPageRouteMap).includes(match.routeId))?.routeId;
            return !!pageRouteId && KatanaPageRouteMap[pageRouteId];
        }
    });
};

/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'utilities/methods/tanstack/router/withRouter';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import Anchor from 'components/Anchor';
import InactiveButton from 'components/Buttons/InactiveButton';
import SolidButton from 'components/Buttons/SolidButton';
import RequestLoader from 'components/Loaders/Request';
import { DialogNotification } from 'components/Notifications/DialogNotification';
import PricingTable from 'components/PricingTable';
import Text from 'components/Utils/Text';

/*   ACTIONS
 *****************************************************/
import { pushNotification } from 'components/Toast/functions';
import { getDomainRenew, renewDomain } from 'containers/domain/action';
import { getDomainsList } from 'containers/services/action';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import { APPLICATION_NEEDS_RENEWING_TEXT } from 'containers/auDirect/consts';
import './_auRenewApplicationForm.scss';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type AuRenewApplicationFormProps = {
    auDirectDomainId?: number;
    toggleApplicationLightbox: () => void;
    history: any;
};

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
let AuRenewApplicationForm: React.FC<AuRenewApplicationFormProps> = ({
    auDirectDomainId,
    toggleApplicationLightbox,
    history,

    /* Redux props */
    domain_renew_status,
    domain_renew_options_status,
    domain_renew_options_data,
    getDomainsList
}) => {
    /***** EFFECTS *****/
    useEffect(() => {
        getDomainRenew(auDirectDomainId);
    }, []);

    /***** RENDER HELPERS *****/
    const renderContent = () => {
        if (domain_renew_options_status === 'loading') return <RequestLoader />;

        if (domain_renew_options_data?.attributes?.invoice_status === 'unpaid') {
            return (
                <DialogNotification type="warning">
                    Looks like you have already initiated a renewal of this application. Please{' '}
                    <Anchor to={`/billing/invoice/${domain_renew_options_data.attributes.invoice_id}/pay`}>pay the invoice</Anchor> to proceed with
                    the renewal.
                </DialogNotification>
            );
        }

        if (domain_renew_options_data?.attributes?.is_pending_renewal) {
            return (
                <DialogNotification type="success">
                    Looks like you have already renewed this application. Please check back shortly as renewals can take a few minutes to process.
                </DialogNotification>
            );
        }

        const renderButton = () => {
            if (domain_renew_status === 'loading') return <InactiveButton loading>Renew Application</InactiveButton>;

            return (
                <SolidButton
                    type="onClick"
                    onClick={() =>
                        renewDomain(
                            auDirectDomainId,
                            { years: 1 },
                            {
                                onSuccess: (renewData) => {
                                    pushNotification({ status: 200, details: 'Application renewal initiated.' });
                                    getDomainsList();
                                    getDomainRenew(auDirectDomainId);
                                    toggleApplicationLightbox();

                                    if (renewData?.type === 'invoice') history.push(`/billing/invoice/${renewData.id}/pay`);
                                },
                                onError: toggleApplicationLightbox
                            }
                        )
                    }
                >
                    Renew Application
                </SolidButton>
            );
        };

        return (
            <>
                <Text secondary size--s align--center>
                    {APPLICATION_NEEDS_RENEWING_TEXT.LONG}
                </Text>
                <PricingTable
                    total={{
                        label: 'Application Renewal Cost',
                        amount: domain_renew_options_data?.attributes?.prices[0]?.price
                            ? `$${domain_renew_options_data.attributes.prices[0].price} AUD`
                            : ''
                    }}
                />
                {renderButton()}
            </>
        );
    };

    /***** RENDER *****/
    return <div className="auRenewApplicationForm">{renderContent()}</div>;
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

const mapStateToProps = (state) => ({
    domain_renew_status: state.domain.domain_renew_status,
    domain_renew_data: state.domain.domain_renew_data,
    domain_renew_options_status: state.domain.domain_renew_options_status,
    domain_renew_options_data: state.domain.domain_renew_options_data
});

const mapDispatchToProps = {
    renewDomain,
    getDomainsList
};

AuRenewApplicationForm = connect(mapStateToProps, mapDispatchToProps)(AuRenewApplicationForm);

AuRenewApplicationForm = withRouter(AuRenewApplicationForm);

export default AuRenewApplicationForm;

/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import EventEmitter from 'events';

/**
 * Handle Global App Events
 * Documentation: https://nodejs.org/dist/v11.13.0/docs/api/events.html
 * Use ./states.js to define additional states
 */
export const appEvents = new EventEmitter();

/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { createRoute } from '@tanstack/react-router';

/**********************************************************************************************************
 *   ROUTE IMPORTS
 **********************************************************************************************************/
import { VIPSitesSetupRoute } from 'containers/katana/routes/vip-sites.$katanaServiceId/_content-editor-lightbox/setup';
import { routerMiddleware } from 'router/utils/middleware';
import { lazyFN } from 'utilities/methods/tanstack/router/lazyFn';

/**********************************************************************************************************
 *   ROUTE START
 **********************************************************************************************************/
export const VIPSitesSetupContactInformationRoute = createRoute({
    getParentRoute: () => VIPSitesSetupRoute,
    path: 'contact-information',
    beforeLoad(opts) {
        routerMiddleware.business(this, opts);
        routerMiddleware.authentication('user', opts);
    }
}).lazy(lazyFN(() => import('./contact-information.lazy'), 'LazyVIPSitesSetupContactInformationRoute'));

import {
    CUSTOM_HOSTING_ADDON_KEEP_FAIL,
    CUSTOM_HOSTING_ADDON_KEEP_REQUEST,
    CUSTOM_HOSTING_ADDON_KEEP_SUCCESS,
    CUSTOM_HOSTING_ADDON_LIST_FAIL,
    CUSTOM_HOSTING_ADDON_LIST_REQUEST,
    CUSTOM_HOSTING_ADDON_LIST_SUCCESS,
    CUSTOM_HOSTING_ADDON_PURCHASE_FAIL,
    CUSTOM_HOSTING_ADDON_PURCHASE_REQUEST,
    CUSTOM_HOSTING_ADDON_PURCHASE_SUCCESS,
    CUSTOM_HOSTING_ADDON_REMOVE_FAIL,
    CUSTOM_HOSTING_ADDON_REMOVE_REQUEST,
    CUSTOM_HOSTING_ADDON_REMOVE_SUCCESS,
    CUSTOM_HOSTING_CALCULATE_FAIL,
    CUSTOM_HOSTING_CALCULATE_REQUEST,
    CUSTOM_HOSTING_CALCULATE_RESET,
    CUSTOM_HOSTING_CALCULATE_SUCCESS,
    CUSTOM_HOSTING_CHANGE_FAIL,
    CUSTOM_HOSTING_CHANGE_REQUEST,
    CUSTOM_HOSTING_CHANGE_SUCCESS,
    CUSTOM_HOSTING_CONFIG_FAIL,
    CUSTOM_HOSTING_CONFIG_REQUEST,
    CUSTOM_HOSTING_CONFIG_SUCCESS,
    CUSTOM_HOSTING_DETAILS_FAIL,
    CUSTOM_HOSTING_DETAILS_REQUEST,
    CUSTOM_HOSTING_DETAILS_SUCCESS,
    CUSTOM_HOSTING_REMOVE_FAIL,
    CUSTOM_HOSTING_REMOVE_REQUEST,
    CUSTOM_HOSTING_REMOVE_SUCCESS,
    HOSTING_ACCOUNT_INITIAL_STATE,
    HOSTING_BILLINGCYCLE_FAIL,
    HOSTING_BILLINGCYCLE_REQUEST,
    HOSTING_BILLINGCYCLE_SUCCESS,
    HOSTING_BILLING_FAIL,
    HOSTING_BILLING_REQUEST,
    HOSTING_BILLING_SUCCESS,
    HOSTING_CPANEL_DETAILS_FAIL,
    HOSTING_CPANEL_DETAILS_REQUEST,
    HOSTING_CPANEL_DETAILS_SUCCESS,
    HOSTING_CPANEL_FAIL,
    HOSTING_CPANEL_LOGIN_FAIL,
    HOSTING_CPANEL_LOGIN_REQUEST,
    HOSTING_CPANEL_LOGIN_SUCCESS,
    HOSTING_CPANEL_REQUEST,
    HOSTING_CPANEL_SUCCESS,
    HOSTING_DISKBLOCK_FAIL,
    HOSTING_DISKBLOCK_LIST_FAIL,
    HOSTING_DISKBLOCK_LIST_REQUEST,
    HOSTING_DISKBLOCK_LIST_SUCCESS,
    HOSTING_DISKBLOCK_PURCHASE_FAIL,
    HOSTING_DISKBLOCK_PURCHASE_REQUEST,
    HOSTING_DISKBLOCK_PURCHASE_SUCCESS,
    HOSTING_DISKBLOCK_REQUEST,
    HOSTING_DISKBLOCK_RESET,
    HOSTING_DISKBLOCK_SUCCESS,
    HOSTING_INFORMATION_FAIL,
    HOSTING_INFORMATION_REQUEST,
    HOSTING_INFORMATION_RESET,
    HOSTING_INFORMATION_SUBACCOUNT_FAIL,
    HOSTING_INFORMATION_SUBACCOUNT_REQUEST,
    HOSTING_INFORMATION_SUBACCOUNT_SUCCESS,
    HOSTING_INFORMATION_SUCCESS,
    HOSTING_PLESK_FAIL,
    HOSTING_PLESK_REQUEST,
    HOSTING_PLESK_SUCCESS,
    HOSTING_PREPAY_DETAILS_ERROR,
    HOSTING_PREPAY_DETAILS_REQUEST,
    HOSTING_PREPAY_DETAILS_SUCCESS,
    HOSTING_PRIMARY_FAIL,
    HOSTING_PRIMARY_REQUEST,
    HOSTING_PRIMARY_SUCCESS,
    HOSTING_RENEW_FAIL,
    HOSTING_RENEW_INFO_FAIL,
    HOSTING_RENEW_INFO_REQUEST,
    HOSTING_RENEW_INFO_SUCCESS,
    HOSTING_RENEW_REQUEST,
    HOSTING_RENEW_SUCCESS,
    HOSTING_SEND_DETAILS_FAIL,
    HOSTING_SEND_DETAILS_REQUEST,
    HOSTING_SEND_DETAILS_SUCCESS,
    HOSTING_SET_RESELLER,
    HOSTING_SUBACCOUNT_INFO_RESET,
    HOSTING_SUBACCOUNT_INITIAL_STATE,
    HOSTING_SUBACCOUNT_LIST_FAIL,
    HOSTING_SUBACCOUNT_LIST_REQUEST,
    HOSTING_SUBACCOUNT_LIST_RESET,
    HOSTING_SUBACCOUNT_LIST_SUCCESS,
    HOSTING_UPGRADE_CALCULATE_ERROR,
    HOSTING_UPGRADE_CALCULATE_REQUEST,
    HOSTING_UPGRADE_CALCULATE_SUCCESS,
    HOSTING_UPGRADE_FAIL,
    HOSTING_UPGRADE_LIST_FAIL,
    HOSTING_UPGRADE_LIST_REQUEST,
    HOSTING_UPGRADE_LIST_SUCCESS,
    HOSTING_UPGRADE_PRORATA_FAIL,
    HOSTING_UPGRADE_PRORATA_REQUEST,
    HOSTING_UPGRADE_PRORATA_SUCCESS,
    HOSTING_UPGRADE_REQUEST,
    HOSTING_UPGRADE_SUCCESS
} from './accountActions';
import {
    HOSTING_ADMIN_INITIAL_STATE,
    HOSTING_ADMIN_REQUEST_RESET,
    HOSTING_CANCEL_FAIL,
    HOSTING_CANCEL_REQUEST,
    HOSTING_CANCEL_SUCCESS
} from './adminActions';
import {
    HOSTING_ADDON_CANCEL_ERROR,
    HOSTING_ADDON_CANCEL_REQUEST,
    HOSTING_ADDON_CANCEL_SUCCESS,
    HOSTING_ADDON_KEEP_ERROR,
    HOSTING_ADDON_KEEP_REQUEST,
    HOSTING_ADDON_KEEP_SUCCESS,
    HOSTING_ADDON_LIST_ERROR,
    HOSTING_ADDON_LIST_REQUEST,
    HOSTING_ADDON_LIST_SUCCESS,
    HOSTING_ADDON_REMOVE_ERROR,
    HOSTING_ADDON_REMOVE_REQUEST,
    HOSTING_ADDON_REMOVE_SUCCESS,
    HOSTING_ADDON_UPDATE_ERROR,
    HOSTING_ADDON_UPDATE_REQUEST,
    HOSTING_ADDON_UPDATE_SUCCESS,
    HOSTING_ADD_BEST_PROMO_ERROR,
    HOSTING_ADD_BEST_PROMO_REQUEST,
    HOSTING_ADD_BEST_PROMO_SUCCESS,
    HOSTING_ADD_PROMO_CODE_ERROR,
    HOSTING_ADD_PROMO_CODE_REQUEST,
    HOSTING_ADD_PROMO_CODE_SUCCESS,
    HOSTING_CREATE_CART_ERROR,
    HOSTING_CREATE_CART_REQUEST,
    HOSTING_CREATE_CART_SUCCESS,
    HOSTING_CREATE_ORDER_ERROR,
    HOSTING_CREATE_ORDER_REQUEST,
    HOSTING_CREATE_ORDER_SUCCESS,
    HOSTING_EDIT_CART_ITEMS_ERROR,
    HOSTING_EDIT_CART_ITEMS_REQUEST,
    HOSTING_EDIT_CART_ITEMS_SUCCESS,
    HOSTING_LIMITS_ERROR,
    HOSTING_LIMITS_REQUEST,
    HOSTING_LIMITS_SUCCESS,
    HOSTING_REMOVE_PROMO_CODE_ERROR,
    HOSTING_REMOVE_PROMO_CODE_REQUEST,
    HOSTING_REMOVE_PROMO_CODE_SUCCESS,
    HOSTING_RESET_CART_PROMO,
    HOSTING_RESOURCE_CONFIG_ERROR,
    HOSTING_RESOURCE_CONFIG_REQUEST,
    HOSTING_RESOURCE_CONFIG_SUCCESS,
    HOSTING_RESOURCE_ERROR,
    HOSTING_RESOURCE_REQUEST,
    HOSTING_RESOURCE_SUCCESS,
    HOSTING_RESOURCE_UPDATE_ERROR,
    HOSTING_RESOURCE_UPDATE_REQUEST,
    HOSTING_RESOURCE_UPDATE_SUCCESS
} from './baseActions';
import {
    HOSTING_CHECK_IP_REQUEST,
    HOSTING_CHECK_IP_SUCCESS,
    HOSTING_CONFIGURATION_INITIAL_STATE,
    HOSTING_CONFIGURATION_REQUEST_RESET,
    HOSTING_DATABASE_FAIL,
    HOSTING_DATABASE_LIST_FAIL,
    HOSTING_DATABASE_LIST_REQUEST,
    HOSTING_DATABASE_LIST_SUCCESS,
    HOSTING_DATABASE_REQUEST,
    HOSTING_DATABASE_SUCCESS,
    HOSTING_IPV6_CHECK_FAIL,
    HOSTING_IPV6_CHECK_REQUEST,
    HOSTING_IPV6_CHECK_SUCCESS,
    HOSTING_IPV6_FAIL,
    HOSTING_IPV6_REQUEST,
    HOSTING_IPV6_SUCCESS,
    HOSTING_SSHACCESS_FAIL,
    HOSTING_SSHACCESS_LIST_FAIL,
    HOSTING_SSHACCESS_LIST_REQUEST,
    HOSTING_SSHACCESS_LIST_SUCCESS,
    HOSTING_SSHACCESS_REQUEST,
    HOSTING_SSHACCESS_SUCCESS,
    HOSTING_TEMPURL_CHECK_FAIL,
    HOSTING_TEMPURL_CHECK_REQUEST,
    HOSTING_TEMPURL_CHECK_SUCCESS,
    HOSTING_TEMPURL_FAIL,
    HOSTING_TEMPURL_REQUEST,
    HOSTING_TEMPURL_SUCCESS
} from './configActions';
import {
    HOSTING_CPANEL_SUBACCOUNT_FAIL,
    HOSTING_CPANEL_SUBACCOUNT_REQUEST,
    HOSTING_CPANEL_SUBACCOUNT_SUCCESS,
    HOSTING_RESELLER_INITIAL_STATE,
    HOSTING_SUBACCOUNT_META,
    HOSTING_SUBACCOUNT_USERNAME
} from './resellerActions';
import {
    HOSTING_AUTOSSL_CHECK_FAIL,
    HOSTING_AUTOSSL_CHECK_REQUEST,
    HOSTING_AUTOSSL_CHECK_SUCCESS,
    HOSTING_FILE_QUARANTINE_FAIL,
    HOSTING_FILE_QUARANTINE_REQUEST,
    HOSTING_FILE_QUARANTINE_SUCCESS,
    HOSTING_FIREWALL_CHECK_FAIL,
    HOSTING_FIREWALL_CHECK_REQUEST,
    HOSTING_FIREWALL_CHECK_SUCCESS,
    HOSTING_FIREWALL_EXTERNAL_CHECK_FAIL,
    HOSTING_FIREWALL_EXTERNAL_CHECK_REQUEST,
    HOSTING_FIREWALL_EXTERNAL_CHECK_SUCCESS,
    HOSTING_FIREWALL_UNBLOCK_FAIL,
    HOSTING_FIREWALL_UNBLOCK_REQUEST,
    HOSTING_FIREWALL_UNBLOCK_SUCCESS,
    HOSTING_MODSEC_FAIL,
    HOSTING_MODSEC_REQUEST,
    HOSTING_MODSEC_SUCCESS,
    HOSTING_PERMISSIONS_FAIL,
    HOSTING_PERMISSIONS_REQUEST,
    HOSTING_PERMISSIONS_SUCCESS,
    HOSTING_SECURITY_INITIAL_STATE,
    HOSTING_SECURITY_REQUEST_RESET
} from './securityActions';

const accountState = {
    hosting_isReseller: null,
    hosting_information_status: null,
    hosting_information_data: null,
    hosting_information_error: null,
    hosting_send_details_status: null,
    hosting_send_details_data: null,
    hosting_send_details_error: null,
    hosting_plesk_status: null,
    hosting_plesk_data: null,
    hosting_plesk_error: null,
    hosting_information_subaccount_status: null,
    hosting_information_subaccount_data: null,
    hosting_information_subaccount_error: null,
    hosting_subaccount_list_status: null,
    hosting_subaccount_list_data: null,
    hosting_subaccount_list_error: null,
    hosting_cpanel_login_status: null,
    hosting_cpanel_login_data: null,
    hosting_cpanel_login_error: null,
    hosting_cpanel_details_status: null,
    hosting_cpanel_details_data: null,
    hosting_cpanel_details_error: null,
    hosting_cpanel_status: null,
    hosting_cpanel_data: null,
    hosting_cpanel_error: null,
    hosting_upgrade_list_status: null,
    hosting_upgrade_list_data: null,
    hosting_upgrade_list_error: null,
    hosting_upgrade_status: null,
    hosting_upgrade_data: null,
    hosting_upgrade_error: null,
    hosting_upgrade_prorata_status: null,
    hosting_upgrade_prorata_data: null,
    hosting_upgrade_prorata_error: null,
    hosting_diskblock_list_status: null,
    hosting_diskblock_list_data: null,
    hosting_diskblock_list_error: null,
    hosting_diskblock_purchase_status: null,
    hosting_diskblock_purchase_data: null,
    hosting_diskblock_purchase_error: null,
    hosting_diskblock_status: null,
    hosting_diskblock_data: null,
    hosting_diskblock_error: null,
    hosting_billingcycle_status: null,
    hosting_billingcycle_data: null,
    hosting_billingcycle_error: null,
    hosting_billing_status: null,
    hosting_billing_data: null,
    hosting_billing_error: null,
    hosting_primary_status: null,
    hosting_primary_data: null,
    hosting_primary_error: null,
    hosting_prepay_status: null,
    hosting_prepay_data: null,
    hosting_prepay_error: null,
    hosting_prepay_info_status: null,
    hosting_prepay_info_data: null,
    hosting_prepay_info_error: null,
    hosting_prepay_details_status: null,
    hosting_prepay_details_data: null,
    hosting_prepay_details_error: null,
    custom_hosting_details_status: null,
    custom_hosting_details_data: null,
    custom_hosting_details_error: null,
    custom_hosting_config_status: null,
    custom_hosting_config_data: null,
    custom_hosting_config_error: null,
    custom_hosting_change_status: null,
    custom_hosting_change_data: null,
    custom_hosting_change_error: null,
    custom_hosting_remove_status: null,
    custom_hosting_remove_data: null,
    custom_hosting_remove_error: null,
    custom_hosting_calculate_status: null,
    custom_hosting_calculate_data: null,
    custom_hosting_calculate_error: null,
    custom_hosting_addon_list_status: null,
    custom_hosting_addon_list_data: null,
    custom_hosting_addon_list_error: null,
    custom_hosting_addon_purchase_status: null,
    custom_hosting_addon_purchase_data: null,
    custom_hosting_addon_purchase_error: null,
    custom_hosting_addon_remove_status: null,
    custom_hosting_addon_remove_data: null,
    custom_hosting_addon_remove_error: null,
    custom_hosting_addon_keep_status: null,
    custom_hosting_addon_keep_data: null,
    custom_hosting_addon_keep_error: null
};
const adminState = {
    hosting_admin_status: null,
    hosting_admin_data: null,
    hosting_admin_error: null,
    hosting_cancel_status: null,
    hosting_cancel_data: null,
    hosting_cancel_error: null
};
const configState = {
    hosting_tempurl_check_status: null,
    hosting_tempurl_check_data: null,
    hosting_tempurl_check_error: null,
    hosting_tempurl_status: null,
    hosting_tempurl_data: null,
    hosting_tempurl_error: null,
    hosting_ipv6_check_status: null,
    hosting_ipv6_check_data: null,
    hosting_ipv6_check_error: null,
    hosting_ipv6_status: null,
    hosting_ipv6_data: null,
    hosting_ipv6_error: null,
    hosting_check_ip_status: null,
    hosting_check_ip_data: null,
    hosting_database_list_status: null,
    hosting_database_list_data: null,
    hosting_database_list_meta: null,
    hosting_database_list_error: null,
    hosting_database_status: null,
    hosting_database_data: null,
    hosting_database_error: null,
    hosting_sshaccess_list_status: null,
    hosting_sshaccess_list_data: null,
    hosting_sshaccess_list_meta: null,
    hosting_sshaccess_list_error: null,
    hosting_sshaccess_status: null,
    hosting_sshaccess_data: null,
    hosting_sshaccess_error: null
};
const resellerState = {
    hosting_subaccount_username: null,
    hosting_subaccount_meta: null,
    hosting_cpanel_subaccount_status: null,
    hosting_cpanel_subaccount_data: null,
    hosting_cpanel_subaccount_error: null
};
const securityState = {
    hosting_security_status: null,
    hosting_security_data: null,
    hosting_security_error: null,
    hosting_autossl_check_status: null,
    hosting_autossl_check_data: null,
    hosting_autossl_check_error: null,
    hosting_permissions_status: null,
    hosting_permissions_data: null,
    hosting_permissions_error: null,
    hosting_file_quarantine_status: null,
    hosting_file_quarantine_data: null,
    hosting_file_quarantine_error: null,
    hosting_modsec_status: null,
    hosting_modsec_data: null,
    hosting_modsec_error: null,
    hosting_firewall_check_status: null,
    hosting_firewall_check_data: null,
    hosting_firewall_check_error: null,
    hosting_firewall_external_check_status: null,
    hosting_firewall_external_check_data: null,
    hosting_firewall_external_check_error: null,
    hosting_firewall_unblock_status: null,
    hosting_firewall_unblock_data: null,
    hosting_firewall_unblock_error: null
};
const baseState = {
    hosting_resource_status: null,
    hosting_resource_data: null,
    hosting_resource_error: null,
    hosting_resource_config_status: null,
    hosting_resource_config_data: null,
    hosting_resource_config_error: null,
    hosting_resource_update_status: null,
    hosting_resource_update_data: null,
    hosting_resource_update_error: null,
    hosting_addons_list_status: null,
    hosting_addons_list_data: null,
    hosting_addons_list_error: null,
    hosting_addon_remove_status: null,
    hosting_addon_remove_data: null,
    hosting_addon_remove_error: null,
    hosting_addon_update_status: null,
    hosting_addon_update_data: null,
    hosting_addon_update_error: null,
    hosting_addon_cancel_status: null,
    hosting_addon_cancel_data: null,
    hosting_addon_cancel_error: null,
    hosting_addon_keep_status: null,
    hosting_addon_keep_data: null,
    hosting_addon_keep_error: null,
    hosting_create_cart_status: null,
    hosting_create_cart_data: null,
    hosting_create_cart_error: null,
    hosting_edit_cart_items_status: null,
    hosting_edit_cart_items_data: null,
    hosting_edit_cart_items_error: null,
    hosting_add_promo_code_status: null,
    hosting_add_promo_code_data: null,
    hosting_add_promo_code_error: null,
    hosting_remove_promo_code_status: null,
    hosting_remove_promo_code_data: null,
    hosting_remove_promo_code_error: null,
    hosting_create_order_status: null,
    hosting_create_order_data: null,
    hosting_create_order_error: null,
    hosting_limits_status: null,
    hosting_limits_data: null
};
const initialState = {
    ...accountState,
    ...adminState,
    ...configState,
    ...resellerState,
    ...securityState,
    ...baseState
};

const hostingReducer = (state = initialState, action) => {
    switch (action.type) {
        case HOSTING_ACCOUNT_INITIAL_STATE:
            return {
                ...state,
                accountState
            };

        case HOSTING_ADMIN_INITIAL_STATE:
            return {
                ...state,
                adminState
            };

        case HOSTING_CONFIGURATION_INITIAL_STATE:
            return {
                ...state,
                configState
            };

        case HOSTING_RESELLER_INITIAL_STATE:
            return {
                ...state,
                ...resellerState
            };

        case HOSTING_SECURITY_INITIAL_STATE:
            return {
                ...state,
                securityState
            };

        case HOSTING_SUBACCOUNT_INITIAL_STATE:
            return {
                ...state,
                hosting_information_subaccount_status: null,
                hosting_information_subaccount_data: null,
                hosting_information_subaccount_error: null
            };

        /**********************************************************************************************************
         *   SUBACCOUNT
         **********************************************************************************************************/
        case HOSTING_INFORMATION_SUBACCOUNT_REQUEST:
            return {
                ...state,
                hosting_information_subaccount_status: 'loading',
                hosting_information_subaccount_data: null,
                hosting_information_subaccount_error: null
            };

        case HOSTING_INFORMATION_SUBACCOUNT_SUCCESS:
            return {
                ...state,
                hosting_information_subaccount_status: 'success',
                hosting_information_subaccount_data: action.hosting_information_subaccount_data
            };

        case HOSTING_INFORMATION_SUBACCOUNT_FAIL:
            return {
                ...state,
                hosting_information_subaccount_status: 'error',
                hosting_information_subaccount_error: action.hosting_information_subaccount_error
            };

        case HOSTING_SET_RESELLER:
            return {
                ...state,
                hosting_isReseller: action.hosting_isReseller
            };

        case HOSTING_SEND_DETAILS_REQUEST:
            return {
                ...state,
                hosting_send_details_status: 'loading',
                hosting_send_details_data: null,
                hosting_send_details_error: null
            };

        case HOSTING_SEND_DETAILS_SUCCESS:
            return {
                ...state,
                hosting_send_details_status: 'success',
                hosting_send_details_data: action.hosting_send_details_data
            };

        case HOSTING_SEND_DETAILS_FAIL:
            return {
                ...state,
                hosting_send_details_status: 'error',
                hosting_send_details_error: action.hosting_send_details_error
            };

        /**********************************************************************************************************
         *   CPANEL LOGIN
         **********************************************************************************************************/
        case HOSTING_CPANEL_LOGIN_REQUEST:
            return {
                ...state,
                hosting_cpanel_login_status: 'loading',
                hosting_cpanel_login_data: null,
                hosting_cpanel_login_error: null
            };

        case HOSTING_CPANEL_LOGIN_SUCCESS:
            return {
                ...state,
                hosting_cpanel_login_status: 'success',
                hosting_cpanel_login_data: action.hosting_cpanel_login_data
            };

        case HOSTING_CPANEL_LOGIN_FAIL:
            return {
                ...state,
                hosting_cpanel_login_status: 'error',
                hosting_cpanel_login_error: action.hosting_cpanel_login_error
            };

        /**********************************************************************************************************
         *   CPANEL DETAILS
         **********************************************************************************************************/
        case HOSTING_CPANEL_DETAILS_REQUEST:
            return {
                ...state,
                hosting_cpanel_details_status: 'loading',
                hosting_cpanel_details_data: null,
                hosting_cpanel_details_error: null
            };

        case HOSTING_CPANEL_DETAILS_SUCCESS:
            return {
                ...state,
                hosting_cpanel_details_status: 'success',
                hosting_cpanel_details_data: action.hosting_cpanel_details_data
            };

        case HOSTING_CPANEL_DETAILS_FAIL:
            return {
                ...state,
                hosting_cpanel_details_status: 'error',
                hosting_cpanel_details_error: action.hosting_cpanel_details_error
            };

        /**********************************************************************************************************
         *   CPANEL ACTIONS
         **********************************************************************************************************/
        case HOSTING_CPANEL_REQUEST:
            return {
                ...state,
                hosting_cpanel_status: 'loading',
                hosting_cpanel_data: null,
                hosting_cpanel_error: null
            };

        case HOSTING_CPANEL_SUCCESS:
            return {
                ...state,
                hosting_cpanel_status: 'success',
                hosting_cpanel_data: action.hosting_cpanel_data
            };

        case HOSTING_CPANEL_FAIL:
            return {
                ...state,
                hosting_cpanel_status: 'error',
                hosting_cpanel_error: action.hosting_cpanel_error
            };

        /**********************************************************************************************************
         *   HOSTING INFORMATION
         **********************************************************************************************************/
        case HOSTING_INFORMATION_RESET:
            return {
                hosting_information_status: null,
                hosting_information_data: null,
                hosting_information_error: null
            };

        case HOSTING_INFORMATION_REQUEST:
            return {
                ...state,
                hosting_information_status: 'loading',
                hosting_information_data: null,
                hosting_information_error: null
            };

        case HOSTING_INFORMATION_SUCCESS:
            return {
                ...state,
                hosting_information_status: 'success',
                hosting_information_data: action.hosting_information_data,
                hosting_information_error: null
            };

        case HOSTING_INFORMATION_FAIL:
            return {
                ...state,
                hosting_information_status: 'error',
                hosting_information_error: action.hosting_information_error,
                hosting_information_data: null
            };

        /**********************************************************************************************************
         *   HOSTING LIMITS
         **********************************************************************************************************/
        case HOSTING_LIMITS_REQUEST:
            return {
                ...state,
                hosting_limits_status: 'loading',
                hosting_limits_data: null
            };

        case HOSTING_LIMITS_SUCCESS:
            return {
                ...state,
                hosting_limits_status: 'success',
                hosting_limits_data: action.hosting_limits_data
            };

        case HOSTING_LIMITS_ERROR:
            return {
                ...state,
                hosting_limits_status: 'error'
            };

        /**********************************************************************************************************
         *   PLESK DETAILS
         **********************************************************************************************************/
        case HOSTING_PLESK_REQUEST:
            return {
                ...state,
                hosting_plesk_status: 'loading',
                hosting_plesk_data: null,
                hosting_plesk_error: null
            };

        case HOSTING_PLESK_SUCCESS:
            return {
                ...state,
                hosting_plesk_status: 'success',
                hosting_plesk_data: action.hosting_plesk_data
            };

        case HOSTING_PLESK_FAIL:
            return {
                ...state,
                hosting_plesk_status: 'error',
                hosting_plesk_error: action.hosting_plesk_error
            };

        /**********************************************************************************************************
         *   DISKBLOCK
         **********************************************************************************************************/
        case HOSTING_DISKBLOCK_RESET:
            return {
                ...state,
                hosting_diskblock_status: null,
                hosting_diskblock_error: null,
                hosting_diskblock_purchase_status: null,
                hosting_diskblock_purchase_error: null
            };

        case HOSTING_DISKBLOCK_LIST_REQUEST:
            return {
                ...state,
                hosting_diskblock_list_status: 'loading',
                hosting_diskblock_list_data: null,
                hosting_diskblock_list_error: null
            };

        case HOSTING_DISKBLOCK_LIST_SUCCESS:
            return {
                ...state,
                hosting_diskblock_list_status: 'success',
                hosting_diskblock_list_data: action.hosting_diskblock_list_data
            };

        case HOSTING_DISKBLOCK_LIST_FAIL:
            return {
                ...state,
                hosting_diskblock_list_status: 'error',
                hosting_diskblock_list_error: action.hosting_diskblock_list_error
            };

        case HOSTING_DISKBLOCK_PURCHASE_REQUEST:
            return {
                ...state,
                hosting_diskblock_purchase_status: 'loading',
                hosting_diskblock_purchase_data: null,
                hosting_diskblock_purchase_error: null
            };

        case HOSTING_DISKBLOCK_PURCHASE_SUCCESS:
            return {
                ...state,
                hosting_diskblock_purchase_status: 'success',
                hosting_diskblock_purchase_data: action.hosting_diskblock_purchase_data
            };

        case HOSTING_DISKBLOCK_PURCHASE_FAIL:
            return {
                ...state,
                hosting_diskblock_purchase_status: 'error',
                hosting_diskblock_purchase_error: action.hosting_diskblock_purchase_error
            };

        case HOSTING_DISKBLOCK_REQUEST:
            return {
                ...state,
                hosting_diskblock_status: 'loading',
                hosting_diskblock_data: null,
                hosting_diskblock_error: null
            };

        case HOSTING_DISKBLOCK_SUCCESS:
            return {
                ...state,
                hosting_diskblock_status: 'success',
                hosting_diskblock_data: action.hosting_diskblock_data
            };

        case HOSTING_DISKBLOCK_FAIL:
            return {
                ...state,
                hosting_diskblock_status: 'error',
                hosting_diskblock_error: action.hosting_diskblock_error
            };

        /**********************************************************************************************************
         *   UPGRADE
         **********************************************************************************************************/
        case HOSTING_UPGRADE_LIST_REQUEST:
            return {
                ...state,
                hosting_upgrade_list_status: 'loading',
                hosting_upgrade_list_data: null,
                hosting_upgrade_list_error: null
            };

        case HOSTING_UPGRADE_LIST_SUCCESS:
            return {
                ...state,
                hosting_upgrade_list_status: 'success',
                hosting_upgrade_list_data: action.hosting_upgrade_list_data
            };

        case HOSTING_UPGRADE_LIST_FAIL:
            return {
                ...state,
                hosting_upgrade_list_status: 'error',
                hosting_upgrade_list_error: action.hosting_upgrade_list_error
            };

        case HOSTING_UPGRADE_CALCULATE_REQUEST:
            return {
                ...state,
                hosting_upgrade_calculate_status: 'loading',
                hosting_upgrade_calculate_data: null
            };

        case HOSTING_UPGRADE_CALCULATE_SUCCESS:
            return {
                ...state,
                hosting_upgrade_calculate_status: 'success',
                hosting_upgrade_calculate_data: action.hosting_upgrade_calculate_data
            };

        case HOSTING_UPGRADE_CALCULATE_ERROR:
            return {
                ...state,
                hosting_upgrade_calculate_status: 'error'
            };

        case HOSTING_UPGRADE_REQUEST:
            return {
                ...state,
                hosting_upgrade_status: 'loading',
                hosting_upgrade_data: null,
                hosting_upgrade_error: null
            };

        case HOSTING_UPGRADE_SUCCESS:
            return {
                ...state,
                hosting_upgrade_status: 'success',
                hosting_upgrade_data: action.hosting_upgrade_data
            };

        case HOSTING_UPGRADE_FAIL:
            return {
                ...state,
                hosting_upgrade_status: 'error',
                hosting_upgrade_error: action.hosting_upgrade_error
            };

        case HOSTING_UPGRADE_PRORATA_REQUEST:
            return {
                ...state,
                hosting_upgrade_prorata_status: 'loading',
                hosting_upgrade_prorata_data: null,
                hosting_upgrade_prorata_error: null
            };

        case HOSTING_UPGRADE_PRORATA_SUCCESS:
            return {
                ...state,
                hosting_upgrade_prorata_status: 'success',
                hosting_upgrade_prorata_data: action.hosting_upgrade_prorata_data
            };

        case HOSTING_UPGRADE_PRORATA_FAIL:
            return {
                ...state,
                hosting_upgrade_prorata_status: 'error',
                hosting_upgrade_prorata_error: action.hosting_upgrade_prorata_error
            };

        /**********************************************************************************************************
         *   SUBACCOUNT
         **********************************************************************************************************/
        case HOSTING_SUBACCOUNT_LIST_REQUEST:
            return {
                ...state,
                hosting_subaccount_list_status: 'loading',
                hosting_subaccount_list_data: null,
                hosting_subaccount_list_error: null
            };

        case HOSTING_SUBACCOUNT_LIST_SUCCESS:
            return {
                ...state,
                hosting_subaccount_list_status: 'success',
                hosting_subaccount_list_data: action.hosting_subaccount_list_data
            };

        case HOSTING_SUBACCOUNT_LIST_FAIL:
            return {
                ...state,
                hosting_subaccount_list_status: 'error',
                hosting_subaccount_list_error: action.hosting_subaccount_list_error
            };

        case HOSTING_SUBACCOUNT_LIST_RESET:
            return {
                ...state,
                hosting_subaccount_list_status: null,
                hosting_subaccount_list_data: null,
                hosting_subaccount_list_error: null
            };

        /**********************************************************************************************************
         *   BILLING CYCLE
         **********************************************************************************************************/
        case HOSTING_BILLINGCYCLE_REQUEST:
            return {
                ...state,
                hosting_billingcycle_status: 'loading',
                hosting_billingcycle_data: null,
                hosting_billingcycle_error: null
            };

        case HOSTING_BILLINGCYCLE_SUCCESS:
            return {
                ...state,
                hosting_billingcycle_status: 'success',
                hosting_billingcycle_data: action.hosting_billingcycle_data
            };

        case HOSTING_BILLINGCYCLE_FAIL:
            return {
                ...state,
                hosting_billingcycle_status: 'error',
                hosting_billingcycle_error: action.hosting_billingcycle_error
            };

        case HOSTING_BILLING_REQUEST:
            return {
                ...state,
                hosting_billing_status: 'loading',
                hosting_billing_data: null,
                hosting_billing_error: null
            };

        case HOSTING_BILLING_SUCCESS:
            return {
                ...state,
                hosting_billing_status: 'success',
                hosting_billing_data: action.hosting_billing_data
            };

        case HOSTING_BILLING_FAIL:
            return {
                ...state,
                hosting_billing_status: 'error',
                hosting_billing_error: action.hosting_billing_error
            };

        /**********************************************************************************************************
         *   PRIMARY DOMAIN
         **********************************************************************************************************/
        case HOSTING_PRIMARY_REQUEST:
            return {
                ...state,
                hosting_primary_status: 'loading',
                hosting_primary_data: null,
                hosting_primary_error: null
            };

        case HOSTING_PRIMARY_SUCCESS:
            return {
                ...state,
                hosting_primary_status: 'success',
                hosting_primary_data: action.hosting_primary_data
            };

        case HOSTING_PRIMARY_FAIL:
            return {
                ...state,
                hosting_primary_status: 'error',
                hosting_primary_error: action.hosting_primary_error
            };

        /**********************************************************************************************************
         *   RENEW HOSTING
         **********************************************************************************************************/
        case HOSTING_RENEW_REQUEST:
            return {
                ...state,
                hosting_prepay_status: 'loading',
                hosting_prepay_data: null,
                hosting_prepay_error: null
            };

        case HOSTING_RENEW_SUCCESS:
            return {
                ...state,
                hosting_prepay_status: 'success',
                hosting_prepay_data: action.hosting_prepay_data
            };

        case HOSTING_RENEW_FAIL:
            return {
                ...state,
                hosting_prepay_status: 'error',
                hosting_prepay_error: action.hosting_prepay_error
            };

        case HOSTING_RENEW_INFO_REQUEST:
            return {
                ...state,
                hosting_prepay_info_status: 'loading',
                hosting_prepay_info_data: null,
                hosting_prepay_info_error: null
            };

        case HOSTING_RENEW_INFO_SUCCESS:
            return {
                ...state,
                hosting_prepay_info_status: 'success',
                hosting_prepay_info_data: action.hosting_prepay_info_data
            };

        case HOSTING_RENEW_INFO_FAIL:
            return {
                ...state,
                hosting_prepay_info_status: 'error',
                hosting_prepay_info_error: action.hosting_prepay_info_error
            };

        case HOSTING_PREPAY_DETAILS_REQUEST:
            return {
                ...state,
                hosting_prepay_details_status: 'loading',
                hosting_prepay_details_data: null,
                hosting_prepay_details_error: null
            };

        case HOSTING_PREPAY_DETAILS_SUCCESS:
            return {
                ...state,
                hosting_prepay_details_status: 'success',
                hosting_prepay_details_data: action.hosting_prepay_details_data
            };

        case HOSTING_PREPAY_DETAILS_ERROR:
            return {
                ...state,
                hosting_prepay_details_status: 'error',
                hosting_prepay_details_error: action.hosting_prepay_details_error
            };

        /**********************************************************************************************************
         *   CUSTOM HOSTING
         **********************************************************************************************************/
        case CUSTOM_HOSTING_DETAILS_REQUEST:
            return {
                ...state,
                custom_hosting_details_status: 'loading',
                custom_hosting_details_data: null,
                custom_hosting_details_error: null
            };
        case CUSTOM_HOSTING_DETAILS_SUCCESS:
            return {
                ...state,
                custom_hosting_details_status: 'success',
                custom_hosting_details_data: action.custom_hosting_details_data
            };
        case CUSTOM_HOSTING_DETAILS_FAIL:
            return {
                ...state,
                custom_hosting_details_status: 'error',
                custom_hosting_details_error: action.custom_hosting_details_error
            };

        case CUSTOM_HOSTING_CONFIG_REQUEST:
            return {
                ...state,
                custom_hosting_config_status: 'loading',
                custom_hosting_config_data: null,
                custom_hosting_config_error: null
            };
        case CUSTOM_HOSTING_CONFIG_SUCCESS:
            return {
                ...state,
                custom_hosting_config_status: 'success',
                custom_hosting_config_data: action.custom_hosting_config_data
            };
        case CUSTOM_HOSTING_CONFIG_FAIL:
            return {
                ...state,
                custom_hosting_config_status: 'error',
                custom_hosting_config_error: action.custom_hosting_config_error
            };

        case CUSTOM_HOSTING_CHANGE_REQUEST:
            return {
                ...state,
                custom_hosting_change_status: 'loading',
                custom_hosting_change_data: null,
                custom_hosting_change_error: null
            };

        case CUSTOM_HOSTING_CHANGE_SUCCESS:
            return {
                ...state,
                custom_hosting_change_status: 'success',
                custom_hosting_change_data: action.custom_hosting_change_data
            };

        case CUSTOM_HOSTING_CHANGE_FAIL:
            return {
                ...state,
                custom_hosting_change_status: 'error',
                custom_hosting_change_error: action.custom_hosting_change_error
            };

        case CUSTOM_HOSTING_REMOVE_REQUEST:
            return {
                ...state,
                custom_hosting_remove_status: 'loading',
                custom_hosting_remove_data: null,
                custom_hosting_remove_error: null
            };

        case CUSTOM_HOSTING_REMOVE_SUCCESS:
            return {
                ...state,
                custom_hosting_remove_status: 'success',
                custom_hosting_remove_data: action.custom_hosting_remove_data
            };

        case CUSTOM_HOSTING_REMOVE_FAIL:
            return {
                ...state,
                custom_hosting_remove_status: 'error',
                custom_hosting_remove_error: action.custom_hosting_remove_error
            };

        case CUSTOM_HOSTING_CALCULATE_RESET:
            return {
                ...state,
                hosting_resource_calculate_status: null,
                hosting_resource_calculate_data: null,
                hosting_resource_calculate_error: null
            };

        case CUSTOM_HOSTING_CALCULATE_REQUEST:
            return {
                ...state,
                custom_hosting_calculate_status: 'loading',
                custom_hosting_calculate_data: null,
                custom_hosting_calculate_error: null
            };

        case CUSTOM_HOSTING_CALCULATE_SUCCESS:
            return {
                ...state,
                custom_hosting_calculate_status: 'success',
                custom_hosting_calculate_data: action.custom_hosting_calculate_data
            };

        case CUSTOM_HOSTING_CALCULATE_FAIL:
            return {
                ...state,
                custom_hosting_calculate_status: 'error',
                custom_hosting_calculate_error: action.custom_hosting_calculate_error
            };

        case CUSTOM_HOSTING_ADDON_LIST_REQUEST:
            return {
                ...state,
                custom_hosting_addon_list_status: 'loading',
                custom_hosting_addon_list_data: null,
                custom_hosting_addon_list_error: null
            };

        case CUSTOM_HOSTING_ADDON_LIST_SUCCESS:
            return {
                ...state,
                custom_hosting_addon_list_status: 'success',
                custom_hosting_addon_list_data: action.custom_hosting_addon_list_data
            };

        case CUSTOM_HOSTING_ADDON_LIST_FAIL:
            return {
                ...state,
                custom_hosting_addon_list_status: 'error',
                custom_hosting_addon_list_error: action.custom_hosting_addon_list_error
            };

        case CUSTOM_HOSTING_ADDON_PURCHASE_REQUEST:
            return {
                ...state,
                custom_hosting_addon_purchase_status: 'loading',
                custom_hosting_addon_purchase_data: null,
                custom_hosting_addon_purchase_error: null
            };

        case CUSTOM_HOSTING_ADDON_PURCHASE_SUCCESS:
            return {
                ...state,
                custom_hosting_addon_purchase_status: 'success',
                custom_hosting_addon_purchase_data: action.custom_hosting_addon_purchase_data
            };

        case CUSTOM_HOSTING_ADDON_PURCHASE_FAIL:
            return {
                ...state,
                custom_hosting_addon_purchase_status: 'error',
                custom_hosting_addon_purchase_error: action.custom_hosting_addon_purchase_error
            };

        case CUSTOM_HOSTING_ADDON_REMOVE_REQUEST:
            return {
                ...state,
                custom_hosting_addon_remove_status: 'loading',
                custom_hosting_addon_remove_data: null,
                custom_hosting_addon_remove_error: null
            };

        case CUSTOM_HOSTING_ADDON_REMOVE_SUCCESS:
            return {
                ...state,
                custom_hosting_addon_remove_status: 'success',
                custom_hosting_addon_remove_data: action.custom_hosting_addon_remove_data
            };

        case CUSTOM_HOSTING_ADDON_REMOVE_FAIL:
            return {
                ...state,
                custom_hosting_addon_remove_status: 'error',
                custom_hosting_addon_remove_error: action.custom_hosting_addon_remove_error
            };

        case CUSTOM_HOSTING_ADDON_KEEP_REQUEST:
            return {
                ...state,
                custom_hosting_addon_keep_status: 'loading',
                custom_hosting_addon_keep_data: null,
                custom_hosting_addon_keep_error: null
            };

        case CUSTOM_HOSTING_ADDON_KEEP_SUCCESS:
            return {
                ...state,
                custom_hosting_addon_keep_status: 'success',
                custom_hosting_addon_keep_data: action.custom_hosting_addon_keep_data
            };

        case CUSTOM_HOSTING_ADDON_KEEP_FAIL:
            return {
                ...state,
                custom_hosting_addon_keep_status: 'error',
                custom_hosting_addon_keep_error: action.custom_hosting_addon_keep_error
            };

        case HOSTING_CREATE_CART_REQUEST:
            return {
                ...state,
                hosting_create_cart_status: 'loading',
                hosting_create_cart_data: null,
                hosting_create_cart_error: null
            };

        case HOSTING_CREATE_CART_SUCCESS:
            return {
                ...state,
                hosting_create_cart_status: 'success',
                hosting_create_cart_data: action.hosting_create_cart_data
            };

        case HOSTING_CREATE_CART_ERROR:
            return {
                ...state,
                hosting_create_cart_status: 'error',
                hosting_create_cart_error: action.hosting_create_cart_error
            };

        case HOSTING_EDIT_CART_ITEMS_REQUEST:
            return {
                ...state,
                hosting_edit_cart_items_status: 'loading',
                hosting_edit_cart_items_data: null,
                hosting_edit_cart_items_error: null
            };

        case HOSTING_EDIT_CART_ITEMS_SUCCESS:
            return {
                ...state,
                hosting_edit_cart_items_status: 'success',
                hosting_edit_cart_items_data: action.hosting_edit_cart_items_data
            };

        case HOSTING_EDIT_CART_ITEMS_ERROR:
            return {
                ...state,
                hosting_edit_cart_items_status: 'error',
                hosting_edit_cart_items_error: action.hosting_edit_cart_items_error
            };

        case HOSTING_RESET_CART_PROMO:
            return {
                ...state,
                hosting_create_cart_status: null,
                hosting_create_cart_data: null,
                hosting_create_cart_error: null,
                hosting_add_promo_code_status: null,
                hosting_add_promo_code_data: null,
                hosting_add_promo_code_error: null
            };

        case HOSTING_ADD_PROMO_CODE_REQUEST:
            return {
                ...state,
                hosting_add_promo_code_status: 'loading',
                hosting_add_promo_code_data: null,
                hosting_add_promo_code_error: null
            };

        case HOSTING_ADD_PROMO_CODE_SUCCESS:
            return {
                ...state,
                hosting_add_promo_code_status: 'success',
                hosting_add_promo_code_data: action.hosting_add_promo_code_data
            };

        case HOSTING_ADD_PROMO_CODE_ERROR:
            return {
                ...state,
                hosting_add_promo_code_status: 'error',
                hosting_add_promo_code_error: action.hosting_add_promo_code_error
            };

        case HOSTING_ADD_BEST_PROMO_REQUEST:
            return {
                ...state,
                hosting_add_best_promo_status: 'loading',
                hosting_add_best_promo_data: null
            };

        case HOSTING_ADD_BEST_PROMO_SUCCESS:
            return {
                ...state,
                hosting_add_best_promo_status: 'success',
                hosting_add_best_promo_data: action.hosting_add_best_promo_data
            };

        case HOSTING_ADD_BEST_PROMO_ERROR:
            return {
                ...state,
                hosting_add_best_promo_status: 'error'
            };

        case HOSTING_REMOVE_PROMO_CODE_REQUEST:
            return {
                ...state,
                hosting_remove_promo_code_status: 'loading',
                hosting_remove_promo_code_data: null,
                hosting_remove_promo_code_error: null
            };

        case HOSTING_REMOVE_PROMO_CODE_SUCCESS:
            return {
                ...state,
                hosting_remove_promo_code_status: 'success',
                hosting_remove_promo_code_data: action.hosting_remove_promo_code_data
            };

        case HOSTING_REMOVE_PROMO_CODE_ERROR:
            return {
                ...state,
                hosting_remove_promo_code_status: 'error',
                hosting_remove_promo_code_error: action.hosting_remove_promo_code_error
            };

        case HOSTING_CREATE_ORDER_REQUEST:
            return {
                ...state,
                hosting_create_order_status: 'loading',
                hosting_create_order_data: null,
                hosting_create_order_error: null
            };

        case HOSTING_CREATE_ORDER_SUCCESS:
            return {
                ...state,
                hosting_create_order_status: 'success',
                hosting_create_order_data: action.hosting_create_order_data
            };

        case HOSTING_CREATE_ORDER_ERROR:
            return {
                ...state,
                hosting_create_order_status: 'error',
                hosting_create_order_error: action.hosting_create_order_error
            };

        /**********************************************************************************************************
         *   RESET
         **********************************************************************************************************/
        case HOSTING_ADMIN_REQUEST_RESET:
            return {
                ...state,
                hosting_admin_status: null,
                hosting_admin_data: null,
                hosting_admin_error: null
            };

        /**********************************************************************************************************
         *   CANCEL STATES
         **********************************************************************************************************/
        case HOSTING_CANCEL_REQUEST:
            return {
                ...state,
                hosting_cancel_status: action.hosting_cancel_status,
                hosting_cancel_data: null,
                hosting_cancel_error: null
            };

        case HOSTING_CANCEL_SUCCESS:
            return {
                ...state,
                hosting_cancel_status: 'success',
                hosting_cancel_data: action.hosting_cancel_data
            };

        case HOSTING_CANCEL_FAIL:
            return {
                ...state,
                hosting_cancel_status: 'error',
                hosting_cancel_error: action.hosting_cancel_error
            };

        /**********************************************************************************************************
         *   RESET
         **********************************************************************************************************/
        case HOSTING_CONFIGURATION_REQUEST_RESET:
            return {
                ...state,
                hosting_tempurl_check_status: null,
                hosting_tempurl_check_error: null,
                hosting_tempurl_status: null,
                hosting_tempurl_error: null,
                hosting_ipv6_check_status: null,
                hosting_ipv6_check_error: null,
                hosting_ipv6_status: null,
                hosting_ipv6_error: null,
                hosting_database_list_status: null,
                hosting_database_list_error: null,
                hosting_database_status: null,
                hosting_database_error: null,
                hosting_sshaccess_list_status: null,
                hosting_sshaccess_list_error: null,
                hosting_sshaccess_status: null,
                hosting_sshaccess_error: null
            };

        /**********************************************************************************************************
         *   TEMPORARY URL
         **********************************************************************************************************/
        case HOSTING_TEMPURL_CHECK_REQUEST:
            return {
                ...state,
                hosting_tempurl_check_status: 'loading',
                hosting_tempurl_check_data: null,
                hosting_tempurl_check_error: null
            };

        case HOSTING_TEMPURL_CHECK_SUCCESS:
            return {
                ...state,
                hosting_tempurl_check_status: 'success',
                hosting_tempurl_check_data: action.hosting_tempurl_check_data
            };

        case HOSTING_TEMPURL_CHECK_FAIL:
            return {
                ...state,
                hosting_tempurl_check_status: 'error',
                hosting_tempurl_check_error: action.hosting_tempurl_check_error
            };

        case HOSTING_TEMPURL_REQUEST:
            return {
                ...state,
                hosting_tempurl_status: 'loading',
                hosting_tempurl_data: null,
                hosting_tempurl_error: null
            };

        case HOSTING_TEMPURL_SUCCESS:
            return {
                ...state,
                hosting_tempurl_status: 'success',
                hosting_tempurl_data: action.hosting_tempurl_data
            };

        case HOSTING_TEMPURL_FAIL:
            return {
                ...state,
                hosting_tempurl_status: 'error',
                hosting_tempurl_error: action.hosting_tempurl_error
            };

        /**********************************************************************************************************
         *   IPV6 MANAGEMENT
         **********************************************************************************************************/
        case HOSTING_IPV6_CHECK_REQUEST:
            return {
                ...state,
                hosting_ipv6_check_status: 'loading',
                hosting_ipv6_check_data: null,
                hosting_ipv6_check_error: null
            };

        case HOSTING_IPV6_CHECK_SUCCESS:
            return {
                ...state,
                hosting_ipv6_check_status: 'success',
                hosting_ipv6_check_data: action.hosting_ipv6_check_data
            };

        case HOSTING_IPV6_CHECK_FAIL:
            return {
                ...state,
                hosting_ipv6_check_status: 'error',
                hosting_ipv6_check_error: action.hosting_ipv6_check_error
            };

        case HOSTING_IPV6_REQUEST:
            return {
                ...state,
                hosting_ipv6_status: 'loading',
                hosting_ipv6_data: null,
                hosting_ipv6_error: null
            };

        case HOSTING_IPV6_SUCCESS:
            return {
                ...state,
                hosting_ipv6_status: 'success',
                hosting_ipv6_data: action.hosting_ipv6_data
            };

        case HOSTING_IPV6_FAIL:
            return {
                ...state,
                hosting_ipv6_status: 'error',
                hosting_ipv6_error: action.hosting_ipv6_error
            };

        /**********************************************************************************************************
         *   CHECK IP TYPE
         **********************************************************************************************************/
        case HOSTING_CHECK_IP_REQUEST:
            return {
                ...state,
                hosting_check_ip_status: 'loading',
                hosting_check_ip_data: null
            };

        case HOSTING_CHECK_IP_SUCCESS:
            return {
                ...state,
                hosting_check_ip_status: 'success',
                hosting_check_ip_data: action.hosting_check_ip_data
            };

        /**********************************************************************************************************
         *   DATABASE ACCESS
         **********************************************************************************************************/
        case HOSTING_DATABASE_LIST_REQUEST:
            return {
                ...state,
                hosting_database_list_status: 'loading',
                hosting_database_list_data: null,
                hosting_database_list_meta: null,
                hosting_database_list_error: null
            };

        case HOSTING_DATABASE_LIST_SUCCESS:
            return {
                ...state,
                hosting_database_list_status: 'success',
                hosting_database_list_data: action.hosting_database_list_data,
                hosting_database_list_meta: action.hosting_database_list_meta
            };

        case HOSTING_DATABASE_LIST_FAIL:
            return {
                ...state,
                hosting_database_list_status: 'error',
                hosting_database_list_error: action.hosting_database_list_error
            };

        case HOSTING_DATABASE_REQUEST:
            return {
                ...state,
                hosting_database_status: 'loading',
                hosting_database_data: null,
                hosting_database_error: null
            };

        case HOSTING_DATABASE_SUCCESS:
            return {
                ...state,
                hosting_database_status: 'success',
                hosting_database_data: action.hosting_database_data
            };

        case HOSTING_DATABASE_FAIL:
            return {
                ...state,
                hosting_database_status: 'error',
                hosting_database_error: action.hosting_database_error
            };

        /**********************************************************************************************************
         *   SSH ACCESS
         **********************************************************************************************************/
        case HOSTING_SSHACCESS_LIST_REQUEST:
            return {
                ...state,
                hosting_sshaccess_list_status: 'loading',
                hosting_sshaccess_list_data: null,
                hosting_sshaccess_list_meta: null,
                hosting_sshaccess_list_error: null
            };

        case HOSTING_SSHACCESS_LIST_SUCCESS:
            return {
                ...state,
                hosting_sshaccess_list_status: 'success',
                hosting_sshaccess_list_data: action.hosting_sshaccess_list_data,
                hosting_sshaccess_list_meta: action.hosting_sshaccess_list_meta
            };

        case HOSTING_SSHACCESS_LIST_FAIL:
            return {
                ...state,
                hosting_sshaccess_list_status: 'error',
                hosting_sshaccess_list_error: action.hosting_sshaccess_list_error
            };

        case HOSTING_SSHACCESS_REQUEST:
            return {
                ...state,
                hosting_sshaccess_status: 'loading',
                hosting_sshaccess_data: null,
                hosting_sshaccess_error: null
            };

        case HOSTING_SSHACCESS_SUCCESS:
            return {
                ...state,
                hosting_sshaccess_status: 'success',
                hosting_sshaccess_data: action.hosting_sshaccess_data
            };

        case HOSTING_SSHACCESS_FAIL:
            return {
                ...state,
                hosting_sshaccess_status: 'error',
                hosting_sshaccess_error: action.hosting_sshaccess_error
            };

        /**********************************************************************************************************
         *   SUBACCOUNT LOGIN
         **********************************************************************************************************/
        case HOSTING_SUBACCOUNT_INFO_RESET:
            return {
                ...state,
                hosting_subaccount_username: null
            };

        case HOSTING_SUBACCOUNT_USERNAME:
            return {
                ...state,
                hosting_subaccount_username: action.hosting_subaccount_username
            };

        case HOSTING_SUBACCOUNT_META:
            return {
                ...state,
                hosting_subaccount_meta: action.hosting_subaccount_meta
            };

        /**********************************************************************************************************
         *   SUBACCOUNT LOGIN
         **********************************************************************************************************/
        case HOSTING_CPANEL_SUBACCOUNT_REQUEST:
            return {
                ...state,
                hosting_cpanel_subaccount_status: 'loading',
                hosting_cpanel_subaccount_data: null,
                hosting_cpanel_subaccount_error: null
            };

        case HOSTING_CPANEL_SUBACCOUNT_SUCCESS:
            return {
                ...state,
                hosting_cpanel_subaccount_status: 'success',
                hosting_cpanel_subaccount_data: action.hosting_cpanel_subaccount_data
            };

        case HOSTING_CPANEL_SUBACCOUNT_FAIL:
            return {
                ...state,
                hosting_cpanel_subaccount_status: 'error',
                hosting_cpanel_subaccount_error: action.hosting_cpanel_subaccount_error
            };

        /**********************************************************************************************************
         *   RESET
         **********************************************************************************************************/
        case HOSTING_SECURITY_REQUEST_RESET:
            return {
                ...state,
                hosting_security_status: null,
                hosting_security_error: null,
                hosting_autossl_check_status: null,
                hosting_autossl_check_error: null,
                hosting_file_quarantine_status: null,
                hosting_file_quarantine_error: null,
                hosting_modsec_status: null,
                hosting_modsec_error: null,
                hosting_permissions_status: null,
                hosting_permissions_error: null,
                hosting_firewall_check_status: null,
                hosting_firewall_check_error: null,
                hosting_firewall_external_check_status: null,
                hosting_firewall_external_check_error: null,
                hosting_firewall_unblock_status: null,
                hosting_firewall_unblock_error: null
            };

        /**********************************************************************************************************
         *   AUTOSSL CHECK
         **********************************************************************************************************/
        case HOSTING_AUTOSSL_CHECK_REQUEST:
            return {
                ...state,
                hosting_autossl_check_status: 'loading',
                hosting_autossl_check_data: null,
                hosting_autossl_check_error: null
            };

        case HOSTING_AUTOSSL_CHECK_SUCCESS:
            return {
                ...state,
                hosting_autossl_check_status: 'success',
                hosting_autossl_check_data: action.hosting_autossl_check_data
            };

        case HOSTING_AUTOSSL_CHECK_FAIL:
            return {
                ...state,
                hosting_autossl_check_status: 'error',
                hosting_autossl_check_error: action.hosting_autossl_check_error
            };

        /**********************************************************************************************************
         *   PERMISSIONS FIX
         **********************************************************************************************************/
        case HOSTING_PERMISSIONS_REQUEST:
            return {
                ...state,
                hosting_permissions_status: 'loading',
                hosting_permissions_data: null,
                hosting_permissions_error: null
            };

        case HOSTING_PERMISSIONS_SUCCESS:
            return {
                ...state,
                hosting_permissions_status: 'success',
                hosting_permissions_data: action.hosting_permissions_data
            };

        case HOSTING_PERMISSIONS_FAIL:
            return {
                ...state,
                hosting_permissions_status: 'error',
                hosting_permissions_error: action.hosting_permissions_error
            };

        /**********************************************************************************************************
         *   FILE QUARANTINE
         **********************************************************************************************************/
        case HOSTING_FILE_QUARANTINE_REQUEST:
            return {
                ...state,
                hosting_file_quarantine_status: 'loading',
                hosting_file_quarantine_data: null,
                hosting_file_quarantine_error: null
            };

        case HOSTING_FILE_QUARANTINE_SUCCESS:
            return {
                ...state,
                hosting_file_quarantine_status: 'success',
                hosting_file_quarantine_data: action.hosting_file_quarantine_data
            };

        case HOSTING_FILE_QUARANTINE_FAIL:
            return {
                ...state,
                hosting_file_quarantine_status: 'error',
                hosting_file_quarantine_error: action.hosting_file_quarantine_error
            };

        /**********************************************************************************************************
         *   MODSEC
         **********************************************************************************************************/
        case HOSTING_MODSEC_REQUEST:
            return {
                ...state,
                hosting_modsec_status: 'loading',
                hosting_modsec_data: null,
                hosting_modsec_error: null
            };

        case HOSTING_MODSEC_SUCCESS:
            return {
                ...state,
                hosting_modsec_status: 'success',
                hosting_modsec_data: action.hosting_modsec_data
            };

        case HOSTING_MODSEC_FAIL:
            return {
                ...state,
                hosting_modsec_status: 'error',
                hosting_modsec_error: action.hosting_modsec_error
            };

        /**********************************************************************************************************
         *   FIREWALL
         **********************************************************************************************************/
        case HOSTING_FIREWALL_CHECK_REQUEST:
            return {
                ...state,
                hosting_firewall_check_status: 'loading',
                hosting_firewall_check_data: null,
                hosting_firewall_check_error: null
            };

        case HOSTING_FIREWALL_CHECK_SUCCESS:
            return {
                ...state,
                hosting_firewall_check_status: 'success',
                hosting_firewall_check_data: action.hosting_firewall_check_data
            };

        case HOSTING_FIREWALL_CHECK_FAIL:
            return {
                ...state,
                hosting_firewall_check_status: 'error',
                hosting_firewall_check_error: action.hosting_firewall_check_error
            };

        case HOSTING_FIREWALL_EXTERNAL_CHECK_REQUEST:
            return {
                ...state,
                hosting_firewall_external_check_status: 'loading',
                hosting_firewall_external_check_data: null,
                hosting_firewall_external_check_error: null
            };

        case HOSTING_FIREWALL_EXTERNAL_CHECK_SUCCESS:
            return {
                ...state,
                hosting_firewall_external_check_status: 'success',
                hosting_firewall_external_check_data: action.hosting_firewall_external_check_data
            };

        case HOSTING_FIREWALL_EXTERNAL_CHECK_FAIL:
            return {
                ...state,
                hosting_firewall_external_check_status: 'error',
                hosting_firewall_external_check_error: action.hosting_firewall_external_check_error
            };

        case HOSTING_FIREWALL_UNBLOCK_REQUEST:
            return {
                ...state,
                hosting_firewall_unblock_status: 'loading',
                hosting_firewall_unblock_data: null,
                hosting_firewall_unblock_error: null
            };

        case HOSTING_FIREWALL_UNBLOCK_SUCCESS:
            return {
                ...state,
                hosting_firewall_unblock_status: 'success',
                hosting_firewall_unblock_data: action.hosting_firewall_unblock_data
            };

        case HOSTING_FIREWALL_UNBLOCK_FAIL:
            return {
                ...state,
                hosting_firewall_unblock_status: 'error',
                hosting_firewall_unblock_error: action.hosting_firewall_unblock_error
            };

        /**********************************************************************************************************
         *   HOSTING RESOURCE
         **********************************************************************************************************/
        case HOSTING_RESOURCE_REQUEST:
            return {
                ...state,
                hosting_resource_status: 'loading',
                hosting_resource_data: null,
                hosting_resource_error: null
            };

        case HOSTING_RESOURCE_SUCCESS:
            return {
                ...state,
                hosting_resource_status: 'success',
                hosting_resource_data: action.hosting_resource_data
            };

        case HOSTING_RESOURCE_ERROR:
            return {
                ...state,
                hosting_resource_status: 'error',
                hosting_resource_error: action.hosting_resource_error
            };

        case HOSTING_RESOURCE_CONFIG_REQUEST:
            return {
                ...state,
                hosting_resource_config_status: 'loading',
                hosting_resource_config_data: null,
                hosting_resource_config_error: null
            };

        case HOSTING_RESOURCE_CONFIG_SUCCESS:
            return {
                ...state,
                hosting_resource_config_status: 'success',
                hosting_resource_config_data: action.hosting_resource_config_data
            };

        case HOSTING_RESOURCE_CONFIG_ERROR:
            return {
                ...state,
                hosting_resource_config_status: 'error',
                hosting_resource_config_error: action.hosting_resource_config_error
            };

        case HOSTING_RESOURCE_UPDATE_REQUEST:
            return {
                ...state,
                hosting_resource_update_status: 'loading',
                hosting_resource_update_data: null,
                hosting_resource_update_error: null
            };

        case HOSTING_RESOURCE_UPDATE_SUCCESS:
            return {
                ...state,
                hosting_resource_update_status: 'success',
                hosting_resource_update_data: action.hosting_resource_update_data
            };

        case HOSTING_RESOURCE_UPDATE_ERROR:
            return {
                ...state,
                hosting_resource_update_status: 'error',
                hosting_resource_update_error: action.hosting_resource_update_error
            };

        /**********************************************************************************************************
         *   HOSTING ADDONS
         **********************************************************************************************************/
        case HOSTING_ADDON_LIST_REQUEST:
            return {
                ...state,
                hosting_addons_list_status: 'loading',
                hosting_addons_list_data: null,
                hosting_addons_list_error: null
            };

        case HOSTING_ADDON_LIST_SUCCESS:
            return {
                ...state,
                hosting_addons_list_status: 'success',
                hosting_addons_list_data: action.hosting_addons_list_data
            };

        case HOSTING_ADDON_LIST_ERROR:
            return {
                ...state,
                hosting_addons_list_status: 'error',
                hosting_addons_list_error: action.hosting_addons_list_error
            };

        case HOSTING_ADDON_REMOVE_REQUEST:
            return {
                ...state,
                hosting_addon_remove_status: 'loading',
                hosting_addon_remove_data: null,
                hosting_addon_remove_error: null
            };

        case HOSTING_ADDON_REMOVE_SUCCESS:
            return {
                ...state,
                hosting_addon_remove_status: 'success',
                hosting_addon_remove_data: action.hosting_addon_remove_data
            };

        case HOSTING_ADDON_REMOVE_ERROR:
            return {
                ...state,
                hosting_addon_remove_status: 'error',
                hosting_addon_remove_error: action.hosting_addon_remove_error
            };

        case HOSTING_ADDON_UPDATE_REQUEST:
            return {
                ...state,
                hosting_addon_update_status: 'loading',
                hosting_addon_update_data: null,
                hosting_addon_update_error: null
            };

        case HOSTING_ADDON_UPDATE_SUCCESS:
            return {
                ...state,
                hosting_addon_update_status: 'success',
                hosting_addon_update_data: action.hosting_addon_update_data
            };

        case HOSTING_ADDON_UPDATE_ERROR:
            return {
                ...state,
                hosting_addon_update_status: 'error',
                hosting_addon_update_error: action.hosting_addon_update_error
            };

        case HOSTING_ADDON_CANCEL_REQUEST:
            return {
                ...state,
                hosting_addon_cancel_status: 'loading',
                hosting_addon_cancel_data: null,
                hosting_addon_cancel_error: null
            };

        case HOSTING_ADDON_CANCEL_SUCCESS:
            return {
                ...state,
                hosting_addon_cancel_status: 'success',
                hosting_addon_cancel_data: action.hosting_addon_cancel_data
            };

        case HOSTING_ADDON_CANCEL_ERROR:
            return {
                ...state,
                hosting_addon_cancel_status: 'error',
                hosting_addon_cancel_error: action.hosting_addon_cancel_error
            };

        case HOSTING_ADDON_KEEP_REQUEST:
            return {
                ...state,
                hosting_addon_keep_status: 'loading',
                hosting_addon_keep_data: null,
                hosting_addon_keep_error: null
            };

        case HOSTING_ADDON_KEEP_SUCCESS:
            return {
                ...state,
                hosting_addon_keep_status: 'success',
                hosting_addon_keep_data: action.hosting_addon_keep_data
            };

        case HOSTING_ADDON_KEEP_ERROR:
            return {
                ...state,
                hosting_addon_keep_status: 'error',
                hosting_addon_keep_error: action.hosting_addon_keep_error
            };

        default:
            return state;
    }
};

export default hostingReducer;

import { Store } from '@tanstack/react-store';
import type { KatanaNamespace } from 'containers/katana/types';
import _ from 'lodash';
import { validatorSocialMediaURLS } from 'utilities/methods/validators';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/

export const navigationLabelsStore = new Store<KatanaNamespace.NavigationLabel[]>([]);
export const navigationLabelsFormStore = new Store<KatanaNamespace.NavigationLabel[]>([]);
export const socialFormatValidations = (social: string): ValidatorFunction[] => {
    const titleCaseSocial = _.upperFirst(social);
    function getURLFromSocial() {
        switch (social) {
            case 'twitter':
                return ['x', 'twitter'];

            default:
                return social;
        }
    }

    function validSocialDomainURLS(value: string) {
        const domain = getURLFromSocial();
        const domainValue = _.isArray(domain) ? domain : [domain];
        return domainValue.some((domain) => {
            return value.startsWith(`https://${domain}.com/`) || value.startsWith(`https://www.${domain}.com/`);
        });
    }

    return [
        (value: string) => {
            const validSocialLink = social === 'twitter' ? value.includes(`twitter`) || value.includes('x') : value.includes(social);
            return !value || validSocialLink ? undefined : `Please enter a valid ${titleCaseSocial} URL`;
        },
        (value: string) =>
            !value || value.toLowerCase().startsWith('https://') ? undefined : `Please include https:// in the ${titleCaseSocial} URL`,
        (value: string) => {
            const validationResult = validSocialDomainURLS(value);
            const hasValue = Boolean(value);

            return !hasValue || validationResult ? undefined : `Please enter a valid ${titleCaseSocial} URL`;
        },
        (value: string) => {
            const validationResult = validatorSocialMediaURLS(value);
            const hasValue = Boolean(value);

            return !hasValue || validationResult ? undefined : `Please enter a valid ${titleCaseSocial} URL`;
        }
    ];
};

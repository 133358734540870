/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { has } from 'lodash';
import queryClient from 'store/queryClient';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { cloneObject, getDataFromSuccessResponse } from 'utilities/methods/commonActions';

/**
 * Checks if a query response is an infinite query.
 * @param {Object} queryResponse - The query response object.
 * @returns {boolean} - Whether the query response is an infinite query or not.
 */
function isInfiniteQuery(queryResponse) {
    return has(queryResponse, 'pages');
}

/**
 * Modifies the invoice data in the query response.
 * Cancels the query, sets the updated invoice data, and invalidates the query.
 * @param {import('@tanstack/react-query').QueryKey} queryKey - The query key.
 * @param {number|null} pageIndex - The index of the page containing the invoice data (or null for regular queries).
 * @param {function} findInvoiceFilter - The function to filter and find the specific invoice.
 */
async function modifyInvoiceData(queryKey, pageIndex, findInvoiceFilter) {
    await queryClient.cancelQueries({ queryKey }); // Cancel the query before modifying the data

    queryClient.setQueryData(queryKey, (oldQueryResponse) => {
        if (!oldQueryResponse) return;
        const clonedOldQueryResponse = cloneObject(oldQueryResponse);
        const invoice =
            pageIndex !== null
                ? clonedOldQueryResponse?.pages?.[pageIndex]?.data?.find(findInvoiceFilter)
                : clonedOldQueryResponse?.data?.find(findInvoiceFilter);
        if (invoice) {
            invoice.isDataUpdating = true;
        }

        return clonedOldQueryResponse;
    });

    queryClient.invalidateQueries({ queryKey }); // Invalidate the query
}

/**
 * Handles an infinite query by finding the page containing the invoice data and modifying it.
 * @param {import('@tanstack/react-query').QueryKey} queryKey - The query key.
 * @param {Object} queryResponse - The query response object.
 * @param {function} findInvoiceFilter - The function to filter and find the specific invoice.
 */
function handleInfiniteQuery(queryKey, queryResponse, findInvoiceFilter) {
    const pageIndex = queryResponse.pages?.findIndex((page) => {
        const pageData = getDataFromSuccessResponse(page);
        return pageData?.find?.(findInvoiceFilter);
    });

    if (pageIndex !== -1) {
        modifyInvoiceData(queryKey, pageIndex, findInvoiceFilter);
    }
}

/**
 * Handles a regular query by finding the invoice data and modifying it.
 * @param {import('@tanstack/react-query').QueryKey} queryKey - The query key.
 * @param {Object} queryResponse - The query response object.
 * @param {function} findInvoiceFilter - The function to filter and find the specific invoice.
 */
function handleRegularQuery(queryKey, queryResponse, findInvoiceFilter) {
    const queryResponseInvoice = getDataFromSuccessResponse(queryResponse)?.find?.(findInvoiceFilter);
    if (queryResponseInvoice) {
        modifyInvoiceData(queryKey, null, findInvoiceFilter);
    }
}

/**
 * Invalidates the optimistic single invoice query data by modifying the invoice and invalidating the associated queries.
 * @param {string[]} queryFilters - The query filters.
 * @param {string|number} invoiceID - The ID of the invoice to invalidate.
 */
export function handleInvalidateOptimisticSingleInvoiceQueryData(queryFilters, invoiceID) {
    // IMPROVE: onMutate, onSettled, Restore Cache based on context.
    const queriesData = queryClient.getQueriesData({ queryKey: queryFilters });
    const findInvoiceFilter = ({ id } = {}) => {
        return Number(id) === Number(invoiceID);
    };
    queriesData?.forEach(([queryKey, queryResponse]) => {
        if (!queryResponse) return;

        if (isInfiniteQuery(queryResponse)) {
            handleInfiniteQuery(queryKey, queryResponse, findInvoiceFilter);
        } else {
            handleRegularQuery(queryKey, queryResponse, findInvoiceFilter);
        }
    });
}

/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
export const PAYMENT_METHODS = {
    BPAY: 'BPAY',
    EFT: 'EFT',
    EFT_TRANSFER: 'EFT Transfer',
    CREDIT_CARD: 'Credit Card',
    SAVED_CREDIT_CARD: 'Saved Credit Card',
    PAYPAL: 'PayPal',
    APPLY_CREDIT: 'Apply Credit'
};
export const PAYMENT_METHODS_VALUES = Object.values(PAYMENT_METHODS);

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import { KatanaColours, KatanaSectionIDHardCodedMetaData } from 'containers/katana/consts';

/**
 * @param {import('containers/katana/types').KatanaNamespace.SectionDefinitions.IDs} sectionID
 */
export function getKatanaSectionIDColour(sectionID) {
    return KatanaSectionIDHardCodedMetaData[sectionID]?.color ?? KatanaColours.AQUA;
}

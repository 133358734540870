/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { infiniteQueryOptions, useInfiniteQuery } from '@tanstack/react-query';
import { katanaQueryKeys } from 'containers/katana/queries/katanaQueryKeys';
import queryClient from 'store/queryClient';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { KATANA_API } from 'utilities/api/katana';
import type { KatanaAPI } from 'utilities/api/katana/types';
import { handleDefaultErrorNotification } from 'utilities/methods/commonActions';
import { getNextPageParamDefault } from 'utilities/methods/queries/getNextPageParamDefault';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type Filters = KatanaAPI.Katana.GET.Filters;

const baseFilter: Filters = {
    page: 1,
    sort: 'desc',
    // sort_by: 'id'
    record_per_page: 50
};

function createQueryKey(filters: Filters) {
    return katanaQueryKeys.katana.service.list(filters);
}

function createQueryOptions(filters: Filters) {
    /**
     * The order to which you pass the keys in MATTERS!!!
     * 1. queryKey
     * 2. queryFn
     * 3. initialPageParam
     * 4. getNextPageParam
     * 5. select
     */
    return infiniteQueryOptions({
        queryKey: createQueryKey(filters),
        queryFn: async (queryFunctionContext) => {
            const { pageParam = filters } = queryFunctionContext;
            return KATANA_API.katana.GET(pageParam).catch((e) => {
                handleDefaultErrorNotification(e);
                throw e;
            });
        },
        initialPageParam: filters,
        getNextPageParam: (lastPage) => {
            return getNextPageParamDefault({ lastPage, filters });
        },
        select: (data) => {
            const firstPageMeta = data?.pages?.[0]?.meta;
            const dataFlatMap = data.pages.map((page) => page.data);
            return {
                ...data,
                pages: dataFlatMap,
                meta: firstPageMeta
            };
        }
    });
}

function invalidateQueries(filters: Filters = baseFilter) {
    return queryClient.invalidateQueries({
        queryKey: createQueryKey(filters)
    });
}

/**
 * List Katana Sites
 */
function _useInfiniteQuery(filters: Filters = baseFilter) {
    return useInfiniteQuery(createQueryOptions(filters));
}

export const getSiteList = Object.freeze({
    useInfiniteQuery: _useInfiniteQuery,
    invalidateQueries,
    createQueryKey,
    createQueryOptions
});

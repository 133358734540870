/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { useIsMutating } from '@tanstack/react-query';
import React from 'react';
import { Field, reduxForm } from 'redux-form';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import CreditCardImage from 'components/CreditCardImage';
import RequestLoader from 'components/Loaders/Request';

/**********************************************************************************************************
 *   QUERIES
 **********************************************************************************************************/
import { PAY_INVOICE } from 'containers/billing/queries/invoice';
import { useGetUserPaymentMethodDataListQuery } from 'containers/billing/queries/useGetUserPaymentMethodDataListQuery';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import Text from 'components/Utils/Text';
import { renderButton, RenderSelectField, requiredFieldValidation } from 'utilities/methods/form';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import { useSelector } from 'react-redux';
import { PAYMENT_METHODS } from '../../invoice/paymentMethods/consts';
import './_savedCreditCardInvoiceForm.scss';
export const savedCreditCardInvoiceFormName = 'SavedCreditCardInvoiceForm';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
let SavedCreditCardInvoiceForm = ({
    handleSubmit,
    initialValues,
    /**
     * Redux Props
     */
    submitting,
    valid,
    form
}) => {
    /***** HOOKS *****/
    const currentCCID = useSelector((state) => state.form?.[form].values?.cc_id);

    /***** QUERIES *****/
    const { data: get_user_payment_method_data_list_data } = useGetUserPaymentMethodDataListQuery({
        select: useGetUserPaymentMethodDataListQuery.selectFilters.getPaymentMethodType(PAYMENT_METHODS.CREDIT_CARD)
    });

    /***** RENDER HELPERS *****/
    const isPayingAnInvoice = useIsMutating({ mutationKey: [PAY_INVOICE] }) > 0;

    const cardOptions = () => {
        return (
            get_user_payment_method_data_list_data?.map?.((creditCard) => {
                const { attributes, id } = creditCard;
                const { card_type, identifier, is_expired } = attributes;

                return {
                    label: (
                        <Text capitalize className="savedCreditCardInvoiceForm__cardOptionsLabel" span warn={is_expired}>
                            {card_type} **** **** **** {identifier} {is_expired && '- Expired'}
                        </Text>
                    ),
                    value: id,
                    disabled: is_expired,
                    icon: <CreditCardImage className="cc_icon saved_credit_card__icon" cardType={card_type} />
                };
            }) ?? []
        );
    };

    function isSelectedCardExpired() {
        const selectedCard = get_user_payment_method_data_list_data?.find?.(({ id }) => id === currentCCID);
        return selectedCard?.attributes?.is_expired;
    }

    /***** RENDER *****/
    return (
        <div className="creditCard__wrapper">
            <form className="OverlayInvoice__form" onSubmit={handleSubmit}>
                <div className="form__content">
                    <div className="form__row">
                        <div className="form__column full">
                            <Field
                                name="cc_id"
                                className="savedCreditCardInvoiceForm__CCIDSelectField"
                                component={RenderSelectField}
                                validate={[requiredFieldValidation]}
                                disabled={isPayingAnInvoice}
                                type="select"
                                label="Card Type"
                                options={cardOptions()}
                            />
                        </div>
                    </div>
                    <div className="form__row">
                        <div className="form__column full">
                            {renderButton(
                                false,
                                submitting,
                                valid && !isPayingAnInvoice && !isSelectedCardExpired(),
                                isPayingAnInvoice ? <RequestLoader /> : 'Confirm and Pay'
                            )}
                        </div>
                    </div>
                </div>
            </form>
        </div>
    );
};

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

SavedCreditCardInvoiceForm = reduxForm({
    form: savedCreditCardInvoiceFormName,
    enableReinitialize: true
})(SavedCreditCardInvoiceForm);

export default SavedCreditCardInvoiceForm;

/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import _ from 'lodash';

/**
 * @description Omit properties from an object and return a new object.
 *
 * _Correct Return Type TBA_
 */
export function deepOmit<TObject extends object>(input: TObject, excludes: Array<string | number>): TObject {
    const omit = [];
    const clone = _.cloneDeepWith(input, (value, key, object, stack) => {
        if (!_.isUndefined(key) && excludes.includes(key)) {
            omit.push({ key, from: stack.get(object) });
            return null;
        }
    });
    omit.forEach(({ key, from }) => _.unset(from, key));
    return clone;
}

/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { useMutation } from '@tanstack/react-query';
import type { KatanaNamespace } from 'containers/katana/types';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { KATANA_API } from 'utilities/api/katana';
import type { NXQueryUtils } from 'utilities/methods/tanstack/types';

type MutationOptions = NXQueryUtils.TMutationOptions<typeof KATANA_API.katana.unsplash.download.trigger.POST>;

function _useMutation(serviceID: KatanaNamespace.ServiceID, options: MutationOptions) {
    function mutationFn(downloadLinks: string[]) {
        return KATANA_API.katana.unsplash.download.trigger.POST(serviceID, downloadLinks);
    }

    return useMutation({
        mutationFn,
        ...options
    });
}

export const triggerUnsplashDownload = Object.freeze({
    useMutation: _useMutation
});

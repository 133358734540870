/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React from 'react';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import Text from 'components/Utils/Text';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type RewardCardBodyComponent = React.FC<{
    title: string;
    description: string;
    subDescription?: React.ReactNode;
    children: React.ReactNode;
}>;

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const RewardCardBody: RewardCardBodyComponent = ({ title, description, subDescription, children }) => {
    /***** RENDER *****/
    return (
        <div className="rewardCard__main">
            <div>
                <Text primary semiBold lead--s size--l>
                    <h4>{title}</h4>
                </Text>
                <Text secondary lead--s size--s>
                    {description}
                </Text>
                {subDescription}
            </div>
            {children}
        </div>
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import page404 from 'assets/images/404_page.png';
import SolidButton from '../../Buttons/SolidButton';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import './_PageNotFound.scss';

/**
 * This component is used to display a 404 page not found message to users with a button to return to the dashboard.
 */
const PageNotFound = () => {
    return (
        <div className="PageNotFound">
            <div className="container">
                <div className="PageNotFound__wrapper">
                    <img src={page404} alt="" className="image_404" />
                    <div className="heading">404</div>
                    <div className="description">Please check the URL or contact our Customer Support Team</div>
                    <SolidButton color="secondary" to="/dashboard">
                        Go back to the Dashboard
                    </SolidButton>
                </div>
            </div>
        </div>
    );
};
export default PageNotFound;

/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React from 'react';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import RequestLoader from 'components/Loaders/Request';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import './_IsDataUpdatingOverlay.scss';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * @param {IsDataUpdatingOverlayProps} props
 */
function _IsDataUpdatingOverlay({ fillHeight, height, children, isDataUpdating, message = 'Updating Data...', Variant = RequestLoader }) {
    /***** RENDER *****/
    if (!isDataUpdating) {
        return children;
    }
    return (
        <div className="IsDataUpdatingOverlay">
            <div className="IsDataUpdatingOverlay__children">{children}</div>
            <Variant message={message} fillHeight={fillHeight} height={height} />
        </div>
    );
}

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
export { _IsDataUpdatingOverlay };

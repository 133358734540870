import { getMetaFromData } from '../commonActions';

/**
 * @template TLastPage
 * @template TFilters
 * @param {{
 *      lastPage: TLastPage,
 *      filters: TFilters
 * }} param
 */
export function getNextPageParamDefault({ lastPage, filters }) {
    const lastPageMeta = getMetaFromData(lastPage);
    if (lastPageMeta?.current_page < lastPageMeta?.total_pages) {
        const nextPageParams = {
            ...filters,
            page: lastPageMeta.current_page + 1
        };

        return nextPageParams;
    }
    return undefined;
}

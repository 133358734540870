/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/

export const imageAcceptsTypes = {
    'image/jpeg': ['.jpeg', '.jpg'],
    'image/png': ['.png'],
    'image/gif': ['.gif'],
    'image/webp': ['.webp']
};

export const defaultAccepts = {
    ...imageAcceptsTypes,
    'text/*': ['.csv', '.txt'],
    'application/pdf': ['.pdf'],
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
    'application/x-unknown': ['.cer', '.crt', '.key', '.pem']
};

export const MAX_FILE_SIZE = 5_000_000;

/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import classNames from 'classnames';
import { eft_details } from 'config/config';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import { Radio } from 'components/Form/Radio';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { useUserData } from 'utilities/hooks/redux/useUserData';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
import type { SelectedPaymentMethodProps } from 'components/Lightboxes/OverlayLightbox/Components/invoice/paymentMethods/types';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import eftImage from 'assets/images/eft.jpeg';
import { PAYMENT_METHODS } from './consts';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const EFTPaymentMethod: React.FC<SelectedPaymentMethodProps> = ({ selectedPaymentMethod, setSelectedPaymentMethod }) => {
    /***** HOOKS *****/
    const app_user_data = useUserData();

    /***** RENDER HELPERS *****/
    const isEFTSelected = selectedPaymentMethod === PAYMENT_METHODS.EFT;

    /***** RENDER *****/
    return (
        <div key="eft" className={classNames('method', { 'method--selected': isEFTSelected })}>
            <Radio.Circle.Button
                className="method__top"
                isActive={isEFTSelected}
                color="primary"
                onClick={() => setSelectedPaymentMethod(PAYMENT_METHODS.EFT)}
            >
                <div className="method__top--title">
                    <div className="label">EFT Transfer</div>
                </div>
                <div className="method__top--img">
                    <img className="cc_icon eft" src={eftImage} alt="eft" />
                </div>
            </Radio.Circle.Button>
            {isEFTSelected ? (
                <div className="method__bottom eft__wrapper">
                    <div className="eft__row">
                        <div className="label">Account Name</div>
                        <div className="value">{eft_details.name}</div>
                    </div>
                    <div className="eft__row">
                        <div className="label">BSB</div>
                        <div className="value">{eft_details.bsb}</div>
                    </div>
                    <div className="eft__row">
                        <div className="label">Account Number</div>
                        <div className="value">{eft_details.account}</div>
                    </div>
                    <div className="eft__row">
                        <div className="label">Description</div>
                        <div className="value desc">{app_user_data.account_number}</div>
                    </div>
                    <div className="eft__note note">
                        Please note we recommend allowing 1-2 business days for payments to process (this may be further delayed by weekends and
                        public holidays).
                    </div>
                </div>
            ) : (
                ''
            )}
        </div>
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { useNavigate, useParams } from '@tanstack/react-router';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import PageNotFound from 'components/Errors/PageNotFound';
import RequestLoader from 'components/Loaders/Request';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { pushNotification } from 'components/Toast/functions';
import { getAstroVPSInfo, getSolusVPSInfo } from 'containers/vps/action';
import { vpsMethods } from 'containers/vps/methods';
import { useDispatch } from 'react-redux';
import { handleRedirect } from 'utilities/methods/redirects/methods';
import { redirectUrls } from 'utilities/methods/redirects/redirectUrls';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * Wrapper component for the VPS route outlet. This component ensures that until the vps_information_data is present, a loading
 * state is shown and no children routes are rendered. Some children routes expect the vps_information_data to be present before
 * rendering.
 *
 * This component also handles redirects relating to the response from this endpoint.
 */
export const VPSRouteLoader = ({ children }) => {
    /***** SELECTORS *****/
    const vps_information_status = useSelector((state: any) => state.vps.vps_information_status);
    const vps_information_error = useSelector((state: any) => state.vps.vps_information_error);
    const vps_information_data = useSelector((state: any) => state.vps.vps_information_data);

    /***** HOOKS *****/
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { vpsId } = useParams({ from: '/my-services/vps/$vpsId' });

    /***** EFFECTS *****/
    useEffect(() => {
        if (vps_information_error?.status === 403) {
            navigate({ to: redirectUrls.vps });
            pushNotification(vps_information_error);
        } else if (vps_information_data) {
            handleRedirect({
                serviceType: 'vps',
                status: vps_information_data?.attributes?.status,
                domainName: vps_information_data?.attributes?.domain,
                history: {
                    push: (to) => navigate({ to })
                }
            });
        }
    }, [vps_information_error]);

    useEffect(() => {
        if (vps_information_status && vps_information_data) {
            const { domain_status, domain } = vps_information_data.attributes;

            handleRedirect({
                serviceType: 'vps',
                status: domain_status,
                domainName: domain,
                history: {
                    push: (to) => navigate({ to })
                }
            });

            if (vpsMethods.getVpsServerType(vps_information_data) === 'astro') {
                getAstroVPSInfo(vpsId)(dispatch);
            } else {
                getSolusVPSInfo(vpsId)(dispatch);
            }
        }
    }, [vps_information_status]);

    /***** RENDER *****/
    switch (vps_information_status) {
        case 'success':
            return children;
        case 'error':
            return <PageNotFound />;
        case 'loading':
        default:
            return <RequestLoader />;
    }
};

/**
 * Modifies specific strings to include HTML formatting
 * @param note{string}
 * @return {string}
 */
export function modifyRenderButtonNote(note) {
    switch (note) {
        case 'Domain is currently in redemption grace period, please contact support to restore.':
            return 'Domain is currently in redemption grace period,<br />please contact support to restore.';
        default:
            return note;
    }
}

/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { useStore } from '@tanstack/react-store';
import { Store } from '@tanstack/store';
import type { KatanaNamespace } from 'containers/katana/types';
import _ from 'lodash';
import { useEffect } from 'react';

const _newSectionOrganiserData = new Store<Record<KatanaNamespace.SectionID, true>>({});

/**
 * This hook checks if the provided id is in the new data and returns a boolean
 */
function useIsNewSectionData(id: KatanaNamespace.SectionID) {
    /***** HOOKS *****/
    const newData = useStore(_newSectionOrganiserData);
    /***** HOOK RESULTS *****/
    return Boolean(newData[id]);
}

function addNewSectionOrganiserID(id: KatanaNamespace.SectionID) {
    _newSectionOrganiserData.setState((value) => ({ ...value, [id]: true }));
}
function removeNewSectionOrganiserID(id: KatanaNamespace.SectionID) {
    _newSectionOrganiserData.setState((value) => ({ ...value, [id]: false }));
}

/**
 * When the hook mounts it checks if the id is in the "newSectionOrganiserData" writable, if it is, when the component unmounts, the value gets removed.
 */
function useNewSectionVisitedEffect(id: KatanaNamespace.SectionID | null) {
    /***** EFFECTS *****/
    useEffect(() => {
        const newData = _newSectionOrganiserData.state;
        if (id === null) {
            return;
        }
        if (newData[id]) {
            return () => {
                _newSectionOrganiserData.setState((data) => {
                    const cloned = _.clone(data);
                    delete cloned[id];
                    return cloned;
                });
            };
        }
    });
}

export const newSectionOrganiserData = Object.assign(_newSectionOrganiserData, {
    useIsNew: useIsNewSectionData,
    addNewID: addNewSectionOrganiserID,
    removeID: removeNewSectionOrganiserID,
    useVisited: useNewSectionVisitedEffect
});

/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React from 'react';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import './_limitedWidthContentModule.scss';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
const LimitedWidthContentModule: React.FC<{
    children: React.ReactNode;
}> = ({ children }) => <div className="LimitedWidthContentModule">{children}</div>;
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
export default LimitedWidthContentModule;

/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { createRoute, Outlet } from '@tanstack/react-router';
import { useStore } from '@tanstack/react-store';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import SideBarPage from 'components/SideBarPage';
import Transition from 'components/Transition';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import { MicrosoftTitle } from 'containers/ms365/components/title';

/**********************************************************************************************************
 *   ROUTE IMPORTS
 **********************************************************************************************************/
import { MyServicesRoute } from 'containers/services';
import { routerMiddleware } from 'router/utils/middleware';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import { MicrosoftModuleStore } from './moduleConditions';

/*   ACTIONS
 *****************************************************/
import { getMs365Service, getMs365UpgradePaths } from 'containers/ms365/action';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import RequestLoader from 'components/Loaders/Request';
import './_ms365.scss';

/**********************************************************************************************************
 *   ROUTE START
 **********************************************************************************************************/
export const MicrosoftManageRoute = createRoute({
    getParentRoute: () => MyServicesRoute,
    path: 'microsoft-365/$microsoftId',
    pendingComponent: RequestLoader,
    gcTime: 0,
    async loader({ context, params, cause }) {
        if (cause === 'enter') {
            await Promise.allSettled([
                getMs365Service(params.microsoftId)(context.store.dispatch),
                getMs365UpgradePaths(params.microsoftId)(context.store.dispatch)
            ]);
        }
    },
    beforeLoad(opts) {
        routerMiddleware.business({ path: '/my-services/microsoft-365' }, opts);
        routerMiddleware.authentication('user', opts);
        routerMiddleware.business(this, opts);
    },
    component: RouteComponent
});

/**********************************************************************************************************
 *   ROUTE COMPONENT START
 **********************************************************************************************************/
function RouteComponent() {
    /***** HOOKS *****/
    const store = useStore(MicrosoftModuleStore);
    MicrosoftModuleStore.useConditions();

    const [links] = SideBarPage.useGetSidebarLinks([
        {
            list_items: store.manage,
            list_title: 'Manage'
        },
        {
            list_items: store.admin,
            list_title: 'Admin'
        }
    ]);

    /***** RENDER *****/
    return (
        <>
            <MicrosoftTitle />
            <SideBarPage sidebarList={links}>
                <Transition>
                    <Outlet />
                </Transition>
            </SideBarPage>
        </>
    );
};

/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import _ from 'lodash';
import React, { useEffect } from 'react';
import classNames from 'classnames';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import { FormItem } from 'components/Form/FormItem';
import { FormItemInner } from 'components/Form/FormItemInner';
import { FormLabel } from 'components/Form/FormLabel';
import { KatanaFileFormFieldChooseStockPhotoDecoupled } from './KatanaFileFormFieldChooseStockPhotoDecoupled';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { isAttachmentDataLink, transformAttachmentDataToLink } from 'containers/katana/queries/methods/attachmentData';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import { sectionDefinitionUnsplashDownloadLinks } from 'containers/katana/containers/ContentEditorLightbox/formHandlers/consts';
import './_dropzone_or_stock_photo.scss';
import type { WrappedFieldInputProps, WrappedFieldMetaProps } from 'redux-form';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/

type KatanaFileFormFieldChooseStockPhotoReduxFormProps = {
    input: WrappedFieldInputProps;
    meta: WrappedFieldMetaProps;
    description: string;
    label: string;
};

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const KatanaFileFormFieldChooseStockPhotoReduxForm: React.FC<KatanaFileFormFieldChooseStockPhotoReduxFormProps> = ({
    /**
     * Inherited Props
     */
    description,
    label,
    /**
     * Redux Props
     */
    input,
    meta
}) => {
    const { name, value, onChange } = input;

    /***** FUNCTIONS *****/
    function handleOnChange(value: string) {
        onChange(transformAttachmentDataToLink(value));
    }

    function handleValueTransform(value: object | string) {
        if (isAttachmentDataLink(value)) {
            return value.url;
        }
        return value;
    }

    /***** EFFECTS *****/
    useEffect(() => {
        if (!value?.url?.includes('https://images.unsplash.com')) {
            sectionDefinitionUnsplashDownloadLinks.setState((prev) => {
                const clonedPrev = { ...prev };
                _.unset(clonedPrev, name);
                return clonedPrev;
            });
        }
    }, [value, name]);

    /***** RENDER *****/
    return (
        <FormItem
            name={name}
            className={classNames({
                'CardSelectGrid--hasDescription': description
            })}
        >
            <FormLabel htmlFor={name}>{label}</FormLabel>
            {description}
            <FormItemInner meta={meta} noBackground fullWidth>
                <KatanaFileFormFieldChooseStockPhotoDecoupled fieldName={name} onChange={handleOnChange} value={handleValueTransform(value)} />
            </FormItemInner>
        </FormItem>
    );
};

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

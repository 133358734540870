/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { createRouter as createTanstackRouter } from '@tanstack/react-router';

/**********************************************************************************************************
 *   ROUTE IMPORTS
 **********************************************************************************************************/
import { AppRoute } from 'App';
import PageNotFound from 'components/Errors/PageNotFound';
import RequestLoader from 'components/Loaders/Request';
import { AccountRoute } from 'containers/account';
import { AccountEmailRoute } from 'containers/account/routes/emails';
import { AccountGeneralRoute } from 'containers/account/routes/general';
import { AccountSecurityRoute } from 'containers/account/routes/security';
import { DirectAUApplicationRoute } from 'containers/auDirect/routes/domain.direct-au-applications';
import { BillingRoute } from 'containers/billing';
import { InvoiceRoute } from 'containers/billing/routes/invoice';
import { InvoiceIdRoute } from 'containers/billing/routes/invoice/$id';
import { InvoiceCancelRoute } from 'containers/billing/routes/invoice/cancel';
import { InvoicePayRoute } from 'containers/billing/routes/invoice/pay';
import { InvoicesRoute } from 'containers/billing/routes/invoices';
import { MergeInvoicesRoute } from 'containers/billing/routes/invoices-merge';
import { PaymentMethodRoute } from 'containers/billing/routes/payment-methods';
import { ServiceAlignmentRoute } from 'containers/billing/routes/service-alignment';
import { SLARoute } from 'containers/billing/routes/sla';
import { SplitInvoiceRoute } from 'containers/billing/routes/split-invoice';
import { WalletRoute } from 'containers/billing/routes/ventraip-wallet';
import { DashboardRoute } from 'containers/dashboard';
import { DomainManageRoute } from 'containers/domain';
import { LegacyDomainManageRoutes } from 'containers/domain/redirects';
import { DomainAdminRoute } from 'containers/domain/routes/domains.$domainId/admin';
import { DomainDNSRoute } from 'containers/domain/routes/domains.$domainId/dns';
import { DomainGeneralRoute } from 'containers/domain/routes/domains.$domainId/general';
import { DomainSecurityRoute } from 'containers/domain/routes/domains.$domainId/security';
import { LegacyDomainManageRoute } from 'containers/domain/tppDomains';
import { LegacyDomainGeneralRoute } from 'containers/domain/tppDomains/routes/general';
import { LegacyDomainSecurityRoute } from 'containers/domain/tppDomains/routes/security';
import { EmailManageRoute } from 'containers/email';
import { EmailHostingGeneralRoute } from 'containers/email/routes/email-hosting.$mailboxId/general';
import { EmailHostingMailboxRoute } from 'containers/email/routes/email-hosting.$mailboxId/mailbox.$mailboxId';
import { EmailHostingAdminMailboxRoute } from 'containers/email/routes/email-hosting.$mailboxId/mailbox.$mailboxId/admin';
import { EmailHostingGeneralMailboxRoute } from 'containers/email/routes/email-hosting.$mailboxId/mailbox.$mailboxId/general';
import { EmailHostingToolsRoute } from 'containers/email/routes/email-hosting.$mailboxId/tools';
import { GoogleManageRoute } from 'containers/gsuite';
import { GoogleAdminRoute } from 'containers/gsuite/routes/admin';
import { GoogleMailboxRoute } from 'containers/gsuite/routes/mailbox.$mailboxId';
import { GoogleMailboxManageRoute } from 'containers/gsuite/routes/mailbox.$mailboxId/manage';
import { GoogleManageManageRoute } from 'containers/gsuite/routes/manage';
import { GoogleSetupRoute } from 'containers/gsuite/routes/setup';
import { HostingManageRoute } from 'containers/hosting';
import { LegacyHostingManageRoutes, LegacyHostingSubaccountManageRoutes } from 'containers/hosting/redirects';
import { HostingAccountRoute } from 'containers/hosting/routes/account';
import { HostingAdminRoute } from 'containers/hosting/routes/admin';
import { HostingConfigurationRoute } from 'containers/hosting/routes/configuration';
import { HostingResellerRoute } from 'containers/hosting/routes/reseller';
import { HostingSecurityRoute } from 'containers/hosting/routes/security';
import { HostingSubaccountRoute } from 'containers/hosting/routes/subaccount.$subaccountId';
import { HostingSubaccountAccountRoute } from 'containers/hosting/routes/subaccount.$subaccountId/account';
import { HostingSubaccountConfigurationRoute } from 'containers/hosting/routes/subaccount.$subaccountId/configuration';
import { HostingSubaccountSecurityRoute } from 'containers/hosting/routes/subaccount.$subaccountId/security';
import { KatanaAuthorizeRoute } from 'containers/katana/routes/katana.authorize';
import { AuthorizeInstagramRoute } from 'containers/katana/routes/katana.authorize/instagram';
import { AuthorizeInstagramBusinessRoute } from 'containers/katana/routes/katana.authorize/instagram_business';
import { VIPSitesRoute } from 'containers/katana/routes/vip-sites.$katanaServiceId';
import { VIPSitesContentLightboxLayoutRoute } from 'containers/katana/routes/vip-sites.$katanaServiceId/_content-editor-lightbox';
import { VIPSitesAddSectionRoute } from 'containers/katana/routes/vip-sites.$katanaServiceId/_content-editor-lightbox/add-section';
import { VIPSitesEditorRoute } from 'containers/katana/routes/vip-sites.$katanaServiceId/_content-editor-lightbox/editor';
import { VIPSitesEditorBasicDetailsRoute } from 'containers/katana/routes/vip-sites.$katanaServiceId/_content-editor-lightbox/editor/basic-details';
import { VIPSitesEditorColoursFontsRoute } from 'containers/katana/routes/vip-sites.$katanaServiceId/_content-editor-lightbox/editor/colours-fonts';
import { VIPSitesEditorContactInformationRoute } from 'containers/katana/routes/vip-sites.$katanaServiceId/_content-editor-lightbox/editor/contact-information';
import { VIPSitesEditorFinaliseRoute } from 'containers/katana/routes/vip-sites.$katanaServiceId/_content-editor-lightbox/editor/finalise';
import { VIPSitesEditorFixDNSRoute } from 'containers/katana/routes/vip-sites.$katanaServiceId/_content-editor-lightbox/editor/fix-dns';
import { VIPSitesEditorLaunchRoute } from 'containers/katana/routes/vip-sites.$katanaServiceId/_content-editor-lightbox/editor/launch';
import { VIPSitesEditorNavigationAndSocialsRoute } from 'containers/katana/routes/vip-sites.$katanaServiceId/_content-editor-lightbox/editor/navigation-and-socials';
import { VIPSitesEditorTemplateContentRoute } from 'containers/katana/routes/vip-sites.$katanaServiceId/_content-editor-lightbox/editor/template-content';
import { VIPSitesEditorTemplateContentChildPageRoute } from 'containers/katana/routes/vip-sites.$katanaServiceId/_content-editor-lightbox/editor/template-content/$childPage';
import { VIPSitesSetupRoute } from 'containers/katana/routes/vip-sites.$katanaServiceId/_content-editor-lightbox/setup';
import { VIPSitesSetupBasicDetailsRoute } from 'containers/katana/routes/vip-sites.$katanaServiceId/_content-editor-lightbox/setup/basic-details';
import { VIPSitesSetupColoursFontsRoute } from 'containers/katana/routes/vip-sites.$katanaServiceId/_content-editor-lightbox/setup/colours-fonts';
import { VIPSitesSetupContactInformationRoute } from 'containers/katana/routes/vip-sites.$katanaServiceId/_content-editor-lightbox/setup/contact-information';
import { VIPSitesSetupFinaliseRoute } from 'containers/katana/routes/vip-sites.$katanaServiceId/_content-editor-lightbox/setup/finalise';
import { VIPSitesSetupLaunchRoute } from 'containers/katana/routes/vip-sites.$katanaServiceId/_content-editor-lightbox/setup/launch';
import { VIPSitesSetupNavigationAndSocialsRoute } from 'containers/katana/routes/vip-sites.$katanaServiceId/_content-editor-lightbox/setup/navigation-and-socials';
import { VIPSitesSetupRegisterDomainRoute } from 'containers/katana/routes/vip-sites.$katanaServiceId/_content-editor-lightbox/setup/register-domain';
import { VIPSitesSetupTemplateContentRoute } from 'containers/katana/routes/vip-sites.$katanaServiceId/_content-editor-lightbox/setup/template-content';
import { VIPSitesSetupTemplateContentChildPageRoute } from 'containers/katana/routes/vip-sites.$katanaServiceId/_content-editor-lightbox/setup/template-content/$childPage';
import { VIPSitesAdminRoute } from 'containers/katana/routes/vip-sites.$katanaServiceId/admin';
import { VIPSitesOverviewRoute } from 'containers/katana/routes/vip-sites.$katanaServiceId/overview';
import { VIPSitesRegisterDomainRoute } from 'containers/katana/routes/vip-sites/register.$katanaTrialDomain';
import { VIPSitesTrialDomainRoute } from 'containers/katana/routes/vip-sites/trial.$katanaTrialDomain';
import { LoginRoute, ResetPasswordRoute as ResetPasswordRouteWithToken } from 'containers/login';
import { ActivateAccountRoute } from 'containers/login/modules/activate';
import { MicrosoftManageRoute } from 'containers/ms365';
import { MicrosoftManageAdminRoute } from 'containers/ms365/routes/admin';
import { MicrosoftManageManageRoute } from 'containers/ms365/routes/manage';
import { MyServicesRoute } from 'containers/services';
import {
    LegacyAccountRoute,
    LegacyEmailHostingMailboxRoute,
    LegacyEmailHostingRoute,
    LegacyKatanaRoute,
    LegacyMicrosoftRoute,
    LegacySSLRoute,
    LegacyVPSRoute,
    LegacyWorkspaceRoute
} from 'containers/services/redirects';
import {
    MyServicesBulkChildDomainRoute,
    MyServicesBulkDomainRoute,
    MyServicesDomainRoute,
    MyServicesEmailRoute,
    MyServicesGoogleRoute,
    MyServicesHostingRoute,
    MyServicesMicrosoftRoute,
    MyServicesSSLRoute,
    MyServicesVIPSitesRoute,
    MyServicesVPSRoute
} from 'containers/services/routes';
import { ManageSSLRoute } from 'containers/ssl';
import { SSLConfigureRoute } from 'containers/ssl/routes/configure';
import { SSLManageRoute } from 'containers/ssl/routes/manage';
import { SSLValidateRoute } from 'containers/ssl/routes/validate';
import { SupportRoute } from 'containers/support';
import { WebsiteSupportArticleUrlRedirectRoute } from 'containers/support/redirects';
import { FeedbackRoute } from 'containers/support/routes/feedback';
import { MigrationsRoute } from 'containers/support/routes/migration-request';
import { RedirectETicketRoute } from 'containers/support/routes/redirects/eTickets';
import { SupportCentreRoute } from 'containers/support/routes/support-centre';
import { SupportCentreSplatRoutes } from 'containers/support/routes/support-centre/$supportArticle';
import { TicketsRoute } from 'containers/support/routes/tickets';
import { TicketsSectionRoute } from 'containers/support/routes/tickets/$ticketSection';
import { TicketsParam4Route } from 'containers/support/routes/tickets/$ticketSection/$param4';
import { TicketsParam5Route } from 'containers/support/routes/tickets/$ticketSection/$param4/$param5';
import { VentraSynergyMigrationRoute } from 'containers/ventraSynMigration';
import { SynergyMigrationConfirmMigrationRoute } from 'containers/ventraSynMigration/routes/confirm-migration';
import { SynergyMigrationCreateAccountRoute } from 'containers/ventraSynMigration/routes/create-account';
import { SynergyResellerMigrationMoreInfoRoute } from 'containers/ventraSynMigration/routes/more-info';
import { SynergyMigrationSelectServicesRoute } from 'containers/ventraSynMigration/routes/select-services';
import { SynergyMigrationSuccessRoute } from 'containers/ventraSynMigration/routes/success';
import { VIPRewardsRootRoute } from 'containers/vipRewards';
import { ManageVPSRoute } from 'containers/vps';
import { VPSAccountRoute } from 'containers/vps/routes/account';
import { VPSAddonsRoute } from 'containers/vps/routes/addons';
import { VPSAdminRoute } from 'containers/vps/routes/admin';
import { VPSManageRoute } from 'containers/vps/routes/manage';
import { RootRoute } from 'router/__root';
import { ForgotRoute, ResetPasswordRoute } from 'router/redirects/reset-password';

/**********************************************************************************************************
 *   ROUTE START
 **********************************************************************************************************/
export const routeTree = RootRoute.addChildren({
    AppRoute: AppRoute.addChildren({
        // Legacy Redirects
        LegacyAccountRoute,

        // Unauthenticated Routes
        ResetPasswordRoute,
        ResetPasswordRouteWithToken,
        ForgotRoute,
        LoginRoute,
        ActivateAccountRoute,

        // Authenticated Routes
        // Other
        DashboardRoute,
        KatanaAuthorizeRoute: KatanaAuthorizeRoute.addChildren({
            AuthorizeInstagramRoute,
            AuthorizeInstagramBusinessRoute
        }),
        
        AccountRoute: AccountRoute.addChildren({
            AccountGeneralRoute,
            AccountSecurityRoute,
            AccountEmailRoute
        }),

        MyServicesRoute: MyServicesRoute.addChildren({
            // Legacy My-service Redirects. Consider removing 11/10/2025
            LegacyDomainManageRoutes,
            LegacyHostingManageRoutes,
            LegacyHostingSubaccountManageRoutes,
            LegacyEmailHostingRoute,
            LegacyEmailHostingMailboxRoute,
            LegacyWorkspaceRoute,
            LegacyMicrosoftRoute,
            LegacyVPSRoute,
            LegacySSLRoute,
            LegacyKatanaRoute,

            MyServicesDomainRoute: MyServicesDomainRoute.addChildren({
                MyServicesBulkDomainRoute: MyServicesBulkDomainRoute.addChildren({
                    MyServicesBulkChildDomainRoute
                })
            }),

            MyServicesHostingRoute,
            MyServicesEmailRoute,
            MyServicesGoogleRoute,
            MyServicesMicrosoftRoute,
            MyServicesVPSRoute,
            MyServicesSSLRoute,
            MyServicesVIPSitesRoute,

            DirectAUApplicationRoute,

            DomainManageRoute: DomainManageRoute.addChildren({
                DomainGeneralRoute, 
                DomainDNSRoute,
                DomainSecurityRoute, 
                DomainAdminRoute
            }),

            VIPSitesTrialDomainRoute,
            VIPSitesRegisterDomainRoute,

            VIPSitesRoute: VIPSitesRoute.addChildren({
                VIPSitesOverviewRoute,
                VIPSitesAdminRoute,

                VIPSitesContentLightboxLayoutRoute: VIPSitesContentLightboxLayoutRoute.addChildren({
                    VIPSitesAddSectionRoute,

                    VIPSitesSetupRoute: VIPSitesSetupRoute.addChildren({
                        VIPSitesSetupBasicDetailsRoute,
                        VIPSitesSetupContactInformationRoute,
                        VIPSitesSetupColoursFontsRoute,
                        VIPSitesSetupNavigationAndSocialsRoute,
                        VIPSitesSetupFinaliseRoute,
                        VIPSitesSetupRegisterDomainRoute,
                        VIPSitesSetupLaunchRoute,
                        VIPSitesSetupTemplateContentRoute: VIPSitesSetupTemplateContentRoute.addChildren({
                            VIPSitesSetupTemplateContentChildPageRoute
                        })
                    }),

                    VIPSitesEditorRoute: VIPSitesEditorRoute.addChildren({
                        VIPSitesEditorLaunchRoute,
                        VIPSitesEditorFinaliseRoute,
                        VIPSitesEditorBasicDetailsRoute,
                        VIPSitesEditorContactInformationRoute,
                        VIPSitesEditorColoursFontsRoute,
                        VIPSitesEditorNavigationAndSocialsRoute,
                        VIPSitesEditorFixDNSRoute,
                        VIPSitesEditorTemplateContentRoute: VIPSitesEditorTemplateContentRoute.addChildren({
                            VIPSitesEditorTemplateContentChildPageRoute
                        })
                    })
                })
            }),

            LegacyDomainManageRoute: LegacyDomainManageRoute.addChildren({
                LegacyDomainGeneralRoute, 
                LegacyDomainSecurityRoute
            }),

            HostingManageRoute: HostingManageRoute.addChildren({
                HostingAccountRoute,
                HostingAdminRoute,
                HostingConfigurationRoute,
                HostingResellerRoute,
                HostingSecurityRoute,

                HostingSubaccountRoute: HostingSubaccountRoute.addChildren({
                    HostingSubaccountAccountRoute,
                    HostingSubaccountConfigurationRoute,
                    HostingSubaccountSecurityRoute
                })
            }),

            EmailManageRoute: EmailManageRoute.addChildren({
                EmailHostingGeneralRoute,
                EmailHostingToolsRoute,

                EmailHostingMailboxRoute: EmailHostingMailboxRoute.addChildren({
                    EmailHostingGeneralMailboxRoute, 
                    EmailHostingAdminMailboxRoute
                })
            }),

            GoogleManageRoute: GoogleManageRoute.addChildren({
                GoogleSetupRoute,
                GoogleManageManageRoute,
                GoogleAdminRoute,
                GoogleMailboxRoute: GoogleMailboxRoute.addChildren({
                    GoogleMailboxManageRoute
                })
            }),

            MicrosoftManageRoute: MicrosoftManageRoute.addChildren({
                MicrosoftManageAdminRoute, 
                MicrosoftManageManageRoute
            }),

            ManageVPSRoute: ManageVPSRoute.addChildren({
                VPSAccountRoute, 
                VPSAddonsRoute, 
                VPSAdminRoute, 
                VPSManageRoute
            }),

            ManageSSLRoute: ManageSSLRoute.addChildren({
                SSLValidateRoute, 
                SSLConfigureRoute, 
                SSLManageRoute
            })
        }),

        VIPRewardsRootRoute,

        VentraSynergyMigrationRoute: VentraSynergyMigrationRoute.addChildren({
            SynergyResellerMigrationMoreInfoRoute,
            SynergyMigrationSelectServicesRoute,
            SynergyMigrationConfirmMigrationRoute,
            SynergyMigrationCreateAccountRoute,
            SynergyMigrationSuccessRoute
        }),

        BillingRoute: BillingRoute.addChildren({
            InvoiceRoute: InvoiceRoute.addChildren({
                InvoiceIdRoute, 
                InvoicePayRoute, 
                InvoiceCancelRoute
            }),

            SplitInvoiceRoute,
            PaymentMethodRoute,
            MergeInvoicesRoute,
            InvoicesRoute,
            WalletRoute,
            SLARoute,
            ServiceAlignmentRoute
        }),

        SupportRoute: SupportRoute.addChildren({
            SupportCentreRoute: SupportCentreRoute.addChildren({
                SupportCentreSplatRoutes
            }),

            TicketsRoute,
            TicketsSectionRoute,
            TicketsParam4Route,
            TicketsParam5Route,
            MigrationsRoute,
            FeedbackRoute,

            RedirectETicketRoute
        }),

        WebsiteSupportArticleUrlRedirectRoute
    })
});

export function createRouter() {
    return createTanstackRouter({
        routeTree,
        defaultPreload: 'intent',
        notFoundMode: 'fuzzy',
        defaultPendingMinMs: 500,
        defaultPendingMs: 200,
        defaultNotFoundComponent: PageNotFound,
        defaultPendingComponent: RequestLoader,
        context: {
            queryClient: undefined!, // Added in react land
            store: undefined! // Added in react land
        }
    });
}

export const router = createRouter();

// Register the router instance for type safety
declare module '@tanstack/react-router' {
    interface Register {
        router: ReturnType<typeof createRouter>;
    }
}

export type RouterType = ReturnType<typeof createRouter>;

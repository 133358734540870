/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import mergeRefs from 'merge-refs';
import React from 'react';
import { useController } from 'react-hook-form';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import { FormItem } from 'components/Form/FormItem';
import { FormItemInner } from 'components/Form/FormItemInner';
import { FormLabel } from 'components/Form/FormLabel';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import { _Input as Input } from 'components/Form/Input/_Input';

/**********************************************************************************************************
 *   TYPE IMPORTS
 **********************************************************************************************************/
import type { NXInputNamespace } from 'components/Form/Input/types';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type InputHookFormProps<TTransformValue extends unknown> = Omit<NXInputNamespace.Props, 'value'> & {
    label?: React.ReactNode;
    name: string;
    onChangeTransform?: (value: string | unknown) => TTransformValue;
    valueTransform?: (value: TTransformValue) => string | unknown;
};

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const _InputHookFormDefault = <TTransformValue extends unknown>({
    label,
    disabled,
    placeholder,
    isPlaceholderItalic,
    name,
    inputRef,
    size,
    intrinsicProps,
    onChangeTransform,
    valueTransform
}: InputHookFormProps<TTransformValue>) => {
    /***** HOOKS *****/
    const { field } = useController({ name });
    const { onChange, onBlur, ref, value } = field;

    /***** FUNCTIONS *****/
    const handleOnChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        const value = e.target.value;
        onChange(onChangeTransform ? onChangeTransform(value) : value);
    };
    const handleOnBlur: React.FocusEventHandler<HTMLInputElement> = (e) => {
        onBlur();
        intrinsicProps?.onBlur?.(e);
    };

    /***** RENDER HELPERS *****/
    const _value = valueTransform ? valueTransform(value) : value;

    /***** RENDER *****/
    return (
        <FormItem disabled={disabled} name={name} size={size}>
            <FormLabel htmlFor={name || ''}>{label}</FormLabel>
            <FormItemInner.HookForm name={name}>
                <Input
                    disabled={disabled}
                    name={name}
                    placeholder={placeholder}
                    isPlaceholderItalic={isPlaceholderItalic}
                    value={_value}
                    inputRef={mergeRefs(ref, inputRef)}
                    intrinsicProps={{ ...intrinsicProps, onChange: handleOnChange, onBlur: handleOnBlur }}
                />
            </FormItemInner.HookForm>
        </FormItem>
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { cloneDeep, merge, snakeCase } from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import { SectionDefinitionRenderer } from 'containers/katana/components/contentDefinitionForm/sectionDefinitionRenderer';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { modifyPropertyKeys } from 'containers/katana/formFields/methods/modifyPropertyKeys';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import { ContentEditorRenderModulesContext } from 'containers/katana/containers/ContentEditorLightbox/contentEditorRenderModules/consts';

/**********************************************************************************************************
 *   TYPE IMPORTS
 **********************************************************************************************************/
import type { KatanaNamespace } from 'containers/katana/types';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
let DEV_SectionDefinitionForm: React.FC<{
    sectionDefinition: KatanaNamespace.SectionDefinitions.Values;
}> = ({
    sectionDefinition,
    /**
     * Redux Props
     */
    pristine,
    submitting,
    valid,
    anyTouched,
    form
}) => {
    /***** RENDER HELPERS *****/
    const context = {
        pristine,
        submitting,
        valid,
        anyTouched,
        form
    };
    const modifiedPropertyKeys = modifyPropertyKeys('properties', sectionDefinition.properties);
    const clonedCurrentSectionDefinition = merge(cloneDeep(sectionDefinition), { properties: modifiedPropertyKeys });

    /***** RENDER *****/
    return (
        <ContentEditorRenderModulesContext.Provider value={context}>
            <form className="ContentDefinitionForm" id={clonedCurrentSectionDefinition.id}>
                <SectionDefinitionRenderer key={clonedCurrentSectionDefinition.id} {...clonedCurrentSectionDefinition} />
            </form>
        </ContentEditorRenderModulesContext.Provider>
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

DEV_SectionDefinitionForm = reduxForm({
    enableReinitialize: true
})(DEV_SectionDefinitionForm);

const mapStateToProps = (state, props) => {
    const { sectionDefinition } = props;
    const { id } = sectionDefinition;
    const formName = `DEV_SectionDefinitionForm${snakeCase(id)}Form`;

    return {
        form: formName
    };
};

DEV_SectionDefinitionForm = connect(mapStateToProps)(DEV_SectionDefinitionForm);

export { DEV_SectionDefinitionForm };

/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React from 'react';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import Anchor from 'components/Anchor';

/**********************************************************************************************************
 *   QUERIES
 **********************************************************************************************************/
import { useGetMergeableInvoicesQuery } from 'containers/billing/queries/invoice/useGetMergeableInvoicesQuery';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
function MergeInvoicesAction() {
    /***** QUERIES *****/
    const { data: get_mergeable_invoices_data } = useGetMergeableInvoicesQuery();

    /***** RENDER HELPERS *****/
    const hasMergeableInvoices =
        get_mergeable_invoices_data?.filter?.(({ attributes: { can_merge }, isDataUpdating }) => can_merge && !isDataUpdating).length > 1;

    /***** RENDER *****/
    if (!hasMergeableInvoices) return null;
    return <Anchor to="/billing/invoices-merge">Merge Invoices</Anchor>;
}
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

export default MergeInvoicesAction;

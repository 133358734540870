/**********************************************************************************************************
 *   QUERIES
 **********************************************************************************************************/
import { useKatanaParams } from 'containers/katana/hooks/useSetupEditorRouteParams';
import { katanaQuery } from 'containers/katana/queries/tanstackTree';
import { useUpdateKatanaSiteSectionMutation } from 'containers/katana/queries/useUpdateKatanaSiteSectionMutation';

/**********************************************************************************************************
 *   HOOK START
 **********************************************************************************************************/
/**
 *
 * @param {object} parameters
 * @param {number} parameters.sectionID
 * @param {import('utilities/api/katana/types').KatanaAPI.Katana.Site.ServiceID.Meta.Sections.GET.SectionKeys} parameters.katanaSectionID
 * @param {object} [parameters.options]
 */
export function useHandleSectionAddOrUpdate({ sectionID, katanaSectionID, options }) {
    /***** HOOKS *****/
    const { katanaServiceId } = useKatanaParams();

    /**********************************************************************************************************
     *   QUERIES
     **********************************************************************************************************/
    const mutationParams = { serviceID: katanaServiceId, katanaSectionID };
    const { mutateAsync: mutateAdd } = katanaQuery.serviceID.addSection.useMutation(mutationParams, options);
    const { mutateAsync: mutateUpdate } = useUpdateKatanaSiteSectionMutation(mutationParams, options);

    /***** FUNCTIONS *****/
    /**
     * @param {import('utilities/api/katana/types').KatanaGetSectionAttributes} attributes
     */
    function submitSectionData(attributes) {
        /**
         * Because the mutations are async,
         * we need to catch any errors that occur so that tanstack can still follow it's regular flow.
         * whilest keeping the button's loading state active.
         */
        if (sectionID) {
            /**
             * Update the section as it already exists
             */
            return mutateUpdate({ sectionID, attributes });
        }

        /**
         * Add the section as it doesn't exist yet
         */
        return mutateAdd({ sectionDefinitionID: katanaSectionID, attributes });
    }

    /***** RENDER *****/
    return { submitSectionData };
}
/**********************************************************************************************************
 *   HOOK END
 **********************************************************************************************************/

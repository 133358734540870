/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { createRoute, Outlet } from '@tanstack/react-router';
import { useStore } from '@tanstack/react-store';
import React from 'react';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import { MyServicesRoute } from 'containers/services';
import { VPSRouteLoader } from 'containers/vps/components/loader';
import { VPSTitle } from 'containers/vps/components/title';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import SideBarPage from 'components/SideBarPage';
import Transition from 'components/Transition';

/*   ACTIONS
 *****************************************************/
import { getLegacyAddon, getSelectedVPS, getVPSAddons, getVPSUserInfo } from './action';

/**********************************************************************************************************
 *   UTILITIES
**********************************************************************************************************/
import { VPSModuleStore } from 'containers/vps/moduleConditions';

/**********************************************************************************************************
 *   ROUTE IMPORTS
 **********************************************************************************************************/
import { routerMiddleware } from 'router/utils/middleware';

/**********************************************************************************************************
 *   CONSTS
**********************************************************************************************************/
import './_vps.scss';

/**********************************************************************************************************
 *   ROUTE START
 **********************************************************************************************************/
export const ManageVPSRoute = createRoute({
    getParentRoute: () => MyServicesRoute,
    path: 'vps/$vpsId',
    loader({ params, context, cause }) {
        if (cause === 'enter') {
            getVPSUserInfo(params.vpsId)(context.store.dispatch);
            getVPSAddons(params.vpsId)(context.store.dispatch);
            getLegacyAddon(params.vpsId)(context.store.dispatch);
            getSelectedVPS(params.vpsId)(context.store.dispatch);
        }
    },
    beforeLoad(opts) {
        routerMiddleware.business({ path: '/my-services/vps' }, opts);
        routerMiddleware.business(this, opts);
        routerMiddleware.authentication('user', opts);
    }
});

/**********************************************************************************************************
 *   ROUTE COMPONENT START
 **********************************************************************************************************/
const RouteComponent = () => {
    /***** HOOKS *****/
    const store = useStore(VPSModuleStore);

    VPSModuleStore.useConditions();

    const [links] = SideBarPage.useGetSidebarLinks([
        {
            list_title: 'Account',
            list_items: store.account
        },
        {
            list_title: 'Addons',
            list_items: store.addons
        },
        {
            list_title: 'Manage',
            list_items: store.manage
        },
        {
            list_title: 'Admin',
            list_items: store.admin
        }
    ]);

    /***** RENDER *****/
    return (
        <VPSRouteLoader>
            <VPSTitle />
            <SideBarPage sidebarList={links}>
                <Transition>
                    <Outlet />
                </Transition>
            </SideBarPage>
        </VPSRouteLoader>
    );
};

/**********************************************************************************************************
 *   ROUTE UPDATES
 **********************************************************************************************************/
ManageVPSRoute.update({
    component: React.memo(RouteComponent)
});

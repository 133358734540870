/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import classNames from 'classnames';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import { CheckBox } from 'components/Form/CheckBox';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import { ServiceSelectItemNotAllowed } from '../serviceSelectItemNotAllowed';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { renderBulkServiceExpiry } from '../../methods';

/**********************************************************************************************************
 *   TYPE IMPORTS
 **********************************************************************************************************/
import type { DomainSelectListItemProps } from 'containers/services/Bulk/Components/domainSelectList/types';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import './_listItem.scss';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const DomainSelectListItem: React.FC<DomainSelectListItemProps> = ({ service, className, onClick, disabledServices, isChecked }) => {
    /***** FUNCTIONS *****/
    function listItemOnClick() {
        onClick(service.id);
    }

    const notAllowedMessage = disabledServices?.find(({ condition }) => condition(service));

    /***** RENDER *****/
    return (
        <ServiceSelectItemNotAllowed disabled={!!notAllowedMessage} content={notAllowedMessage?.content ?? null}>
            <CheckBox
                isChecked={isChecked}
                className={classNames('DomainSelectListItem', className)}
                onChange={notAllowedMessage ? null : listItemOnClick}
            >
                <div className="DomainSelectListItem__info">
                    <div className="DomainSelectListItem__title">{service.attributes.domain}</div>
                    <div className={`DomainSelectListItem__subtitle ${service.attributes.status !== 'Active' ? 'warning' : ''}`}>
                        {renderBulkServiceExpiry(service.attributes.expiry_date)}
                    </div>
                </div>
            </CheckBox>
        </ServiceSelectItemNotAllowed>
    );
};

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

export default DomainSelectListItem;

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { ms365Methods } from 'containers/ms365/methods';
import { getIncludedObjBasedOnType } from 'utilities/methods/commonActions';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
/**
 *  @typedef {import("utilities/api/_services").Services.MS365.TListData['data'][number]} TData
 */

/**
 * @param {TData} data
 */
export const getMS365InformationFromData = (data) => {
    const { product, invoice } = {
        product: getIncludedObjBasedOnType(data.included, 'product'),
        invoice: getIncludedObjBasedOnType(data.included, 'invoice')
    };

    const numberOfSeats = ms365Methods.getTotalSeats(data.attributes.configs);

    return /** @type {const} */ ({
        ...data,
        domain: data.attributes.name,
        name: product?.attributes.name || invoice?.attributes.name,
        nextDueDate: data.attributes.next_due_date,
        product,
        invoice,
        numberOfSeats,
        status: data.attributes.status
    });
};

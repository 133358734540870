/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { useMatch } from '@tanstack/react-router';
import { useDispatch, useSelector } from 'react-redux';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import Title from 'components/Title';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import { GoogleTitleDropdown } from 'containers/gsuite/components/title/dropdown';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { useSelectableSelector } from 'utilities/hooks/redux/useSelectableSelector';
import { getIncludedObjBasedOnType } from 'utilities/methods/commonActions';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { getGSuiteLogin } from 'containers/gsuite/action';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const WorkspaceTitle = () => {
    /***** SELECTORS *****/
    const gsuite_login_status = useSelector((state: any) => state.gsuite.gsuite_login_status);
    const { type, domain, product, id } = useSelectableSelector((state: any) => state.gsuite.gsuite_service_data, {
        select: ({ attributes = {}, included, ...rest }) => {
            const { domain } = attributes;
            const product = getIncludedObjBasedOnType(included, 'product');

            return {
                ...rest,
                domain,
                product,
                attributes
            };
        }
    });

    /***** HOOKS *****/
    const dispatch = useDispatch();
    const { routeId } = useMatch({ strict: false });

    /***** RENDER HELPERS *****/
    const getActions = () => {
        if (routeId === '/my-services/google/$workspaceId/setup') {
            return [<GoogleTitleDropdown key="dropdown" />];
        } else
            return [
                {
                    status: gsuite_login_status,
                    label: 'Google Workspace Login',
                    color: 'notice',
                    type: 'onClick',
                    onClick: (e) => {
                        e.preventDefault();
                        getGSuiteLogin(id)(dispatch);
                    }
                },
                <GoogleTitleDropdown key="dropdown" />
            ];
    };

    /***** RENDER *****/
    return (
        <Title //@prettier-ignore
            serviceTitle={domain}
            serviceSubTitle={product?.attributes?.name}
            serviceType={type}
            action={getActions()}
        />
    );
};

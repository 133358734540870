import { _ServiceOverviewBannerDescription } from 'components/ServiceOverviewBanner/Description';
import { _ServiceOverviewBannerHeading } from 'components/ServiceOverviewBanner/Heading';
import { _ServiceOverviewBanner } from 'components/ServiceOverviewBanner/_ServiceOverviewBanner';
import { _ServiceOverviewBannerContentWrapper } from 'components/ServiceOverviewBanner/_ServiceOverviewBannerContent';
import { _ServiceOverviewBannerButton } from './Button';
import { _ServiceOverviewBannerImage } from './Image';

const ServiceOverviewBanner = Object.assign(_ServiceOverviewBanner, {
    Heading: _ServiceOverviewBannerHeading,
    Description: _ServiceOverviewBannerDescription,
    ContentWrapper: _ServiceOverviewBannerContentWrapper,
    Image: _ServiceOverviewBannerImage,
    Button: _ServiceOverviewBannerButton
});

export { ServiceOverviewBanner };

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import Tip from 'components/Tooltip';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import './_NXBoxToolTip.scss';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type NXToolTip = React.FC<NXToolTipProps>;
type NXToolTipProps = {
    info: string;
};

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * Provides a tooltip for the NXBox Component. This is positioned absolutely, as such it will be put in the top right of it's closest non-static parent.
 */
export const NXToolTip: NXToolTip = ({ info }) => {
    /***** RENDER *****/
    return (
        <div className="NXBoxTooltip">
            <Tip info={info} />
        </div>
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { Component } from 'react';
import { Field, change } from 'redux-form';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import Anchor from 'components/Anchor';
import { Input } from 'components/Form/Input';
import { ValidationMessage } from 'components/Form/ValidationMessage';
import Tooltip from 'components/Tooltip';

/*   ACTIONS
 *****************************************************/
import { bankACCValidation, bankBSBValidation, requiredFieldValidation } from 'utilities/methods/form';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import SynergyLogo from 'assets/images/ventraSynMigration/temp/sw-logo-emblem.svg';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type BankDetailsProps = {
    /**
     * List of field names that should have required field validation
     */
    requiredFields?: Array<'account_name' | 'branch_number' | 'account_number'>;
};

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export class BankDetails extends Component<BankDetailsProps> {
    render() {
        const { requiredFields } = this.props;

        const validation = {
            account_name: [],
            branch_number: [bankBSBValidation],
            account_number: [bankACCValidation]
        };

        if (requiredFields) requiredFields.forEach((item) => validation[item].unshift(requiredFieldValidation));

        return (
            <div className="bankDetails">
                <Field component={Input.ReduxForm} label="Account Name" name="account_name" validate={validation.account_name} />
                <Field component={Input.ReduxForm} label="BSB" name="branch_number" validate={validation.branch_number} />
                <Field component={Input.ReduxForm} label="Account Number" name="account_number" validate={validation.account_number} />
            </div>
        );
    }
}

export class RefundMethods extends Component {
    render() {
        const { cancelInfo } = this.props;
        let methods = null;

        if (cancelInfo !== null && 0 in cancelInfo) {
            if (cancelInfo[0].refund_methods && cancelInfo[0].refund_methods.length > 0) {
                methods = cancelInfo[0].refund_methods;
            }
        } else {
            return null;
        }

        const refundList = [];

        if (methods) {
            methods.forEach((method) => {
                switch (method) {
                    case 'account_credit':
                        refundList.push({
                            attributes: {
                                name: 'account_credit',
                                displayName: 'Account Credit',
                                desc: `We'll credit your account for future use`,
                                content: null,
                                icon: <span className="icon-coins_collect item_logoRefund item_logoRefund--coins_collect"></span>
                            }
                        });
                        break;

                    case 'donation':
                        refundList.push({
                            attributes: {
                                name: 'donation',
                                displayName: 'Donate to Charity',
                                desc: (
                                    <>
                                        Your refund amount will be donated to an Australian Registered Charity.{' '}
                                        <Anchor href="https://ventraip.com.au/faq/article/donating-unused-credit-to-charity/" target="_blank">
                                            Learn more
                                        </Anchor>
                                        .
                                    </>
                                ),
                                content: null,
                                icon: <span className="icon-smile_heart item_logoRefund item_logoRefund--smile_heart"></span>
                            }
                        });
                        break;

                    case 'manual':
                        refundList.push({
                            attributes: {
                                name: 'manual',
                                displayName: 'Cash Refund',
                                desc: `We'll transfer the balance to your account`,
                                content: null,
                                icon: <span className="icon-banknote item_logoRefund item_logoRefund--banknote"></span>
                            }
                        });
                        break;

                    case 'gateway':
                        refundList.push({
                            attributes: {
                                name: 'gateway',
                                displayName: 'Cash Refund',
                                desc: `We'll transfer the balance to your account`,
                                content: null,
                                icon: <span className="icon-banknote item_logoRefund item_logoRefund--banknote"></span>
                            }
                        });
                        break;

                    case 'synergy':
                        refundList.push({
                            attributes: {
                                name: 'synergy',
                                displayName: 'Synergy Wholesale Account Credit',
                                desc: `We'll transfer the balance to your Synergy Wholesale account`,
                                content: null,
                                icon: <img className="item_logoRefund item_logoRefund--synergy" src={SynergyLogo} alt="Synergy Wholesale logo" />
                            }
                        });
                        break;

                    case 'none':
                    default:
                        break;
                }
            });
        }

        const { input, label, tooltip, readOnly, placeholder, type, meta } = this.props;
        const { value } = input;
        const inputname = input.name;
        const { touched, error, warning, initial, dispatch, form } = meta;

        const list = refundList;

        const renderList = () => {
            if (list && list.length > 0) {
                return list.map((item, index) => {
                    const { attributes } = item;
                    const { displayName, icon, name, desc, destination, content } = attributes;
                    return (
                        <button
                            type="button"
                            key={index}
                            className={`RenderAdvanceListSlim__item item${value === name ? ' selected' : ''}${content ? ' has__content' : ''}`}
                            onClick={() => {
                                dispatch(change(form, inputname, name));
                            }}
                            onKeyDown={(e) => {
                                if (e.key === ' ') {
                                    e.preventDefault();
                                    dispatch(change(form, inputname, name));
                                }
                            }}
                        >
                            <div className="item__top">
                                <div className="item__column item__column--left">
                                    <span className="item_logoRefundContainer">{icon}</span>
                                </div>
                                <div className="item__column item__column--right">
                                    <span className="item__label">{displayName}</span>
                                    <span className="item__desc">
                                        {desc}
                                        {destination ? <span className="item__desc-dest">{destination}</span> : ''}
                                    </span>
                                </div>
                            </div>
                            {value === name && content ? <div className="item__content">{content}</div> : ''}
                        </button>
                    );
                });
            }
        };
        return (
            <div className="form__item RenderAdvanceListSlim">
                <div className="form__title">
                    {label ? (
                        <label>
                            {label}
                            {tooltip ? <Tooltip info={tooltip} /> : ''}
                        </label>
                    ) : (
                        ``
                    )}
                </div>
                <div className="form__item__inner RenderAdvanceListSlim__inner">
                    <div className={`wrapperbox RenderAdvanceListSlim__wrapperbox ${readOnly ? 'readonly' : ''}`}>
                        <input {...input} readOnly={readOnly} placeholder={placeholder} type={type} />
                        <div className="form__item__list">{renderList()}</div>
                    </div>
                    <ValidationMessage.ReduxForm.Default touched={touched} error={error} warning={warning} initial={initial} />
                </div>
            </div>
        );
    }
}
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

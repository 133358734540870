/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Field, formValueSelector } from 'redux-form';
import store from 'store/store';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import RequestLoader from 'components/Loaders/Request';
import { DialogNotification } from 'components/Notifications/DialogNotification';

/*   ACTIONS
 *****************************************************/
import { getDomainContacts } from 'containers/domain/action';
import { bulkListDomains } from 'containers/services/Bulk/action';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { filterDomainBulkListData } from 'containers/services/Bulk/methods';
import { RenderSelectField } from 'utilities/methods/form';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import './_domainSelectionInfoList.scss';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
let DomainSelectionInfoList = ({
    /**
     * Redux Props
     */
    domain_bulk_list_data,
    domain_bulk_list_status,
    domainBulkListSelectID
}) => {
    const { dispatch } = store;

    /***** FUNCTIONS *****/
    function getSelectedServicesOptions() {
        const filteredDomainList = filterDomainBulkListData(domain_bulk_list_data);

        return filteredDomainList.map((service) => ({
            label: service.attributes.domain,
            value: service.id
        }));
    }

    /***** EFFECTS *****/
    useEffect(() => {
        if (domain_bulk_list_status !== 'loading') {
            dispatch(bulkListDomains());
        }
    }, []);

    useEffect(() => {
        if (!domainBulkListSelectID) return;
        dispatch(getDomainContacts(domainBulkListSelectID));
    }, [domainBulkListSelectID]);

    /***** RENDER *****/
    if (domain_bulk_list_status === 'loading' && !domain_bulk_list_data) {
        return <RequestLoader heightAuto message="Your domain list is loading" height={61} />;
    }

    if (domain_bulk_list_data?.length > 0)
        return (
            <Field
                label="Use domain contacts info from"
                name="domainBulkListSelectID"
                component={RenderSelectField}
                type="select"
                className="form__dropdown DomainSelectInfoList"
                options={getSelectedServicesOptions()}
            />
        );

    return <DialogNotification type="info">You have no domains.</DialogNotification>;
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

const mapStateToProps = (state, props) => {
    const selector = formValueSelector(props.form);
    const domainBulkListSelectID = selector(state, 'domainBulkListSelectID');

    return {
        domain_bulk_list_data: state.servicesBulk.domain_bulk_list_data,
        domain_bulk_list_status: state.servicesBulk.domain_bulk_list_status,
        domainBulkListSelectID
    };
};

DomainSelectionInfoList = connect(mapStateToProps)(DomainSelectionInfoList);

export default DomainSelectionInfoList;

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import OutlineTag, { TAG_COLOURS } from 'components/Tags/OutlineTag';
import type { INVOICE_STATUS_VALUES } from 'utilities/consts';
import { INVOICE_STATUSES } from 'utilities/consts';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
function getTagStatus(status: string, is_overdue?: boolean) {
    if (status === INVOICE_STATUSES.UNPAID && is_overdue === true) {
        return TAG_COLOURS.WARN;
    }
    switch (status) {
        case INVOICE_STATUSES.UNPAID:
            return TAG_COLOURS.NOTICE;
        case INVOICE_STATUSES.PAID:
            return TAG_COLOURS.CONFIRM;
        case INVOICE_STATUSES.CANCELLED:
            return TAG_COLOURS.SECONDARY;
        default:
            return TAG_COLOURS.PRIMARY;
    }
}

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type InvoiceStatusTagProps = {
    status: (typeof INVOICE_STATUS_VALUES)[number];
    is_overdue?: boolean;
};

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
const InvoiceStatusTag: React.FC<InvoiceStatusTagProps> = ({ status, is_overdue }) => {
    /***** RENDER HELPERS *****/
    const tagStatus = getTagStatus(status, is_overdue);
    /***** RENDER *****/
    return <OutlineTag color={tagStatus}>{tagStatus === TAG_COLOURS.WARN ? 'Overdue' : status}</OutlineTag>;
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

export default InvoiceStatusTag;

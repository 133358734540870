/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import classNames from 'classnames';
import { getCountryCallingCode } from 'libphonenumber-js';
import React from 'react';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import Grid from 'components/Grid';
import Text from 'components/Utils/Text';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
function _PhoneInputCountrySelectOptionList({ sortedCountryOptions, selectedCountry, updateSelectedCountry, IconComponent }) {
    /***** FUNCTIONS *****/
    /**
     * Scrolls to the selected option when the ref gets constructed
     * @param {HTMLElement} el
     */
    function optionListRef(el) {
        const selectedOption = el?.querySelector('.selected');
        const scrollToOptions = { top: selectedOption?.offsetTop };
        el?.scrollTo(scrollToOptions);
    }

    /*  RENDER COMPONENT
     **********************************************************************************************************/
    return (
        <div className="PhoneInputCountrySelect__optionList" ref={optionListRef}>
            {sortedCountryOptions.map((option, i) => {
                const selected = selectedCountry ? selectedCountry === option.value : !option.value;
                return (
                    <button
                        type="button"
                        key={i}
                        className={classNames('PhoneInputCountrySelect__optionList--option', {
                            selected,
                            noValue: !(option?.value ?? undefined)
                        })}
                        onClick={() => updateSelectedCountry(i)}
                    >
                        <Grid columns="30px 1fr" gap={1}>
                            <IconComponent title={option.label ?? 'country'} country={option?.value ?? 'AU'} />
                            <span className="PhoneInputCountrySelect__optionList--optionLabel">
                                <Text size--xss black>
                                    {option.label}
                                </Text>
                                {option?.value ? (
                                    <>
                                        {' '}
                                        <Text size--xss secondary>
                                            {`+${getCountryCallingCode(option.value)}`}
                                        </Text>
                                    </>
                                ) : (
                                    ''
                                )}
                            </span>
                        </Grid>
                    </button>
                );
            })}
        </div>
    );
}
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

export default _PhoneInputCountrySelectOptionList;

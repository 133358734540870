/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { authenticationMiddleware } from 'router/utils/middleware/authentication';
import { businessMiddleware } from 'router/utils/middleware/business';

/***** EXPORTS *****/
export const routerMiddleware = {
    business: businessMiddleware,
    authentication: authenticationMiddleware
};

/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import classNames from 'classnames';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import Anchor from 'components/Anchor';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import './_ClearFilterButton.scss';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type ClearFilterButtonProps = {
    className: string;
    onClick: () => void;
};

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/

/**
 * The ClearFilterButton component implements an Anchor tag to provide a "clear" button for any filterable component.
 */
function ClearFilterButton({ className, onClick }: ClearFilterButtonProps) {
    const clearFilterButtonClassNames = classNames('ClearFilterButton', className);
    return (
        <Anchor onClick={onClick} className={clearFilterButtonClassNames}>
            Clear filters
        </Anchor>
    );
}
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

export default ClearFilterButton;

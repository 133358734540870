/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import _isEqual from 'lodash/isEqual';
import { useEffect, useRef } from 'react';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { useTextSelection } from 'utilities/hooks/useTextSelection/useTextSelection';

/**********************************************************************************************************
 *   TYPE IMPORTS
 **********************************************************************************************************/
import type { UseTextSelectionNamespace } from 'utilities/hooks/useTextSelection/types';

/**********************************************************************************************************
 *   HOOK START
 **********************************************************************************************************/
export function useTextSelectionChangedEffect(
    element: Element | null | undefined,
    callback: (selectionData: UseTextSelectionNamespace.Data) => void
) {
    const selectionData = useTextSelection(element);
    /***** HOOKS *****/
    const lastSelectionData = useRef<UseTextSelectionNamespace.Data>(selectionData);

    /***** EFFECTS *****/
    useEffect(() => {
        const isRectsLengthDifferent = selectionData.rects.length !== lastSelectionData.current.rects.length;
        const isRectsDifferent = selectionData.rects.some((rect, index) => {
            const currentPickedRect = rect.toJSON();
            const lastPickedRect = lastSelectionData.current.rects[index]?.toJSON?.();
            return !_isEqual(lastPickedRect, currentPickedRect);
        });

        if (isRectsLengthDifferent || isRectsDifferent) {
            lastSelectionData.current = selectionData;
            callback(selectionData);
        }
    }, [selectionData, callback]);
}
/**********************************************************************************************************
 *   HOOK END
 **********************************************************************************************************/

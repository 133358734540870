/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { Outlet, createRoute, redirect } from '@tanstack/react-router';
import { useStore } from '@tanstack/react-store';
import React from 'react';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import { GoogleManageRouteLoader } from 'containers/gsuite/components/loader';
import { GoogleTitle } from 'containers/gsuite/components/title';

/**********************************************************************************************************
 *   ROUTE IMPORTS
 **********************************************************************************************************/
import { MyServicesRoute } from 'containers/services';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import RequestLoader from 'components/Loaders/Request';
import SideBarPage from 'components/SideBarPage';
import Transition from 'components/Transition';
import { WrapWithComponent } from 'components/WrapWithComponent';
import { useChildMatchById } from 'utilities/hooks/router/useChildMatchById';

/*   ACTIONS
 *****************************************************/
import { getGSuiteService } from './action';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { GoogleModuleStore } from 'containers/gsuite/moduleConditions';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import { routerMiddleware } from 'router/utils/middleware';
import './_gsuite.scss';

/**********************************************************************************************************
 *   ROUTE START
 **********************************************************************************************************/
export const GoogleManageRoute = createRoute({
    getParentRoute: () => MyServicesRoute,
    path: 'google/$workspaceId',
    pendingComponent: RequestLoader,
    async loader({ params, cause }) {
        if (cause === 'enter') {
            getGSuiteService(params.workspaceId);
        }
    },
    beforeLoad(opts) {
        routerMiddleware.business({ path: '/my-services/google' }, opts);
        routerMiddleware.business(this, opts);
        routerMiddleware.authentication('user', opts);

        const { pathname: directPathname } = opts.buildLocation({
            to: '/my-services/google/$workspaceId',
            params: opts.params
        });

        // Cannot visit this route directly
        if (opts.location.pathname === directPathname) {
            throw redirect({
                to: '/my-services/google/$workspaceId/manage',
                params: opts.params,
                hash: 'overview'
            });
        }
    }
});

/**********************************************************************************************************
 *   ROUTE COMPONENT START
 **********************************************************************************************************/
const GoogleRouteComponent = () => {
    /***** HOOKS *****/
    const store = useStore(GoogleModuleStore);
    const isSetupRoute = useChildMatchById('/my-services/google/$workspaceId/setup');

    GoogleModuleStore.useConditions();

    const [links] = SideBarPage.useGetSidebarLinks([
        {
            list_items: store.manage,
            list_title: 'Manage'
        },
        {
            list_items: store.admin,
            list_title: 'Admin'
        }
    ]);

    /***** RENDER *****/
    return (
        <GoogleManageRouteLoader>
            <GoogleTitle />
            <WrapWithComponent component={SideBarPage.Wrapper} wrap={isSetupRoute}>
                <WrapWithComponent component={SideBarPage} wrap={!isSetupRoute} sidebarList={links}>
                    <Transition>
                        <Outlet />
                    </Transition>
                </WrapWithComponent>
            </WrapWithComponent>
        </GoogleManageRouteLoader>
    );
};

/**********************************************************************************************************
 *   ROUTE UPDATES
 **********************************************************************************************************/
GoogleManageRoute.update({
    component: React.memo(GoogleRouteComponent)
});

/**********************************************************************************************************
 *   TYPE IMPORTS
 **********************************************************************************************************/

/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { useRef } from 'react';
import { v4 as uuidv4 } from 'uuid';

/**********************************************************************************************************
 *   HOOK START
 **********************************************************************************************************/
export function useUUID() {
    /***** STATE *****/
    const { current } = useRef(uuidv4());

    /***** HOOK RESULTS *****/
    return current;
}
/**********************************************************************************************************
 *   HOOK END
 **********************************************************************************************************/

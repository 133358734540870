/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { createRoute } from '@tanstack/react-router';

/**********************************************************************************************************
 *   ROUTE IMPORTS
 **********************************************************************************************************/
import { MyServicesRoute } from 'containers/services';
import { routerMiddleware } from 'router/utils/middleware';
import { lazyFN } from 'utilities/methods/tanstack/router/lazyFn';

/**********************************************************************************************************
 *   ROUTE START
 **********************************************************************************************************/
export const DirectAUApplicationRoute = createRoute({
    getParentRoute: () => MyServicesRoute,
    path: 'domains/direct-au-applications',
    beforeLoad(opts) {
        routerMiddleware.business({ path: '/my-services/domains' }, opts);
        routerMiddleware.authentication('user', opts);
    }
}).lazy(lazyFN(() => import('./domain.direct-au-applications.lazy'), 'LazyDirectAUApplicationRoute'));

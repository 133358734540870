import { validateHrefOptionalScheme } from 'utilities/methods/validators/validateHrefOptionalScheme/validateHrefOptionalScheme';

export const HrefWithoutSchemeValidation = Object.assign(
    (href: string) => {
        return validateHrefOptionalScheme(href) ? undefined : 'Invalid Link';
    },
    {
        validationMethod: validateHrefOptionalScheme
    }
);

/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { useCurrentEditor } from '@tiptap/react';
import { useEffect } from 'react';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
interface MarkdownExtractorPluginProps {
    onChange: (markdown: string) => void;
    markdown?: string;
}

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const MarkdownExtractorPlugin: React.FC<MarkdownExtractorPluginProps> = ({ onChange, markdown }) => {
    /***** HOOKS *****/
    const { editor } = useCurrentEditor();

    /***** EFFECTS *****/
    useEffect(() => {
        const markdownOutput = editor?.storage.markdown.getMarkdown();
        if (markdown === markdownOutput) {
            return;
        }
        onChange(markdownOutput);
    }, [editor?.storage.markdown.getMarkdown()]);

    /***** RENDER *****/
    return null;
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

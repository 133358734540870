/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React from 'react';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import BaseButton from '../_BaseButton';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import './_OutlineButton.scss';

/**
 * @template T
 * @typedef {T[keyof T]} ValueOf
 */

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * that renders a button with the SolidButton variant
 * @type {React.FC<ButtonPropsTypeDef & LoadingButtonPropsTypeDef>}
 */
const OutlineButton = (props) => {
    /***** RENDER *****/
    return <BaseButton variant="OutlineButton" {...props} />;
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

export default OutlineButton;

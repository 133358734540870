/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { useMutation } from '@tanstack/react-query';
import { cloneDeep } from 'lodash';

/**********************************************************************************************************
 *   QUERIES
 **********************************************************************************************************/
import { katanaQuery } from 'containers/katana/queries/tanstackTree';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { useKatanaParams } from 'containers/katana/hooks/useSetupEditorRouteParams';
import { filterEndpointAttributeKeys } from 'containers/katana/queries/methods/filterEndpointAttributeKeys';
import { KATANA_API } from 'utilities/api/katana';
import { handleDefaultSuccessNotification } from 'utilities/methods/commonActions';

/**********************************************************************************************************
 *   HOOK START
 **********************************************************************************************************/
/**
 * @param {number} serviceID
 */
export function usePostNavigationItemsMutation(serviceID) {
    /***** HOOKS *****/
    const { page } = useKatanaParams();

    /***** FUNCTIONS *****/
    /**
     * @type {(values: import('utilities/api/katana/types').KatanaAPI.Katana.ServiceID.Navigation.Items.POST.Attributes) => Promise<unknown>}
     */
    function mutationFn(values) {
        const attributes = filterEndpointAttributeKeys(values, usePostNavigationItemsMutation.acceptableKeys);
        return KATANA_API.katana.service_id.navigation.items.POST({ serviceID, attributes });
    }

    /***** HOOK RESULTS *****/
    return useMutation({
        mutationFn,
        onSettled: () => {
            katanaQuery.serviceID.getService.invalidateQueries(serviceID);
        },
        onSuccess: (data, variables, context) => {
            if (page !== 'setup') {
                handleDefaultSuccessNotification(data);
            }
            try {
                if (variables?.section_ids) {
                    katanaQuery.serviceID.getNavigationItems.setQueryData(serviceID, (oldData) => {
                        if (oldData?.status !== 200) {
                            return;
                        }

                        const clonedData = cloneDeep(oldData);

                        clonedData.data.section_ids = variables.section_ids;

                        return clonedData;
                    });
                }
            } catch (e) {
                console.log('e', e);
            }
        }
    });
}

/**********************************************************************************************************
 *   HOOK END
 **********************************************************************************************************/

usePostNavigationItemsMutation.FORM_FIELD_KEYS = /** @type {const} */ ({
    SECTION_IDS: 'section_ids'
});
usePostNavigationItemsMutation.acceptableKeys = Object.values(usePostNavigationItemsMutation.FORM_FIELD_KEYS);

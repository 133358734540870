/**********************************************************************************************************
 *   BASE IMPORT
**********************************************************************************************************/
import { Outlet, createRoute, redirect, useLocation } from '@tanstack/react-router';
import React from 'react';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import Subnav from 'components/Subnav';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { actionButtonWidth } from 'components/NXTable/hooks/useTableActions';
import { useStyle } from 'utilities/hooks/useStyle';

/**********************************************************************************************************
 *   ROUTE IMPORTS
 **********************************************************************************************************/
import { AppRoute } from 'App';

/**********************************************************************************************************
 *   TYPE IMPORTS
 **********************************************************************************************************/

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import { routerMiddleware } from 'router/utils/middleware';
import './_services.scss';

/**********************************************************************************************************
 *   ROUTE START
 **********************************************************************************************************/
export const MyServicesRoute = createRoute({
    getParentRoute: () => AppRoute,
    path: 'my-services',
    beforeLoad(opts) {
        const paths: Array<string> = ['/my-services', '/my-services/'];

        routerMiddleware.business(this, opts);
        routerMiddleware.authentication('user', opts);

        // Refer to https://github.com/TanStack/router/issues/1882 why we need the cause check
        if (paths.includes(opts.location.pathname) && opts.cause !== 'preload') {
            throw redirect({ to: '/my-services/domains', replace: true });
        }
    }
});

/**********************************************************************************************************
 *   ROUTE COMPONENT START
 **********************************************************************************************************/
const MyServices = () => {
    /***** HOOKS *****/
    const { pathname } = useLocation();
    const styles = useStyle({
        '--actionButtonWidth': actionButtonWidth
    });

    /***** RENDER *****/
    return (
        <div className="ServicePage" style={styles}>
            <Subnav pathname={pathname} />

            {/* Animated; 600ms; ccpPageFade */}
            <Outlet />
        </div>
    );
};

/**********************************************************************************************************
 *   ROUTE UPDATES
 **********************************************************************************************************/
MyServicesRoute.update({
    component: React.memo(MyServices)
});

/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { createRoute } from '@tanstack/react-router';
import PageNotFound from 'components/Errors/PageNotFound';

/**********************************************************************************************************
 *   ROUTE IMPORTS
 **********************************************************************************************************/
import { DomainManageRoute } from 'containers/domain';
import { routerMiddleware } from 'router/utils/middleware';
import { lazyFN } from 'utilities/methods/tanstack/router/lazyFn';

/**********************************************************************************************************
 *   ROUTE START
 **********************************************************************************************************/
export const DomainDNSRoute = createRoute({
    getParentRoute: () => DomainManageRoute,
    path: 'manage',
    notFoundComponent: PageNotFound,
    beforeLoad(opts) {
        routerMiddleware.business(this, opts);
        routerMiddleware.authentication('user', opts);
    }
}).lazy(lazyFN(() => import('./dns.lazy'), 'LazyDomainDNSRoute'));

/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React from 'react';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import { ServiceOverviewBanner } from 'components/ServiceOverviewBanner/';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { getPurchaseLink } from 'containers/services/consts';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import upgradeBackgroundImage from 'assets/images/gsuite/google_banner_background.png';
import upgradeImage from 'assets/images/gsuite/google_banner_icon.png';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const GoogleBanner = () => (
    <ServiceOverviewBanner src={upgradeBackgroundImage}>
        <ServiceOverviewBanner.ContentWrapper>
            <ServiceOverviewBanner.Heading>Power Your Business With Google Workspace</ServiceOverviewBanner.Heading>
            <ServiceOverviewBanner.Description>Get Gmail, Docs, Drive, and Calendar for business.</ServiceOverviewBanner.Description>
            <ServiceOverviewBanner.Button href={getPurchaseLink('Google Workspace', { utmMedium: 'service-banner' })} target="_blank">
                View Pricing
            </ServiceOverviewBanner.Button>
        </ServiceOverviewBanner.ContentWrapper>
        <ServiceOverviewBanner.Image src={upgradeImage} alt="Purchase Hosting" />
    </ServiceOverviewBanner>
);
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

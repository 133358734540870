/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React from 'react';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import NewServices from 'assets/images/vipRewards/service-discount.svg';
import Giveaways from 'assets/images/vipRewards/giveaways.svg';
import FreeServices from 'assets/images/vipRewards/free-service.svg';
import Renewals from 'assets/images/vipRewards/renewal-discounts.svg';
import Support from 'assets/images/vipRewards/cust-support.svg';

export const rewardCircles = [
    {
        image: (
            <div className="vipRewardsInfoCircle__circle vipRewardsInfoCircle__circle--newServices">
                <div className="vipRewardsInfoCircle__circleBg" />
                <img src={NewServices} alt="Discounts On New Services" />
            </div>
        ),
        title: (
            <p>
                Discounts On
                <br />
                New Services
            </p>
        ),
        description: 'Save money with exclusive offers.',
        isBold: false
    },
    {
        image: (
            <div className="vipRewardsInfoCircle__circle vipRewardsInfoCircle__circle--giveaways">
                <div className="vipRewardsInfoCircle__circleBg" />
                <img src={Giveaways} alt="Exclusive Giveaways" />
            </div>
        ),
        title: (
            <p>
                Exclusive
                <br />
                Giveaways
            </p>
        ),
        description: 'Enter giveaways for prizes and more.',
        isBold: false
    },
    {
        image: (
            <div className="vipRewardsInfoCircle__circle vipRewardsInfoCircle__circle--freeServices">
                {' '}
                <div className="vipRewardsInfoCircle__circleBg" />
                <img src={FreeServices} alt="Free Services" />
            </div>
        ),
        title: (
            <p>
                Free
                <br />
                Services
            </p>
        ),
        description: 'Get free access to some of our premium services.',
        isBold: false
    },
    {
        image: (
            <div className="vipRewardsInfoCircle__circle vipRewardsInfoCircle__circle--renewals">
                {' '}
                <div className="vipRewardsInfoCircle__circleBg" />
                <img src={Renewals} alt="Renewal Discounts" />
            </div>
        ),
        title: (
            <p>
                Renewal
                <br />
                Discounts
            </p>
        ),
        description: 'Save money on your renewals with exclusive discounts.',
        isBold: false
    },
    {
        image: (
            <div className="vipRewardsInfoCircle__circle vipRewardsInfoCircle__circle--support">
                {' '}
                <div className="vipRewardsInfoCircle__circleBg" />
                <img src={Support} alt="Priority Customer Support" />
            </div>
        ),
        title: (
            <p>
                Priority Customer
                <br />
                Support
            </p>
        ),
        description: 'Get to the front of the line for customer support.',
        isBold: false
    }
];

export const headings = [
    {
        id: 'image',
        title: null,
        tooltip: null
    },
    {
        id: 'name',
        title: null,
        tooltip: null
    },
    {
        id: 'account_age',
        title: 'Account Age',
        tooltip: "Account age is simply the period from when you created your VentraIP account to today's date."
    },
    {
        id: 'new_hosting_benefit',
        title: 'New Hosting Service Benefit',
        tooltip: "An exclusive discount you'll receive for any new web hosting purchases."
    },
    {
        id: 'new_domain_benefit',
        title: 'New Domain Name Benefit',
        tooltip: "An exclusive discount you'll receive for any new domain name registrations."
    },
    {
        id: 'existing_hosting_benefit',
        title: 'Web Hosting Renewal Benefit',
        tooltip: "An exclusive discount you'll receive for any web hosting renewals."
    },
    {
        id: 'existing_domain_benefit',
        title: 'Domain Name Renewal Benefit',
        tooltip: "An exclusive discount you'll receive for any domain name renewals."
    }
];

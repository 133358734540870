/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React, { useEffect, useState } from 'react';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import { PhosphorIcons } from 'components/Icons/Phosphor';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { getSlots } from 'components/Slot/methods';
import Text from 'components/Utils/Text';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import './_SortableButton.scss';

const sortableSortStates = [false, 'asc', 'desc'] as const;
const sortableSortStatesIcons = [false, 'up', 'down'] as const;

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type SortableButtonProps = {
    sortKey: string;
    children: React.ReactNode;
    onSort?: (sortData: { sortKey: string; sortMethod: string | boolean }) => void;
    currentSort?:
        | {
              sortKey: string | false;
              sortMethod: string | false;
          }
        | {};
};

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * Sortable Button allows you to easily get a desired Key + Sorting direction to help guide what you should sort
 *
 * Note: The onSort and currentSort methods on the Sortable Button should NOT be provided by the user if used within the
 * NXTable Component, but instead get injected by the SortableButtonWrapper component. The Sortable Button Wrapper retrieves
 * these from the NXTable Context
 */
export const SortableButton: React.FC<SortableButtonProps> = ({ sortKey, children, onSort, currentSort = {} }) => {
    /***** STATE *****/
    const [sortState, setSortState] = useState(0);

    /***** FUNCTIONS *****/
    /**
     * Handles sorting
     */
    function toggleSort() {
        let nextState = sortState + 1;
        if (nextState >= sortableSortStates.length) {
            nextState = 0;
        }
        setSortState(nextState);
        const sortData = { sortKey, sortMethod: sortableSortStates[nextState] };
        onSort?.(sortData);
    }

    /***** EFFECTS *****/
    /**
     * When the sort changes from the master, we need to change the components internals.
     */
    useEffect(() => {
        if (currentSort.sortKey !== sortKey && sortState !== 0) {
            setSortState(0);
        }
    }, [currentSort, sortKey, sortState]);

    /***** RENDER HELPERS *****/
    function renderSortIcon() {
        const state = sortableSortStatesIcons[sortState];
        if (state === false) return '';

        return <PhosphorIcons.Chevron className="sortedIcon" state={state} />;
    }

    const { defaultChildren, afterIcon } = getSlots(['defaultChildren', 'afterIcon'], children);

    /***** RENDER *****/
    return (
        <div className="sortableButton">
            <button className="sortableButton__clickWrapper" onClick={toggleSort}>
                <Text bold uppercase size--xs>
                    {defaultChildren}
                </Text>
                {renderSortIcon()}
            </button>
            {afterIcon}
        </div>
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

export default SortableButton;

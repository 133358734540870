/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React from 'react';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import { RenderEntryCreationElement } from 'containers/katana/components/EntryCreationBehaviourHandler/wrapper/RenderEntryCreationElement';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { useGetCaveat } from 'containers/katana/formFields/methods/useGetCaveat';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import { CaveatTypes } from 'containers/katana/formFields/caveatTypes';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * @param {import('containers/katana/components/EntryCreationBehaviourHandler/wrapper/types').EntryCreationBehaviourHandlerProps} props
 */
function EntryCreationBehaviourHandlerWrapper({ children, ...props }) {
    const { property } = props;

    /***** RENDER HELPERS *****/
    const entryCreationPosition = useGetCaveat(CaveatTypes.ENTRY_CREATION_POSITION, property.caveats, 'bottom');

    /***** RENDER *****/
    return (
        <>
            {entryCreationPosition === 'top' && <RenderEntryCreationElement {...props} />}
            {children}
            {entryCreationPosition === 'bottom' && <RenderEntryCreationElement {...props} />}
        </>
    );
}

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
export { EntryCreationBehaviourHandlerWrapper };

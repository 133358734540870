/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { useNavigate, useParams } from '@tanstack/react-router';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import Title from 'components/Title';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import { GoogleTitleDropdown } from 'containers/gsuite/components/title/dropdown';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { useSelectableSelector } from 'utilities/hooks/redux/useSelectableSelector';
import { getIncludedObjBasedOnType } from 'utilities/methods/commonActions';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const MailboxTitle = () => {
    /***** HOOKS *****/
    const navigate = useNavigate();
    const { workspaceId } = useParams({ from: '/my-services/google/$workspaceId' });

    /***** SELECTORS *****/
    const { domain, product } = useSelectableSelector((state: any) => state.gsuite.gsuite_service_data, {
        select: ({ attributes, included }) => ({
            ...attributes,
            product: getIncludedObjBasedOnType(included, 'product')
        })
    });
    const { type, primary_email } = useSelectableSelector((state: any) => state.gsuite.gsuite_mailbox_data, {
        select: ({ type, attributes }) => ({
            type,
            primary_email: attributes?.primary_email
        })
    });

    /***** RENDER *****/
    return (
        <Title
            serviceTitle={domain}
            serviceSubTitle={product?.attributes.name}
            serviceType={type}
            useSubtitle
            subtitleData={{
                icon: 'email',
                title: primary_email ?? ''
            }}
            action={[
                {
                    className: 'listAccount__action',
                    label: 'List Accounts',
                    color: 'secondary',
                    type: 'onClick',
                    onClick: () =>
                        navigate({
                            to: '/my-services/google/$workspaceId/manage',
                            params: { workspaceId },
                            hash: 'overview'
                        })
                },
                <GoogleTitleDropdown key="dropdown" />
            ]}
        />
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { createRoute, redirect } from '@tanstack/react-router';

/**********************************************************************************************************
 *   ROUTE IMPORTS
 **********************************************************************************************************/
import { AppRoute } from 'App';
import type { GetOptionTypeFromRouteId } from 'router/types';
import { routerMiddleware } from 'router/utils/middleware';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { z } from 'zod';

/**********************************************************************************************************
 *   ROUTE SCHEMA
 **********************************************************************************************************/
const searchSchema = z.object({
    code: z.coerce.string().optional(),
    state: z.coerce.string().optional(),
    error_reason: z.coerce.string().optional()
});

/**********************************************************************************************************
 *   ROUTE START
 **********************************************************************************************************/
export const KatanaAuthorizeRoute = createRoute({
    getParentRoute: () => AppRoute,
    path: 'katana/authorize',
    beforeLoad(opts) {
        routerMiddleware.business(this, opts);
        routerMiddleware.authentication('user', opts);
        authorizeMiddleware(opts);
    },
    validateSearch: searchSchema
});

function authorizeMiddleware(opts: GetOptionTypeFromRouteId<'/katana/authorize', 'beforeLoad'>) {
    if (opts.location.pathname === '/katana/authorize') throw redirect({ to: '/dashboard' });
    if (opts.location.pathname === '/katana/authorize/') throw redirect({ to: '/dashboard' });
}

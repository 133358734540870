/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React from 'react';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import { Revealer } from 'components/Revealer';
import { NXTransitionDelayedUnmount } from 'components/Transition/NXTransitionDelayedUnmount';
import Text from 'components/Utils/Text';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/

/**
 * Field Validation Error or Confirmation
 * @type {React.FC<{
 *      children: React.ReactElement | React.ReactNode;
 *      open?: boolean;
 *      warn?: boolean;
 *      confirm?: boolean;
 * }>}
 */
const _ValidationMessage = ({ children, warn, confirm, open }) => (
    <Revealer className="ValidationMessage" isOpen={Boolean(children && open)}>
        <Text size--xss lead-xs warn={warn} confirm={confirm} align--left>
            <div>
                <NXTransitionDelayedUnmount>{children}</NXTransitionDelayedUnmount>
            </div>
        </Text>
    </Revealer>
);
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

export { _ValidationMessage };

/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import classNames from 'classnames';
import React, { useRef } from 'react';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import BackgroundImage from 'components/BackgroundImage';
import NXCard from 'components/NXCard';
import { NXSquare } from 'components/Utils/NXSquare';
import Text from 'components/Utils/Text';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { getKatanaSectionIDColour } from 'containers/katana/methods';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import ErrorImage from 'assets/images/404_page.png';
import './_contentOptionCard.scss';

/**********************************************************************************************************
 *   TYPE IMPORTS
 **********************************************************************************************************/
import type { ContentOptionCardNamespace } from 'containers/katana/components/contentOptionCard/types';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/

export const ContentOptionCard: React.FC<ContentOptionCardNamespace.Props> = ({
    cardImageURL,
    name = '',
    description = '',
    tooltipMessage,
    sectionDefinitionID,
    children
}) => {
    const { current } = useRef(cardImageURL ?? ErrorImage);
    /***** RENDER *****/
    return (
        <NXCard border className={classNames('ContentOptionCard', `ContentOptionCard--${getKatanaSectionIDColour(sectionDefinitionID)}`)}>
            <NXCard.Content>
                <NXSquare ratio={0.54}>
                    <BackgroundImage imageURL={current} contain fill />
                </NXSquare>
            </NXCard.Content>
            {tooltipMessage ? <NXCard.ToolTip>{tooltipMessage}</NXCard.ToolTip> : ''}
            <NXCard.Actions column align--start>
                <Text medium black size--m>
                    {name}
                </Text>
                <Text medium secondary size--s className="ContentOptionCard__description">
                    {description}
                </Text>
            </NXCard.Actions>
            {children}
        </NXCard>
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

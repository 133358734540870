/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React from 'react';
import { z } from 'zod';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import RequestLoader from 'components/Loaders/Request';
import { KatanaHookFormHandler } from 'containers/katana/components/HookFormHandler';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { useColourThemeDefaultFormValues } from 'containers/katana/containers/ContentEditorLightbox/formHandlers/webpageColourThemeFormHandler/useColourThemeDefaultFormValues';
import { useHandleColorThemeSubmit } from 'containers/katana/containers/ContentEditorLightbox/formHandlers/webpageColourThemeFormHandler/useHandleColourThemeSubmit';
import { requiredFieldErrorMessage } from 'utilities/methods/form';

/**********************************************************************************************************
 *   TYPE IMPORTS
 **********************************************************************************************************/
import type { KatanaNamespace } from 'containers/katana/types';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/

const webPageColourThemeFormHandlerZodSchema = z.object({
    color: z.string({ message: requiredFieldErrorMessage }),
    style: z.object({
        fonts: z.object({
            body: z.string().optional(),
            heading: z.string().optional()
        })
    })
});

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type WebPageColourThemeFormHandlerProps = {
    subpageRouteData: KatanaNamespace.SubPageRouteData;
};

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const WebPageColourThemeFormHandler: React.FC<WebPageColourThemeFormHandlerProps> = ({ subpageRouteData }) => {
    /***** HOOKS *****/
    const defaultValues = useColourThemeDefaultFormValues(subpageRouteData);
    const handleSubmit = useHandleColorThemeSubmit(subpageRouteData);

    /***** RENDER *****/
    if (!defaultValues) {
        return <RequestLoader message="Loading Colours & Fonts..." />;
    }

    return (
        <KatanaHookFormHandler handleOnSubmit={handleSubmit} defaultValues={defaultValues} zodSchema={webPageColourThemeFormHandlerZodSchema}>
            {subpageRouteData.modules}
        </KatanaHookFormHandler>
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

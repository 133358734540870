/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { useSelector } from 'react-redux';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import RequestLoader from 'components/Loaders/Request';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * Temporary loading component until we have a full page loader available.
 */
export const HostingRouteLoader = ({ children }) => {
    /***** HOOKS *****/
    const isLoading = useSelector((state: any) => state.hosting.hosting_information_status === 'loading');
    const hasData = useSelector((state: any) => !!state.hosting.hosting_information_data);

    if (isLoading || !hasData) {
        return <RequestLoader />;
    }

    /***** RENDER *****/
    return children;
};

/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import _ from 'lodash';
import { useEffect, useMemo } from 'react';
import { Field } from 'redux-form';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import { CheckBoxList } from 'components/Form/CheckBoxList';
import { FormItem } from 'components/Form/FormItem';
import { FormLabel } from 'components/Form/FormLabel';
import { Input } from 'components/Form/Input';
import { Radio } from 'components/Form/Radio';
import { Revealer } from 'components/Revealer';
import { Flex } from 'components/Utils/Flex';
import Text from 'components/Utils/Text';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import NXDropZoneWithKatanaFileUploadAndControl from 'components/Form/NXDropZone/variants/NXDropZoneWithKatanaFileUploadAndControl';
import { useDynamicFormActions } from 'containers/katana/components/dynamicFormFieldRenderer/hooks/useDynamicFormActions';
import { fieldLabelWithOptional } from 'containers/katana/components/fieldLabelWithOptional';
import { useGetRoutableCallToActionSections } from 'containers/katana/containers/contentEditorModules/callToAction/useGetRoutableCallToActionSections';
import { RenderSelectField } from 'utilities/methods/form';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { useGetDynamicFormFieldValues } from 'containers/katana/components/dynamicFormFieldRenderer/hooks/useGetDynamicFormFieldValue';
import { getKatanaFormFieldValidation } from 'containers/katana/formFields/methods/validations/getKatanaFormFieldValidation';
import { useKatanaParams } from 'containers/katana/hooks/useSetupEditorRouteParams';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import { CallToActionType } from 'containers/katana/containers/contentEditorModules/callToAction/consts';

/**********************************************************************************************************
 *   TYPE IMPORTS
 **********************************************************************************************************/
import type { KatanaNamespace } from 'containers/katana/types';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const KatanaGroupFormFieldCallToAction: KatanaNamespace.PropertyComponent = ({ property }) => {
    const { key, name = '', properties = [] } = property;
    /***** HOOKS *****/
    const { katanaServiceId } = useKatanaParams();
    const routableOptions = useGetRoutableCallToActionSections();
    const { dynamicChange } = useDynamicFormActions();
    const formValues = useGetDynamicFormFieldValues();

    /***** RENDER HELPERS *****/
    const currentFormValues = _.get(formValues.values, key);
    const isLinkOrSection = [CallToActionType.LINK, CallToActionType.SECTION].includes(currentFormValues?.type);

    const enabledProperty = properties?.find((property) => property.originalKey === 'enabled');
    const showEnableButtonCheckbox = Boolean(enabledProperty?.validation?.find((validation) => validation.name === 'required'));

    const validations = useMemo(() => {
        function getValidationFunctionsForKey(key) {
            const _property = properties?.find((property) => property.originalKey === key);
            if (!_property || !_.has(_property, 'validation')) {
                return null;
            }
            return getKatanaFormFieldValidation({ property: _property, formValues: formValues.values });
        }

        return {
            type: getValidationFunctionsForKey('type'),
            text: getValidationFunctionsForKey('text'),
            value: getValidationFunctionsForKey('value'),
            section: getValidationFunctionsForKey('section'),
            enabled: getValidationFunctionsForKey('enabled')
        };
    }, [properties, formValues]);

    useEffect(() => {
        // Only do dynamic change if the value for type is falsy
        if (!currentFormValues?.type) {
            dynamicChange(`${key}.type`, CallToActionType.LINK);
        }
    }, [formValues]);

    /***** RENDER *****/
    return (
        <FormItem name={key} className="KatanaGroupFieldCallToAction">
            <FormLabel htmlFor={key}>{name}</FormLabel>

            <Field
                className="KatanaGroupFieldCallToAction"
                fullWidth
                component={Radio.Circle.Button.ActiveRender.ReduxForm}
                name={`${key}.type`}
                validate={validations.type}
                onSelect={(newSelection) => {
                    if (newSelection === currentFormValues?.type) {
                        return;
                    }
                    dynamicChange(`${key}.text`, null);
                    dynamicChange(`${key}.value`, null);
                }}
            >
                {!showEnableButtonCheckbox && (
                    <Field name={`${key}.enabled`} component={CheckBoxList.Item.ReduxForm} validate={validations.enabled}>
                        Display customer engagement button
                    </Field>
                )}
                {currentFormValues?.enabled !== false && (
                    <>
                        <Radio.Circle.Button.ActiveRender.Item
                            color="confirm"
                            label="A button that takes the user somewhere"
                            value={currentFormValues?.type === CallToActionType.LINK ? CallToActionType.LINK : CallToActionType.SECTION}
                            isSelected={isLinkOrSection}
                        >
                            {({ shouldRenderChildren }) => (
                                <Revealer isOpen={shouldRenderChildren}>
                                    <CheckBoxList>
                                        <CheckBoxList>
                                            <Field
                                                label={
                                                    <Text size--m medium black>
                                                        What will the button say?
                                                    </Text>
                                                }
                                                name={`${key}.text`}
                                                component={Input.ReduxForm}
                                                placeholder="E.g. Get Started"
                                                validate={validations.text}
                                            />
                                            <Field
                                                name={`${key}.type`}
                                                label={
                                                    <Text size--m medium black>
                                                        Where will it go when clicked?
                                                    </Text>
                                                }
                                                component={Radio.Circle.Button.ActiveRender.ReduxForm}
                                                validate={validations.type}
                                                onSelect={() => {
                                                    dynamicChange(`${key}.value`, null);
                                                }}
                                            >
                                                <Radio.Circle.Button.ActiveRender.Item
                                                    color="confirm"
                                                    label="Somewhere on my website"
                                                    value={CallToActionType.SECTION}
                                                >
                                                    <Field
                                                        name={`${key}.section`}
                                                        component={RenderSelectField}
                                                        options={routableOptions}
                                                        validate={validations.section}
                                                    />
                                                </Radio.Circle.Button.ActiveRender.Item>

                                                <Radio.Circle.Button.ActiveRender.Item
                                                    color="confirm"
                                                    label="To an external URL"
                                                    value={CallToActionType.LINK}
                                                >
                                                    <Field
                                                        name={`${key}.value`}
                                                        component={Input.ReduxForm}
                                                        placeholder="E.g. https://ventraip.com.au/"
                                                        validate={validations.value}
                                                    />
                                                </Radio.Circle.Button.ActiveRender.Item>
                                            </Field>
                                        </CheckBoxList>
                                    </CheckBoxList>
                                </Revealer>
                            )}
                        </Radio.Circle.Button.ActiveRender.Item>

                        <Radio.Circle.Button.ActiveRender.Item
                            color="confirm"
                            label="A button that shows my phone number"
                            value={CallToActionType.PHONE}
                            // disabled={!get_katana_site_business_info?.phone}
                        >
                            {({ shouldRenderChildren }) => (
                                <>
                                    {/* {!get_katana_site_business_info?.phone ? (
                                        <NoPhoneWarningMessage />
                                    ) : ( */}
                                    <Revealer isOpen={shouldRenderChildren}>
                                        <CheckBoxList>
                                            <CheckBoxList>
                                                <Flex direction="column">
                                                    <div>
                                                        <Field
                                                            label={
                                                                <Text black size--m medium>
                                                                    {fieldLabelWithOptional('Pretext')}
                                                                </Text>
                                                            }
                                                            name={`${key}.text`}
                                                            component={Input.ReduxForm}
                                                            validate={validations.text}
                                                            placeholder="E.g. Call Us on"
                                                        />
                                                        <Text secondary size--s italic>
                                                            This text will be appended before your phone number.
                                                        </Text>
                                                    </div>
                                                    <Flex direction="column" gap={1}>
                                                        <Text black size--m medium>
                                                            Business phone number
                                                        </Text>
                                                        {/* <FormItem name="phone"> */}
                                                        {/* <Input name="phone" disabled value={get_katana_site_business_info?.phone} /> */}
                                                        <Field
                                                            name={`${key}.value`}
                                                            component={Input.ReduxForm}
                                                            placeholder="04 1234 5678"
                                                            validate={validations.value}
                                                        />
                                                        {/* <Text secondary size--s italic>
                                                                The phone number above is taken from the phone number used in the Basic Details
                                                                section a few steps back.
                                                            </Text> */}
                                                        {/* </FormItem> */}
                                                    </Flex>
                                                </Flex>
                                            </CheckBoxList>
                                        </CheckBoxList>
                                    </Revealer>
                                    {/* )} */}
                                </>
                            )}
                        </Radio.Circle.Button.ActiveRender.Item>

                        <Radio.Circle.Button.ActiveRender.Item color="confirm" label="A button that downloads a file" value={CallToActionType.FILE}>
                            {({ shouldRenderChildren }) => (
                                <>
                                    <Revealer isOpen={shouldRenderChildren}>
                                        <CheckBoxList>
                                            <CheckBoxList>
                                                <Flex direction="column">
                                                    <div>
                                                        <Field
                                                            label={
                                                                <Text black size--m medium>
                                                                    What will the button say?
                                                                </Text>
                                                            }
                                                            name={`${key}.text`}
                                                            component={Input.ReduxForm}
                                                            validate={validations.text}
                                                            placeholder="E.g. Download takeaway menu"
                                                        />
                                                    </div>
                                                    <Flex direction="column" gap={1}>
                                                        <Text black size--m medium>
                                                            Download file
                                                        </Text>
                                                        <Field
                                                            name={`${key}.file`}
                                                            component={NXDropZoneWithKatanaFileUploadAndControl}
                                                            accept={{ 'application/pdf': ['.pdf'] }}
                                                            singleFile
                                                            serviceID={katanaServiceId}
                                                            currentFormValues={currentFormValues}
                                                        />
                                                    </Flex>
                                                </Flex>
                                            </CheckBoxList>
                                        </CheckBoxList>
                                    </Revealer>
                                </>
                            )}
                        </Radio.Circle.Button.ActiveRender.Item>
                    </>
                )}
            </Field>
        </FormItem>
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

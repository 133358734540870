import { FormItem } from 'components/Form/FormItem';
import { HookForm } from 'components/Form/HookForm';
import { ValidationMessage } from 'components/Form/ValidationMessage';
import { socialFormatFilter } from 'containers/katana/containers/contentEditorModules/socialsMetaInput/socialFormatFilter';
import { SocialsMetaInputChild } from 'containers/katana/containers/contentEditorModules/socialsMetaInput/SocialsMetaInputChild';
import type { SocialsMetaInputNamespace } from 'containers/katana/containers/contentEditorModules/socialsMetaInput/types';
import { useKatanaParams } from 'containers/katana/hooks/useSetupEditorRouteParams';
import { katanaQuery } from 'containers/katana/queries/tanstackTree';
import { useUpdateSocialLinksMutation } from 'containers/katana/queries/useUpdateSocialLinksMutation';
import _ from 'lodash';
import { useMemo } from 'react';
import { useController } from 'react-hook-form';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
const { SOCIALS: name } = useUpdateSocialLinksMutation.FORM_FIELD_KEYS;

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const SocialsMetaInput = () => {
    /***** HOOKS *****/
    const { katanaServiceId } = useKatanaParams();

    /***** QUERIES *****/
    const { data: get_socials_meta_data } = katanaQuery.serviceID.meta.getSocialsDefinitions.useQuery(katanaServiceId);

    /***** HOOKS *****/
    const { field, fieldState } = useController({ name });
    const { onBlur } = field;

    /***** RENDER HELPERS *****/
    const meta = HookForm.getMeta(fieldState);
    const socialFields: Array<SocialsMetaInputNamespace.InputField> = useMemo(
        () => _.map(get_socials_meta_data, socialFormatFilter),
        [get_socials_meta_data]
    );

    /***** RENDER *****/
    return (
        <FormItem name={name}>
            {socialFields.map((fieldContent, index) => {
                return <SocialsMetaInputChild onBlur={onBlur} key={name + fieldContent.type} index={index} {...fieldContent} />;
            })}
            <ValidationMessage.HookForm.Default {...meta} />
        </FormItem>
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

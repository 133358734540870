/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { _LazyHookFormDevTool as LazyHookFormDevTool } from 'components/Form/HookForm/_LazyHookFormDevTool';
import { _getMeta as getMeta } from 'components/Form/HookForm/getMeta';

/**
 * HookForm Utilities
 */
export const HookForm = Object.freeze({
    DevTool: LazyHookFormDevTool,
    getMeta
});

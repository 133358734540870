import { DndContext, KeyboardSensor, PointerSensor, useSensor, useSensors } from '@dnd-kit/core';
import { restrictToParentElement, restrictToVerticalAxis } from '@dnd-kit/modifiers';
import { SortableContext, sortableKeyboardCoordinates, verticalListSortingStrategy } from '@dnd-kit/sortable';
import _ from 'lodash';
import React, { useLayoutEffect, useMemo, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { SORTABLE_ID_KEY } from 'containers/katana/formFields/repeated/SortableRepeatedWrapper/consts';
import { sectionDefinitionUnsplashDownloadLinks } from 'containers/katana/containers/ContentEditorLightbox/formHandlers/consts';
import type { FieldArrayFieldsProps } from 'redux-form';
import type { KatanaNamespace } from 'containers/katana/types';
import type { DragEndEvent } from '@dnd-kit/core';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type SortableRepeatedWrapperProps = {
    fields: FieldArrayFieldsProps<unknown>;
    children: any;
    property: KatanaNamespace.SectionDefinitions.PropertiesModified;
};

const modifiers = [restrictToVerticalAxis, restrictToParentElement];
const keyboardSensorOption = {
    coordinateGetter: sortableKeyboardCoordinates
};

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
const SortableRepeatedWrapper: React.FC<SortableRepeatedWrapperProps> = ({ fields, children, property }) => {
    /***** STATE *****/
    const [items, setItems] = useState([]);

    /***** HOOKS *****/
    const sensors = useSensors(useSensor(PointerSensor), useSensor(KeyboardSensor, keyboardSensorOption));

    /***** FUNCTIONS *****/
    function handleDragEnd(event: DragEndEvent) {
        const { active, over } = event;

        if (!over?.id || active.id === over?.id) {
            return;
        }

        const oldIndex = items.indexOf(active.id);
        const newIndex = items.indexOf(over.id);

        fields.move(oldIndex, newIndex);

        if (property.key.match(/(properties\.work_project\[)\d(\]\.images)/)) {
            const links = Object.entries(sectionDefinitionUnsplashDownloadLinks.state);

            const filteredLinks = links.filter(([key, value]) => {
                return key.includes(property.key);
            });

            const existingLinks = links.filter(([key, value]) => {
                return !key.includes(property.key);
            });

            const updatedLinkOrder = filteredLinks.map(([key, value]) => {
                const indexKey = key.replace(property.key, '');
                const indexDigit = indexKey.match(/[\d]/);
                if (indexDigit) {
                    const indexDigitMatch = Number(indexDigit[0]);
                    if (indexDigitMatch === oldIndex) {
                        const updatedKey = `${property.key}[${newIndex}].image`;
                        return [updatedKey, value] as const;
                    }
                    if (indexDigitMatch > oldIndex && indexDigitMatch <= newIndex) {
                        const updatedKey = `${property.key}[${indexDigitMatch - 1}].image`;
                        return [updatedKey, value] as const;
                    } else if (indexDigitMatch < oldIndex && indexDigitMatch >= newIndex) {
                        const updatedKey = `${property.key}[${indexDigitMatch + 1}].image`;
                        return [updatedKey, value] as const;
                    }
                }
                return [key, value] as const;
            });

            const mergedLinks = [...existingLinks, ...updatedLinkOrder];
            sectionDefinitionUnsplashDownloadLinks.setState(() => Object.fromEntries(mergedLinks));
        } else if (property.key.split('.').length === 2) {
            // the if statement here aims to match the cases which property.key equals to either "properties.work_project" or "properties.service"
            const keySecondNode = property.key.split('.')[1];
            const links = Object.entries(sectionDefinitionUnsplashDownloadLinks.state);
            const updatedLinkOrder = links.map(([key, value]) => {
                const indexDigit = key.match(/[\d]/);
                if (indexDigit) {
                    const indexDigitMatch = Number(indexDigit[0]);
                    if (indexDigitMatch === oldIndex) {
                        const updatedKey = key.replace(`${keySecondNode}[${indexDigitMatch}]`, `${keySecondNode}[${newIndex}]`);
                        return [updatedKey, value] as const;
                    } else if (indexDigitMatch > oldIndex && indexDigitMatch <= newIndex) {
                        const updatedKey = key.replace(`${keySecondNode}[${indexDigitMatch}]`, `${keySecondNode}[${indexDigitMatch - 1}]`);
                        return [updatedKey, value] as const;
                    } else if (indexDigitMatch < oldIndex && indexDigitMatch >= newIndex) {
                        const updatedKey = key.replace(`${keySecondNode}[${indexDigitMatch}]`, `${keySecondNode}[${indexDigitMatch + 1}]`);
                        return [updatedKey, value] as const;
                    }
                }
                return [key, value] as const;
            });

            sectionDefinitionUnsplashDownloadLinks.setState(() => Object.fromEntries(updatedLinkOrder));
        }
    }

    /***** EFFECTS *****/
    useLayoutEffect(() => {
        const allFields = fields.getAll();
        if (!allFields?.length) {
            setItems([]);
            return;
        }
        allFields.forEach((field) => {
            if (!_.has(field, SORTABLE_ID_KEY)) {
                field[SORTABLE_ID_KEY] = uuidv4();
            }
        });
        const fieldItems = allFields.map((field) => field[SORTABLE_ID_KEY]);
        setItems(fieldItems);
    }, [fields]);

    /***** RENDER HELPERS *****/
    const filteredResults = useMemo(() => {
        const mappedFieldsData = fields.map((member, index, fields) => ({ member, index, fields }));

        function findField(id: string): NSortableRepeatedWrapper.FieldData | undefined {
            return mappedFieldsData.find(({ index, fields }) => fields?.get(index)?.[SORTABLE_ID_KEY] === id);
        }
        return items.filter(findField).map((id) => ({ ...findField(id), [SORTABLE_ID_KEY]: id }));
    }, [items, fields]);

    /***** RENDER *****/
    return (
        <DndContext sensors={sensors} onDragEnd={handleDragEnd} modifiers={modifiers}>
            <SortableContext items={items} strategy={verticalListSortingStrategy}>
                {children({ filteredResults })}
            </SortableContext>
        </DndContext>
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

export { SortableRepeatedWrapper };

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { getRepeatedValidationMinTypeMessage } from 'utilities/methods/form/validations/validationMinimum';
import { validateMinimum } from 'utilities/methods/validators';

/**********************************************************************************************************
 *   TYPE IMPORTS
 **********************************************************************************************************/
import type { KatanaNamespace } from 'containers/katana/types';

export function formFieldsMinRuleParser(validation: KatanaNamespace.SectionDefinitions.PropertiesModified['validation'][number]) {
    return (value: any) => {
        const textResult = getRepeatedValidationMinTypeMessage(value, validation?.value);
        const result = validateMinimum(value, validation?.value);
        return result ? undefined : textResult;
    };
}

/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import axios from 'axios';

export let cancelDashboardServices;

export const API = {
    /**********************************************************************************************************
     *   DASHBOARD API
     **********************************************************************************************************/

    dashboard: {
        get: {
            tickets: () => {
                return axios.get('/api/support/e-ticket/active');
            },

            alerts: () => {
                return axios.get('/api/user/dashboard/alerts');
            },

            /**
             * @returns {Promise<Artah.AlertBanner.GET.All.Promise>}
             */
            alert: () => {
                return axios.get('/api/alert-banner');
            }
        },

        post: {
            services: (attributes) => {
                return axios.post('/api/user/dashboard/services/custom', {
                    attributes
                });
            }
        }
    },

    referral: {
        get: {
            code: () => {
                return axios.get(`/api/referrals`);
            }
        },
        post: {
            createCode: () => {
                return axios.post(`/api/referrals`);
            },

            sendCode: (attributes) => {
                return axios.post(`/api/referrals/send`, {
                    type: 'referral',
                    attributes
                });
            }
        }
    }
};

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import useBooleanEffect from 'utilities/hooks/useBooleanEffect';

/**********************************************************************************************************
 *   HOOK START
 **********************************************************************************************************/
/**
 * Custom hook to handle controlled or uncontrolled dropdown behavior.
 */
export function useControlledReveal(isOpen = false, onClick?: () => void) {
    /***** STATE *****/
    const { value: _isOpen, toggle: toggleOpen } = useBooleanEffect(isOpen);

    /***** HOOK RESULTS *****/
    return {
        isOpen: isOpen || _isOpen,
        onClick: onClick || toggleOpen
    };
}
/**********************************************************************************************************
 *   HOOK END
 **********************************************************************************************************/

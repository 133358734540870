/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import classNames from 'classnames';
import React from 'react';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type DefaultImageComponent = React.FC<{
    src: string;
    alt: string;
    className?: string;
}>;

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const DefaultImage: DefaultImageComponent = ({ src, alt, className = 'rewardCard__bg--dotStore' }) => {
    /***** RENDER *****/
    return (
        <div className={classNames('rewardCard__bg', className)}>
            <img src={src} alt={alt} />
        </div>
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

/**********************************************************************************************************
 *   IMPORTS
 **********************************************************************************************************/
import { DateTime } from 'luxon';

/**********************************************************************************************************
 *   ADDONS
 **********************************************************************************************************/
export const invoiceMethods = {
    handleDateRender(status, date) {
        if (
            !date ||
            typeof status !== 'string' ||
            ['cancelled', 'refunded', 'fraud'].includes(status.toLowerCase()) ||
            !DateTime.fromFormat(date, 'yyyy-MM-dd TT').isValid
        ) {
            return '';
        }

        return DateTime.fromFormat(date, 'yyyy-MM-dd TT').toFormat('dd MMM yyyy');
    }
};

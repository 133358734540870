/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React from 'react';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import Border from 'components/Utils/Border';
import { NXBoxDropdownCloseButton } from './CloseButton';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type TNXBoxDropdown = {
    isOpen: boolean;
    embedded?: boolean;
    children: React.ReactNode;
};

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * Renders its children with a top border when open, otherwise renders nothing
 */
const _NXBoxDropdown: React.FC<TNXBoxDropdown> = ({ isOpen, embedded, children }) => {
    /***** RENDER *****/
    if (!isOpen) {
        return null;
    }

    return (
        <Border top={!embedded} colour="C_NXBoxSplit__section_color_border">
            <div className="NXBoxDropdown">{children}</div>
        </Border>
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

export const NXBoxDropdown = Object.assign(_NXBoxDropdown, {
    CloseButton: NXBoxDropdownCloseButton
});

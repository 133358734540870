/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import { validationRuleTypes } from 'containers/katana/formFields/validationRuleTypes';

/**********************************************************************************************************
 *   TYPE IMPORTS
 **********************************************************************************************************/
import type { ValidationRuleTypesValues } from 'containers/katana/formFields/types';

// "exclude_if" not added yet
const { STRING, URL, REQUIRED, IN, MIN, MAX, REQUIRED_IF, REQUIRED_UNLESS } = validationRuleTypes;

export const supportedValidations: ValidationRuleTypesValues[] = [URL, STRING, REQUIRED, IN, MIN, MAX, REQUIRED_IF, REQUIRED_UNLESS];

/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React from 'react';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import { PhosphorIcons } from 'components/Icons/Phosphor';
import Tooltip from 'components/Tooltip';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import './__NXCardToolTip.scss';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
function _NXCardToolTip({ children }) {
    /***** RENDER *****/
    return (
        <div className="NXCardToolTip">
            <div className="NXCardToolTip__wrapper">
                <Tooltip info={children} iconOverride={<PhosphorIcons.Question size={24} secondary />} />
            </div>
        </div>
    );
}
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

export { _NXCardToolTip };

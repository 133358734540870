/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { ventraSynMigrationBanners } from 'config/config';
import { useSelector } from 'react-redux';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import { useParams } from '@tanstack/react-router';
import VentraSynBanner from 'containers/ventraSynMigration/modules/banner';

export const ConditionalVentraSynBanner = () => {
    /***** HOOKS *****/
    const vsm_requires_migration_data = useSelector((state: any) => state.vsm.vsm_requires_migration_data);
    const vsm_reseller_list_data = useSelector((state: any) => state.vsm.vsm_reseller_list_data);
    const { serviceId } = useParams({ from: '/my-services/hosting/$serviceId' });

    /***** RENDER *****/
    switch (true) {
        case !ventraSynMigrationBanners:
        case !vsm_requires_migration_data?.requires_migration:
        case !vsm_reseller_list_data?.find(({ id }) => String(id) === serviceId):
            return null;
        default:
            return <VentraSynBanner />;
    }
};

/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React from 'react';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import BackgroundImage from 'components/BackgroundImage';
import SolidButton from 'components/Buttons/SolidButton';
import Text from 'components/Utils/Text';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { useAppViewport } from 'utilities/hooks/useAppViewport/useAppViewport';
import { scrollToRef } from 'utilities/methods/commonActions';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import BackgroundMobile from '../../assets/dashboard-banner-background-mobile.png';
import BackgroundTablet from '../../assets/dashboard-banner-background-tablet.png';
import Background from '../../assets/dashboard-banner-background.svg';
import Logo from '../../assets/dashboard-banner-logo.svg';
import TapeMobile from '../../assets/tape-green-dashboard-banner-mobile.svg';
import Tape from '../../assets/tape-green-dashboard-banner.svg';

import './_megamay24DashboardBanner.scss';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export function MegaMayDashboardBanner({ promoDealsRef }) {
    /***** HOOKS *****/
    const isMobile = useAppViewport(['sm', 'xs']);
    const isTablet = useAppViewport(['md']);

    /***** RENDER HELPERS *****/
    function getBackgroundImage() {
        if (isMobile) {
            return BackgroundMobile;
        }
        if (isTablet) {
            return BackgroundTablet;
        }
        return Background;
    }

    /***** RENDER *****/
    return (
        <BackgroundImage className="mmDashboardBanner" imageURL={getBackgroundImage()} cover>
            <div className="mmDashboardBanner__content">
                <img className="mmDashboardBanner__logo" src={Logo} alt="MegaMay Spin to Win" />
                <div className="mmDashboardBanner__tape">
                    <img src={isMobile ? TapeMobile : Tape} alt="Background color green" />
                    <div className="mmDashboardBanner__text">
                        <Text lead--1 align--center={isMobile}>
                            <h3>Win a Trip to Fiji</h3>
                        </Text>
                        <Text black bold lead--xs align--center={isMobile}>
                            Pay any invoice in May for your chance
                            {isMobile ? '' : <br />} to spin the wheel and enter the draw!*
                        </Text>
                    </div>
                    <SolidButton
                        color="white"
                        type="onClick"
                        onClick={() => {
                            scrollToRef(promoDealsRef?.current);
                        }}
                    >
                        See The Deals
                    </SolidButton>
                    <Text white size--xs align--center italic className="mmDashboardBanner__tandc">
                        *Full Terms and Conditions. NSW Authority No. TP/03444. ACT Permit No. TP 24/00854. SA Licence No. T24/623.
                    </Text>
                </div>
            </div>
        </BackgroundImage>
    );
}

MegaMayDashboardBanner.MEGAMAY24_DISMISS_KEY = 'MEGAMAY24_DISMISS_KEY';
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React from 'react';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import OutlineButton from 'components/Buttons/OutlineButton';
import Grid from 'components/Grid';
import RequestLoader from 'components/Loaders/Request';
import OutlineTag from 'components/Tags/OutlineTag';
import Tooltip from 'components/Tooltip';
import Border from 'components/Utils/Border';
import { Flex } from 'components/Utils/Flex';
import Padding from 'components/Utils/Padding';
import Text from 'components/Utils/Text';
import { WrapWithComponent } from 'components/WrapWithComponent';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import CheckListItems from 'containers/katana/modules/launchChecklist/checkListItems';
import SitePreviewContent from 'containers/katana/modules/presetContent/SitePreviewContent';

/**********************************************************************************************************
 *   QUERIES
 **********************************************************************************************************/
import { katanaQuery } from 'containers/katana/queries/tanstackTree';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { useKatanaServiceSiteListSetupURL } from 'containers/katana/hooks/useKatanaServiceSetupURL';
import { useAppViewport } from 'utilities/hooks/useAppViewport/useAppViewport';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import './_katanaServiceListEntryVisual.scss';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
import type { KatanaNamespace } from 'containers/katana/types';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type KatanaServiceListEntryVisualComponent = React.FC<{
    katanaSiteData: Artah.Katana.GET._200['data'][number];
}>;

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
const KatanaServiceListEntryVisual: KatanaServiceListEntryVisualComponent = ({ katanaSiteData: { attributes, id: serviceID } }) => {
    /***** HOOKS *****/
    const finalKatanaURL = useKatanaServiceSiteListSetupURL(serviceID);
    const isTabletAndSmaller = useAppViewport(['md', 'sm', 'xs']);

    /***** QUERIES *****/
    const { data: dnsData, isLoading } = katanaQuery.serviceID.dns.useQuery(serviceID);

    /***** FUNCTIONS *****/
    function handlePrefetch() {
        if (!serviceID) {
            return;
        }
        katanaQuery.serviceID.getService.prefetchQuery(serviceID);
    }

    /***** RENDER HELPERS *****/
    const status = attributes?.site_status as KatanaNamespace.SiteStatus;
    const serviceStatus = attributes?.status as Service.Status;
    const isPendingCancellation = serviceStatus === 'pending cancellation';
    function renderListEntrySiteInfo() {
        if (isLoading) {
            return (
                <OutlineTag color="notice">
                    <RequestLoader />
                </OutlineTag>
            );
        }
        if (isPendingCancellation) {
            return <OutlineTag color="info">Pending Cancellation</OutlineTag>;
        }
        switch (status) {
            case 'in-progress':
                return <OutlineTag color="notice">In Progress</OutlineTag>;

            case 'published':
                return (
                    <Flex items="center">
                        <OutlineTag color={dnsData?.is_site_live ? 'confirm' : 'notice'}>
                            {dnsData?.is_site_live ? 'Published' : 'Published - DNS Propagation'}
                        </OutlineTag>
                        <Tooltip info="Your website has been published. Please allow up to 24 hours for DNS propagation to take place for your VIPsites website to appear online." />
                    </Flex>
                );

            case 'unpublished':
                return <OutlineTag color="notice">Unpublished</OutlineTag>;

            case 'suspended':
                return <OutlineTag color="warn">Suspended</OutlineTag>;

            default:
                return <OutlineTag color="primary">Unconfigured</OutlineTag>;
        }
    }

    function renderActionButton() {
        return (
            <OutlineButton disabled={isPendingCancellation || status === 'suspended'} to={finalKatanaURL} onMouseOver={handlePrefetch} size="small">
                {attributes?.is_setup_complete ? 'Manage' : 'Continue'}
            </OutlineButton>
        );
    }

    const isPublished = status === 'published';
    /***** RENDER *****/
    return (
        <Border all>
            <Flex direction="column" gap={0} fullHeight>
                <Border bottom>
                    <SitePreviewContent serviceID={serviceID} ratio={0.5} />
                </Border>
                <Flex.Child grow inject>
                    <Padding paddingOnly xy={4} inject>
                        <Flex alignChildren="stretch" fullWidth>
                            <Grid
                                columns={isTabletAndSmaller ? '100%' : '1fr 150px'}
                                justify-children--stretch
                                alignItems--stretch
                                gap={isTabletAndSmaller ? 3 : 4}
                                fullWidth
                            >
                                <Flex direction="column" justify="between" items="start" fullHeight>
                                    <Text size--s black medium lead--1>
                                        {attributes?.name}
                                    </Text>

                                    <WrapWithComponent component={Grid} wrap={isTabletAndSmaller} columns="1fr 1fr" fullWidth>
                                        {isPublished || isTabletAndSmaller ? (
                                            renderListEntrySiteInfo()
                                        ) : (
                                            <Flex
                                                justify="between"
                                                fullWidth
                                                relative
                                                className="KatanaServiceListEntryVisual__checkListItemsWrapper"
                                            >
                                                <CheckListItems
                                                    showIconHoverToolTip
                                                    circleSize={36}
                                                    serviceID={serviceID}
                                                    showItemTitle={false}
                                                    showItemButton={false}
                                                    separateLinePath
                                                />
                                            </Flex>
                                        )}
                                        {isTabletAndSmaller && renderActionButton()}
                                    </WrapWithComponent>
                                </Flex>
                                {!isTabletAndSmaller && (
                                    <Flex direction="column" justify="center">
                                        {!isPublished && renderListEntrySiteInfo()}
                                        {renderActionButton()}
                                    </Flex>
                                )}
                            </Grid>
                        </Flex>
                    </Padding>
                </Flex.Child>
            </Flex>
        </Border>
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

export { KatanaServiceListEntryVisual };

/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import classNames from 'classnames';
import React, { useEffect, useMemo, useRef } from 'react';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import { _List as List } from 'components/Revealer/_List';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { useControlledReveal } from 'components/Revealer/hooks/useControlledReveal';
import { useElementHeight } from 'utilities/hooks/useElementHeight';
import { useTransitional } from 'utilities/hooks/useTransitional';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import { RevealerContext } from 'components/Revealer/consts';
import './_Revealer.scss';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
import type { NRevealer } from 'components/Revealer/types';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * Reveals the content of the component which is hidden with overflow hidden.
 *
 * When the content is revealed and the height transition has finished, it is allowed to overflow.
 *
 * Sometimes the height of the content can cause a resize to happen if the content uses margins which have a different behaviour when inside a container with overflow hidden.
 *
 */
const _Revealer: React.FC<NRevealer.Props> = ({ children, float = false, className, isOpen, onClick }) => {
    /***** STATE *****/
    const revealerContentRef = useRef<HTMLDivElement>(null);
    const childHolderRef = useRef<HTMLDivElement>(null);

    /***** HOOKS *****/
    const childHolderHeight = useElementHeight(childHolderRef);
    const { isOpen: _isOpen, onClick: _onClick } = useControlledReveal(isOpen, onClick);
    const [{ overflow, height }, trigger] = useTransitional(
        300,
        {
            postTransition: { height: 'auto', overflow: 'visible' },
            transitionEnd: { height: childHolderHeight, overflow: 'hidden' },
            transitionStart: { height: 0, overflow: 'hidden' },
            preTransition: { height: 0, overflow: 'hidden' }
        },
        _isOpen ? { height: 'auto', overflow: 'visible' } : { height: 0, overflow: 'hidden' }
    );

    /***** EFFECTS *****/
    useEffect(() => trigger(_isOpen ? 'forward' : 'backwards'), [_isOpen]);

    /***** RENDER HELPERS *****/
    const context = useMemo(() => ({ isOpen: _isOpen, onClick: _onClick }), [_isOpen, _onClick]);

    /***** RENDER *****/
    return (
        <RevealerContext.Provider value={context}>
            <div className={classNames('Revealer', className)}>
                <div
                    style={{ height, overflow }}
                    className={classNames('Revealer__content', {
                        'Revealer__content--float': float
                    })}
                >
                    <div className="Revealer__inner" ref={revealerContentRef}>
                        <List childHolderRef={childHolderRef} overflow={overflow}>
                            {children}
                        </List>
                    </div>
                </div>
            </div>
        </RevealerContext.Provider>
    );
};

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/

export { _Revealer };

/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { createRoute, Navigate, Outlet, redirect } from '@tanstack/react-router';

/**********************************************************************************************************
 *   ROUTE IMPORTS
 **********************************************************************************************************/
import { AppRoute } from 'App';
import { routerMiddleware } from 'router/utils/middleware';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import './_ventraSynMigration.scss';

/**********************************************************************************************************
 *   ROUTE START
 **********************************************************************************************************/
export const VentraSynergyMigrationRoute = createRoute({
    getParentRoute: () => AppRoute,
    path: 'synergy-reseller-migration',
    notFoundComponent: () => <Navigate to="/synergy-reseller-migration/more-info" />,
    beforeLoad(opts) {
        routerMiddleware.business(this, opts);
        routerMiddleware.authentication('user', opts);
        if (opts.location.pathname === '/synergy-reseller-migration') {
            throw redirect({ to: '/synergy-reseller-migration/more-info' });
        }
    }
});

/**********************************************************************************************************
 *   ROUTE COMPONENT START
 **********************************************************************************************************/
const SynergyMigrationRouteComponent = () => {
    return (
        <div className="ventraSynMigration">
            <Outlet />
        </div>
    );
};

/**********************************************************************************************************
 *   ROUTE UPDATES
 **********************************************************************************************************/
VentraSynergyMigrationRoute.update({
    component: SynergyMigrationRouteComponent
});

import { values } from 'lodash';

/**
 * @param {object} data
 */
export function TruthKeys(data) {
    const result = !values(data).some((value) => {
        const isTruthy = Boolean(value);
        return isTruthy === false;
    });
    return result;
}

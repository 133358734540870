/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { useMemo } from 'react';

/**********************************************************************************************************
 *   QUERIES
 **********************************************************************************************************/
import { katanaQuery } from 'containers/katana/queries/tanstackTree';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { modifyInitialValues } from 'containers/katana/hooks/useModifiedInitialValues/methods';
import { useKatanaParams } from 'containers/katana/hooks/useSetupEditorRouteParams';

/**********************************************************************************************************
 *   TYPE IMPORTS
 **********************************************************************************************************/
import type { KatanaNamespace } from 'containers/katana/types';

/**********************************************************************************************************
 *   HOOK START
 **********************************************************************************************************/
/**
 * To be used within a Hook Form FormProvider
 */
export function useColourThemeDefaultFormValues(subpageRouteData: KatanaNamespace.SubPageRouteData) {
    /***** HOOKS *****/
    const { katanaServiceId } = useKatanaParams();

    /***** QUERIES *****/
    const { data: get_katana_site_info_data, isLoading: isGetKatanaSiteInfoLoading } = katanaQuery.serviceID.getSiteInfo.useQuery(katanaServiceId);

    /***** RENDER HELPERS *****/
    return useMemo(() => {
        const queryData = {
            ...(get_katana_site_info_data ?? {})
        };

        const modifiedInitialValues = modifyInitialValues({ initialValues: queryData, modules: subpageRouteData.modules });

        if (!isGetKatanaSiteInfoLoading && get_katana_site_info_data) {
            return modifiedInitialValues;
        }
    }, [get_katana_site_info_data, isGetKatanaSiteInfoLoading]);
}

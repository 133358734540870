/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { useQuery } from '@tanstack/react-query';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { API as emailAPI } from 'utilities/api/email';
import { API as servicesAPI } from 'utilities/api/services';
import { getDataFromSuccessResponse, handleDefaultErrorNotification } from 'utilities/methods/commonActions';
import { createQueryBoilerPlate } from 'utilities/methods/tanstack';

/**
 * @param {string} name - String representing the name of the resolved API call.
 */
const nameResult = (name) => (apiResult) => ({ name, apiResult });
const MAX_RECORD_PER_PAGE = { record_per_page: 999 };

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
/**
 * @deprecated use v5 format, see katanaQuery
 */
const useGetAllServicesQueryBoilerPlate = createQueryBoilerPlate({
    service: 'billing',
    serviceKey: 'GET_ALL_SERVICES',
    APIRoute: () => () =>
        Promise.allSettled([
            servicesAPI.hosting.list(MAX_RECORD_PER_PAGE).then(nameResult('webHosting')),
            servicesAPI.domain.list(MAX_RECORD_PER_PAGE).then(nameResult('domains')),
            servicesAPI.vps.list(MAX_RECORD_PER_PAGE).then(nameResult('vps')),
            servicesAPI.gsuite.list(MAX_RECORD_PER_PAGE).then(nameResult('gsuite')),
            servicesAPI.ms365.list(MAX_RECORD_PER_PAGE).then(nameResult('ms365')),
            servicesAPI.ssl.list(MAX_RECORD_PER_PAGE).then(nameResult('ssl')),
            emailAPI.email.get.allMailboxes().then(nameResult('emailHosting'))
        ]),
    defaultOptions: {
        onError: () => handleDefaultErrorNotification,
        select: () => (data) =>
            data.reduce((accumulator, { status, value }) => {
                if (status === 'fulfilled') {
                    const { name, apiResult } = value;
                    accumulator[name] = getDataFromSuccessResponse(apiResult);
                }
                return accumulator;
            }, {}),
        staleTime: 1000 * 60 * 5 // 5 minutes
    }
});

const { getDefaults } = useGetAllServicesQueryBoilerPlate;

/**********************************************************************************************************
 * HOOK START
 **********************************************************************************************************/
/**
 * @deprecated use v5 format, see katanaQuery
 * Get all services
 * This is a general endpoint to get a full list of all services for an account
 */
const useGetAllServicesQuery = (_, options = {}) => {
    return useQuery({
        ...getDefaults(),
        ...options
    });
};

/**********************************************************************************************************
 *   HOOK END
 **********************************************************************************************************/

export { useGetAllServicesQuery, useGetAllServicesQueryBoilerPlate };

/**
 *
 * New password requirements for VIPC account password introduced in CCP-1900
 *
 */

/**********************************************************************************************************
 *   IMPORTS
 **********************************************************************************************************/
import { validatePasswordCheckerCustomRequirements } from './index';

/**********************************************************************************************************
 *   DEFAULT REQUIREMENTS LISTS
 **********************************************************************************************************/
export const defaultPasswordRequirements = {
    require: ['minLength8', 'upperAndLower', 'numOrSpecial'],
    exclude: []
};

export const increasedStrengthPasswordRequirements = {
    require: ['minLength10', 'upperAndLower', 'num', 'specialChar'],
    exclude: []
};

/**********************************************************************************************************
 *   DEFAULT PASSWORD VALIDATIONS
 **********************************************************************************************************/
export const validateDefaultRequirements = (value) => validatePasswordCheckerCustomRequirements(value, defaultPasswordRequirements);
export const validateIncreasedStrengthRequirements = (value) =>
    validatePasswordCheckerCustomRequirements(value, increasedStrengthPasswordRequirements);

/**********************************************************************************************************
 *   BASE IMPORT
**********************************************************************************************************/
import classNames from 'classnames';
import React from 'react';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import Text from 'components/Utils/Text';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { useAppViewport } from 'utilities/hooks/useAppViewport/useAppViewport';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import './_ServiceOverviewBannerHeading.scss';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * @type {React.FC<Partial<NText.Props> & { children: React.ReactNode }>}
 */
const _ServiceOverviewBannerHeading = ({ children, className, 'align--center': alignCenter, 'size--custom': sizeCustom, ...props }) => {
    /***** HOOKS *****/
    const isMedium = useAppViewport('md');
    const isMobile = useAppViewport(['xs', 'sm']);

    /***** RENDER *****/
    return (
        <Text
            size--custom={sizeCustom || (isMedium ? 30 : 35)}
            align--center={alignCenter || isMobile}
            white
            bold
            className={classNames('NXServiceOverviewBannerHeading', className)}
            {...props}
        >
            {children}
        </Text>
    );
};

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

export { _ServiceOverviewBannerHeading };

/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { clone, has, keys, sample } from 'lodash';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { deepDiff } from 'utilities/methods/recursion/deepDiff/deepDiff';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import {
    SUPER_ACTIONS,
    SUPER_ACTIONS_KEYS,
    lastSuperActionAdded,
    superActionKeys,
    superUserSuperActionsState
} from 'components/StaffMenu/SuperUser/consts';

/**
 * @param {string} code
 */
export function matchSuperCode(code) {
    return superActionKeys.find((actionKey) => code.includes(SUPER_ACTIONS[actionKey]?.cheatCode));
}

/**
 * @param {string} action
 */
export function addSuperAction(action) {
    const currentSuperActions = superUserSuperActionsState.state;
    const clonedSuperActions = clone(currentSuperActions);
    lastSuperActionAdded.setState(() => action);

    clonedSuperActions[action] = true;
    superUserSuperActionsState.setState(() => clonedSuperActions);
}

/**
 * @param {import('components/StaffMenu/SuperUser/types').SuperUserNamespace.ActionKeys} action
 * @returns {boolean | undefined}
 */
export function getSuperAction(action) {
    return superUserSuperActionsState.state[action];
}

/**
 * @param {import('components/StaffMenu/SuperUser/types').SuperUserNamespace.ActionKeys | undefined} action
 * @returns {boolean}
 */
export function canAddSuperActionCondition(action) {
    if (!action) return false;
    const currentSuperActions = superUserSuperActionsState.state;
    if (action === SUPER_ACTIONS_KEYS.KONAMI_CODE) {
        return true;
    }
    if (!has(currentSuperActions, SUPER_ACTIONS_KEYS.KONAMI_CODE)) {
        return false;
    }
    const superAction = SUPER_ACTIONS[action];
    return has(superAction, 'condition') ? superAction.condition() : true;
}

/**
 * @param {string[]} actionKeyList
 */
function getCheatCodeActions(actionKeyList) {
    return superActionKeys.filter((actionKey) => SUPER_ACTIONS[actionKey]?.cheatCode);
}

export function superActionSubscriberChanges() {
    function halfLifeSounds() {
        const bell = new Audio('https://github.com/sourcesounds/hl1/raw/master/sound/buttons/bell1.wav');
        const blip = new Audio('https://github.com/sourcesounds/hl1/raw/master/sound/buttons/blip1.wav');
        const inactiveButtons = [
            new Audio('https://github.com/sourcesounds/hl1/raw/master/sound/buttons/button2.wav'),
            new Audio('https://github.com/sourcesounds/hl1/raw/master/sound/buttons/button7.wav'),
            new Audio('https://github.com/sourcesounds/hl1/raw/master/sound/buttons/latchlocked1.wav'),
            new Audio('https://github.com/sourcesounds/hl1/raw/master/sound/buttons/latchlocked2.wav')
        ];
        const activeButtons = [
            new Audio('https://github.com/sourcesounds/hl1/raw/master/sound/buttons/button1.wav'),
            new Audio('https://github.com/sourcesounds/hl1/raw/master/sound/buttons/button3.wav'),
            new Audio('https://github.com/sourcesounds/hl1/raw/master/sound/buttons/button7.wav'),
            new Audio('https://github.com/sourcesounds/hl1/raw/master/sound/buttons/button9.wav'),
            new Audio('https://github.com/sourcesounds/hl1/raw/master/sound/buttons/latchunlocked1.wav'),
            new Audio('https://github.com/sourcesounds/hl1/raw/master/sound/buttons/latchunlocked2.wav')
        ];

        const checkboxActivated = new Audio('https://github.com/sourcesounds/hl1/raw/master/sound/fvox/activated.wav');
        const checkboxDeactivated = new Audio('https://github.com/sourcesounds/hl1/raw/master/sound/fvox/deactivated.wav');

        return {
            bell,
            blip,
            inactiveButtons,
            activeButtons,
            checkboxActivated,
            checkboxDeactivated
        };
    }

    let lastSuperActions = {};

    /**
     * @type {Partial<ReturnType<typeof halfLifeSounds>>}
     */
    let halfLifeSoundData;

    /**
     *
     * @param {MouseEvent} e
     */
    function handleHalfLifeSoundsClick(e) {
        if (e.target instanceof HTMLElement) {
            const anchor = e.target.tagName === 'A' ? e.target : e.target.closest('a');
            const button = e.target.tagName === 'BUTTON' ? e.target : e.target.closest('button');
            if (button || (anchor && !anchor?.hasAttribute?.('href'))) {
                if (button?.classList?.contains('CheckBox')) {
                    if (button?.getAttribute?.('aria-checked') === 'true') {
                        halfLifeSoundData.checkboxActivated.currentTime = 0;
                        if (halfLifeSoundData.checkboxActivated.paused) {
                            halfLifeSoundData.checkboxActivated.play();
                        }
                    } else {
                        halfLifeSoundData.checkboxDeactivated.currentTime = 0;
                        if (halfLifeSoundData.checkboxDeactivated.paused) {
                            halfLifeSoundData.checkboxDeactivated.play();
                        }
                    }
                } else if (
                    button?.classList?.contains?.('InactiveButton') ||
                    button?.hasAttribute?.('disabled') ||
                    button?.hasAttribute?.('aria-disabled')
                ) {
                    const randomInactiveButton = sample(halfLifeSoundData.inactiveButtons);
                    randomInactiveButton.currentTime = 0;
                    if (randomInactiveButton.paused) {
                        randomInactiveButton.play();
                    }
                } else {
                    const randomActiveButton = sample(halfLifeSoundData.activeButtons);
                    randomActiveButton.currentTime = 0;
                    if (randomActiveButton.paused) {
                        randomActiveButton.play();
                    }
                }
            }

            if (anchor && anchor?.hasAttribute?.('href')) {
                halfLifeSoundData.bell.currentTime = 0;
                if (halfLifeSoundData.bell.paused) {
                    halfLifeSoundData.bell.play();
                }
            }
        }
    }

    const unsubscribeSuperActions = superUserSuperActionsState.subscribe(() => {
        const superActions = superUserSuperActionsState.state;
        const actionsDiff = deepDiff(lastSuperActions, superActions);

        lastSuperActions = superActions;

        /**
         *
         * @param {import('components/StaffMenu/SuperUser/types').SuperUserNamespace.ActionKeys} superCode
         */
        function codeRemoved(superCode) {
            return actionsDiff[superCode]?.from && !actionsDiff[superCode]?.to && !has(superActions, superCode);
        }

        if (actionsDiff[SUPER_ACTIONS_KEYS.KONAMI_CODE]?.to === true) {
            new Audio('https://www.myinstants.com/media/sounds/ringtones-zelda-1.mp3').play();
        }

        if (actionsDiff[SUPER_ACTIONS_KEYS.KONAMI_CODE]?.to === false || codeRemoved(SUPER_ACTIONS_KEYS.KONAMI_CODE)) {
            const superActionsReduced = getCheatCodeActions(keys(superActions)).reduce((acc, action) => {
                if (has(acc, action)) {
                    delete acc[action];
                    return acc;
                }
                return acc;
            }, superActions);

            superUserSuperActionsState.setState(() => superActionsReduced);
            return;
        }

        if (actionsDiff[SUPER_ACTIONS_KEYS.SV_CHEATS_1]?.to === true) {
            if (!halfLifeSoundData) {
                halfLifeSoundData = halfLifeSounds();
            }
            new Audio('https://www.myinstants.com/media/sounds/is-ricochet-2-coming-out-at-all-.mp3').play();
            document.addEventListener('click', handleHalfLifeSoundsClick);
        }

        if (actionsDiff[SUPER_ACTIONS_KEYS.SV_CHEATS_1]?.to === false || codeRemoved(SUPER_ACTIONS_KEYS.SV_CHEATS_1)) {
            document.removeEventListener('click', handleHalfLifeSoundsClick);
        }
    });

    return () => {
        unsubscribeSuperActions();
    };
}

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * @param {{ children: React.ReactNode, name: string }} props
 * @deprecated Please just use props to pass named children to a component.
 */
export const Slot = ({ children }) => <>{children}</>;
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

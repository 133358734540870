/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { useState } from 'react';
import classNames from 'classnames';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import { PromoCodePill } from './Pill';
import { PromoCodeApplyButton } from './ApplyButton';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import './_PromoCode.scss';

/**********************************************************************************************************
 *   TYPE IMPORTS
 **********************************************************************************************************/
import type { PromoCodeProp } from './types';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const PromoCode: React.FC<PromoCodeProp> = ({
    className,
    isDisabled,
    customDisabledMessage,
    isLoading,
    appliedPromoCode,
    removeAppliedCode,
    onApply,
    isRemoveable
}) => {
    /***** STATE *****/
    const [inputValue, setInputValue] = useState('');

    /***** FUNCTIONS *****/
    function onApplyClick() {
        if (!isDisabled && inputValue && !isLoading) {
            onApply(inputValue);
            setInputValue('');
        }
    }

    /***** RENDER HELPERS *****/
    const renderInput = () => {
        return (
            <div className={classNames('form__item', { form__item: isDisabled })}>
                <div className="form__title">
                    <label htmlFor="promo_code">
                        Have a Coupon Code?<div className="PromoCode__couponTooltip">{customDisabledMessage || 'Unable to apply promo codes.'}</div>
                    </label>
                </div>
                <div className="form__item__inner">
                    <div className="wrapperbox">
                        <input
                            name="promo_code"
                            id="promo_code"
                            type="text"
                            placeholder="Enter your coupon code here if you have one"
                            tabIndex="0"
                            disabled={isDisabled}
                            value={inputValue}
                            onChange={(e) => setInputValue(e.target.value)}
                        />
                    </div>
                </div>
            </div>
        );
    };

    /***** RENDER *****/
    return (
        <div className={classNames('PromoCode', { 'PromoCode--disabled': isDisabled, 'className': !!className })}>
            <div className="PromoCode__couponTop">
                {renderInput()}
                <div className="PromoCode__couponApplyContainer">
                    <PromoCodeApplyButton isLoading={isLoading} isDisabled={!inputValue || isLoading} applyButtonOnClick={onApplyClick} />
                </div>
            </div>

            {appliedPromoCode && !isLoading ? (
                <div className="PromoCode__appliedCoupons">
                    <PromoCodePill code={appliedPromoCode} removeButtonOnClick={removeAppliedCode} isRemoveable={isRemoveable} />
                </div>
            ) : (
                ''
            )}
        </div>
    );
};

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

import { createRoute, Outlet } from '@tanstack/react-router';
import { useStore } from '@tanstack/react-store';
import React from 'react';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import { Page } from 'components/Page';
import SideBarPage from 'components/SideBarPage';
import Transition from 'components/Transition';
import { WrapWithComponent } from 'components/WrapWithComponent';
import { MyServicesRoute } from 'containers/services';
import { SSLLoaderHandler } from 'containers/ssl/components/loader';
import { SSLTitle } from 'containers/ssl/components/title';
import { SSLModuleStore } from 'containers/ssl/moduleConditions';
import { routerMiddleware } from 'router/utils/middleware';
import { redirectMatch } from 'router/utils/redirectMatch';
import { useChildMatchById } from 'utilities/hooks/router/useChildMatchById';
import './_ssl.scss';
import { getSslInformation } from './action';

/**********************************************************************************************************
 *   ROUTE START
 **********************************************************************************************************/
export const ManageSSLRoute = createRoute({
    getParentRoute: () => MyServicesRoute,
    path: 'ssl/$sslId',
    loader({ cause, params, context }) {
        if (cause === 'enter') {
            getSslInformation(params.sslId)(context.store.dispatch);
        }
    },
    beforeLoad(opts) {
        routerMiddleware.business({ path: '/my-services/ssl' }, opts);
        routerMiddleware.authentication('user', opts);
        routerMiddleware.business(this, opts);
        // prettier-ignore
        redirectMatch(
            { from: '/my-services/ssl/$sslId', opts },
            {
                ...opts.location,
                ...opts,
                to: '/my-services/ssl/$sslId/manage',
                hash: 'overview'
            }
        );
    }
});

/**********************************************************************************************************
 *   ROUTE COMPONENT START
 **********************************************************************************************************/
const RouteComponent = () => {
    /***** HOOKS *****/
    const store = useStore(SSLModuleStore);
    const isSetupRoute = useChildMatchById([
        '/my-services/ssl/$sslId/validate', //
        '/my-services/ssl/$sslId/configure'
    ]);

    const [links] = SideBarPage.useGetSidebarLinks([
        {
            list_title: 'Manage',
            list_items: store.manage
        }
    ]);

    /***** RENDER *****/
    return (
        <SSLLoaderHandler>
            <SSLTitle />
            <WrapWithComponent wrap={!isSetupRoute} component={SideBarPage} sidebarList={links} title="Manage SSL Certificate" icon="about-certs">
                <WrapWithComponent wrap={isSetupRoute} component={Page}>
                    <Transition>
                        <Outlet />
                    </Transition>
                </WrapWithComponent>
            </WrapWithComponent>
        </SSLLoaderHandler>
    );
};

/**********************************************************************************************************
 *   ROUTE UPDATES
 **********************************************************************************************************/
ManageSSLRoute.update({
    component: React.memo(RouteComponent)
});

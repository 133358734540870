/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import dropin from 'braintree-web-drop-in';
import React, { useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import queryClient from 'store/queryClient';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import FetchPageError from 'components/Errors/FetchPageError';
import RequestLoader from 'components/Loaders/Request';

/**********************************************************************************************************
 *   QUERIES
 **********************************************************************************************************/
import { useHandlePaymentMethodCallbackMutation } from 'containers/billing/queries';
import { useGetInvoiceQuery } from 'containers/billing/queries/invoice/useGetInvoiceQuery';
import { useGetAvailablePaymentMethodsQuery } from 'containers/billing/queries/useGetAvailablePaymentMethodsQuery';
import { useGetPaymentMethodTokenBoilerPlate, useGetPaymentMethodTokenQuery } from 'containers/billing/queries/useGetPaymentMethodTokenQuery';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import { PAYMENT_METHODS } from 'components/Lightboxes/OverlayLightbox/Components/invoice/paymentMethods/consts';
import './_BrainTreeDropIn.scss';
const brainTreeSelector = 'BrainTreeDropIn';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type TBrainTreeDropIn = React.FC<{
    mutationOptions?: {
        onSuccess: () => void;
        onError?: () => void;
    };
    invoiceID?: number | string;
}>;

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
const BrainTreeDropIn: TBrainTreeDropIn = ({ invoiceID, mutationOptions }) => {
    /***** STATE *****/
    const [dropinInstance, setDropinInstance] = useState(null);
    const [dropinError, setDropinError] = useState(null);

    /***** HOOKS *****/
    const brainTreeRef = useRef();
    const dropinInstanceRef = useRef();

    /***** QUERIES *****/
    const { data: get_invoice_data } = useGetInvoiceQuery(invoiceID);

    const { data: paymentMethodID } = useGetAvailablePaymentMethodsQuery({
        select: useGetAvailablePaymentMethodsQuery.selectFilters.getPaymentMethodID(PAYMENT_METHODS.PAYPAL),
        retryOnMount: false
    });

    const {
        refetch: refetchGetPaymentMethodToken,
        isLoading: isGetPaymentMethodTokenLoading,
        data: get_payment_method_token_data,
        isError: isGetPaymentMethodTokenError
    } = useGetPaymentMethodTokenQuery(paymentMethodID);

    const { mutate: mutateHandlePaymentMethodCallback, isPending: isHandlePaymentMethodCallbackPending } = useHandlePaymentMethodCallbackMutation({
        ...mutationOptions,
        onMutate: () => {
            setDropinInstance(null);
        },
        onError: (err) => {
            dropinInstance?.teardown();
            setDropinInstance(null);
            queryClient.removeQueries({ queryKey: useGetPaymentMethodTokenBoilerPlate.createQueryKey(paymentMethodID) });
            refetchGetPaymentMethodToken();
        }
    });

    /***** FUNCTIONS *****/
    function handleRequestPaymentMethodResponse(err, payload) {
        if (err) {
            // Handle errors in requesting payment method
            setDropinError(err);
            return;
        }

        const attributes = {
            type: 'checkout',
            payment_nonce: payload?.nonce,
            invoice_id: invoiceID
        };

        mutateHandlePaymentMethodCallback({ paymentMethodID, attributes });
    }

    function handleDropInInstance(err, _dropinInstance) {
        if (err) {
            // Handle any errors that might've occurred when creating Drop-in
            setDropinError(err);
            return;
        }

        function submitPaymentRequest() {
            _dropinInstance.requestPaymentMethod(handleRequestPaymentMethodResponse);
        }

        _dropinInstance.on('changeActiveView', (data) => {
            setDropinError(null);
            if (data.previousViewId === 'paypal' && data.newViewId === 'methods') {
                submitPaymentRequest();
            }
        });

        setDropinInstance(_dropinInstance);
    }

    function createDropIn({ token, total }) {
        dropin.create(
            {
                authorization: token,
                selector: brainTreeRef.current,
                dataCollector: true,
                paypal: {
                    flow: 'checkout',
                    amount: total,
                    currency: 'AUD',
                    buttonStyle: {
                        layout: 'vertical',
                        color: 'gold',
                        shape: 'rect'
                    }
                },
                card: false
            },
            handleDropInInstance
        );
    }

    /***** EFFECTS *****/
    const hasTokenAndInvoice = !!(get_payment_method_token_data && get_invoice_data);

    useEffect(() => {
        setDropinError(null);
    }, [dropinInstance]);

    useEffect(() => {
        if (!hasTokenAndInvoice || dropinInstance) return;

        const { attributes } = get_invoice_data;
        const { total } = attributes;
        const { token } = get_payment_method_token_data;

        createDropIn({ token, total });
    }, [get_payment_method_token_data, get_invoice_data]);

    /**
     * Have to keep a reference of the dropinInstance otherwise we can't call "teardown" when we unmount the component.
     */
    useEffect(() => {
        dropinInstanceRef.current = dropinInstance;
    }, [dropinInstance]);

    useEffect(() => {
        return () => {
            dropinInstanceRef?.current?.teardown?.();
        };
    }, []);

    function renderLoaderOrError() {
        if (!hasTokenAndInvoice || isGetPaymentMethodTokenLoading) return <RequestLoader />;
        if (isGetPaymentMethodTokenError || dropinError) return <FetchPageError height={250} />;
        return '';
    }

    function renderLoadingPayment() {
        if (isHandlePaymentMethodCallbackPending)
            return ReactDOM.createPortal(
                <RequestLoader className="BrainTreeDropIn__loadingLightBox" message="Processing payment with paypal..." />,
                document.body
            );
        return '';
    }

    /***** RENDER *****/
    return (
        <div className="BrainTreeDropIn">
            <div ref={brainTreeRef} id={brainTreeSelector} />
            {renderLoaderOrError()}
            {renderLoadingPayment()}
        </div>
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

export default BrainTreeDropIn;

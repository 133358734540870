/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import Carousel from 'components/Carousel';

/**********************************************************************************************************
 *   TYPE IMPORTS
 **********************************************************************************************************/
import type { CarouselProps } from 'components/Carousel';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import './_vipRewardsMoreInfoTiersCarousel.scss';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type VipRewardsMoreInfoTiersCarousel = React.FC<CarouselProps>;

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const VipRewardsMoreInfoTiersCarousel: VipRewardsMoreInfoTiersCarousel = ({ slides }) => {
    /***** RENDER *****/
    return (
        <div className="vipRewardsMoreInfoTiersCarousel">
            <Carousel slides={slides} />
        </div>
    );
};

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

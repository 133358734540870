/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { Outlet, createRoute } from '@tanstack/react-router';
import { useStore } from '@tanstack/react-store';

/**********************************************************************************************************
 *   QUERIES
 **********************************************************************************************************/
import { tppDomainTransferLockBoilerPlate } from 'containers/domain/tppDomains/queries/useTppDomainTransferLockQuery';
import { tppDomainBoilerPlate } from './queries/useTppDomainQuery';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import RequestLoader from 'components/Loaders/Request';
import SideBarPage from 'components/SideBarPage';
import Transition from 'components/Transition';

/**********************************************************************************************************
 *   ROUTE IMPORTS
 **********************************************************************************************************/
import { MyServicesRoute } from 'containers/services';
import { routerMiddleware } from 'router/utils/middleware';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import { LegacyDomainsTitle } from 'containers/domain/tppDomains/components/title';
import { LegacyDomainModuleStore } from 'containers/domain/tppDomains/moduleConditions';
import './_tppDomain.scss';

/**********************************************************************************************************
 *   ROUTE START
 **********************************************************************************************************/
export const LegacyDomainManageRoute = createRoute({
    getParentRoute: () => MyServicesRoute,
    path: 'domains/legacy/$legacyDomainId',
    pendingComponent: RequestLoader,
    preloadStaleTime: 0,
    loader({ params: { legacyDomainId }, context }) {
        context.queryClient.ensureQueryData(tppDomainBoilerPlate.getDefaults(legacyDomainId));
        context.queryClient.ensureQueryData(tppDomainTransferLockBoilerPlate.getDefaults(legacyDomainId));
    },
    beforeLoad(opts) {
        routerMiddleware.business({ path: '/my-services/domains' }, opts);
        routerMiddleware.business(this, opts);
        routerMiddleware.authentication('user', opts);
    }
});

/**********************************************************************************************************
 *   ROUTE COMPONENT START
 **********************************************************************************************************/
const LegacyDomainManageRouteComponent = () => {
    /***** HOOKS *****/
    const store = useStore(LegacyDomainModuleStore);

    LegacyDomainModuleStore.useConditions();

    /***** RENDER HELPERS *****/
    const [links] = SideBarPage.useGetSidebarLinks([
        {
            list_title: 'General',
            list_icon: 'accounts',
            list_items: store.general
        },
        {
            list_title: 'Security',
            list_icon: 'secure-host',
            list_items: store.security
        }
    ]);

    /***** RENDER *****/
    return (
        <div className="tppDomainPage">
            <LegacyDomainsTitle />
            <SideBarPage title="Manage Domain" icon="domain-names" sidebarList={links}>
                <Transition>
                    <Outlet />
                </Transition>
            </SideBarPage>
        </div>
    );
};

/**********************************************************************************************************
 *   ROUTE UPDATES
 **********************************************************************************************************/
LegacyDomainManageRoute.update({
    component: LegacyDomainManageRouteComponent
});

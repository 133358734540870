/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import SelectOption from './Option';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import './_SelectOptions.scss';

/**********************************************************************************************************
 *   TYPE IMPORTS
 **********************************************************************************************************/
import type { SelectOptionProps } from './Option/index';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type SelectOptionsProps = {
    options: Array<SelectOptionProps>;
};

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * This component is usually used in conjunction with the CustomDropdown component to handle rendering of the list of options
 */
function SelectOptions({ options }: SelectOptionsProps) {
    /***** RENDER *****/
    return (
        <ul className="SelectOptions">
            {options.map(({ label, value, isActive, onSelect }, index) => {
                return <SelectOption key={`${value}-${index}`} label={label} value={value} isActive={isActive} onSelect={onSelect} />;
            })}
        </ul>
    );
}
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

export default SelectOptions;

/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { useMemo } from 'react';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { getCaveat } from 'containers/katana/formFields/methods/getCaveat';

/**********************************************************************************************************
 *   TYPE IMPORTS
 **********************************************************************************************************/
import type { CaveatNames } from 'containers/katana/formFields/types';

/**********************************************************************************************************
 *   TYPE IMPORTS
 **********************************************************************************************************/
import type { KatanaNamespace } from 'containers/katana/types';

/**********************************************************************************************************
 *   HOOK START
 **********************************************************************************************************/
export function useGetCaveat<TFallback>(
    caveatName: CaveatNames,
    caveats: KatanaNamespace.SectionDefinitions.AllCaveats[] = [],
    fallbackValue: TFallback = null
) {
    return useMemo(() => getCaveat(caveatName, caveats) ?? fallbackValue, [caveatName, caveats, fallbackValue]);
}
/**********************************************************************************************************
 *   HOOK END
 **********************************************************************************************************/

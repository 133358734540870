/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { useParams } from '@tanstack/react-router';
import { useEffect } from 'react';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { createModuleStore } from 'utilities/methods/createModuleStore';

/**********************************************************************************************************
 *   MODULE STORE START
 **********************************************************************************************************/
export const GoogleModuleStore = createModuleStore(
    {
        manage: {
            status: {
                enabled: true,
                label: null!,
                to: '/my-services/google/$workspaceId/manage',
                hash: 'status'
            },
            overview: {
                enabled: true,
                label: 'Overview',
                to: '/my-services/google/$workspaceId/manage',
                hash: 'overview'
            },
            upgrade: {
                enabled: true,
                label: 'Upgrade',
                to: '/my-services/google/$workspaceId/manage',
                hash: 'upgrade'
            },
            billing: {
                enabled: true,
                label: 'Billing',
                to: '/my-services/google/$workspaceId/manage',
                hash: 'billing'
            },
            manageSeats: {
                enabled: true,
                label: 'Manage Seats',
                to: '/my-services/google/$workspaceId/manage',
                hash: 'manage-seats'
            },
            userAccounts: {
                enabled: true,
                label: 'User Accounts',
                to: '/my-services/google/$workspaceId/manage',
                hash: 'user-accounts'
            },
            userDetails: {
                enabled: false,
                label: 'User Details',
                to: '/my-services/google/$workspaceId/mailbox/$mailboxId/manage',
                hash: 'user-details'
            },
            delete: {
                enabled: false,
                label: 'Delete User',
                to: '/my-services/google/$workspaceId/mailbox/$mailboxId/manage',
                hash: 'delete'
            }
        },
        admin: {
            details: {
                enabled: true,
                label: 'Account Details',
                to: '/my-services/google/$workspaceId/admin',
                hash: 'account-details'
            },
            delete: {
                enabled: true,
                label: 'Delete Mailbox',
                to: '/my-services/google/$workspaceId/admin',
                hash: 'delete-account'
            }
        }
    },
    useGoogleModuleStoreCondition
);

/**********************************************************************************************************
 *   MODULE CONDITIONS START
 **********************************************************************************************************/
function useGoogleModuleStoreCondition(args: any) {
    const store = createModuleStore.castHookProps<typeof GoogleModuleStore>(args);

    /***** HOOKS *****/
    const { mailboxId } = useParams({ strict: false });

    /***** EFFECTS *****/
    useEffect(() => {
        store.batch(() => {
            // Condition Updates
            store.updateChangedStoreKey('manage.status.enabled', !mailboxId);
            store.updateChangedStoreKey('manage.overview.enabled', !mailboxId);
            store.updateChangedStoreKey('manage.upgrade.enabled', !mailboxId);
            store.updateChangedStoreKey('manage.billing.enabled', !mailboxId);
            store.updateChangedStoreKey('manage.manageSeats.enabled', !mailboxId);
            store.updateChangedStoreKey('manage.userAccounts.enabled', !mailboxId);
            store.updateChangedStoreKey('manage.userDetails.enabled', !!mailboxId);
            store.updateChangedStoreKey('manage.delete.enabled', !!mailboxId);
        });
    });
}

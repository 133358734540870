/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { useEffect } from 'react';
import { useBoolean } from 'usehooks-ts';

/**********************************************************************************************************
 *   HOOK START
 **********************************************************************************************************/
/**
 * @param {boolean} value
 */
function useBooleanEffect(value) {
    /***** STATE *****/
    const booleanData = useBoolean(value);

    /***** EFFECTS *****/
    useEffect(() => {
        booleanData.setValue(value);
    }, [value]);

    /***** HOOK RESULTS *****/
    return booleanData;
}
/**********************************************************************************************************
 *   HOOK END
 **********************************************************************************************************/

export default useBooleanEffect;

import { useQuery } from '@tanstack/react-query';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { API as ACCOUNT_API } from 'utilities/api/account';
import { handleDefaultErrorNotification } from 'utilities/methods/commonActions';
import { createQueryBoilerPlate } from 'utilities/methods/tanstack';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
/**
 * @deprecated use v5 format, see katanaQuery
 */
const useGetDefaultPaymentMethodBoilerPlate = createQueryBoilerPlate({
    service: 'billing',
    serviceKey: 'GET_DEFAULT_PAYMENT_METHOD',
    APIRoute: () => () => ACCOUNT_API.user.payment_method.GET(),
    defaultOptions: {
        staleTime: 60_000,
        cacheTime: 120_000,
        retryOnMount: false,
        retry: 3,
        onError: () => handleDefaultErrorNotification
    }
});

const { getDefaults } = useGetDefaultPaymentMethodBoilerPlate;

/**********************************************************************************************************
 *   HOOK START
 **********************************************************************************************************/
/**
 * @deprecated use v5 format, see katanaQuery
 * User get default Payment Method
 */
function useGetDefaultPaymentMethodQuery(options = {}) {
    return useQuery({
        ...getDefaults(),
        ...options
    });
}

/**********************************************************************************************************
 *   HOOK END
 **********************************************************************************************************/

export { useGetDefaultPaymentMethodBoilerPlate, useGetDefaultPaymentMethodQuery };

/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React, { useEffect, useRef } from 'react';
import ResizeObserver from 'resize-observer-polyfill';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import { NXIframe } from 'components/NXIframe';
import { HideWebpackOverlayDuringDevelopment } from 'components/Utils/HideWebpackOverlayDuringDevelopment';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { calculateFinalDisplayScale } from 'components/NXIframe/ScalingIframe/methods';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import './_ScalingIframe.scss';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * @type {React.FC<TScalingIframe.Props>}
 */
const ScalingIframe = ({ title, srcDoc, height, width, ...iframeProps }) => {
    /***** HOOKS *****/
    const iframeWrapperRef = useRef(/** @type {HTMLDivElement | null} */ (null));

    /***** EFFECTS *****/
    useEffect(() => {
        function handleScalingWrapperResize(/** @type {ResizeObserverEntry[]} */ entries) {
            entries.forEach((entry) => {
                const element = entry.target;
                const iframeWrapperWidth = element.getBoundingClientRect().width;

                const finalDisplayScale = calculateFinalDisplayScale(iframeWrapperWidth, width);

                const frame = element.querySelector('iframe');

                if (frame) {
                    frame.style.transform = `scale(${finalDisplayScale})`;
                }
            });
        }

        const resizeObservers = new ResizeObserver(handleScalingWrapperResize);

        if (!iframeWrapperRef.current) {
            return;
        }

        resizeObservers.observe(iframeWrapperRef.current);

        return () => {
            resizeObservers.disconnect();
        };
    }, []);

    /***** RENDER *****/
    return (
        <div className="ScalingIframe__wrapper" ref={iframeWrapperRef}>
            <NXIframe className="ScalingIframe" height={height} width={width} title={title} srcDoc={srcDoc} {...iframeProps} />
            <HideWebpackOverlayDuringDevelopment />
        </div>
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
export { ScalingIframe };

import NXTable from 'components/NXTable';
import Border from 'components/Utils/Border';
import Text from 'components/Utils/Text';
import React from 'react';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type PreviewChangesRecord = Artah.Domain.ID.DNS.Preset.Katana.PreviewChanges.POST._200['data'];
type Records = Array<
    (PreviewChangesRecord['add'][number] | PreviewChangesRecord['remove'][number]) & {
        hostname?: string;
        hostName?: string;
    }
>;

type Props = {
    records?: Records;
};

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const _RecordTable: React.FC<Props> = ({ records = [] }) => {
    if (!Array.isArray(records) || !records?.length) {
        return null;
    }

    return (
        <Border top left right bottom>
            <NXTable columns="1.2fr 2fr 3fr">
                <NXTable.Header gap={2}>
                    <NXTable.Header.Title>Record Type</NXTable.Header.Title>
                    <NXTable.Header.Title>Hostname</NXTable.Header.Title>
                    <NXTable.Header.Title>Content</NXTable.Header.Title>
                </NXTable.Header>
                {React.Children.toArray(
                    records?.map((record) => (
                        <NXTable.Row gap={2}>
                            <Text size--s>{record.type}</Text>
                            <Text size--s>{record?.hostname ?? record.hostName ?? ''}</Text>
                            <Text size--s>{record.content}</Text>
                        </NXTable.Row>
                    ))
                )}
            </NXTable>
        </Border>
    );
};

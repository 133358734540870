/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { injectChildPropsByRecord } from 'utilities/methods/reactChildren';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * @type {React.FC<PropInjectorProps>}
 */
const PropInjector = (props) => {
    const { children, injectableProps, inject, injectable } = props;

    /***** RENDER *****/
    if (inject && injectableProps) {
        const injectedChildren = injectChildPropsByRecord(injectable, injectableProps);
        if (injectedChildren) {
            return injectedChildren;
        }
    }
    return children;
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

export { PropInjector };

/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { useMemo } from 'react';

/**********************************************************************************************************
 *   HOOK START
 **********************************************************************************************************/
/**
 * Hook to simplify the use memo of loaders data for the RequestLoader.MultiLoader
 * @param {NRequestLoader.MultiLoader.Data[]} loaders // Loaders can not a dynamic length because they are used to determine the dependencies of the useMemo
 */
export function useLoadersData(loaders) {
    const loadersDeps = loaders.map((loader) => loader.condition);
    /***** HOOK RESULTS *****/
    // If you get an error here because the `loadersDeps` length is changed that means wherever you are using this hook the dependency length is dynamic, make it static.
    return useMemo(() => loaders, loadersDeps);
}

/**********************************************************************************************************
 *   HOOK END
 **********************************************************************************************************/

/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { Component } from 'react';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type DismissableBannerProps = {
    localStorageKey: string;
    children: (dismiss: () => void) => React.ReactNode;
};

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/

class DismissibleBanner extends Component<DismissableBannerProps> {
    constructor(props) {
        super(props);

        this.state = {
            dismissed: !!localStorage.getItem(props.localStorageKey)
        };

        this.dismiss = this.dismiss.bind(this);
    }

    dismiss() {
        const { localStorageKey } = this.props;

        this.setState(
            {
                dismissed: true
            },
            () => dismissBannerLocalStorage(localStorageKey)
        );
    }

    render() {
        const { children } = this.props;
        const { dismissed } = this.state;
        const { dismiss } = this;

        /*  RENDER COMPONENT
         **********************************************************************************************************/
        return dismissed ? '' : children(dismiss);
    }
}

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

export default DismissibleBanner;

export const dismissBannerLocalStorage = (localStorageKey) => localStorage.setItem(localStorageKey, 'true');

/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React from 'react';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
const HideWebpackOverlayDuringDevelopment = () =>
    import.meta.env.MODE === 'development' && (
        <style dangerouslySetInnerHTML={{ __html: `#webpack-dev-server-client-overlay { display: none !important; }` }} />
    );
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

export { HideWebpackOverlayDuringDevelopment };

/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React from 'react';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { getTldValue } from 'components/Promo/FreeDomain/ClaimForm/methods';
import { RewardCard } from 'containers/vipRewards/modules/rewardCard/new';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import OnlineText from 'assets/images/promo/dotOnlineBanner/onlineText.svg';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type DotOnlineReferralCardComponent = React.FC<{
    availabilityData: unknown;
}>;

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const DotOnlineRewardCard: DotOnlineReferralCardComponent = ({ availabilityData }) => {
    /***** RENDER HELPERS *****/
    const tldValue = getTldValue('.online', availabilityData);
    const description = `You're eligible for a FREE .online domain name registration valued at $${tldValue}.`;

    /***** RENDER *****/
    return (
        <RewardCard>
            <RewardCard.Image tag="FREE">
                <RewardCard.Image.Default className="rewardCard__bg--dotOnline" src={OnlineText} alt=".online" />
            </RewardCard.Image>
            <RewardCard.Body title="Claim your FREE .online domain name!*" description={description}>
                <RewardCard.Button searchParam="free-domain=online">Claim Now</RewardCard.Button>
            </RewardCard.Body>
        </RewardCard>
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import store from 'store/store';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import { pushNotification } from 'components/Toast/functions';

/*   ACTIONS
 *****************************************************/
import { API as ACCOUNT } from 'utilities/api/account';
import { API as SERVICES } from 'utilities/api/services';
import { getDataFromSuccessResponse, getErrorFromFailResponse } from 'utilities/methods/commonActions';
import { PROMOTIONS } from './api';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import { PAYMENT_METHODS } from 'components/Lightboxes/OverlayLightbox/Components/invoice/paymentMethods/consts';

/**********************************************************************************************************
 *   DECLARATIONS
 **********************************************************************************************************/
export const PROMOTION_INITIAL_STATE = 'promotion/PROMOTION_INITIAL_STATE';

export const PROMOTION_PROMO_CODE_STATUS_RESET = 'promotion/PROMOTION_PROMO_CODE_STATUS_RESET';
export const PROMOTION_PROMO_CODE_STATUS_REQUEST = 'promotion/PROMOTION_PROMO_CODE_STATUS_REQUEST';
export const PROMOTION_PROMO_CODE_STATUS_SUCCESS = 'promotion/PROMOTION_PROMO_CODE_STATUS_SUCCESS';
export const PROMOTION_PROMO_CODE_STATUS_ERROR = 'promotion/PROMOTION_PROMO_CODE_STATUS_ERROR';

export const PROMOTION_CLAIM_FREE_DOMAIN_REQUEST = 'promotion/PROMOTION_CLAIM_FREE_DOMAIN_REQUEST';
export const PROMOTION_CLAIM_FREE_DOMAIN_SUCCESS = 'promotion/PROMOTION_CLAIM_FREE_DOMAIN_SUCCESS';
export const PROMOTION_CLAIM_FREE_DOMAIN_ERROR = 'promotion/PROMOTION_CLAIM_FREE_DOMAIN_ERROR';

export const PROMOTION_RADIX_RENEW_OFFERS_REQUEST = 'promotion/PROMOTION_RADIX_RENEW_OFFERS_REQUEST';
export const PROMOTION_RADIX_RENEW_OFFERS_SUCCESS = 'promotion/PROMOTION_RADIX_RENEW_OFFERS_SUCCESS';
export const PROMOTION_RADIX_RENEW_OFFERS_ERROR = 'promotion/PROMOTION_RADIX_RENEW_OFFERS_ERROR';

/**********************************************************************************************************
 *   RESET
 **********************************************************************************************************/
export const resetPromotionState = () => {
    return (dispatch) => {
        dispatch({
            type: PROMOTION_INITIAL_STATE
        });
    };
};

/**
 * @param {{
 *   promo_code: "veryspecificpromo",
 *   tld: '.online',
 *   billing_cycle_id: 8,
 *   invoice_type: "new"
 * }} promoCodeData
 */
export function checkPromoCodeStatus(promoCodeData) {
    const { dispatch } = store;

    dispatch({
        type: PROMOTION_PROMO_CODE_STATUS_REQUEST,
        promotion_promo_code_status_request_data: {
            promo_data: promoCodeData
        }
    });

    return PROMOTIONS.POST.checkCode(promoCodeData)
        .then((response) => {
            const response_data = getDataFromSuccessResponse(response);
            dispatch({
                type: PROMOTION_PROMO_CODE_STATUS_SUCCESS,
                promotion_promo_code_status_request_data: {
                    promo_data: promoCodeData,
                    response_data
                }
            });
        })
        .catch(() => {
            dispatch({
                type: PROMOTION_PROMO_CODE_STATUS_ERROR,
                promotion_promo_code_status_request_data: {
                    promo_data: promoCodeData
                }
            });
        });
}

/**
 * @param {{
 *   items: Array<{
 *     service_id: number,
 *     quantity: number,
 *     billing_cycle_id: number,
 *     invoice_type: string
 *   }>,
 *   promo_code: string,
 *   tld: string,
 *   options: {
 *     onSuccess?: Function
 *   }
 * }} props
 */
export const claimFreeDomainPromo = ({ items, promo_code, tld, options = {} }) => {
    const { dispatch } = store;

    dispatch({
        type: PROMOTION_CLAIM_FREE_DOMAIN_REQUEST
    });

    function catchError(error) {
        const claim_free_domain_error = getErrorFromFailResponse(error);
        dispatch({
            type: PROMOTION_CLAIM_FREE_DOMAIN_ERROR
        });
        pushNotification(claim_free_domain_error);
    }

    const run = async () => {
        //get payment methods
        const paymentMethodsResponse = await ACCOUNT.user.payment_methods.GET();
        const paymentMethodsData = getDataFromSuccessResponse(paymentMethodsResponse);
        const bpayPaymentMethodId = paymentMethodsData?.find((method) => method.name === PAYMENT_METHODS.BPAY)?.id;

        //create a new cart
        const cart_create_response = await SERVICES.cart.POST.create(items);
        const cart_create_data = getDataFromSuccessResponse(cart_create_response);
        const cart_token = cart_create_data.token;

        //apply promo
        await SERVICES.cart.POST.promo(cart_token, promo_code);

        //create order
        const order_create_response = await SERVICES.order.POST.create(cart_token, bpayPaymentMethodId);
        const promotion_claim_free_domain_data = getDataFromSuccessResponse(order_create_response);
        promotion_claim_free_domain_data.tld = tld;

        pushNotification({ status: 200, details: 'Domain claimed successfully!' });
        dispatch({
            type: PROMOTION_CLAIM_FREE_DOMAIN_SUCCESS,
            promotion_claim_free_domain_data
        });

        options?.onSuccess?.();
    };

    run().catch(catchError);
};

/**********************************************************************************************************
 *   RADIX FREE DOMAINS
 **********************************************************************************************************/
export const getRadixRenewOffers = (id) => {
    const { dispatch } = store;

    dispatch({ type: PROMOTION_RADIX_RENEW_OFFERS_REQUEST });
    PROMOTIONS.GET.radixRenewOffers(id)
        .then((response) => {
            const promotion_radix_renew_offers_data = getDataFromSuccessResponse(response);
            dispatch({
                type: PROMOTION_RADIX_RENEW_OFFERS_SUCCESS,
                promotion_radix_renew_offers_data
            });
        })
        .catch(() => {
            dispatch({ type: PROMOTION_RADIX_RENEW_OFFERS_ERROR });
        });
};

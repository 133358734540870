import { queryOptions, useMutation, useQuery } from '@tanstack/react-query';
import { newActionsStore } from 'components/StaffMenu/SuperUser/consts';
import type { SuperUserNamespace } from 'components/StaffMenu/SuperUser/types';
import _ from 'lodash';
import queryClient from 'store/queryClient';

const localStorageKey = `SUPER_USER_ACTION_VIEWED`;
const queryKey = [localStorageKey];

function createQueryOptions() {
    return queryOptions({
        queryKey,
        queryFn: () => {
            return new Promise<SuperUserNamespace.ActionKeys[]>((resolve) => {
                const viewedData = localStorage.getItem(localStorageKey);
                resolve(viewedData ? (JSON.parse(viewedData) as SuperUserNamespace.ActionKeys[]) : ([] as SuperUserNamespace.ActionKeys[]));
            });
        },
        staleTime: Infinity,
        select: (data) => data
    });
}

const _useQuery = () => {
    return useQuery(createQueryOptions());
};

function _useMutation() {
    /***** FUNCTIONS *****/
    function mutationFn(values: SuperUserNamespace.ActionKeys[]) {
        newActionsStore.setState((state) => {
            const clonedState = { ...state };
            values.forEach((value) => {
                delete clonedState[value];
            });
            return clonedState;
        });
        return new Promise((resolve) => {
            localStorage.setItem(localStorageKey, JSON.stringify(_.uniq(values)));
            resolve(values);
        });
    }

    /***** HOOK RESULTS *****/
    return useMutation({
        mutationFn,
        onSettled: () => {
            queryClient.invalidateQueries({
                queryKey
            });
        },
        onError: () => void 0,
        onSuccess: () => void 0
    });
}

export const superUserActionViewedQuery = Object.freeze({
    useQuery: _useQuery,
    useMutation: _useMutation
});

/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { createRoute } from '@tanstack/react-router';

/**********************************************************************************************************
 *   QUERIES
 **********************************************************************************************************/

/**********************************************************************************************************
 *   ROUTE IMPORTS
 **********************************************************************************************************/
import { VIPSitesEditorRoute } from 'containers/katana/routes/vip-sites.$katanaServiceId/_content-editor-lightbox/editor';
import { routerMiddleware } from 'router/utils/middleware';
import { lazyFN } from 'utilities/methods/tanstack/router/lazyFn';

/**********************************************************************************************************
 *   ROUTE START
 **********************************************************************************************************/
export const VIPSitesEditorFixDNSRoute = createRoute({
    getParentRoute: () => VIPSitesEditorRoute,
    path: 'fix-dns',
    beforeLoad(opts) {
        routerMiddleware.business(this, opts);
        routerMiddleware.authentication('user', opts);
    }
}).lazy(lazyFN(() => import('./fix-dns.lazy'), 'LazyVIPSitesEditorFixDNSRoute'));

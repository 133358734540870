/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { createRoute } from '@tanstack/react-router';
import { katanaQuery } from 'containers/katana/queries/tanstackTree';

/**********************************************************************************************************
 *   ROUTE IMPORTS
 **********************************************************************************************************/
import { VIPSitesEditorRoute } from 'containers/katana/routes/vip-sites.$katanaServiceId/_content-editor-lightbox/editor';
import { routerMiddleware } from 'router/utils/middleware';
import { lazyFN } from 'utilities/methods/tanstack/router/lazyFn';

/**********************************************************************************************************
 *   ROUTE START
 **********************************************************************************************************/
export const VIPSitesEditorNavigationAndSocialsRoute = createRoute({
    getParentRoute: () => VIPSitesEditorRoute,
    path: 'navigation-and-socials',
    loader({ params }) {
        katanaQuery.serviceID.getNavigation.prefetchQuery(Number(params.katanaServiceId));
        katanaQuery.serviceID.getNavigationItems.prefetchQuery(Number(params.katanaServiceId));
        katanaQuery.serviceID.getSocials.prefetchQuery(Number(params.katanaServiceId));
        katanaQuery.serviceID.meta.getColours.prefetchQuery(Number(params.katanaServiceId));
        katanaQuery.serviceID.meta.getSocialsDefinitions.prefetchQuery(Number(params.katanaServiceId));
        katanaQuery.serviceID.getSiteInfo.prefetchQuery(Number(params.katanaServiceId));
    },
    beforeLoad(opts) {
        routerMiddleware.business(this, opts);
        routerMiddleware.authentication('user', opts);
    }
}).lazy(lazyFN(() => import('./navigation-and-socials.lazy'), 'LazyVIPSitesEditorNavigationAndSocialsRoute'));

import _ from 'lodash';

export function getAllPaths(obj: Object, parentKey = ''): string[] {
    if (_.isArray(obj)) {
        if (obj.length === 0) {
            return [parentKey];
        }
        return obj.flatMap((value, index) => getAllPaths(value, `${parentKey}[${index}]`));
    }

    if (_.isObject(obj)) {
        if (_.isEmpty(obj)) {
            return [parentKey];
        }
        return _.keys(obj).flatMap((key) => getAllPaths(obj[key], parentKey ? `${parentKey}.${key}` : key));
    }

    if (!_.isObject(obj)) {
        return [parentKey];
    }

    return [];
}

/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { useMemo } from 'react';

/**********************************************************************************************************
 *   QUERIES
 **********************************************************************************************************/
import { katanaQuery } from 'containers/katana/queries/tanstackTree';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { useKatanaParams } from 'containers/katana/hooks/useSetupEditorRouteParams';

/**********************************************************************************************************
 *   HOOK START
 **********************************************************************************************************/
const useDomainOptionsCustomField = (name: string) => {
    /***** HOOKS *****/
    const { katanaServiceId } = useKatanaParams();

    /***** QUERIES *****/
    const { data: domain_options_data } = katanaQuery.serviceID.getDomainOptions.useQuery(katanaServiceId);

    /***** HOOK RESULTS *****/
    return useMemo(() => domain_options_data?.attributes?.custom_fields?.find((field) => field?.name === name), [domain_options_data]);
};
/**********************************************************************************************************
 *   HOOK END
 **********************************************************************************************************/
export default useDomainOptionsCustomField;

/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Field, change, formValueSelector, reduxForm } from 'redux-form';

import { audaPriorityStatusToolUrl, audaPriorityTokenRetrievalToolUrl } from 'config/config';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import { fallbackEligibilityOptions } from 'containers/auDirect/forms/auApplicationForm';
import { modes } from 'containers/auDirect/modules/withAuApplicationForm';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import Anchor from 'components/Anchor';
import InactiveButton from 'components/Buttons/InactiveButton';
import SolidButton from 'components/Buttons/SolidButton';
import RequestLoader from 'components/Loaders/Request';

/*   ACTIONS
 *****************************************************/
import { getContentionDomains, updateApplicationDetails } from 'containers/auDirect/action';
import { submitUpdateInfo } from 'containers/services/action';
import { RenderField, RenderSelectField, alphaNumericValidation, requiredFieldValidation } from 'utilities/methods/form';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
class AuResubmitDetailsForm extends Component {
    constructor(props) {
        super(props);

        this.handleResubmitPriorityDetails = this.handleResubmitPriorityDetails.bind(this);
        this.handleResubmitEligibilityDetails = this.handleResubmitEligibilityDetails.bind(this);
    }

    handleResubmitPriorityDetails() {
        const { auDirectDomainId, updateApplicationDetails, priority_contact_id, priority_authinfo } = this.props;

        const application_domain = {
            priority_contact_id,
            priority_authinfo
        };

        updateApplicationDetails(auDirectDomainId, { application_domain });
    }

    handleResubmitEligibilityDetails() {
        const { auDirectDomainId, submitUpdateInfo, type, number } = this.props;

        const attributes = {
            type,
            number: number || ''
        };

        submitUpdateInfo(auDirectDomainId, attributes);
    }

    componentDidMount() {
        const { auDirectDomain, getContentionDomains, mode } = this.props;

        if (mode === modes.INVALID_ELIGIBILITY) getContentionDomains(auDirectDomain);
    }

    componentDidUpdate(prevProps, prevState) {
        const { type, change } = this.props;

        if (type !== prevProps.type && type === 'Citizen/Resident') change('number', '');
    }

    render() {
        const {
            submitting,
            valid,
            audirect_update_application_status,
            audirect_contention_domains_status,
            audirect_contention_domains_data,
            domain_form_status,
            mode,
            type
        } = this.props;
        const { handleResubmitPriorityDetails, handleResubmitEligibilityDetails } = this;

        const renderPriorityFields = () => {
            return (
                <div className="auApplyForm__priorityInfo">
                    <h3 className="auApplyForm__selectTitle">.AU Priority Information</h3>
                    <p className="auApplyForm__desc">
                        Please supply the .AU priority information for your related domain for proof that you hold the domain name.
                    </p>
                    <div className="auApplyForm__note">
                        Your priority token and password can be generated using{' '}
                        <Anchor href={audaPriorityStatusToolUrl} target="_blank">
                            auDA&apos;s tool
                        </Anchor>
                        . Otherwise if the domain name is with VentraIP, the information will be entered automatically for you.
                    </div>
                    <Field
                        label=".AU Priority Contact ID"
                        tooltip={`<div>To proceed with the .au direct registration, we require this information to confirm you hold the existing domain name. <a href="${audaPriorityTokenRetrievalToolUrl}" target="_blank">Where do I find this?</a></div>`}
                        name="priority_contact_id"
                        component={RenderField}
                        type="text"
                        validate={[requiredFieldValidation, alphaNumericValidation]}
                    />
                    <Field
                        label=".AU Priority authInfo"
                        tooltip={`<div>To proceed with the .au direct registration, we require this information to confirm you hold the existing domain name. <a href="${audaPriorityTokenRetrievalToolUrl}" target="_blank">Where do I find this?</a></div>`}
                        name="priority_authinfo"
                        component={RenderField}
                        type="text"
                        validate={[requiredFieldValidation]}
                    />
                </div>
            );
        };

        const renderEligibilityFields = () => {
            const eligibilityTypeOptions = audirect_contention_domains_data?.eligibility_options || fallbackEligibilityOptions;

            return (
                <div className="auApplyForm__eligibility">
                    <h3 className="auApplyForm__selectTitle">Eligibility Information</h3>
                    <p className="auApplyForm__desc">Please supply the eligibility information for your .AU domain application.</p>
                    <Field
                        label="Eligibility Type"
                        name="type"
                        component={RenderSelectField}
                        type="select"
                        validate={[requiredFieldValidation]}
                        className="form__dropdown"
                        options={eligibilityTypeOptions}
                    />
                    {type === 'Citizen/Resident' ? (
                        <p className="auApplyForm__eligibilityCitizenNote">
                            To process this registration, you will be required to perform either a Driver&apos;s Licence, Passport or Medicare Card ID
                            verification. Once you have paid for your order, we will contact you via email to complete the required ID verification.
                        </p>
                    ) : (
                        <Field
                            label="Eligibility Details"
                            name="number"
                            component={RenderField}
                            type="text"
                            validate={[requiredFieldValidation]}
                            className="form__textfield"
                        />
                    )}
                </div>
            );
        };

        /*  RENDER COMPONENT
         **********************************************************************************************************/
        if (
            audirect_update_application_status === 'loading' ||
            domain_form_status === 'loading' ||
            (mode === modes.INVALID_ELIGIBILITY && audirect_contention_domains_status === 'loading')
        )
            return <RequestLoader />;

        return (
            <div className="auApplyForm">
                <form className="auApplyForm__form">
                    {mode === modes.INVALID_APPLICATION ? renderPriorityFields() : renderEligibilityFields()}
                    {valid && !submitting ? (
                        <SolidButton
                            type="onClick"
                            onClick={mode === modes.INVALID_APPLICATION ? handleResubmitPriorityDetails : handleResubmitEligibilityDetails}
                        >
                            Re-submit Details
                        </SolidButton>
                    ) : (
                        <InactiveButton>Re-submit Details</InactiveButton>
                    )}
                </form>
            </div>
        );
    }
}

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
AuResubmitDetailsForm = reduxForm({
    form: 'auResubmitDetailsForm'
})(AuResubmitDetailsForm);

const selector = formValueSelector('auResubmitDetailsForm');

export default connect(
    (state) => ({
        audirect_update_application_status: state.auDirect.audirect_update_application_status,
        audirect_contention_domains_status: state.auDirect.audirect_contention_domains_status,
        audirect_contention_domains_data: state.auDirect.audirect_contention_domains_data,
        domain_form_status: state.services.domain_form_status,
        priority_contact_id: selector(state, 'priority_contact_id'),
        priority_authinfo: selector(state, 'priority_authinfo'),
        type: selector(state, 'type'),
        number: selector(state, 'number')
    }),
    {
        updateApplicationDetails,
        getContentionDomains,
        submitUpdateInfo,
        change
    }
)(AuResubmitDetailsForm);

/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { useEffect, useState } from 'react';

/**********************************************************************************************************
 *   HOOK START
 **********************************************************************************************************/

/**
 * @param { ImageSelectControl.ImageDataOrFile } imageData
 */
export function useImageURLorFileStream(imageData) {
    /***** STATE *****/
    const [imageURL, setImageURL] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    /***** EFFECTS *****/
    useEffect(() => {
        let fileReader;
        let isCancel = false;

        if ((Array.isArray(imageData) && !imageData.length) || !imageData) {
            setImageURL('');
        }

        function imageDataSelector(_imageData) {
            if (Array.isArray(_imageData) && _imageData[0] instanceof File) return _imageData[0];
            return _imageData;
        }

        const theImageData = imageDataSelector(imageData);

        if (theImageData && theImageData instanceof File) {
            setIsLoading(true);
            fileReader = new FileReader();
            fileReader.onload = (e) => {
                const { result } = e.target;
                if (result && !isCancel) {
                    setImageURL(result);
                }
            };
            fileReader.readAsDataURL(theImageData);
        } else {
            setImageURL(theImageData);
        }

        return () => {
            isCancel = true;
            if (fileReader && fileReader.readyState === 1) {
                fileReader.abort();
            }
        };
    }, [imageData]);

    useEffect(() => {
        setIsLoading(false);
    }, [imageURL]);

    /***** HOOK RESULTS *****/
    return { dataURL: imageURL, isLoading };
}
/**********************************************************************************************************
 *   HOOK END
 **********************************************************************************************************/

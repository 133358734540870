/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { useDynamicChildPageData } from 'containers/katana/containers/contentEditorModules/validFormSubmitButton/useDynamicChildPageData';
import { useKatanaParams } from 'containers/katana/hooks/useSetupEditorRouteParams';

/**********************************************************************************************************
 *   TYPE IMPORTS
 **********************************************************************************************************/
import type { KatanaNamespace } from 'containers/katana/types';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import { katanaServicePages } from 'containers/katana/consts';

const { EDITOR } = katanaServicePages;

const launchWebsiteSubpages: KatanaNamespace.SubPages[] = ['launch', 'fix-dns'];

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const ValidFormSubmitButtonContent = () => {
    /***** HOOKS *****/
    const { page, childPage, subpage } = useKatanaParams();

    /***** QUERIES *****/
    const { sectionID } = useDynamicChildPageData();

    /***** RENDER *****/
    if (subpage && launchWebsiteSubpages.includes(subpage)) {
        return 'Launch Website';
    }

    if (!sectionID && childPage) {
        return 'Create Section';
    }

    return page === EDITOR.to ? 'Save Updates' : 'Confirm and Continue';
};

/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import classNames from 'classnames';
import React from 'react';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import './_WrapperBox.scss';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/

/**
 * @type {React.FC<TWrapperBox.Props>}
 */
const WrapperBox = ({ error, isPrepended, disabled, validatedValid, validatedInvalid, className, children, fullWidth }) => {
    /***** RENDER HELPERS *****/
    const wrapperBoxClass = classNames('wrapperbox', className, {
        'wrapperbox--fullWidth': fullWidth,
        'wrapperbox--error': error,
        'error': error,
        'wrapperbox--isPrepended': isPrepended,
        'wrapperbox--disabled': disabled,
        'wrapperbox--validated_valid': validatedValid,
        'wrapperbox--validated_invalid': validatedInvalid
    });

    /***** RENDER *****/
    return <div className={wrapperBoxClass}>{children}</div>;
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

export { WrapperBox };

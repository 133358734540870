/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import classNames from 'classnames';
import React from 'react';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import Text from 'components/Utils/Text';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { createAppliedStylingClasses } from 'components/Utils/methods';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import { keys, merge } from 'lodash';
import './_NXPill.scss';

const colourProps = [
    /** Primary Colour */
    'primary',
    /** Black Colour */
    'black'
];

const appliedStylingProps = [
    /**
     * Wether the pill should apply the highlighted styling
     */
    'isHighlighted',

    /**
     * Wether the NXPill will have a hover effect when hovered
     */
    'hoverEffect',

    ...colourProps
];

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
interface NXPillProps {
    /**
     * The content inside the pill
     */
    children: React.ReactNode;

    /**
     * The function to call when the pill is clicked
     */
    onClick?: () => void;
    isHighlighted?: boolean;
    hoverEffect?: boolean;
    primary?: boolean;
    black?: boolean;
}

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
const NXPill: React.FC<NXPillProps> = (props) => {
    const _props = merge(keys(props).some((key) => colourProps.includes(key)) ? {} : { black: true }, props);
    const { children, onClick, isHighlighted } = _props;
    /***** RENDER HELPERS *****/
    const appliedStylingClasses = createAppliedStylingClasses({
        props: _props,
        keyBoundary: appliedStylingProps,
        componentName: 'NXPill',
        delimiter: '--'
    });
    const pillClassNames = classNames('NXPill', appliedStylingClasses);

    function renderChildren() {
        return (
            <Text bold size--s black={!isHighlighted} white={isHighlighted} break-word>
                {children}
            </Text>
        );
    }

    /***** RENDER *****/
    if (onClick) {
        return (
            <button type="button" className={pillClassNames} onClick={onClick}>
                {renderChildren()}
            </button>
        );
    }
    return <div className={pillClassNames}>{renderChildren()}</div>;
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

export default NXPill;

/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import classNames from 'classnames';
import React from 'react';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import Text from 'components/Utils/Text';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { useAppViewport } from 'utilities/hooks/useAppViewport/useAppViewport';
import { getMS365InformationFromData } from './helpers';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
/**
 * @typedef {import('../../types').NMicrosoft365Table.TStore} TStore
 * @typedef {import('../../types').NMicrosoft365Table.TMicrosoft365Seats} TMicrosoft365Seats
 */

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * @type {TMicrosoft365Seats}
 */
export const MS365TableRenderSeats = ({ data }) => {
    /***** HOOKS *****/
    const isMobile = useAppViewport(['xs', 'sm']);

    /***** RENDER HELPERS *****/
    const { numberOfSeats } = getMS365InformationFromData(data);

    const classes = /** @type {const} */ ({
        tag: classNames('Microsoft365TableBody__seats', {
            'Microsoft365TableBody__seats--mobile': isMobile
        }),
        title: classNames('Microsoft365TableBody__seatsTitle', {
            'Microsoft365TableBody__seatsTitle--mobile': isMobile
        })
    });

    /***** RENDER *****/
    return (
        <>
            {isMobile && typeof numberOfSeats === 'number' && (
                <Text align--left size--s bold lead--1 uppercase className={classes.title}>
                    Seats
                </Text>
            )}
            <Text align--left size--s info bold lead--1 className={classes.tag}>
                {numberOfSeats}
            </Text>
        </>
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React from 'react';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import BaseDropdown from '../_BaseDropdown';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import './_OutlineDropdown.scss';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * Outline Dropdown Variant, use this for Secondary dropdowns
 */
/**
 * @param {Omit<Parameters<typeof BaseDropdown>[number], 'variant'>} props
 */
function OutlineDropdown(props) {
    /***** RENDER *****/
    return <BaseDropdown variant="OutlineDropdown" {...props} />;
}

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

export default OutlineDropdown;

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import { VipRewardsTierIcon } from 'containers/vipRewards/sections/moreInfo/tierIcon';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import Text from 'components/Utils/Text';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { capitalize } from 'utilities/methods/commonActions';

/**********************************************************************************************************
 *   TYPE IMPORTS
 **********************************************************************************************************/
import type { Tier } from 'containers/vipRewards/sections/moreInfo/tierIcon';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import './_vipRewardsTierBar.scss';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type VipRewardsTierBarProps = {
    /**
     * Current tier of the logged in user
     */
    tier: Tier;

    /**
     * Discount amount to display
     */
    discount: string;
};

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const VipRewardsTierBar: React.FC<VipRewardsTierBarProps> = ({ tier = 'bronze', discount = '55% off' }) => {
    return (
        <div className={`vipRewardsTierBar vipRewardsTierBar--${tier}`}>
            <div>
                <VipRewardsTierIcon tier={tier} />
                <Text black semiBold size--l>
                    <p>{capitalize(tier)} Status discount</p>
                </Text>
            </div>
            <Text black bold size--xxxl uppercase>
                <p>{discount}</p>
            </Text>
        </div>
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { EditorContent, useCurrentEditor } from '@tiptap/react';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import { ToolbarPlugin } from 'components/Form/RichTextEditor/plugins/Toolbar/ToolbarPlugin';

/**********************************************************************************************************
 *   TYPE IMPORTS
 **********************************************************************************************************/
import { FloatingLinkEditorPlugin } from 'components/Form/RichTextEditor/plugins/FloatingLinkEditorPlugin';
import type { RichTextEditorPluginRendererNamespace } from 'components/Form/RichTextEditor/plugins/PluginRenderer/types';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/

/**
 * Separate out rendering so that it has access to the RichTextEditorContext
 */
export const PluginRenderer: React.FC<RichTextEditorPluginRendererNamespace.Props> = ({ features }) => {
    /***** HOOKS *****/
    const { editor } = useCurrentEditor();

    /***** RENDER *****/
    return (
        <>
            <ToolbarPlugin features={features} />
            <EditorContent editor={editor} />
            <FloatingLinkEditorPlugin />
        </>
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

import { useMutation } from '@tanstack/react-query';
import { getService } from 'containers/katana/queries/serviceID/getService';
import { KATANA_API } from 'utilities/api/katana';
import type { KatanaAPI } from 'utilities/api/katana/types';
import { handleDefaultErrorNotification, handleDefaultSuccessNotification } from 'utilities/methods/commonActions';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type Params = KatanaAPI.Katana.Site.ServiceID.Pages.PageID.Reset.POST.Params;

/**********************************************************************************************************
 *   HOOK START
 **********************************************************************************************************/
function _useMutation() {
    async function handleMutation(params: Params) {
        return KATANA_API.katana.site.service_id.pages.page_id.reset.POST(params);
    }

    return useMutation({
        mutationFn: handleMutation,
        onSuccess: (data) => {
            handleDefaultSuccessNotification(data);
        },
        onError: handleDefaultErrorNotification,

        onSettled: (data, error, { serviceID }) => {
            getService.invalidateQueries(serviceID);
        }
    });
}

export const resetPage = Object.freeze({
    useMutation: _useMutation
});

/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { queryOptions, useQuery } from '@tanstack/react-query';
import queryClient from 'store/queryClient';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { API } from 'utilities/api/domain';
import type { DomainAPI } from 'utilities/api/domain/types';
import { TruthKeys } from 'utilities/methods/commonActions/Truth';
import { createBaseQueryKey } from 'utilities/methods/tanstack/createBaseQueryKey';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type Params = DomainAPI.Domain.DomainID.DNS.Preset.PresetName.PreviewChanges.POST.Params;
type PartialParams = Partial<Params>;

function createQueryKey({ id, preset, attributes }: PartialParams) {
    return createBaseQueryKey(['domain', id, 'dns', 'preset', preset, 'preview-changes', attributes]);
}

function createQueryOptions(params: PartialParams) {
    return queryOptions({
        queryKey: createQueryKey(params),
        queryFn: () => API.domain.POST.presetPreview(params as Params),
        enabled: Boolean(params.id && params.preset && params.attributes && TruthKeys(params.attributes)),
        select: (data) => {
            if (data?.status === 200) {
                return data.data;
            }
        }
    });
}

function resetQueries({ id, preset }: Omit<Params, 'attributes'>) {
    return queryClient.resetQueries({
        queryKey: createBaseQueryKey(['domain', id, 'dns', 'preset', preset, 'preview-changes'])
    });
}

/**
 * @param {Omit<Params, 'attributes'>} params
 */
function invalidate({ id, preset }) {
    return queryClient.invalidateQueries({
        queryKey: createBaseQueryKey(/** @type {const} */ (['domain', id, 'dns', 'preset', preset, 'preview-changes']))
    });
}

/**********************************************************************************************************
 *   HOOK START
 **********************************************************************************************************/
function _useQuery(params: PartialParams) {
    return useQuery(createQueryOptions(params));
}

/**********************************************************************************************************
 *   HOOK END
 **********************************************************************************************************/

export const getDNSPresetPreview = Object.freeze({
    useQuery: _useQuery,
    resetQueries,
    createQueryKey,
    createQueryOptions,
    invalidate
});

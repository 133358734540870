import { _NXCardActions as NXCardActions } from 'components/NXCard/_Actions';
import { _NXCardContent as NXCardContent } from 'components/NXCard/_Content';
import { _NXCardToolTip as NXCardToolTip } from 'components/NXCard/_ToolTip';
import { _NXCard } from './Card';

const NXCard = Object.assign(_NXCard, {
    Content: NXCardContent,
    Actions: NXCardActions,
    ToolTip: NXCardToolTip
});

export default NXCard;

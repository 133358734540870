/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { Outlet, createRoute } from '@tanstack/react-router';
import { useStore } from '@tanstack/react-store';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import { EmailHostingTitle } from 'containers/email/components/title/title';
import { EmailHostingModuleStore } from 'containers/email/moduleConditions';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import RequestLoader from 'components/Loaders/Request';
import SideBarPage from 'components/SideBarPage';
import Transition from 'components/Transition';

/**********************************************************************************************************
 *   ROUTE IMPORTS
 **********************************************************************************************************/
import { MyServicesRoute } from 'containers/services';
import { routerMiddleware } from 'router/utils/middleware';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import './_email.scss';

/**********************************************************************************************************
 *   ROUTE START
 **********************************************************************************************************/
export const EmailManageRoute = createRoute({
    getParentRoute: () => MyServicesRoute,
    path: '/email-hosting/$groupId',
    pendingComponent: RequestLoader,
    beforeLoad(opts) {
        routerMiddleware.business({ path: '/my-services/email-hosting' }, opts);
        routerMiddleware.business(this, opts);
        routerMiddleware.authentication('user', opts);
    },
    component: EmailRouteComponent
});

/**********************************************************************************************************
 *   ROUTE COMPONENT START
 **********************************************************************************************************/
function EmailRouteComponent() {
    /***** HOOKS *****/
    const store = useStore(EmailHostingModuleStore);

    EmailHostingModuleStore.useConditions();

    /***** RENDER HELPERS *****/
    const [sidebarLinks] = SideBarPage.useGetSidebarLinks([
        {
            list_title: 'General',
            list_icon: 'accounts',
            list_items: store.general
        },
        {
            list_title: 'Tools',
            list_icon: 'settings',
            list_items: store.tools
        },
        {
            list_title: 'Admin',
            list_icon: 'settings',
            list_items: store.admin
        }
    ]);

    /***** RENDER *****/
    return (
        <>
            <EmailHostingTitle />
            <SideBarPage sidebarList={sidebarLinks}>
                <Transition>
                    <Outlet />
                </Transition>
            </SideBarPage>
        </>
    );
};

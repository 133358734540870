/**
 *
 * @param {File} file
 * @param {(result: string) => void} setResult
 */
export function handleImageLoading(file, setResult) {
    const fileReader = new FileReader();
    fileReader.onload = () => {
        if (fileReader.result && typeof fileReader.result === 'string') {
            setResult(fileReader.result);
        }
    };
    fileReader.readAsDataURL(file);
}

/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { createContext } from 'react';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
export const InvoicesTableContext = createContext({});

export const INVOICE_HEADINGS = /** @type {const} */ ({
    INVOICE_ID: 'invoice_id',
    STATUS: 'status',
    TOTAL: 'total',
    DUE_DATE: 'due_date',
    DATE_PAID: 'date_paid',
    ACTION: 'action'
});

export const sortableHeadings = /** @type {const} */ ([INVOICE_HEADINGS.DUE_DATE, INVOICE_HEADINGS.DATE_PAID]);

export const desktopTableHeadings = /** @type {const} */ ([
    [INVOICE_HEADINGS.INVOICE_ID, 'Invoice #'],
    [INVOICE_HEADINGS.STATUS, 'Status'],
    [INVOICE_HEADINGS.TOTAL, 'Total'],
    [INVOICE_HEADINGS.DUE_DATE, 'Due Date'],
    [INVOICE_HEADINGS.DATE_PAID, 'Date Paid'],
    [INVOICE_HEADINGS.ACTION, '']
]);
export const mobileTableHeadings = /** @type {const} */ ([
    [INVOICE_HEADINGS.INVOICE_ID, 'Invoice #'],
    [INVOICE_HEADINGS.STATUS, 'Status'],
    [INVOICE_HEADINGS.DUE_DATE, 'Due Date']
]);

export const desktopColumns = '95px 1fr 1fr 1fr 1fr NXActions';
export const mobileColumns = '80px 1fr 1fr';
export const mobileColumnsHead = `${mobileColumns} 25px`;
export const mobileTableSizes = ['sm', 'xs'];

/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { createRoute } from '@tanstack/react-router';

/**********************************************************************************************************
 *   ROUTE IMPORTS
 **********************************************************************************************************/
import { GoogleManageRoute } from 'containers/gsuite';
import { routerMiddleware } from 'router/utils/middleware';

/*   ACTIONS
 *****************************************************/
import { getGSuiteMailbox } from 'containers/gsuite/action';

/**********************************************************************************************************
 *   ROUTE START
 **********************************************************************************************************/
export const GoogleMailboxRoute = createRoute({
    getParentRoute: () => GoogleManageRoute,
    path: 'mailbox/$mailboxId',
    loader({ cause, context, params }) {
        if (cause === 'enter') {
            getGSuiteMailbox(params.workspaceId, params.mailboxId)(context.store.dispatch);
        }
    },
    beforeLoad(opts) {
        routerMiddleware.business(this, opts);
        routerMiddleware.authentication('user', opts);
    }
});

/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import { _PropertyRenderer as PropertyRenderer } from 'containers/katana/components/dynamicFormFieldRenderer/propertyRenderer';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/

/**
 * @param {{
 *      properties: import('containers/katana/types').KatanaNamespace.SectionDefinitions.PropertiesModified[]
 * }} props
 */
function PropertiesRenderer({ properties }) {
    /***** RENDER *****/
    return properties.map((property, i) => <PropertyRenderer key={`${property.key}${i}`} property={property} />);
}
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

export { PropertiesRenderer };

/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { has } from 'lodash';
import React from 'react';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import amexImage from 'assets/images/amex.png';
import mastercardImage from 'assets/images/mastercard.png';
import visaImage from 'assets/images/visa.png';
import { CREDIT_CARD_TYPES } from './consts';

const cardImages = {
    [CREDIT_CARD_TYPES.VISA]: visaImage,
    [CREDIT_CARD_TYPES.MASTER_CARD]: mastercardImage,
    [CREDIT_CARD_TYPES.AMERICAN_EXPRESS]: amexImage
};

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * @type {React.FC<{
 *      className: string,
 *      cardType: import('utilities/NXUtils').NXUtils.ValueOf<CREDIT_CARD_TYPES>
 * }>}
 */
const CreditCardImage = ({ className, cardType }) => {
    /***** RENDER *****/
    if (!has(cardImages, cardType)) {
        return '';
    }
    return <img className={className} src={cardImages[cardType]} alt={cardType} />;
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

export default CreditCardImage;

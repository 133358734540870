/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import classNames from 'classnames';
import React from 'react';
import { useBoolean } from 'usehooks-ts';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import { PhosphorIcons } from 'components/Icons/Phosphor';
import { Revealer } from 'components/Revealer';
import { Flex } from 'components/Utils/Flex';
import Padding from 'components/Utils/Padding';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import NXBoxDefaultPadding from 'components/NXBox/DefaultPadding';
import { NXBoxDescription } from 'components/NXBox/Description';
import NXBoxTitle from 'components/NXBox/Title';
import NXBoxTop from 'components/NXBox/Top';
import NXBox from 'components/NXBox/_NXBox';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { useNXBoxDefaultPadding } from 'components/NXBox/DefaultPadding/useNXBoxDefaultPadding';
import { useAppViewport } from 'utilities/hooks/useAppViewport/useAppViewport';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import './_NXBoxTopAccordion.scss';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
interface NXBoxTitleAccordionProps {
    title: string;
    description?: string;
    children: React.ReactNode;
    topChildren?: React.ReactNode;
    isOpenDefault?: boolean;
    className?: string;
}

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * This component turns the box title into an accordion on mobile
 */
export const _NXBoxTopAccordion: React.FC<NXBoxTitleAccordionProps> = ({
    title,
    description,
    children,
    topChildren,
    isOpenDefault = false,
    className
}) => {
    /***** STATE *****/
    const { value: isRevealerOpen, toggle } = useBoolean(isOpenDefault);

    /***** HOOKS *****/
    const isMobile = useAppViewport(['xs', 'sm']);
    const padding = useNXBoxDefaultPadding();

    /***** RENDER *****/
    if (isMobile) {
        return (
            <NXBox className={classNames('NXBoxTopAccordion__NXBox', className)}>
                <div className="NXBoxTopAccordion">
                    <NXBoxDefaultPadding paddingOnly inject>
                        <Flex justify="between" items="center" inject fullWidth>
                            <button type="button" onClick={toggle} className="NXBoxTopAccordion__accordionToggle">
                                <NXBoxTitle title={title} />
                                <PhosphorIcons.Chevron state={isRevealerOpen ? 'up' : 'down'} primary bold />
                            </button>
                        </Flex>
                    </NXBoxDefaultPadding>
                    <Revealer isOpen={isMobile ? isRevealerOpen : true}>
                        {description && (
                            <Padding x={padding} bottom={padding} top={0}>
                                <NXBoxDescription description={description} />
                            </Padding>
                        )}
                        {topChildren && (
                            <Padding x={padding} bottom={padding} top={0}>
                                <div className="NXBoxTop">{topChildren}</div>
                            </Padding>
                        )}
                        {children}
                    </Revealer>
                </div>
            </NXBox>
        );
    }
    return (
        <NXBox className={classNames(className)}>
            <NXBoxTop title={title} description={description}>
                {topChildren}
            </NXBoxTop>
            {children}
        </NXBox>
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

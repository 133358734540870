/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { createContext } from 'react';

/**
 * Default context for the dropdown.
 */
const defaultContext = {
    isOpen: false,
    onClick: () => {}
};

/**
 * Context for the dropdown.
 * @type {import('components/Revealer/types').NRevealer.Context}
 */
export const RevealerContext = createContext(defaultContext);

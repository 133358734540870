/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import classNames from 'classnames';
import React from 'react';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import Padding from 'components/Utils/Padding';
import Text from 'components/Utils/Text';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { useAppViewport } from 'utilities/hooks/useAppViewport/useAppViewport';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import './_NXTableErrorMessage.scss';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * @type {NErrorMessage.TErrorMessage}
 */
const ErrorMessage = ({ message, className }) => {
    /***** HOOKS *****/
    const isMobile = useAppViewport(['xs', 'sm']);

    /***** RENDER HELPERS *****/
    const textProps = {
        'lead--1': true,
        'size--s': true,
        'secondary': true,
        'size--xss': isMobile,
        'align--left': isMobile,
        'align--center': !isMobile,
        'className': classNames('NXTableErrorMessage', className)
    };

    /***** RENDER *****/
    if (!message) {
        return null;
    }

    return (
        <Padding x={isMobile ? 4 : 3} y={isMobile ? 2 : 3}>
            <Text {...textProps}>{message}</Text>
        </Padding>
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

export default ErrorMessage;

/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { useParams, useRouter } from '@tanstack/react-router';

/**********************************************************************************************************
 *   TYPE IMPORTS
 **********************************************************************************************************/
import type { AnyRouteId, RoutePaths } from 'router/types';
import type { HashesAtRoute } from 'router/types/hashes';
import { getModuleConfigStatus } from 'router/utils/getModuleConfigStatus';
import { injectRouteParams } from 'router/utils/injectRouteParams';
import type { NXUtils } from 'utilities/NXUtils';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type ReducedModuleObject = NXUtils.ReplaceKey<ModuleObject, 'to', RoutePaths, false>;
export type ModuleObject = {
    [To in AnyRouteId]: {
        enabled: boolean;
        label: string | null;
        to: To;
        hash: To extends keyof HashesAtRoute ? HashesAtRoute[To] : never;
    };
}[AnyRouteId];

export namespace UseGetSidebarLinks {
    export type Links = {
        list_items: {
            link: string;
            enabled: boolean;
            label: string;
        }[];
        list_title: string | null;
        list_icon?: string;
    }[];

    export type ReturnType = [links: Links, isValidURL: boolean] & Links;
}

export type DevelopmentModuleObject = ModuleObject & {
    /**
     * This is expected to be set through the `useUpdateEnabledWithReason` method on a module store. It is only
     * used in development mode to display a reason why a module is disabled.
     */
    reason?: Readonly<string>;
};

export type Links<TTitles extends string | null = string | null> = {
    list_title: NonNullable<TTitles> | null;
    list_icon?: string;
    list_items: Record<string, ModuleObject>;
};

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
export const getActiveModules = (modules: Record<string, ModuleObject>) => {
    return Object.values(modules).filter((item) => item.enabled);
};

/**
 * Takes any module with an array of links and reduces it to the first link.
 */
export const reduceModuleLinks = (modules: Array<ModuleObject>) => {
    return modules.map((module) => (Array.isArray(module.to) ? { ...module, to: module.to[0] } : module)) as Array<ReducedModuleObject>;
};

/**
 * Hides modules that don't have active routes based on the params passed in.
 */
const getModulesWithActiveRoutes = (modules: Array<ReducedModuleObject>, params: Record<any, any>) => {
    return modules.filter(({ to: path, hash }) => {
        const { isAtleastOneHashActive, isEveryRouteSegmentActive } = getModuleConfigStatus({ path, hash }, params);

        return isEveryRouteSegmentActive && isAtleastOneHashActive;
    });
};

/**
 * Goes through each module and injects the params into the link where they match. This assumes
 * That params are generated from tanstack router where the original link would be $paramName and the
 * params object would have the key paramName and a corresponding value
 */
export const injectParams = (modules: Array<ModuleObject>, params: Record<any, any>) => {
    return modules.map((module) => {
        return {
            ...module,
            link: injectRouteParams(module.to, params)
        };
    });
};

/**
 * Takes an array of sidebar links and returns the links that are active based on the params passed in.
 */
export const getSidebarLinks = (args: Array<Links>, params: Record<any, any>) => {
    return args.map(({ list_items, ...item }) => {
        const activeModules = getActiveModules(list_items);
        const reducedModules = reduceModuleLinks(activeModules);
        const modulesWithActiveRoutes = getModulesWithActiveRoutes(reducedModules, params);
        const paramInjectedModules = injectParams(modulesWithActiveRoutes, params);

        return {
            ...item,
            list_items: paramInjectedModules
        };
    });
};

/**
 * Hook for generating sidebar links based on the params passed in.
 *
 * With strict null checks disabled, typescript cannot handle TTItle values of null, therefore if we want to use a null title for any module
 * then it should be written as such `list_title: null as 'DEV_STRINGIFIED_NULL',`
 */
export const useGetSidebarLinks = <TTitles extends string | null>(args: Array<Links<TTitles>>, filter?: Array<NoInfer<TTitles | null>>) => {
    /***** HOOKS *****/
    const params = useParams({ strict: false });
    const { buildLocation } = useRouter();

    /***** FUNCTIONS *****/
    const build = (modules: Array<ModuleObject>) => {
        return modules.map(({ to, hash, ...module }) => ({
            ...module,
            link: buildLocation({
                to: Array.isArray(to) ? to.at(0) : to,
                hash: Array.isArray(hash) ? hash.at(0) : hash,
                params
            }).href
        }));
    };

    /***** RENDER HELPERS *****/
    const links = args
        .map(({ list_items, ...item }) => {
            const activeModules = getActiveModules(list_items);
            const reducedModules = reduceModuleLinks(activeModules);
            const modulesWithActiveRoutes = getModulesWithActiveRoutes(reducedModules, params);
            const withFinalPathname = build(modulesWithActiveRoutes);

            return {
                ...item,
                list_items: withFinalPathname
            };
        })
        .filter(({ list_title }) => !filter || filter.includes(list_title));

    /***** RESULT *****/
    return [links] as const;
};

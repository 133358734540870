/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import { TOAST_NOTIFICATION } from './consts';

/**
 * pubsub - a simple pubsub implementation to allow for toast notifications to be published and subscribed to.
 *
 * @type {import('./types').TPubSub}
 */
export const pubSub = {
    events: {},
    subscribe(event, callback) {
        if (!this.events[event]) {
            this.events[event] = [];
        }
        this.events[event].push(callback);
    },
    publish(event, data) {
        if (!this.events[event]) {
            return [];
        }

        // This must create a new array otherwise react won't recognize the array reference changing
        // and therefore not cause a rerender
        return this.events[event].map((callback) => callback([...data]));
    },
    unsubscribe(event, callback) {
        if (!this.events[event]) {
            return [];
        }
        return this.events[event].filter((cb) => cb !== callback);
    }
};

/**
 * Represents an array of toast notification messages. This proxy will publish a notification event when the array is
 * updated.
 */
export const atomicState = new Proxy(/** @type {import('./types').TAtomicToastState[]} */ ([]), {
    set(target, property, value) {
        target[property] = value;
        pubSub.publish(TOAST_NOTIFICATION, target);
        return true;
    },
    deleteProperty(target, property) {
        delete target[property];
        pubSub.publish(TOAST_NOTIFICATION, target);
        return true;
    }
});

/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import type { UseQueryOptions } from '@tanstack/react-query';
import { useQuery, type QueryKey } from '@tanstack/react-query';
import type { NXQueryUtils } from 'utilities/methods/tanstack/types';

// prettier-ignore
export function createUseQuerySelectorMethod<
    TParams extends any,
    TData extends NXQueryUtils.ApiData,
    TCreateQueryOptions extends ((params: TParams) => UseQueryOptions<any, any, any, QueryKey>) // TODO: This requires passing of the generic for now.
>(
    createQueryOptions: TCreateQueryOptions
) {
    function useQuerySelector<TSelected>(params: TParams, selector: (data: TData) => TSelected) {
        return useQuery({ ...createQueryOptions(params), select: selector });
    }

    return useQuerySelector;
}

/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React from 'react';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import { NXIframe } from 'components/NXIframe';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import './_InteractiveGoogleMapsIframe.scss';
const GOOGLE_MAPS_BASE_URL = 'https://maps.google.com/maps';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
function InteractiveGoogleMapsIframe({ address, title = 'Interactive Google Maps' }) {
    const finalURL = address
        ? `${GOOGLE_MAPS_BASE_URL}?q="${address}"&output=embed`
        : 'https://www.google.com/maps/embed?pb=!4v1692332207159!6m8!1m7!1sQBjl4XW3gK09XWq_mje_kw!2m2!1d48.37323669012159!2d-123.5868787911571!3f179.8060627659077!4f-26.16078412673165!5f2.984181192797212';

    /***** RENDER *****/
    return (
        <div className="InteractiveGoogleMapsIframe__wrapper">
            <NXIframe
                className="InteractiveGoogleMapsIframe"
                title={title}
                src={finalURL}
                width="100%"
                height="450"
                style={{ border: 0 }}
                allowFullScreen={false}
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
            />
        </div>
    );
}
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

export default InteractiveGoogleMapsIframe;

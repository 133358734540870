/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import Grid from 'components/Grid';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { useAppViewport } from 'utilities/hooks/useAppViewport/useAppViewport';

/**********************************************************************************************************
 *   TYPE IMPORTS
 **********************************************************************************************************/
import type { ContentOptionsCardGridNamespace } from 'containers/katana/containers/contentEditorModules/chooseWebLayout/contentOptionsCardGrid/types';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/

const ContentOptionsCardGrid: ContentOptionsCardGridNamespace.Component = ({ children, contentOptionsDataList }) => {
    const isMobile = useAppViewport(['xs', 'sm']);
    /***** RENDER *****/
    return (
        <Grid className="ContentOptionsCardGrid" columns={isMobile ? '100%' : '1fr 1fr'} gap={4} alignItems--stretch>
            {contentOptionsDataList.map(children)}
        </Grid>
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

export default ContentOptionsCardGrid;

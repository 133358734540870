/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { createRoute, redirect } from '@tanstack/react-router';

/**********************************************************************************************************
 *   ROUTE IMPORTS
 **********************************************************************************************************/
import { SupportRoute } from 'containers/support';

/**********************************************************************************************************
 *   ROUTE START
 **********************************************************************************************************/
export const RedirectETicketRoute = createRoute({
    getParentRoute: () => SupportRoute,
    path: 'eTickets',
    beforeLoad() {
        throw redirect({ to: '/support/tickets' });
    }
});

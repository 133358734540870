/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import classNames from 'classnames';
import React from 'react';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import { PropInjector } from 'components/Utils/PropInjector';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import GridSpan from 'components/Grid/GridSpan';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { createAppliedStylingClasses } from 'components/Utils/methods';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import { GridStyledPropKeys } from 'components/Grid/consts';
import './_Grid.scss';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * The Grid component. just a simple grid
 * @type {React.FC<import('components/Grid/types').TGrid.Props>}
 */
const _Grid = (props) => {
    const { className, columns, gap = 2, children, inject } = props;

    /***** RENDER HELPERS *****/
    const appliedStylingClasses = createAppliedStylingClasses({
        props,
        keyBoundary: GridStyledPropKeys,
        componentName: 'NXGrid',
        delimiter: '--'
    });

    const rowBaseClassNames = classNames('NXGrid', className, appliedStylingClasses);

    const rowStyle = {
        gridTemplateColumns: columns,
        gap: `calc(5px * ${gap})`
    };

    /***** RENDER *****/
    return (
        <PropInjector inject={inject} injectableProps={{ className: rowBaseClassNames, style: rowStyle }} injectable={children}>
            <div className={rowBaseClassNames} style={rowStyle}>
                {children}
            </div>
        </PropInjector>
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

const Grid = Object.assign(_Grid, { Span: GridSpan });

export default Grid;

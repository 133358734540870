import { createRoute } from '@tanstack/react-router';
import { AppRoute } from 'App';
import { routerMiddleware } from 'router/utils/middleware';

export const WebsiteSupportArticleUrlRedirectRoute = createRoute({
    getParentRoute: () => AppRoute,
    path: '/support-centre/$supportArticle',
    beforeLoad(opts: any) {
        routerMiddleware.business(this, opts);
    }
});

/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import { _ScrollableComponentAnchor } from './Anchor';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { createElement } from 'react';
import { useSectionModuleContext } from 'utilities/methods/sectionRendering';
import { useScrollableComponent } from './hooks';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * Provides functionality to any component inside a section module to be scrollable, and to be able to scroll to it using the sidebarRef.
 *
 * @type {ScrollableComponent}
 *
 * **TODO:** Currently the component does not respect if the module is the first module, meaning that it will scroll to the component even
 * if it is the first component when the scroll is being caused by a route change rather than the sidebar.
 *
 * To resolve this, one approach would be to pass a context from the SidebarPage component with the list of links that we can use to determine
 * if the current module is the first module or not. This would not be a performance issue because rerendering this component is
 * cheap and the context also doesn't change often.
 */
const _ScrollableComponent = ({ children, className, ready = true, allowImmediateReady = false, as = 'section' }) => {
    /***** HOOKS *****/
    const { path } = useSectionModuleContext();
    const scrollRef = useScrollableComponent(path, ready, {
        allowImmediateReady
    });

    /***** RENDER *****/
    return createElement(
        as,
        {
            ref: scrollRef,
            className
        },
        children
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

const ScrollableComponent = Object.assign(_ScrollableComponent, {
    Anchor: _ScrollableComponentAnchor
});

export { ScrollableComponent };

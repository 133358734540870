import { regexes } from 'utilities/methods/regexes';

export function validateHref(href: string): boolean {
    if (href.startsWith('http:') && !href.startsWith('http://')) {
        return false;
    }
    if (href.startsWith('https:') && !href.startsWith('https://')) {
        return false;
    }
    return regexes.href().test(href);
}

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
export const COPY_restoreConfirmDialogNotification = `By clicking restore, the system will generate a new invoice to match the terminated service.
                                Once this invoice is generated you'll want to proceed to this invoice and complete the transaction,
                                this will begin the restoration and will contact our technical support team on your behalf to
                                complete the file restore.`;

export const COPY_payInvoiceLater = 'Pay this invoice later';
export const COPY_closeInvoice = 'Close Invoice';

import { Navigate, useNavigate } from '@tanstack/react-router';
import Title from 'components/Title';
import { pushNotification } from 'components/Toast/functions';
import { ticket_name } from 'config/config';
import { getTicketSubmitUrl } from 'containers/support/methods';
import { useSelector } from 'react-redux';
import { API } from 'utilities/api/hosting';
import { createPopup, getDataFromSuccessResponse, getErrorFromFailResponse } from 'utilities/methods/commonActions';

export const _HostingPleskTitle = () => {
    /***** HOOKS *****/
    const navigate = useNavigate();
    const hosting_information_data = useSelector((state: any) => state.hosting.hosting_information_data);

    /***** DERIVED *****/
    const { type, id, product } = hosting_information_data;
    const { domain } = hosting_information_data.attributes;

    /***** FUNCTIONS *****/
    const handleLogin = () => {
        const { closePopup, goToTargetUrl } = createPopup();

        API.hosting.GET.login
            .plesk(id)
            .then((response) => {
                const data = getDataFromSuccessResponse(response);
                goToTargetUrl(data.attributes.url);
            })
            .catch((error) => {
                pushNotification(getErrorFromFailResponse(error));
                closePopup();
            });
    };

    /***** RENDER *****/
    return (
        <Title
            serviceTitle={domain}
            serviceSubTitle={`${product.attributes.name} (Shared)`}
            serviceType={type}
            action={[
                {
                    label: 'Login',
                    color: 'notice',
                    type: 'onClick',
                    onClick: (e) => {
                        e.preventDefault();
                        handleLogin();
                    }
                },
                {
                    label: 'WordPress Hosting Help',
                    className: 'TitleShared__dropdown',
                    size: 'medium',
                    type: 'onClick',
                    onClick: () => navigate({ to: '/support/support-centre/$', params: { _splat: 'wordpress-hosting' } }),
                    list: [
                        {
                            label: `Submit ${ticket_name}`,
                            type: 'onClick',
                            onClick: () => Navigate({ to: getTicketSubmitUrl({ department: 'technical-support', service: id }) })
                        }
                    ]
                }
            ]}
        />
    );
};

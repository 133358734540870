/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { useLocation } from "@tanstack/react-router";
import { service_status, ticket_name } from "config/config";
import { useMemo } from "react";

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import Subnav from "components/Subnav";

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const SupportSubNav = () => {
    /***** HOOKS *****/
    const { pathname } = useLocation();

    /***** RENDER HELPERS *****/
    const navItems = useMemo(() => [
        {
            label: 'Support Centre',
            link: '/support/support-centre',
            icon: 'support'
        },
        {
            label: `${ticket_name}s`,
            link: '/support/tickets',
            icon: 'eticket'
        },
        {
            label: 'Migration Request',
            link: '/support/migration-request',
            icon: 'icon-migrations'
        },
        {
            label: 'Feedback',
            link: '/support/feedback',
            icon: 'customer-service'
        },
        {
            label: 'Service Status',
            external: true,
            link: service_status,
            icon: 'service-status'
        }
    ], []);

    /***** RENDER *****/
    return <Subnav items={navItems} pathname={pathname} />;
};

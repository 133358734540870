/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { createRoute } from '@tanstack/react-router';
import { katanaQuery } from 'containers/katana/queries/tanstackTree';

/**********************************************************************************************************
 *   ROUTE IMPORTS
 **********************************************************************************************************/
import { VIPSitesSetupRoute } from 'containers/katana/routes/vip-sites.$katanaServiceId/_content-editor-lightbox/setup';
import { routerMiddleware } from 'router/utils/middleware';
import { lazyFN } from 'utilities/methods/tanstack/router/lazyFn';

/**********************************************************************************************************
 *   ROUTE START
 **********************************************************************************************************/
export const VIPSitesSetupTemplateContentChildPageRoute = createRoute({
    getParentRoute: () => VIPSitesSetupRoute,
    path: 'template-content/$childPage',
    loader({ params }) {
        katanaQuery.serviceID.meta.getSectionDefinitions.prefetchQuery(Number(params.katanaServiceId));
    },
    beforeLoad(opts) {
        routerMiddleware.business({ path: '/my-services/vip-sites/$katanaServiceId/_content-editor-lightbox/setup/template-content' }, opts);
        routerMiddleware.business(this, opts);
        routerMiddleware.authentication('user', opts);
    }
}).lazy(lazyFN(() => import('./$childPage.lazy'), 'LazyVIPSitesSetupTemplateContentChildPageRoute'));

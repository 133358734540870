/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { Outlet, createRoute } from '@tanstack/react-router';
import { useStore } from '@tanstack/react-store';

/**********************************************************************************************************
 *   ROUTE IMPORTS
 **********************************************************************************************************/
import { MyServicesRoute } from 'containers/services';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import { ConditionalVentraSynBanner } from 'containers/hosting/components/conditionalSynergyMigrationBanner';
import { HostingRouteLoader } from 'containers/hosting/components/pageLoader';
import { HostingTitle } from 'containers/hosting/components/title';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import RequestLoader from 'components/Loaders/Request';
import SideBarPage from 'components/SideBarPage';
import Transition from 'components/Transition';

/**********************************************************************************************************
 *   ROUTE IMPORTS
 **********************************************************************************************************/
import { HostingModuleStore } from 'containers/hosting/moduleConditions';
import { routerMiddleware } from 'router/utils/middleware';

/*   ACTIONS
 *****************************************************/
import { getHostingInformation } from './state/accountActions';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import 'containers/hosting/styles/_accountStyles.scss';
import 'containers/hosting/styles/_adminStyles.scss';
import 'containers/hosting/styles/_baseStyles.scss';
import 'containers/hosting/styles/_configStyles.scss';
import 'containers/hosting/styles/_securityStyles.scss';

/**********************************************************************************************************
 *   ROUTE START
 **********************************************************************************************************/
export const HostingManageRoute = createRoute({
    getParentRoute: () => MyServicesRoute,
    path: 'hosting/$serviceId',
    pendingComponent: RequestLoader,
    loader({ context, params, cause }) {
        if (cause === 'enter') {
            getHostingInformation(params.serviceId)(context.store.dispatch);
        }
    },
    beforeLoad(opts) {
        routerMiddleware.business({ path: '/my-services/hosting' }, opts);
        routerMiddleware.authentication('user', opts);
        routerMiddleware.business(this, opts);
    }
});

/**********************************************************************************************************
 *   ROUTE COMPONENT START
 **********************************************************************************************************/
/**
 * **Note**: This component is shared between the main hosting route and the subaccount route. Components rendered within
 * this will always have `$serviceId` available and can reference the `HostingManageRoute` route directly for hook calls,
 * but may not always have `$subaccount` available and should reference subaccount using `useParams({ strict: false })`.
 */
const HostingRouteComponent = () => {
    /***** HOOKS *****/
    const store = useStore(HostingModuleStore);

    HostingModuleStore.useConditions();

    /***** RENDER HELPERS *****/
    const [links] = SideBarPage.useGetSidebarLinks([
        {
            list_title: 'Account',
            list_icon: 'accounts',
            list_items: store.account
        },
        {
            list_title: 'Configuration',
            list_icon: 'other-control-panel',
            list_items: store.configuration
        },
        {
            list_title: 'Security',
            list_icon: 'secure-hosting',
            list_items: store.security
        },
        {
            list_title: 'Admin',
            list_icon: 'settings',
            list_items: store.admin
        }
    ]);

    /***** RENDER *****/
    return (
        <HostingRouteLoader>
            <HostingTitle />
            <SideBarPage sidebarList={links}>
                <Transition>
                    <ConditionalVentraSynBanner />
                    <Outlet />
                </Transition>
            </SideBarPage>
        </HostingRouteLoader>
    );
};

/**********************************************************************************************************
 *   ROUTE UPDATES
 **********************************************************************************************************/
HostingManageRoute.update({
    component: HostingRouteComponent
});

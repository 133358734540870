/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import { PhosphorIcons } from 'components/Icons/Phosphor';
import { Flex } from 'components/Utils/Flex';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type NXBoxDropdownCloseButtonProps = {
    onClose: () => void;
};

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * Button that appears inside the dropdown content of the NXBox, used to close the dropdown
 */
export const NXBoxDropdownCloseButton: React.FC<NXBoxDropdownCloseButtonProps> = ({ onClose }) => {
    /***** RENDER *****/
    return (
        <Flex className="NXBoxDropdownCloseButton" justify="end">
            <button onClick={onClose} type="button">
                <PhosphorIcons.X.Bold size={16} />
            </button>
        </Flex>
    );
};

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import classNames from 'classnames';
import React, { useLayoutEffect, useMemo, useState } from 'react';
import store from 'store/store';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import { PhosphorIcons } from 'components/Icons/Phosphor';
import { Revealer } from 'components/Revealer';
import Tooltip from 'components/Tooltip';
import { Flex } from 'components/Utils/Flex';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import { _AccordionTitle as AccordionTitle } from 'components/Accordion/_AccordionTitle';
import { AccordionContext } from 'components/Accordion/consts';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { useAccordionContext } from 'components/Accordion/methods';
import { copyToClipboard } from 'utilities/methods/commonActions';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import './_Accordion.scss';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 *
 * @type {React.FC<import('components/Accordion/types').NAccordion.Props>}
 */
const _Accordion = (props) => {
    const {
        title,
        content,
        info,
        servicesContainer,
        copyContent,
        controlled,
        colour = 'info',
        border,
        defaultActive = false,
        preTitleContent = null,
        preChevronContent = null,
        afterChevronContent = null,
        className,
        propagateCloseToChildNestedAccordions
    } = props;

    /***** STATE *****/
    const [active, setActive] = useState(defaultActive);

    /***** HOOKS *****/
    const { propagateClose } = useAccordionContext();

    /***** FUNCTIONS *****/
    const accordionToggle = () => {
        setActive((currentState) => !currentState);
    };

    /***** EFFECTS *****/
    useLayoutEffect(() => {
        if (propagateClose) {
            setActive(false);
        }
    }, [propagateClose]);

    /***** RENDER HELPERS *****/
    const isActive = controlled ? controlled.active : active;
    const accordionClassNames = classNames(
        'accordion__container',
        `accordion__container--colour-${colour}`,
        {
            accordion__open: isActive,
            accordion__border: border
        },
        className
    );

    const contextValue = useMemo(
        () => ({ propagateClose: propagateCloseToChildNestedAccordions ? !active : false }),
        [propagateCloseToChildNestedAccordions, active]
    );

    /***** RENDER *****/
    return (
        <AccordionContext.Provider value={contextValue}>
            <div className={accordionClassNames}>
                <button
                    type="button"
                    className={`accordion__header${servicesContainer ? ` accordion__header--servicesContainer` : ''}`}
                    onClick={() => {
                        if (controlled) {
                            controlled.toggle();
                        } else {
                            accordionToggle();
                        }
                    }}
                >
                    <Flex items="center">
                        {preTitleContent}
                        {info ? <Tooltip className="accordion__icon" info={info} /> : ''}
                        {typeof title === 'string' ? <AccordionTitle>{title}</AccordionTitle> : title}
                    </Flex>
                    {copyContent ? (
                        <button
                            className="accordion__copy"
                            onClick={(e) => {
                                e.stopPropagation();
                                store.dispatch(copyToClipboard(copyContent));
                            }}
                        >
                            Copy
                        </button>
                    ) : (
                        ''
                    )}
                    <Flex className="accordion__chevron" items="center">
                        {preChevronContent}
                        <PhosphorIcons.Chevron state={isActive ? 'up' : 'down'} bold size={12} secondary={!isActive} white={isActive} />
                        {afterChevronContent}
                    </Flex>
                </button>

                <div className="accordion__content">
                    <Revealer isOpen={isActive}>{content}</Revealer>
                </div>
            </div>
        </AccordionContext.Provider>
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

export { _Accordion };

/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React from 'react';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import { Flex } from 'components/Utils/Flex';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import './_PresetCustomiser.scss';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * @type {React.FC<{
 *      children: React.ReactNode
 * }>}
 */
const PresetCustomiser = ({ children }) => (
    <>
        <div
            style={{
                flexBasis: '100%',
                height: 0
            }}
        />
        <Flex className="PresetCustomiser" justify="center" items="center" alignChildren="stretch" gap={0}>
            {children}
        </Flex>
    </>
);
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

export { PresetCustomiser };

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import BaseDropdown from '../_BaseDropdown';

/**********************************************************************************************************
 *   TYPE IMPORTS
 **********************************************************************************************************/
import type { BaseDropdownProps } from 'components/Dropdowns/types';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import './_SolidDropdown.scss';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
// IMPROVE: Base styling for the list does not match OutlineDropdown
/**
 * Solid Dropdown Variant, use this for most dropdowns
 */
export const SolidDropdown: React.FC<BaseDropdownProps> = (props) => {
    /***** RENDER *****/
    return <BaseDropdown variant="SolidDropdown" {...props} />;
};

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

export default SolidDropdown;

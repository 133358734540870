/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { useAppViewport } from 'utilities/hooks/useAppViewport/useAppViewport';

/**********************************************************************************************************
 *   HOOK START
 **********************************************************************************************************/
export const useNXBoxDefaultPadding = () => {
    /***** HOOKS *****/
    const isMobile = useAppViewport(['xs', 'sm']);

    /***** HOOK RESULTS *****/
    return isMobile ? 4 : 6;
};
/**********************************************************************************************************
 *   HOOK END
 **********************************************************************************************************/

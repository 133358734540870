/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React from 'react';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { getTldValue } from 'components/Promo/FreeDomain/ClaimForm/methods';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import StoreText from 'assets/images/promo/dotStoreBanner/storeText.svg';
import { RewardCard } from '../new';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type DotStoreReferralCardComponent = React.FC<{
    availabilityData: unknown;
}>;

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const DotStoreReferralCard: DotStoreReferralCardComponent = ({ availabilityData }) => {
    /***** RENDER HELPERS *****/
    const tldValue = getTldValue('.store', availabilityData);
    const description = `You're eligible for a FREE .store domain name registration valued at $${tldValue}.`;

    /***** RENDER *****/
    return (
        <RewardCard>
            <RewardCard.Image tag="FREE">
                <RewardCard.Image.Default src={StoreText} alt=".store" />
            </RewardCard.Image>
            <RewardCard.Body title="Claim your FREE .store domain name!*" description={description}>
                <RewardCard.Button searchParam="free-domain=store">Claim Now</RewardCard.Button>
            </RewardCard.Body>
        </RewardCard>
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

import axios from 'axios';

export const API = {
    /**********************************************************************************************************
     *   SSL API
     **********************************************************************************************************/

    ssl: {
        GET: {
            information: (id) => {
                return axios.get('/api/ssl/' + id);
            },

            beacon: (id) => {
                return axios.get('/api/ssl/' + id + '/validate');
            },

            downloadZip: (id) => {
                return axios.get('/api/ssl/' + id + '/download', {
                    responseType: 'arraybuffer'
                });
            },

            searchHosting: (keyword) => {
                return axios.get('/api/ssl/search-web-hosting/' + keyword);
            }
        },

        POST: {
            validateTxt: (id) => {
                return axios.post('/api/ssl/' + id + '/validate/txt');
            },

            validateFile: (id) => {
                return axios.post('/api/ssl/' + id + '/validate/file');
            },

            validateCname: (id) => {
                return axios.post('/api/ssl/' + id + '/validate/cname');
            },

            validateEmail: (id) => {
                return axios.post('/api/ssl/' + id + '/validate/email');
            },

            install: (id, attributes) => {
                return axios.post('/api/ssl/' + id + '/install/service', {
                    type: 'ssl-install',
                    id: id,
                    attributes
                });
            }
        }
    },

    CSR: {
        GET: {
            information: (id) => {
                return axios.get('/api/ssl/' + id + '/configure/csr/info');
            }
        },

        POST: {
            advance: (id, attributes) => {
                return axios.post('/api/ssl/' + id + '/configure/csr/manual', {
                    type: 'ssl',
                    id: id,
                    attributes
                });
            },

            simple: (id, attributes) => {
                return axios.post('/api/ssl/' + id + '/configure/csr/recommended', {
                    type: 'ssl',
                    id: id,
                    attributes
                });
            },

            clear: (id) => {
                return axios.post('/api/ssl/' + id + '/configure/csr/clear');
            },

            submit: (id) => {
                return axios.post('/api/ssl/' + id + '/configure/csr/submit');
            }
        }
    }
};

/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import classNames from 'classnames';
import type { MouseEventHandler } from 'react';
import React from 'react';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import { Flex } from 'components/Utils/Flex';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import { ToolBarToolTip } from 'components/Form/RichTextEditor/plugins/Toolbar/ToolBarToolTip/ToolBarToolTip';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import './_ToolbarButton.scss';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
interface ToolbarButtonProps {
    children: React.ReactNode;
    disabled?: boolean;
    onClick: MouseEventHandler<HTMLButtonElement>;
    ariaLabel: string;
    isActive?: boolean;
}

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const ToolbarButton: React.FC<ToolbarButtonProps> = ({ children, disabled, onClick, ariaLabel, isActive }) => (
    <ToolBarToolTip ariaLabel={ariaLabel}>
        <Flex justify="center" items="center" inject>
            <button
                disabled={disabled}
                onClick={onClick}
                className={classNames('ToolbarButton', { 'ToolbarButton--isActive': isActive, 'ToolbarButton--disabled': disabled })}
                aria-label={ariaLabel}
            >
                {children}
            </button>
        </Flex>
    </ToolBarToolTip>
);
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

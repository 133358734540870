import React from 'react';

import './_ContentEditorModuleContent.scss';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * @type {React.FC<{
 *      children: React.ReactNode;
 * }>}
 */
const ContentEditorModuleContent = ({ children }) => {
    return <div className="ContentEditorModuleContent">{children}</div>;
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

export default ContentEditorModuleContent;

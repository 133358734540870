/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { has, keys } from 'lodash';
import React, { useCallback, useContext, useMemo, useState } from 'react';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import { Accordion } from 'components/Accordion';
import { OptionsIcon } from 'components/OptionsIcon';
import Padding from 'components/Utils/Padding';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import ContentEditorModuleContent from 'containers/katana/containers/ContentEditorLightbox/contentEditorModuleContent';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { useGetDynamicFormFieldSyncErrors } from 'containers/katana/components/dynamicFormFieldRenderer/hooks/useGetDynamicFormFieldSyncErrors';
import useRecursivePropertiesRenderer from 'containers/katana/components/dynamicFormFieldRenderer/useRecursivePropertiesRenderer';
import { useGetCaveat } from 'containers/katana/formFields/methods/useGetCaveat';
import { useModifiedFieldArrayProperties } from 'containers/katana/formFields/repeated/useModifiedFieldArrayProperties';
import { useStoreEffect } from 'utilities/hooks/tanstack-store/useStoreEffect';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import { ContentEditorRenderModulesContext } from 'containers/katana/containers/ContentEditorLightbox/contentEditorRenderModules/consts';
import { CaveatTypes } from 'containers/katana/formFields/caveatTypes';
import { SORTABLE_ID_KEY } from 'containers/katana/formFields/repeated/SortableRepeatedWrapper/consts';
import { sectionDefinitionUnsplashDownloadLinks } from 'containers/katana/containers/ContentEditorLightbox/formHandlers/consts';

/**********************************************************************************************************
 *   TYPE IMPORTS
 **********************************************************************************************************/
import type { ContentEditorRenderModulesNamespace } from 'containers/katana/containers/ContentEditorLightbox/contentEditorRenderModules/types';
import type { RepeatedFieldArrayRenderer } from 'containers/katana/formFields/repeated/types';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const _AccordionFieldArrayChildReduxForm: React.FC<RepeatedFieldArrayRenderer.Accordion.ReduxForm.Props> = (props) => {
    const { member, index, fields, properties, property, sortable } = props;
    /***** HOOKS *****/
    const { submitButtonClickEvent } = useContext(ContentEditorRenderModulesContext);
    const PropertiesRenderer = useRecursivePropertiesRenderer();
    const { syncErrors } = useGetDynamicFormFieldSyncErrors();

    const fieldValues = fields.get(index);

    /***** RENDER HELPERS *****/
    // have to modify the properties to include the member key
    const modifiedProperties = useModifiedFieldArrayProperties(member, properties, true);
    const singularName = useGetCaveat(CaveatTypes.SINGULAR_NAME, property.caveats, property.name);
    const accordionTitleFormValueTarget = useGetCaveat(CaveatTypes.ACCORDION_TITLE_VALUE_TARGET, property.caveats);

    /***** FUNCTIONS *****/
    function removeService() {
        fields.remove(index);

        const downloadLinks = Object.entries(sectionDefinitionUnsplashDownloadLinks.state);

        if (downloadLinks.length > 0) {
            const memberWithoutIndex = member.split('[')[0];
            const updatedLinkOrder = downloadLinks.map(([key, value]) => {
                const indexDigit = key.match(/[\d]/);
                if (indexDigit) {
                    const indexDigitMatch = Number(indexDigit[0]);
                    if (indexDigitMatch > index) {
                        const updatedKey = key.replace(`${memberWithoutIndex}[${indexDigitMatch}]`, `${memberWithoutIndex}[${indexDigitMatch - 1}]`);
                        return [updatedKey, value] as const;
                    } else if (indexDigitMatch < index) {
                        return [key, value] as const;
                    } else {
                        return [];
                    }
                } else {
                    return [];
                }
            });
            const updatedLinkOrderWithoutEmptyArray = updatedLinkOrder.filter((element) => element.length !== 0);
            sectionDefinitionUnsplashDownloadLinks.setState(() => Object.fromEntries(updatedLinkOrderWithoutEmptyArray));
        }
    }

    const accordionTitleFormValue = useMemo(() => fieldValues[accordionTitleFormValueTarget], [fieldValues, accordionTitleFormValueTarget]);
    const accordionIsDefaultActive = useMemo(() => !keys(fieldValues).filter((key) => key !== SORTABLE_ID_KEY).length, [fieldValues]);
    const [isAccordionOpen, setIsAccordionOpen] = useState(accordionIsDefaultActive);

    const handleSubmitButtonEvent = useCallback(
        (e: ContentEditorRenderModulesNamespace.SubmitButtonClickEventValue) => {
            const errorInPath = has(syncErrors, member);
            if (errorInPath) {
                setIsAccordionOpen(true);
            }
        },
        [syncErrors, member]
    );

    /***** EFFECTS *****/
    useStoreEffect(submitButtonClickEvent, handleSubmitButtonEvent, false);

    const AccordionComponent = useMemo(() => (sortable ? Accordion.WithDragHandle : Accordion), [sortable]);

    const memoedContent = useMemo(
        () => (
            <Padding xy={4} paddingOnly>
                {/* <KatanaRepeatedFormFieldRemoveButton removeService={removeService} singularName={singularName} /> */}
                <ContentEditorModuleContent>
                    <PropertiesRenderer properties={modifiedProperties} />
                </ContentEditorModuleContent>
            </Padding>
        ),
        [modifiedProperties]
    );

    /***** RENDER *****/
    return (
        <AccordionComponent
            controlled={{
                active: isAccordionOpen,
                toggle: () => setIsAccordionOpen((currentState) => !currentState)
            }}
            id={fieldValues?.[SORTABLE_ID_KEY]}
            border
            title={accordionTitleFormValue ? accordionTitleFormValue : singularName}
            colour="primary"
            afterChevronContent={<OptionsIcon onConfirm={removeService} />}
            content={memoedContent}
        />
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

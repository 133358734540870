import _ from 'lodash';

interface ValidateQueryParamDomainOptions {
    domainValidator: (domain: string) => boolean;
    areQueryParamsOptional?: boolean;
}

/**
 * This function is used to validate a domain that contains query parameters and is a valid domain
 * Pass the validation function you want to apply for the domain without query parameters.
 */
export function validateQueryParamDomain({ domainValidator, areQueryParamsOptional = false }: ValidateQueryParamDomainOptions) {
    function validationMethod(domain: string) {
        if (!_.isString(domain)) return false;
        const containsQueryParams = domain.includes('?');
        if (containsQueryParams) {
            const splitLink = domain.split('?');
            if (splitLink.length <= 2) {
                const potentialDomain = splitLink[0];
                if (domainValidator(potentialDomain)) return true;
            }
        }

        if (areQueryParamsOptional) {
            return domainValidator(domain);
        }

        return false;
    }

    return validationMethod;
}

/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import _ from 'lodash';
import { createContext } from 'react';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
import { Store } from '@tanstack/react-store';
import type { RichTextEditorNamespace } from 'components/Form/RichTextEditor/types';

export const RichTextEditorContext = createContext<Store<RichTextEditorNamespace.ContextState>>(
    new Store<RichTextEditorNamespace.ContextState>({
        isLinkEditMode: false,
        rootElement: null
    })
);

export function newRichTextEditorTanstackStore() {
    return new Store<RichTextEditorNamespace.ContextState>({
        isLinkEditMode: false,
        rootElement: null
    });
}

export const richTextFeatureEnum = {
    // UNDO: 'undo',
    // REDO: 'redo',
    NO_NEW_LINE: 'no_new_line',
    LINK: 'link',
    BOLD: 'bold',
    ITALIC: 'italic',

    NUMBERED_LIST: 'ol',
    UNORDERED_LIST: 'ul',

    MARKDOWN: 'markdown'

    // CLEAR: 'clear'
} as const;

export const richTextFeatures = _.values(richTextFeatureEnum);

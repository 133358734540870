import { useIsMutating, useMutation } from '@tanstack/react-query';
import { newSectionOrganiserData } from 'containers/katana/modules/presetContent/components/sectionOrganiser/newSectionOrganiserData';
import { katanaQueryKeys } from 'containers/katana/queries/katanaQueryKeys';
import { getService } from 'containers/katana/queries/serviceID/getService';
import { sections } from 'containers/katana/queries/serviceID/sections';
import type { KatanaNamespace } from 'containers/katana/types';
import _ from 'lodash';
import { KATANA_API } from 'utilities/api/katana';
import type { KatanaAPI } from 'utilities/api/katana/types';
import { handleDefaultErrorNotification, handleDefaultSuccessNotification } from 'utilities/methods/commonActions';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type Params = KatanaAPI.Katana.Site.ServiceID.Pages.PageID.Sections.SectionID.Duplicate.POST.Params;
type MainParams = Omit<Params, 'serviceID'>;

function createMutationKey() {
    return katanaQueryKeys.katana.duplicatePageSection();
}

function _useIsMutating() {
    return useIsMutating({
        mutationKey: createMutationKey()
    });
}

/**********************************************************************************************************
 *   HOOK START
 **********************************************************************************************************/
function _useMutation(serviceID: KatanaNamespace.ServiceID) {
    async function handleMutation(params: MainParams) {
        return KATANA_API.katana.site.service_id.pages.page_id.sections.section_id.duplicate.POST({ serviceID, ...params });
    }

    return useMutation({
        mutationFn: handleMutation,
        mutationKey: createMutationKey(),
        onSuccess: (data, { sectionID }) => {
            if (data.status === 200) {
                const sectionsQueryData = sections.getQueryData(serviceID);
                if (sectionsQueryData) {
                    const clonedQueryDataSection = _.cloneDeep(sectionsQueryData.data);

                    const newOrder = data.data.order;
                    // Optimistically update the sort order of the sections so that the new section is in the correct place
                    clonedQueryDataSection.forEach((section) => {
                        if (section.order >= newOrder) {
                            section.order += 1;
                        }
                    });

                    clonedQueryDataSection.push(data.data);
                    sections.optimistic.set(serviceID, 'data', clonedQueryDataSection);
                }
                newSectionOrganiserData.addNewID(data.data.id);
            }
            handleDefaultSuccessNotification(data);
        },
        onError: handleDefaultErrorNotification,
        onSettled: () => {
            getService.invalidateQueries(serviceID);
        }
    });
}

export const duplicatePageSection = Object.freeze({
    useMutation: _useMutation,
    createMutationKey,
    useIsMutating: _useIsMutating
});

import { useState } from 'react';

/**********************************************************************************************************
 *   HOOK START
 **********************************************************************************************************/
/**
 * This hook makes sure the value is always a floored number.
 * @param {number} value
 */
function useFlooredNumber(value) {
    /***** STATE *****/
    const [flooredNumber, _setFlooredNumber] = useState(~~value);

    /***** FUNCTIONS *****/
    /**
     * @param {number} value
     */
    function setFlooredNumber(value) {
        _setFlooredNumber(~~value);
    }

    /***** HOOK RESULTS *****/
    return /** @type {const} */ ([flooredNumber, setFlooredNumber]);
}

/**********************************************************************************************************
 *   HOOK END
 **********************************************************************************************************/

export { useFlooredNumber };

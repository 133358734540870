/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { useEffect, useState } from 'react';

/**********************************************************************************************************
 *   HOOK START
 **********************************************************************************************************/
/**
 * Behaves exactly the same as "useEffect", except it doesn't run the callback on mount
 * @param {function} callback - The callback function that you would normally pass to "useEffect"
 * @param {any[]} deps - The dependency array that you would normally pass to "useEffect"
 */
function useEffectAfterMount(callback, deps) {
    /***** STATE *****/
    const [hasMounted, setHasMounted] = useState(false);

    /***** EFFECTS *****/
    // Use the first render to register that mounting has happened
    useEffect(() => {
        setHasMounted(true);
    }, []);

    // Only run the effect if the current render is not the first render
    useEffect(() => {
        if (hasMounted) {
            return callback();
        }
    }, deps);
}
/**********************************************************************************************************
 *   HOOK END
 **********************************************************************************************************/

export default useEffectAfterMount;

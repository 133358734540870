import { useKatanaNextPage } from 'containers/katana/hooks/useKatanaNextPage';
import { useKatanaParams } from 'containers/katana/hooks/useSetupEditorRouteParams';
import { katanaQuery } from 'containers/katana/queries/tanstackTree';
import { useUpdateKatanaSiteInfoMutation } from 'containers/katana/queries/useUpdateKatanaSiteInfoMutation';
import type { KatanaNamespace } from 'containers/katana/types';

/**********************************************************************************************************
 *   HOOK START
 **********************************************************************************************************/
export function useFinaliseSubmit(subpageRouteData: KatanaNamespace.SubPageRouteData) {
    /***** HOOKS *****/
    const { katanaServiceId } = useKatanaParams();
    const { performRouting } = useKatanaNextPage(subpageRouteData);

    /***** QUERIES *****/
    const { mutateAsync: mutateUpdateKatanaSiteInfo } = useUpdateKatanaSiteInfoMutation(katanaServiceId, {}, { context: 'favicon' });

    return async function handleSubmit(values: { [x: string]: any }) {
        const mutation = mutateUpdateKatanaSiteInfo(values);

        const { restore } = katanaQuery.serviceID.getService.optimistic.concat(katanaServiceId, 'data.attributes.launch_checklist', [
            'finishing-touches-completed'
        ]);

        return mutation
            .then(() => {
                performRouting();
            })
            .catch((e) => {
                restore();
                return e;
            })
            .finally(() => {
                katanaQuery.serviceID.getService.invalidateQueries(katanaServiceId);
            });
    };
}

/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { useCallback, useRef } from 'react';
import type { DropzoneRef } from 'react-dropzone';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import { Input } from 'components/Form/Input';
import { NXDropZone } from 'components/Form/NXDropZone';
import { Radio } from 'components/Form/Radio';
import NXBox from 'components/NXBox';
import { Revealer } from 'components/Revealer';
import Tooltip from 'components/Tooltip';
import { Flex } from 'components/Utils/Flex';
import Padding from 'components/Utils/Padding';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import ContentEditorModuleContent from 'containers/katana/containers/ContentEditorLightbox/contentEditorModuleContent';
import LimitedWidthContentModule from 'containers/katana/containers/ContentEditorLightbox/contentEditorModuleContent/limitedWidthContentModule';
import { _PickLogoStyle as PickLogoStyle } from 'containers/katana/containers/contentEditorModules/basicDetails/pickLogoStyle';

/**********************************************************************************************************
 *   QUERIES
 **********************************************************************************************************/
import { useUpdateKatanaSiteBusinessInfoMutation } from 'containers/katana/queries/useUpdateKatanaSiteBusinessInfoMutation';
import { useUpdateKatanaSiteInfoMutation } from 'containers/katana/queries/useUpdateKatanaSiteInfoMutation';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { useKatanaParams } from 'containers/katana/hooks/useSetupEditorRouteParams';

const { NAME, TAG_LINE, LOGO } = useUpdateKatanaSiteBusinessInfoMutation.FORM_FIELD_KEYS;
const { STYLE } = useUpdateKatanaSiteInfoMutation.FORM_FIELD_KEYS;

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
const _BasicDetailsContentModule = () => {
    /***** HOOKS *****/
    const { katanaServiceId } = useKatanaParams();
    const zoneRef = useRef<DropzoneRef>();

    /***** FUNCTIONS *****/
    const handleOnEqualIdentifierLogoClick = useCallback(() => {
        // Open Dropzone
        if (zoneRef.current) {
            zoneRef.current?.open?.();
        }
    }, []);

    const handleOnZoneRef = useCallback((ref: DropzoneRef) => {
        zoneRef.current = ref;
    }, []);

    /***** RENDER *****/
    return (
        <NXBox.TopAccordion isOpenDefault={true} title="Your Website Details">
            <NXBox.DefaultPadding>
                <ContentEditorModuleContent>
                    <Input.HookForm
                        name={NAME}
                        label={
                            <Flex items="center" gap={1}>
                                Your Website Name?
                                <Tooltip info="Don't worry, you'll be able to change this later if you need to!" />
                            </Flex>
                        }
                        placeholder={`Eg. John Smith's Cabinet Making`}
                    />

                    <Radio.HookForm label="Add Your Logo" name="logo_choice" fullWidth>
                        <Flex direction="column" gap={1}>
                            <LimitedWidthContentModule>
                                <Radio.CheckBoxPanel
                                    type="circle"
                                    identifier={LOGO}
                                    fullWidth
                                    onEqualIdentifierClick={handleOnEqualIdentifierLogoClick}
                                >
                                    Upload my website logo
                                </Radio.CheckBoxPanel>
                            </LimitedWidthContentModule>

                            <Radio.IdentifierMatch identifier={LOGO}>
                                {(isMatch) => (
                                    <Revealer isOpen={isMatch}>
                                        <Padding paddingOnly top={2} bottom={4}>
                                            <NXDropZone.HookForm.WithKatanaImageUploadAndControl
                                                name={LOGO}
                                                onZoneRef={handleOnZoneRef}
                                                serviceID={katanaServiceId}
                                                singleFile
                                            />
                                        </Padding>
                                    </Revealer>
                                )}
                            </Radio.IdentifierMatch>

                            <LimitedWidthContentModule>
                                <Radio.CheckBoxPanel type="circle" identifier={STYLE} fullWidth>
                                    {`I don't have a website logo to upload yet`}
                                </Radio.CheckBoxPanel>
                            </LimitedWidthContentModule>
                            <Radio.IdentifierMatch identifier={STYLE}>
                                {(isMatch) => (
                                    <Revealer isOpen={isMatch}>
                                        <Padding paddingOnly top={2} bottom={4}>
                                            <PickLogoStyle />
                                        </Padding>
                                    </Revealer>
                                )}
                            </Radio.IdentifierMatch>
                        </Flex>
                    </Radio.HookForm>
                </ContentEditorModuleContent>
            </NXBox.DefaultPadding>
        </NXBox.TopAccordion>
    );
};

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
export const BasicDetailsContentModule = Object.assign(_BasicDetailsContentModule, {
    formFields: { NAME, TAG_LINE, LOGO, STYLE, logo_choice: 'logo_choice' }
});

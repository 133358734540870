/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import classNames from 'classnames';
import React from 'react';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { createAppliedStylingClasses } from 'components/Utils/methods';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import { backgroundColourAppliedStylingKeys } from 'components/Utils/BackgroundColour/consts';
import './_BackgroundColour.scss';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
import type { NBackgroundColour } from 'components/Utils/BackgroundColour/types';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
const BackgroundColour: React.FC<NBackgroundColour.BackgroundColourProps> = (props) => {
    const { children, className, opacity } = props;

    /***** RENDER HELPERS *****/
    const appliedStylingClasses = createAppliedStylingClasses({
        props,
        keyBoundary: backgroundColourAppliedStylingKeys,
        componentName: 'BackgroundColour',
        delimiter: '--'
    });

    const backgroundColourClasses = classNames('BackgroundColour', appliedStylingClasses, className);

    const style = { '--BackgroundColour-opacity': opacity ?? 1 } as React.CSSProperties;

    return (
        <div className={backgroundColourClasses} style={style}>
            <div className="BackgroundColour__background" />
            <div className="BackgroundColour__content">{children}</div>
        </div>
    );
};

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

export default BackgroundColour;

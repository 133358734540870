/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import classNames from 'classnames';
import React from 'react';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import Text from 'components/Utils/Text';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { useAppViewport } from 'utilities/hooks/useAppViewport/useAppViewport';
import { truncateText } from 'utilities/methods/commonActions';
import { getVPSInformationFromData } from './helpers';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
/**
 * @typedef {import('../../types').TVPSOverview.TVPSServiceName} TVPSServiceName
 */

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * @private - For use in the _TableBody component.
 * @type {TVPSServiceName}
 */
export const OwnRenderServiceName = ({ data }) => {
    /***** HOOKS *****/
    const isMobile = useAppViewport(['xs', 'sm']);

    /***** RENDER HELPERS *****/
    const { name, domain } = getVPSInformationFromData(data);

    const primaryText = domain ? truncateText(domain, 35, '...') : 'Not Available';
    const secondaryText = name || 'Not Available';

    const classes = /** @type {const} */ ({
        title: classNames('VPSTableBody__title', {
            'VPSTableBody__title--mobile': isMobile
        }),
        hostname: 'VPSTableBody__hostnameTitle--mobile',
        type: classNames('VPSTableBody__typeTitle', {
            'VPSTableBody__typeTitle--mobile': isMobile
        })
    });

    /***** RENDER *****/
    return (
        <div className={classes.title}>
            {isMobile && (
                <Text className={classes.hostname} lead--1 secondary bold size--xss align--left uppercase>
                    Hostname
                </Text>
            )}
            <Text black bold size--s lead--1 align--left={isMobile}>
                {primaryText}
            </Text>
            <Text className={classes.type} secondary bold size--xs lead--s uppercase align--left={isMobile}>
                {secondaryText}
            </Text>
        </div>
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { createRoute, Outlet, redirect } from '@tanstack/react-router';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import { BillingSubnav } from './components/subnav';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import Transition from 'components/Transition';

/**********************************************************************************************************
 *   ROUTE IMPORTS
 **********************************************************************************************************/
import { AppRoute } from 'App';
import { routerMiddleware } from 'router/utils/middleware';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import type { GetOptionTypeFromRouteId } from 'router/types';
import './_billing.scss';

/**********************************************************************************************************
 *   ROUTE START
 **********************************************************************************************************/
export const BillingRoute = createRoute({
    getParentRoute: () => AppRoute,
    path: 'billing',
    beforeLoad(opts) {
        routerMiddleware.business(this, opts);
        routerMiddleware.authentication('user', opts);
        handleDirectRedirect(opts);
    },
    component: BillingRouteComponent
});

/**********************************************************************************************************
 *   ROUTE MIDDLEWARE
 **********************************************************************************************************/
function handleDirectRedirect(opts: GetOptionTypeFromRouteId<'/billing', 'beforeLoad'>) {
    if (['/billing/', '/billing'].includes(opts.location.pathname)) {
        throw redirect({ to: '/billing/payment-methods' });
    }
}

/**********************************************************************************************************
 *   ROUTE COMPONENT START
 **********************************************************************************************************/
function BillingRouteComponent() {
    /***** RENDER *****/
    return (
        <div className="billing">
            <BillingSubnav />
            <Transition className="billing__section">
                <Outlet />
            </Transition>
        </div>
    );
}

import type { TruthyProp } from 'utilities/consts';

export type AppliedStylingProps = {
    /**
     * Whether to force remove padding
     */
    'noPadding': TruthyProp;

    /**
     * Flex Prop align request loader to the left
     */
    'align-start': TruthyProp;

    /**
     * Auto heights the main request loader
     */
    'heightAuto': TruthyProp;
};

export const appliedStylingPropTypeKeys: Array<keyof AppliedStylingProps> = ['noPadding', 'align-start', 'heightAuto'];

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import NXBox from 'components/NXBox';
import { DialogNotification } from 'components/Notifications/DialogNotification';
import Padding from 'components/Utils/Padding';
import Text from 'components/Utils/Text';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { useGetCaveat } from 'containers/katana/formFields/methods/useGetCaveat';
import { useKatanaParams } from 'containers/katana/hooks/useSetupEditorRouteParams';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import { CaveatTypes } from 'containers/katana/formFields/caveatTypes';
import { katanaQuery } from 'containers/katana/queries/tanstackTree';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/

export const _PropertyRenderNXBoxDescription = (props) => {
    const { property } = props;
    const { caveats } = property;

    /***** HOOKS *****/
    const { katanaServiceId } = useKatanaParams();

    /***** QUERIES *****/
    const { data: get_service_data } = katanaQuery.serviceID.getService.useQuery(katanaServiceId);

    /***** RENDER HELPERS *****/
    const isContactUsRequestedDetails = property?.key.includes('properties.requested_details');
    const hasBusinessEmail = !!get_service_data?.business.email;
    const boxDescription = useGetCaveat(CaveatTypes.BOX_DESCRIPTION, caveats);

    /***** RENDER *****/
    if (!hasBusinessEmail && isContactUsRequestedDetails) {
        return (
            <>
                <NXBox.Description description={boxDescription} />
                <Padding y={2}>
                    <DialogNotification type="warning" noMargin>
                        <Text bold black size--xss lead--s>
                            This feature requires an email to work. To show the contact form on your site, please add your business email on the
                            contact information page.
                        </Text>
                    </DialogNotification>
                </Padding>
            </>
        );
    } else {
        return <NXBox.Description description={boxDescription} />;
    }
};

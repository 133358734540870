/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React from 'react';

import './_styles.scss';
/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * The Label component provides a standardized box that should be used to provide a Label to the user. This essentially
 * Just provides some styling for a label and accepts any children
 */
function Label(props) {
    const { children } = props;
    return <div className="Label">{children}</div>;
}
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

export default Label;

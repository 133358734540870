/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { batchActions } from 'redux-batched-actions';
import { Field, change, getFormValues, reduxForm } from 'redux-form';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import { ReduxFormButton } from 'components/Form/Button/reduxForm';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import OverlayLightbox from 'components/Lightboxes/OverlayLightbox';
import { RenderSelectField, renderCheckboxField, requiredFieldValidation } from 'utilities/methods/form';

const formName = 'exportForm';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
class ExportForm extends Component {
    constructor(props) {
        super(props);
        this.removeSelectAll = this.removeSelectAll.bind(this);
        this.toggleSelectAll = this.toggleSelectAll.bind(this);
        this.triggerSelect = this.triggerSelect.bind(this);
        this.someAuDomains = this.someAuDomains.bind(this);
    }

    componentDidUpdate(prevProps, prevState) {
        const { formValues, dispatch, form } = this.props;
        const { toggleSelectAll } = this;

        if (
            formValues &&
            formValues.exportType === 'synergy' &&
            prevProps.formValues &&
            prevProps.formValues.exportType !== 'synergy' &&
            !formValues?.select_all
        ) {
            toggleSelectAll();
            dispatch(change(form, 'select_all', true));
        }
    }

    removeSelectAll() {
        const { form, formValues, dispatch } = this.props;

        if (formValues && formValues.exportType !== 'synergy') dispatch(change(form, 'select_all', false));
    }

    triggerSelect(value) {
        const { form, dispatch } = this.props;
        const { someAuDomains } = this;

        const actions = [
            change(form, 'expiry', value),
            change(form, 'epp', value),
            change(form, 'nameservers', value),
            change(form, 'registrant_contact', value),
            change(form, 'admin_contact', value),
            change(form, 'tech_contact', value),
            change(form, 'billing_contact', value)
        ];

        if (someAuDomains()) actions.push(change(form, 'eligibility', value));

        dispatch(batchActions(actions));
    }

    toggleSelectAll() {
        const { formValues } = this.props;
        const { triggerSelect } = this;

        triggerSelect(!formValues?.select_all);
    }

    someAuDomains() {
        const { domain_list_data } = this.props;

        return domain_list_data?.some((domain) => domain?.attributes?.domain?.endsWith('.au'));
    }

    /*   RENDER COMPONENT
     **********************************************************************************************************/
    render() {
        const { handleSubmit, closeLightbox, formValues } = this.props;
        const { toggleSelectAll, removeSelectAll, someAuDomains } = this;

        const getDisabledClassName = () => {
            return formValues && formValues.exportType === 'synergy' ? ' exportForm__field--disabled' : '';
        };

        const getDisabledProp = () => {
            return formValues && formValues.exportType === 'synergy';
        };

        return (
            <form className="exportForm" onSubmit={handleSubmit}>
                <div className="exportForm__wrapper">
                    <div className={`exportForm__field${getDisabledClassName()}`}>
                        <Field
                            name="select_all"
                            label="Select All"
                            component={renderCheckboxField}
                            type="checkbox"
                            className="exportForm__checkbox"
                            onClick={(e) => {
                                if (!getDisabledProp()) {
                                    toggleSelectAll();
                                }
                            }}
                            disabled={getDisabledProp()}
                        />
                    </div>
                    <div className={`exportForm__field${getDisabledClassName()}`}>
                        <Field
                            name="expiry"
                            label="Expiry Date"
                            component={renderCheckboxField}
                            type="checkbox"
                            className="exportForm__checkbox"
                            onClick={(e) => {
                                if (formValues && formValues.expiry && formValues?.select_all) {
                                    removeSelectAll();
                                }
                            }}
                            disabled={getDisabledProp()}
                        />
                    </div>
                    <div className={`exportForm__field${getDisabledClassName()}`}>
                        <Field
                            name="epp"
                            label="Domain Password / EPP"
                            component={renderCheckboxField}
                            type="checkbox"
                            className="exportForm__checkbox"
                            onClick={() => {
                                if (formValues && formValues.epp && formValues?.select_all) {
                                    removeSelectAll();
                                }
                            }}
                            disabled={getDisabledProp()}
                        />
                    </div>
                    <div className={`exportForm__field${getDisabledClassName()}`}>
                        <Field
                            name="nameservers"
                            label="Nameservers"
                            component={renderCheckboxField}
                            type="checkbox"
                            className="exportForm__checkbox"
                            onClick={(e) => {
                                if (formValues && formValues.nameservers && formValues?.select_all) {
                                    removeSelectAll();
                                }
                            }}
                            disabled={getDisabledProp()}
                        />
                    </div>
                    <div className={`exportForm__field${getDisabledClassName()}`}>
                        <Field
                            name="registrant_contact"
                            label="Registrant Contact Information"
                            component={renderCheckboxField}
                            type="checkbox"
                            className="exportForm__checkbox"
                            onClick={() => {
                                if (formValues && formValues.registrant_contact && formValues?.select_all) {
                                    removeSelectAll();
                                }
                            }}
                            disabled={getDisabledProp()}
                        />
                    </div>
                    <div className={`exportForm__field${getDisabledClassName()}`}>
                        <Field
                            name="admin_contact"
                            label="Admin Contact Information"
                            component={renderCheckboxField}
                            type="checkbox"
                            className="exportForm__checkbox"
                            onClick={(e) => {
                                if (formValues && formValues.admin_contact && formValues?.select_all) {
                                    removeSelectAll();
                                }
                            }}
                            disabled={getDisabledProp()}
                        />
                    </div>
                    <div className={`exportForm__field${getDisabledClassName()}`}>
                        <Field
                            name="tech_contact"
                            label="Technical Contact Information"
                            component={renderCheckboxField}
                            type="checkbox"
                            className="exportForm__checkbox"
                            onClick={(e) => {
                                if (formValues && formValues.tech_contact && formValues?.select_all) {
                                    removeSelectAll();
                                }
                            }}
                            disabled={getDisabledProp()}
                        />
                    </div>
                    <div className={`exportForm__field${getDisabledClassName()}`}>
                        <Field
                            name="billing_contact"
                            label="Billing Contact Information"
                            component={renderCheckboxField}
                            type="checkbox"
                            className="exportForm__checkbox"
                            onClick={(e) => {
                                if (formValues && formValues.billing_contact && formValues?.select_all) {
                                    removeSelectAll();
                                }
                            }}
                            disabled={getDisabledProp()}
                        />
                    </div>
                    {someAuDomains() && (
                        <div className={`exportForm__field${getDisabledClassName()}`}>
                            <Field
                                name="eligibility"
                                label="Eligibility Information"
                                component={renderCheckboxField}
                                type="checkbox"
                                className="exportForm__checkbox"
                                onClick={() => {
                                    if (formValues && formValues.eligibility && formValues?.select_all) {
                                        removeSelectAll();
                                    }
                                }}
                                disabled={getDisabledProp()}
                            />
                        </div>
                    )}
                </div>
                <div className="exportForm__dropdown">
                    <Field
                        label="Export To"
                        name="exportType"
                        component={RenderSelectField}
                        type="select"
                        validate={[requiredFieldValidation]}
                        className="form__dropdown"
                        options={[
                            {
                                label: 'Display results on screen',
                                value: 'dcsv'
                            },
                            {
                                label: 'Download to a CSV',
                                value: 'csv'
                            },
                            {
                                label: 'Download to a CSV (Synergy Wholesale)',
                                value: 'synergy'
                            }
                        ]}
                    />
                </div>

                <ReduxFormButton form={formName}>Export Domain Names</ReduxFormButton>

                <OverlayLightbox.BackLink text="NO, GO BACK" onClick={closeLightbox} />
            </form>
        );
    }
}

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
ExportForm = reduxForm({
    form: formName,
    enableReinitialize: true
})(ExportForm);

export default connect((state) => {
    const formValues = getFormValues(formName)(state);
    const initialValues = {
        expiry: false,
        epp: false,
        nameservers: false,
        registrant_contact: false,
        tech_contact: false,
        admin_contact: false,
        billing_contact: false,
        eligibility: false
    };

    return {
        form: formName,
        initialValues,
        formValues,
        domain_list_data: state.services.domain_list_data
    };
})(ExportForm);

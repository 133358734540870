/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import classNames from 'classnames';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import Anchor from 'components/Anchor';
import RequestLoader from 'components/Loaders/Request';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import './_PromoCodeApplyButton.scss';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type PromoCodeApplyButtonProp = {
    /**
     * Whether to show a loader in place of the "apply promo" button
     */
    isLoading: boolean;

    /**
     * Whether to grey out and disable the "apply promo" button
     */
    isDisabled: boolean;

    /**
     * Function to call on click of the "apply promo" button
     */
    applyButtonOnClick: () => void;
};

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const PromoCodeApplyButton: React.FC<PromoCodeApplyButtonProp> = ({ isLoading, isDisabled, applyButtonOnClick }) => {
    /***** RENDER *****/
    return (
        <div className="PromoCodeApplyButton">
            {isLoading ? (
                <RequestLoader />
            ) : (
                <Anchor
                    onClick={applyButtonOnClick}
                    className={classNames('PromoCodeApplyButton__anchor', {
                        'PromoCodeApplyButton__anchor--disabled': isDisabled
                    })}
                >
                    Apply Coupon
                </Anchor>
            )}
        </div>
    );
};

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

export default PromoCodeApplyButton;

/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { Field } from 'redux-form';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import { NXDropZoneWithKatanaImageUploadAndControl } from 'components/Form/NXDropZone/wrappers/reduxForm/WithKatanaImageUploadAndControl';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { createAcceptMimeTypes } from 'containers/katana/formFields/file/methods';
import { useGetCaveat } from 'containers/katana/formFields/methods/useGetCaveat';
import { useKatanaParams } from 'containers/katana/hooks/useSetupEditorRouteParams';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import { CaveatTypes } from 'containers/katana/formFields/caveatTypes';
import type { KatanaNamespace } from 'containers/katana/types';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/

const KatanaFileFormFieldNXDropZone: KatanaNamespace.PropertyComponent = ({ property }) => {
    const { key, name, type, caveats, validationFunctions } = property;

    /***** HOOKS *****/
    const { katanaServiceId } = useKatanaParams();

    /***** RENDER HELPERS *****/
    const mimeTypeAccept = useGetCaveat(CaveatTypes.IMAGE_MIME_TYPE, caveats, []);
    const dropzoneMimeTypeAccept = createAcceptMimeTypes(mimeTypeAccept);

    /***** RENDER *****/
    return (
        <Field
            name={key}
            label={name}
            component={NXDropZoneWithKatanaImageUploadAndControl}
            serviceID={katanaServiceId}
            type={type}
            accept={dropzoneMimeTypeAccept}
            singleFile={true}
            validate={validationFunctions}
        />
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

export { KatanaFileFormFieldNXDropZone };


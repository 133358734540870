/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import Text from 'components/Utils/Text';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import './_ModalTitle.scss';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const _ModalTitle: React.FC<{ children: string }> = ({ children }) => {
    /***** RENDER *****/
    return (
        <Text.Heading h2 align--center className="ModalTitle">
            {children}
        </Text.Heading>
    );
};

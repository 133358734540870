/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React from 'react';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import './__CheckBoxList.scss';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/

/**
 * @type {React.FC<{
 *      children: React.ReactNode
 * }>}
 */
const _CheckBoxList = ({ children }) => {
    /***** RENDER *****/
    return <div className="CheckBoxList">{children}</div>;
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

export { _CheckBoxList };

/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import * as Sentry from '@sentry/react';
import { redirect } from '@tanstack/react-router';
import type { AppSchema } from 'App';
import axios from 'axios';
import { dev_api as devAPI, api as prodAPI } from 'config/config';
import type { activateAccountSchema } from 'containers/login/modules/activate';
import type { GetOptionTypeFromRouteId } from 'router/types';
import { isAuthenticated } from 'router/utils/isAuthenticated';
import store from 'store/store';
import type { z } from 'zod';

function configureSentryEmailScope() {
    const { app } = store.getState();
    const email = app?.app_user_data?.email;

    try {
        Sentry.configureScope((scope) => {
            scope.setUser({ email });
        });
    } catch (error) {
        console.error('Failed to attach user context to Sentry.');
    }
}

export function loginGetUserSuccessSideEffects() {
    configureSentryEmailScope();
}
export const API_BASE_URL = import.meta.env.VITE_CCP_ENVIRONMENT === 'development' ? devAPI : prodAPI;
/**
 * This function can be used to set axios defaults on either the global axios, or an instance of axios.
 */
export function setAxiosDefaults(instance = null) {
    const axiosInstance = instance || axios;
    axiosInstance.defaults.baseURL = API_BASE_URL;

    if (window?.ccpIsAppWebview) {
        const accessToken = localStorage.getItem('access_token');
        axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;
    } else {
        axiosInstance.defaults.withCredentials = true;
    }
}

type AppRouteBeforeLoadOpts = GetOptionTypeFromRouteId<'/', 'beforeLoad'>;

/**
 * Handle activation requests
 */
export function handleActivation({ context, location }: Pick<AppRouteBeforeLoadOpts, 'context' | 'location'>) {
    const { app_check_token_data } = context.store.getState().app;
    const { service_move_approve_status, service_move_approve_error } = context.store.getState().services;
    const { account_approve_additional_user_status, account_approve_additional_user_error } = context.store.getState().account;

    if (location.pathname !== '/activate') {
        // handle additional user requests
        if (account_approve_additional_user_status === 'error') {
            switch (account_approve_additional_user_error.code) {
                case 'ERR_ACCESS_TOKEN':
                    break;
                case 'ERR_MOVE_TOKEN_INVALID':
                case 'ERR_INVITATION_TOKEN_INVALID':
                    return handleTemplate('invalid');
                case 'ERR_INVITATION_REQUIRES_SIGN_UP': {
                    return !app_check_token_data?.is_security_detail_completed || !app_check_token_data?.is_user_detail_completed
                        ? handleTemplate('additional-user')
                        : handleTemplate('invalid');
                }
                default:
                    return handleTemplate('expired-token');
            }
        }

        // Handle move service requests
        if (service_move_approve_status === 'error') {
            switch (service_move_approve_error.code) {
                case 'ERR_ACCESS_TOKEN':
                    break;
                case 'ERR_MOVE_TOKEN_INVALID':
                case 'ERR_INVITATION_TOKEN_INVALID':
                    return handleTemplate('invalid');
                case 'ERR_INVITATION_REQUIRES_SIGN_UP': {
                    return !app_check_token_data?.is_security_detail_completed || !app_check_token_data?.is_user_detail_completed
                        ? handleTemplate('move-service')
                        : handleTemplate('expired-move');
                }
                default:
                    return handleTemplate('expired-move');
            }
        }
    }
}

function handleTemplate(template: z.infer<typeof activateAccountSchema>['template']): never {
    throw redirect({
        to: '/activate',
        search: ({ ...rest }) => ({
            ...rest,
            template
        })
    });
}

/**
 * Handle unauthenticated redirects and redirects after login (where ref is provided in the URL)
 */
export function handleRootRoute(search: AppSchema) {
    if (!isAuthenticated()) {
        throw redirect({ to: '/login', search });
    }

    throw redirect({ to: '/dashboard' });
}

/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { useKatanaParams } from 'containers/katana/hooks/useSetupEditorRouteParams';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { useAppViewport } from 'utilities/hooks/useAppViewport/useAppViewport';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
interface KatanaMobileViewExclusionProps {
    children: React.ReactNode;
}

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const KatanaMobileViewEditorExclusion: React.FC<KatanaMobileViewExclusionProps> = ({ children }) => {
    /***** HOOKS *****/
    const { page } = useKatanaParams();
    const isMobile = useAppViewport(['xs', 'sm']);

    /***** RENDER HELPERS *****/
    const isSetupPage = page === 'setup';
    const isShowingChildren = !(isMobile && !isSetupPage);

    /***** RENDER *****/
    if (isShowingChildren) {
        return children;
    }
};

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

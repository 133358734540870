import {
    DOMAIN_ACTION_COR_ERROR,
    DOMAIN_ACTION_COR_REQUEST,
    DOMAIN_ACTION_COR_SUCCESS,
    DOMAIN_ACTION_MANUAL_COR_ERROR,
    DOMAIN_ACTION_MANUAL_COR_REQUEST,
    DOMAIN_ACTION_MANUAL_COR_SUCCESS,
    DOMAIN_ACTIVE_ERROR,
    DOMAIN_ACTIVE_REQUEST,
    DOMAIN_ACTIVE_SUCCESS,
    DOMAIN_ADD_HOSTING_CALCULATE_ERROR,
    DOMAIN_ADD_HOSTING_CALCULATE_REQUEST,
    DOMAIN_ADD_HOSTING_CALCULATE_SUCCESS,
    DOMAIN_AUTO_RENEW_ERROR,
    DOMAIN_AUTO_RENEW_REQUEST,
    DOMAIN_AUTO_RENEW_SUCCESS,
    DOMAIN_AVAILABILITY_RESET,
    DOMAIN_AVAILABILITY_UPDATE,
    DOMAIN_CHILD_RECORD_DELETE_ERROR,
    DOMAIN_CHILD_RECORD_DELETE_REQUEST,
    DOMAIN_CHILD_RECORD_DELETE_RESET,
    DOMAIN_CHILD_RECORD_DELETE_SUCCESS,
    DOMAIN_CHILD_RECORD_ERROR,
    DOMAIN_CHILD_RECORD_LIST_ERROR,
    DOMAIN_CHILD_RECORD_LIST_REQUEST,
    DOMAIN_CHILD_RECORD_LIST_SUCCESS,
    DOMAIN_CHILD_RECORD_REQUEST,
    DOMAIN_CHILD_RECORD_SUCCESS,
    DOMAIN_CONFIGURATION_ERROR,
    DOMAIN_CONFIGURATION_REQUEST,
    DOMAIN_CONFIGURATION_SUCCESS,
    DOMAIN_CONTACT_ERROR,
    DOMAIN_CONTACT_REQUEST,
    DOMAIN_CONTACT_RESET,
    DOMAIN_CONTACT_SUCCESS,
    DOMAIN_CONTACT_THROUGH_SEARCH_ERROR,
    DOMAIN_CONTACT_THROUGH_SEARCH_REQUEST,
    DOMAIN_CONTACT_THROUGH_SEARCH_RESET,
    DOMAIN_CONTACT_THROUGH_SEARCH_SUCCESS,
    DOMAIN_CONTACT_UPDATE_ERROR,
    DOMAIN_CONTACT_UPDATE_REQUEST,
    DOMAIN_CONTACT_UPDATE_SUCCESS,
    DOMAIN_COR_ERROR,
    DOMAIN_COR_REQUEST,
    DOMAIN_COR_SUCCESS,
    DOMAIN_CURRENT_CONFIGURATION_ERROR,
    DOMAIN_CURRENT_CONFIGURATION_REQUEST,
    DOMAIN_CURRENT_CONFIGURATION_SUCCESS,
    DOMAIN_DEFAULT_CONFIGURATION_ERROR,
    DOMAIN_DEFAULT_CONFIGURATION_REQUEST,
    DOMAIN_DEFAULT_CONFIGURATION_SUCCESS,
    DOMAIN_DEFAULT_RECORD_STORE,
    DOMAIN_DEFAULT_RECORD_STORE_AWAIT,
    DOMAIN_DNSSEC_DISABLE_ERROR,
    DOMAIN_DNSSEC_DISABLE_REQUEST,
    DOMAIN_DNSSEC_DISABLE_SUCCESS,
    DOMAIN_DNSSEC_ENABLED_STATUS_ERROR,
    DOMAIN_DNSSEC_ENABLED_STATUS_REQUEST,
    DOMAIN_DNSSEC_ENABLED_STATUS_SUCCESS,
    DOMAIN_DNSSEC_ENABLE_ERROR,
    DOMAIN_DNSSEC_ENABLE_REQUEST,
    DOMAIN_DNSSEC_ENABLE_SUCCESS,
    DOMAIN_DNSSEC_ERROR,
    DOMAIN_DNSSEC_LIST_ERROR,
    DOMAIN_DNSSEC_LIST_REQUEST,
    DOMAIN_DNSSEC_LIST_SUCCESS,
    DOMAIN_DNSSEC_OPTIONS_ERROR,
    DOMAIN_DNSSEC_OPTIONS_REQUEST,
    DOMAIN_DNSSEC_OPTIONS_SUCCESS,
    DOMAIN_DNSSEC_REQUEST,
    DOMAIN_DNSSEC_SUCCESS,
    DOMAIN_DNS_RECORDS_EXPORT_ERROR,
    DOMAIN_DNS_RECORDS_EXPORT_REQUEST,
    DOMAIN_DNS_RECORDS_EXPORT_SUCCESS,
    DOMAIN_DNS_VIEW,
    DOMAIN_ELIGIBILITY_ERROR,
    DOMAIN_ELIGIBILITY_REQUEST,
    DOMAIN_ELIGIBILITY_SUCCESS,
    DOMAIN_EMAIL_RECORD_ERROR,
    DOMAIN_EMAIL_RECORD_LIST_ERROR,
    DOMAIN_EMAIL_RECORD_LIST_REQUEST,
    DOMAIN_EMAIL_RECORD_LIST_SUCCESS,
    DOMAIN_EMAIL_RECORD_REQUEST,
    DOMAIN_EMAIL_RECORD_STORE,
    DOMAIN_EMAIL_RECORD_STORE_RESET,
    DOMAIN_EMAIL_RECORD_SUCCESS,
    DOMAIN_EMAIL_UPDATE_ERROR,
    DOMAIN_EMAIL_UPDATE_REQUEST,
    DOMAIN_EMAIL_UPDATE_SUCCESS,
    DOMAIN_EPP_ERROR,
    DOMAIN_EPP_REQUEST,
    DOMAIN_EPP_SUCCESS,
    DOMAIN_GET_COR_PRICE_ERROR,
    DOMAIN_GET_COR_PRICE_REQUEST,
    DOMAIN_GET_COR_PRICE_SUCCESS,
    DOMAIN_INFORMATION_ERROR,
    DOMAIN_INFORMATION_REQUEST,
    DOMAIN_INFORMATION_SUCCESS,
    DOMAIN_INITIAL_STATE,
    DOMAIN_LOCK_CHECK_ERROR,
    DOMAIN_LOCK_CHECK_REQUEST,
    DOMAIN_LOCK_CHECK_SUCCESS,
    DOMAIN_LOCK_ERROR,
    DOMAIN_LOCK_REQUEST,
    DOMAIN_LOCK_SUCCESS,
    DOMAIN_MOVE_ERROR,
    DOMAIN_MOVE_REQUEST,
    DOMAIN_MOVE_SUCCESS,
    DOMAIN_NAMESERVER_STORE,
    DOMAIN_PREVIEW_PRESET_CHANGES_ERROR,
    DOMAIN_PREVIEW_PRESET_CHANGES_REQUEST,
    DOMAIN_PREVIEW_PRESET_CHANGES_SUCCESS,
    DOMAIN_PROTECTION_CHECK_ERROR,
    DOMAIN_PROTECTION_CHECK_REQUEST,
    DOMAIN_PROTECTION_CHECK_SUCCESS,
    DOMAIN_PROTECTION_ERROR,
    DOMAIN_PROTECTION_REQUEST,
    DOMAIN_PROTECTION_SUCCESS,
    DOMAIN_RECORD_FAIL,
    DOMAIN_RECORD_REQUEST,
    DOMAIN_RECORD_STORE,
    DOMAIN_RECORD_STORE_AWAIT,
    DOMAIN_RECORD_SUCCESS,
    DOMAIN_RENEW_APPLY_PROMO_ERROR,
    DOMAIN_RENEW_APPLY_PROMO_REQUEST,
    DOMAIN_RENEW_APPLY_PROMO_SUCCESS,
    DOMAIN_RENEW_ERROR,
    DOMAIN_RENEW_OPTIONS_ERROR,
    DOMAIN_RENEW_OPTIONS_REQUEST,
    DOMAIN_RENEW_OPTIONS_SUCCESS,
    DOMAIN_RENEW_REQUEST,
    DOMAIN_RENEW_SUCCESS,
    DOMAIN_SUBACCOUNT_FAIL,
    DOMAIN_SUBACCOUNT_REQUEST,
    DOMAIN_SUBACCOUNT_SUCCESS,
    DOMAIN_SUGGESTION_ERROR,
    DOMAIN_SUGGESTION_REQUEST,
    DOMAIN_SUGGESTION_SUCCESS,
    DOMAIN_THIRD_PARTY_ERROR,
    DOMAIN_THIRD_PARTY_REQUEST,
    DOMAIN_THIRD_PARTY_SUCCESS,
    DOMAIN_UPDATE_CURRENT_CONFIGURATION_ERROR,
    DOMAIN_UPDATE_CURRENT_CONFIGURATION_REQUEST,
    DOMAIN_UPDATE_CURRENT_CONFIGURATION_SUCCESS,
    DOMAIN_WEB_RECORD_ERROR,
    DOMAIN_WEB_RECORD_LIST_ERROR,
    DOMAIN_WEB_RECORD_LIST_REQUEST,
    DOMAIN_WEB_RECORD_LIST_SUCCESS,
    DOMAIN_WEB_RECORD_REQUEST,
    DOMAIN_WEB_RECORD_STORE,
    DOMAIN_WEB_RECORD_STORE_RESET,
    DOMAIN_WEB_RECORD_SUCCESS,
    DOMAIN_WHOIS_ERROR,
    DOMAIN_WHOIS_REQUEST,
    DOMAIN_WHOIS_SUCCESS
} from './action';

const initialState = {
    domain_dns_view: {
        state: 'default',
        config: '',
        icon: 'cloud-hardware',
        title: 'Custom Nameservers'
    },
    domain_information_data: null,
    domain_information_status: null,
    domain_information_error: null,
    domain_active_data: null,
    domain_active_status: null,
    domain_active_error: null,
    domain_suggestion_status: null,
    domain_suggestion_data: null,
    domain_suggestion_error: null,
    domain_renew_options_status: null,
    domain_renew_options_data: null,
    domain_renew_options_error: null,
    domain_renew_data: null,
    domain_renew_status: null,
    domain_renew_error: null,
    domain_renew_apply_promo_data: null,
    domain_renew_apply_promo_status: null,
    domain_renew_apply_promo_error: null,
    domain_auto_renew_data: null,
    domain_auto_renew_status: null,
    domain_auto_renew_error: null,
    domain_contact_data: null,
    domain_contact_status: null,
    domain_contact_error: null,
    domain_contact_through_search_data: null,
    domain_contact_through_search_status: null,
    domain_contact_through_search_error: null,
    domain_contact_update_data: null,
    domain_contact_update_status: null,
    domain_contact_update_error: null,
    domain_registrant_contact: null,
    domain_tech_contact: null,
    domain_billing_contact: null,
    domain_admin_contact: null,
    domain_email_update_status: null,
    domain_email_update_data: null,
    domain_email_update_error: null,

    domain_default_configuration_status: null,
    domain_default_configuration_data: null,
    domain_default_configuration_error: null,
    domain_current_configuration_status: null,
    domain_current_configuration_data: null,
    domain_current_configuration_error: null,
    domain_update_current_configuration_data: null,
    domain_update_current_configuration_status: null,
    domain_update_current_configuration_error: null,

    domain_web_record_list_data: null,
    domain_web_record_list_status: null,
    domain_web_record_list_error: null,
    domain_web_record_data: null,
    domain_web_record_status: null,
    domain_web_record_error: null,
    domain_web_record_store: [],
    domain_email_record_list_data: null,
    domain_email_record_list_status: null,
    domain_email_record_list_error: null,
    domain_email_record_data: null,
    domain_email_record_status: null,
    domain_email_record_error: null,
    domain_email_record_store: [],

    domain_record_store_status: 'ready',
    domain_record_store: [],
    domain_default_record_store_status: 'ready',
    domain_default_record_store: [],
    domain_nameserver_store: [],

    domain_record_data: null,
    domain_record_status: null,
    domain_record_error: null,

    domain_dns_records_export_data: null,
    domain_dns_records_export_status: null,
    domain_dns_records_export_error: null,

    domain_third_party_status: null,
    domain_third_party_data: null,
    domain_third_party_error: null,

    domain_child_record_list_data: null,
    domain_child_record_list_status: null,
    domain_child_record_list_error: null,
    domain_child_record_delete_data: null,
    domain_child_record_delete_status: null,
    domain_child_record_delete_error: null,
    domain_child_record_data: null,
    domain_child_record_status: null,
    domain_child_record_error: null,
    domain_dnssec_list_data: null,
    domain_dnssec_list_status: null,
    domain_dnssec_data: null,
    domain_dnssec_status: null,
    domain_dnssec_error: null,

    domain_cor_status: null,
    domain_cor_data: null,
    domain_cor_action_data: null,
    domain_cor_action_status: null,
    domain_move_data: null,
    domain_move_status: null,
    domain_move_error: null,
    domain_eligibility_status: null,
    domain_eligibility_data: null,
    domain_eligibility_error: null,

    domain_protection_data: null,
    domain_protection_status: null,
    domain_protection_error: null,
    domain_protection_check_status: null,
    domain_protection_check_data: null,
    domain_protection_check_error: null,
    domain_lock_data: null,
    domain_lock_status: null,
    domain_lock_error: null,
    domain_lock_check_status: null,
    domain_lock_check_data: null,
    domain_lock_check_error: null,
    domain_whois_data: null,
    domain_whois_status: null,
    domain_whois_error: null,
    domain_epp_data: null,
    domain_epp_status: null,
    domain_epp_error: null,

    domain_subaccount_data: null,
    domain_subaccount_status: null,
    domain_subaccount_error: null,

    domain_add_hosting_calculate_status: null,
    domain_add_hosting_calculate_data: null,
    domain_add_hosting_calculate_error: null,

    domain_availability_status: null,
    domain_availability_data: {},
    domain_availability_error: null,

    domain_preview_preset_changes_status: null,
    domain_preview_preset_changes_data: null,

    domain_dnssec_enable_status: null,
    domain_dnssec_enable_data: null
};

const domainReducer = (state = initialState, action) => {
    switch (action.type) {
        /**********************************************************************************************************
         *   RESET
         **********************************************************************************************************/
        case DOMAIN_INITIAL_STATE:
            return initialState;

        case DOMAIN_DNS_VIEW:
            return {
                ...state,
                domain_dns_view: action.domain_dns_view
            };

        /**********************************************************************************************************
         *   DOMAIN INFORMATION REQUEST
         **********************************************************************************************************/
        case DOMAIN_INFORMATION_REQUEST:
            return {
                ...state,
                domain_information_status: 'loading',
                domain_information_data: null,
                domain_information_error: null
            };

        case DOMAIN_INFORMATION_SUCCESS:
            return {
                ...state,
                domain_information_status: 'success',
                domain_information_data: action.domain_information_data
            };

        case DOMAIN_INFORMATION_ERROR:
            return {
                ...state,
                domain_information_status: 'error',
                domain_information_error: action.domain_information_error
            };

        /**********************************************************************************************************
         *   DOMAIN AVAILABILITY REQUEST
         **********************************************************************************************************/
        case DOMAIN_AVAILABILITY_RESET: {
            const newState = {};
            Object.entries(state.domain_availability_data).forEach(([key, value]) => {
                if (key === action.queryKey) {
                    return;
                }

                newState[key] = value;
            });

            return {
                ...state,
                domain_availability_data: newState
            };
        }

        case DOMAIN_AVAILABILITY_UPDATE: {
            const { key, data } = action.payload;

            const domain_availability_data = { ...state.domain_availability_data, [key]: data };

            return {
                ...state,
                domain_availability_data
            };
        }

        /**********************************************************************************************************
         *   DOMAIN ACTIVE REQUEST
         **********************************************************************************************************/
        case DOMAIN_ACTIVE_REQUEST:
            return {
                ...state,
                domain_active_status: 'loading',
                domain_active_data: null,
                domain_active_error: null
            };

        case DOMAIN_ACTIVE_SUCCESS:
            return {
                ...state,
                domain_active_status: 'success',
                domain_active_data: action.domain_active_data
            };

        case DOMAIN_ACTIVE_ERROR:
            return {
                ...state,
                domain_active_status: 'error',
                domain_active_error: action.domain_active_error
            };

        /**********************************************************************************************************
         *   SUGGESTION STATES
         **********************************************************************************************************/
        case DOMAIN_SUGGESTION_REQUEST:
            return {
                ...state,
                domain_suggestion_status: 'loading',
                domain_suggestion_data: null,
                domain_suggestion_error: null
            };

        case DOMAIN_SUGGESTION_SUCCESS:
            return {
                ...state,
                domain_suggestion_status: 'success',
                domain_suggestion_data: action.domain_suggestion_data
            };

        case DOMAIN_SUGGESTION_ERROR:
            return {
                ...state,
                domain_suggestion_status: 'error',
                domain_suggestion_error: action.domain_suggestion_error
            };

        /**********************************************************************************************************
         *   DOMAIN RENEW REQUEST
         **********************************************************************************************************/
        case DOMAIN_RENEW_OPTIONS_REQUEST:
            return {
                ...state,
                domain_renew_options_status: 'loading',
                domain_renew_options_data: null,
                domain_renew_options_error: null
            };

        case DOMAIN_RENEW_OPTIONS_SUCCESS:
            return {
                ...state,
                domain_renew_options_status: 'success',
                domain_renew_options_data: action.domain_renew_options_data
            };

        case DOMAIN_RENEW_OPTIONS_ERROR:
            return {
                ...state,
                domain_renew_options_status: 'error',
                domain_renew_options_error: action.domain_renew_options_error
            };

        case DOMAIN_RENEW_REQUEST:
            return {
                ...state,
                domain_renew_status: 'loading',
                domain_renew_data: null,
                domain_renew_error: null
            };

        case DOMAIN_RENEW_SUCCESS:
            return {
                ...state,
                domain_renew_status: 'success',
                domain_renew_data: action.domain_renew_data
            };

        case DOMAIN_RENEW_ERROR:
            return {
                ...state,
                domain_renew_status: 'error',
                domain_renew_error: action.domain_renew_error
            };

        case DOMAIN_RENEW_APPLY_PROMO_REQUEST:
            return {
                ...state,
                domain_renew_apply_promo_status: 'loading',
                domain_renew_apply_promo_data: null,
                domain_renew_apply_promo_error: null
            };

        case DOMAIN_RENEW_APPLY_PROMO_SUCCESS:
            return {
                ...state,
                domain_renew_apply_promo_status: 'success',
                domain_renew_apply_promo_data: action.domain_renew_apply_promo_data
            };

        case DOMAIN_RENEW_APPLY_PROMO_ERROR:
            return {
                ...state,
                domain_renew_apply_promo_status: 'error',
                domain_renew_apply_promo_error: action.domain_renew_apply_promo_error
            };

        case DOMAIN_AUTO_RENEW_REQUEST:
            return {
                ...state,
                domain_auto_renew_status: 'loading',
                domain_auto_renew_data: null,
                domain_auto_renew_error: null
            };

        case DOMAIN_AUTO_RENEW_SUCCESS:
            return {
                ...state,
                domain_auto_renew_status: 'success',
                domain_auto_renew_data: action.domain_auto_renew_data
            };

        case DOMAIN_AUTO_RENEW_ERROR:
            return {
                ...state,
                domain_auto_renew_status: 'error',
                domain_auto_renew_error: action.domain_auto_renew_error
            };

        case DOMAIN_CONTACT_RESET: {
            return {
                ...state,
                domain_contact_status: null,
                domain_contact_data: null,
                domain_contact_error: null
            };
        }
        case DOMAIN_CONTACT_REQUEST:
            return {
                ...state,
                domain_contact_status: 'loading',
                domain_contact_data: null,
                domain_contact_error: null
            };

        case DOMAIN_CONTACT_SUCCESS:
            return {
                ...state,
                domain_contact_status: 'success',
                domain_contact_data: action.domain_contact_data
            };

        case DOMAIN_CONTACT_ERROR:
            return {
                ...state,
                domain_contact_status: 'error',
                domain_contact_error: action.domain_contact_error
            };

        case DOMAIN_CONTACT_THROUGH_SEARCH_RESET: {
            return {
                ...state,
                domain_contact_through_search_status: null,
                domain_contact_through_search_data: null,
                domain_contact_through_search_error: null
            };
        }

        case DOMAIN_CONTACT_THROUGH_SEARCH_REQUEST:
            return {
                ...state,
                domain_contact_through_search_status: 'loading',
                domain_contact_through_search_data: null,
                domain_contact_through_search_error: null
            };

        case DOMAIN_CONTACT_THROUGH_SEARCH_SUCCESS:
            return {
                ...state,
                domain_contact_through_search_status: 'success',
                domain_contact_through_search_data: action.domain_contact_through_search_data
            };

        case DOMAIN_CONTACT_THROUGH_SEARCH_ERROR:
            return {
                ...state,
                domain_contact_through_search_status: 'error',
                domain_contact_through_search_error: action.domain_contact_through_search_error
            };

        case DOMAIN_CONTACT_UPDATE_REQUEST:
            return {
                ...state,
                domain_contact_update_status: 'loading',
                domain_contact_update_data: null,
                domain_contact_update_error: null
            };

        case DOMAIN_CONTACT_UPDATE_SUCCESS:
            return {
                ...state,
                domain_contact_update_status: 'success',
                domain_contact_update_data: action.domain_contact_update_data
            };

        case DOMAIN_CONTACT_UPDATE_ERROR:
            return {
                ...state,
                domain_contact_update_status: 'error',
                domain_contact_update_error: action.domain_contact_update_error
            };

        case DOMAIN_EMAIL_UPDATE_REQUEST:
            return {
                ...state,
                domain_email_update_status: 'loading',
                domain_email_update_data: null,
                domain_email_update_error: null
            };

        case DOMAIN_EMAIL_UPDATE_SUCCESS:
            return {
                ...state,
                domain_email_update_status: 'success',
                domain_email_update_data: action.domain_email_update_data
            };

        case DOMAIN_EMAIL_UPDATE_ERROR:
            return {
                ...state,
                domain_email_update_status: 'error',
                domain_email_update_error: action.domain_email_update_error
            };

        /**********************************************************************************************************
         *   DOMAIN COR
         **********************************************************************************************************/
        case DOMAIN_COR_REQUEST:
            return {
                ...state,
                domain_cor_status: 'loading',
                domain_cor_data: null
            };

        case DOMAIN_COR_SUCCESS:
            return {
                ...state,
                domain_cor_status: 'success',
                domain_cor_data: action.domain_cor_data
            };

        case DOMAIN_COR_ERROR:
            return {
                ...state,
                domain_cor_status: 'error'
            };
        case DOMAIN_ACTION_MANUAL_COR_REQUEST:
            return {
                ...state,
                domain_cor_action_status: 'loading',
                domain_cor_action_data: null
            };

        case DOMAIN_ACTION_MANUAL_COR_SUCCESS:
            return {
                ...state,
                domain_cor_action_status: 'success',
                domain_cor_action_data: action.domain_cor_action_data
            };

        case DOMAIN_ACTION_MANUAL_COR_ERROR:
            return {
                ...state,
                domain_cor_action_status: 'error'
            };

        case DOMAIN_ACTION_COR_REQUEST:
            return {
                ...state,
                domain_cor_action_status: 'loading',
                domain_cor_action_data: null
            };

        case DOMAIN_ACTION_COR_SUCCESS:
            return {
                ...state,
                domain_cor_action_status: 'success',
                domain_cor_action_data: action.domain_cor_action_data
            };

        case DOMAIN_ACTION_COR_ERROR:
            return {
                ...state,
                domain_cor_action_status: 'error'
            };

        /**********************************************************************************************************
         *   DOMAIN ELIGIBILITY
         **********************************************************************************************************/
        case DOMAIN_ELIGIBILITY_REQUEST:
            return {
                ...state,
                domain_eligibility_status: 'loading',
                domain_eligibility_data: null,
                domain_eligibility_error: null
            };

        case DOMAIN_ELIGIBILITY_SUCCESS:
            return {
                ...state,
                domain_eligibility_status: 'success',
                domain_eligibility_data: action.domain_eligibility_data
            };

        case DOMAIN_ELIGIBILITY_ERROR:
            return {
                ...state,
                domain_eligibility_status: 'error',
                domain_eligibility_error: action.domain_eligibility_error
            };

        /**********************************************************************************************************
         *   DOMAIN MOVE
         **********************************************************************************************************/
        case DOMAIN_MOVE_REQUEST:
            return {
                ...state,
                domain_move_status: 'loading',
                domain_move_data: null,
                domain_move_error: null
            };

        case DOMAIN_MOVE_SUCCESS:
            return {
                ...state,
                domain_move_status: 'success',
                domain_move_data: action.domain_move_data
            };

        case DOMAIN_MOVE_ERROR:
            return {
                ...state,
                domain_move_status: 'error',
                domain_move_error: action.domain_move_error
            };

        /**********************************************************************************************************
         *   DOMAINS DEFAULT DNS CONFIGURATION
         **********************************************************************************************************/
        case DOMAIN_CONFIGURATION_REQUEST:
            return {
                ...state,
                domain_default_configuration_status: 'loading',
                domain_default_configuration_data: null,
                domain_default_configuration_error: null,
                domain_current_configuration_status: 'loading',
                domain_current_configuration_data: null,
                domain_current_configuration_error: null,
                domain_record_store_status: 'loading',
                domain_record_store: [],
                domain_default_record_store_status: 'loading',
                domain_default_record_store: []
            };

        case DOMAIN_CONFIGURATION_SUCCESS:
            return {
                ...state,
                domain_default_configuration_status: 'success',
                domain_default_configuration_data: action.domain_default_configuration_data,
                domain_current_configuration_status: 'success',
                domain_current_configuration_data: action.domain_current_configuration_data,
                domain_dns_view: action.domain_dns_view,
                domain_record_store_status: 'ready',
                domain_record_store: action.domain_record_store,
                domain_default_record_store_status: 'ready',
                domain_default_record_store: action.domain_default_record_store,
                domain_nameserver_store: action.domain_nameserver_store
            };

        case DOMAIN_CONFIGURATION_ERROR:
            return {
                ...state,
                domain_default_configuration_status: 'error',
                domain_default_configuration_error: action.domain_default_configuration_error,
                domain_current_configuration_status: 'error',
                domain_current_configuration_error: action.domain_current_configuration_error
            };

        /**********************************************************************************************************
         *   DOMAINS DEFAULT DNS CONFIGURATION
         **********************************************************************************************************/
        case DOMAIN_DEFAULT_CONFIGURATION_REQUEST:
            return {
                ...state,
                domain_default_configuration_status: 'loading',
                domain_default_configuration_data: null,
                domain_default_configuration_error: null
            };

        case DOMAIN_DEFAULT_CONFIGURATION_SUCCESS:
            return {
                ...state,
                domain_default_configuration_status: 'success',
                domain_default_configuration_data: action.domain_default_configuration_data
            };

        case DOMAIN_DEFAULT_CONFIGURATION_ERROR:
            return {
                ...state,
                domain_default_configuration_status: 'error',
                domain_default_configuration_error: action.domain_default_configuration_error
            };

        /**********************************************************************************************************
         *   DOMAINS CURRENT DNS CONFIGURATION
         **********************************************************************************************************/
        case DOMAIN_CURRENT_CONFIGURATION_REQUEST:
            return {
                ...state,
                domain_current_configuration_status: 'loading',
                domain_current_configuration_data: null,
                domain_current_configuration_error: null
            };

        case DOMAIN_CURRENT_CONFIGURATION_SUCCESS:
            return {
                ...state,
                domain_current_configuration_status: 'success',
                domain_current_configuration_data: action.domain_current_configuration_data
            };

        case DOMAIN_CURRENT_CONFIGURATION_ERROR:
            return {
                ...state,
                domain_current_configuration_status: 'error',
                domain_current_configuration_error: action.domain_current_configuration_error
            };

        case DOMAIN_UPDATE_CURRENT_CONFIGURATION_REQUEST:
            return {
                ...state,
                domain_update_current_configuration_status: 'loading',
                domain_update_current_configuration_data: null,
                domain_update_current_configuration_error: null
            };

        case DOMAIN_UPDATE_CURRENT_CONFIGURATION_SUCCESS:
            return {
                ...state,
                domain_update_current_configuration_status: 'success',
                domain_update_current_configuration_data: action.domain_update_current_configuration_data
            };

        case DOMAIN_UPDATE_CURRENT_CONFIGURATION_ERROR:
            return {
                ...state,
                domain_update_current_configuration_status: 'error',
                domain_update_current_configuration_error: action.domain_update_current_configuration_error
            };

        /**********************************************************************************************************
         *   RECORD/NAMESERVER STORE
         **********************************************************************************************************/
        case DOMAIN_RECORD_STORE:
            return {
                ...state,
                domain_record_store_status: 'ready',
                domain_record_store: action.domain_record_store
            };

        case DOMAIN_RECORD_STORE_AWAIT:
            return {
                ...state,
                domain_record_store_status: 'loading',
                domain_record_store: []
            };

        case DOMAIN_DEFAULT_RECORD_STORE:
            return {
                ...state,
                domain_default_record_store_status: 'ready',
                domain_default_record_store: action.domain_default_record_store
            };

        case DOMAIN_DEFAULT_RECORD_STORE_AWAIT:
            return {
                ...state,
                domain_default_record_store_status: 'loading',
                domain_default_record_store: []
            };

        case DOMAIN_NAMESERVER_STORE:
            return {
                ...state,
                domain_nameserver_store: action.domain_nameserver_store
            };

        /**********************************************************************************************************
         *   WEB-FORWARDER RECORD STATES
         **********************************************************************************************************/
        case DOMAIN_WEB_RECORD_LIST_REQUEST:
            return {
                ...state,
                domain_web_record_list_status: 'loading',
                domain_web_record_list_data: null,
                domain_web_record_list_error: null
            };

        case DOMAIN_WEB_RECORD_LIST_SUCCESS:
            return {
                ...state,
                domain_web_record_list_status: 'success',
                domain_web_record_list_data: action.domain_web_record_data
            };

        case DOMAIN_WEB_RECORD_LIST_ERROR:
            return {
                ...state,
                domain_web_record_list_status: 'error',
                domain_web_record_list_error: action.domain_web_record_error
            };

        case DOMAIN_WEB_RECORD_REQUEST:
            return {
                ...state,
                domain_web_record_status: 'loading',
                domain_web_record_data: null,
                domain_web_record_error: null
            };

        case DOMAIN_WEB_RECORD_SUCCESS:
            return {
                ...state,
                domain_web_record_status: 'success',
                domain_web_record_data: action.domain_web_record_data
            };

        case DOMAIN_WEB_RECORD_ERROR:
            return {
                ...state,
                domain_web_record_status: 'error',
                domain_web_record_error: action.domain_web_record_error
            };

        case DOMAIN_WEB_RECORD_STORE:
            return {
                ...state,
                domain_web_record_store: action.domain_web_record_store
            };

        case DOMAIN_WEB_RECORD_STORE_RESET:
            return {
                ...state,
                domain_web_record_store: []
            };

        /**********************************************************************************************************
         *   EMAIL-FORWARDER RECORD STATES
         **********************************************************************************************************/
        case DOMAIN_EMAIL_RECORD_LIST_REQUEST:
            return {
                ...state,
                domain_email_record_list_status: 'loading',
                domain_email_record_list_data: null,
                domain_email_record_list_error: null
            };

        case DOMAIN_EMAIL_RECORD_LIST_SUCCESS:
            return {
                ...state,
                domain_email_record_list_status: 'success',
                domain_email_record_list_data: action.domain_email_record_list_data
            };

        case DOMAIN_EMAIL_RECORD_LIST_ERROR:
            return {
                ...state,
                domain_email_record_list_status: 'error',
                domain_email_record_list_error: action.domain_email_record_list_error
            };

        case DOMAIN_EMAIL_RECORD_REQUEST:
            return {
                ...state,
                domain_email_record_status: 'loading',
                domain_email_record_data: null,
                domain_email_record_error: null
            };

        case DOMAIN_EMAIL_RECORD_SUCCESS:
            return {
                ...state,
                domain_email_record_status: 'success',
                domain_email_record_data: action.domain_email_record_data
            };

        case DOMAIN_EMAIL_RECORD_ERROR:
            return {
                ...state,
                domain_email_record_status: 'error',
                domain_email_record_error: action.domain_email_record_error
            };

        case DOMAIN_EMAIL_RECORD_STORE:
            return {
                ...state,
                domain_email_record_store: action.domain_email_record_store
            };

        case DOMAIN_EMAIL_RECORD_STORE_RESET:
            return {
                ...state,
                domain_email_record_store: []
            };

        /**********************************************************************************************************
         *   DNS RECORD STATES
         **********************************************************************************************************/
        case DOMAIN_RECORD_REQUEST:
            return {
                ...state,
                domain_record_status: 'loading',
                domain_record_data: null,
                domain_record_error: null
            };

        case DOMAIN_RECORD_SUCCESS:
            return {
                ...state,
                domain_record_status: 'success',
                domain_record_data: action.domain_record_data,
                domain_record_store: action.domain_record_store ? action.domain_record_store : state.domain_record_store
            };

        case DOMAIN_RECORD_FAIL:
            return {
                ...state,
                domain_record_status: 'error',
                domain_record_error: action.domain_record_error
            };

        /**********************************************************************************************************
         *   DNS RECORD STATES
         **********************************************************************************************************/
        case DOMAIN_DNS_RECORDS_EXPORT_REQUEST:
            return {
                ...state,
                domain_dns_records_export_status: 'loading',
                domain_dns_records_export_data: null,
                domain_dns_records_export_error: null
            };

        case DOMAIN_DNS_RECORDS_EXPORT_SUCCESS:
            return {
                ...state,
                domain_dns_records_export_status: 'success',
                domain_dns_records_export_data: action.domain_dns_records_export_data
            };

        case DOMAIN_DNS_RECORDS_EXPORT_ERROR:
            return {
                ...state,
                domain_dns_records_export_status: 'error',
                domain_dns_records_export_error: action.domain_dns_records_export_error
            };

        /**********************************************************************************************************
         *   THIRD PARTY STATES
         **********************************************************************************************************/
        case DOMAIN_THIRD_PARTY_REQUEST:
            return {
                ...state,
                domain_third_party_status: 'loading',
                domain_third_party_data: null,
                domain_third_party_error: null
            };

        case DOMAIN_THIRD_PARTY_SUCCESS:
            return {
                ...state,
                domain_third_party_status: 'success',
                domain_third_party_data: action.domain_third_party_data
            };

        case DOMAIN_THIRD_PARTY_ERROR:
            return {
                ...state,
                domain_third_party_status: 'error',
                domain_third_party_error: action.domain_third_party_error
            };

        /**********************************************************************************************************
         *  PREVIEW PRESET CHANGES
         **********************************************************************************************************/
        case DOMAIN_PREVIEW_PRESET_CHANGES_REQUEST:
            return {
                ...state,
                domain_preview_preset_changes_status: 'loading',
                domain_preview_preset_changes_data: null
            };

        case DOMAIN_PREVIEW_PRESET_CHANGES_SUCCESS:
            return {
                ...state,
                domain_preview_preset_changes_status: 'success',
                domain_preview_preset_changes_data: action.domain_preview_preset_changes_data
            };

        case DOMAIN_PREVIEW_PRESET_CHANGES_ERROR:
            return {
                ...state,
                domain_preview_preset_changes_status: 'error'
            };

        /**********************************************************************************************************
         *   CHILD RECORD STATES
         **********************************************************************************************************/
        case DOMAIN_CHILD_RECORD_LIST_REQUEST:
            return {
                ...state,
                domain_child_record_list_status: 'loading',
                domain_child_record_list_data: null,
                domain_child_record_list_error: null
            };

        case DOMAIN_CHILD_RECORD_LIST_SUCCESS:
            return {
                ...state,
                domain_child_record_list_status: 'success',
                domain_child_record_list_data: action.domain_child_record_list_data
            };

        case DOMAIN_CHILD_RECORD_LIST_ERROR:
            return {
                ...state,
                domain_child_record_list_status: 'error',
                domain_child_record_list_error: action.domain_child_record_list_error
            };

        case DOMAIN_CHILD_RECORD_DELETE_RESET:
            return {
                ...state,
                domain_child_record_delete_status: null,
                domain_child_record_delete_data: null,
                domain_child_record_delete_error: null
            };

        case DOMAIN_CHILD_RECORD_DELETE_REQUEST:
            return {
                ...state,
                domain_child_record_delete_status: 'loading',
                domain_child_record_delete_data: null,
                domain_child_record_delete_error: null
            };

        case DOMAIN_CHILD_RECORD_DELETE_SUCCESS:
            return {
                ...state,
                domain_child_record_delete_status: 'success',
                domain_child_record_delete_data: action.domain_child_record_delete_data
            };

        case DOMAIN_CHILD_RECORD_DELETE_ERROR:
            return {
                ...state,
                domain_child_record_delete_status: 'error',
                domain_child_record_delete_error: action.domain_child_record_delete_error
            };

        case DOMAIN_CHILD_RECORD_REQUEST:
            return {
                ...state,
                domain_child_record_status: 'loading',
                domain_child_record_data: null,
                domain_child_record_error: null
            };

        case DOMAIN_CHILD_RECORD_SUCCESS:
            return {
                ...state,
                domain_child_record_status: 'success',
                domain_child_record_data: action.domain_child_record_data
            };

        case DOMAIN_CHILD_RECORD_ERROR:
            return {
                ...state,
                domain_child_record_status: 'error',
                domain_child_record_error: action.domain_child_record_error
            };

        /**********************************************************************************************************
         *   DNSSEC STATES
         **********************************************************************************************************/
        case DOMAIN_DNSSEC_OPTIONS_REQUEST:
            return {
                ...state,
                domain_dnssec_options_status: 'loading',
                domain_dnssec_options_data: null,
                domain_dnssec_options_error: null
            };
        case DOMAIN_DNSSEC_OPTIONS_SUCCESS:
            return {
                ...state,
                domain_dnssec_options_status: 'success',
                domain_dnssec_options_data: action.domain_dnssec_options_data
            };
        case DOMAIN_DNSSEC_OPTIONS_ERROR:
            return {
                ...state,
                domain_dnssec_options_status: 'error',
                domain_dnssec_options_error: action.domain_dnssec_options_error
            };

        case DOMAIN_DNSSEC_LIST_REQUEST:
            return {
                ...state,
                domain_dnssec_list_status: 'loading',
                domain_dnssec_list_data: null
            };

        case DOMAIN_DNSSEC_LIST_SUCCESS:
            return {
                ...state,
                domain_dnssec_list_status: 'success',
                domain_dnssec_list_data: action.domain_dnssec_list_data
            };

        case DOMAIN_DNSSEC_LIST_ERROR:
            return {
                ...state,
                domain_dnssec_list_error: action.domain_dnssec_list_error,
                domain_dnssec_list_status: 'error'
            };

        case DOMAIN_DNSSEC_REQUEST:
            return {
                ...state,
                domain_dnssec_status: 'loading',
                domain_dnssec_data: null,
                domain_dnssec_error: null
            };

        case DOMAIN_DNSSEC_SUCCESS:
            return {
                ...state,
                domain_dnssec_status: 'success',
                domain_dnssec_data: action.domain_dnssec_data
            };

        case DOMAIN_DNSSEC_ERROR:
            return {
                ...state,
                domain_dnssec_status: 'error',
                domain_dnssec_error: action.domain_dnssec_error
            };

        case DOMAIN_DNSSEC_ENABLED_STATUS_REQUEST:
            return {
                ...state,
                domain_dnssec_enabled_status_status: 'loading',
                domain_dnssec_enabled_status_data: null
            };

        case DOMAIN_DNSSEC_ENABLED_STATUS_SUCCESS:
            return {
                ...state,
                domain_dnssec_enabled_status_status: 'success',
                domain_dnssec_enabled_status_data: action.domain_dnssec_enabled_status_data
            };

        case DOMAIN_DNSSEC_ENABLED_STATUS_ERROR:
            return {
                ...state,
                domain_dnssec_enabled_status_status: 'error',
                domain_dnssec_enabled_status_error: null
            };

        /**********************************************************************************************************
         *   ID PROTECTION
         **********************************************************************************************************/
        case DOMAIN_PROTECTION_REQUEST:
            return {
                ...state,
                domain_protection_status: 'loading',
                domain_protection_data: null,
                domain_protection_error: null
            };

        case DOMAIN_PROTECTION_SUCCESS:
            return {
                ...state,
                domain_protection_status: 'success',
                domain_protection_data: action.domain_protection_data
            };

        case DOMAIN_PROTECTION_ERROR:
            return {
                ...state,
                domain_protection_status: 'error',
                domain_protection_error: action.domain_protection_error
            };

        /**********************************************************************************************************
         *   ID PROTECTION CHECK
         **********************************************************************************************************/
        case DOMAIN_PROTECTION_CHECK_REQUEST:
            return {
                ...state,
                domain_protection_check_status: 'loading',
                domain_protection_check_data: null,
                domain_protection_check_error: null
            };

        case DOMAIN_PROTECTION_CHECK_SUCCESS:
            return {
                ...state,
                domain_protection_check_status: 'success',
                domain_protection_check_data: action.domain_protection_check_data
            };

        case DOMAIN_PROTECTION_CHECK_ERROR:
            return {
                ...state,
                domain_protection_check_status: 'error',
                domain_protection_check_error: action.domain_protection_check_error
            };

        /**********************************************************************************************************
         *   DOMAIN LOCK CHECK
         **********************************************************************************************************/
        case DOMAIN_LOCK_CHECK_REQUEST:
            return {
                ...state,
                domain_lock_check_status: 'loading',
                domain_lock_check_data: null,
                domain_lock_check_error: null
            };

        case DOMAIN_LOCK_CHECK_SUCCESS:
            return {
                ...state,
                domain_lock_check_status: 'success',
                domain_lock_check_data: action.domain_lock_check_data
            };

        case DOMAIN_LOCK_CHECK_ERROR:
            return {
                ...state,
                domain_lock_check_status: 'error',
                domain_lock_check_error: action.domain_lock_check_error
            };

        /**********************************************************************************************************
         *   DOMAIN LOCK
         **********************************************************************************************************/
        case DOMAIN_LOCK_REQUEST:
            return {
                ...state,
                domain_lock_status: 'loading',
                domain_lock_data: null,
                domain_lock_error: null
            };

        case DOMAIN_LOCK_SUCCESS:
            return {
                ...state,
                domain_lock_status: 'success',
                domain_lock_data: action.domain_lock_data
            };

        case DOMAIN_LOCK_ERROR:
            return {
                ...state,
                domain_lock_status: 'error',
                domain_lock_error: action.domain_lock_error
            };

        /**********************************************************************************************************
         *   DOMAIN WHOIS
         **********************************************************************************************************/
        case DOMAIN_WHOIS_REQUEST:
            return {
                ...state,
                domain_whois_status: 'loading',
                domain_whois_data: null,
                domain_whois_error: null
            };

        case DOMAIN_WHOIS_SUCCESS:
            return {
                ...state,
                domain_whois_status: 'success',
                domain_whois_data: action.domain_whois_data
            };

        case DOMAIN_WHOIS_ERROR:
            return {
                ...state,
                domain_whois_status: 'error',
                domain_whois_error: action.domain_whois_error
            };

        /**********************************************************************************************************
         *   DOMAIN EPP
         **********************************************************************************************************/
        case DOMAIN_EPP_REQUEST:
            return {
                ...state,
                domain_epp_status: 'loading',
                domain_epp_data: null,
                domain_epp_error: null,
                domain_epp_copied: null
            };

        case DOMAIN_EPP_SUCCESS:
            return {
                ...state,
                domain_epp_status: 'success',
                domain_epp_data: action.domain_epp_data
            };

        case DOMAIN_EPP_ERROR:
            return {
                ...state,
                domain_epp_status: 'error',
                domain_epp_error: action.domain_epp_error
            };

        /**********************************************************************************************************
         *   SEARCH FOR SUB ACCOUNTS (USED FOR DNS VENTRA HOSTING)
         **********************************************************************************************************/
        case DOMAIN_SUBACCOUNT_REQUEST:
            return {
                ...state,
                domain_subaccount_status: 'loading',
                domain_subaccount_data: null,
                domain_subaccount_error: null
            };

        case DOMAIN_SUBACCOUNT_SUCCESS:
            return {
                ...state,
                domain_subaccount_status: 'success',
                domain_subaccount_data: action.domain_subaccount_data
            };

        case DOMAIN_SUBACCOUNT_FAIL:
            return {
                ...state,
                domain_subaccount_status: 'error',
                domain_subaccount_error: action.domain_subaccount_error
            };

        /**********************************************************************************************************
         *   ADD HOSTING
         **********************************************************************************************************/
        case DOMAIN_ADD_HOSTING_CALCULATE_REQUEST:
            return {
                ...state,
                domain_add_hosting_calculate_status: 'loading',
                domain_add_hosting_calculate_data: null,
                domain_add_hosting_calculate_error: null
            };

        case DOMAIN_ADD_HOSTING_CALCULATE_SUCCESS:
            return {
                ...state,
                domain_add_hosting_calculate_status: 'success',
                domain_add_hosting_calculate_data: action.domain_add_hosting_calculate_data
            };

        case DOMAIN_ADD_HOSTING_CALCULATE_ERROR:
            return {
                ...state,
                domain_add_hosting_calculate_status: 'error',
                domain_add_hosting_calculate_error: action.domain_add_hosting_calculate_error
            };

        /**********************************************************************************************************
         *   COR PRICE
         **********************************************************************************************************/
        case DOMAIN_GET_COR_PRICE_REQUEST:
            return {
                ...state,
                domain_get_cor_price_status: 'loading',
                domain_get_cor_price_data: null
            };

        case DOMAIN_GET_COR_PRICE_SUCCESS:
            return {
                ...state,
                domain_get_cor_price_status: 'success',
                domain_get_cor_price_data: action.domain_get_cor_price_data
            };

        case DOMAIN_GET_COR_PRICE_ERROR:
            return {
                ...state,
                domain_get_cor_price_status: 'error',
                domain_get_cor_price_data: null
            };

        /**********************************************************************************************************
         *   DNSSEC ENABLE
         **********************************************************************************************************/
        case DOMAIN_DNSSEC_ENABLE_REQUEST:
            return {
                ...state,
                domain_dnssec_enable_status: 'loading',
                domain_dnssec_enable_data: null,
                domain_dnssec_enable_error: null
            };

        case DOMAIN_DNSSEC_ENABLE_SUCCESS:
            return {
                ...state,
                domain_dnssec_enable_status: 'success',
                domain_dnssec_enable_data: action.domain_dnssec_enable_data
            };

        case DOMAIN_DNSSEC_ENABLE_ERROR:
            return {
                ...state,
                domain_dnssec_enable_status: 'error',
                domain_dnssec_enable_error: action.domain_dnssec_enable_error
            };

        /**********************************************************************************************************
         *   DNSSEC DISABLE
         **********************************************************************************************************/
        case DOMAIN_DNSSEC_DISABLE_REQUEST:
            return {
                ...state,
                domain_dnssec_disable_status: 'loading',
                domain_dnssec_disable_data: null,
                domain_dnssec_disable_error: null
            };

        case DOMAIN_DNSSEC_DISABLE_SUCCESS:
            return {
                ...state,
                domain_dnssec_disable_status: 'success',
                domain_dnssec_disable_data: action.domain_dnssec_disable_data
            };

        case DOMAIN_DNSSEC_DISABLE_ERROR:
            return {
                ...state,
                domain_dnssec_disable_status: 'error'
            };

        default:
            return state;
    }
};

export default domainReducer;

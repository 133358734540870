/**********************************************************************************************************
 *   TYPE IMPORTS
 **********************************************************************************************************/
import type { KatanaNamespace } from 'containers/katana/types';

export function formFieldsInRuleParser(validation: KatanaNamespace.SectionDefinitions.PropertiesModified['validation'][number]) {
    return (value: any) => {
        if (Array.isArray(validation?.value)) {
            return validation.value.includes(value) ? undefined : `Value must be one of: ${validation.value.join(', ')}`;
        }
        return undefined;
    };
}

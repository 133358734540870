/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { connect } from 'react-redux';
import { Field, formValueSelector, reduxForm } from 'redux-form';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import Anchor from 'components/Anchor';
import InactiveButton from 'components/Buttons/InactiveButton';
import NXBox from 'components/NXBox';
import { PasswordChecker } from 'components/PasswordChecker';
import { increasedStrengthPasswordRequirements, validateIncreasedStrengthRequirements } from 'components/PasswordChecker/default';

/*   ACTIONS
 *****************************************************/
import { RenderRevealPasswordField, renderButton, requiredFieldValidation, validateConfirmValidation } from 'utilities/methods/form';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
let LoginReset = ({
    handleSubmit,
    // Redux props
    password_input,
    pristine,
    submitting,
    valid,
    login_reset_password_status
}) => {
    /***** RENDER *****/
    return (
        <>
            <NXBox.DefaultPadding>
                <div className="login__title sent">
                    <div className="heading">Reset your password</div>
                    <div className="description">Please enter your new password below.</div>
                </div>
            </NXBox.DefaultPadding>
            <form className="login__form" onSubmit={handleSubmit}>
                <div className="form__row">
                    <div className="form__column form__column--full">
                        <Field
                            label="Password"
                            name="password"
                            component={RenderRevealPasswordField}
                            validate={[requiredFieldValidation, validateIncreasedStrengthRequirements]}
                            className="form__textfield"
                            parent={this}
                            autoComplete="new-password"
                        />
                    </div>
                </div>
                <div className="form__row">
                    <div className="form__column form__column--full">
                        <Field
                            label="Confirm Password"
                            name="password_confirmation"
                            component={RenderRevealPasswordField}
                            validate={[requiredFieldValidation, validateConfirmValidation]}
                            className="form__textfield"
                            autoComplete="new-password"
                        />
                    </div>
                </div>
                <div className="form__row">
                    <div className="form__column form__column--full">
                        <div className="form__item">
                            {login_reset_password_status === 'loading' ? (
                                <InactiveButton loading />
                            ) : (
                                renderButton(pristine, submitting, valid, 'Change Password', undefined, 'reset-password__button')
                            )}
                        </div>
                    </div>
                </div>
                <div className="form__row form__row reset-password__password-checker-row">
                    <div className="form__column form__column--full">
                        <PasswordChecker input={password_input} requirements={increasedStrengthPasswordRequirements} />
                    </div>
                </div>
            </form>
            <div className="login__link-container">
                <Anchor className="login__link" to="/login">
                    Back to Login
                </Anchor>
            </div>
        </>
    );
};

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
LoginReset = reduxForm({
    form: 'resetForm' // a unique identifier for this form,
})(LoginReset);

const mapStateToProps = (state) => {
    const selector = formValueSelector(`resetForm`);
    const password_input = selector(state, 'password');

    return {
        form: `resetForm`,
        password_input,
        login_reset_password_status: state.login.login_reset_password_status
    };
};

LoginReset = connect(mapStateToProps)(LoginReset);

export default LoginReset;

/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import classNames from 'classnames';
import React, { useRef } from 'react';
import { useBoolean } from 'usehooks-ts';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import OutlineButton from 'components/Buttons/OutlineButton';
import { PhosphorIcons } from 'components/Icons/Phosphor';
import { Flex } from 'components/Utils/Flex';
import Text from 'components/Utils/Text';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { useClickAway } from 'utilities/hooks/useClickAway';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import './_OptionsIcon.scss';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * @type {React.FC<{
 *      onConfirm: () => void;
 * }>}
 */
const OptionsIcon = ({ onConfirm }) => {
    /***** STATE *****/
    const { value: isConfirming, setTrue: showConfirmation, setFalse: closeConfirmation } = useBoolean(false);

    /***** HOOKS *****/
    const wrapperRef = useRef(null);

    useClickAway([wrapperRef], closeConfirmation);

    /***** FUNCTIONS *****/
    /**
     * @type {React.MouseEventHandler<HTMLButtonElement>}
     */
    function handleClick(e) {
        e.stopPropagation();
        showConfirmation();
    }

    function handleConfirmDelete() {
        onConfirm();
        closeConfirmation();
    }

    /***** RENDER *****/
    return (
        <div className="OptionsIcon__wrapper HoverBlocker" ref={wrapperRef}>
            <button type="button" className={classNames('OptionsIcon', { 'OptionsIcon--isConfirming': isConfirming })} onClick={handleClick}>
                <PhosphorIcons.DotsThree.Bold secondary size={24} />
            </button>

            {isConfirming && (
                <Flex gap={0} direction="column" className="OptionsIcon__buttonsWrapper">
                    <OutlineButton
                        color="secondary-alt"
                        type="onClick"
                        onClick={(e) => {
                            e.stopPropagation();
                            handleConfirmDelete();
                        }}
                    >
                        <Text lead--l align--right size--xs medium secondary>
                            Delete
                        </Text>
                    </OutlineButton>
                </Flex>
            )}
        </div>
    );
};

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

export { OptionsIcon };

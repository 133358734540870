/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { Extension } from '@tiptap/core';
import { Plugin, PluginKey } from 'prosemirror-state';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import { richTextFeatureEnum } from 'components/Form/RichTextEditor/consts';

export const NoNewLine = Extension.create({
    name: richTextFeatureEnum.NO_NEW_LINE,

    addProseMirrorPlugins() {
        return [
            new Plugin({
                key: new PluginKey('eventHandler'),
                props: {
                    handleKeyDown: (view, event) => {
                        if (event.key === 'Enter' && !event.shiftKey) {
                            console.log('enter pressed');
                            return true;
                        }
                    }
                    // … and many, many more.
                    // Here is the full list: https://prosemirror.net/docs/ref/#view.EditorProps
                }
            })
        ];
    }
});

/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React from 'react';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import { Slot } from './Slot';

/**
 * @deprecated DO NOT USE SLOTS, just use props
 * @param {string[]} names
 * @param {React.ReactElement | React.ReactElement[] | React.ReactNode | React.ReactNodeArray} children
 * @returns {Array<React.ReactElement | null> }
 */
export function getSlots(names, children) {
    if (!children) return names.map(() => null);

    const slots = {};
    names.forEach((name) => {
        const slot = [];
        React.Children.forEach(children, (child) => {
            if (!child) {
                return;
            }
            if ((child.type === Slot && child.props.name === name) || (child.type !== Slot && name === 'defaultChildren')) {
                if (typeof child === 'string') {
                    slot.push(child);
                } else {
                    slot.push(React.cloneElement(child));
                }
            }
        });

        if (slot.length === 0) slots[name] = null;
        else if (slot.length === 1) slots[name] = slot[0];
        else slots[name] = slot;
    });
    return slots;
}

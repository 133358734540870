/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { application, postLoginChecklistItems } from 'config/config';
import React, { useState } from 'react';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import { Modal } from 'components/Lightboxes/Modal';
import { ProgressSteps } from 'components/ProgressSteps';
import Text from 'components/Utils/Text';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import { possibleChecklistItems } from 'containers/dashboard/Components/postLoginChecklist/consts';
import './_postLoginChecklist.scss';

function getItemsTurnedOnInConfig() {
    return possibleChecklistItems.filter(({ name: possibleChecklistItemName }) => {
        return Object.values(postLoginChecklistItems).some(({ name, on }) => possibleChecklistItemName === name && on);
    });
}

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
function PostLoginChecklist() {
    /***** STATE *****/
    const [checklistItems, setChecklistItems] = useState(getItemsTurnedOnInConfig());

    /***** FUNCTIONS *****/
    function markItemRequired(itemToMarkRequired) {
        setChecklistItems((prevChecklistItems) =>
            prevChecklistItems.map((item) => ({ ...item, isRequired: item.name === itemToMarkRequired ? true : item.isRequired }))
        );
    }

    function markItemComplete(itemToMarkComplete) {
        setChecklistItems((prevChecklistItems) =>
            prevChecklistItems.map((item) => ({ ...item, isComplete: item.name === itemToMarkComplete ? true : item.isComplete }))
        );
    }

    /***** RENDER HELPERS *****/
    const renderCurrentChecklistItem = () => {
        const currentChecklistItem = checklistItems.find(({ isRequired, isComplete }) => isRequired && !isComplete);

        if (!currentChecklistItem) return '';

        const { UIComponent } = currentChecklistItem;

        return <UIComponent markSelfAsComplete={() => markItemComplete(currentChecklistItem.name)} />;
    };

    const renderPrompt = () => {
        const requiredItems = checklistItems.filter(({ isRequired }) => isRequired);
        const areSomeRequiredItems = requiredItems.some(({ isComplete }) => !isComplete);

        return (
            <Modal ariaLabel="post login checklist" className="postLoginChecklist" isOpen={areSomeRequiredItems} removeCloseButton>
                <div className="postLoginChecklist__content">
                    <div className="postLoginChecklist__text">
                        <Text size--xxxl semiBold lead--none align--center>
                            <h2>Welcome to {application}!</h2>
                        </Text>
                        <Text className="postLoginChecklist__description" secondary size--s lead--s align--center>
                            Before you get started in {application}, there is a few housekeeping things that we would like to get sorted out for you.
                        </Text>
                    </div>
                    {requiredItems.length > 1 ? (
                        <ProgressSteps steps={requiredItems.map(({ name, label, isComplete }) => ({ name, label, isComplete }))} />
                    ) : (
                        ''
                    )}
                    {renderCurrentChecklistItem()}
                </div>
            </Modal>
        );
    };

    /***** RENDER *****/
    return (
        <>
            {/* Render all the "check is required" components, so they can each check if themselves are required before rendering the corresponding UI component */}
            {checklistItems.map(({ CheckIsRequiredComponent, name }) => (
                <CheckIsRequiredComponent key={name} markSelfAsRequired={() => markItemRequired(name)} />
            ))}

            {renderPrompt()}
        </>
    );
}
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

export default PostLoginChecklist;

/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import classNames from 'classnames';
import htmr from 'htmr';
import React, { Fragment, useEffect, useRef, useState } from 'react';
import ReactTooltipLite from 'react-tooltip-lite';
import store from 'store/store';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import { PhosphorIcons } from 'components/Icons/Phosphor';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { toolTipStateUpdate } from 'utilities/methods/commonActions';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import { focusableElementsSelector } from 'components/Lightboxes/Modal/consts';
import './_Tooltip.scss';
import { toolTipActiveContentSelector } from './consts';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * @type {React.FC<NTooltip.Props>}
 */
const Tooltip = ({ info, className, iconOverride, direction = 'up', style }) => {
    /***** STATE *****/
    const [open, setOpen] = useState(false);

    /***** HOOKS *****/
    const tipRef = useRef(null);
    const icoRef = useRef(null);
    const closeButtonRef = useRef(null);

    /***** FUNCTIONS *****/
    const toggleTip = () => {
        const { dispatch } = store;
        const newState = !open;
        setOpen(newState);
        dispatch(toolTipStateUpdate(newState));
    };

    const closeToolTip = () => {
        const { dispatch } = store;
        setOpen(false);
        dispatch(toolTipStateUpdate(false));
    };

    const closeToolTipKey = (e) => {
        if (e.key === 'Tab') return;
        e.preventDefault();
        closeToolTip();
        icoRef.current?.focus();
    };

    const bodyClick = (e) => {
        if (tipRef.current?.contains(e.target) || icoRef.current?.contains(e.target)) return;
        closeToolTip();
    };

    const onModalScroll = () => {
        if (closeButtonRef.current && open) {
            closeButtonRef.current.click();
        }
    };

    const toolTipTrap = (e) => {
        const toolTipActive = document.querySelector(toolTipActiveContentSelector);
        const isTooltipIcon = e.target.classList.contains('Tooltip__icon');
        const focusableToolTipElements = toolTipActive?.querySelectorAll(focusableElementsSelector);

        if (!isTooltipIcon && toolTipActive !== null) {
            const toolTipTabIndex = Array.from(focusableToolTipElements).indexOf(e.target);
            e.preventDefault();

            if (e.shiftKey) {
                const nextToolTipElementIndex = toolTipTabIndex === 0 ? focusableToolTipElements.length - 1 : toolTipTabIndex - 1;
                focusableToolTipElements[nextToolTipElementIndex].focus();
            } else {
                const nextToolTipElementIndex = toolTipTabIndex === focusableToolTipElements.length - 1 ? 0 : toolTipTabIndex + 1;
                focusableToolTipElements[nextToolTipElementIndex].focus();
            }
        }

        if (!isTooltipIcon || toolTipActive === null) return false;

        e.preventDefault();
        if (e.shiftKey) {
            focusableToolTipElements[focusableToolTipElements.length - 1].focus();
        } else {
            focusableToolTipElements[0].focus();
        }
        return true;
    };

    const handleKeyDown = (e) => {
        if (e.key === 'Escape' && open) {
            closeToolTip();
            e.stopImmediatePropagation();
        } else if (e.key === 'Tab') {
            toolTipTrap(e);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', bodyClick);
        document.addEventListener('scroll', bodyClick);
        document.addEventListener('keydown', handleKeyDown);
        return () => {
            document.removeEventListener('mousedown', bodyClick);
            document.removeEventListener('scroll', bodyClick);
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, [open]);

    useEffect(() => {
        const modal = document.querySelector('.Overlay');
        if (modal) {
            if (open) {
                modal.addEventListener('scroll', onModalScroll);
            } else {
                modal.removeEventListener('scroll', onModalScroll);
            }
        }
    }, [open]);

    const renderIcon = () => {
        if (iconOverride) {
            return iconOverride;
        }

        return <PhosphorIcons.WarningCircle.Bold size={18} />;
    };

    return (
        <Fragment>
            <ReactTooltipLite
                className={classNames('Tooltip', className)}
                isOpen={open}
                direction={direction}
                distance={10}
                arrowSize={5}
                styles={style}
                content={
                    <div role="document" ref={tipRef} className={classNames('Tooltip__content', { active: open })}>
                        <button type="button" ref={closeButtonRef} onClick={closeToolTip} onKeyDown={closeToolTipKey} className="Tooltip__close">
                            <PhosphorIcons.X white />
                        </button>
                        {typeof info === 'string' ? htmr(info) : info}
                    </div>
                }
            >
                <button type="button" ref={icoRef} onClick={toggleTip} className="Tooltip__icon icon">
                    {renderIcon()}
                </button>
            </ReactTooltipLite>
        </Fragment>
    );
};

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
export default Tooltip;

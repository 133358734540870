import { configureDNSMode } from 'containers/domain/queries/domainID/configureDNSMode';
import { getDNSPresetPreview } from 'containers/domain/queries/domainID/getDNSPresetPreview';
import { getDomainInfo } from 'containers/domain/queries/domainID/getDomainInfo';

export const domainQuery = Object.freeze({
    domainID: {
        getDomainInfo,
        getDNSPresetPreview,
        configureDNSMode
    }
});

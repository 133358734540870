/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React from 'react';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import Text from 'components/Utils/Text';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * Provides primary color and some other text styling such as font size and weight
 * 
 * Using children is recommended, however title is temporarily retained for existing components.
 * 
 * @param {{
 *    title?: React.ReactNode;
 *    children?: React.ReactNode;
 *    className?: string
 * }} props
 */
function NXBoxTitle({ title, className, children }) {
    /***** RENDER *****/
    return (
        <Text size--xl primary semiBold className={className}>
            <h3 className="NXBoxTitle">{children ?? title}</h3>
        </Text>
    );
}

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

export default NXBoxTitle;

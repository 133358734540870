/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { queryOptions, useQuery } from '@tanstack/react-query';
import type { DomainNamespace } from 'containers/domain/types';
import { launchSiteDNSModePropagationTracker } from 'containers/katana/components/launchWebsite/consts';
import { katanaQueryKeys } from 'containers/katana/queries/katanaQueryKeys';
import type { KatanaNamespace } from 'containers/katana/types';
import queryClient from 'store/queryClient';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { KATANA_API } from 'utilities/api/katana';
import type { KatanaAPI } from 'utilities/api/katana/types';

export function createQueryKey(serviceID: KatanaNamespace.ServiceID) {
    return katanaQueryKeys.katana.service.ID.serviceDetails(serviceID);
}

function createQueryOptions(serviceID: KatanaNamespace.ServiceID) {
    return queryOptions({
        queryKey: createQueryKey(serviceID),
        queryFn: () => KATANA_API.katana.service_id.service_details.GET(serviceID),
        retryOnMount: false,
        enabled: Boolean(serviceID),
        select: (data) => {
            if (data?.status === 200) {
                return data.data;
            }
        }
    });
}

function resetQueries(serviceID: KatanaNamespace.ServiceID) {
    return queryClient.resetQueries({
        queryKey: createQueryKey(serviceID),
        predicate: (query) => {
            const data = query.state.data as KatanaAPI.Katana.ServiceID.ServiceDetails.GET.ReturnTypeModified;

            if (data?.status === 200) {
                launchSiteDNSModePropagationTracker.setState((state) => state.filter((id) => id !== data.data.domain_id));
            }

            return true;
        }
    });
}

function resetQueriesByDomainID(domainID: DomainNamespace.DomainID) {
    return queryClient.resetQueries({
        queryKey: katanaQueryKeys.katana.service(),
        predicate: (query) => {
            if (!query.queryKey.includes('service_details')) {
                return false;
            }
            const data = query.state.data as KatanaAPI.Katana.ServiceID.ServiceDetails.GET.ReturnTypeModified;
            if (data?.status !== 200) {
                return false;
            }
            if (data.data.domain_id !== domainID) {
                return false;
            }

            launchSiteDNSModePropagationTracker.setState((state) => state.filter((id) => id !== domainID));

            return true;
        }
    });
}

function fetchQuery(serviceID: KatanaNamespace.ServiceID) {
    return queryClient.fetchQuery(createQueryOptions(serviceID));
}

/**********************************************************************************************************
 *   HOOK START
 **********************************************************************************************************/
/**
 * Checks Katana Service Details
 */
function _useQuery(serviceID: KatanaNamespace.ServiceID) {
    return useQuery(createQueryOptions(serviceID));
}

/**********************************************************************************************************
 *   HOOK END
 **********************************************************************************************************/

export const domainServiceDetails = Object.freeze({
    useQuery: _useQuery,
    createQueryKey,
    createQueryOptions,
    fetchQuery,
    resetQueries,
    resetQueriesByDomainID
});

/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React, { useContext, useEffect, useRef, useState } from 'react';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import { PhosphorIcons } from 'components/Icons/Phosphor';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import { PhoneInputContext } from '../PhoneInputContext';
import PhoneInputCountrySelectOptionList from './PhoneInputCountrySelectOptionList';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { useClickAway } from 'utilities/hooks/useClickAway';
import { generateSortedCountryOptions } from '../methods';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import './__PhoneCountrySelect.scss';

let _sortedCountryOptions = [];

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
function _PhoneInputCountrySelect(props) {
    const { iconComponent: IconComponent, options: countryOptions, disabled } = props;

    /***** STATE *****/
    if (_sortedCountryOptions.length === 0) {
        _sortedCountryOptions = generateSortedCountryOptions(countryOptions);
    }
    const sortedCountryOptions = _sortedCountryOptions;

    /***** STATE *****/
    const [countrySelectOpen, setCountrySelectOpen] = useState(false);

    /***** HOOKS *****/
    const { selectedCountry, setSelectedCountry, value, countrySelectEnabled } = useContext(PhoneInputContext);
    const countrySelectRef = useRef(null);
    useClickAway([countrySelectRef], () => {
        setCountrySelectOpen(false);
    });

    const canOpenSubMenu = !disabled && countrySelectEnabled;

    /***** FUNCTIONS *****/
    function toggleSelectMenu() {
        if (!canOpenSubMenu) return;
        setCountrySelectOpen(!countrySelectOpen);
    }

    function updateSelectedCountry(index) {
        const option = sortedCountryOptions[index];
        setSelectedCountry(option?.value ?? undefined);
        setCountrySelectOpen(false);
    }

    /***** EFFECTS *****/
    useEffect(() => {
        setCountrySelectOpen(false);
    }, [value]);

    /***** RENDER HELPERS *****/
    function renderSubmenuDropdownIcon() {
        if (!canOpenSubMenu) return '';
        return <PhosphorIcons.Chevron size={18} state={countrySelectOpen ? 'up' : 'down'} />;
    }

    /***** RENDER *****/
    return (
        <div ref={countrySelectRef}>
            <button type="button" disabled={!canOpenSubMenu} className="PhoneInputCountrySelect" onClick={toggleSelectMenu}>
                <IconComponent country={selectedCountry} label={selectedCountry} /> {renderSubmenuDropdownIcon()}
            </button>
            {countrySelectOpen ? (
                <PhoneInputCountrySelectOptionList {...{ sortedCountryOptions, selectedCountry, updateSelectedCountry, IconComponent }} />
            ) : (
                ''
            )}
        </div>
    );
}
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

export default _PhoneInputCountrySelect;

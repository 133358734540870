/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { createRoute, Outlet } from '@tanstack/react-router';

/**********************************************************************************************************
 *   ROUTE IMPORTS
 **********************************************************************************************************/
import { Redirect } from 'components/Router/Redirect';
import { BillingRoute } from 'containers/billing';
import { routerMiddleware } from 'router/utils/middleware';

/**********************************************************************************************************
 *   ROUTE START
 **********************************************************************************************************/
export const InvoiceRoute = createRoute({
    getParentRoute: () => BillingRoute,
    path: 'invoice',
    beforeLoad(opts) {
        routerMiddleware.business(this, opts);
        routerMiddleware.authentication('user', opts);
    },
    component: () => (
        <Redirect.When when="/billing/invoice" to="/billing/invoices">
            <Outlet />
        </Redirect.When>
    )
});

/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { useLocation, useNavigate, useSearch } from '@tanstack/react-router';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import OverlayLightbox from 'components/Lightboxes/OverlayLightbox';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import { FreeDomainClaimForm } from 'components/Promo/FreeDomain/ClaimForm';
import { freeDotSitePromoCode } from 'components/Promo/promoCodes';
import { checkYearPromoCode } from 'config/containers/promotions/methods';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const FreeSiteLightbox = () => {
    /***** HOOKS *****/
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const isOpen = useSearch({
        from: '/vip-rewards',
        select: (search) => search['free-domain'] === 'site'
    });

    /***** RENDER *****/
    return (
        <>
            {isOpen && (
                <OverlayLightbox
                    title="Claim your free .site domain"
                    desc="Find out if the name you want is available by searching below."
                    onOpen={isOpen}
                    onClose={() => {
                        checkYearPromoCode(freeDotSitePromoCode, '.site');
                        navigate({ to: pathname });
                    }}
                >
                    <FreeDomainClaimForm tld=".site" promoCode={freeDotSitePromoCode} />
                </OverlayLightbox>
            )}
        </>
    );
};

/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React, { useRef } from 'react';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import { RichTextEditorContext, newRichTextEditorTanstackStore } from 'components/Form/RichTextEditor/consts';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
interface RichTextEditorContextProviderProps {
    children: React.ReactNode;
}

export const RichTextEditorContextProvider: React.FC<RichTextEditorContextProviderProps> = ({ children }) => {
    /***** HOOKS *****/
    const richTextEditorContextTanstackStore = useRef(newRichTextEditorTanstackStore());

    /***** RENDER *****/
    return <RichTextEditorContext.Provider value={richTextEditorContextTanstackStore.current}>{children}</RichTextEditorContext.Provider>;
};

/**********************************************************************************************************
 *   BASE IMPORT
 *********************************************************************************************************/
import { Outlet, createRoute, redirect } from '@tanstack/react-router';
import { useStore } from '@tanstack/react-store';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import { ManageDomainTitle } from 'containers/domain/components/title';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import RequestLoader from 'components/Loaders/Request';
import SideBarPage from 'components/SideBarPage';
import Transition from 'components/Transition';

/*   ACTIONS
 *****************************************************/
import { resetAuDirectStatusState } from 'containers/auDirect/action';
import { getDomainInformation, getDomainRecords } from 'containers/domain/action';

/**********************************************************************************************************
 *   ROUTE IMPORTS
 **********************************************************************************************************/
import { MyServicesRoute } from 'containers/services';

/**********************************************************************************************************
 *   QUERIES
 **********************************************************************************************************/
import { katanaQuery } from 'containers/katana/queries/tanstackTree';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { useAUDirectRegisterStatusEffect, useHandleDomainInformationEffect } from 'containers/domain/hooks';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import { DomainModuleStore } from 'containers/domain/moduleConditions';
import type { GetOptionTypeFromRouteId } from 'router/types';
import { routerMiddleware } from 'router/utils/middleware';
import './_domain.scss';

/**********************************************************************************************************
 *   ROUTE START
 **********************************************************************************************************/
export const DomainManageRoute = createRoute({
    getParentRoute: () => MyServicesRoute,
    path: 'domains/$domainId',
    pendingComponent: RequestLoader,
    async loader(args) {
        await handlePreload(args).catch(() => void 0);
    },
    beforeLoad(opts) {
        routerMiddleware.business({ path: '/my-services/domains' }, opts);
        routerMiddleware.authentication('user', opts);
        routerMiddleware.business(this, opts);
        handleDirectDomainRoute(opts);
    }
});

/**********************************************************************************************************
 *   ROUTE FUNCTIONS
 **********************************************************************************************************/
type BeforeLoadContext = GetOptionTypeFromRouteId<'/my-services/domains/$domainId', 'beforeLoad'>;
type LoaderFnContext = GetOptionTypeFromRouteId<'/my-services/domains/$domainId', 'loader'>;

async function handlePreload({ cause, params, context }: LoaderFnContext) {
    if (cause === 'enter') {
        try {
            await getDomainInformation(params.domainId);
            await getDomainRecords(params.domainId);

            resetAuDirectStatusState();

            const domain = context.store.getState().domain.domain_information_data?.attributes?.domain;
            const is_vpn = context.store.getState().app.app_check_token_data?.is_vpn;

            await context.queryClient.prefetchQuery(katanaQuery.checkDomain.createQueryOptions(domain, is_vpn));
        } catch (e) {
            /* */
        }
    }
}

function handleDirectDomainRoute({ buildLocation, params, location }: BeforeLoadContext) {
    const { pathname: directPathname } = buildLocation({
        to: '/my-services/domains/$domainId',
        params
    });

    // Cannot visit this route directly
    if (location.pathname === directPathname) {
        throw redirect({
            to: '/my-services/domains/$domainId/general',
            params,
            hash: 'overview'
        });
    }
}

/**********************************************************************************************************
 *   ROUTE COMPONENT START
 **********************************************************************************************************/
const DomainManageRouteComponent = () => {
    /***** HOOKS *****/
    const store = useStore(DomainModuleStore);

    DomainModuleStore.useConditions();

    /***** EFFECTS *****/
    useHandleDomainInformationEffect();
    useAUDirectRegisterStatusEffect();

    /***** RENDER HELPERS *****/
    const [links] = SideBarPage.useGetSidebarLinks([
        {
            list_title: 'General',
            list_icon: 'accounts',
            list_items: store.general
        },
        {
            list_title: 'DNS',
            list_icon: 'dns-records',
            list_items: store.dns
        },
        {
            list_title: 'Security',
            list_icon: 'secure-hosting',
            list_items: store.security
        },
        {
            list_title: 'Admin',
            list_icon: 'settings',
            list_items: store.admin
        }
    ]);

    return (
        <>
            <ManageDomainTitle />
            <SideBarPage title="Manage Domain" icon="account" sidebarList={links}>
                <Transition>
                    <Outlet />
                </Transition>
            </SideBarPage>
        </>
    );
};

/**********************************************************************************************************
 *   ROUTE UPDATES
 **********************************************************************************************************/
DomainManageRoute.update({
    component: DomainManageRouteComponent
});

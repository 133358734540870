/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { useSelector } from 'react-redux';

/**********************************************************************************************************
 *   HOOK START
 **********************************************************************************************************/
export const useIsAuthenticated = () => {
    return useSelector((store: any) => !!store.app.app_user_data);
};

/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import * as Sentry from '@sentry/react';
import { applyMiddleware, compose, legacy_createStore as createStore } from 'redux';
import { batchDispatchMiddleware, enableBatching } from 'redux-batched-actions';
import { devToolsEnhancer } from 'redux-devtools-extension';
import { thunk } from 'redux-thunk';
import rootReducer from './rootReducer';

const initialState = {};
const enhancers = [];
const middleware = [thunk, batchDispatchMiddleware];

if (import.meta.env.MODE === 'development') {
    const devToolExtension = window.devToolExtension;
    if (typeof devToolExtension === 'function') {
        enhancers.push(devToolExtension());
    }
    enhancers.push(
        devToolsEnhancer({
            serialize: true
        })
    );
}

enhancers.push(
    Sentry.createReduxEnhancer({
        //optionally pass options listed below
        actionTransformer: (action) => {
            // remove any action that isn't required
            // https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/redux/#:~:text=actionTransformer%20(Function)

            return action;
        },
        stateTransformer: (state) => {
            // remove any state that isn't required
            // https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/redux/#:~:text=stateTransformer%20(Function)

            return state;
        }
    })
);

const composeEnhancers = compose(applyMiddleware(...middleware), ...enhancers);

//note, createStore is deprecated. We should investigate configureStore
const store = createStore(enableBatching(rootReducer), initialState, composeEnhancers);

export default store;

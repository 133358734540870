/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React from 'react';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import { MegaMayServicesBanner } from '..';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import SolidButton from 'components/Buttons/SolidButton';
import Text from 'components/Utils/Text';

/**********************************************************************************************************
 *   QUERIES
 **********************************************************************************************************/
import { usePromotionAllServicesQuery } from 'config/containers/promotions/queries';
import { megaMayAllServiesQueryRequiredServiceTypes } from '../../../queries';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { getPurchaseLink } from 'containers/services/consts';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import TenOff from '../../../assets/percent-off/10off.svg';
import TwentyOff from '../../../assets/percent-off/20off.svg';
import ThirtyOff from '../../../assets/percent-off/30off.svg';
import ThirtyFiveOff from '../../../assets/percent-off/35off.svg';
import FiftyOff from '../../../assets/percent-off/50off.svg';
import SeventyOff from '../../../assets/percent-off/70off.svg';
import SixteenSeventyFive from '../../../assets/prices/1675.svg';
import FourtyNineSeventyFive from '../../../assets/prices/4975.svg';

import {
    checkAtLeastOneEligibleService,
    isHostingEligibleForRenewDiscount,
    isHostingEligibleForUpgradeDiscount,
    isVpsEligibleForRenewDiscount
} from '../../../methods';
import { megamay24UtmCampaign, megamay24UtmSource } from '../../deals';

import './_megamay24ServiceBannerPanels.scss';
import { application } from 'config/config';

/**********************************************************************************************************
 *   PANEL COMPONENTS
 **********************************************************************************************************/
function Panel({ children }) {
    return <div className="mmServiceBannerPanel">{children}</div>;
}

function Price({ children }) {
    return (
        <div className="mmServiceBannerPanel__price">
            <div className="mmServiceBannerPanel__priceContent">{children}</div>
        </div>
    );
}

function TextSection({ top, bottom }) {
    return (
        <div className="mmServiceBannerPanel__text">
            <Text lead--1 align--center>
                <h3>{top}</h3>
            </Text>
            {bottom ? (
                <Text black bold lead--1 align--center size--s>
                    {bottom}
                </Text>
            ) : (
                ''
            )}
        </div>
    );
}

function ButtonContainer({ children }) {
    return <div className="mmServiceBannerPanel__buttonContainer">{children}</div>;
}

/**********************************************************************************************************
 *   DOMAINS
 **********************************************************************************************************/
/***** New .com.au domain *****/
function NewComAuDomain() {
    return (
        <Panel>
            <Price>
                <img src={FourtyNineSeventyFive} alt="$49.75" />
                <Text>/5 years*</Text>
            </Price>
            <TextSection
                top={
                    <>
                        New .com.au
                        <br />
                        domain names
                    </>
                }
                bottom={
                    <>
                        {' '}
                        Register Australia&apos;s
                        <br />
                        best-selling domain name.
                    </>
                }
            />
            <ButtonContainer>
                <SolidButton
                    color="white"
                    type="anchor"
                    href={getPurchaseLink(
                        null,
                        { utmMedium: 'service-banner-tld-com-au', utmCampaign: megamay24UtmCampaign, utmSource: megamay24UtmSource },
                        'https://ventraip.com.au/domain-names/extensions/com-au/'
                    )}
                    target="_blank"
                >
                    Buy Now
                </SolidButton>
            </ButtonContainer>
        </Panel>
    );
}

NewComAuDomain.MEGAMAY24_DOMAINS_NEW_COM_AU_DOMAIN_DISMISS_KEY = 'MEGAMAY24_DOMAINS_NEW_COM_AU_DOMAIN_DISMISS_KEY';

/***** New .au domain *****/
function NewAuDirectDomain() {
    return (
        <Panel>
            <Price>
                <img src={FourtyNineSeventyFive} alt="$49.75" />
                <Text>/5 years*</Text>
            </Price>
            <TextSection
                top={
                    <>
                        New .au direct
                        <br />
                        domain names
                    </>
                }
                bottom={
                    <>
                        {' '}
                        Register Australia&apos;s
                        <br />
                        newest domain name.
                    </>
                }
            />
            <ButtonContainer>
                <SolidButton
                    color="white"
                    type="anchor"
                    href={getPurchaseLink(
                        null,
                        { utmMedium: 'service-banner-tld-au', utmCampaign: megamay24UtmCampaign, utmSource: megamay24UtmSource },
                        'https://ventraip.com.au/domain-names/extensions/au/'
                    )}
                    target="_blank"
                >
                    Buy Now
                </SolidButton>
            </ButtonContainer>
        </Panel>
    );
}

NewAuDirectDomain.MEGAMAY24_DOMAINS_NEW_AU_DIRECT_DOMAIN_DISMISS_KEY = 'MEGAMAY24_DOMAINS_NEW_AU_DIRECT_DOMAIN_DISMISS_KEY';

/***** New .com domain *****/
function NewComDomain() {
    return (
        <Panel>
            <Price>
                <img src={SixteenSeventyFive} alt="$16.75" />
                <Text>/1 year*</Text>
            </Price>
            <TextSection
                top={
                    <>
                        New .com
                        <br />
                        domain names
                    </>
                }
                bottom={
                    <>
                        Register the world&apos;s most
                        <br />
                        popular web address.
                    </>
                }
            />
            <ButtonContainer>
                <SolidButton
                    color="white"
                    type="anchor"
                    href={getPurchaseLink(
                        null,
                        { utmMedium: 'service-banner-tld-com', utmCampaign: megamay24UtmCampaign, utmSource: megamay24UtmSource },
                        'https://ventraip.com.au/domain-names/extensions/com/'
                    )}
                    target="_blank"
                >
                    Buy Now
                </SolidButton>
            </ButtonContainer>
        </Panel>
    );
}

NewComDomain.MEGAMAY24_DOMAINS_NEW_COM_DOMAIN_DISMISS_KEY = 'MEGAMAY24_DOMAINS_NEW_COM_DOMAIN_DISMISS_KEY';

/**** PANELS *****/
const domainPanels = [
    {
        key: 1,
        ui: <NewComAuDomain />,
        dismissKey: NewComAuDomain.MEGAMAY24_DOMAINS_NEW_COM_AU_DOMAIN_DISMISS_KEY,
        showCondition: true
    },
    {
        key: 2,
        ui: <NewAuDirectDomain />,
        dismissKey: NewAuDirectDomain.MEGAMAY24_DOMAINS_NEW_AU_DIRECT_DOMAIN_DISMISS_KEY,
        showCondition: true
    },
    {
        key: 3,
        ui: <NewComDomain />,
        dismissKey: NewComDomain.MEGAMAY24_DOMAINS_NEW_COM_DOMAIN_DISMISS_KEY,
        showCondition: true
    }
];

export function MegaMayDomainsBanner() {
    return <MegaMayServicesBanner panels={domainPanels} />;
}

/**********************************************************************************************************
 *   HOSTING
 **********************************************************************************************************/
/***** Hosting renewals *****/
function HostingRenewals() {
    return (
        <Panel>
            <Price>
                <img src={ThirtyOff} alt="30% off" />
                <Text>
                    <span>*</span>
                </Text>
            </Price>
            <TextSection
                top={
                    <>
                        Shared Web
                        <br />
                        Hosting
                        <br />
                        Renewals
                    </>
                }
                bottom={null}
            />
            <ButtonContainer>
                <Text black semiBold lead--s align--center size--xss>
                    Click the &apos;Renew&apos; button on an eligible hosting service below to claim a discount on renewals. Offer only available
                    during MegaMay!
                </Text>
            </ButtonContainer>
        </Panel>
    );
}

HostingRenewals.MEGAMAY24_HOSTING_RENEWALS_DISMISS_KEY = 'MEGAMAY24_HOSTING_RENEWALS_DISMISS_KEY';

/***** Hosting upgrades *****/
function HostingUpgrades() {
    return (
        <Panel>
            <Price>
                <img src={FiftyOff} alt="50% off" />
                <Text>
                    <span>*</span>
                </Text>
            </Price>
            <TextSection
                top={
                    <>
                        Shared Web
                        <br />
                        Hosting
                        <br />
                        Upgrades
                    </>
                }
                bottom={<>Discount applied in {application}</>}
            />
            <ButtonContainer>
                <Text black semiBold lead--s align--center size--xss>
                    Click the &apos;Upgrade&apos; button on an eligible hosting service below to claim a discount on upgrades. Offer only available
                    during MegaMay!
                </Text>
            </ButtonContainer>
        </Panel>
    );
}

HostingUpgrades.MEGAMAY24_HOSTING_UPGRADE_DISMISS_KEY = 'MEGAMAY24_HOSTING_UPGRADE_DISMISS_KEY';

/***** New Hosting *****/
function NewHosting() {
    return (
        <Panel>
            <Price>
                <img src={SeventyOff} alt="70% off" />
                <Text>
                    <span>*</span>
                </Text>
            </Price>
            <TextSection
                top={
                    <>
                        New Web
                        <br />
                        Hosting
                    </>
                }
                bottom={
                    <>
                        Perfect for any type
                        <br />
                        of business website.
                    </>
                }
            />
            <ButtonContainer>
                <SolidButton
                    color="white"
                    type="anchor"
                    href={getPurchaseLink(
                        null,
                        { utmMedium: 'service-banner-business-hosting', utmCampaign: megamay24UtmCampaign, utmSource: megamay24UtmSource },
                        'https://ventraip.com.au/web-hosting/business-hosting/'
                    )}
                    target="_blank"
                >
                    Buy Now
                </SolidButton>
            </ButtonContainer>
        </Panel>
    );
}

NewHosting.MEGAMAY24_NEW_HOSTING_DISMISS_KEY = 'MEGAMAY24_NEW_HOSTING_DISMISS_KEY';

export function MegaMayHostingBanner() {
    /***** QUERIES *****/
    const { data: promotion_all_services_data } = usePromotionAllServicesQuery(megaMayAllServiesQueryRequiredServiceTypes);

    /***** RENDER HELPERS *****/
    const hostingPanels = [
        {
            key: 1,
            ui: <HostingRenewals />,
            dismissKey: HostingRenewals.MEGAMAY24_HOSTING_RENEWALS_DISMISS_KEY,
            showCondition: checkAtLeastOneEligibleService(promotion_all_services_data?.hosting, isHostingEligibleForRenewDiscount)
        },
        {
            key: 2,
            ui: <HostingUpgrades />,
            dismissKey: HostingUpgrades.MEGAMAY24_HOSTING_UPGRADE_DISMISS_KEY,
            showCondition: checkAtLeastOneEligibleService(promotion_all_services_data?.hosting, isHostingEligibleForUpgradeDiscount)
        },
        {
            key: 3,
            ui: <NewHosting />,
            dismissKey: NewHosting.MEGAMAY24_NEW_HOSTING_DISMISS_KEY,
            showCondition: true
        }
    ];

    return <MegaMayServicesBanner panels={hostingPanels} />;
}

/**********************************************************************************************************
 *   VPS
 **********************************************************************************************************/
/***** New Hosting *****/
function NewVps() {
    return (
        <Panel>
            <Price>
                <img src={TwentyOff} alt="20% off" />
                <Text>
                    <span>*</span>
                </Text>
            </Price>
            <TextSection
                top={
                    <>
                        New VPS
                        <br />
                        Hosting
                    </>
                }
                bottom={
                    <>
                        The ultimate speed,
                        <br />
                        reliability and freedom.
                    </>
                }
            />
            <ButtonContainer>
                <SolidButton
                    color="white"
                    type="anchor"
                    href={getPurchaseLink(
                        null,
                        { utmMedium: 'service-banner-new-vps', utmCampaign: megamay24UtmCampaign, utmSource: megamay24UtmSource },
                        'https://ventraip.com.au/web-professionals/'
                    )}
                    target="_blank"
                >
                    Buy Now
                </SolidButton>
            </ButtonContainer>
        </Panel>
    );
}

NewVps.MEGAMAY24_NEW_VPS_DISMISS_KEY = 'MEGAMAY24_NEW_VPS_DISMISS_KEY';

/***** VPS renewals *****/
function VpsRenewals() {
    return (
        <Panel>
            <Price>
                <img src={TenOff} alt="10% off" />
                <Text>
                    <span>*</span>
                </Text>
            </Price>
            <TextSection
                top={
                    <>
                        VPS
                        <br />
                        Renewals
                    </>
                }
                bottom={<>Discount applied in {application}</>}
            />
            <ButtonContainer>
                <Text black semiBold lead--s align--center size--xss>
                    Click the &apos;Renew&apos; button on an eligible VPS service below to claim a discount on renewals. Offer only available during
                    MegaMay!
                </Text>
            </ButtonContainer>
        </Panel>
    );
}

VpsRenewals.MEGAMAY24_VPS_RENEWALS_DISMISS_KEY = 'MEGAMAY24_VPS_RENEWALS_DISMISS_KEY';

export function MegaMayVpsBanner() {
    /***** QUERIES *****/
    const { data: promotion_all_services_data } = usePromotionAllServicesQuery(megaMayAllServiesQueryRequiredServiceTypes);

    /***** RENDER HELPERS *****/
    const vpsPanels = [
        {
            key: 1,
            ui: <NewVps />,
            dismissKey: NewVps.MEGAMAY24_NEW_VPS_DISMISS_KEY,
            showCondition: true
        },
        {
            key: 2,
            ui: <VpsRenewals />,
            dismissKey: VpsRenewals.MEGAMAY24_VPS_RENEWALS_DISMISS_KEY,
            showCondition: checkAtLeastOneEligibleService(promotion_all_services_data?.vps, isVpsEligibleForRenewDiscount)
        }
    ];

    return <MegaMayServicesBanner panels={vpsPanels} />;
}

/**********************************************************************************************************
 *   EMAIL
 **********************************************************************************************************/
/***** Email Hosting *****/
function EmailHosting() {
    return (
        <Panel>
            <Price>
                <img src={ThirtyFiveOff} alt="35% off" />
                <Text>
                    <span>*</span>
                </Text>
            </Price>
            <TextSection
                top={
                    <>
                        Email
                        <br />
                        Hosting
                    </>
                }
                bottom={
                    <>
                        A custom mailbox with free
                        <br />
                        spam and virus filtering.
                    </>
                }
            />
            <ButtonContainer>
                <SolidButton
                    color="white"
                    type="anchor"
                    href={getPurchaseLink('Email Hosting', {
                        utmMedium: 'service-banner-email-hosting',
                        utmCampaign: megamay24UtmCampaign,
                        utmSource: megamay24UtmSource
                    })}
                    target="_blank"
                >
                    Buy Now
                </SolidButton>
            </ButtonContainer>
        </Panel>
    );
}

EmailHosting.MEGAMAY24_EMAIL_HOSTING_DISMISS_KEY = 'MEGAMAY24_EMAIL_HOSTING_DISMISS_KEY';

/**** PANELS *****/
export const emailPanels = [
    {
        key: 1,
        ui: <EmailHosting />,
        dismissKey: EmailHosting.MEGAMAY24_EMAIL_HOSTING_DISMISS_KEY,
        showCondition: true
    }
];

export function MegaMayEmailBanner() {
    return <MegaMayServicesBanner panels={emailPanels} />;
}

/**********************************************************************************************************
 *   SSL
 **********************************************************************************************************/
/***** SSL *****/
function Ssl() {
    return (
        <Panel>
            <Price>
                <img src={TwentyOff} alt="20% off" />
                <Text>
                    <span>*</span>
                </Text>
            </Price>
            <TextSection
                top={
                    <>
                        SSL
                        <br />
                        Certificates
                    </>
                }
                bottom={
                    <>
                        Increase sales and boost
                        <br />
                        your website&apos;s ranking.
                    </>
                }
            />
            <ButtonContainer>
                <SolidButton
                    color="white"
                    type="anchor"
                    href={getPurchaseLink('SSL Certificates', {
                        utmMedium: 'service-banner-ssl-certificates',
                        utmCampaign: megamay24UtmCampaign,
                        utmSource: megamay24UtmSource
                    })}
                    target="_blank"
                >
                    Buy Now
                </SolidButton>
            </ButtonContainer>
        </Panel>
    );
}

Ssl.MEGAMAY24_SSL_DISMISS_KEY = 'MEGAMAY24_SSL_DISMISS_KEY';

/**** PANELS *****/
const sslPanels = [
    {
        key: 1,
        ui: <Ssl />,
        dismissKey: Ssl.MEGAMAY24_SSL_DISMISS_KEY,
        showCondition: true
    }
];

export function MegaMaySslBanner() {
    return <MegaMayServicesBanner panels={sslPanels} />;
}

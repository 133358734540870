import React, { createContext, useContext } from 'react';

// Create the RecursiveContext
/**
 * This context is used to pass down components to be used recursively
 * @type {import('react').Context<Record<string, unknown>>}
 */
const RecursiveContext = createContext({});

/**********************************************************************************************************
 *   HOOK START
 **********************************************************************************************************/

/**
 * Define the RecursiveContextProvider component
 */
const RecursiveContextProvider = ({ children, value }) => {
    /***** HOOKS *****/
    const existingValue = useContext(RecursiveContext);

    // Combine the existing value with the new value
    const mergedValue = {
        ...existingValue,
        ...value
    };

    /***** RENDER *****/
    return <RecursiveContext.Provider value={mergedValue}>{children}</RecursiveContext.Provider>;
};
/**********************************************************************************************************
 *   HOOK END
 **********************************************************************************************************/

/**
 * Define the useRecursiveContext hook
 */
const useRecursiveContext = () => {
    return useContext(RecursiveContext);
};

export { RecursiveContextProvider, useRecursiveContext };

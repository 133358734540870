import { useLocation, useNavigate, useRouter } from '@tanstack/react-router';

/**
 * @deprecated - Use useNavigate from @tanstack/react-router instead.
 *
 * This hook provides a matching API to @react-routers useHistory hook for navigation/integration
 * with legacy code.
 */
export const useHistory = () => {
    /***** HOOKS *****/
    const navigate = useNavigate();
    const location = useLocation();
    const router = useRouter();

    /**
     * @deprecated
     */
    const history = {
        push: (to: string, state: any = {}) => {
            navigate({ to, state });
        },
        replace: (to: string) => {
            navigate({ to, replace: true });
        },
        goBack: () => router.history.back(),
        location
    };

    /***** RESULTS *****/
    return history;
};

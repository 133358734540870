/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import classNames from 'classnames';
import React from 'react';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import './_InfoNotification.scss';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/

/**
 * @deprecated use DialogNotification instead
 * @type {React.FC<{
 *      type: 'warning' | 'error' | 'success',
 *      children: React.ReactNode,
 *      className?: string,
 *      noMargin?: boolean
 * }>}
 */
const InfoNotification = (props) => {
    const { children, type, className, noMargin } = props;

    const infoNotificationClassNames = classNames('InfoNotification', type, className, {
        'InfoNotification--noMargin': noMargin
    });

    /***** RENDER *****/
    return <div className={infoNotificationClassNames}>{children}</div>;
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

export default InfoNotification;

import { _PictureInPicture } from 'components/PictureInPicture/_PictureInPicture';
import { _PictureInPictureContent } from 'components/PictureInPicture/content';
import { _PictureInPictureControlsWrapper } from 'components/PictureInPicture/controls/controlsWrapper';
import { _PictureInPictureMaximiseButton } from 'components/PictureInPicture/controls/maximiseButton';
import { _PictureInPictureMinimiseButton } from 'components/PictureInPicture/controls/minimiseButton';
import { _PictureInPictureShowButton } from 'components/PictureInPicture/controls/showButton';
import { _PictureInPictureShrinkButton } from 'components/PictureInPicture/controls/shrinkButton';

export const PictureInPicture = Object.assign(_PictureInPicture, {
    Content: _PictureInPictureContent,
    ControlsWrapper: _PictureInPictureControlsWrapper,
    MaximiseButton: _PictureInPictureMaximiseButton,
    MinimiseButton: _PictureInPictureMinimiseButton,
    ShowButton: _PictureInPictureShowButton,
    ShrinkButton: _PictureInPictureShrinkButton
});

/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { get } from 'lodash';
import React from 'react';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { useDynamicFormActions } from 'containers/katana/components/dynamicFormFieldRenderer/hooks/useDynamicFormActions';
import { useGetDynamicFormFieldValues } from 'containers/katana/components/dynamicFormFieldRenderer/hooks/useGetDynamicFormFieldValue';
import { useTargetKeyToPropertyPath } from 'containers/katana/components/dynamicFormFieldRenderer/hooks/useTargetKeyToPropertyPath';
import { getCaveat } from 'containers/katana/formFields/methods/getCaveat';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import { CaveatTypes } from 'containers/katana/formFields/caveatTypes';
import './_CopyValueOnClickToTarget.scss';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export function CopyValueOnClickToTarget({ property, children }) {
    const copyValueOnClickTargetKey = getCaveat(CaveatTypes.COPY_VALUE_TO_TARGET_KEY_ON_CLICK, property.caveats);
    const targetPropertyAccessor = useTargetKeyToPropertyPath(property?.key, copyValueOnClickTargetKey);

    /***** HOOKS *****/
    const formValues = useGetDynamicFormFieldValues();
    const { dynamicChange } = useDynamicFormActions();

    /***** FUNCTIONS *****/
    function onClick() {
        const currentValue = get(formValues.values, property?.key);
        dynamicChange(targetPropertyAccessor, currentValue);
    }

    /***** RENDER *****/
    if (!copyValueOnClickTargetKey) {
        return children;
    }

    return (
        <button className="CopyValueOnClickToTarget" type="button" onClick={onClick}>
            {children}
        </button>
    );
}
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

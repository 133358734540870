/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { useCurrentEditor } from '@tiptap/react';
import type { MouseEventHandler } from 'react';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import { PhosphorIcons } from 'components/Icons/Phosphor';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import { ToolbarButton } from 'components/Form/RichTextEditor/plugins/Toolbar/ToolbarButton/ToolbarButton';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { useRichTextEditorContext } from 'components/Form/RichTextEditor/hooks/useRichTextEditorContext';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const InsertLinkAction = () => {
    /***** HOOKS *****/
    const [, setIsLinkEditMode] = useRichTextEditorContext('isLinkEditMode', false);
    const { editor } = useCurrentEditor();

    const onClick: MouseEventHandler<HTMLButtonElement> = (e) => {
        e.preventDefault();
        setIsLinkEditMode(true);
    };

    /***** RENDER HELPERS *****/
    const isLink = editor?.isActive('link');

    /***** RENDER *****/
    return (
        <ToolbarButton ariaLabel={isLink ? 'Update Link' : 'Insert Link'} onClick={onClick} isActive={isLink}>
            <PhosphorIcons.Link.Bold size={23} black={isLink} />
        </ToolbarButton>
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

import { _RequestLoaderMultiLoader } from 'components/Loaders/Request/_MultiLoader';
import { _RequestLoader } from 'components/Loaders/Request/_requestLoader';
import { _RequestLoaderSuspenseBoundary } from 'components/Loaders/Request/_SuspenseBoundary';
import { _RequestLoaderWithBackground } from 'components/Loaders/Request/_WithBackground';

const RequestLoader = Object.assign(_RequestLoader, {
    MultiLoader: _RequestLoaderMultiLoader,
    WithBackground: _RequestLoaderWithBackground,
    Suspense: _RequestLoaderSuspenseBoundary
});

export default RequestLoader;

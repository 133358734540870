/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React from 'react';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import { Input } from 'components/Form/Input';
import Text from 'components/Utils/Text';
import type { SocialsMetaInputNamespace } from 'containers/katana/containers/contentEditorModules/socialsMetaInput/types';
import { useUpdateSocialLinksMutation } from 'containers/katana/queries/useUpdateSocialLinksMutation';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
const { SOCIALS } = useUpdateSocialLinksMutation.FORM_FIELD_KEYS;

type InputValue = {
    type: string;
    url: string;
};

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const SocialsMetaInputChild: React.FC<SocialsMetaInputNamespace.ChildProps> = (props) => {
    const { type, index, label, placeholder, description, onBlur } = props;

    /***** FUNCTIONS *****/
    function onChangeTransform(value: string) {
        return { type, url: value };
    }

    function valueTransform(value?: InputValue) {
        return value?.url || '';
    }

    /***** RENDER *****/
    return (
        <>
            <Input.HookForm
                valueTransform={valueTransform}
                onChangeTransform={onChangeTransform}
                placeholder={placeholder}
                label={label}
                name={`${SOCIALS}.${index}`}
                intrinsicProps={{ onBlur }}
            />
            {Boolean(description) && (
                <Text secondary size--s italic>
                    {description}
                </Text>
            )}
        </>
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

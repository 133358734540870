/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import { useNavigate } from '@tanstack/react-router';
import PageNotFound from 'components/Errors/PageNotFound';
import RequestLoader from 'components/Loaders/Request';
import { pushNotification } from 'components/Toast/functions';
import { genericTerminatedCancelled } from 'utilities/methods/redirects/messageContent';
import { handleRedirect } from 'utilities/methods/redirects/methods';
import { redirectUrls } from 'utilities/methods/redirects/redirectUrls';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type GoogleManageRouteLoaderComponent = React.FC<{
    children: React.ReactNode;
}>;

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * Component that prevents children rendering until the gsuite_service_data is loaded. Once this is moved to tanstack (query),
 * the children can be suspense enabled and will not risk this being an issue.
 */
export const GoogleManageRouteLoader: GoogleManageRouteLoaderComponent = ({ children }) => {
    /***** HOOKS *****/
    const gsuite_service_status = useSelector((state: any) => state.gsuite.gsuite_service_status);
    const gsuite_service_error = useSelector((state: any) => state.gsuite.gsuite_service_error);
    const gsuite_service_data = useSelector((state: any) => state.gsuite.gsuite_service_data);

    const navigate = useNavigate();

    /***** EFFECTS *****/
    useEffect(() => {
        if (gsuite_service_error?.status === 403) {
            navigate({ to: redirectUrls.google_workspace });
            pushNotification(genericTerminatedCancelled);
        } else if (gsuite_service_data) {
            handleRedirect({
                serviceType: 'google_workspace',
                status: gsuite_service_data?.attributes?.status,
                domainName: gsuite_service_data?.attributes?.domain,
                history: {
                    push: (to) => navigate({ to })
                }
            });
        }
    }, [gsuite_service_error, gsuite_service_data]);

    /***** RENDER *****/
    if (gsuite_service_status === 'success') {
        return children;
    }

    if (gsuite_service_status === 'loading') {
        return <RequestLoader />;
    }

    return <PageNotFound />;
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

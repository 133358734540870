/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { cloneDeep } from 'lodash';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { getCaveat } from 'containers/katana/formFields/methods/getCaveat';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import { CaveatTypes } from 'containers/katana/formFields/caveatTypes';

/**********************************************************************************************************
 *   HOOK START
 **********************************************************************************************************/
/**
 * @param {{
 *      sectionDefinitionData: import('utilities/api/katana/types').KatanaAPI.Katana.Site.ServiceID.Meta.Sections.GET.SectionData;
 *      attributes: Record<string, any>;
 * }} params
 */
export function handleRecursiveAttributesCaveatController({ sectionDefinitionData, attributes }) {
    /**
     * @param {string[]} path
     */
    function removeValueRecursively(path = [], properties) {
        const newPath = [...path];
        const key = newPath.shift();
        if (!(key in properties)) {
            return;
        }

        const value = properties[key];

        if (Array.isArray(value)) {
            value.forEach((_value) => {
                removeValueRecursively(newPath, _value);
            });
        } else if (newPath.length) {
            removeValueRecursively(newPath, value);
        } else {
            delete properties[key];
        }
    }

    const modifiedProperties = cloneDeep(attributes.properties);
    /**
     *
     * @param { import('utilities/api/katana/types').KatanaAPI.Katana.Site.ServiceID.Meta.Sections.GET.SectionData['properties']} _properties
     * @param {string[]} path
     */
    function mapNewlyModifiedValues(_properties, path = []) {
        _properties.forEach((property) => {
            const hideOnSectionStyle = getCaveat(CaveatTypes.HIDE_ON_SECTION_STYLE, property.caveats);
            const shouldHideOnSectionStyle = hideOnSectionStyle?.includes(attributes.style);
            const newPath = [...path, property.key];

            if (shouldHideOnSectionStyle) {
                removeValueRecursively(newPath, modifiedProperties);
            }

            if ('properties' in property) {
                mapNewlyModifiedValues(property.properties, newPath);
            }
        });
    }

    mapNewlyModifiedValues(sectionDefinitionData.properties);

    const newAttributes = cloneDeep(attributes);
    delete newAttributes.properties;
    newAttributes.properties = modifiedProperties;

    return newAttributes;
}
/**********************************************************************************************************
 *   HOOK END
 **********************************************************************************************************/

import {
    MS365_INFORMATION_REQUEST,
    MS365_INFORMATION_SUCCESS,
    MS365_INFORMATION_ERROR,
    MS365_PRODUCT_CONFIG_REQUEST,
    MS365_PRODUCT_CONFIG_SUCCESS,
    MS365_PRODUCT_CONFIG_ERROR,
    MS365_UPGRADE_CALCULATE_REQUEST,
    MS365_UPGRADE_CALCULATE_SUCCESS,
    MS365_UPGRADE_CALCULATE_ERROR,
    MS365_UPGRADE_REQUEST,
    MS365_UPGRADE_SUCCESS,
    MS365_UPGRADE_ERROR,
    MS365_UPDATE_CONFIG_CALCULATE_REQUEST,
    MS365_UPDATE_CONFIG_CALCULATE_SUCCESS,
    MS365_UPDATE_CONFIG_CALCULATE_ERROR,
    RESET_MS365_UPDATE_CONFIG_CALCULATE,
    MS365_UPDATE_CONFIG_REQUEST,
    MS365_UPDATE_CONFIG_SUCCESS,
    MS365_UPDATE_CONFIG_ERROR,
    MS365_UPGRADE_PATHS_REQUEST,
    MS365_UPGRADE_PATHS_SUCCESS,
    MS365_UPGRADE_PATHS_ERROR
} from './action';

const initialState = {
    ms365_information_status: null,
    ms365_information_data: null,
    ms365_product_config_status: null,
    ms365_product_config_data: null,
    ms365_upgrade_calculate_status: null,
    ms365_upgrade_calculate_data: null,
    ms365_upgrade_status: null,
    ms365_upgrade_data: null,
    ms365_update_config_calculate_status: null,
    ms365_update_config_calculate_data: null,
    ms365_update_config_status: null,
    ms365_update_config_data: null,
    ms365_move_cancel_error: null,
    ms365_upgrade_paths_status: null,
    ms365_upgrade_paths_data: null
};

const ms365Reducer = (state = initialState, action) => {
    switch (action.type) {
        /**********************************************************************************************************
         *   MS365 SERVICE
         **********************************************************************************************************/
        case MS365_INFORMATION_REQUEST:
            return {
                ...state,
                ms365_information_status: 'loading',
                ms365_information_data: null
            };

        case MS365_INFORMATION_SUCCESS:
            return {
                ...state,
                ms365_information_status: 'success',
                ms365_information_data: action.ms365_information_data
            };

        case MS365_INFORMATION_ERROR:
            return {
                ...state,
                ms365_information_status: 'error'
            };

        /**********************************************************************************************************
         *   MS365 SERVICE
         **********************************************************************************************************/
        case MS365_PRODUCT_CONFIG_REQUEST:
            return {
                ...state,
                ms365_product_config_status: 'loading',
                ms365_product_config_data: null
            };

        case MS365_PRODUCT_CONFIG_SUCCESS:
            return {
                ...state,
                ms365_product_config_status: 'success',
                ms365_product_config_data: action.ms365_product_config_data
            };

        case MS365_PRODUCT_CONFIG_ERROR:
            return {
                ...state,
                ms365_product_config_status: 'error'
            };

        /**********************************************************************************************************
         *   MS365 UPGRADE CALCULATE
         **********************************************************************************************************/
        case MS365_UPGRADE_CALCULATE_REQUEST:
            return {
                ...state,
                ms365_upgrade_calculate_status: 'loading',
                ms365_upgrade_calculate_data: null
            };

        case MS365_UPGRADE_CALCULATE_SUCCESS:
            return {
                ...state,
                ms365_upgrade_calculate_status: 'success',
                ms365_upgrade_calculate_data: action.ms365_upgrade_calculate_data
            };

        case MS365_UPGRADE_CALCULATE_ERROR:
            return {
                ...state,
                ms365_upgrade_calculate_status: 'error'
            };

        /**********************************************************************************************************
         *   MS365 UPGRADE
         **********************************************************************************************************/
        case MS365_UPGRADE_REQUEST:
            return {
                ...state,
                ms365_upgrade_status: 'loading',
                ms365_upgrade_data: null
            };

        case MS365_UPGRADE_SUCCESS:
            return {
                ...state,
                ms365_upgrade_status: 'success',
                ms365_upgrade_data: action.ms365_upgrade_data
            };

        case MS365_UPGRADE_ERROR:
            return {
                ...state,
                ms365_upgrade_status: 'error'
            };

        /**********************************************************************************************************
         *   MS365 MS365 UPDATE CONFIG CALCULATE
         **********************************************************************************************************/
        case MS365_UPDATE_CONFIG_CALCULATE_REQUEST:
            return {
                ...state,
                ms365_update_config_calculate_status: 'loading',
                ms365_update_config_calculate_data: null
            };

        case MS365_UPDATE_CONFIG_CALCULATE_SUCCESS:
            return {
                ...state,
                ms365_update_config_calculate_status: 'success',
                ms365_update_config_calculate_data: action.ms365_update_config_calculate_data
            };

        case MS365_UPDATE_CONFIG_CALCULATE_ERROR:
            return {
                ...state,
                ms365_update_config_calculate_status: 'error'
            };

        case RESET_MS365_UPDATE_CONFIG_CALCULATE:
            return {
                ...state,
                ms365_update_config_calculate_status: null,
                ms365_update_config_calculate_data: null
            };

        /**********************************************************************************************************
         *   MS365 UPDATE CONFIG
         **********************************************************************************************************/
        case MS365_UPDATE_CONFIG_REQUEST:
            return {
                ...state,
                ms365_update_config_status: 'loading',
                ms365_update_config_data: null
            };

        case MS365_UPDATE_CONFIG_SUCCESS:
            return {
                ...state,
                ms365_update_config_status: 'success',
                ms365_update_config_data: action.ms365_update_config_data
            };

        case MS365_UPDATE_CONFIG_ERROR:
            return {
                ...state,
                ms365_update_config_status: 'error'
            };

        /**********************************************************************************************************
         *   MS365 UPGRADE PATHS
         **********************************************************************************************************/
        case MS365_UPGRADE_PATHS_REQUEST:
            return {
                ...state,
                ms365_upgrade_paths_status: 'loading',
                ms365_upgrade_paths_data: null
            };

        case MS365_UPGRADE_PATHS_SUCCESS:
            return {
                ...state,
                ms365_upgrade_paths_status: 'success',
                ms365_upgrade_paths_data: action.ms365_upgrade_paths_data
            };

        case MS365_UPGRADE_PATHS_ERROR:
            return {
                ...state,
                ms365_upgrade_paths_status: 'error'
            };

        default:
            return state;
    }
};

export default ms365Reducer;

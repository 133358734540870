/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import classNames from 'classnames';
import type { MouseEventHandler } from 'react';
import React from 'react';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import './__NXSelectOptions.scss';

/**********************************************************************************************************
 *   TYPE IMPORTS
 **********************************************************************************************************/
import type { SelectNamespace } from 'components/Form/Select/types';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const _NXSelectOptions: React.FC<SelectNamespace.Options.Props> = ({ value, options, onChange }) => {
    if (!options.length) {
        return null;
    }
    return options.map((option, index) => {
        const handleClick: MouseEventHandler<HTMLButtonElement> = (e) => {
            e.preventDefault();
            onChange(option.value);
        };

        const isSelected = option.value === value;

        return (
            <button
                key={index}
                onClick={handleClick}
                disabled={option.disabled}
                className={classNames('NXSelectOption', { 'NXSelectOption--isSelected': isSelected }, option.className)}
            >
                {option.icon} {option.label}
            </button>
        );
    });
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

import RequestLoader from 'components/Loaders/Request';
import { KatanaHookFormHandler } from 'containers/katana/components/HookFormHandler';
import { contactInformationZodSchema } from 'containers/katana/containers/ContentEditorLightbox/formHandlers/contactInformationFormHandler/consts';
import { useContactInformationFormValues } from 'containers/katana/containers/ContentEditorLightbox/formHandlers/contactInformationFormHandler/useContactInformationFormValues';
import { useHandleContactInformationSubmit } from 'containers/katana/containers/ContentEditorLightbox/formHandlers/contactInformationFormHandler/useHandleContactInformationSubmit';
import type { KatanaNamespace } from 'containers/katana/types';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type Props = {
    subpageRouteData: KatanaNamespace.SubPageRouteData;
};

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const ContactInformationFormHandler: React.FC<Props> = ({ subpageRouteData }) => {
    /***** HOOKS *****/
    const defaultValues = useContactInformationFormValues(subpageRouteData);
    const handleBasicDetailsSubmit = useHandleContactInformationSubmit(subpageRouteData);

    /***** RENDER *****/
    if (!defaultValues) {
        return <RequestLoader message="Loading Contact Information..." />;
    }

    return (
        <KatanaHookFormHandler defaultValues={defaultValues} zodSchema={contactInformationZodSchema} handleOnSubmit={handleBasicDetailsSubmit}>
            {subpageRouteData.modules}
        </KatanaHookFormHandler>
    );
};

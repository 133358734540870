/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { get as _get } from 'lodash';
import React, { useEffect, useRef } from 'react';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { useTanstackStore } from 'utilities/hooks/tanstack-store/useTanstackStore';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import { SUPER_ACTIONS_KEYS, superUserSuperActionsState } from 'components/StaffMenu/SuperUser/consts';

const DVDpalette = ['#ff8800', '#e124ff', '#6a19ff', '#ff2188'];

const newDVDSTate = () => ({
    x: 0,
    y: 0,
    dirX: 1,
    dirY: 1,
    prevColorChoiceIndex: 0,
    speed: 1,
    animationID: Date.now(),
    lastTime: Date.now(),
    backgroundColour: DVDpalette[0]
});

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
const _SuperUserDVD = () => {
    /***** HOOKS *****/
    const [superUserActions] = useTanstackStore(superUserSuperActionsState);

    /** @type {React.MutableRefObject<HTMLDivElement | null>} */
    const dvdBackgroundRef = useRef(null);
    /** @type {React.MutableRefObject<HTMLDivElement | null>} */
    const dvdDVDRef = useRef(null);

    const dvdState = useRef(newDVDSTate());

    const canShowDVDAction = _get(superUserActions, SUPER_ACTIONS_KEYS.CONTROLSHIFTDVD);

    /***** FUNCTIONS *****/
    function getNewRandomColor() {
        const currentPalette = [...DVDpalette];
        currentPalette.splice(dvdState.current.prevColorChoiceIndex, 1);
        const colorChoiceIndex = Math.floor(Math.random() * currentPalette.length);
        dvdState.current.prevColorChoiceIndex = colorChoiceIndex < dvdState.current.prevColorChoiceIndex ? colorChoiceIndex : colorChoiceIndex + 1;
        const choice = currentPalette[colorChoiceIndex];
        dvdState.current.backgroundColour = choice;
        return choice;
    }

    /***** FUNCTIONS *****/
    /**
     * @param {number} animationID
     */
    function animate(animationID) {
        const currentTime = Date.now();
        const diff = currentTime - dvdState.current.lastTime;
        const dt = diff / (1000 / 60);
        dvdState.current.lastTime = currentTime;

        if (
            dvdState.current.animationID !== animationID ||
            !_get(superUserSuperActionsState.state, SUPER_ACTIONS_KEYS.CONTROLSHIFTDVD) ||
            !dvdBackgroundRef.current ||
            !dvdDVDRef.current
        ) {
            return;
        }

        const dvd = dvdDVDRef.current;

        const dvdBackgroundRect = dvdBackgroundRef.current.getBoundingClientRect();
        const dvdRect = dvd.getBoundingClientRect();
        const screenHeight = dvdBackgroundRect.height;
        const screenWidth = dvdBackgroundRect.width;

        if (dvdState.current.y + dvdRect.height >= screenHeight) {
            dvdState.current.y = screenHeight - dvdRect.height;
            dvdState.current.dirY *= -1;
            dvd.style.backgroundColor = getNewRandomColor();
        }
        if (dvdState.current.y < 0) {
            dvdState.current.y = 0;
            dvdState.current.dirY *= -1;
            dvd.style.backgroundColor = getNewRandomColor();
        }

        if (dvdState.current.x + dvdRect.width >= screenWidth) {
            dvdState.current.dirX *= -1;
            dvdState.current.x = screenWidth - dvdRect.width;
            dvd.style.backgroundColor = getNewRandomColor();
        } else if (dvdState.current.x < 0) {
            dvdState.current.dirX *= -1;
            dvdState.current.x = 0;
            dvd.style.backgroundColor = getNewRandomColor();
        }

        dvdState.current.x += dvdState.current.dirX * dvdState.current.speed * dt;
        dvdState.current.y += dvdState.current.dirY * dvdState.current.speed * dt;
        dvd.style.left = `${dvdState.current.x}px`;
        dvd.style.top = `${dvdState.current.y}px`;
        window.requestAnimationFrame(() => animate(animationID));
    }

    /***** EFFECTS *****/
    useEffect(() => {
        if (!(canShowDVDAction && dvdBackgroundRef?.current && dvdDVDRef?.current)) {
            return;
        }

        dvdState.current.animationID = Date.now();
        window.requestAnimationFrame(() => animate(dvdState.current.animationID));
    }, [superUserActions, dvdBackgroundRef?.current, dvdDVDRef?.current]);

    /***** RENDER *****/
    if (!canShowDVDAction) {
        return;
    }

    return (
        <div
            ref={dvdBackgroundRef}
            style={{ position: 'absolute', zIndex: 100, left: '0px', top: '0px', width: '100%', height: '100%', backgroundColor: '#111' }}
        >
            <div
                ref={dvdDVDRef}
                style={{
                    position: 'absolute',
                    left: '0px',
                    top: '0px',
                    height: '50px',
                    width: '100px',
                    mask: 'url("https://upload.wikimedia.org/wikipedia/commons/9/9b/DVD_logo.svg")',
                    WebkitMask: 'url("https://upload.wikimedia.org/wikipedia/commons/9/9b/DVD_logo.svg")',
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: '75px',
                    backgroundPosition: 'center',
                    backgroundColor: dvdState.current.backgroundColour
                }}
            />
        </div>
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
export { _SuperUserDVD };

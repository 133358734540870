/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import classNames from 'classnames';
import React from 'react';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import Anchor from 'components/Anchor';
import BackgroundImage from 'components/BackgroundImage';
import SolidButton from 'components/Buttons/SolidButton';
import DealsCarousel from 'components/Promo/Carousel/Deals';
import Text from 'components/Utils/Text';

/**********************************************************************************************************
 *   QUERIES
 **********************************************************************************************************/
import { usePromotionAllServicesQuery } from 'config/containers/promotions/queries';
import { megaMayAllServiesQueryRequiredServiceTypes } from '../../queries';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { getPurchaseLink } from 'containers/services/consts';
import {
    checkAtLeastOneEligibleService,
    isHostingEligibleForRenewDiscount,
    isHostingEligibleForUpgradeDiscount,
    isVpsEligibleForRenewDiscount
} from '../../methods';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import Logo from '../../assets/dashboard-banner-logo.svg';
import Pattern from '../../assets/pattern.png';
import TenOff from '../../assets/percent-off/10off.svg';
import ThirtyOff from '../../assets/percent-off/30off.svg';
import FiftyOff from '../../assets/percent-off/50off.svg';
import SixteenSeventyFive from '../../assets/prices/1675.svg';
import OneNintyTwo from '../../assets/prices/192.svg';
import TwoHundredSomething from '../../assets/prices/23752.svg';
import ThirtyNineNintySix from '../../assets/prices/3996.svg';
import FourTwenty from '../../assets/prices/420.svg';
import FourEighty from '../../assets/prices/480.svg';
import SixtyThreeNintySix from '../../assets/prices/6396.svg';
import StarterGuide from '../../assets/starter-guide.svg';
import Strip from '../../assets/strip.svg';
import Sunset from '../../assets/sunset.svg';
import TagGreen from '../../assets/tag-green.svg';
import Tag from '../../assets/tag-white.svg';
import TapeBlue from '../../assets/tape-blue.svg';

import './_megamay24Deals.scss';

export const megaMayBlogPostLink = 'https://ventraip.com.au/blog/megamay-2024/';
export const megaMayPermitNumbers = 'NSW Authority No. TP/03444. ACT Permit No. TP 24/00854. SA Licence No. T24/623.';
export const megamay24UtmCampaign = 'megamay2024';
export const megamay24UtmSource = 'ccp';

function getDeals(promotion_all_services_data) {
    return [
        {
            id: 'business_hosting',
            title: (
                <>
                    Business
                    <br />
                    Web Hosting
                </>
            ),
            prePrice: {
                start: null,
                middle: null,
                end: 'Starting from'
            },
            price: <img className="mmDeals__cardPrice" src={FourTwenty} alt="$4.20" />,
            postPrice: '/month*',
            desc: 'Perfect for any type of business website.',
            button: (
                <SolidButton
                    className="mmDeals__cardButton"
                    type="anchor"
                    href={getPurchaseLink(
                        null,
                        { utmMedium: 'tile-business-hosting', utmCampaign: megamay24UtmCampaign, utmSource: megamay24UtmSource },
                        'https://ventraip.com.au/web-hosting/business-hosting/'
                    )}
                    target="_blank"
                >
                    Buy Now
                </SolidButton>
            ),
            flags: null,
            condition: true
        },
        {
            id: 'worpress_hosting',
            title: (
                <>
                    WordPress
                    <br />
                    Web Hosting
                </>
            ),
            prePrice: {
                start: null,
                middle: null,
                end: 'Starting from'
            },
            price: <img className="mmDeals__cardPrice" src={FourEighty} alt="$4.80" />,
            postPrice: '/month*',
            desc: 'Amazing for WordPress and WooCommerce websites.',
            button: (
                <SolidButton
                    className="mmDeals__cardButton"
                    type="anchor"
                    href={getPurchaseLink(
                        null,
                        { utmMedium: 'tile-wordpress-hosting', utmCampaign: megamay24UtmCampaign, utmSource: megamay24UtmSource },
                        'https://ventraip.com.au/web-hosting/wordpress-hosting/'
                    )}
                    target="_blank"
                >
                    Buy Now
                </SolidButton>
            ),
            flags: null,
            condition: true
        },
        {
            id: 'custom_hosting',
            title: (
                <>
                    Custom
                    <br />
                    Web Hosting
                </>
            ),
            prePrice: {
                start: null,
                middle: null,
                end: 'Starting from'
            },
            price: <img className="mmDeals__cardPrice" src={FourTwenty} alt="$4.20" />,
            postPrice: '/month*',
            desc: 'Fully customizable hosting, ideal for all websites.',
            button: (
                <SolidButton
                    className="mmDeals__cardButton"
                    type="anchor"
                    href={getPurchaseLink(
                        null,
                        { utmMedium: 'tile-custom-hosting', utmCampaign: megamay24UtmCampaign, utmSource: megamay24UtmSource },
                        'https://ventraip.com.au/web-hosting/custom-hosting/'
                    )}
                    target="_blank"
                >
                    Buy Now
                </SolidButton>
            ),
            flags: null,
            condition: true
        },
        {
            id: 'hosting_plan_recommender',
            title: (
                <>
                    Hosting Plan
                    <br />
                    Recommender
                </>
            ),
            prePrice: null,
            price: <img className="mmDeals__cardPrice mmDeals__cardPrice--picture" src={StarterGuide} alt="Starter guide" />,
            postPrice: null,
            desc: 'Launch your latest idea with our customized hosting plan recommender.',
            button: (
                <SolidButton
                    className="mmDeals__cardButton"
                    type="anchor"
                    href={`https://ventraip.com.au//web-hosting/?flow=wizard&utm_source=${megamay24UtmSource}&utm_medium=tile-hosting-plan-recommender&utm_campaign=${megamay24UtmCampaign}`}
                    target="_blank"
                >
                    Start with a guide
                </SolidButton>
            ),
            flags: {
                biggerDesc: true
            },
            condition: true
        },
        {
            id: 'hosting_renewals',
            title: (
                <>
                    Web Hosting
                    <br />
                    Renewals
                </>
            ),
            prePrice: null,
            price: (
                <div className="mmDeals__cardPriceContainer">
                    <img className="mmDeals__cardPrice mmDeals__cardPrice--bigger" src={ThirtyOff} alt="30% off" />
                    <Text white size--l lead--0>
                        *
                    </Text>
                </div>
            ),
            postPrice: null,
            desc: 'Keep a good thing going by renewing your web hosting service.',
            button: (
                <SolidButton
                    className="mmDeals__cardButton"
                    to={getPurchaseLink(
                        null,
                        { utmMedium: 'tile-web-hosting-renewals', utmCampaign: megamay24UtmCampaign, utmSource: megamay24UtmSource },
                        '/my-services/hosting'
                    )}
                >
                    Renew Now
                </SolidButton>
            ),
            flags: null,
            condition: checkAtLeastOneEligibleService(promotion_all_services_data?.hosting, isHostingEligibleForRenewDiscount)
        },
        {
            id: 'hosting_upgrades',
            title: (
                <>
                    Web Hosting
                    <br />
                    Upgrades
                </>
            ),
            prePrice: null,
            price: (
                <div className="mmDeals__cardPriceContainer">
                    <img className="mmDeals__cardPrice mmDeals__cardPrice--bigger" src={FiftyOff} alt="50% off" />
                    <Text white size--l lead--0>
                        *
                    </Text>
                </div>
            ),
            postPrice: null,
            desc: "Upgrade today to boost your website's resources",
            button: (
                <SolidButton
                    className="mmDeals__cardButton"
                    to={getPurchaseLink(
                        null,
                        { utmMedium: 'tile-web-hosting-upgrades', utmCampaign: megamay24UtmCampaign, utmSource: megamay24UtmSource },
                        '/my-services/hosting'
                    )}
                >
                    Upgrade Now
                </SolidButton>
            ),
            flags: null,
            condition: checkAtLeastOneEligibleService(promotion_all_services_data?.hosting, isHostingEligibleForUpgradeDiscount)
        },
        {
            id: 'com_domains',
            title: (
                <>
                    .com
                    <br />
                    Domains
                </>
            ),
            prePrice: {
                start: 'Was',
                middle: '$21.95/yr',
                end: 'Now'
            },
            price: <img className="mmDeals__cardPrice" src={SixteenSeventyFive} alt="$16.75" />,
            postPrice: '/yr*',
            desc: "The world's most popular web address.",
            button: (
                <SolidButton
                    className="mmDeals__cardButton"
                    type="anchor"
                    href={getPurchaseLink(
                        null,
                        { utmMedium: 'tile-tld-com', utmCampaign: megamay24UtmCampaign, utmSource: megamay24UtmSource },
                        'https://ventraip.com.au/domain-names/extensions/com/'
                    )}
                    target="_blank"
                >
                    Buy Now
                </SolidButton>
            ),
            flags: null,
            condition: true
        },
        {
            id: 'com_au',
            title: (
                <>
                    .com.au
                    <br />
                    Domains
                </>
            ),
            prePrice: {
                start: null,
                middle: null,
                end: 'Up to'
            },
            price: (
                <div className="mmDeals__cardPriceContainer">
                    <img className="mmDeals__cardPrice mmDeals__cardPrice--bigger" src={FiftyOff} alt="50% off" />
                    <Text white size--l lead--0>
                        *
                    </Text>
                </div>
            ),
            postPrice: null,
            desc: "Australia's best-selling domain name.",
            button: (
                <SolidButton
                    className="mmDeals__cardButton"
                    type="anchor"
                    href={getPurchaseLink(
                        null,
                        { utmMedium: 'tile-tld-com-au', utmCampaign: megamay24UtmCampaign, utmSource: megamay24UtmSource },
                        'https://ventraip.com.au/domain-names/extensions/com-au/'
                    )}
                    target="_blank"
                >
                    Buy Now
                </SolidButton>
            ),
            flags: null,
            condition: true
        },
        {
            id: 'direct_au',
            title: (
                <>
                    .au Direct
                    <br />
                    Domains
                </>
            ),
            prePrice: {
                start: null,
                middle: null,
                end: 'Up to'
            },
            price: (
                <div className="mmDeals__cardPriceContainer">
                    <img className="mmDeals__cardPrice mmDeals__cardPrice--bigger" src={FiftyOff} alt="50% off" />
                    <Text white size--l lead--0>
                        *
                    </Text>
                </div>
            ),
            postPrice: null,
            desc: "Australia's newest domain name.",
            button: (
                <SolidButton
                    className="mmDeals__cardButton"
                    type="anchor"
                    href={getPurchaseLink(
                        null,
                        { utmMedium: 'tile-tld-au', utmCampaign: megamay24UtmCampaign, utmSource: megamay24UtmSource },
                        'https://ventraip.com.au/domain-names/extensions/au/'
                    )}
                    target="_blank"
                >
                    Buy Now
                </SolidButton>
            ),
            flags: null,
            condition: true
        },
        {
            id: 'email_hosting',
            title: (
                <>
                    Email
                    <br />
                    Hosting
                </>
            ),
            prePrice: {
                start: null,
                middle: null,
                end: 'Starting from'
            },
            price: <img className="mmDeals__cardPrice" src={OneNintyTwo} alt="$1.92" />,
            postPrice: '/month*',
            desc: 'A custom mailbox with free spam and virus filtering.',
            button: (
                <SolidButton
                    className="mmDeals__cardButton"
                    type="anchor"
                    href={getPurchaseLink('Email Hosting', {
                        utmMedium: 'tile-email-hosting',
                        utmCampaign: megamay24UtmCampaign,
                        utmSource: megamay24UtmSource
                    })}
                    target="_blank"
                >
                    Buy Now
                </SolidButton>
            ),
            flags: null,
            condition: true
        },
        {
            id: 'ssl',
            title: (
                <>
                    SSL
                    <br />
                    Certificates
                </>
            ),
            prePrice: {
                start: null,
                middle: null,
                end: 'Starting from'
            },
            price: <img className="mmDeals__cardPrice mmDeals__cardPrice--smaller" src={ThirtyNineNintySix} alt="$39.96" />,
            postPrice: '/year*',
            desc: "Increase sales and boost your website's ranking.",
            button: (
                <SolidButton
                    className="mmDeals__cardButton"
                    type="anchor"
                    href={getPurchaseLink('SSL Certificates', {
                        utmMedium: 'tile-ssl-certificates',
                        utmCampaign: megamay24UtmCampaign,
                        utmSource: megamay24UtmSource
                    })}
                    target="_blank"
                >
                    Buy Now
                </SolidButton>
            ),
            flags: null,
            condition: true
        },
        {
            id: 'sm_vps',
            title: (
                <>
                    Self-Managed
                    <br />
                    VPS
                </>
            ),
            prePrice: {
                start: null,
                middle: null,
                end: 'Starting from'
            },
            price: <img className="mmDeals__cardPrice mmDeals__cardPrice--smaller" src={SixtyThreeNintySix} alt="$63.96" />,
            postPrice: '/month*',
            desc: 'The ultimate speed, reliability and freedom.',
            button: (
                <SolidButton
                    className="mmDeals__cardButton"
                    type="anchor"
                    href={getPurchaseLink(
                        null,
                        { utmMedium: 'tile-sm-vps', utmCampaign: megamay24UtmCampaign, utmSource: megamay24UtmSource },
                        'https://ventraip.com.au/server/self-managed-vps/'
                    )}
                    target="_blank"
                >
                    Buy Now
                </SolidButton>
            ),
            flags: null,
            condition: true
        },
        {
            id: 'fm_vps',
            title: (
                <>
                    Fully-Managed
                    <br />
                    VPS
                </>
            ),
            prePrice: {
                start: null,
                middle: null,
                end: 'Starting from'
            },
            price: <img className="mmDeals__cardPrice mmDeals__cardPrice--smallest" src={TwoHundredSomething} alt="$237.52" />,
            postPrice: '/month*',
            desc: "Australia's #1 SSD Virtual Private Server.",
            button: (
                <SolidButton
                    className="mmDeals__cardButton"
                    type="anchor"
                    href={getPurchaseLink('VPS', { utmMedium: 'tile-fm-vps', utmCampaign: megamay24UtmCampaign, utmSource: megamay24UtmSource })}
                    target="_blank"
                >
                    Buy Now
                </SolidButton>
            ),
            flags: null,
            condition: true
        },
        {
            id: 'vps_renewals',
            title: (
                <>
                    VPS
                    <br />
                    Renewals
                </>
            ),
            prePrice: null,
            price: (
                <div className="mmDeals__cardPriceContainer">
                    <img className="mmDeals__cardPrice mmDeals__cardPrice--bigger" src={TenOff} alt="10% off" />
                    <Text white size--l lead--0>
                        *
                    </Text>
                </div>
            ),
            postPrice: null,
            desc: 'Keep your VPS running smoothly with a renewal.',
            button: (
                <SolidButton
                    className="mmDeals__cardButton"
                    to={getPurchaseLink(
                        null,
                        { utmMedium: 'tile-vps-renewals', utmCampaign: megamay24UtmCampaign, utmSource: megamay24UtmSource },
                        '/my-services/vps'
                    )}
                >
                    Renew Now
                </SolidButton>
            ),
            flags: null,
            condition: checkAtLeastOneEligibleService(promotion_all_services_data?.vps, isVpsEligibleForRenewDiscount)
        }
    ];
}

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export function MegaMay24Deals({ promoDealsRef }) {
    /***** QUERIES *****/
    const { data: promotion_all_services_data } = usePromotionAllServicesQuery(megaMayAllServiesQueryRequiredServiceTypes);

    /***** RENDER HELPERS *****/
    function renderCards() {
        return getDeals(promotion_all_services_data)
            .filter(({ condition }) => condition)
            .map(({ id, title, prePrice, price, postPrice, desc, button, flags }) => {
                return (
                    <div key={id} className="mmDeals__card">
                        <BackgroundImage className="mmDeals__cardPattern" imageURL={Pattern} cover />
                        <div className="mmDeals__cardGradient" />
                        <div className="mmDeals__cardContent">
                            <Text className="mmDeals__cardTitle" white lead--1 align--center>
                                {title}
                            </Text>
                            <div className="mmDeals__cardCenter">
                                {prePrice ? (
                                    <Text className="mmDeals__cardPrePrice" white lead--1 align--center>
                                        <small>
                                            {prePrice.start} <del>{prePrice.middle}</del>
                                        </small>{' '}
                                        <span>{prePrice.end}</span>
                                    </Text>
                                ) : (
                                    ''
                                )}
                                {price}
                                {postPrice ? (
                                    <Text className="mmDeals__cardPostPrice" white lead--1 align--right>
                                        {postPrice}
                                    </Text>
                                ) : (
                                    ''
                                )}
                            </div>
                            <Text
                                className={classNames('mmDeals__cardDesc', { 'mmDeals__cardDesc--bigger': !!flags?.biggerDesc })}
                                white
                                lead--1
                                align--center
                            >
                                {desc}
                            </Text>
                            {button}
                        </div>
                    </div>
                );
            });
    }

    /***** RENDER *****/
    return (
        <div className="mmDeals" ref={promoDealsRef}>
            <div className="mmDeals__background">
                <img className="mmDeals__sunset" src={Sunset} alt="Sunset" />
                <img className="mmDeals__strip" src={Strip} alt="Decorative section" />
            </div>

            <div className="mmDeals__content">
                <img className="mmDeals__mainLogo" src={Logo} alt="MegaMay Spin to Win" />
                <div className="mmDeals__tags">
                    <div className="mmDeals__tag">
                        <img className="mmDeals__tagBackground" src={Tag} alt="Background tag" />
                        <Text className="mmDeals__tagTitle" align--center>
                            Pay Any Invoice to Spin
                        </Text>
                        <Text size--s black bold lead--xs align--center>
                            Pay an invoice during May for your
                            <br /> chance to spin the wheel!*
                        </Text>
                    </div>
                    <div className="mmDeals__tag">
                        <img className="mmDeals__tagBackground" src={TagGreen} alt="Background tag" />
                        <div className="mmDeals__megaPrizeDrawBackground">
                            <img src={TapeBlue} alt="Background tape" />
                            <Text white size--s>
                                MEGA Draw Prize
                            </Text>
                        </div>
                        <Text className="mmDeals__tagTitle" align--center>
                            Win a Trip to Fiji
                        </Text>
                        <Text size--s black bold lead--xs align--center>
                            5-night stay including flights,
                            <br /> transfers and spending money!*
                        </Text>
                    </div>
                    <div className="mmDeals__tag">
                        <img className="mmDeals__tagBackground" src={Tag} alt="Background tag" />
                        <Text className="mmDeals__tagTitle" align--center>
                            MEGA Savings
                        </Text>
                        <Text size--s black bold lead--xs align--center>
                            Huge discounts on new and existing
                            <br /> services all month long!*
                        </Text>
                    </div>
                </div>
                <div className="mmDeals__deals">
                    <DealsCarousel cards={renderCards()} />
                </div>
                <Text white size--s align--center className="mmDeals__tandc">
                    *Full Terms and Conditions apply. Check out our{' '}
                    <Anchor href={megaMayBlogPostLink} target="_blank">
                        blog post
                    </Anchor>{' '}
                    for more information. Australian residents 18+. Starts: 01/05/2024. Ends 11:59 PM (AEST) 31/05/2024.
                    <br /> {megaMayPermitNumbers}
                </Text>
            </div>
        </div>
    );
}
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

import type { TruthyProp } from 'utilities/consts';

type NXCardActionsAppliedStylingProps = {
    /**
     * Applies flex direction column
     */
    'column': TruthyProp;

    /**
     * Applies align-items flex-start
     */
    'align--start': TruthyProp;

    /**
     * Justify content center
     */
    'center': TruthyProp;

    /**
     * Sets default gap for content
     */
    'gap': TruthyProp;

    'fullWidth': TruthyProp;
};

export type NXCardActions = NXCardActionsAppliedStylingProps & {
    children: React.ReactNode | React.ReactElement;
    className?: string;
    onClick?: () => void;
    fullWidth?: boolean;
};

export const NXCardActionsAppliedStylingPropTypeKeys: Array<keyof NXCardActionsAppliedStylingProps> = [
    'column',
    'align--start',
    'center',
    'gap',
    'fullWidth'
];

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { createModuleStore } from 'utilities/methods/createModuleStore';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
export const SSLModuleStore = createModuleStore({
    manage: {
        overview: {
            enabled: true,
            label: 'Overview',
            to: '/my-services/ssl/$sslId/manage',
            hash: 'overview'
        },
        install: {
            enabled: true,
            label: 'Install SSL',
            to: '/my-services/ssl/$sslId/manage',
            hash: 'install'
        },
        details: {
            enabled: true,
            label: 'SSL Details',
            to: '/my-services/ssl/$sslId/manage',
            hash: 'details'
        }
    }
});

/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import classNames from 'classnames';
import { useMemo } from 'react';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import { HookFormButton } from 'components/Form/Button/hookForm';
import { Flex } from 'components/Utils/Flex';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import { ValidFormSubmitButtonContent } from 'containers/katana/containers/contentEditorModules/validFormSubmitButton/ValidFormSubmitButtonContent';
import { ValidFormSubmitButtonDeleteSectionButton } from 'containers/katana/containers/contentEditorModules/validFormSubmitButton/ValidFormSubmitButtonDeleteSectionButton';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { useHandleSubmitButtonEvent } from 'containers/katana/containers/contentEditorModules/validFormSubmitButton/handleDisabledSubmitButtonClick';
import { useKatanaParams } from 'containers/katana/hooks/useSetupEditorRouteParams';

/**********************************************************************************************************
 *   TYPE IMPORTS
 **********************************************************************************************************/
import type { KatanaNamespace } from 'containers/katana/types';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import { katanaServicePages } from 'containers/katana/consts';
const { EDITOR } = katanaServicePages;

const launchWebsiteSubpages: KatanaNamespace.SubPages[] = ['launch', 'fix-dns'];

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type Props = {
    ignorePristine?: boolean;
};

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const ValidFormSubmitButtonHookForm: React.FC<Props> = ({ ignorePristine }) => {
    /***** HOOKS *****/
    const { page, subpage } = useKatanaParams();
    const handleOnDisabledClick = useHandleSubmitButtonEvent();

    /***** RENDER HELPERS *****/
    const colour = useMemo(() => {
        if (subpage && launchWebsiteSubpages.includes(subpage)) {
            return null;
        }
        return page === EDITOR.to ? 'confirm' : 'primary';
    }, [page, subpage]);

    /***** RENDER *****/
    return (
        <Flex
            direction="column"
            gap={5}
            className={classNames('ValidFormSubmitButton', {
                [`ValidFormSubmitButton--color-${colour}`]: colour
            })}
        >
            <HookFormButton onDisabledClick={handleOnDisabledClick} color={colour} ignorePristine={subpage !== 'launch' || ignorePristine}>
                <ValidFormSubmitButtonContent />
            </HookFormButton>
            <ValidFormSubmitButtonDeleteSectionButton />
        </Flex>
    );
};

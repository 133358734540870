/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import classNames from 'classnames';
import React from 'react';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * Handles spanning over the grid area, horizontally only because each row is technically a separate grid.
 * @type {React.FC<{
 *      children: import('react').ReactNode | import('react').ReactNode[];
 *      span?: number;
 *      start?: number;
 *      className?: string;
 * }>}
 */
const GridSpan = ({ children, span, start, className }) => {
    /***** RENDER HELPERS *****/
    const styling = {};
    if (start) {
        styling.gridColumnStart = start;
    }
    if (span) {
        styling.gridColumn = `span ${span}`;
    }

    const spanClassNames = classNames('Span', className);
    /***** RENDER *****/
    return (
        <div className={spanClassNames} style={styling}>
            {children}
        </div>
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

export default GridSpan;

/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { Navigate, Outlet, createRoute } from '@tanstack/react-router';
import { useStore } from '@tanstack/react-store';

/**********************************************************************************************************
 *   ROUTER IMPORTS
 **********************************************************************************************************/
import { AppRoute } from 'App';
import { routerMiddleware } from 'router/utils/middleware';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import SideBarPage from 'components/SideBarPage';
import Transition from 'components/Transition';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { AccountModuleStore } from 'containers/account/moduleConditions';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import './_account.scss';

/*   ACTIONS
 *****************************************************/
import { pushNotification } from 'components/Toast/functions';
import { checkIsAccountHolder } from 'containers/account/methods';
import { useUserData } from 'utilities/hooks/redux/useUserData';

/**********************************************************************************************************
 *   ROUTE START
 **********************************************************************************************************/
export const AccountRoute = createRoute({
    getParentRoute: () => AppRoute,
    path: 'account',
    beforeLoad(opts) {
        routerMiddleware.business(this, opts);
        routerMiddleware.authentication('user', opts);
    },
    component: AccountRouteComponent
});

/**********************************************************************************************************
 *   ROUTE COMPONENT START
 **********************************************************************************************************/
function AccountRouteComponent() {
    /***** HOOKS *****/
    const store = useStore(AccountModuleStore);
    const app_user_data = useUserData();

    AccountModuleStore.useConditions();

    /***** RENDER HELPERS *****/
    const [links] = SideBarPage.useGetSidebarLinks([
        {
            list_title: null,
            list_items: store.general
        },
        {
            list_title: 'Security',
            list_icon: 'lock',
            list_items: store.security
        },
        {
            list_title: 'Emails and Notifications',
            list_icon: 'email',
            list_items: store.emails
        }
    ]);

    if (!checkIsAccountHolder(app_user_data)) {
        pushNotification({ status: 401, details: 'As an additional user you are not authorized to view this resource.' });
        return <Navigate to="/dashboard" />;
    }

    /***** RENDER *****/
    return (
        <SideBarPage title="My Profile" icon="account" sidebarList={links}>
            <Transition>
                <Outlet />
            </Transition>
        </SideBarPage>
    );
};

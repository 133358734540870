/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import Text from 'components/Utils/Text';
import { ProgressStepsNode, nodeStates } from './Node';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import './_ProgressSteps.scss';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type ProgressStepsProps = {
    /**
     * A list of steps to show. Currently has to be exactly length 2, due to the label positioning
     */
    steps: Array<{
        /**
         * Step label to show
         */
        label: string;

        /**
         * Unique identifier for the step
         */
        name: string;

        /**
         * Whether or not the step has been completed
         */
        isComplete: boolean;
    }>;
};

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * Indicated how many "steps" require completion in a given process, and highlights which steps are completed and which step is the "current" step.
 */
export const ProgressSteps: React.FC<ProgressStepsProps> = ({ steps }) => {
    if (steps.length !== 2)
        throw new Error(
            'Currently this component can only have 2 steps, as the labels are positioned on the side. It needs to be modified if we need more than 2 steps.'
        );

    /***** RENDER HELPERS *****/
    const renderLabel = (stepIndex) => {
        const isCurrent = currentStepIndex === stepIndex;

        return (
            <Text className="ProgressSteps__label" size--s medium primary={isCurrent} black={!isCurrent}>
                {steps[stepIndex]?.label || ''}
            </Text>
        );
    };

    const getStepStatus = (stepIndex) => {
        if (steps[stepIndex]?.isComplete) return nodeStates.COMPLETE;
        if (currentStepIndex === stepIndex) return nodeStates.IN_PROGRESS;
        return nodeStates.NOT_STARTED;
    };

    const currentStepIndex = steps.findIndex(({ isComplete }) => !isComplete);

    /***** RENDER *****/
    return (
        <div className="ProgressSteps">
            <div className="ProgressSteps__content">
                {renderLabel(0)}
                <div className="ProgressSteps__line" />
                <ProgressStepsNode state={getStepStatus(0)} />
                <ProgressStepsNode state={getStepStatus(1)} />
                {renderLabel(1)}
            </div>
        </div>
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

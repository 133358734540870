export const backgroundColours = /** @type {const} */ ([
    'primary',
    'secondary',
    'secondary-alt',
    'black',
    'medium-gray',
    'light-gray',
    'white',
    'grey',
    'confirm',
    'warn',
    'info',
    'notice'
]);

export const backgroundColourAppliedStylingKeys = [...backgroundColours, 'inject', 'borderRadius'];

/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import classNames from 'classnames';
import React from 'react';
import type { WrappedFieldProps } from 'redux-form';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import { NXImage } from 'components/NXImage';
import SolidTag from 'components/Tags/SolidTag';
import { NXSquare } from 'components/Utils/NXSquare';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import { ComplexLabel } from 'containers/katana/components/complexLabel/ComplexLabel';
import { CopyValueOnClickToTarget } from 'containers/katana/components/copyValueOnClickToTarget';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { useGetKatanaFileUploadValue } from 'components/Form/NXDropZone/wrappers/withKatanaFileUpload/useGetKatanaFileUploadValue';
import { useGetCaveat } from 'containers/katana/formFields/methods/useGetCaveat';
import { getNestedKeyListIndex } from 'containers/katana/formFields/repeated/methods/getNestedKeyListIndex';
import getYoutubeThumbnailLink from 'utilities/methods/getYouTubeThumbnailLink/getYouTubeThumbnailLink';
import { isAttachmentDataEmbed } from 'containers/katana/queries/methods/attachmentData';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import { CaveatTypes } from 'containers/katana/formFields/caveatTypes';
import './_DisplayOnlyWrapper.scss';

/**********************************************************************************************************
 *   TYPE IMPORTS
 **********************************************************************************************************/
import type { NXDropZoneNamespace } from 'components/Form/NXDropZone/types';
import type { KatanaNamespace } from 'containers/katana/types';

type DisplayOnlyWrapperProps = {
    input: NXDropZoneNamespace.ReduxForm.WithKatanaFileUpload.InputProp;
    property: KatanaNamespace.SectionDefinitions.PropertiesModified;
} & WrappedFieldProps;

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/

export const DisplayOnlyWrapper: React.FC<DisplayOnlyWrapperProps> = ({ input, property }) => {
    const { value, name } = input;
    const { originalKey } = property;

    /***** HOOKS *****/
    const katanaFileUploadResult = useGetKatanaFileUploadValue(value);
    const imageURL = isAttachmentDataEmbed(value) ? getYoutubeThumbnailLink(katanaFileUploadResult) : katanaFileUploadResult;
    const firstItemInListLabel = useGetCaveat(CaveatTypes.FIRST_ITEM_IN_LIST_LABEL_CAVEAT, property.caveats);
    const listIndex = firstItemInListLabel ? getNestedKeyListIndex(name, originalKey) : null;

    /***** RENDER HELPERS *****/
    return (
        <CopyValueOnClickToTarget property={property}>
            <ComplexLabel property={property}>
                <NXSquare className={classNames('FileFormFieldDisplayOnlyWrapper')}>
                    <NXImage.WithLoader cover src={imageURL} />
                    {listIndex === 0 && <SolidTag color="black">{firstItemInListLabel}</SolidTag>}
                </NXSquare>
            </ComplexLabel>
        </CopyValueOnClickToTarget>
    );
};

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React from 'react';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import Anchor from 'components/Anchor';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { serviceNav } from 'config/config';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import { getPurchaseLink } from 'containers/services/consts';

const utmMedium = 'express-service-manager';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * @type {React.FC<{
 *   view: NXUtils.ValueOf<typeof serviceNav>['key']
 * }>}
 */
export const ExpressServiceManagerPurchaseLink = ({ view }) => {
    /***** RENDER *****/
    switch (view) {
        case serviceNav['Domain Names'].key:
            return (
                <Anchor href={getPurchaseLink('Domain Names', { utmMedium })} target="_blank">
                    Register a Domain
                </Anchor>
            );
        case serviceNav['Email Hosting'].key:
            return <Anchor to="/my-services/email-hosting?purchase=true">Purchase Email Hosting</Anchor>;
        case serviceNav['SSL Certificates'].key:
            return <Anchor to="/my-services/ssl?purchase=true">Purchase SSL Certificate</Anchor>;
        case serviceNav['cPanel Hosting'].key:
            return (
                <Anchor href={getPurchaseLink('Web Hosting', { utmMedium })} target="_blank">
                    Purchase Web Hosting
                </Anchor>
            );
        case serviceNav['Google Workspace'].key:
            return (
                <Anchor href={getPurchaseLink('Google Workspace', { utmMedium })} target="_blank">
                    Purchase Google Workspace
                </Anchor>
            );
        case serviceNav['VPS'].key:
            return (
                <Anchor href={getPurchaseLink('VPS', { utmMedium })} target="_blank">
                    Purchase VPS
                </Anchor>
            );
        case serviceNav['Microsoft 365'].key: {
            return (
                <Anchor href={getPurchaseLink('Microsoft 365', { utmMedium })} target="_blank">
                    Purchase Microsoft 365
                </Anchor>
            );
        }
        case serviceNav['VIPsites'].key:
            return null;
        default:
            return null;
    }
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

import { queryOptions, useQuery } from '@tanstack/react-query';
import { katanaQueryKeys } from 'containers/katana/queries/katanaQueryKeys';
import { KATANA_API } from 'utilities/api/katana';
import type { KatanaAPI } from 'utilities/api/katana/types';
import { SECOND } from 'utilities/consts';
import { TruthKeys } from 'utilities/methods/commonActions/Truth';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type Params = KatanaAPI.Katana.Site.ServiceID.Pages.PageID.Sections.SectionID.GET.Params;

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
function createQueryKey(params: Params) {
    return katanaQueryKeys.katana.service.ID.pages.ID.sections.ID(params);
}

function createQueryOptions(params: Params) {
    return queryOptions({
        queryKey: createQueryKey(params),
        queryFn: () => KATANA_API.katana.site.service_id.pages.page_id.sections.section_id.GET(params),
        staleTime: SECOND * 25,
        enabled: TruthKeys(params),
        select: (data) => {
            if (data?.status === 200) {
                return data.data;
            }
        }
    });
}

/**********************************************************************************************************
 *   HOOK START
 **********************************************************************************************************/
/**
 * Gets the specific section on a specific page on a service
 */
function _useQuery(serviceID: Params) {
    return useQuery(createQueryOptions(serviceID));
}

export const pageSection = Object.freeze({
    useQuery: _useQuery
});

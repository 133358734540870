import _Switch from 'components/Switch/_Switch';
import _SwitchHookFormDefault from 'components/Switch/hookForm/Default';

const Switch = Object.assign(_Switch, {
    HookForm: {
        Default: _SwitchHookFormDefault
    }
});

export default Switch;

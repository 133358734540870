/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import type { CSSProperties} from 'react';
import { useMemo } from 'react';

/**********************************************************************************************************
 *   HOOK START
 **********************************************************************************************************/
/**
 * **Description**
 * The useStyle hook provides an intuitive interface for creating CSSProperties objects for custom  
 * css properties that are properly memoized.  
 *
 * The hook takes in an object where the keys represent a local scss variable (e.g. '--Border-color') and the  
 * values represent a number, string, or boolean. It returns an object where the keys are the local scss variable  
 * and the values are the token value.  
 * 
 * - **Numbers**: Represent pixel values (e.g. 1 -> '1px')
 * - **Booleans**: Represent 1px or 0px (e.g. true -> '1px', false -> '0')
 * - **Strings**: Passed as is (e.g. '1rem' -> '1rem')
 *
 * This hook is not intended for use with tokens as the useTheme hook should be used for that purpose.  
 * 
 * **Notice:** The object passed to this function must have a static length of key-value pairs so that the underlying    
 * hook can properly memoize the results. If you are passing a dynamic object for some reason, you should memoize    
 * the object before passing it to this hook.  
 */
export const useStyle = (props: Record<string, number | boolean | string | undefined>) => {
    /***** HOOKS *****/
    return useMemo(() => {
        return Object.entries(props).reduce<CSSProperties>((acc, [cssVariable, value]) => {
            if (typeof value === 'boolean') {
                return {
                    ...acc,
                    [cssVariable]: value ? '1px' : '0'
                };
            }

            if (typeof value === 'number') {
                return {
                    ...acc,
                    [cssVariable]: `${value}px`
                };
            }

            if (typeof value === 'string') {
                return {
                    ...acc,
                    [cssVariable]: value
                };
            }

            return acc;
        }, {});
    }, Object.values(props));
};
/**********************************************************************************************************
 *   HOOK END
 **********************************************************************************************************/

/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { useMemo } from 'react';

/**********************************************************************************************************
 *   HOOK START
 **********************************************************************************************************/
/**
 * Returns a string representing the property path from a given target key to a contextual form value key.
 * @param {string} propertyKey - The property key to start from.
 * @param {string} targetKey - The target key to reach.
 * @returns {string}
 */
export function useTargetKeyToPropertyPath(propertyKey, targetKey) {
    return useMemo(() => {
        const propertyPath = propertyKey.split('.');
        const targetKeyPath = targetKey.split('.');

        const indexOfFirstMatchingKey = propertyPath.findIndex((key) => key.includes(targetKeyPath[0]));
        const newPath = targetKeyPath
            ?.map((key) => {
                return propertyPath.find((propertyPathKey) => propertyPathKey.includes(key)) ?? key;
            })
            ?.join('.');

        propertyPath.splice(indexOfFirstMatchingKey, propertyPath.length - indexOfFirstMatchingKey);

        return [...propertyPath, newPath].join('.');
    }, [propertyKey, targetKey]);
}
/**********************************************************************************************************
 *   HOOK END
 **********************************************************************************************************/

/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { useCurrentEditor } from '@tiptap/react';
import { useEffect } from 'react';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const MarkdownInjectorPlugin: React.FC<{ markdown: string }> = ({ markdown }) => {
    /***** HOOKS *****/
    const { editor } = useCurrentEditor();

    /***** EFFECTS *****/
    useEffect(() => {
        if (!editor) {
            return;
        }
        const currentMarkdown = editor?.storage.markdown.getMarkdown();
        if (currentMarkdown === markdown) {
            return;
        }
        editor.commands.setContent(markdown);
    }, []);

    /***** RENDER *****/
    return null;
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

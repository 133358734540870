/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { useParams } from '@tanstack/react-router';
import { useSelector } from 'react-redux';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import { MailboxTitle } from 'containers/gsuite/components/title/mailbox-title';
import { WorkspaceTitle } from 'containers/gsuite/components/title/workspace-title';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const GoogleTitle = () => {
    /***** HOOKS *****/
    const hasServiceData = useSelector((state: any) => !!state.gsuite.gsuite_service_data);
    const { mailboxId } = useParams({ strict: false });

    /***** RENDER *****/
    switch (true) {
        case hasServiceData && !mailboxId:
            return <WorkspaceTitle />;
        case hasServiceData && !!mailboxId:
            return <MailboxTitle />;
        default:
            return null;
    }
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import type { DependencyList} from 'react';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type Options<TSelectorResult, TSelectedResult = TSelectorResult> = {
    select?: (TResult: NoInfer<TSelectorResult> extends undefined ? Record<string, unknown> : NoInfer<TSelectorResult>) => TSelectedResult;
};

type UseSelectableSelector = <TSelectorResult, TSelectedResult>(
    selector: (state: any) => TSelectorResult,
    options?: Options<TSelectorResult, TSelectedResult>,
    dependencies?: DependencyList
) => TSelectedResult;

/**********************************************************************************************************
 *   HOOK START
 **********************************************************************************************************/
/**
 * Provides a useSelector abstraction that takes a second parameter with a select function (or not and guarantees a non-null return value). This will allow you
 * to derive data from the selected value that is only recalculated when the useSelector function changes.
 *
 * This should avoid recalculations when a component rerenders from another state change.
 *
 * @param selector - The selector function to select the state, this is directly passed to useSelector
 * @param options - The options object that contains the select function to derive data from the selected value
 * @param dependencies - The dependencies array that will be passed to useMemo to recalculate the derived value.
 */
export const useSelectableSelector: UseSelectableSelector = (selector, options, dependencies = []) => {
    /***** HOOKS *****/
    const selected = useSelector(selector);

    /***** RENDER HELPERS *****/
    const derived = useMemo(() => {
        if (!options?.select) {
            return (selected as any) ?? {};
        }

        return options.select((selected as any) ?? {});
    }, [selected, ...dependencies]);

    /***** RESULTS *****/
    return derived;
};
/**********************************************************************************************************
 *   HOOK END
 **********************************************************************************************************/

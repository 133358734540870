import {
    CANCEL_INITIAL_STATE,
    EMAIL_DELETE_ERROR,
    EMAIL_DELETE_REQUEST,
    EMAIL_DELETE_SUCCESS,
    GSUITE_DELETE_ERROR,
    GSUITE_DELETE_REQUEST,
    GSUITE_DELETE_SUCCESS,
    GSUITE_REVERSE_CANCEL_ERROR,
    GSUITE_REVERSE_CANCEL_REQUEST,
    GSUITE_REVERSE_CANCEL_SUCCESS,
    MS365_DELETE_SERVICE_ERROR,
    MS365_DELETE_SERVICE_REQUEST,
    MS365_DELETE_SERVICE_SUCCESS,
    MS365_REVERSE_DELETE_SERVICE_ERROR,
    MS365_REVERSE_DELETE_SERVICE_REQUEST,
    MS365_REVERSE_DELETE_SERVICE_SUCCESS,
    SERVICE_CANCEL_ERROR,
    SERVICE_CANCEL_REQUEST,
    SERVICE_CANCEL_SUCCESS,
    SERVICE_CHECK_CANCEL_ERROR,
    SERVICE_CHECK_CANCEL_STATUS,
    SERVICE_CHECK_CANCEL_SUCCESS,
    SERVICE_REVERSE_CANCEL_ERROR,
    SERVICE_REVERSE_CANCEL_REQUEST,
    SERVICE_REVERSE_CANCEL_SUCCESS,
    VPS_CANCEL_ERROR,
    VPS_CANCEL_REQUEST,
    VPS_CANCEL_SUCCESS
} from './action';

const initialState = {
    service_cancel_status: null,
    service_cancel_data: null,
    service_cancel_error: null,
    service_reverse_cancel_status: null,
    service_reverse_cancel_data: null,
    service_reverse_cancel_error: null,
    service_check_cancel_status: null,
    service_check_cancel_data: null,
    service_check_cancel_error: null,
    gsuite_cancel_status: null,
    gsuite_cancel_data: null,
    gsuite_cancel_error: null,
    gsuite_reverse_cancel_status: null,
    gsuite_reverse_cancel_data: null,
    gsuite_reverse_cancel_error: null,
    ms365_delete_service_status: null,
    ms365_reverse_delete_service_status: null,
    email_delete_status: null,
    email_delete_data: null,
    email_delete_error: null,
    vps_cancel_status: null,
    vps_cancel_data: null,
    vps_cancel_error: null
};

const cancelReducer = (state = initialState, action) => {
    switch (action.type) {
        /**********************************************************************************************************
         *   RESET
         **********************************************************************************************************/
        case CANCEL_INITIAL_STATE:
            return initialState;

        /**********************************************************************************************************
         *   HOSTING CANCEL
         **********************************************************************************************************/
        case SERVICE_CHECK_CANCEL_STATUS:
            return {
                ...state,
                service_check_cancel_status: 'loading',
                service_check_cancel_data: null,
                service_check_cancel_error: null
            };

        case SERVICE_CHECK_CANCEL_SUCCESS:
            return {
                ...state,
                service_check_cancel_status: 'success',
                service_check_cancel_data: action.service_check_cancel_data
            };

        case SERVICE_CHECK_CANCEL_ERROR:
            return {
                ...state,
                service_check_cancel_status: 'error',
                service_check_cancel_error: action.service_check_cancel_error
            };

        case SERVICE_CANCEL_REQUEST:
            return {
                ...state,
                service_cancel_status: 'loading',
                service_cancel_data: null,
                service_cancel_error: null
            };

        case SERVICE_CANCEL_SUCCESS:
            return {
                ...state,
                service_cancel_status: 'success',
                service_cancel_data: action.service_cancel_data
            };

        case SERVICE_CANCEL_ERROR:
            return {
                ...state,
                service_cancel_status: 'error',
                service_cancel_error: action.service_cancel_error
            };

        case SERVICE_REVERSE_CANCEL_REQUEST:
            return {
                ...state,
                service_reverse_cancel_status: 'loading',
                service_reverse_cancel_data: null,
                service_reverse_cancel_error: null
            };

        case SERVICE_REVERSE_CANCEL_SUCCESS:
            return {
                ...state,
                service_reverse_cancel_status: 'success',
                service_reverse_cancel_data: action.service_reverse_cancel_data
            };

        case SERVICE_REVERSE_CANCEL_ERROR:
            return {
                ...state,
                service_reverse_cancel_status: 'error',
                service_reverse_cancel_error: action.service_reverse_cancel_error
            };

        /**********************************************************************************************************
         *   GSUITE CANCEL
         **********************************************************************************************************/
        case GSUITE_DELETE_REQUEST:
            return {
                ...state,
                gsuite_cancel_status: 'loading',
                gsuite_cancel_data: null,
                gsuite_cancel_error: null
            };

        case GSUITE_DELETE_SUCCESS:
            return {
                ...state,
                gsuite_cancel_status: 'success',
                gsuite_cancel_data: action.gsuite_cancel_data
            };

        case GSUITE_DELETE_ERROR:
            return {
                ...state,
                gsuite_cancel_status: 'error',
                gsuite_cancel_error: action.gsuite_cancel_error
            };

        case GSUITE_REVERSE_CANCEL_REQUEST:
            return {
                ...state,
                gsuite_reverse_cancel_status: 'loading',
                gsuite_reverse_cancel_data: null,
                gsuite_reverse_cancel_error: null
            };

        case GSUITE_REVERSE_CANCEL_SUCCESS:
            return {
                ...state,
                gsuite_reverse_cancel_status: 'success',
                gsuite_reverse_cancel_data: action.gsuite_reverse_cancel_data
            };

        case GSUITE_REVERSE_CANCEL_ERROR:
            return {
                ...state,
                gsuite_reverse_cancel_status: 'error',
                gsuite_reverse_cancel_error: action.gsuite_reverse_cancel_error
            };

        /**********************************************************************************************************
         *   MS365 DELETE SERVICE
         **********************************************************************************************************/
        case MS365_DELETE_SERVICE_REQUEST:
            return {
                ...state,
                ms365_delete_service_status: 'loading'
            };

        case MS365_DELETE_SERVICE_SUCCESS:
            return {
                ...state,
                ms365_delete_service_status: 'success'
            };

        case MS365_DELETE_SERVICE_ERROR:
            return {
                ...state,
                ms365_delete_service_status: 'error'
            };

        case MS365_REVERSE_DELETE_SERVICE_REQUEST:
            return {
                ...state,
                ms365_reverse_delete_service_status: 'loading'
            };

        case MS365_REVERSE_DELETE_SERVICE_SUCCESS:
            return {
                ...state,
                ms365_reverse_delete_service_status: 'success'
            };

        case MS365_REVERSE_DELETE_SERVICE_ERROR:
            return {
                ...state,
                ms365_reverse_delete_service_status: 'error'
            };

        /**********************************************************************************************************
         *   GSUITE CANCEL
         **********************************************************************************************************/
        case EMAIL_DELETE_REQUEST:
            return {
                ...state,
                email_delete_status: 'loading',
                email_delete_data: null,
                email_delete_error: null
            };

        case EMAIL_DELETE_SUCCESS:
            return {
                ...state,
                email_delete_status: 'success',
                email_delete_data: action.email_delete_data
            };

        case EMAIL_DELETE_ERROR:
            return {
                ...state,
                email_delete_status: 'error',
                email_delete_error: action.email_delete_error
            };

        /**********************************************************************************************************
         *   VPS CANCEL
         **********************************************************************************************************/
        case VPS_CANCEL_REQUEST:
            return {
                ...state,
                vps_cancel_status: 'loading',
                vps_cancel_data: null,
                vps_cancel_error: null
            };

        case VPS_CANCEL_SUCCESS:
            return {
                ...state,
                vps_cancel_status: 'success',
                vps_cancel_data: action.vps_cancel_data
            };

        case VPS_CANCEL_ERROR:
            return {
                ...state,
                vps_cancel_status: 'error',
                vps_cancel_error: action.vps_cancel_error
            };

        default:
            return state;
    }
};

export default cancelReducer;

/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React from 'react';

/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { PhosphorIcons } from 'components/Icons/Phosphor';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import './_Staff.scss';

const MaskIcon = Math.random() < 0.01 ? PhosphorIcons.MaskSad : PhosphorIcons.MaskHappy;

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
const StaffTag = () => (
    <div className="staff__notification">
        <MaskIcon white size={28} />
        <div className="text">You are logged in as a staff member</div>
    </div>
);
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

export default StaffTag;

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { useGetCaveat } from 'containers/katana/formFields/methods/useGetCaveat';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import { CaveatTypes } from 'containers/katana/formFields/caveatTypes';
import { defaultRichTextFeatures } from 'containers/katana/formFields/methods/getRichTextCaveat/consts';

/**********************************************************************************************************
 *   TYPE IMPORTS
 **********************************************************************************************************/
import type { RichTextEditorNamespace } from 'components/Form/RichTextEditor/types';
import type { KatanaNamespace } from 'containers/katana/types';

/**********************************************************************************************************
 *   HOOK START
 **********************************************************************************************************/
export function useGetRichTextCaveat(
    caveats: KatanaNamespace.SectionDefinitions.AllCaveats[] = defaultRichTextFeatures
): RichTextEditorNamespace.Features[] {
    return useGetCaveat(CaveatTypes.RICH_TEXT, caveats, defaultRichTextFeatures) as any;
}
/**********************************************************************************************************
 *   HOOK END
 **********************************************************************************************************/

/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import type { RefObject } from 'react';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import { PhosphorIcons } from 'components/Icons/Phosphor';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
interface DefaultLabelProps {
    labelRef: RefObject<HTMLButtonElement>;
    label?: string;
    disabled?: boolean;
    dropdownTitleOnClick?: () => void;
    isOpen: boolean;
}

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
function _DefaultLabel({ labelRef, dropdownTitleOnClick, disabled, label, isOpen }: DefaultLabelProps) {
    /***** RENDER *****/
    return (
        <button className="CustomDropdown__title" type="button" ref={labelRef} onClick={!disabled ? dropdownTitleOnClick : () => {}}>
            <div className="text">{label}</div>
            <PhosphorIcons.Chevron state={isOpen ? 'up' : 'down'} />
        </button>
    );
}
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
export { _DefaultLabel };

import {
    ACCOUNT_SERVICES_ALIGNMENT_REQUEST,
    ACCOUNT_SERVICES_ALIGNMENT_SUCCESS,
    ACCOUNT_SERVICES_ALIGNMENT_ERROR,
    ACCOUNT_SERVICES_ALIGNMENT_PREVIEW_REQUEST,
    ACCOUNT_SERVICES_ALIGNMENT_PREVIEW_SUCCESS,
    ACCOUNT_SERVICES_ALIGNMENT_PREVIEW_ERROR,
    ACCOUNT_SERVICES_ALIGN_REQUEST,
    ACCOUNT_SERVICES_ALIGN_SUCCESS,
    ACCOUNT_SERVICES_ALIGN_ERROR,
    ACCOUNT_SLA_CLAIM_SERVICES_REQUEST,
    ACCOUNT_SLA_CLAIM_SERVICES_SUCCESS,
    ACCOUNT_SLA_CLAIM_SERVICES_ERROR,
    ACCOUNT_SLA_CLAIM_EVENTS_REQUEST,
    ACCOUNT_SLA_CLAIM_EVENTS_SUCCESS,
    ACCOUNT_SLA_CLAIM_EVENTS_ERROR,
    ACCOUNT_SLA_CLAIM_SUBMIT_REQUEST,
    ACCOUNT_SLA_CLAIM_SUBMIT_SUCCESS,
    ACCOUNT_SLA_CLAIM_SUBMIT_ERROR,
    ACCOUNT_WALLET_REQUEST,
    ACCOUNT_WALLET_SUCCESS,
    ACCOUNT_WALLET_ERROR,
    ACCOUNT_WALLET_TRANSACTIONS_REQUEST,
    ACCOUNT_WALLET_TRANSACTIONS_SUCCESS,
    ACCOUNT_WALLET_TRANSACTIONS_ERROR,
    ACCOUNT_WALLET_TRANSACTIONS_SEARCH_REQUEST,
    ACCOUNT_WALLET_TRANSACTIONS_SEARCH_SUCCESS,
    ACCOUNT_WALLET_TRANSACTIONS_SEARCH_ERROR,
    ACCOUNT_WALLET_TRANSACTIONS_SEARCH_RESET,
    ACCOUNT_WALLET_ADD_CREDIT_REQUEST,
    ACCOUNT_WALLET_ADD_CREDIT_SUCCESS,
    ACCOUNT_WALLET_ADD_CREDIT_ERROR,
    ACCOUNT_CALCULATE_WALLET_CASH_OUT_REQUEST,
    ACCOUNT_CALCULATE_WALLET_CASH_OUT_SUCCESS,
    ACCOUNT_CALCULATE_WALLET_CASH_OUT_ERROR,
    ACCOUNT_WALLET_CASH_OUT_REQUEST,
    ACCOUNT_WALLET_CASH_OUT_SUCCESS,
    ACCOUNT_WALLET_CASH_OUT_ERROR
} from './action';

const initialState = {
    account_services_alignment_status: null,
    account_services_alignment_data: null,
    account_services_alignment_error: null,
    account_services_alignment_preview_status: null,
    account_services_alignment_preview_data: null,
    account_services_alignment_preview_error: null,
    account_services_align_status: null,
    account_services_align_data: null,
    account_services_align_error: null,
    account_sla_claim_services_status: null,
    account_sla_claim_services_data: null,
    account_sla_claim_services_error: null,
    account_sla_claim_events_status: null,
    account_sla_claim_events_data: null,
    account_sla_claim_events_error: null,
    account_sla_claim_submit_status: null,
    account_sla_claim_submit_data: null,
    account_sla_claim_submit_error: null,

    account_wallet_status: null,
    account_wallet_data: null,

    account_wallet_transactions_status: null,
    account_wallet_transactions_data: null,
    account_wallet_transactions_meta: null,

    account_wallet_transactions_search_status: null,
    account_wallet_transactions_search_data: null,

    account_wallet_add_credit_status: null,
    account_wallet_add_credit_data: null,

    account_calculate_wallet_cash_out_status: null,
    account_calculate_wallet_cash_out_data: null,

    account_wallet_cash_out_status: null,
    account_wallet_cash_out_data: null
};

const billingReducer = (state = initialState, action) => {
    switch (action.type) {
        /**********************************************************************************************************
         *   ALIGN RECURRING SERVICES
         **********************************************************************************************************/
        case ACCOUNT_SERVICES_ALIGNMENT_REQUEST:
            return {
                ...state,
                account_services_alignment_status: 'loading',
                account_services_alignment_data: null,
                account_services_alignment_error: null
            };

        case ACCOUNT_SERVICES_ALIGNMENT_SUCCESS:
            return {
                ...state,
                account_services_alignment_status: 'success',
                account_services_alignment_data: action.account_services_alignment_data
            };

        case ACCOUNT_SERVICES_ALIGNMENT_ERROR:
            return {
                ...state,
                account_services_alignment_status: 'error',
                account_services_alignment_error: action.account_services_alignment_error
            };

        case ACCOUNT_SERVICES_ALIGNMENT_PREVIEW_REQUEST:
            return {
                ...state,
                account_services_alignment_preview_status: 'loading',
                account_services_alignment_preview_data: null,
                account_services_alignment_preview_error: null
            };

        case ACCOUNT_SERVICES_ALIGNMENT_PREVIEW_SUCCESS:
            return {
                ...state,
                account_services_alignment_preview_status: 'success',
                account_services_alignment_preview_data: action.account_services_alignment_preview_data
            };

        case ACCOUNT_SERVICES_ALIGNMENT_PREVIEW_ERROR:
            return {
                ...state,
                account_services_alignment_preview_status: 'error',
                account_services_alignment_preview_error: action.account_services_alignment_preview_error
            };

        case ACCOUNT_SERVICES_ALIGN_REQUEST:
            return {
                ...state,
                account_services_align_status: 'loading',
                account_services_align_data: null,
                account_services_align_error: null
            };

        case ACCOUNT_SERVICES_ALIGN_SUCCESS:
            return {
                ...state,
                account_services_align_status: 'success',
                account_services_align_data: action.account_services_align_data
            };

        case ACCOUNT_SERVICES_ALIGN_ERROR:
            return {
                ...state,
                account_services_align_status: 'error',
                account_services_align_error: action.account_services_align_error
            };

        case ACCOUNT_SLA_CLAIM_SERVICES_REQUEST:
            return {
                ...state,
                account_sla_claim_services_status: 'loading',
                account_sla_claim_services_data: null,
                account_sla_claim_services_error: null
            };

        case ACCOUNT_SLA_CLAIM_SERVICES_SUCCESS:
            return {
                ...state,
                account_sla_claim_services_status: 'success',
                account_sla_claim_services_data: action.account_sla_claim_services_data
            };

        case ACCOUNT_SLA_CLAIM_SERVICES_ERROR:
            return {
                ...state,
                account_sla_claim_services_status: 'error',
                account_sla_claim_services_error: action.account_sla_claim_services_error
            };

        case ACCOUNT_SLA_CLAIM_EVENTS_REQUEST:
            return {
                ...state,
                account_sla_claim_events_status: 'loading',
                account_sla_claim_events_data: null,
                account_sla_claim_events_error: null
            };

        case ACCOUNT_SLA_CLAIM_EVENTS_SUCCESS:
            return {
                ...state,
                account_sla_claim_events_status: 'success',
                account_sla_claim_events_data: action.account_sla_claim_events_data
            };

        case ACCOUNT_SLA_CLAIM_EVENTS_ERROR:
            return {
                ...state,
                account_sla_claim_events_status: 'error',
                account_sla_claim_events_error: action.account_sla_claim_events_error
            };

        case ACCOUNT_SLA_CLAIM_SUBMIT_REQUEST:
            return {
                ...state,
                account_sla_claim_submit_status: 'loading',
                account_sla_claim_submit_data: null,
                account_sla_claim_submit_error: null
            };

        case ACCOUNT_SLA_CLAIM_SUBMIT_SUCCESS:
            return {
                ...state,
                account_sla_claim_submit_status: 'success',
                account_sla_claim_submit_data: action.account_sla_claim_submit_data
            };

        case ACCOUNT_SLA_CLAIM_SUBMIT_ERROR:
            return {
                ...state,
                account_sla_claim_submit_status: 'error',
                account_sla_claim_submit_error: action.account_sla_claim_submit_error
            };

        /**********************************************************************************************************
         *   WALLET BALANCES
         **********************************************************************************************************/
        case ACCOUNT_WALLET_REQUEST:
            return {
                ...state,
                account_wallet_status: 'loading',
                account_wallet_data: null
            };

        case ACCOUNT_WALLET_SUCCESS:
            return {
                ...state,
                account_wallet_status: 'success',
                account_wallet_data: action.account_wallet_data
            };

        case ACCOUNT_WALLET_ERROR:
            return {
                ...state,
                account_wallet_status: 'error'
            };

        /**********************************************************************************************************
         *   WALLET TRANSACTIONS
         **********************************************************************************************************/
        case ACCOUNT_WALLET_TRANSACTIONS_REQUEST:
            return {
                ...state,
                account_wallet_transactions_status: 'loading',
                account_wallet_transactions_data: null,
                account_wallet_transactions_meta: null
            };

        case ACCOUNT_WALLET_TRANSACTIONS_SUCCESS:
            return {
                ...state,
                account_wallet_transactions_status: 'success',
                account_wallet_transactions_data: action.account_wallet_transactions_data,
                account_wallet_transactions_meta: action.account_wallet_transactions_meta
            };

        case ACCOUNT_WALLET_TRANSACTIONS_ERROR:
            return {
                ...state,
                account_wallet_transactions_status: 'error'
            };

        /**********************************************************************************************************
         *   WALLET TRANSACTIONS SEARCH
         **********************************************************************************************************/
        case ACCOUNT_WALLET_TRANSACTIONS_SEARCH_REQUEST:
            return {
                ...state,
                account_wallet_transactions_search_status: 'loading',
                account_wallet_transactions_search_data: null
            };

        case ACCOUNT_WALLET_TRANSACTIONS_SEARCH_SUCCESS:
            return {
                ...state,
                account_wallet_transactions_search_status: 'success',
                account_wallet_transactions_search_data: action.account_wallet_transactions_search_data
            };

        case ACCOUNT_WALLET_TRANSACTIONS_SEARCH_ERROR:
            return {
                ...state,
                account_wallet_transactions_search_status: 'error'
            };

        case ACCOUNT_WALLET_TRANSACTIONS_SEARCH_RESET:
            return {
                ...state,
                account_wallet_transactions_search_status: null,
                account_wallet_transactions_search_data: null,
                account_wallet_transactions_search_error: null
            };

        /**********************************************************************************************************
         *   WALLET ADD CREDIT
         **********************************************************************************************************/
        case ACCOUNT_WALLET_ADD_CREDIT_REQUEST:
            return {
                ...state,
                account_wallet_add_credit_status: 'loading',
                account_wallet_add_credit_data: null
            };

        case ACCOUNT_WALLET_ADD_CREDIT_SUCCESS:
            return {
                ...state,
                account_wallet_add_credit_status: 'success',
                account_wallet_add_credit_data: action.account_wallet_add_credit_data
            };

        case ACCOUNT_WALLET_ADD_CREDIT_ERROR:
            return {
                ...state,
                account_wallet_add_credit_status: 'error'
            };

        /**********************************************************************************************************
         *   WALLET CASH OUT
         **********************************************************************************************************/
        case ACCOUNT_CALCULATE_WALLET_CASH_OUT_REQUEST:
            return {
                ...state,
                account_calculate_wallet_cash_out_status: 'loading',
                account_calculate_wallet_cash_out_data: null
            };

        case ACCOUNT_CALCULATE_WALLET_CASH_OUT_SUCCESS:
            return {
                ...state,
                account_calculate_wallet_cash_out_status: 'success',
                account_calculate_wallet_cash_out_data: action.account_calculate_wallet_cash_out_data
            };

        case ACCOUNT_CALCULATE_WALLET_CASH_OUT_ERROR:
            return {
                ...state,
                account_calculate_wallet_cash_out_status: 'error'
            };

        case ACCOUNT_WALLET_CASH_OUT_REQUEST:
            return {
                ...state,
                account_wallet_cash_out_status: 'loading',
                account_wallet_cash_out_data: null
            };

        case ACCOUNT_WALLET_CASH_OUT_SUCCESS:
            return {
                ...state,
                account_wallet_cash_out_status: 'success',
                account_wallet_cash_out_data: action.account_wallet_cash_out_data
            };

        case ACCOUNT_WALLET_CASH_OUT_ERROR:
            return {
                ...state,
                account_wallet_cash_out_status: 'error'
            };

        default:
            return state;
    }
};

export default billingReducer;

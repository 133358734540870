import { useStore } from '@tanstack/react-store';
import classNames from 'classnames';
import Anchor from 'components/Anchor';
import { CheckBox } from 'components/Form/CheckBox';
import { IconifyIcon } from 'components/Icons/Iconify';
import { PhosphorIcons } from 'components/Icons/Phosphor';
import { SuperUserItem } from 'components/StaffMenu/SuperUser/SuperUserItem';
import {
    SUPER_ACTIONS,
    maxActionKeyLength,
    newActionsStore,
    superActionNoCheats,
    superUserSuperActionsState
} from 'components/StaffMenu/SuperUser/consts';
import { addSuperAction, canAddSuperActionCondition, matchSuperCode, superActionSubscriberChanges } from 'components/StaffMenu/SuperUser/methods';
import { superUserActionViewedQuery } from 'components/StaffMenu/SuperUser/superUserActionViewedQuery';
import type { SuperUserNamespace } from 'components/StaffMenu/SuperUser/types';
import { useStaffMenu } from 'components/StaffMenu/useStaffMenu';
import Border from 'components/Utils/Border';
import { Flex } from 'components/Utils/Flex';
import Gradient from 'components/Utils/Gradient';
import Text from 'components/Utils/Text';
import _, { keys } from 'lodash';
import { useEffect, useRef } from 'react';
import { useTanstackStore } from 'utilities/hooks/tanstack-store/useTanstackStore';
import './_SuperUser.scss';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/

export const SuperUser = () => {
    /***** STATE *****/
    const [superActions] = useTanstackStore(superUserSuperActionsState);
    const newActions = useStore(newActionsStore);

    /***** HOOKS *****/
    const superCodeRef = useRef('');
    const { active, toggle, setActive, id, isOpen } = useStaffMenu();

    /***** QUERIES *****/
    const { data: viewedSuperActionsData } = superUserActionViewedQuery.useQuery();
    const { mutate: mutateSetViewedAction } = superUserActionViewedQuery.useMutation();

    /***** EFFECTS *****/
    useEffect(() => {
        function handleKeyPress(e: KeyboardEvent) {
            superCodeRef.current += e.key;

            if (superCodeRef.current.length > maxActionKeyLength) {
                const subStringTarget = maxActionKeyLength - superCodeRef.current.length;
                superCodeRef.current = superCodeRef.current.substring(subStringTarget);
            }

            const matchedSuperCode = matchSuperCode(superCodeRef.current);

            const canAddSuperAction = canAddSuperActionCondition(matchedSuperCode);

            if (matchedSuperCode && canAddSuperAction) {
                addSuperAction(matchedSuperCode);
                superCodeRef.current = '';
            }
        }

        document.addEventListener('keydown', handleKeyPress);

        const actionSubscriber = superActionSubscriberChanges();

        return () => {
            document.removeEventListener('keydown', handleKeyPress);
            actionSubscriber();
        };
    }, []);

    useEffect(() => {
        if (!keys(superActions).length) {
            setActive((previous) => (previous === id ? '' : previous));
        }
    }, [superActions]);

    const usableActions: SuperUserNamespace.ActionKeys[] = _.uniq([...superActionNoCheats, ...keys(superActions)]).filter((actionKey) =>
        Boolean(SUPER_ACTIONS[actionKey as SuperUserNamespace.ActionKeys].menuContent)
    ) as SuperUserNamespace.ActionKeys[];

    const totalActiveActions = _.values(superActions).filter(Boolean).length;
    const hasActiveActions = Boolean(totalActiveActions);

    const newActionValues = Object.values(newActions);
    const hasNewActions = Boolean(newActionValues.length);

    /***** RENDER *****/
    if (!usableActions.length) {
        return;
    }

    return (
        <Gradient primary className="SuperUser">
            <Flex fullHeight fullWidth justify="center" items="center">
                <button type="button" style={{ width: '100%', height: '100%' }} onClick={toggle}>
                    <IconifyIcon icon="simple-icons:superuser" white size={28} />

                    {!active && isOpen && hasNewActions && !hasActiveActions && (
                        <Flex className="SuperUser__hasNewActions" gap={0} justify="center" items="center">
                            <PhosphorIcons.Arrow.Left white size={12} />
                            <Text size--xs white>
                                New!
                            </Text>
                        </Flex>
                    )}
                    {!active && isOpen && Boolean(totalActiveActions) && (
                        <Flex className="SuperUser__totalActiveActions" gap={1}>
                            <Text size--xs white>
                                {totalActiveActions}
                            </Text>
                            <CheckBox isChecked />
                        </Flex>
                    )}
                </button>
                <Border all inject>
                    <div className={classNames('SuperUser__actions', { hidden: !active })}>
                        <Flex items="center" justify="between">
                            <Text>Super User Actions</Text>
                            {hasNewActions && (
                                <Anchor
                                    onClick={() => {
                                        mutateSetViewedAction([..._.values(viewedSuperActionsData), ...newActionValues]);
                                    }}
                                >
                                    clear new
                                </Anchor>
                            )}
                        </Flex>
                        <hr />
                        <div className="SuperUser__actionEntries">
                            {usableActions.map((action) => {
                                return <SuperUserItem key={action} action={action} />;
                            })}
                        </div>
                    </div>
                </Border>
            </Flex>
        </Gradient>
    );
};

import { CheckBoxList } from 'components/Form/CheckBoxList';
import RequestLoader from 'components/Loaders/Request';
import NXBox from 'components/NXBox';
import ContentEditorModuleContent from 'containers/katana/containers/ContentEditorLightbox/contentEditorModuleContent';
import { SocialsMetaInput } from 'containers/katana/containers/contentEditorModules/socialsMetaInput';
import { useKatanaParams } from 'containers/katana/hooks/useSetupEditorRouteParams';
import { katanaQuery } from 'containers/katana/queries/tanstackTree';
import { usePostNavigationMutation } from 'containers/katana/queries/usePostNavigationMutation';
import { useUpdateSocialLinksMutation } from 'containers/katana/queries/useUpdateSocialLinksMutation';
import _ from 'lodash';
import { useWatch } from 'react-hook-form';
const { SOCIALS } = useUpdateSocialLinksMutation.FORM_FIELD_KEYS;
const { SHOW_SOCIALS_IN_FOOTER, SHOW_SOCIALS_IN_HEADER } = usePostNavigationMutation.FORM_FIELD_KEYS;

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
const _SocialMediaLinksContentModule = () => {
    /***** HOOKS *****/
    const { katanaServiceId } = useKatanaParams();
    const formValues = useWatch();

    /***** QUERIES *****/
    const { isLoading: isGetSocialsMetaLoading } = katanaQuery.serviceID.meta.getSocialsDefinitions.useQuery(katanaServiceId);

    /***** RENDER HELPERS *****/
    const multiLoaderData = RequestLoader.MultiLoader.useLoadersData([
        { condition: isGetSocialsMetaLoading, message: 'Loading Available Socials..' }
    ]);
    const socialsInFooterValue = _.get(formValues, SHOW_SOCIALS_IN_HEADER);
    const socialsInHeaderValue = _.get(formValues, SHOW_SOCIALS_IN_FOOTER);
    const isSocialsDisplayEnabled = socialsInFooterValue || socialsInHeaderValue;

    /***** RENDER *****/
    return (
        <NXBox.TopAccordion
            title="Social Media Links"
            description="You can include links to your social media accounts in both the menu and footer of your website. These will display as icons linked to your social profiles."
        >
            <NXBox.DefaultPadding>
                <RequestLoader.MultiLoader loaders={multiLoaderData}>
                    <CheckBoxList>
                        <CheckBoxList.Item.HookForm name={SHOW_SOCIALS_IN_HEADER}>
                            Display my social media links in the header
                        </CheckBoxList.Item.HookForm>

                        <CheckBoxList.Item.HookForm name={SHOW_SOCIALS_IN_FOOTER}>
                            Add social media links to the footer too
                        </CheckBoxList.Item.HookForm>

                        {isSocialsDisplayEnabled && (
                            <ContentEditorModuleContent>
                                <SocialsMetaInput />
                            </ContentEditorModuleContent>
                        )}
                    </CheckBoxList>
                </RequestLoader.MultiLoader>
            </NXBox.DefaultPadding>
        </NXBox.TopAccordion>
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
const SocialMediaLinksContentModule = Object.assign(_SocialMediaLinksContentModule, {
    /**
     * formFields is used to provide initial values to the form.
     */
    formFields: { SOCIALS, SHOW_SOCIALS_IN_HEADER, SHOW_SOCIALS_IN_FOOTER }
});

export { SocialMediaLinksContentModule };

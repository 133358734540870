/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { redirect } from '@tanstack/react-router';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { isAuthenticated } from 'router/utils/isAuthenticated';

/**********************************************************************************************************
 *   TYPE IMPORTS
 **********************************************************************************************************/
import type { AnyRouteId, GetOptionTypeFromRouteId } from 'router/types';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type AuthenticationLevels = 'user' | 'guest' | 'any';

/**********************************************************************************************************
 *   MIDDLEWARE START
 **********************************************************************************************************/
export const authenticationMiddleware = (
    level: AuthenticationLevels,
    opts: GetOptionTypeFromRouteId<AnyRouteId, 'beforeLoad'>,
    callback?: () => void
): void => {
    switch (level) {
        case 'guest':
            if (isAuthenticated()) {
                callback?.();
                throw redirect({ to: '/dashboard' });
            }
            break;
        case 'user':
            if (!isAuthenticated()) {
                callback?.();
                throw redirect({
                    to: '/login',
                    search: { ref: opts.location.pathname }
                });
            }
            break;
        default:
            break;
    }
};

import { _ValidationMessage } from 'components/Form/ValidationMessage/_ValidationMessage';
import { _ValidationMessageHookFormDefault as ValidationMessageHookFormDefault } from 'components/Form/ValidationMessage/wrappers/hookForm/Default';
import { _ValidationMessageReduxFormDefault as ValidationMessageReduxFormDefault } from 'components/Form/ValidationMessage/wrappers/reduxform/Default';
import { _ValidationMessageReduxFormFieldArray as ValidationMessageReduxFormFieldArray } from 'components/Form/ValidationMessage/wrappers/reduxform/FieldArray';

const ValidationMessage = Object.assign(_ValidationMessage, {
    ReduxForm: {
        Default: ValidationMessageReduxFormDefault,
        FieldArray: ValidationMessageReduxFormFieldArray
    },
    HookForm: {
        Default: ValidationMessageHookFormDefault
    }
});

export { ValidationMessage };

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { isValidDomRect } from 'components/Form/RichTextEditor/methods/isValidDomRect';

/**********************************************************************************************************
 *   TYPE IMPORTS
 **********************************************************************************************************/
import type { UsePositionEditorElementParams } from 'components/Form/RichTextEditor/hooks/usePositionEditorElement/types';

export const getSelectedElementDomRect = (align: UsePositionEditorElementParams['align']) => {
    const nativeSelection = window.getSelection();

    function getDomRect() {
        if (nativeSelection === null || !nativeSelection?.anchorNode) {
            return;
        }

        if (align === 'left' && nativeSelection.anchorNode) {
            const range = document.createRange();
            range.selectNode(nativeSelection.anchorNode);
            const rect = range.getBoundingClientRect();
            range.detach();
            return rect;
        } else {
            const domRange = nativeSelection.getRangeAt(0);
            const rect = domRange.getBoundingClientRect();
            if (isValidDomRect(rect)) {
                return rect;
            }

            const startParentRect = domRange.startContainer.parentElement?.getBoundingClientRect();
            if (isValidDomRect(startParentRect)) {
                return startParentRect;
            }
        }
    }

    const domRangeRect = getDomRect();
    const isValidDomRangeRect = isValidDomRect(domRangeRect);

    if (!isValidDomRangeRect) {
        return;
    }

    return domRangeRect;
};

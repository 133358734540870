/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { createRoute } from '@tanstack/react-router';

/**********************************************************************************************************
 *   ROUTE IMPORTS
 **********************************************************************************************************/
import { EmailHostingMailboxRoute } from 'containers/email/routes/email-hosting.$mailboxId/mailbox.$mailboxId';
import { routerMiddleware } from 'router/utils/middleware';
import { lazyFN } from 'utilities/methods/tanstack/router/lazyFn';

/**********************************************************************************************************
 *   ROUTE START
 **********************************************************************************************************/
export const EmailHostingAdminMailboxRoute = createRoute({
    getParentRoute: () => EmailHostingMailboxRoute,
    path: 'admin',
    beforeLoad(opts) {
        routerMiddleware.business(this, opts);
        routerMiddleware.authentication('user', opts);
    }
}).lazy(lazyFN(() => import('./admin.lazy'), 'LazyEmailHostingAdminMailboxRoute'));

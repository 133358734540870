import { invariant } from '@tanstack/react-router';
import { createContext, useContext } from 'react';

type ModuleStoreContextType = {
    href: string;
    hash: string;
    reason?: string;
};

export const ModuleStoreContext = createContext<ModuleStoreContextType | null>(null);

export const useModuleStoreContext = () => {
    const context = useContext(ModuleStoreContext);

    if (!context) {
        invariant(context, 'useModuleStoreContext must be used within a ModuleStoreContextProvider');
    }

    return context;
};

/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import _ from 'lodash';

export function getComplexValue(validationMethod: (value: unknown) => undefined | string, key: string) {
    return (value: unknown) => {
        if (!_.has(value, key)) {
            return;
        }
        const _value = _.get(value, key);
        return validationMethod(_value);
    };
}

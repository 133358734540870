import { API } from 'utilities/api/ssl';
import { createDownloadFile, getDataFromSuccessResponse, getErrorFromFailResponse } from 'utilities/methods/commonActions';

import { pushNotification } from 'components/Toast/functions';

export const SSL_INITIAL_STATE = 'ssl/SSL_INITIAL_STATE';

export const SSL_INSTALL_REQUEST = 'ssl/SSL_INSTALL_REQUEST';
export const SSL_INSTALL_SUCCESS = 'ssl/SSL_INSTALL_SUCCESS';
export const SSL_INSTALL_ERROR = 'ssl/SSL_INSTALL_ERROR';

export const SSL_HOSTING_SEARCH_REQUEST = 'ssl/SSL_HOSTING_SEARCH_REQUEST';
export const SSL_HOSTING_SEARCH_SUCCESS = 'ssl/SSL_HOSTING_SEARCH_SUCCESS';
export const SSL_HOSTING_SEARCH_ERROR = 'ssl/SSL_HOSTING_SEARCH_ERROR';

export const SSL_INFORMATION_REQUEST = 'ssl/SSL_INFORMATION_REQUEST';
export const SSL_INFORMATION_SUCCESS = 'ssl/SSL_INFORMATION_SUCCESS';
export const SSL_INFORMATION_ERROR = 'ssl/SSL_INFORMATION_ERROR';

export const SSL_CREATE_REQUEST = 'ssl/SSL_CREATE_REQUEST';
export const SSL_CREATE_SUCCESS = 'ssl/SSL_CREATE_SUCCESS';
export const SSL_CREATE_ERROR = 'ssl/SSL_CREATE_ERROR';

export const SSL_DOWNLOAD_REQUEST = 'ssl/SSL_DOWNLOAD_REQUEST';
export const SSL_DOWNLOAD_SUCCESS = 'ssl/SSL_DOWNLOAD_SUCCESS';
export const SSL_DOWNLOAD_ERROR = 'ssl/SSL_DOWNLOAD_ERROR';

export const SSL_CONFIGURATION_REQUEST = 'ssl/SSL_CONFIGURATION_REQUEST';
export const SSL_CONFIGURATION_SUCCESS = 'ssl/SSL_CONFIGURATION_SUCCESS';
export const SSL_CONFIGURATION_ERROR = 'ssl/SSL_CONFIGURATION_ERROR';

export const SSL_CSR_INFORMATION_REQUEST = 'ssl/SSL_CSR_INFORMATION_REQUEST';
export const SSL_CSR_INFORMATION_SUCCESS = 'ssl/SSL_CSR_INFORMATION_SUCCESS';
export const SSL_CSR_INFORMATION_ERROR = 'ssl/SSL_CSR_INFORMATION_ERROR';

export const SSL_CSR_SUBMISSION_REQUEST = 'ssl/SSL_CSR_SUBMISSION_REQUEST';
export const SSL_CSR_SUBMISSION_SUCCESS = 'ssl/SSL_CSR_SUBMISSION_SUCCESS';
export const SSL_CSR_SUBMISSION_ERROR = 'ssl/SSL_CSR_SUBMISSION_ERROR';

export const SSL_CSR_CLEAR_REQUEST = 'ssl/SSL_CSR_CLEAR_REQUEST';
export const SSL_CSR_CLEAR_SUCCESS = 'ssl/SSL_CSR_CLEAR_SUCCESS';
export const SSL_CSR_CLEAR_ERROR = 'ssl/SSL_CSR_CLEAR_ERROR';

export const SSL_BEACON_REQUEST = 'ssl/SSL_BEACON_REQUEST';
export const SSL_BEACON_SUCCESS = 'ssl/SSL_BEACON_SUCCESS';
export const SSL_BEACON_ERROR = 'ssl/SSL_BEACON_ERROR';

export const SSL_VALIDATION_REQUEST = 'ssl/SSL_VALIDATION_REQUEST';
export const SSL_VALIDATION_SUCCESS = 'ssl/SSL_VALIDATION_SUCCESS';
export const SSL_VALIDATION_ERROR = 'ssl/SSL_VALIDATION_ERROR';

/**********************************************************************************************************
 *   SSL RESET
 **********************************************************************************************************/

export const resetSsl = () => {
    return (dispatch) => {
        dispatch({
            type: SSL_INITIAL_STATE
        });
    };
};

/**********************************************************************************************************
 *   SSL INSTALL
 **********************************************************************************************************/

export const sslInstall = (id, attributes) => {
    return (dispatch) => {
        dispatch({
            type: SSL_INSTALL_REQUEST
        });
        API.ssl.POST.install(id, attributes)
            .then((response) => {
                const ssl_install_data = getDataFromSuccessResponse(response);
                pushNotification(getDataFromSuccessResponse(response));
                dispatch({
                    type: SSL_INSTALL_SUCCESS,
                    ssl_install_data
                });
            })
            .catch((error) => {
                const ssl_install_error = getErrorFromFailResponse(error);
                pushNotification(ssl_install_error);
                dispatch({
                    type: SSL_INSTALL_ERROR,
                    ssl_install_error
                });
            });
    };
};

export const searchHostingForDomain = (domain) => {
    return (dispatch) => {
        dispatch({
            type: SSL_HOSTING_SEARCH_REQUEST
        });
        API.ssl.GET.searchHosting(domain)
            .then((response) => {
                const ssl_hosting_search_data = getDataFromSuccessResponse(response);
                dispatch({
                    type: SSL_HOSTING_SEARCH_SUCCESS,
                    ssl_hosting_search_data
                });
            })
            .catch((error) => {
                const ssl_hosting_search_error = getErrorFromFailResponse(error);
                pushNotification(ssl_hosting_search_error);
                dispatch({
                    type: SSL_HOSTING_SEARCH_ERROR,
                    ssl_hosting_search_error
                });
            });
    };
};

/**********************************************************************************************************
 *   SSL DETAILS
 **********************************************************************************************************/

export const getSslInformation = (id) => {
    return (dispatch) => {
        dispatch({
            type: SSL_INFORMATION_REQUEST
        });
        API.ssl.GET.information(id)
            .then((response) => {
                const ssl_information_data = getDataFromSuccessResponse(response);
                dispatch({
                    type: SSL_INFORMATION_SUCCESS,
                    ssl_information_data
                });
            })
            .catch((error) => {
                const ssl_information_error = getErrorFromFailResponse(error);
                dispatch({
                    type: SSL_INFORMATION_ERROR,
                    ssl_information_error
                });
            });
    };
};

/**********************************************************************************************************
 *   SSL DOWNLOAD
 **********************************************************************************************************/

//Create the SSL .zip
export const sslDownloadZip = (id) => {
    return (dispatch) => {
        dispatch({
            type: SSL_CREATE_REQUEST
        });
        API.ssl.GET.downloadZip(id)
            .then((response) => {
                createDownloadFile(response.data, `SSL_Certificate_${id}.zip`);

                const ssl_download_data = getDataFromSuccessResponse(response);
                pushNotification('Downloading SSL Certificate', 4000);
                dispatch({
                    type: SSL_DOWNLOAD_SUCCESS,
                    ssl_download_data
                });
            })
            .catch((error) => {
                const ssl_download_error = getErrorFromFailResponse(error);
                pushNotification(ssl_download_error);
                dispatch({
                    type: SSL_DOWNLOAD_ERROR,
                    ssl_download_error
                });
            });
    };
};

/**********************************************************************************************************
 *   SSL CONFIGURATION
 **********************************************************************************************************/
export const sslConfiguration = (id, attributes) => {
    return (dispatch) => {
        dispatch({
            type: SSL_CONFIGURATION_REQUEST
        });
        if (attributes.method === 'simple') {
            API.CSR.POST.simple(id, attributes)
                .then((response) => {
                    const ssl_configuration_data = getDataFromSuccessResponse(response);
                    dispatch({
                        type: SSL_CONFIGURATION_SUCCESS,
                        ssl_configuration_data
                    });
                    dispatch(getCsrInformation(id));
                })
                .catch((error) => {
                    const ssl_configuration_error = getErrorFromFailResponse(error);
                    pushNotification(ssl_configuration_error);
                    dispatch({
                        type: SSL_CONFIGURATION_ERROR,
                        ssl_configuration_error: {
                            ...ssl_configuration_error,
                            previousRequest: attributes
                        }
                    });
                });
        } else {
            API.CSR.POST.advance(id, attributes)
                .then((response) => {
                    const ssl_configuration_data = getDataFromSuccessResponse(response);
                    dispatch({
                        type: SSL_CONFIGURATION_SUCCESS,
                        ssl_configuration_data
                    });
                    dispatch(getCsrInformation(id));
                })
                .catch((error) => {
                    const ssl_configuration_error = getErrorFromFailResponse(error);
                    pushNotification(ssl_configuration_error);
                    dispatch({
                        type: SSL_CONFIGURATION_ERROR,
                        ssl_configuration_error: {
                            ...ssl_configuration_error,
                            previousRequest: attributes
                        }
                    });
                });
        }
    };
};

/**********************************************************************************************************
 *   CSR INFORMATION
 **********************************************************************************************************/
export const getCsrInformation = (id) => {
    return (dispatch) => {
        dispatch({
            type: SSL_CSR_INFORMATION_REQUEST
        });
        API.CSR.GET.information(id)
            .then((response) => {
                const ssl_csr_information_data = getDataFromSuccessResponse(response);
                dispatch({
                    type: SSL_CSR_INFORMATION_SUCCESS,
                    ssl_csr_information_data
                });
            })
            .catch((error) => {
                const ssl_csr_information_error = getErrorFromFailResponse(error);
                pushNotification(ssl_csr_information_error);
                dispatch({
                    type: SSL_CSR_INFORMATION_ERROR,
                    ssl_csr_information_error
                });
            });
    };
};

export const csrConfirmation = (id) => {
    return (dispatch) => {
        dispatch({
            type: SSL_CSR_SUBMISSION_REQUEST
        });
        API.CSR.POST.submit(id)
            .then((response) => {
                const ssl_csr_submission_data = getDataFromSuccessResponse(response);
                dispatch({
                    type: SSL_CSR_SUBMISSION_SUCCESS,
                    ssl_csr_submission_data
                });
                dispatch(getSslBeacon(id));
            })
            .catch((error) => {
                const ssl_csr_submission_error = getErrorFromFailResponse(error);
                pushNotification(ssl_csr_submission_error);
                dispatch({
                    type: SSL_CSR_SUBMISSION_ERROR,
                    ssl_csr_submission_error
                });
                dispatch(getCsrInformation(id));
            });
    };
};

export const clearConfiguration = (id) => {
    return (dispatch) => {
        dispatch({
            type: SSL_CSR_CLEAR_REQUEST
        });
        API.CSR.POST.clear(id)
            .then((response) => {
                const ssl_csr_clear_data = getDataFromSuccessResponse(response);
                dispatch({
                    type: SSL_CSR_CLEAR_SUCCESS,
                    ssl_csr_clear_data
                });
            })
            .catch((error) => {
                const ssl_csr_clear_error = getErrorFromFailResponse(error);
                pushNotification(ssl_csr_clear_error);
                dispatch({
                    type: SSL_CSR_CLEAR_ERROR,
                    ssl_csr_clear_error
                });
            });
    };
};

/**********************************************************************************************************
 *   DETAILS
 **********************************************************************************************************/

export const getSslBeacon = (id) => {
    return (dispatch) => {
        dispatch({
            type: SSL_BEACON_REQUEST
        });
        API.ssl.GET.beacon(id)
            .then((response) => {
                const ssl_beacon_data = getDataFromSuccessResponse(response);
                dispatch({
                    type: SSL_BEACON_SUCCESS,
                    ssl_beacon_data
                });
            })
            .catch((error) => {
                const ssl_beacon_error = getErrorFromFailResponse(error);
                pushNotification(ssl_beacon_error);
                dispatch({
                    type: SSL_BEACON_ERROR,
                    ssl_beacon_error
                });
            });
    };
};

/**********************************************************************************************************
 *   VALIDATION
 **********************************************************************************************************/
export const sslValidation = (id, attributes) => {
    return (dispatch) => {
        dispatch({
            type: SSL_VALIDATION_REQUEST
        });
        if (attributes.method === 'cname') {
            API.ssl.POST.validateCname(id)
                .then((response) => {
                    const ssl_validation_data = getDataFromSuccessResponse(response);
                    dispatch({
                        type: SSL_VALIDATION_SUCCESS,
                        ssl_validation_data
                    });
                })
                .catch((error) => {
                    const ssl_validation_error = getErrorFromFailResponse(error);
                    pushNotification(ssl_validation_error);
                    dispatch({
                        type: SSL_VALIDATION_ERROR,
                        ssl_validation_error
                    });
                });
        } else if (attributes.method === 'file') {
            API.ssl.POST.validateFile(id)
                .then((response) => {
                    const ssl_validation_data = getDataFromSuccessResponse(response);
                    dispatch({
                        type: SSL_VALIDATION_SUCCESS,
                        ssl_validation_data
                    });
                })
                .catch((error) => {
                    const ssl_validation_error = getErrorFromFailResponse(error);
                    pushNotification(ssl_validation_error);
                    dispatch({
                        type: SSL_VALIDATION_ERROR,
                        ssl_validation_error
                    });
                });
        } else if (attributes.method === 'email') {
            API.ssl.POST.validateEmail(id)
                .then((response) => {
                    const ssl_validation_data = getDataFromSuccessResponse(response);
                    dispatch({
                        type: SSL_VALIDATION_SUCCESS,
                        ssl_validation_data
                    });
                })
                .catch((error) => {
                    const ssl_validation_error = getErrorFromFailResponse(error);
                    pushNotification(ssl_validation_error);
                    dispatch({
                        type: SSL_VALIDATION_ERROR,
                        ssl_validation_error
                    });
                });
        } else {
            API.ssl.POST.validateTxt(id)
                .then((response) => {
                    const ssl_validation_data = getDataFromSuccessResponse(response);
                    dispatch({
                        type: SSL_VALIDATION_SUCCESS,
                        ssl_validation_data
                    });
                })
                .catch((error) => {
                    const ssl_validation_error = getErrorFromFailResponse(error);
                    pushNotification(ssl_validation_error);
                    dispatch({
                        type: SSL_VALIDATION_ERROR,
                        ssl_validation_error
                    });
                });
        }
    };
};

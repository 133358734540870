/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { nonDirectAuTLDs } from 'config/config';

/**********************************************************************************************************
 *   METHODS
 **********************************************************************************************************/
export const auDirectMethods = {
    getContendedDomains(domainsList) {
        if (!domainsList) return [];

        return domainsList.filter(
            (domain) =>
                this.isDirectAu(domain.attributes?.domain) &&
                (['In Progress', 'Application Expiring'].includes(domain.attributes?.status) ||
                    (domain.attributes?.status === 'Pending' && domain.attributes?.invalid_rego_details))
        );
    },

    isDirectAu(domain) {
        if (typeof domain !== 'string') return false;

        return domain.endsWith('.au') && !nonDirectAuTLDs.find((tld) => domain.endsWith(tld));
    }
};

/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'utilities/methods/tanstack/router/withRouter';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import classNames from 'classnames';
import './_MobileSidebar.scss';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/

/**
 * @type {React.ComponentClass<MobileSideBar.Props>}
 */
class MobileSidebar extends Component {
    constructor() {
        super();
        this.handleClassForMobileMode = this.handleClassForMobileMode.bind(this);
        this.isSectionSelected = this.isSectionSelected.bind(this);
    }

    isSectionSelected(link) {
        const { history } = this.props;
        const { location } = history;
        const { pathname } = location;

        return link.split('#')?.at(0) === pathname;
    }

    handleClassForMobileMode() {
        const { app_viewport } = this.props;
        const rootElement = document.getElementById('root');

        if (app_viewport === 'xs' || app_viewport === 'sm') {
            if (rootElement && !rootElement.classList.contains('root--navigation-mobile')) {
                rootElement.classList.add('root--navigation-mobile');
            }
        } else {
            if (rootElement && rootElement.classList.contains('root--navigation-mobile')) {
                rootElement.classList.remove('root--navigation-mobile');
            }
        }
    }
    componentDidMount() {
        this.handleClassForMobileMode();
        window.addEventListener('resize', this.handleClassForMobileMode);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleClassForMobileMode);
    }

    render() {
        const { links, title, history } = this.props;
        const { isSectionSelected } = this;

        const renderList = () => {
            if (links && links.length > 0) {
                return links.map((item, index) => {
                    const { list_title, list_items, list_icon } = item;
                    if (list_items && list_items.length > 0) {
                        const currentItem = list_items[0];
                        const { link } = currentItem;
                        const outerClassNames = classNames('MobileSidebar__item', {
                            'MobileSidebar__item--selected': isSectionSelected(link)
                        });

                        return (
                            <div className={outerClassNames} key={index}>
                                <button
                                    onClick={(e) => {
                                        e.preventDefault();
                                        history.push(link);
                                    }}
                                    className="MobileSidebar__item-anchor"
                                >
                                    <span className={`MobileSidebar__item-icon${list_icon ? ' icon-' + list_icon : ' icon-accounts'}`} />
                                    <span className="MobileSidebar__item-text">{list_title ? list_title : title}</span>
                                </button>
                            </div>
                        );
                    }

                    return '';
                });
            }
            return '';
        };

        /*   RENDER COMPONENT
         **********************************************************************************************************/
        return (
            <div className="MobileSidebar">
                <div className="MobileSidebar__wrapper">{renderList()}</div>
            </div>
        );
    }
}

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
const mapStateToProps = (state) => {
    return {
        app_viewport: state.app.app_viewport
    };
};

export default withRouter(connect(mapStateToProps, null)(MobileSidebar));

import { Accordion } from './Accordion';
import Child from './Child';
import ToggleWrapper from './ToggleWrapper';

const TableAccordion = Object.assign(Accordion, {
    Child,
    ToggleWrapper
});

export default TableAccordion;

/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React, { Component, createRef } from 'react';
import { CsvToHtmlTable } from 'react-csv-to-table';
import { connect } from 'react-redux';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
class ExportDisplay extends Component {
    constructor(props) {
        super(props);
        this.horizontalScroll = createRef();
    }

    render() {
        const { domain_export_data, closeLightbox } = this.props;

        return (
            <div className="exportFormDisplay">
                <CsvToHtmlTable
                    id="exportFormTable"
                    data={domain_export_data}
                    csvDelimiter=","
                    tableClassName="exportFormTable"
                    ref={this.horizontalScroll}
                />
                <button type="button" className="commonlink" onClick={closeLightbox}>
                    CLOSE
                </button>
            </div>
        );
    }
}

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
const mapStateToProps = (state) => {
    return {
        domain_export_data: state.services.domain_export_data
    };
};

export default connect(mapStateToProps, null)(ExportDisplay);

/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { entries, get, has, isEqual, isObjectLike, isUndefined, keys } from 'lodash';

/**
 * Deep diff between two object-likes
 * @param  {Object} fromObject the original object
 * @param  {Object} toObject   the updated object
 * @return {Partial<Record<string, { from?: any, to?: any }>>}            a new object which represents the diff
 */
export function deepDiff(fromObject: Object, toObject: Object): Partial<Record<string, { from?: any, to?: any }>> {
    /**
     * @type {Partial<Record<string, { from?: any, to?: any }>>}
     */
    const changes: Partial<Record<string, { from?: any, to?: any }>> = {};

    /**
     * Recursively builds the path for a nested object.
     * @param {string} key - The current key being processed.
     * @param {string} [path] - The current path.
     * @returns {string} The updated path.
     */
    const buildPath = (key: string, path?: string): string => (isUndefined(path) ? key : `${path}.${key}`);

    /**
     * Recursively walks through the object and identifies differences.
     * @param {Object} fromObject - The original object.
     * @param {Object} toObject - The updated object.
     * @param {string} [path] - The current path.
     */
    const walk = (fromObject: Object, toObject: Object, path?: string) => {
        for (const key of keys(fromObject)) {
            const currentPath = buildPath(key, path);
            if (!has(toObject, key)) {
                changes[currentPath] = { from: get(fromObject, key) };
            }
        }

        for (const [key, to] of entries(toObject)) {
            const currentPath = buildPath(key, path);
            if (has(fromObject, key)) {
                const from = get(fromObject, key);
                if (!isEqual(from, to)) {
                    if (isObjectLike(to) && isObjectLike(from)) {
                        walk(from, to, currentPath);
                    } else {
                        changes[currentPath] = { from, to };
                    }
                }
            } else {
                changes[currentPath] = { to };
            }
        }
    };

    walk(fromObject, toObject);

    return changes;
}

/**
 * @template {object[]} TSections
 * @param {number[]} sectionIDs
 * @param {TSections} sections
 */
export function applyNewSectionSort(sectionIDs, sections) {
    sectionIDs.forEach((sectionID, index) => {
        const section = sections.find((section) => section.id === sectionID);
        if (section) {
            section.order = index;
        }
    });

    return sections;
}

import {
    AUTOCOMPLETE_ADDRESS_SUGGESTIONS_REQUEST,
    AUTOCOMPLETE_ADDRESS_SUGGESTIONS_SUCCESS,
    AUTOCOMPLETE_ADDRESS_SUGGESTIONS_ERROR,
    AUTOCOMPLETE_ADDRESS_DETAILS_REQUEST,
    AUTOCOMPLETE_ADDRESS_DETAILS_SUCCESS,
    AUTOCOMPLETE_ADDRESS_DETAILS_ERROR
} from './action';

const initialState = {
    autocomplete_address_suggestions_status: null,
    autocomplete_address_suggestions_data: null,
    autocomplete_address_suggestions_error: null,
    autocomplete_address_details_status: null,
    autocomplete_address_details_data: null,
    autocomplete_address_details_error: null
};

const addressAutocompleteReducer = (state = initialState, action) => {
    switch (action.type) {
        /**********************************************************************************************************
         *   AUTOCOMPLETE SUGGESTIONS
         **********************************************************************************************************/
        case AUTOCOMPLETE_ADDRESS_SUGGESTIONS_REQUEST:
            return {
                ...state,
                autocomplete_address_suggestions_status: 'loading',
                autocomplete_address_suggestions_data: null,
                autocomplete_address_suggestions_error: null
            };

        case AUTOCOMPLETE_ADDRESS_SUGGESTIONS_SUCCESS:
            return {
                ...state,
                autocomplete_address_suggestions_status: 'success',
                autocomplete_address_suggestions_data: action.autocomplete_address_suggestions_data
            };

        case AUTOCOMPLETE_ADDRESS_SUGGESTIONS_ERROR:
            return {
                ...state,
                autocomplete_address_suggestions_status: 'error',
                autocomplete_address_suggestions_error: action.autocomplete_address_suggestions_error
            };

        /**********************************************************************************************************
         *   PLACE DETAILS
         **********************************************************************************************************/
        case AUTOCOMPLETE_ADDRESS_DETAILS_REQUEST:
            return {
                ...state,
                autocomplete_address_details_status: 'loading',
                autocomplete_address_details_data: null,
                autocomplete_address_details_error: null
            };

        case AUTOCOMPLETE_ADDRESS_DETAILS_SUCCESS:
            return {
                ...state,
                autocomplete_address_details_status: 'success',
                autocomplete_address_details_data: action.autocomplete_address_details_data
            };

        case AUTOCOMPLETE_ADDRESS_DETAILS_ERROR:
            return {
                ...state,
                autocomplete_address_details_status: 'error',
                autocomplete_address_details_error: action.autocomplete_address_details_error
            };

        default:
            return state;
    }
};

export default addressAutocompleteReducer;

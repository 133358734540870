/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React from 'react';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import Text from 'components/Utils/Text';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import _SideBarChildMenuItemStyledLoader from './_styledLoader';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * @type {React.FC<NSideBarChildMenuItem.Props>}
 */
const _SideBarChildMenuItem = ({ title, onClick, isCurrentRoute }) => {
    /***** RENDER HELPERS *****/
    const menuItemProps = {
        primary: isCurrentRoute,
        secondary: !isCurrentRoute
    };

    /***** RENDER *****/
    return (
        <button type="button" className="ContentEditorSidebar__childMenuItem" onClick={onClick}>
            <Text {...menuItemProps} size--s semiBold align--left>
                {title}
            </Text>
        </button>
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
const SideBarChildMenuItem = Object.assign(_SideBarChildMenuItem, {
    StyledLoader: _SideBarChildMenuItemStyledLoader
});

export { SideBarChildMenuItem };

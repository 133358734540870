/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React, { useCallback, useRef, useState } from 'react';
import _ from 'lodash';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import { CheckBox } from 'components/Form/CheckBox';
import { Revealer } from 'components/Revealer';
import { Flex } from 'components/Utils/Flex';
import { Input } from 'components/Form/Input';
import { Fragment } from 'react';
import SolidButton from 'components/Buttons/SolidButton';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import { KatanaFileFormFieldChooseStockPhotoDecoupled } from 'containers/katana/formFields/file/inputTypes/dropzone_or_stock_photo/KatanaFileFormFieldChooseStockPhotoDecoupled';
import { _NXDropZoneWithKatanaFileUpload as NXDropZoneWithKatanaFileUpload } from 'components/Form/NXDropZone/wrappers/withKatanaFileUpload';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { useGetCaveat } from 'containers/katana/formFields/methods/useGetCaveat';
import { useKatanaParams } from 'containers/katana/hooks/useSetupEditorRouteParams';
import { transformToVideoEmbed, transformAttachmentDataToLink } from 'containers/katana/queries/methods/attachmentData';
import { formFieldUrlRuleParser } from 'containers/katana/formFields/methods/validations/ruleParsers/formFieldUrlRuleParser';
import { formFieldYouTubeUrlParser } from 'containers/katana/formFields/methods/validations/ruleParsers/formFieldYouTubeUrlParser';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import { CaveatTypes } from 'containers/katana/formFields/caveatTypes';
import './_KatanaFileFormFieldMediaDecoupled.scss';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
import type { KatanaNamespace } from 'containers/katana/types';
import type { UseKatanaFileUpload } from 'components/Form/NXDropZone/wrappers/withKatanaFileUpload/types';
import type { KatanaAPI } from 'utilities/api/katana/types';
import type { CardSelectGridNamespace } from 'components/Form/CardSelectGrid/types';
import type { MediaFeatures } from 'containers/katana/components/EntryCreationBehaviourHandler/types';
import type { DropzoneRef } from 'react-dropzone';
import type { ChangeEvent } from 'react';

type KatanaFileFormFieldMediaDecoupledProps = {
    property: KatanaNamespace.SectionDefinitions.PropertiesModified;
    onEmitValue: (
        a: KatanaAPI.Katana.ServiceID.Uploads.POST.AttachmentData | KatanaNamespace.FileAttachmentDataLink | KatanaNamespace.MediaDataEmbed
    ) => void;
    activeFeature?: MediaFeatures;
    setActiveFeature?: (feature: MediaFeatures) => void;
};

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
const KatanaFileFormFieldMediaDecoupled: React.FC<KatanaFileFormFieldMediaDecoupledProps> = ({
    property,
    onEmitValue,
    activeFeature,
    setActiveFeature
}) => {
    const { key, caveats } = property;

    /***** STATE *****/
    const [youtubeLinkValue, setYoutubeLinkValue] = useState<string>();
    const [validationResult, setValidationResult] = useState<string | undefined>(undefined);

    /***** HOOKS *****/
    const { katanaServiceId } = useKatanaParams();
    const zoneRef = useRef<DropzoneRef>();

    /***** FUNCTIONS *****/
    const handleOnEqualIdentifierLogoClick = useCallback(() => {
        // Open Dropzone
        if (zoneRef.current) {
            zoneRef.current?.open?.();
        }
    }, []);

    function handleOnChangeUploadImage(value: UseKatanaFileUpload.Values) {
        if (_.isArray(value)) {
            onEmitValue(value[0]);
        } else {
            onEmitValue(value);
        }
    }

    function handleOnChangeStockPhoto(value: CardSelectGridNamespace.SelectCard.OnChangeValue) {
        onEmitValue(transformAttachmentDataToLink(value));
    }

    function handleOnChangeEmbedVideo(e: ChangeEvent<HTMLInputElement>) {
        setYoutubeLinkValue(e.target.value);
    }

    function handleOnClick() {
        onEmitValue(transformToVideoEmbed(youtubeLinkValue ? youtubeLinkValue : ''));
        setYoutubeLinkValue('');
    }

    /***** RENDER HELPERS *****/
    const mediaFeatureRender = useGetCaveat(CaveatTypes.MEDIA_FEATURES, caveats, []);
    if (!_.isArray(mediaFeatureRender)) {
        return 'No Media Features Found';
    }

    /***** RENDER *****/
    return (
        <Flex direction="column" gap={2} className="KatanaFileFormFieldMediaDecoupled">
            {mediaFeatureRender.map((element: { id: string }) => {
                switch (element.id) {
                    case 'upload_image':
                        return (
                            <Fragment key={element.id}>
                                <CheckBox.Panel
                                    type="circle"
                                    isChecked={activeFeature === 'upload_image'}
                                    onIsCheckedClick={handleOnEqualIdentifierLogoClick}
                                    onChange={(v) => {
                                        if (!v) {
                                            return;
                                        }
                                        if (setActiveFeature) {
                                            setActiveFeature('upload_image');
                                        }
                                    }}
                                >
                                    Upload your own photo
                                </CheckBox.Panel>
                                <Revealer isOpen={activeFeature === 'upload_image'}>
                                    <NXDropZoneWithKatanaFileUpload
                                        serviceID={katanaServiceId}
                                        onChange={handleOnChangeUploadImage}
                                    ></NXDropZoneWithKatanaFileUpload>
                                </Revealer>
                            </Fragment>
                        );
                    case 'stock_image':
                        return (
                            <Fragment key={element.id}>
                                <CheckBox.Panel
                                    type="circle"
                                    isChecked={activeFeature === 'stock_image'}
                                    onChange={(v) => {
                                        if (!v) {
                                            return;
                                        }
                                        if (setActiveFeature) {
                                            setActiveFeature('stock_image');
                                        }
                                    }}
                                >
                                    Pick a stock photo
                                </CheckBox.Panel>
                                <Revealer isOpen={activeFeature === 'stock_image'}>
                                    <KatanaFileFormFieldChooseStockPhotoDecoupled fieldName={key} onChange={handleOnChangeStockPhoto} />
                                </Revealer>
                            </Fragment>
                        );
                    case 'embed_video':
                        return (
                            <Fragment key={element.id}>
                                <CheckBox.Panel
                                    type="circle"
                                    isChecked={activeFeature === 'embed_video'}
                                    onChange={(v) => {
                                        if (!v) {
                                            return;
                                        }
                                        if (setActiveFeature) {
                                            setActiveFeature('embed_video');
                                        }
                                    }}
                                >
                                    Embed a YouTube video
                                </CheckBox.Panel>
                                <Revealer
                                    isOpen={activeFeature === 'embed_video'}
                                    className="KatanaFileFormFieldMediaDecoupled__mediaEmbedVideoRevealer"
                                >
                                    <Input.Decoupled
                                        label="YouTube Link"
                                        name={key}
                                        placeholder="E.g. https://www.youtube.com/watch?v=SFuqgn_Pm90"
                                        intrinsicProps={{ onChange: handleOnChangeEmbedVideo }}
                                        validate={[formFieldUrlRuleParser({ name: 'url', value: [] }), formFieldYouTubeUrlParser]}
                                        value={youtubeLinkValue ? youtubeLinkValue : ''}
                                        onEmitValidationResult={setValidationResult}
                                        fullWidth={true}
                                        className="RevealerListContainer__FormItem-grid"
                                    >
                                        <SolidButton
                                            type="onClick"
                                            onClick={handleOnClick}
                                            className="KatanaFileFormField__addButton"
                                            disabled={!!validationResult}
                                        >
                                            Add
                                        </SolidButton>
                                    </Input.Decoupled>
                                </Revealer>
                            </Fragment>
                        );
                    default:
                        return null;
                }
            })}
        </Flex>
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

export { KatanaFileFormFieldMediaDecoupled };

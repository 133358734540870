/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { isAttachmentDataEmbed } from 'containers/katana/queries/methods/attachmentData';
import { formFieldYouTubeUrlParser } from 'containers/katana/formFields/methods/validations/ruleParsers/formFieldYouTubeUrlParser';

export function videoEmbedValidation(value: unknown) {
    if (isAttachmentDataEmbed(value)) {
        return formFieldYouTubeUrlParser(value.url);
    }
    return 'incorrect format';
}

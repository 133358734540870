/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { createRoute } from '@tanstack/react-router';

/**********************************************************************************************************
 *   ROUTE IMPORTS
 **********************************************************************************************************/
import { EmailManageRoute } from 'containers/email';
import { routerMiddleware } from 'router/utils/middleware';

/*   ACTIONS
 *****************************************************/
import { getEmailMailboxInformation } from 'containers/email/action';

/**********************************************************************************************************
 *   ROUTE START
 **********************************************************************************************************/
export const EmailHostingMailboxRoute = createRoute({
    getParentRoute: () => EmailManageRoute,
    path: 'mailbox/$mailboxId',
    loader({ context, params, cause }) {
        if (cause === 'enter') {
            return getEmailMailboxInformation(params.mailboxId)(context.store.dispatch);
        }
    },
    beforeLoad(opts) {
        routerMiddleware.business(this, opts);
        routerMiddleware.authentication('user', opts);
    }
});

/**********************************************************************************************************
 *  BASE IMPORT
 **********************************************************************************************************/
import { application, company } from 'config/config';
import CompanyLogo from 'config/images/footer.svg';
import AppLogo from 'config/images/header.svg';
import { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'utilities/methods/tanstack/router/withRouter';

/**********************************************************************************************************
 *   COMPONENTS
 **********************************************************************************************************/
import LoginContact from '../forms/LoginContact';
import LoginSecurity from '../forms/LoginSecurity';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import Anchor from 'components/Anchor';
import RequestLoader from 'components/Loaders/Request';

/*   ACTIONS
 *****************************************************/
import { createRoute, redirect, useNavigate } from '@tanstack/react-router';
import { AppRoute } from 'App';
import { loadAppConfig } from 'App/action';
import SolidButton from 'components/Buttons/SolidButton';
import { ACCOUNT_APPROVE_ADDITIONAL_USER_RESET, approveSignup } from 'containers/account/action';
import { getSecurityQuestions, getUserSecurityInformation } from 'containers/login/action';
import { batchActions } from 'redux-batched-actions';
import { routerMiddleware } from 'router/utils/middleware';
import store from 'store/store';
import { z } from 'zod';
import { SERVICE_MOVE_APPROVE_REQUEST_RESET, registerMoveService } from '../../services/action';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
export const activateAccountSchema = z.object({
    template: z
        .union([
            z.literal('additional-user'), //
            z.literal('expired-token'),
            z.literal('move-service'),
            z.literal('invalid'),
            z.literal('expired-move')
        ])
        .optional()
});

/**********************************************************************************************************
 *   ROUTE START
 **********************************************************************************************************/
export const ActivateAccountRoute = createRoute({
    getParentRoute: () => AppRoute,
    path: '/activate',
    beforeLoad(opts) {
        routerMiddleware.business(this, opts);

        if (!opts.search.template) {
            throw redirect({ to: '/login' });
        }
    },
    validateSearch: activateAccountSchema
});

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
class ActivateAccount extends Component {
    constructor(props) {
        super(props);
        this.state = {
            user: null,
            currentView: 'loading',
            securityQuestions: {},
            accessToken: false
        };
        this.changeView = this.changeView.bind(this);
        this.handleContactSubmit = this.handleContactSubmit.bind(this);
        this.handleSecuritySubmit = this.handleSecuritySubmit.bind(this);
    }

    changeView(view) {
        this.setState({
            currentView: view
        });
    }

    handleSecuritySubmit(values) {
        const { template } = this.props;
        const { changeView } = this;
        if (template === 'additional-user' || template === 'move-service') {
            this.setState(
                {
                    user: {
                        password: values.password,
                        password_confirmation: values.password_confirmation,
                        security_question_id: values.question_id,
                        security_answer: values.answer
                        // dob: values.dob
                    }
                },
                () => {
                    changeView('user');
                }
            );
        }
    }

    handleContactSubmit(values) {
        const { template, approveSignup, registerMoveService } = this.props;
        const params = new URLSearchParams(window.location.search);

        // Clear the previous error state so we don't get unwanted redirects after successful signup
        store.dispatch({
            type: ACCOUNT_APPROVE_ADDITIONAL_USER_RESET
        });
        if (template === 'additional-user') {
            const params = new URLSearchParams(window.location.search);
            const invitation_token = params.get('token');

            const user = {
                ...this.state.user,
                ...values,
                // dob: DateTime.fromJSDate(this.state.user.dob || '').toFormat('dd/LL/yyyy')
                dob: null
            };

            approveSignup(user, invitation_token);
        }

        if (template === 'move-service') {
            const move_token = params.get('move_token');
            const user = {
                ...this.state.user,
                ...values,
                // dob: DateTime.fromJSDate(this.state.user.dob || '').toFormat('dd/LL/yyyy')
                dob: null
            };

            registerMoveService(user, move_token);
        }
    }

    componentDidMount() {
        const { match, app_user_data, template, getUserSecurityInformation, getSecurityQuestions } = this.props;
        const { params } = match;
        const { token } = params;
        const { changeView } = this;

        if (template === 'expired-token') return changeView('expired');
        if (template === 'expired-move') return changeView('expired-move');
        if (template === 'invalid') return changeView('invalid');

        getSecurityQuestions();

        if (template !== 'additional-user' && template !== 'move-service') getUserSecurityInformation();

        this.setState({
            user: app_user_data,
            accessToken: token
        });
    }

    componentDidUpdate(prevProps) {
        const {
            template,
            login_security_questions_status,
            login_security_questions_data,
            account_approve_additional_user_signup_status,
            service_move_register_status,
            service_move_register_error,
            navigate
        } = this.props;
        const { changeView } = this;
        if (template === 'additional-user' || template === 'move-service') {
            if (prevProps.login_security_questions_status === 'loading' && login_security_questions_status === 'success') {
                const securityQuestions = login_security_questions_data.map((q) => {
                    return {
                        value: q.id,
                        label: q.attributes.question
                    };
                });

                this.setState(
                    {
                        securityQuestions
                    },
                    () => {
                        changeView('security');
                    }
                );
            }
        }

        if (account_approve_additional_user_signup_status === 'error' && prevProps.account_approve_additional_user_signup_status === 'loading') {
            changeView('user');
        }

        //prevProps.account_approve_additional_user_signup_status never enters 'loading' state for unknown reasons.  It shouldn't matter because once account_approve_additional_user_signup_status === 'success' the user has left the page
        if (account_approve_additional_user_signup_status === 'success' || service_move_register_status === 'success') {
            navigate({ to: '/dashboard' });
        }

        if (service_move_register_status === 'error' && prevProps.service_move_register_status === 'loading') {
            const { details } = service_move_register_error;
            if (details === 'Token invalid.') {
                changeView('invalid');
            } else {
                changeView('user');
            }
        }
    }

    render() {
        const { template, type, navigate } = this.props;
        const { currentView, securityQuestions, user } = this.state;
        const { handleContactSubmit, handleSecuritySubmit } = this;

        const handleNavigateDashboard = () => {
            //Reset ActivateAccount related Redux State
            store.dispatch(
                batchActions([
                    {
                        type: SERVICE_MOVE_APPROVE_REQUEST_RESET
                    },
                    {
                        type: ACCOUNT_APPROVE_ADDITIONAL_USER_RESET
                    }
                ])
            );

            // App config hasn't loaded if we went directly to this page, so we need to load it before they navigate to dashboard
            loadAppConfig({
                onError: () => navigate({ to: '/dashboard' }),
                onSuccess: () => navigate({ to: '/dashboard' })
            });
        };

        const renderBreadcrumb = () => (
            <div className="loginActivate__step">
                <div className="loginActivate__breadcrumb">
                    <div className="breadcrumb__list">
                        <div className={`breadcrumb__item ${currentView === 'security' ? ' breadcrumb__item--selected ' : ''}`}>
                            <div className="breadcrumb__circle" />
                            <div className="breadcrumb__label">Security Info</div>
                        </div>
                        <div className={`breadcrumb__item ${currentView === 'user' ? ' breadcrumb__item--selected ' : ''}`}>
                            <div className="breadcrumb__circle" />
                            <div className="breadcrumb__label">Contact Details</div>
                        </div>
                    </div>
                </div>
            </div>
        );

        const handleCurrentView = () => {
            switch (currentView) {
                case 'security':
                    return (
                        <Fragment>
                            {renderBreadcrumb()}
                            <div className="loginActivate__container">
                                <div className="loginActivate__title">
                                    <div className="title__text">Welcome to {application}!</div>
                                    <div className="title__desc">
                                        Thanks for choosing us as your hosting provider. Before you get started, we need to grab some details from
                                        you.
                                    </div>
                                </div>
                                <div className="loginActivate__security">
                                    <LoginSecurity
                                        template={template}
                                        options={securityQuestions}
                                        currentValues={user}
                                        onSubmit={handleSecuritySubmit}
                                    />
                                </div>
                            </div>
                        </Fragment>
                    );

                case 'user':
                    return (
                        <Fragment>
                            {renderBreadcrumb()}
                            <div className="loginActivate__container">
                                <div className="loginActivate__title">
                                    <div className="title__text">Welcome to {application}!</div>
                                    <div className="title__desc">
                                        Thanks for choosing us as your hosting provider. Before you get started, we need to grab some details from
                                        you.
                                    </div>
                                </div>
                                <div className="loginActivate__contact">
                                    <LoginContact template={template} onSubmit={handleContactSubmit} />
                                    <Anchor className="loginActivate__contactBackLink" onClick={() => this.changeView('security')}>
                                        Back to Security Info
                                    </Anchor>
                                </div>
                            </div>
                        </Fragment>
                    );

                case 'expired':
                    return (
                        <div className="loginActivate__expired">
                            <div className="loginActivate__expired--heading">Your invitation to join this account has expired.</div>
                            <div className="loginActivate__expired--description">
                                Please contact the account owner, who will be required to resend the invitation link.
                            </div>
                            <SolidButton type="onClick" className="loginActivate__contactBackLink--center" onClick={handleNavigateDashboard}>
                                Dashboard
                            </SolidButton>
                        </div>
                    );

                case 'expired-move':
                    return (
                        <div className="loginActivate__expired">
                            <div className="loginActivate__expired--heading">Your invitation to manage this service has expired.</div>
                            <div className="loginActivate__expired--description">
                                Please contact the service owner, who will be required to resend the invitation link.
                            </div>
                            <SolidButton type="onClick" className="loginActivate__contactBackLink--center" onClick={handleNavigateDashboard}>
                                Dashboard
                            </SolidButton>
                        </div>
                    );

                case 'invalid':
                    return (
                        <div className="loginActivate__expired">
                            <div className="loginActivate__expired--heading">
                                Your {type === 'additional_user' ? '' : 'move service'} token is invalid.
                            </div>
                            <div className="loginActivate__expired--description">
                                Please contact the account owner, who will be required to resend the invitation link.
                            </div>
                            <SolidButton type="onClick" className="loginActivate__contactBackLink--center" onClick={handleNavigateDashboard}>
                                Dashboard
                            </SolidButton>
                        </div>
                    );

                case 'loading':
                default:
                    return <RequestLoader />;
            }
        };

        /*   RENDER COMPONENT
         **********************************************************************************************************/
        return (
            <div className="loginActivate">
                <div className="loginActivate__header">
                    <img src={CompanyLogo} className="header__cologo" alt={company} />
                    <img alt={application} className="header__logo" src={AppLogo} />
                </div>
                {handleCurrentView()}
            </div>
        );
    }
}

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
const OwnActivateAccount = withRouter(
    connect(
        (state) => ({
            app_user_data: state.app.app_user_data,
            login_security_questions_status: state.login.login_security_questions_status,
            login_security_questions_data: state.login.login_security_questions_data,
            account_approve_additional_user_signup_status: state.account.account_approve_additional_user_signup_status,
            service_move_register_status: state.services.service_move_register_status,
            service_move_register_error: state.services.service_move_register_error
        }),
        {
            getSecurityQuestions,
            getUserSecurityInformation,
            approveSignup,
            registerMoveService
        }
    )(ActivateAccount)
);

/**********************************************************************************************************
 *   ROUTE COMPONENT
 **********************************************************************************************************/
const ActivateAccountRouteComponent = () => {
    const { template } = ActivateAccountRoute.useSearch();
    const navigate = useNavigate();

    return <OwnActivateAccount template={template} navigate={navigate} />;
};

ActivateAccountRoute.update({
    component: ActivateAccountRouteComponent
});

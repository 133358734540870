/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { useLocation, useNavigate, useParams } from '@tanstack/react-router';
import { useEffect, useState } from 'react';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import { CancelInvoiceLightbox } from './cancelInvoiceLightbox';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import OverlayLightbox from 'components/Lightboxes/OverlayLightbox';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import type { AppSchema } from 'App';
import type { NXUtils } from 'utilities/NXUtils';
import { INVOICE_ACTIONS } from './methods';

export const INVOICE_LIGHTBOX_FROM = '/billing/invoices/{{id}}';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * Show the pay / cancel invoice lightbox if the URL is "/billing/invoices/{{ id }}/{{ action }}", where the action is one of the allowed "ACTIONS"
 *
 * This component manually stores the history so that it can determine if there is something to go back to or should use a default.
 *
 * TODO: Now that we have moved to @tanstack-router we can move this component to the route and use the routers state to determine if there is a history to go back to.
 * Note: I don't believe this is exposed from the useRouterState hook directly but useRouter gives us access to the __store which should have the history
 */
function InvoiceUrlLightbox() {
    /***** STATE *****/
    const [history, setHistory] = useState<Array<string>>([]);

    /***** HOOKS *****/
    const navigate = useNavigate();
    const { action, isInvoiceRoute, pathname } = useLocation({
        select: ({ pathname }) => ({
            pathname,
            isInvoiceRoute: /^\/billing\/invoice(s?)\/\d+\/(pay|cancel)$/g.test(pathname),
            action: (pathname.split('/')?.at(-1) ?? '') as NXUtils.ValueOf<typeof INVOICE_ACTIONS>
        })
    });
    const invoiceId = useParams({
        strict: false,
        // Fallback to attempting to access the invoiceId from the path
        select: ({ invoiceId }) => invoiceId ?? pathname.split('/').at(-2)
    });

    /***** FUNCTIONS *****/
    // If the user came from another url, send them back there. If not, fallback to invoices list
    const closeLightbox = (action: AppSchema['invoice-action']) => {
        function getUrlToGoBackTo() {
            if (history.length < 2 || history[1] === pathname) return '/billing/invoices';
            return history[1];
        }

        navigate({
            to: getUrlToGoBackTo(),
            search: { 'invoice-action': action }
        });
    };

    /***** EFFECTS *****/
    useEffect(() => {
        setHistory([pathname, ...history].slice(0, 2));
    }, [pathname]);

    /***** RENDER *****/
    // Only show the lightbox if the url fits the correct format
    if (!isInvoiceRoute || !invoiceId || !Object.values(INVOICE_ACTIONS).includes(action)) {
        return '';
    }

    return (
        <>
            {action === INVOICE_ACTIONS.PAY && (
                <OverlayLightbox
                    onOpen
                    invoiceid={invoiceId}
                    title={`Pay Invoice #${invoiceId}`}
                    onClose={closeLightbox}
                    onSuccessClose={() => closeLightbox('pay')}
                />
            )}
            {action === INVOICE_ACTIONS.CANCEL ? <CancelInvoiceLightbox id={invoiceId} closeLightbox={() => closeLightbox('cancel')} /> : ''}
        </>
    );
}
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

export default InvoiceUrlLightbox;

/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React from 'react';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import './__NXCardContent.scss';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type NXCardProps = {
    children: React.ReactNode;
    onClick?: () => void;
};

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
const _NXCardContent: React.FC<NXCardProps> = ({ children, onClick }) => {
    if (onClick)
        return (
            <button type="button" className="NXCardContent" onClick={onClick}>
                {children}
            </button>
        );

    return <div className="NXCardContent">{children}</div>;
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

export { _NXCardContent };

/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { createRoute } from '@tanstack/react-router';

/**********************************************************************************************************
 *   ROUTE IMPORTS
 **********************************************************************************************************/
import { MyServicesRoute } from 'containers/services';
import { routerMiddleware } from 'router/utils/middleware';
import { lazyFN } from 'utilities/methods/tanstack/router/lazyFn';

/**********************************************************************************************************
 *   ROUTE START
 **********************************************************************************************************/
export const VIPSitesRegisterDomainRoute = createRoute({
    getParentRoute: () => MyServicesRoute,
    path: 'vip-sites/register/$katanaTrialDomain',
    beforeLoad(opts) {
        routerMiddleware.business({ path: '/my-services/vip-sites' }, opts);
        routerMiddleware.business(this, opts);
        routerMiddleware.authentication('user', opts);
    }
}).lazy(lazyFN(() => import('./register.$katanaTrialDomain.lazy'), 'LazyVIPSitesRegisterDomainRoute'));

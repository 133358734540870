/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import _ from 'lodash';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { findValidationObjectByPath } from 'containers/katana/formFields/methods/validations/findValidationObjectByPath';
import { requiredFieldValidation } from 'utilities/methods/form';

/**********************************************************************************************************
 *   TYPE IMPORTS
 **********************************************************************************************************/
import { getFormFieldValidationValue } from 'containers/katana/formFields/methods/validations/getFormFieldValidationValue';
import type { KatanaRuleParserValidationParams } from 'containers/katana/formFields/methods/validations/types';

export function formFieldsRequiredUnlessRuleParser({ validation, formValues, property }: KatanaRuleParserValidationParams) {
    if (_.has(validation, 'value.0') && _.has(validation, 'value.1') && _.has(formValues, 'properties')) {
        const findObjectByPath = findValidationObjectByPath({ formValues, property });
        const validationKey: string = validation.value[0];
        const validationValue = validation?.value?.[1];

        // Because Laravel is silly we have to do a boolean or string manipulation here.
        const parsedValidationValue = getFormFieldValidationValue(validationValue);

        const foundObject = findObjectByPath(validation, validationKey);
        const foundValue = _.get(foundObject, validationKey);

        const isRequired = Array.isArray(validationValue) ? validationValue.includes(foundValue) : foundValue === parsedValidationValue;

        if (!isRequired) {
            return requiredFieldValidation;
        }

        const hasKey = _.has(formValues.properties, validationKey);
        const theValue = _.get(formValues.properties, validationKey);

        if (hasKey && theValue !== parsedValidationValue) {
            return requiredFieldValidation;
        }
    }
    return undefined;
}

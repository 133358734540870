/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { useEffect, useState } from 'react';

/**********************************************************************************************************
 *   TYPE IMPORTS
 **********************************************************************************************************/
import type { UseTextSelectionNamespace } from 'utilities/hooks/useTextSelection/types';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
const getRangesFromSelection = (selection: Selection): Range[] => {
    const rangeCount = selection.rangeCount;
    return Array.from({ length: rangeCount }, (_, i) => selection.getRangeAt(i));
};

const isSelectionInsideRef = (selection: Selection, element: Element) => {
    if (!element || selection.rangeCount === 0) return true;

    const range = selection.getRangeAt(0);
    return element.contains(range.commonAncestorContainer);
};

/**********************************************************************************************************
 *   HOOK START
 **********************************************************************************************************/
export function useTextSelection(element?: Element | null): UseTextSelectionNamespace.Data {
    /***** STATE *****/
    const [selection, setSelection] = useState<Selection | null>(null);
    const [text, setText] = useState('');
    const [ranges, setRanges] = useState<Range[]>([]);
    const [rects, setRects] = useState<DOMRect[]>([]);

    /***** EFFECTS *****/
    useEffect(() => {
        const onSelectionChange = () => {
            const newSelection = window.getSelection();

            if (newSelection && (!element || isSelectionInsideRef(newSelection, element))) {
                setSelection(newSelection);
                setText(newSelection.toString());
                const newRanges = getRangesFromSelection(newSelection);
                setRanges(newRanges);
                setRects(newRanges.map((range) => range.getBoundingClientRect()));
            } else {
                setText('');
                setRanges([]);
                setRects([]);
                setSelection(null);
            }
        };

        document.addEventListener('selectionchange', onSelectionChange);

        return () => {
            document.removeEventListener('selectionchange', onSelectionChange);
        };
    }, [element]);

    /***** HOOK RESULTS *****/
    return {
        text,
        rects,
        ranges,
        selection
    };
}
/**********************************************************************************************************
 *   HOOK END
 **********************************************************************************************************/

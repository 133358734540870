/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React from 'react';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import './_Code.scss';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * @type {React.FC<{
 *      children: React.ReactNode
 * }>}
 */
export const Code = ({ children }) => <code className="Code">{children}</code>;

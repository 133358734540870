/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { createRoute } from '@tanstack/react-router';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { lazyFN } from 'utilities/methods/tanstack/router/lazyFn';

/**********************************************************************************************************
 *   ROUTE IMPORTS
 **********************************************************************************************************/
import { ManageSSLRoute } from 'containers/ssl';
import { routerMiddleware } from 'router/utils/middleware';

/**********************************************************************************************************
 *   ROUTE START
 **********************************************************************************************************/
/**
 * The validate route is not rendered within a module. Instead it is a standalone route that shares the
 * configuration component because of legacy grug decisions. In the sake of preventing a larger refactor,
 * This remains like this until we have dedicated time to move the "setup" flow to all being on one route
 * or split it to multiple routes with multiple components.
 */
export const SSLValidateRoute = createRoute({
    getParentRoute: () => ManageSSLRoute,
    path: 'validate',
    beforeLoad(opts) {
        routerMiddleware.business(this, opts);
        routerMiddleware.authentication('user', opts);
    }
}).lazy(lazyFN(() => import('./validate.lazy'), 'LazySSLValidateRoute'));

/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { useMutation } from '@tanstack/react-query';
import { DateTime } from 'luxon';
import queryClient from 'store/queryClient';

/*   ACTIONS
 *****************************************************/
import { loginGetUser } from 'App/action';
import { pushNotification } from 'components/Toast/functions';

/**********************************************************************************************************
 *   QUERIES
 **********************************************************************************************************/
/***** MEGAMAY24 START *****/
import { invalidateMegaMayAvailableSpinsQuery } from 'config/containers/promotions/megamay2024/queries';
/***** MEGAMAY24 END *****/

import { useGetFilteredAndPaginatedInvoiceListInfiniteBoilerPlate } from 'containers/billing/queries/invoice/useGetFilteredAndPaginatedInvoiceListInfiniteQuery';
import { useGetInvoiceBoilerPlate } from 'containers/billing/queries/invoice/useGetInvoiceQuery';
import { useGetMergeableInvoicesQuery } from 'containers/billing/queries/invoice/useGetMergeableInvoicesQuery';
import { useSearchInvoiceByKeywordBoilerPlate } from 'containers/billing/queries/invoice/useSearchInvoiceByKeywordQuery';
import { useOverdueInvoicesQueryBoilerPlate } from 'containers/dashboard/queries/useOverdueInvoicesQuery';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { API as ACCOUNT_API } from 'utilities/api/account';
import { cloneObject, createDownloadFile, handleDefaultErrorNotification, handleDefaultSuccessNotification } from 'utilities/methods/commonActions';
import { handleInvalidateOptimisticSingleInvoiceQueryData } from './methods';

/**********************************************************************************************************
 *   INVOICE QUERIES - Single
 **********************************************************************************************************/

// IMPROVE: This function should not be here, but we need a better way of handling the query keys, because if we move this file into a separate file and reference the query keys inside this file then we will have a circular dependency when attempting to call this function.
/**
 * @deprecated use v5 format, see katanaQuery
 * Invalidates the useGetInvoiceSingle query and associated queries for the specified invoice ID(s).
 * If the invoice ID is an array, it invalidates the queries for each ID individually.
 * If the invoice ID is a single value, it invalidates the queries for that ID.
 *
 * @param {*} data - The data (not used in the function).
 * @param {*} error - The error (not used in the function).
 * @param {number | string | Array<number | string>} invoiceID - The invoice ID(s) to invalidate the queries for.
 * @param {*} context - The context (not used in the function).
 */
export function handleInvalidateUseGetInvoiceSingleQuery(data, error, invoiceID, context) {
    if (error) return;

    const associatedQueries = [
        useGetFilteredAndPaginatedInvoiceListInfiniteBoilerPlate.createQueryKey(),
        useSearchInvoiceByKeywordBoilerPlate.createQueryKey(),
        useGetMergeableInvoicesQuery.createQueryKey()
    ];

    /**
     * @param {string|number} id
     */
    const invalidateSingleInvoiceQuery = (id) => {
        associatedQueries.forEach((queryKey) => {
            handleInvalidateOptimisticSingleInvoiceQueryData(queryKey, id);
        });
        const invoiceQueryKey = useGetInvoiceBoilerPlate.createQueryKey(id);
        const data = queryClient.getQueryData(invoiceQueryKey);

        /**
         * If there's no data, the query has never been called, so we don't need to do any updates to it.
         */
        if (!data) return;

        queryClient
            .cancelQueries({ queryKey: invoiceQueryKey })
            .then(() => {
                queryClient.setQueryData(invoiceQueryKey, (oldQueryResponse) => {
                    if (!oldQueryResponse) return;
                    const clonedOldQueryResponse = cloneObject(oldQueryResponse);
                    clonedOldQueryResponse.data.isDataUpdating = true;
                    return clonedOldQueryResponse;
                });
            })
            .finally(() => {
                queryClient.invalidateQueries({ queryKey: invoiceQueryKey });
            })
            .catch((error) => {
                console.error(error);
            });
    };

    if (Array.isArray(invoiceID)) {
        invoiceID.forEach(invalidateSingleInvoiceQuery);
    } else {
        invalidateSingleInvoiceQuery(invoiceID);
    }
}

/**
 * User Invoice Apply Credit
 */
export function useApplyCreditToInvoiceMutation(options = {}) {
    return useMutation({
        mutationFn: ACCOUNT_API.user.invoice.single.apply_credit.PUT,
        onSettled: handleInvalidateUseGetInvoiceSingleQuery,
        ...options
    });
}

export const PAY_INVOICE = 'billing/PAY_INVOICE';
/**
 * User Invoice Pay
 */
export function usePayInvoiceMutation(options = {}) {
    return useMutation({
        mutationKey: [PAY_INVOICE],
        mutationFn: ACCOUNT_API.user.invoice.single.pay.POST,
        onSettled: (data, error, { invoiceID }, context) => {
            handleInvalidateUseGetInvoiceSingleQuery(data, error, invoiceID, context);
            useOverdueInvoicesQueryBoilerPlate.invalidate();
        },
        /***** MEGAMAY24 START *****/
        onSuccess: () => {
            invalidateMegaMayAvailableSpinsQuery();
        },
        /***** MEGAMAY24 END *****/
        ...options
    });
}

/**
 * Extend the invoice
 */
export function useExtendInvoiceMutation(options = {}) {
    return useMutation({
        mutationFn: ACCOUNT_API.user.invoice.single.extend.POST,
        ...options,
        onSettled: (...args) => {
            handleInvalidateUseGetInvoiceSingleQuery(...args);
            options?.onSettled?.(...args);
        }
    });
}

/**
 * Download the invoice as a pdf
 */
export function useDownloadInvoiceMutation(options = {}) {
    return useMutation({
        mutationFn: ACCOUNT_API.user.invoice.single.download.POST,
        onSuccess: (response, id) => {
            if (response instanceof Blob && response.type === 'application/pdf') {
                createDownloadFile(response, `invoice_${id}.pdf`);
            } else {
                handleDefaultSuccessNotification(response);
            }
        },
        ...options
    });
}

/**
 * Cancel an invoice
 */
export function useCancelInvoiceMutation(options = {}) {
    return useMutation({
        mutationFn: ACCOUNT_API.user.invoice.single.cancel.POST,
        onSettled: handleInvalidateUseGetInvoiceSingleQuery,
        ...options
    });
}

/**
 * Sends the invoice by email to the user
 */
export function useEmailInvoiceMutation(options = {}) {
    return useMutation({
        mutationFn: ACCOUNT_API.user.invoice.single.email.POST,
        ...options
    });
}

/**********************************************************************************************************
 *   INVOICE QUERIES - Bulk
 **********************************************************************************************************/
export const BULK_EMAIL_INVOICES = 'billing/BULK_EMAIL_INVOICES';
/**
 * Bulk email invoices data
 */
export function useBulkEmailInvoicesMutation(options = {}) {
    return useMutation({
        mutationKey: [BULK_EMAIL_INVOICES],
        mutationFn: ACCOUNT_API.user.invoice.bulk.email.POST,
        ...options,
        onSuccess: (response) => {
            options.onSuccess?.(response);

            if (typeof response === 'string' || response instanceof String) {
                const now = DateTime.local();
                createDownloadFile(response, `invoices_${now.day}-${now.month}-${now.year}.csv`);
                return;
            }

            handleDefaultSuccessNotification(response);
        }
    });
}

/**
 * Perform Final invoice merge
 */
export function useMergeInvoicesMutation(options = {}) {
    return useMutation({
        mutationFn: ACCOUNT_API.user.invoice.merge.POST,
        onError: handleDefaultErrorNotification,
        onSettled: (data, error, { invoices }, context) => {
            handleInvalidateUseGetInvoiceSingleQuery(
                data,
                error,
                invoices.map(({ id }) => id),
                context
            );
        },
        ...options
    });
}

/**********************************************************************************************************
 *   INVOICE QUERIES - Split
 **********************************************************************************************************/
/**
 * Split an invoice
 */
export function useSplitInvoiceMutation(options = {}) {
    return useMutation({
        mutationFn: ACCOUNT_API.user.invoice.single.split.POST,
        onSettled: (data, error, { invoiceID }, context) => {
            handleInvalidateUseGetInvoiceSingleQuery(data, error, invoiceID, context);
        },
        onError: handleDefaultErrorNotification,
        ...options,
        onSuccess: (response) => {
            pushNotification({ status: 200, details: 'Invoice split successfully!' });
            options.onSuccess?.(response);
        }
    });
}

/**
 * Set invoice offset
 */
export function useChangeInvoiceOffsetMutation(options = {}) {
    return useMutation({
        mutationFn: ACCOUNT_API.user.invoice_offset.POST,
        ...options,
        onSuccess: (response) => {
            handleDefaultSuccessNotification(response);
            loginGetUser();
            options.onSuccess?.(response);
        }
    });
}

/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import type { Services } from 'utilities/api/_services';
import { formatLuxonOrdinal, getCurrentDate, getIncludedObjBasedOnType } from 'utilities/methods/commonActions';

export const vpsServerTypes = {
    ASTRO: 'astro',
    SOLUS: 'souls'
} as const;

export const vpsTypes = {
    FULLY_MANAGED: 'fully_managed_vps',
    SELF_MANAGED: 'self_managed_vps',
    VPS: 'vps'
} as const;

/**********************************************************************************************************
 *   VPS
 **********************************************************************************************************/
export const vpsMethods = {
    getVpsServerType(vpsInfoData?: Pick<Services.VPS.TListData['data'][number], 'included'>) {
        const product = getIncludedObjBasedOnType(vpsInfoData?.included, 'product');
        const serverType = product?.attributes.server_type;

        if (typeof serverType === 'string' && serverType.toLowerCase().includes(vpsServerTypes.ASTRO)) {
            return vpsServerTypes.ASTRO;
        }

        return vpsServerTypes.SOLUS;
    }
};

export function getVpsType(productName) {
    if (typeof productName !== 'string') return vpsTypes.VPS;

    const strippedProductName = productName.replaceAll('-', '').toLowerCase();

    if (strippedProductName.includes('selfmanaged')) return vpsTypes.SELF_MANAGED;
    if (strippedProductName.includes('fullymanaged')) return vpsTypes.FULLY_MANAGED;
    return vpsTypes.VPS;
}

export const migrationDateOptions = () => {
    let currentDate = getCurrentDate();
    const range = 14;
    const rangeArray = [];

    for (let i = 0; i < range; i++) {
        currentDate = currentDate.plus({ days: 1 });
        const updatedLuxonDate = formatLuxonOrdinal(currentDate.toFormat('{d} MMMM yyyy'));
        const currentOption = {
            label: updatedLuxonDate,
            value: updatedLuxonDate
        };
        rangeArray.push(currentOption);
    }

    return rangeArray;
};

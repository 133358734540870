/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * @param {{
 *   useQuery: () => import("@tanstack/react-query").UseQueryResult,
 *   children: (queryResult: import("@tanstack/react-query").UseQueryResult) => React.ReactElement,
 * }} props
 */
export const TanstackClassAdapter = ({ useQuery, children }) => {
    /***** HOOKS *****/
    const queryResult = useQuery();

    /***** RENDER *****/
    return children(queryResult);
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

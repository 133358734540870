/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import classNames from 'classnames';
import { Component } from 'react';
import { connect } from 'react-redux';

// Carousel
import Slider from 'react-slick';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import DismissButton from 'components/DismissibleBanner/DismissButton';

/*   ACTIONS
 *****************************************************/
import { dismissBannerLocalStorage } from 'components/DismissibleBanner';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import './_BannerCarousel.scss';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type BannerCarouselProps = {
    initialBanners: Array<{
        /**
         * The array key
         */
        key: number;

        /**
         * Ui rendering element
         */
        ui: React.ReactNode;

        /**
         * Dismiss key for local storage.
         */
        dismissKey: string;

        /**
         * Whether to show the banner or not.
         */
        showCondition: boolean;

        /**
         * Whether or not to show the dots representing which is the current slide
         */
        dots: boolean;
    }>;
};

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
function NextArrow({ className, style, onClick }) {
    return (
        <button className={className} style={style} onClick={onClick}>
            <i className="icon icon-chevron-right" />
            <i className="icon icon-chevron-right" />
        </button>
    );
}

function PrevArrow({ className, style, onClick }) {
    return (
        <button className={className} style={style} onClick={onClick}>
            <i className="icon icon-chevron-left" />
            <i className="icon icon-chevron-left" />
        </button>
    );
}

function ProgressBar({ active, width }) {
    return (
        <div className={classNames('BannerCarousel__progress', { 'BannerCarousel__progress--active': active })}>
            <div style={{ display: active ? 'block' : 'none', transform: `translateX(${width}%)` }} className="BannerCarousel__progressFill" />
        </div>
    );
}

class BannerCarousel extends Component<BannerCarouselProps> {
    static autoplayInterval = 5000;

    constructor(props) {
        super(props);

        this.state = {
            banners: props.initialBanners.filter(
                ({ dismissKey, showCondition }) => showCondition && (!dismissKey || !localStorage.getItem(dismissKey))
            ),
            activeSlideIndex: 0
        };

        this.dismissBanner = this.dismissBanner.bind(this);
        this.updateActiveSlideIndex = this.updateActiveSlideIndex.bind(this);
        this.pauseSlider = this.pauseSlider.bind(this);
        this.playSlider = this.playSlider.bind(this);

        this.timerInterval = null;
    }

    dismissBanner(keyToDismiss) {
        const { restart } = this.props;
        const { banners } = this.state;

        this.setState(
            {
                banners: banners.filter(({ dismissKey }) => dismissKey !== keyToDismiss)
            },
            () => {
                dismissBannerLocalStorage(keyToDismiss);
                restart();
            }
        );
    }

    updateActiveSlideIndex(newIndex) {
        const { restart } = this.props;

        restart();
        this.setState({
            activeSlideIndex: newIndex,
            timer: 0
        });
    }

    pauseSlider() {
        const { pause } = this.props;

        pause();
    }

    playSlider() {
        const { resume } = this.props;

        resume();
    }

    /************** LIFECYCLE METHODS **************/
    componentDidMount() {
        const { start } = this.props;

        start();
    }

    componentDidUpdate(prevProps) {
        const { elapsed, initialBanners } = this.props;
        const prevActiveBanners = [];
        const activeBanners = [];
        initialBanners.forEach((bannerData) => {
            const { dismissKey, showCondition } = bannerData;
            if (showCondition && (!dismissKey || !localStorage.getItem(dismissKey))) {
                activeBanners.push(dismissKey);
            }
        });

        prevProps.initialBanners.forEach((bannerData) => {
            const { dismissKey, showCondition } = bannerData;
            if (showCondition && (!dismissKey || !localStorage.getItem(dismissKey))) {
                prevActiveBanners.push(dismissKey);
            }
        });

        if (!activeBanners.every((dismissKey) => prevActiveBanners.includes(dismissKey))) {
            this.setState({
                banners: initialBanners.filter(({ dismissKey, showCondition }) => showCondition && (!dismissKey || !localStorage.getItem(dismissKey)))
            });
        }

        if (elapsed >= BannerCarousel.autoplayInterval && prevProps.elapsed < BannerCarousel.autoplayInterval && this.slider) {
            this.slider.slickNext();
        }
    }

    render() {
        const { app_viewport, elapsed, dots, arrows, onDismiss } = this.props;
        const { banners, activeSlideIndex } = this.state;
        const { dismissBanner, updateActiveSlideIndex, pauseSlider, playSlider } = this;

        const areArrowsShowing = typeof arrows === 'boolean' ? arrows : !['md', 'sm', 'xs'].includes(app_viewport);

        const renderBannerCarousel = (banners) => {
            return (
                <Slider
                    ref={(slider) => (this.slider = slider)}
                    arrows={areArrowsShowing}
                    infinite
                    speed={500}
                    pauseOnHover={false}
                    slidesToShow={1}
                    slidesToScroll={1}
                    nextArrow={<NextArrow />}
                    prevArrow={<PrevArrow />}
                    beforeChange={(_, next) => updateActiveSlideIndex(next)}
                    dots={!!dots}
                >
                    {banners}
                </Slider>
            );
        };

        const renderBanners = () =>
            banners.map(({ key, ui, dismissKey }) => (
                <div key={key} className="BannerCarousel__banner">
                    {ui}
                    {dismissKey ? (
                        <DismissButton
                            background
                            onClick={() => {
                                dismissBanner(dismissKey);
                                onDismiss?.();
                            }}
                        />
                    ) : (
                        ''
                    )}
                </div>
            ));

        /*  RENDER COMPONENT
         **********************************************************************************************************/
        if (banners.length <= 0) return '';

        return (
            <div className="BannerCarousel__container">
                <div className="BannerCarousel" onMouseEnter={pauseSlider} onMouseLeave={playSlider}>
                    {renderBannerCarousel(renderBanners())}
                </div>
                {banners.length > 1 ? (
                    <div className="BannerCarousel__progressContainer">
                        {banners.map(({ key }, index) => (
                            <ProgressBar key={key} active={index === activeSlideIndex} width={(elapsed / BannerCarousel.autoplayInterval) * 100} />
                        ))}
                    </div>
                ) : (
                    ''
                )}
            </div>
        );
    }
}

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

export default connect((state) => ({
    app_viewport: state.app.app_viewport
}))(BannerCarousel);

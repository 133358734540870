/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { BubbleMenu, useCurrentEditor } from '@tiptap/react';
import React from 'react';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import Border from 'components/Utils/Border';
import { Flex } from 'components/Utils/Flex';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import { FormatBoldAction } from 'components/Form/RichTextEditor/plugins/Toolbar/Actions/FormatBoldAction';
import { FormatItalicAction } from 'components/Form/RichTextEditor/plugins/Toolbar/Actions/FormatItalicAction';
import { InsertLinkAction } from 'components/Form/RichTextEditor/plugins/Toolbar/Actions/InsertLinkAction';
import { OrderedListAction } from 'components/Form/RichTextEditor/plugins/Toolbar/Actions/OrderedListAction';
import { UnorderedListAction } from 'components/Form/RichTextEditor/plugins/Toolbar/Actions/UnorderedListAction';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { collectionIncludes } from 'utilities/methods/collectionIncludes/collectionIncludes';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import type { richTextFeatures } from 'components/Form/RichTextEditor/consts';
import './_ToolBarPlugin.scss';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
interface ToolbarPluginProps {
    features?: typeof richTextFeatures;
}

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/

/**
 * Reference for toolbar plugin: https://github.com/facebook/lexical/blob/main/packages/lexical-playground/src/plugins/ToolbarPlugin/index.tsx
 */
export const ToolbarPlugin: React.FC<ToolbarPluginProps> = ({ features }) => {
    const { editor } = useCurrentEditor();
    /***** RENDER *****/
    if (!features?.length) return;
    return (
        <BubbleMenu editor={editor} tippyOptions={{ duration: 100 }}>
            <Border all inject>
                <Flex className="RichTextEditor__ToolBar" gap={0}>
                    {/* {collectionIncludes(features, ['undo']) && <UndoAction />} */}

                    {/* {collectionIncludes(features, ['redo']) && <RedoAction />} */}

                    {collectionIncludes(features, ['bold']) && <FormatBoldAction />}

                    {collectionIncludes(features, ['italic']) && <FormatItalicAction />}

                    {collectionIncludes(features, ['ul']) && <UnorderedListAction />}

                    {collectionIncludes(features, ['ol']) && <OrderedListAction />}

                    {collectionIncludes(features, ['link']) && <InsertLinkAction />}

                    {/* {collectionIncludes(features, ['clear']) && <ClearEditorAction />} */}
                </Flex>
            </Border>
        </BubbleMenu>
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

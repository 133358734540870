import { useKatanaParams } from 'containers/katana/hooks/useSetupEditorRouteParams';
import { sortByMenuIDs } from 'containers/katana/modules/presetContent/components/sectionOrganiser/sortByMenuIds';
import { katanaQuery } from 'containers/katana/queries/tanstackTree';
import _ from 'lodash';

/**********************************************************************************************************
 *   HOOK START
 **********************************************************************************************************/
export const useNavigationPreviewOptions = (value: number[]) => {
    /***** HOOKS *****/
    const { katanaServiceId } = useKatanaParams();

    /***** QUERIES *****/
    const { data: get_katana_site_sections } = katanaQuery.serviceID.sections.useQuery(katanaServiceId);
    const { data: get_section_definitions_data } = katanaQuery.serviceID.meta.getSectionDefinitions.useQuery(katanaServiceId);

    /***** HOOK RESULTS *****/
    return (
        get_katana_site_sections
            ?.filter((section) => _.has(get_section_definitions_data, section.section_id))
            ?.filter((section) => value.includes(section.id))
            ?.sort((a, b) => (get_section_definitions_data[a.section_id].order < get_section_definitions_data[b.section_id].order ? -1 : 1))
            ?.sort(sortByMenuIDs(value))
            ?.map((section) => {
                const sectionDefinitionData = get_section_definitions_data?.[section?.section_id];
                const name = section?.name;
                const navBarTitle = sectionDefinitionData?.navbar_title;

                return {
                    name: navBarTitle ?? name,
                    sectionID: section.id
                };
            }) ?? []
    );
};

/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import classNames from 'classnames';
import React from 'react';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import { PhosphorIcons } from 'components/Icons/Phosphor';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { createAppliedStylingClasses } from 'components/Utils/methods';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import './_checkListItemCircle.scss';

const CheckListItemCircleAppliedStylingPropKeys = ['checked', 'noBorder'];

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * @type {React.FC<{
 *      size?: number,
 *      checkSize?: number,
 *      children?: React.ReactNode,
 *      checked?: boolean,
 *      noBorder?: boolean,
 * }>}
 */
const CheckListItemCircle = (props) => {
    const { size = 16, checkSize = 12, checked, children } = props;

    /***** RENDER HELPERS *****/
    const appliedStylingClasses = createAppliedStylingClasses({
        props,
        keyBoundary: CheckListItemCircleAppliedStylingPropKeys,
        componentName: 'CheckListItemCircle',
        delimiter: '--'
    });
    const className = classNames('CheckListItemCircle', appliedStylingClasses);

    const styles = {
        width: size,
        height: size
    };

    /***** RENDER *****/
    return (
        <div className={className} style={styles}>
            {checked ? <PhosphorIcons.CheckFat.Fill size={checkSize} white /> : children}
        </div>
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

export default CheckListItemCircle;

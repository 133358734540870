/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import { _Text as Text } from 'components/Utils/Text/_Text';

/**********************************************************************************************************
 *   TYPE IMPORTS
 **********************************************************************************************************/
import type { TextProps } from 'components/Utils/Text/_Text/types';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const _TableContent: React.FC<TextProps> = (props) => {
    return <Text size--s secondary {...props} />;
};

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import classNames from 'classnames';
import React from 'react';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import Text from 'components/Utils/Text';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import { _AcknowledgementBox as AcknowledgementBox } from '../_AcknowledgementBox';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import './_CompactAcknowledgementBox.scss';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * Compact Acknowledgement Box. This should be used in all instances where an Acknowledgement box is present
 * within a lightbox form; opposed to the standard acknowledgement box which is present on larger longform forms.
 *
 * @param {{
 *   title?: string | React.ReactNode
 *   notice?: string | React.ReactNode
 *   children: React.ReactNode
 *   className?: string
 * }} props
 */
const _CompactAcknowledgementBox = ({ title = 'Acknowledgement', notice, children, className }) => {
    /***** RENDER HELPERS *****/
    const acknowledgementBoxClassNames = classNames('compactAcknowledgementBox', className, {
        compactAcknowledgementBox__hasNotice: !!notice
    });
    
    /***** RENDER *****/
    return (
        <AcknowledgementBox className={acknowledgementBoxClassNames} title={title}>
            {children}
            {!!notice && (
                <Text align--left white size--s className={classNames('compactAcknowledgementBox__notice')}>
                    {notice}
                </Text>
            )}
        </AcknowledgementBox>
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

export { _CompactAcknowledgementBox };

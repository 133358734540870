/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import Tooltip from '@mui/material/Tooltip';
import classNames from 'classnames';
import React from 'react';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import SolidButton from 'components/Buttons/SolidButton';
import CheckListItemCircle from 'components/CheckListItemCircle';
import { Flex } from 'components/Utils/Flex';
import Padding from 'components/Utils/Padding';
import Text from 'components/Utils/Text';
import { WrapWithComponent } from 'components/WrapWithComponent';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import useKatanaURL from 'containers/katana/hooks/useKatanaURL';
import { isChecklistItemEnabled } from 'containers/katana/modules/launchChecklist/methods';
import { useKatanaChecklistData } from 'containers/katana/modules/launchChecklist/useKatanaChecklistData';

/**********************************************************************************************************
 *   QUERIES
 **********************************************************************************************************/
import { katanaQuery } from 'containers/katana/queries/tanstackTree';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import { SiteSetupStageOrder } from 'containers/katana/consts';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type CheckListItemsProps = {
    circleSize?: number;
    serviceID: number;
    showItemTitle?: boolean;
    showItemButton?: boolean;
    separateLinePath?: boolean;
    showIconHoverToolTip?: boolean;
};

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * Rendered at
 * - `/dashboard`
 * - `/my-services/vip-sites/$katanaServiceId/overview#basic-details`
 */
const CheckListItems: React.FC<CheckListItemsProps> = ({
    serviceID,
    circleSize = 44,
    showItemTitle = true,
    showItemButton = true,
    separateLinePath = false,
    showIconHoverToolTip = false
}) => {
    /***** HOOKS *****/
    const launchChecklistData = useKatanaChecklistData({ iconSize: circleSize * 0.6, ...(serviceID ? { serviceID } : {}) });
    const { getKatanaDestination } = useKatanaURL();

    /***** QUERIES *****/
    const { data: get_katana_service_data } = katanaQuery.serviceID.getService.useQuery(serviceID);

    /***** RENDER HELPERS *****/
    const launch_checklist = get_katana_service_data?.attributes?.launch_checklist ?? [];
    const filteredSiteSetupStageOrder = SiteSetupStageOrder.filter((key) => launchChecklistData.find(({ checkListKey }) => checkListKey === key));

    /***** RENDER *****/
    return filteredSiteSetupStageOrder
        .map((key) => launchChecklistData.find(({ checkListKey }) => checkListKey === key))
        .map(({ icon, title, to, checkListKey, prefetch }, i) => {
            const isCompleted = launch_checklist?.includes?.(checkListKey);
            const isEnabled = isChecklistItemEnabled(launch_checklist, checkListKey);

            const checklistClassNames = classNames('KatanaLaunchChecklist__checklistItem', {
                'KatanaLaunchChecklist__checklistItem--completed': isCompleted,
                'KatanaLaunchChecklist__checklistItem--enabled': isEnabled,
                'KatanaLaunchChecklist__checklistItem--separateLinePath': separateLinePath
            });

            const checkListStyle = { '--KatanaLaunchChecklist__checklistItem_circleSize': `${circleSize}px` } as React.CSSProperties;

            return (
                <>
                    <WrapWithComponent component={Tooltip} wrap={showIconHoverToolTip} title={title} arrow>
                        <div key={title} className={checklistClassNames} style={checkListStyle}>
                            <CheckListItemCircle size={circleSize} checkSize={circleSize * 0.5} checked={isCompleted} noBorder={isCompleted}>
                                {icon}
                            </CheckListItemCircle>
                            {showItemTitle && (
                                <Flex.Child grow>
                                    <Padding top={2}>
                                        <Text size--s align--center black semiBold>
                                            {title}
                                        </Text>
                                    </Padding>
                                </Flex.Child>
                            )}
                            {!separateLinePath && <div className="KatanaLaunchChecklist__linePath"></div>}
                            {showItemButton && isEnabled && !isCompleted && (
                                <SolidButton
                                    to={isEnabled ? getKatanaDestination(to) : null}
                                    key={title}
                                    size="small"
                                    onMouseOver={prefetch && isEnabled ? () => prefetch(serviceID) : null}
                                >
                                    Continue
                                </SolidButton>
                            )}
                        </div>
                    </WrapWithComponent>
                    {separateLinePath && i !== filteredSiteSetupStageOrder.length - 1 && <div className="KatanaLaunchChecklist__linePath"></div>}
                </>
            );
        });
};

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
export default CheckListItems;

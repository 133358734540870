/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { createRoute } from '@tanstack/react-router';

/**********************************************************************************************************
 *   ROUTE IMPORTS
 **********************************************************************************************************/
import { SupportCentreRoute } from 'containers/support/routes/support-centre';
import { routerMiddleware } from 'router/utils/middleware';
import { lazyFN } from 'utilities/methods/tanstack/router/lazyFn';

/**********************************************************************************************************
 *   ROUTE START
 **********************************************************************************************************/
/**
 * Route exists purely as a placeholder route, nothing is rendered.
 *
 * The reason for this is so that the parent route (/support-centre) can render the component at /support-centre
 * while subroutes can be rendered at /support-centre/:slug.
 *
 * If this also rendered the KnowledgeBase as a sibling route, then the component would unmount and remount between
 * the routes despite it being the same page which causes a white flash of a loader as well as a less smooth experience.
 */
export const SupportCentreSplatRoutes = createRoute({
    getParentRoute: () => SupportCentreRoute,
    path: '$supportArticle',
    beforeLoad(opts) {
        routerMiddleware.business(this, opts);
        routerMiddleware.authentication('user', opts);
    }
}).lazy(lazyFN(() => import('../index.lazy'), 'LazySupportCentreRoute'));

/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { createRoute } from '@tanstack/react-router';

/**********************************************************************************************************
 *   ROUTE IMPORTS
 **********************************************************************************************************/
import { VIPSitesRoute } from 'containers/katana/routes/vip-sites.$katanaServiceId';
import { routerMiddleware } from 'router/utils/middleware';
import { lazyFN } from 'utilities/methods/tanstack/router/lazyFn';

/**********************************************************************************************************
 *   ROUTE START
 **********************************************************************************************************/
export const VIPSitesContentLightboxLayoutRoute = createRoute({
    getParentRoute: () => VIPSitesRoute,
    id: '_content-editor-lightbox',
    beforeLoad(opts) {
        routerMiddleware.business(this, opts);
        routerMiddleware.authentication('user', opts);
    }
}).lazy(lazyFN(() => import('./_content-editor-lightbox.lazy'), 'LazyVIPSitesContentLightboxLayoutRoute'));

/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import axios from 'axios';
import { url } from 'config/config';
import { nxAxios } from 'store/nxAxios';

/**********************************************************************************************************
 *   CANCEL TOKENS
 **********************************************************************************************************/
const CancelToken = axios.CancelToken;

export let cancelGetKnowledgeData;
export let cancelSearchResolvedTickets;

export const API = {
    /**********************************************************************************************************
     *   SUPPORT API
     **********************************************************************************************************/

    support: {
        GET: {
            migration: {
                migrationDates: () => {
                    return axios.get('/api/support/migration/migration-dates');
                }
            },

            tickets: {
                active: () => {
                    return axios.get('/api/support/e-ticket/active');
                },

                resolved: (page = 1) => {
                    return axios.get(`/api/support/e-ticket/resolved?page=${page}`);
                },

                messages: (id) => {
                    return axios.get(`/api/support/e-ticket/${id}`);
                },

                questions: () => {
                    return axios.get(`/api/support/e-ticket/questions`);
                },

                waitTime: () => {
                    return axios.get('/api/support/e-ticket/wait-time');
                },

                search: {
                    resolved: (keyword) => {
                        return axios.get(`/api/support/e-ticket/search/${keyword}`, {
                            cancelToken: new CancelToken(function executor(c) {
                                cancelSearchResolvedTickets = c;
                            })
                        });
                    }
                },

                attachment: (ticketMask, attachmentId) => {
                    return axios.get(`/api/support/e-ticket/${ticketMask}/attachment/${attachmentId}/download`, {
                        responseType: 'blob'
                    });
                }
            },

            status: () => {
                return axios.get('/api/support/service-status');
            }
        },

        POST: {
            tickets: {
                /**
                 * @param {Record<string, unknown> & { is_technical?: boolean }} attributes
                 */
                new: (attributes) => {
                    return nxAxios.post('/api/support/e-ticket', {
                        type: 'e-ticket',
                        attributes
                    });
                },

                migration: (attributes) => {
                    return axios.post('/api/support/e-ticket/migration', {
                        type: 'e-ticket',
                        attributes
                    });
                },

                close: (id) => {
                    return axios.post(`/api/support/e-ticket/${id}/close`);
                },

                /**
                 * @param {{
                 *   id: string,
                 *   attributes: Record<string, unknown>
                 * }} params
                 */
                reply: ({ id, attributes }) => {
                    return nxAxios.post(`/api/support/e-ticket/${id}/reply`, {
                        type: 'e-ticket',
                        id,
                        attributes
                    });
                },

                feedback: (id, status) => {
                    return axios.post(`/api/support/e-ticket/${id}/feedback`, {
                        type: 'e-ticket',
                        id,
                        attributes: {
                            feedback_status: status
                        }
                    });
                }
            },

            status: {
                enable: (type) => {
                    if (!type) return axios.post('/api/support/service-status/general-notice/enable');

                    return axios.post('/api/support/service-status/service-notice/enable', {
                        attributes: {
                            type
                        }
                    });
                },

                disable: (type) => {
                    if (!type) return axios.post('/api/support/service-status/general-notice/disable');

                    return axios.post('/api/support/service-status/service-notice/disable', {
                        attributes: {
                            type
                        }
                    });
                }
            },

            knowledge: {
                data: () => {
                    return axios({
                        method: 'post',
                        withCredentials: false,
                        url: '/wp-content/themes/ventraip-template/module/backend/api/getFAQ.php',
                        baseURL: url,
                        headers: {
                            'content-type': 'application/json'
                        },
                        cancelToken: new CancelToken(function executor(c) {
                            cancelGetKnowledgeData = c;
                        }),
                        data: {
                            action: 'all'
                        }
                    });
                }
            },

            migration: {
                checkService: (data) => {
                    return axios.post('/api/support/migration/check-service', data);
                },

                submit: (data) => {
                    return axios.post('/api/support/migration', data);
                }
            }
        }
    }
};

/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React from 'react';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import { _ValidationMessage as ValidationMessage } from 'components/Form/ValidationMessage/_ValidationMessage';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * @type {React.FC<
 *      import('redux-form').FieldArrayMetaProps
 *      & { validated?: { value: string, valid:boolean }}
 * >}
 */
const _ValidationMessageReduxFormFieldArray = (props) => {
    const { error, warning, pristine } = props;

    /***** FUNCTIONS *****/
    function getMessage() {
        if (pristine && (error || warning)) {
            return '';
        }
        if (error) {
            return error;
        }
        if (warning) {
            return warning;
        }

        return '';
    }

    /***** RENDER HELPERS *****/
    const message = getMessage();

    const warn = error || warning;

    /***** RENDER *****/
    return (
        <ValidationMessage warn={warn} open={Boolean(message)}>
            {message}
        </ValidationMessage>
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
export { _ValidationMessageReduxFormFieldArray };

/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import classNames from 'classnames';
import React from 'react';

/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { PropInjector } from 'components/Utils/PropInjector';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { createAppliedStylingClasses } from 'components/Utils/methods';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import './_Gradient.scss';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type TGradient = React.FC<{
    'children': React.ReactNode;
    'white'?: boolean;
    'primary'?: boolean;
    'notice'?: boolean;
    'development-pink'?: boolean;
    'className'?: string;
    'inject'?: boolean;
}>;

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
const Gradient: TGradient = (props) => {
    const { children, className, inject } = props;

    /***** RENDER HELPERS *****/
    const appliedStylingClasses = createAppliedStylingClasses({
        props,
        keyBoundary: ['development-pink', 'white', 'primary', 'notice'],
        componentName: 'Gradient',
        delimiter: '--'
    });

    const gradientClassNames = classNames('Gradient', appliedStylingClasses, className);

    return (
        <PropInjector inject={inject} injectableProps={{ className: gradientClassNames }} injectable={children}>
            <div className={gradientClassNames}>{children}</div>
        </PropInjector>
    );
};

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

export default Gradient;

/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { LiveChatWidget } from '@livechat/widget-react';
import { useLocation } from '@tanstack/react-router';
import { changeLivechatVisibility, livechatVisibilityOptions } from 'App/action';
import { application, company, serviceNav } from 'config/config';
import { useDispatch, useSelector } from 'react-redux';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { useUserData } from 'utilities/hooks/redux/useUserData';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const LiveChat = () => {
    /***** HOOKS *****/
    const dispatch = useDispatch();
    const { pathname } = useLocation();

    /***** SELECTORS *****/
    const app_user_data = useUserData();
    const app_check_token_data = useSelector((state: any) => state.app.app_check_token_data);
    const app_livechat_visibility = useSelector((state: any) => state.app.app_livechat_visibility);

    /***** RENDER *****/
    if (app_check_token_data?.is_vpn || company !== 'VentraIP Australia' || import.meta.env.VITE_CCP_ENVIRONMENT === 'development') {
        return null;
    }

    /***** RENDER HELPERS *****/
    const visitor = {
        name: `${app_user_data.firstname} ${app_user_data.lastname}`,
        email: app_user_data.email
    };

    const params = {
        Company: company,
        Location: application
    };

    // Don't show livechat on VIP sites
    const visibility = pathname.includes(serviceNav.VIPsites.link) ? livechatVisibilityOptions.MINIMIZED.visibility : app_livechat_visibility;

    /***** RENDER *****/
    return (
        <LiveChatWidget
            license="7094961"
            customerName={visitor.name}
            customerEmail={visitor.email}
            sessionVariables={params}
            visibility={visibility}
            onVisibilityChanged={(state) => changeLivechatVisibility(state)(dispatch)}
        />
    );
};

/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import OverlayLightbox from 'components/Lightboxes/OverlayLightbox';
import { Component } from 'react';
import { Field, reduxForm } from 'redux-form';
import { withRouter } from 'utilities/methods/tanstack/router/withRouter';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import { RenderAdvanceListSlim, renderButton } from 'utilities/methods/form';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
class ApproveForm extends Component {
    /*   RENDER COMPONENT
     **********************************************************************************************************/
    render() {
        const { pristine, submitting, valid, handleSubmit, closeLightbox } = this.props;

        const optionList = [
            {
                attributes: {
                    name: 'transfer',
                    displayName: 'Approve transfer WITHOUT renewal',
                    desc: 'This will approve the transfer and will not renew the domain.'
                }
            },
            {
                attributes: {
                    name: 'transferRenew',
                    displayName: 'Approve transfer with renewal',
                    desc: 'This will approve the transfer and will renew the domain on transfer.'
                }
            }
        ];

        return (
            <form className="domainActionForm" onSubmit={handleSubmit}>
                <div className="domainActionForm__container">
                    <div className="domainActionForm__input">
                        <Field component={RenderAdvanceListSlim} name="method" type="hidden" className="form__textfield" list={optionList} />
                    </div>
                    <div className="domainActionForm__action">{renderButton(pristine, submitting, valid, 'Submit')}</div>
                    <OverlayLightbox.BackLink text="NO, GO BACK" onClick={closeLightbox} />
                </div>
            </form>
        );
    }
}

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
ApproveForm = reduxForm({
    form: 'approve'
})(ApproveForm);

export default withRouter(ApproveForm);

/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import classNames from 'classnames';
import React from 'react';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import { IconifyIcon } from 'components/Icons/Iconify';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import './_Chevron.scss';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * Functional Chevron Icon with arrow transition
 * @type {React.FC<
 *      Omit<NPhosphorIcon.Props, 'icon'>
 *      & {
 * 			state: 'up' | 'down',
 * 			bold?: boolean
 *      }
 * >};
 */
const Chevron = (props) => {
    const { className, state, bold } = props;
    /***** RENDER HELPERS *****/
    const chevronClassNames = classNames(
        'Chevron',
        `Chevron--${state}`,
        {
            'Chevron--bold': bold
        },
        className
    );
    /***** RENDER *****/
    return <IconifyIcon icon={bold ? 'ph:caret-up-bold' : 'ph:caret-up'} {...{ ...props, className: chevronClassNames }} />;
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

export default Chevron;

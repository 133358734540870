/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import classNames from 'classnames';
import React from 'react';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import './_NXImage.scss';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * @type {React.FC<TNXImage.Props>}
 */
const _NXImage = ({ cover, contain, ...props }) => {
    /***** RENDER HELPERS *****/
    const className = classNames('NXImage', {
        'NXImage--cover': cover,
        'NXImage--contain': contain
    });

    /***** RENDER *****/
    return <img className={className} alt={props.alt} {...props} />;
};

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
export { _NXImage };

/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import classNames from 'classnames';
import React, { useEffect, useRef } from 'react';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { createAppliedStylingClasses } from 'components/Utils/methods';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import './_TextArea.scss';

/**********************************************************************************************************
 *   TYPE IMPORTS
 **********************************************************************************************************/
import type { NXTextAreaNamespace } from 'components/Form/TextArea/types';
import mergeRefs from 'merge-refs';

const textAreaAppliedStylingPropTypeKeys = ['readonly', 'disabled', 'isPlaceholderItalic'];

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * CCP `textarea` abstraction
 */
export const _TextArea: React.FC<NXTextAreaNamespace.Props> = (props) => {
    const { name, readOnly, placeholder, disabled, rows = 5, value, spellCheck = false, textAreaRef, intrinsicProps } = props;

    const _textAreaRef = useRef<HTMLTextAreaElement>(null);

    /***** RENDER HELPERS *****/
    const appliedClassNames = createAppliedStylingClasses({
        props,
        keyBoundary: textAreaAppliedStylingPropTypeKeys,
        delimiter: '--',
        componentName: 'NXTextArea'
    });

    function handleAutoSize() {
        if (!_textAreaRef.current) {
            return;
        }

        _textAreaRef.current.style.height = '5px';
        _textAreaRef.current.style.height = `${_textAreaRef.current.scrollHeight + 4}px`;
    }

    useEffect(() => {
        if (rows === 'auto') {
            handleAutoSize();
        }
    }, [value]);

    /***** RENDER *****/
    return (
        <textarea
            className={classNames('NXTextArea', appliedClassNames)}
            name={name}
            readOnly={readOnly}
            placeholder={placeholder}
            disabled={disabled}
            rows={typeof rows === 'number' ? rows : undefined}
            value={value}
            spellCheck={spellCheck}
            ref={mergeRefs(textAreaRef, _textAreaRef)}
            {...intrinsicProps}
        />
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

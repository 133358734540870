import axios from 'axios';
import { nxAxios } from 'store/nxAxios';

const CancelToken = axios.CancelToken;

export let cancelSearchDomain;
export let cancelSearchHosting;
export let cancelSearchEmail;
export let cancelSearchMailbox;
export let cancelSearchSSL;
export let cancelSearchVPS;
export let cancelSearchGSuite;
export let cancelSearchMs365;
export let cancelListSSL;
export let cancelListVPS;
export let cancelListMS365;

export let cancelCalculateChangePlanCost;

export const API = {
    /**********************************************************************************************************
     *   SERVICE API
     **********************************************************************************************************/
    service: {
        get: {
            moveInvitation: (id) => {
                return axios.get(`/api/user/service-move/${id}`);
            },

            userExists: (token) => {
                return axios.get(`/api/user/service-move/user-exists?token=${token}`);
            },

            approveMove: (token) => {
                return axios.get(`/api/user/service-move/approve?token=${token}`);
            },

            addons: (serviceId) => {
                return axios.get(`/api/service/${serviceId}/addon`);
            },

            renewInfo: (id) => {
                return axios.get(`/api/service/${id}/renew`);
            },

            upgradePaths: (serviceId) => {
                return axios.get(`/api/service/${serviceId}/change-plan-paths`);
            },

            suspensionReason: (serviceId) => {
                return axios.get(`/api/service/${serviceId}/unsuspend/reason`);
            }
        },

        post: {
            purchaseAddon: (serviceId, attributes) => {
                return axios.post(`/api/service/${serviceId}/addon/purchase`, {
                    attributes
                });
            },

            cancel: (id, attributes) => {
                const payload = {
                    type: 'cancellation',
                    attributes: attributes
                };
                return axios.post(`/api/service/${id}/cancellation`, payload);
            },

            cancelInfo: (id, attributes = {}) => {
                const payload = {
                    type: 'cancellation',
                    attributes: attributes
                };
                return axios.post(`/api/service/${id}/cancellation/check`, payload);
            },

            move: (attributes) => {
                return axios.post(`/api/user/service-move`, {
                    type: 'service-move',
                    attributes
                });
            },

            registerMove: (attributes, token) => {
                return axios.post(`/api/user/service-move/approve-signup?token=${token}`, {
                    type: 'service-move',
                    attributes
                });
            },

            cancelMove: (id) => {
                return axios.post(`/api/user/service-move/cancel/${id}`);
            },

            renew: (id, attributes) => {
                return axios.post(`/api/service/${id}/renew`, {
                    type: 'service',
                    id,
                    attributes
                });
            },
            /**
             *
             * @param {string} id
             * @param {{billing_cycle: "Monthly" | "Quarterly" | "Semi-Annually" | "Annually" | "Biennially" | "Triennially"}} attributes
             * @returns {Artah.Service.ID.Renew.Details.POST.All.Promise}
             */
            renewDetails: (id, attributes) => {
                return nxAxios.post(`/api/service/${id}/renew/details`, {
                    type: 'service',
                    id,
                    attributes
                });
            },

            unsuspend: (serviceId) => {
                return axios.post(`/api/service/${serviceId}/unsuspend`);
            },

            changePlan: (id, attributes) => {
                return axios({
                    method: 'post',
                    url: `/api/service/${id}/change-plan`,
                    data: {
                        type: 'service',
                        attributes
                    }
                });
            },

            changePlanCalculate: (id, attributes) => {
                return axios({
                    method: 'post',
                    url: `/api/service/${id}/change-plan/calculate`,
                    data: {
                        type: 'service',
                        attributes
                    },
                    cancelToken: new CancelToken(function executor(c) {
                        cancelCalculateChangePlanCost = c;
                    })
                });
            }
        },

        delete: {
            cancel: (id, attributes) => {
                const payload = {
                    type: 'cancellation',
                    attributes: attributes
                };
                return axios.delete(`/api/service/${id}/cancellation`, payload);
            }
        }
    },

    /**********************************************************************************************************
     *   DOMAINS API
     **********************************************************************************************************/

    domain: {
        list: (params) => {
            if (params) {
                return axios.get(`/api/domain`, {
                    params
                });
            }

            return axios.get(`/api/domain`);
        },

        search: (keyword) => {
            return axios.get(`/api/domain/search/${keyword}`, {
                cancelToken: new CancelToken(function executor(c) {
                    cancelSearchDomain = c;
                })
            });
        },

        get: {
            transfer: (id) => {
                return axios.get(`/api/domain/${id}/transfer/details`);
            }
        },

        post: {
            export: (attributes) => {
                return axios.post(`/api/domain/export`, {
                    attributes
                });
            },

            enableRenew: (id) => {
                return axios.post(`/api/domain/${id}/auto-renew/enable`);
            },

            disableRenew: (id) => {
                return axios.post(`/api/domain/${id}/auto-renew/disable`);
            },

            approve: (id, attributes) => {
                return axios.post(`/api/domain/${id}/transfer/approve`, {
                    attributes
                });
            },

            info: (id, attributes) => {
                return axios.post(`/api/domain/${id}/registration-details/update`, {
                    attributes
                });
            },

            epp: (id, attributes) => {
                return axios.post(`/api/domain/${id}/epp/update`, {
                    attributes
                });
            },

            resend: (id) => {
                return axios.post(`/api/domain/${id}/transfer/resend`);
            },

            reprocessTransfer: (id) => {
                return axios.post(`/api/domain/${id}/transfer/reset`);
            }
        }
    },

    /**********************************************************************************************************
     *   EMAIL API
     **********************************************************************************************************/

    email: {
        list: (params) => {
            if (params) {
                return axios.get(`/api/email-hosting`, {
                    params
                });
            }

            return axios.get(`/api/email-hosting`);
        },

        search: (keyword) => {
            return axios.get(`/api/email-hosting/search/${keyword}`, {
                cancelToken: new CancelToken(function executor(c) {
                    cancelSearchEmail = c;
                })
            });
        },

        mailboxSearch: (id, keyword) => {
            return axios.get(`/api/email-hosting/mailbox/${id}/search/${keyword}`, {
                cancelToken: new CancelToken(function executor(c) {
                    cancelSearchMailbox = c;
                })
            });
        }
    },

    /**********************************************************************************************************
     *   HOSTING API
     **********************************************************************************************************/

    hosting: {
        list: (params) => {
            if (params) {
                return axios.get(`/api/web-hosting`, {
                    params
                });
            }

            return axios.get(`/api/web-hosting`);
        },

        status: (query) => {
            return axios.get(`/api/web-hosting/?status=${query}`);
        },

        search: (keyword) => {
            return axios.get(`/api/web-hosting/search/${keyword}`, {
                cancelToken: new CancelToken(function executor(c) {
                    cancelSearchHosting = c;
                })
            });
        },

        restore: (id) => {
            return axios.post(`/api/web-hosting/${id}/restore`, {});
        }
    },

    /*********************************************************************************************************
     *   SSL API
     **********************************************************************************************************/

    ssl: {
        /**
         * @type {import('./_services').Services.SSL.TList}
         */
        list: (params) => {
            if (params) {
                return axios.get(`/api/ssl`, {
                    params,
                    cancelToken: new CancelToken((c) => {
                        cancelListSSL = c;
                    })
                });
            }

            return axios.get(`/api/ssl`);
        },

        product: () => {
            return axios.get(`/api/ssl/product/list`);
        },

        /**
         * @type {import('./_services').Services.SSL.TSearch}
         */
        search: (keyword) => {
            return axios.get(`/api/ssl/search/${keyword}`, {
                cancelToken: new CancelToken(function executor(c) {
                    cancelSearchSSL = c;
                })
            });
        },

        purchase: (attributes) => {
            return axios.post(`/api/ssl/purchase`, {
                type: 'purchase-ssl',
                attributes
            });
        }
    },

    /**********************************************************************************************************
     *   VPS API
     **********************************************************************************************************/

    vps: {
        list: (params) => {
            if (params) {
                return axios.get(`/api/vps`, {
                    params,
                    cancelToken: new CancelToken(function executor(c) {
                        cancelListVPS = c;
                    })
                });
            }

            return axios.get(`/api/vps`);
        },

        search: (keyword) => {
            return axios.get(`/api/vps/search/${keyword}`, {
                cancelToken: new CancelToken(function executor(c) {
                    cancelSearchVPS = c;
                })
            });
        }
    },

    /**********************************************************************************************************
     *   GSUITE API
     **********************************************************************************************************/

    gsuite: {
        list: (params) => {
            if (params) {
                return axios.get(`/api/gsuite`, {
                    params
                });
            }

            return axios.get(`/api/gsuite`);
        },

        search: (keyword) => {
            return axios.get(`/api/gsuite/search/${keyword}`, {
                cancelToken: new CancelToken(function executor(c) {
                    cancelSearchGSuite = c;
                })
            });
        }
    },

    /**********************************************************************************************************
     *   MS365 API
     **********************************************************************************************************/

    ms365: {
        list: (params) => {
            if (params) {
                return axios.get(`/api/microsoft365`, {
                    params,
                    cancelToken: new CancelToken(function executor(c) {
                        cancelListMS365 = c;
                    })
                });
            }

            return axios.get(`/api/microsoft365`);
        },

        search: (keyword) => {
            return axios.get(`/api/microsoft365/search/${keyword}`, {
                cancelToken: new CancelToken(function executor(c) {
                    cancelSearchMs365 = c;
                })
            });
        }
    },

    cart: {
        // internal requires obfuscated product ids
        internal: {
            POST: {
                create: (items) => {
                    return axios.post(`/api/internal/cart/create`, {
                        items
                    });
                },

                promo: (token, code) => {
                    return axios.post(`/api/internal/cart/${token}/promo/add`, {
                        promo: code
                    });
                },

                addBestPromo: (cartId) => {
                    return axios.post(`/api/cart/${cartId}/promo/find-best`);
                }
            },
            PUT: {
                edit: (token, uuid, items) => {
                    return axios.put(`/api/internal/cart/${token}/item/${uuid}/edit`, {
                        ...items
                    });
                }
            }
        },
        POST: {
            create: (items) => {
                return axios.post(`/api/cart/create`, {
                    items
                });
            },

            promo: (token, code) => {
                return axios.post(`/api/cart/${token}/promo/add`, {
                    promo: code
                });
            }
        },
        DELETE: {
            promo: (token, code) => {
                return axios.delete(`/api/cart/${token}/promo/${code}`);
            }
        }
    },

    order: {
        POST: {
            create: (token, paymentMethodId) => {
                return axios.post(`/api/cart/${token}/order/create`, {
                    payment_method_id: paymentMethodId
                });
            }
        }
    }
};

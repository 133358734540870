/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import Bold from '@tiptap/extension-bold';
import BubbleMenu from '@tiptap/extension-bubble-menu';
import BulletList from '@tiptap/extension-bullet-list';
import Document from '@tiptap/extension-document';
import History from '@tiptap/extension-history';
import Italic from '@tiptap/extension-italic';
import Link from '@tiptap/extension-link';
import ListItem from '@tiptap/extension-list-item';
import OrderedList from '@tiptap/extension-ordered-list';
import Paragraph from '@tiptap/extension-paragraph';
import Text from '@tiptap/extension-text';
import { EditorProvider } from '@tiptap/react';
import _ from 'lodash';
import React, { useCallback, useMemo } from 'react';
import { Markdown } from 'tiptap-markdown';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import { MarkdownExtractorPlugin } from 'components/Form/RichTextEditor/plugins/MarkdownExtractor/MarkdownExtractorPlugin';
import { MarkdownInjectorPlugin } from 'components/Form/RichTextEditor/plugins/MarkdownInjector/MarkdownInjectorPlugin';
import { PluginRenderer } from 'components/Form/RichTextEditor/plugins/PluginRenderer/PluginRenderer';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { collectionIncludes } from 'utilities/methods/collectionIncludes/collectionIncludes';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import { useComponentDebugTrackerCount, useSuperUserActionKey } from 'components/StaffMenu/SuperUser/consts';

/**********************************************************************************************************
 *   TYPE IMPORTS
 **********************************************************************************************************/
import { RichTextEditorContent } from 'components/Form/RichTextEditor/_RichTextEditor/RichTextEditorContent';
import { NoNewLine } from 'components/Form/RichTextEditor/plugins/NoNewLine/NoNewLinePlugin';
import { RichTextEditorContextProvider } from 'components/Form/RichTextEditor/RichTextEditorContextProvider';
import type { RichTextEditorNamespace } from 'components/Form/RichTextEditor/types';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 *
 * @prop {boolean} __debugEnabled - If true, logs the markdown string to the console on change
 */
export const _RichTextEditor: React.FC<RichTextEditorNamespace.Props> = ({
    markdown = '',
    onChange,
    features = [],
    className,
    style,
    __debugEnabled = false
}) => {
    /***** HOOKS *****/
    useComponentDebugTrackerCount('RichTextEditor');
    const { value: isRichTextEditorDebugEnabled } = useSuperUserActionKey('RICH_TEXT_EDITOR_DEBUG');
    const isDebugEnabled = useMemo(() => __debugEnabled || isRichTextEditorDebugEnabled, [__debugEnabled, isRichTextEditorDebugEnabled]);

    /***** FUNCTIONS *****/
    const handleOnChange = useCallback(
        (markdown: string) => {
            if (isDebugEnabled) {
                console.log('markdown', markdown);
                console.log(JSON.stringify(markdown));
            }
            onChange?.(markdown);
        },
        [onChange, isDebugEnabled]
    );

    /***** RENDER HELPERS *****/
    const extensions = useMemo(() => {
        return [
            Document,
            Paragraph,
            Text,
            Markdown,
            BubbleMenu,
            History,
            ..._.compact([
                collectionIncludes(features, ['link']) &&
                    Link.configure({
                        openOnClick: false,
                        autolink: true,
                        defaultProtocol: 'https'
                    }),
                collectionIncludes(features, ['no_new_line']) && NoNewLine,
                collectionIncludes(features, ['bold']) && Bold,
                collectionIncludes(features, ['italic']) && Italic,
                collectionIncludes(features, ['ul']) && BulletList,
                collectionIncludes(features, ['ol']) && OrderedList,
                collectionIncludes(features, ['ol', 'ul']) && ListItem
            ])
        ];
    }, [features]);

    /***** RENDER *****/
    return (
        <RichTextEditorContextProvider>
            <RichTextEditorContent className={className} style={style}>
                <EditorProvider extensions={extensions} content="">
                    <PluginRenderer features={features} />
                    <MarkdownExtractorPlugin markdown={markdown} onChange={handleOnChange} />
                    <MarkdownInjectorPlugin markdown={markdown} />
                </EditorProvider>
            </RichTextEditorContent>
        </RichTextEditorContextProvider>
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

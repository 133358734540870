/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import htmr from 'htmr';
import React from 'react';
import { formatDescription } from 'utilities/methods/commonActions';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
const InvoiceItems = ({ items }) =>
    items.map((lineItem, index) => {
        return (
            <div key={`lineitem-${index}`} className="item__row">
                <div className="item__column--description">{htmr(formatDescription(lineItem.attributes.description))}</div>
                <div className="item__column--price">${lineItem.attributes.amount} AUD</div>
            </div>
        );
    });

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

export { InvoiceItems };

/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { batchActions } from 'redux-batched-actions';
import store from '../../../store/store';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { pushNotification } from 'components/Toast/functions';
import { getDataFromSuccessResponse, getErrorFromFailResponse } from 'utilities/methods/commonActions';

/*   ACTIONS
 *****************************************************/
import { API as ACCOUNT } from 'utilities/api/account';
import { API } from 'utilities/api/hosting';
import { API as SERVICES } from 'utilities/api/services';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import { PAYMENT_METHODS } from 'components/Lightboxes/OverlayLightbox/Components/invoice/paymentMethods/consts';

/**********************************************************************************************************
 *   DECLARATIONS
 **********************************************************************************************************/

export const HOSTING_RESOURCE_REQUEST = 'hosting/HOSTING_RESOURCE_REQUEST';
export const HOSTING_RESOURCE_SUCCESS = 'hosting/HOSTING_RESOURCE_SUCCESS';
export const HOSTING_RESOURCE_ERROR = 'hosting/HOSTING_RESOURCE_ERROR';

export const HOSTING_RESOURCE_CONFIG_REQUEST = 'hosting/HOSTING_RESOURCE_CONFIG_REQUEST';
export const HOSTING_RESOURCE_CONFIG_SUCCESS = 'hosting/HOSTING_RESOURCE_CONFIG_SUCCESS';
export const HOSTING_RESOURCE_CONFIG_ERROR = 'hosting/HOSTING_RESOURCE_CONFIG_ERROR';

export const HOSTING_RESOURCE_UPDATE_REQUEST = 'hosting/HOSTING_RESOURCE_UPDATE_REQUEST';
export const HOSTING_RESOURCE_UPDATE_SUCCESS = 'hosting/HOSTING_RESOURCE_UPDATE_SUCCESS';
export const HOSTING_RESOURCE_UPDATE_ERROR = 'hosting/HOSTING_RESOURCE_UPDATE_ERROR';

export const HOSTING_ADDON_LIST_REQUEST = 'hosting/HOSTING_ADDON_LIST_REQUEST';
export const HOSTING_ADDON_LIST_SUCCESS = 'hosting/HOSTING_ADDON_LIST_SUCCESS';
export const HOSTING_ADDON_LIST_ERROR = 'hosting/HOSTING_ADDON_LIST_ERROR';

export const HOSTING_ADDON_REMOVE_REQUEST = 'hosting/HOSTING_ADDON_REMOVE_REQUEST';
export const HOSTING_ADDON_REMOVE_SUCCESS = 'hosting/HOSTING_ADDON_REMOVE_SUCCESS';
export const HOSTING_ADDON_REMOVE_ERROR = 'hosting/HOSTING_ADDON_REMOVE_ERROR';

export const HOSTING_ADDON_UPDATE_REQUEST = 'hosting/HOSTING_ADDON_UPDATE_REQUEST';
export const HOSTING_ADDON_UPDATE_SUCCESS = 'hosting/HOSTING_ADDON_UPDATE_SUCCESS';
export const HOSTING_ADDON_UPDATE_ERROR = 'hosting/HOSTING_ADDON_UPDATE_ERROR';

export const HOSTING_ADDON_CANCEL_REQUEST = 'hosting/HOSTING_ADDON_CANCEL_REQUEST';
export const HOSTING_ADDON_CANCEL_SUCCESS = 'hosting/HOSTING_ADDON_CANCEL_SUCCESS';
export const HOSTING_ADDON_CANCEL_ERROR = 'hosting/HOSTING_ADDON_CANCEL_ERROR';

export const HOSTING_ADDON_KEEP_REQUEST = 'hosting/HOSTING_ADDON_KEEP_REQUEST';
export const HOSTING_ADDON_KEEP_SUCCESS = 'hosting/HOSTING_ADDON_KEEP_SUCCESS';
export const HOSTING_ADDON_KEEP_ERROR = 'hosting/HOSTING_ADDON_KEEP_ERROR';

export const HOSTING_CREATE_CART_REQUEST = 'hosting/HOSTING_CREATE_CART_REQUEST';
export const HOSTING_CREATE_CART_SUCCESS = 'hosting/HOSTING_CREATE_CART_SUCCESS';
export const HOSTING_CREATE_CART_ERROR = 'hosting/HOSTING_CREATE_CART_ERROR';

export const HOSTING_EDIT_CART_ITEMS_REQUEST = 'hosting/HOSTING_EDIT_CART_ITEMS_REQUEST';
export const HOSTING_EDIT_CART_ITEMS_SUCCESS = 'hosting/HOSTING_EDIT_CART_ITEMS_SUCCESS';
export const HOSTING_EDIT_CART_ITEMS_ERROR = 'hosting/HOSTING_EDIT_CART_ITEMS_ERROR';

export const HOSTING_RESET_CART_PROMO = 'hosting/HOSTING_RESET_CART_PROMO';

export const HOSTING_ADD_PROMO_CODE_REQUEST = 'hosting/HOSTING_ADD_PROMO_CODE_REQUEST';
export const HOSTING_ADD_PROMO_CODE_SUCCESS = 'hosting/HOSTING_ADD_PROMO_CODE_SUCCESS';
export const HOSTING_ADD_PROMO_CODE_ERROR = 'hosting/HOSTING_ADD_PROMO_CODE_ERROR';

export const HOSTING_ADD_BEST_PROMO_REQUEST = 'hosting/HOSTING_ADD_BEST_PROMO_REQUEST';
export const HOSTING_ADD_BEST_PROMO_SUCCESS = 'hosting/HOSTING_ADD_BEST_PROMO_SUCCESS';
export const HOSTING_ADD_BEST_PROMO_ERROR = 'hosting/HOSTING_ADD_BEST_PROMO_ERROR';

export const HOSTING_REMOVE_PROMO_CODE_REQUEST = 'hosting/HOSTING_REMOVE_PROMO_CODE_REQUEST';
export const HOSTING_REMOVE_PROMO_CODE_SUCCESS = 'hosting/HOSTING_REMOVE_PROMO_CODE_SUCCESS';
export const HOSTING_REMOVE_PROMO_CODE_ERROR = 'hosting/HOSTING_REMOVE_PROMO_CODE_ERROR';

export const HOSTING_CREATE_ORDER_REQUEST = 'hosting/HOSTING_CREATE_ORDER_REQUEST';
export const HOSTING_CREATE_ORDER_SUCCESS = 'hosting/HOSTING_CREATE_ORDER_SUCCESS';
export const HOSTING_CREATE_ORDER_ERROR = 'hosting/HOSTING_CREATE_ORDER_ERROR';

export const HOSTING_LIMITS_REQUEST = 'hosting/HOSTING_LIMITS_REQUEST';
export const HOSTING_LIMITS_SUCCESS = 'hosting/HOSTING_LIMITS_SUCCESS';
export const HOSTING_LIMITS_ERROR = 'hosting/HOSTING_LIMITS_ERROR';

/**********************************************************************************************************
 *   ACTIONS - INITIAL
 **********************************************************************************************************/

export const getHostingResources = (id) => {
    return (dispatch) => {
        dispatch({
            type: HOSTING_RESOURCE_REQUEST
        });
        API.hosting.GET.custom
            .resources(id)
            .then((response) => {
                dispatch({
                    type: HOSTING_RESOURCE_SUCCESS,
                    hosting_resource_data: getDataFromSuccessResponse(response)
                });
            })
            .catch((error) => {
                dispatch({
                    type: HOSTING_RESOURCE_ERROR,
                    hosting_resource_error: getErrorFromFailResponse(error)
                });
            });
    };
};

export const getHostingResourceConfig = (id) => {
    return (dispatch) => {
        dispatch({
            type: HOSTING_RESOURCE_CONFIG_REQUEST
        });
        API.hosting.GET.custom
            .configList(id ? id : false)
            .then((response) => {
                dispatch({
                    type: HOSTING_RESOURCE_CONFIG_SUCCESS,
                    hosting_resource_config_data: getDataFromSuccessResponse(response)
                });
            })
            .catch((error) => {
                dispatch({
                    type: HOSTING_RESOURCE_CONFIG_ERROR,
                    hosting_resource_config_error: getErrorFromFailResponse(error)
                });
            });
    };
};

/**
 * @param {number} id
 */
export const getResourceLimits = (id) => {
    const { dispatch } = store;

    dispatch({
        type: HOSTING_LIMITS_REQUEST
    });

    API.hosting.GET.custom
        .resourceLimits(id)
        .then((response) =>
            dispatch({
                type: HOSTING_LIMITS_SUCCESS,
                hosting_limits_data: getDataFromSuccessResponse(response)
            })
        )
        .catch(() =>
            dispatch({
                type: HOSTING_LIMITS_ERROR
            })
        );
};

export const updateResourceAllocations = (id, attributes) => {
    return (dispatch) => {
        dispatch({
            type: HOSTING_RESOURCE_UPDATE_REQUEST
        });
        API.hosting.POST.custom
            .change(id, attributes)
            .then((response) => {
                const hosting_resource_update_data = getDataFromSuccessResponse(response);
                pushNotification(hosting_resource_update_data);
                dispatch({
                    type: HOSTING_RESOURCE_UPDATE_SUCCESS,
                    hosting_resource_update_data
                });
            })
            .catch((error) => {
                const hosting_resource_update_error = getErrorFromFailResponse(error);
                pushNotification(hosting_resource_update_error);
                dispatch({
                    type: HOSTING_RESOURCE_UPDATE_ERROR,
                    hosting_resource_update_error
                });
            });
    };
};

export const getActiveAddons = (serviceId) => {
    return (dispatch) => {
        dispatch({
            type: HOSTING_ADDON_LIST_REQUEST
        });
        SERVICES.service.get
            .addons(serviceId)
            .then((response) => {
                dispatch({
                    type: HOSTING_ADDON_LIST_SUCCESS,
                    hosting_addons_list_data: getDataFromSuccessResponse(response)
                });
            })
            .catch((error) => {
                dispatch({
                    type: HOSTING_ADDON_LIST_ERROR,
                    hosting_addon_list_error: getErrorFromFailResponse(error)
                });
            });
    };
};

export const removeActiveAddon = (id, attributes) => {
    return (dispatch) => {
        dispatch({
            type: HOSTING_ADDON_REMOVE_REQUEST
        });
        SERVICES.service.post
            .cancel(id, attributes)
            .then((response) => {
                const hosting_addon_remove_data = getDataFromSuccessResponse(response);
                pushNotification(hosting_addon_remove_data);
                dispatch({
                    type: HOSTING_ADDON_REMOVE_SUCCESS,
                    hosting_addon_remove_data
                });
            })
            .catch((error) => {
                const hosting_addon_remove_error = getErrorFromFailResponse(error);
                pushNotification(hosting_addon_remove_error);
                dispatch({
                    type: HOSTING_ADDON_REMOVE_ERROR,
                    hosting_addon_remove_error
                });
            });
    };
};

export const updateActiveAddons = (id, attributes) => {
    return (dispatch) => {
        dispatch({
            type: HOSTING_ADDON_UPDATE_REQUEST
        });
        SERVICES.service.post
            .purchaseAddon(id, attributes)
            .then((response) => {
                const hosting_addon_update_data = getDataFromSuccessResponse(response);
                pushNotification(hosting_addon_update_data);
                dispatch({
                    type: HOSTING_ADDON_UPDATE_SUCCESS,
                    hosting_addon_update_data
                });
            })
            .catch((error) => {
                const hosting_addon_update_error = getErrorFromFailResponse(error);
                pushNotification(hosting_addon_update_error);
                dispatch({
                    type: HOSTING_ADDON_UPDATE_ERROR,
                    hosting_addon_update_error
                });
            });
    };
};

export const cancelAddonsRequest = (id) => {
    return (dispatch) => {
        dispatch({
            type: HOSTING_ADDON_CANCEL_REQUEST
        });
        ACCOUNT.account.POST.invoice
            .cancel(id)
            .then((response) => {
                const hosting_addon_cancel_data = getDataFromSuccessResponse(response);
                pushNotification(hosting_addon_cancel_data);
                dispatch({
                    type: HOSTING_ADDON_CANCEL_SUCCESS,
                    hosting_addon_cancel_data
                });
            })
            .catch((error) => {
                const hosting_addon_cancel_error = getErrorFromFailResponse(error);
                pushNotification(hosting_addon_cancel_error);
                dispatch({
                    type: HOSTING_ADDON_CANCEL_ERROR,
                    hosting_addon_cancel_error
                });
            });
    };
};

export const keepActiveAddon = (id, attributes) => {
    return (dispatch) => {
        dispatch({
            type: HOSTING_ADDON_KEEP_REQUEST
        });
        API.hosting.POST.custom
            .keepAddon(id, attributes)
            .then((response) => {
                const hosting_addon_keep_data = getDataFromSuccessResponse(response);
                pushNotification(hosting_addon_keep_data);
                dispatch({
                    type: HOSTING_ADDON_KEEP_SUCCESS,
                    hosting_addon_keep_data
                });
            })
            .catch((error) => {
                const hosting_addon_keep_error = getErrorFromFailResponse(error);
                pushNotification(hosting_addon_keep_error);
                dispatch({
                    type: HOSTING_ADDON_KEEP_ERROR,
                    hosting_addon_keep_error
                });
            });
    };
};

export const createCart = (items, options) => {
    return (dispatch) => {
        dispatch({
            type: HOSTING_CREATE_CART_REQUEST
        });
        SERVICES.cart.internal.POST.create(items)
            .then((response) => {
                const hosting_create_cart_data = getDataFromSuccessResponse(response);
                dispatch({
                    type: HOSTING_CREATE_CART_SUCCESS,
                    hosting_create_cart_data
                });

                options?.onSuccess?.(hosting_create_cart_data);
            })
            .catch((error) => {
                const hosting_create_cart_error = getErrorFromFailResponse(error);
                pushNotification(hosting_create_cart_error);
                dispatch(
                    batchActions([
                        {
                            type: HOSTING_CREATE_CART_ERROR,
                            hosting_create_cart_error
                        }
                    ])
                );
            });
    };
};

export const editCartItems = (token, uuid, items) => {
    return (dispatch) => {
        dispatch({
            type: HOSTING_EDIT_CART_ITEMS_REQUEST
        });
        SERVICES.cart.internal.PUT.edit(token, uuid, items)
            .then((response) => {
                const hosting_edit_cart_items_data = getDataFromSuccessResponse(response);
                pushNotification(hosting_edit_cart_items_data);
                dispatch(
                    batchActions([
                        {
                            type: HOSTING_EDIT_CART_ITEMS_SUCCESS,
                            hosting_edit_cart_items_data
                        }
                    ])
                );
            })
            .catch((error) => {
                const hosting_edit_cart_items_error = getErrorFromFailResponse(error);
                pushNotification(hosting_edit_cart_items_error);
                dispatch(
                    batchActions([
                        {
                            type: HOSTING_EDIT_CART_ITEMS_ERROR,
                            hosting_edit_cart_items_error
                        }
                    ])
                );
            });
    };
};

export const addPromoCode = (token, code, hideNotification, options) => {
    return (dispatch) => {
        dispatch({
            type: HOSTING_ADD_PROMO_CODE_REQUEST
        });
        SERVICES.cart.internal.POST.promo(token, code)
            .then((response) => {
                const hosting_add_promo_code_data = getDataFromSuccessResponse(response);
                dispatch({
                    type: HOSTING_ADD_PROMO_CODE_SUCCESS,
                    hosting_add_promo_code_data
                });
                if (!hideNotification) pushNotification(hosting_add_promo_code_data);

                options?.onSuccess?.(hosting_add_promo_code_data);
            })
            .catch((error) => {
                const hosting_add_promo_code_error = getErrorFromFailResponse(error);
                pushNotification(hosting_add_promo_code_error);
                dispatch({
                    type: HOSTING_ADD_PROMO_CODE_ERROR,
                    hosting_add_promo_code_error
                });
                if (!hideNotification) pushNotification(hosting_add_promo_code_error);
            });
    };
};

export const addBestPromo = (cartId, options) => {
    return (dispatch) => {
        dispatch({
            type: HOSTING_ADD_BEST_PROMO_REQUEST
        });
        SERVICES.cart.internal.POST.addBestPromo(cartId)
            .then((response) => {
                const hosting_add_best_promo_data = getDataFromSuccessResponse(response);
                dispatch({
                    type: HOSTING_ADD_BEST_PROMO_SUCCESS,
                    hosting_add_best_promo_data
                });

                options?.onSuccess?.(hosting_add_best_promo_data);
            })
            .catch((error) => {
                pushNotification(getErrorFromFailResponse(error));
                dispatch({
                    type: HOSTING_ADD_BEST_PROMO_ERROR
                });
            });
    };
};

export const removePromoCode = (token, code) => {
    return (dispatch) => {
        dispatch({
            type: HOSTING_REMOVE_PROMO_CODE_REQUEST
        });
        SERVICES.cart.DELETE.promo(token, code)
            .then((response) => {
                const hosting_remove_promo_code_data = getDataFromSuccessResponse(response);
                pushNotification(hosting_remove_promo_code_data);
                dispatch(
                    batchActions([
                        {
                            type: HOSTING_REMOVE_PROMO_CODE_SUCCESS,
                            hosting_remove_promo_code_data
                        }
                    ])
                );
            })
            .catch((error) => {
                const hosting_remove_promo_code_error = getErrorFromFailResponse(error);
                pushNotification(hosting_remove_promo_code_error);
                dispatch(
                    batchActions([
                        {
                            type: HOSTING_REMOVE_PROMO_CODE_ERROR,
                            hosting_remove_promo_code_error
                        }
                    ])
                );
            });
    };
};

export const createOrder = (items, options) => {
    return (dispatch) => {
        dispatch({
            type: HOSTING_CREATE_ORDER_REQUEST
        });

        ACCOUNT.user.payment_methods
            .GET()
            .then((paymentMethodsResponse) => {
                const paymentMethodsData = getDataFromSuccessResponse(paymentMethodsResponse);
                const bpayPaymentMethodId = paymentMethodsData?.find((method) => method.name === PAYMENT_METHODS.BPAY)?.id;

                SERVICES.order.POST.create(items, bpayPaymentMethodId)
                    .then((response) => {
                        const hosting_create_order_data = getDataFromSuccessResponse(response);
                        dispatch(
                            batchActions([
                                {
                                    type: HOSTING_CREATE_ORDER_SUCCESS,
                                    hosting_create_order_data
                                },
                                {
                                    type: HOSTING_RESET_CART_PROMO
                                }
                            ])
                        );
                        dispatch({
                            type: HOSTING_CREATE_ORDER_SUCCESS,
                            hosting_create_order_data
                        });

                        options?.onSuccess?.(hosting_create_order_data);
                    })
                    .catch((error) => {
                        const hosting_create_order_error = getErrorFromFailResponse(error);
                        pushNotification(hosting_create_order_error);
                        dispatch({
                            type: HOSTING_CREATE_ORDER_ERROR,
                            hosting_create_order_error
                        });

                        options?.onError?.();
                    });
            })
            .catch((error) => {
                const paymentMethodsError = getErrorFromFailResponse(error);
                pushNotification(error);
                dispatch({
                    type: HOSTING_CREATE_ORDER_ERROR,
                    paymentMethodsError
                });

                options?.onError?.();
            });
    };
};

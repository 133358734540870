/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import _ from 'lodash';

/**********************************************************************************************************
 *   TYPE IMPORTS
 **********************************************************************************************************/
import type { Store } from '@tanstack/react-store';
import type { DefaultStore } from 'utilities/methods/createModuleStore/types';

/***** FUNCTIONS *****/
export const createUpdateEnabledWithReason = <TStore extends DefaultStore>(store: Store<TStore>) => {
    return (key: string, enabled: boolean, reason: string): void => {
        const currentEnabled = _.get(store.state, `${key}.enabled`) as unknown as boolean;

        if (currentEnabled === enabled) {
            return;
        }

        store.setState((state) => {
            _.set(state, `${key}.enabled`, enabled);
            if (import.meta.env.DEV) {
                _.set(state, `${key}.reason`, reason);
            }
            return _.cloneDeep(state);
        });
    };
};

import { StaffMenuContext } from 'components/StaffMenu/context';
import { useCallback, useContext, useId } from 'react';

/**********************************************************************************************************
 *   HOOK START
 **********************************************************************************************************/
export const useStaffMenu = () => {
    /***** HOOKS *****/
    const id = useId();
    const { active: _active, setActive, isOpen } = useContext(StaffMenuContext);

    /***** FUNCTIONS *****/
    const toggle = useCallback(() => {
        setActive((active) => {
            return active === id ? '' : id;
        });
    }, [setActive, id]);

    /***** RENDER HELPERS *****/
    const active = _active === id;

    /***** RESULT *****/
    return {
        id,
        active,
        toggle,
        setActive,
        isOpen
    };
};

/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { useNavigate } from '@tanstack/react-router';
import { useSelector } from 'react-redux';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import { CustomDropdown } from 'components/Dropdowns/CustomDropdown';
import SelectOptions from 'components/Dropdowns/SelectDropdown/Options';
import { PhosphorIcons } from 'components/Icons/Phosphor';
import Title from 'components/Title';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { getTicketSubmitUrl } from 'containers/support/methods';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const ManageDomainTitle = () => {
    /***** HOOKS *****/
    const navigate = useNavigate();
    const domain_information_data = useSelector((state: any) => state.domain.domain_information_data);

    /***** RENDER *****/
    if (!domain_information_data) {
        // Ideally consider creating a "Title.Loader" component for when the page is loading,
        // this would go as part of the pending component on the route
        return null;
    }

    return (
        <Title
            serviceTitle={domain_information_data.attributes.domain}
            serviceType={domain_information_data.type}
            action={[
                <CustomDropdown
                    className="TitleShared__singleDropdown"
                    label={({ labelRef, dropdownTitleOnClick }) => (
                        <button className="TitleShared__singleDropdownButton" ref={labelRef} type="button" onClick={() => dropdownTitleOnClick()}>
                            Domain Names Help
                            <PhosphorIcons.Caret.Down className="item__icon" />
                        </button>
                    )}
                    key="dropdown"
                    render={() => (
                        <SelectOptions
                            options={[
                                {
                                    label: 'Domain Name Help Articles',
                                    onSelect: () => navigate({ to: '/support/support-centre/domain-names' as string })
                                },
                                {
                                    label: 'Submit a Technical Support eTicket for this Service',
                                    onSelect: () =>
                                        navigate({ to: getTicketSubmitUrl({ department: 'technical-support', service: domain_information_data.id }) })
                                },
                                {
                                    label: 'Submit a Billing eTicket for this Service',
                                    onSelect: () =>
                                        navigate({ to: getTicketSubmitUrl({ department: 'accounts-billing', service: domain_information_data.id }) })
                                }
                            ]}
                        />
                    )}
                />
            ]}
        />
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

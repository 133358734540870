/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { useLocation } from '@tanstack/react-router';
import Subnav from 'components/Subnav';
import SolidTag from 'components/Tags/SolidTag';
import { useSelector } from 'react-redux';

export const BillingSubnav = () => {
    /***** SELECTORS *****/
    const app_user_data = useSelector((state: any) => state.app.app_user_data);
    const { pathname } = useLocation();

    /***** RENDER HELPERS *****/
    const navItems = [
        {
            label: 'Invoices',
            link: '/billing/invoices',
            icon: 'icon-migrations'
        },
        {
            label: (
                <div className="Subnav__option--withTag">
                    VentraIP Wallet<SolidTag color="confirm">new</SolidTag>
                </div>
            ),
            link: '/billing/ventraip-wallet',
            icon: ''
        },
        {
            label: 'Services Alignment',
            link: '/billing/services-alignment',
            icon: ''
        },
        {
            label: 'SLA Claim',
            link: '/billing/sla',
            icon: 'customer-service'
        }
    ];

    if (app_user_data?.role === 'Account Holder' || app_user_data?.role === 'Admin') {
        navItems.splice(1, 0, {
            label: 'Payment Methods',
            link: '/billing/payment-methods',
            icon: 'eticket'
        });
    }

    /***** RENDER *****/
    return <Subnav items={navItems} pathname={pathname} />;
};

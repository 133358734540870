import { formatString } from 'utilities/methods/formatString';

export function formatBusinessNumber(value: string | unknown, businessNumberType: 'ABN' | 'ACN') {
    switch (businessNumberType) {
        case 'ACN':
            return formatString(value, 'XXX XXX XXX');
        case 'ABN':
            return formatString(value, 'XX XXX XXX XXX');

        default:
            return value;
    }
}

/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { useMemo, useState } from 'react';
import _ from 'lodash';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import Text from 'components/Utils/Text';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import { EntryCreationBehaviourHandlerOnImageAddWithKatanaImageUpload } from 'containers/katana/components/EntryCreationBehaviourHandler/wrapper/katana/uploadImage';
import { KatanaFileFormFieldMediaDecoupled } from 'containers/katana/formFields/file/media/KatanaFileFormFieldMediaDecoupled';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { useDynamicFormActions } from 'containers/katana/components/dynamicFormFieldRenderer/hooks/useDynamicFormActions';
import { useTargetKeyToPropertyPath } from 'containers/katana/components/dynamicFormFieldRenderer/hooks/useTargetKeyToPropertyPath';
import { useGetCaveat } from 'containers/katana/formFields/methods/useGetCaveat';
import { useKatanaParams } from 'containers/katana/hooks/useSetupEditorRouteParams';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import { CaveatTypes } from 'containers/katana/formFields/caveatTypes';
import { entryCreationBehaviourTypes } from 'containers/katana/formFields/entryCreationBehaviourTypes';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
import type { MediaFeatures } from 'containers/katana/components/EntryCreationBehaviourHandler/types';
import type { FieldArrayFieldsProps } from 'redux-form';
import type { EntryCreationBehaviourTypeValues } from 'containers/katana/formFields/types';
import type { KatanaNamespace } from 'containers/katana/types';
import type { UseKatanaFileUpload } from 'components/Form/NXDropZone/wrappers/withKatanaFileUpload/types';

type EntryCreationBehaviourHandlerProps = {
    method: EntryCreationBehaviourTypeValues;
    target: string;
    fields: FieldArrayFieldsProps<unknown>;
    properties: KatanaNamespace.SectionDefinitions.PropertiesModified[];
    property: KatanaNamespace.SectionDefinitions.PropertiesModified;
};

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/

export const EntryCreationBehaviourHandler: React.FC<EntryCreationBehaviourHandlerProps> = (props) => {
    const { method, target, fields, properties, property } = props;
    const fieldsLength = fields.length;

    /***** STATE *****/
    const [activeFeature, setActiveFeature] = useState<MediaFeatures>();

    /***** HOOKS *****/
    const { katanaServiceId } = useKatanaParams();
    const { dynamicChange } = useDynamicFormActions();
    const firstEntryCreationTargetKey = useGetCaveat(CaveatTypes.ENTRY_CREATION_FIRST_ENTRY_COPY_TO_TARGET_KEY, property.caveats);
    const newPropertyAccessor = useTargetKeyToPropertyPath(property?.key, firstEntryCreationTargetKey);
    const fileLimit = useGetCaveat(CaveatTypes.REPEATABLE_PROPERTY_LIMIT, property.caveats);

    /***** FUNCTIONS *****/
    function handleEmitValue(value: UseKatanaFileUpload.Values | UseKatanaFileUpload.Values[]) {
        if (firstEntryCreationTargetKey && fields.length === 0) {
            dynamicChange(newPropertyAccessor, value);
        }
        fields.push({
            [target]: value
        });
        setActiveFeature(undefined);
    }

    /***** RENDER HELPERS *****/
    const targetProperty = properties.find((property) => property?.originalKey === target);

    const targetPropertyGenerater = useMemo(() => {
        // This is an alternative targetProperty with property key including target parent index
        // It makes the correct property key available for its child and grandchild components
        // which makes it easier to generate the key for sectionDefinitionUnsplashDownloadLinks
        const dynamicTargetProperty = _.clone(targetProperty);
        const targetPropertyKey = targetProperty?.key;
        const regExp = new RegExp(`.${target}+$`);
        const newTargetPropertyKey = targetPropertyKey?.replace(regExp, `[${fieldsLength}].${target}`);
        dynamicTargetProperty.key = newTargetPropertyKey;
        return dynamicTargetProperty;
    }, [fieldsLength]);

    /***** RENDER *****/
    if (!targetProperty) {
        return <Text>No target property found for {target}</Text>;
    }

    switch (method) {
        case entryCreationBehaviourTypes.ON_IMAGE_ADD: {
            return (
                <EntryCreationBehaviourHandlerOnImageAddWithKatanaImageUpload
                    onEmitValue={handleEmitValue}
                    serviceID={katanaServiceId}
                    targetProperty={targetProperty}
                    fileLimit={fileLimit}
                    fields={fields}
                />
            );
        }

        case entryCreationBehaviourTypes.ON_MEDIA_ADD: {
            return (
                <KatanaFileFormFieldMediaDecoupled
                    property={targetPropertyGenerater}
                    onEmitValue={handleEmitValue}
                    activeFeature={activeFeature}
                    setActiveFeature={setActiveFeature}
                />
            );
        }

        default:
            return <Text>No Entry Creation Behaviour for {method}</Text>;
    }
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

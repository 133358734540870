/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import { KatanaBooleanFormField } from 'containers/katana/formFields/boolean';
import { KatanaChoiceFormField } from 'containers/katana/formFields/choice';
import { KatanaFileFormField } from 'containers/katana/formFields/file';
import { KatanaGroupFormField } from 'containers/katana/formFields/group';
import { KatanaRepeatedFormField } from 'containers/katana/formFields/repeated';
import { KatanaTextFormField } from 'containers/katana/formFields/text';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import { SECTION_DEFINITION_PROPERTY_TYPES } from '../components/contentDefinitionForm/consts';

const { BOOLEAN, CHOICE, FILE, GROUP, REPEATED, TEXT } = SECTION_DEFINITION_PROPERTY_TYPES;

export const contentDefinitionPropertyFormByType = {
    [BOOLEAN]: KatanaBooleanFormField,
    [CHOICE]: KatanaChoiceFormField,
    [FILE]: KatanaFileFormField,
    [GROUP]: KatanaGroupFormField,
    [REPEATED]: KatanaRepeatedFormField,
    [TEXT]: KatanaTextFormField
} as const;

import { _Accordion } from 'components/Accordion/_Accordion';
import { _AccordionTitle } from 'components/Accordion/_AccordionTitle';
import { _AccordionWithDragHandle } from 'components/Accordion/wrappers/AccordionWithDragHandle';
import { _SlickAccordion } from 'components/Accordion/wrappers/styled/_SlickAccordion';

export const Accordion = Object.assign(_Accordion, {
    Title: _AccordionTitle,
    WithDragHandle: _AccordionWithDragHandle,
    Slick: _SlickAccordion
});

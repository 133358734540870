import { COMMON_INITIAL_STATE, COMMON_COPY_SUCCESS, COMMON_TOOLTIP_CHANGE } from './declerations';

const initialState = {
    common_copy_status: null,
    common_tooltip_open: false
};

const common = (state = initialState, action) => {
    switch (action.type) {
        /**********************************************************************************************************
         *   RESET
         **********************************************************************************************************/
        case COMMON_INITIAL_STATE:
            return initialState;

        /**********************************************************************************************************
         *   COPY
         **********************************************************************************************************/
        case COMMON_COPY_SUCCESS:
            return {
                ...state,
                common_copy_status: 'success'
            };

        /**********************************************************************************************************
         *   ToolTipOpen
         **********************************************************************************************************/
        case COMMON_TOOLTIP_CHANGE:
            return {
                ...state,
                common_tooltip_open: action.common_tooltip_open
            };
        default:
            return state;
    }
};
export default common;

/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React from 'react';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import { EntryCreationBehaviourHandler } from 'containers/katana/components/EntryCreationBehaviourHandler';
import { RenderDefaultButton } from 'containers/katana/components/EntryCreationBehaviourHandler/wrapper/RenderDefaultButton';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { entryCreationBehaviourParser } from 'containers/katana/formFields/methods/entryCreationBehaviourParser';
import { useGetCaveat } from 'containers/katana/formFields/methods/useGetCaveat';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import { CaveatTypes } from 'containers/katana/formFields/caveatTypes';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * @param {import('containers/katana/components/EntryCreationBehaviourHandler/wrapper/types').EntryCreationBehaviourHandlerCoreProps} props
 */
export function RenderEntryCreationElement(props) {
    const { property, fields, properties } = props;

    /***** RENDER HELPERS *****/
    const entryCreationBehaviour = useGetCaveat(CaveatTypes.ENTRY_CREATION_BEHAVIOUR, property.caveats);

    /***** RENDER *****/
    if (entryCreationBehaviour) {
        const entryBehaviour = entryCreationBehaviourParser(entryCreationBehaviour) ?? null;
        if (entryBehaviour) {
            return <EntryCreationBehaviourHandler {...entryBehaviour} fields={fields} properties={properties} property={property} />;
        }
    }

    return <RenderDefaultButton {...props} />;
}

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { useChildMatches } from '@tanstack/react-router';

/**********************************************************************************************************
 *   TYPE IMPORTS
 **********************************************************************************************************/
import type { AnyRouteId } from 'router/types';

/**********************************************************************************************************
 *   HOOK START
 **********************************************************************************************************/
export const useChildMatchById = (id: AnyRouteId | AnyRouteId[]) => {
    /***** RESULTS *****/
    return useChildMatches({
        select: (matches) => {
            if (Array.isArray(id)) {
                return matches.some(({ routeId }) => id.includes(routeId as AnyRouteId));
            }

            return matches.some(({ routeId }) => routeId === id);
        }
    });
};

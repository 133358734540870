/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import useResizeObserver from '@react-hook/resize-observer';
import type React from 'react';
import { useLayoutEffect, useState } from 'react';

/**********************************************************************************************************
 *   HOOK START
 **********************************************************************************************************/
/**
 * Custom hook to calculate the height of an element.
 * 
 * Make sure your element uses paddings as margins can cause recalculations
 * @param {React.RefObject<HTMLDivElement>} element - The element to calculate the height of.
 * @returns {number} - The height of the element.
 */
export function useElementHeight(element: React.RefObject<HTMLDivElement>) {
    /***** STATE *****/
    const [height, setHeight] = useState(0);

    /***** HOOKS *****/
    useResizeObserver(element, (entry) => setHeight(entry?.contentRect?.height ?? 0));

    /***** EFFECTS *****/
    useLayoutEffect(() => {
        if (!element.current) {
            throw new Error('Element is not mounted');
        }

        setHeight(element.current.clientHeight);
    }, [element.current]);

    /***** HOOK RESULTS *****/
    return height;
}
/**********************************************************************************************************
 *   HOOK END
 **********************************************************************************************************/

/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { useEffect } from 'react';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { vpsMethods } from 'containers/vps/methods';
import { useSelectableSelector } from 'utilities/hooks/redux/useSelectableSelector';
import { createModuleStore } from 'utilities/methods/createModuleStore';

export const VPSModuleStore = createModuleStore(
    {
        account: {
            overview: {
                enabled: true,
                label: 'Overview',
                to: '/my-services/vps/$vpsId/account',
                hash: 'overview'
            },
            whmLink: {
                enabled: true,
                label: null,
                to: '/my-services/vps/$vpsId/account',
                hash: 'whm-link'
            },
            astroResources: {
                enabled: false,
                label: 'Resource Usage',
                to: '/my-services/vps/$vpsId/account',
                hash: 'resources'
            },
            serverJobs: {
                enabled: false,
                label: 'Server Jobs',
                to: '/my-services/vps/$vpsId/account',
                hash: 'server-jobs'
            },
            upgradePlan: {
                enabled: true,
                label: 'Upgrade Plan',
                to: '/my-services/vps/$vpsId/account',
                hash: 'upgrade-plan'
            },
            renew: {
                enabled: true,
                label: 'Renew',
                to: '/my-services/vps/$vpsId/account',
                hash: 'renew'
            }
        },
        addons: {
            resourceAddons: {
                enabled: true,
                label: 'Resource Addons',
                to: '/my-services/vps/$vpsId/addons',
                hash: 'resource-addons'
            },
            softwareAddons: {
                enabled: true,
                label: 'Software Addons',
                to: '/my-services/vps/$vpsId/addons',
                hash: 'software-licenses'
            }
        },
        manage: {
            powerManagement: {
                enabled: false,
                label: 'Power Management',
                to: '/my-services/vps/$vpsId/manage',
                hash: 'power-management'
            },
            vncAccess: {
                enabled: false,
                label: 'VNC Access',
                to: '/my-services/vps/$vpsId/manage',
                hash: 'vnc-access'
            },
            vncLogin: {
                enabled: false,
                label: 'VNC Login',
                to: '/my-services/vps/$vpsId/manage',
                hash: 'vnc-login'
            },
            reverseDNS: {
                enabled: true,
                label: 'Reverse DNS',
                to: '/my-services/vps/$vpsId/manage',
                hash: 'reverse-dns'
            }
        },
        admin: {
            formatVPS: {
                enabled: false,
                label: 'Format VPS',
                to: '/my-services/vps/$vpsId/admin',
                hash: 'format-vps'
            },
            cancelService: {
                enabled: true,
                label: 'Cancel Service',
                to: '/my-services/vps/$vpsId/admin',
                hash: 'cancel-service'
            }
        }
    },
    VPSModuleStoreConditions
);

function VPSModuleStoreConditions(args: any) {
    const store = createModuleStore.castHookProps<typeof VPSModuleStore>(args);

    /***** HOOKS *****/
    const { isAstro, isSolus, isLegacy, isFullyManaged, isSelfManaged } = useSelectableSelector((state: any) => state.vps.vps_information_data, {
        select: (data) => ({
            isAstro: vpsMethods.getVpsServerType(data) === 'astro',
            isSolus: vpsMethods.getVpsServerType(data) === 'souls',
            isLegacy: data?.attributes?.vps_type === 'legacy',
            isFullyManaged: data?.attributes?.vps_type === 'fully_managed',
            isSelfManaged: data?.attributes?.vps_type === 'self_managed'
        })
    });

    /***** EFFECTS *****/
    useEffect(() => {
        store.batch(() => {
            store.updateEnabledWithReason('account.astroResources', isAstro, "Module is only enabled for Astro VPS's");
            store.updateEnabledWithReason('account.serverJobs', !isLegacy, "Module is not enabled for Legacy VPS's");
            store.updateEnabledWithReason('manage.powerManagement', !isFullyManaged, "Module is not enabled for Fully Managed VPS's");
            store.updateEnabledWithReason(
                'manage.vncAccess',
                !isFullyManaged && isSolus,
                "Module is only enabled for Solus VPS's that are not fully managed"
            );
            store.updateEnabledWithReason(
                'manage.vncLogin',
                !isFullyManaged && isAstro,
                "Module is only enabled for astro VPS's that are not fully managed"
            );
            store.updateEnabledWithReason('admin.formatVPS', isSelfManaged, "Module is only enabled for Self Managed VPS's");
        });
    });
}

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import { TanStackRouterDevtools } from 'components/Router/TanstackRouterDevtools';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type RouterInnerWrapperType = (props: { children: any }) => React.ReactElement;

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const RouterInnerWrapper: RouterInnerWrapperType = ({ children }) => {
    /***** RENDER *****/
    return (
        <>
            <TanStackRouterDevtools />
            {children}
        </>
    );
};

/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import type { QueryClient } from '@tanstack/react-query';
import { createRootRouteWithContext, Navigate, Outlet, redirect, ScrollRestoration, useLocation, useRouter } from '@tanstack/react-router';
import type { Action, Store } from 'redux';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import { CustomErrorFallback } from 'components/Errors/ErrorBoundary';
import PageNotFound from 'components/Errors/PageNotFound';
import { useIsAuthenticated } from 'utilities/hooks/redux/useIsAuthenticated';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import { StaffMenu } from 'components/StaffMenu';
import './_Root.scss';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type RouterContext = {
    /**
     * Tanstack Query client
     */
    queryClient: QueryClient;

    /**
     * Redux store
     */
    store: Store<unknown, Action, any>;
};

/**********************************************************************************************************
 *   ROUTE START
 **********************************************************************************************************/
export const RootRoute = createRootRouteWithContext<RouterContext>()({
    errorComponent: CustomErrorFallback,
    pendingComponent: PendingComponent,
    notFoundComponent: NotFoundComponent,
    component: RootRouteComponent,
    beforeLoad({ location }) {
        if (location.pathname.includes('/staff-login')) {
            throw redirect({ to: '/dashboard' });
        }

        // Handle legacy "referral(s)" hash to ensure historical legacy links are redirected to the correct location.
        // Added: 14/10/2024; consider removing in +1 year.
        if (location.pathname.includes('/account/general/referrals')) {
            throw redirect({
                to: '/account/general',
                hash: 'referral',
                search: (prev) => prev,
                replace: true
            });
        }

        // Handle legacy account emails routes to ensure legacy links are redirected to the correct location.
        // Added: 14/10/2024; consider removing in +1 year.
        if (location.pathname.startsWith('/home/account/emails')) {
            throw redirect({
                to: '/account/emails',
                hash: 'view',
                search: (prev) => prev,
                replace: true
            });
        }
    }
});

/**********************************************************************************************************
 *   ROUTE COMPONENT START
 **********************************************************************************************************/
function RootRouteComponent() {
    return (
        <>
            <ScrollRestoration />
            <StaffMenu />
            <Outlet />
        </>
    );
}

/**********************************************************************************************************
 *   PENDING COMPONENT
 **********************************************************************************************************/
function PendingComponent() {
    /***** HOOKS *****/
    const router = useRouter();

    /***** RENDER *****/
    if (import.meta.env.DEV) {
        // Outer most pending component. If there is any bugs in tanstack that cause infinite pending state, this will help with debugging
        return (
            <>
                Hey developer 👋 Looks like the entire page is pending there... Consider adding a pending component further down the router tree for
                more granular updates
                <button onClick={() => console.warn(router)}>Print router state to console</button>
            </>
        );
    }

    return null;
}

/**********************************************************************************************************
 *   NOT FOUND COMPONENT
 **********************************************************************************************************/
/**
 * Note: If you are here because a route is disabled in the config file and the notfound component showing is propagating to the
 * __root component when it should not, please refer to: {@link https://github.com/TanStack/router/issues/2139}
 */
function NotFoundComponent() {
    /***** HOOKS *****/
    const isAuthenticated = useIsAuthenticated();
    const pathname = useLocation({ select: ({ pathname }) => pathname });

    /***** RENDER *****/
    return (
        <div className='RootNotFound'>
            <PageNotFound />
            {!isAuthenticated && <Navigate to="/login" search={{ ref: pathname }} />}
        </div>
    );
};

/**********************************************************************************************************
 *   IMPORTS
 **********************************************************************************************************/
import { getIncludedObjBasedOnType } from 'utilities/methods/commonActions';

/**********************************************************************************************************
 *   MICROSOFT 365
 **********************************************************************************************************/
export const ms365Methods = {
    getInvoiceId(serviceInformationData) {
        return getIncludedObjBasedOnType(serviceInformationData?.included, 'invoice')?.id;
    },

    prepareConfigs(serviceData) {
        if (!serviceData) return [];

        const included = getIncludedObjBasedOnType(serviceData.included, 'product');

        return serviceData.attributes.configs.map((config) => {
            const correspondingProductConfigOption = included?.attributes.config_options.find((option) => option.id === config.option_id);

            return {
                ...config,
                monthly_price: correspondingProductConfigOption?.attributes.monthly_price || '',
                newQuantity: config.quantity,
                max_quantity: correspondingProductConfigOption?.attributes.max_quantity ?? 25,
                min_quantity: correspondingProductConfigOption?.attributes.min_quantity ?? 1,
                unit_name: correspondingProductConfigOption?.attributes.unit_name || '',
                name: correspondingProductConfigOption?.attributes.name || ''
            };
        });
    },

    getConfigPricing(serviceData) {
        if (!serviceData) return [];

        const included = getIncludedObjBasedOnType(serviceData.included, 'product');

        return serviceData.attributes.configs.map((config) => {
            const correspondingProductConfigOption = included?.attributes.config_options.find((option) => option.id === config.option_id);

            return {
                quantity: config.quantity ?? 0,
                monthly_price: correspondingProductConfigOption?.attributes.monthly_price || '',
                unit_name: correspondingProductConfigOption?.attributes.unit_name || '',
                name: correspondingProductConfigOption?.attributes.name || ''
            };
        });
    },

    /**
     * @template {{ quantity: number }} T
     * @param {T[]} configs
     * @returns {number}
     */
    getTotalSeats(configs) {
        if (!(configs?.length > 0)) return 0;

        return configs.reduce((prev, current) => prev + current.quantity, 0);
    }
};

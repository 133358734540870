/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { NXAxiosInterceptor } from 'store/nxAxios';
import { ARTAHInterceptor } from 'utilities/methods/ARTAHInterceptor';

export function interceptorHandler() {
    // Artah needs to run first because it needs the clean request
    ARTAHInterceptor();
    // this interceptor messes with the response
    NXAxiosInterceptor();
}

/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { Field } from 'redux-form';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import { PasswordChecker } from 'components/PasswordChecker';
import { dedicatedEmailsRequirements, validateDedicatedEmailsRequirements } from 'components/PasswordChecker/axigen';

/*   ACTIONS
 *****************************************************/
import { RenderField, RenderRevealPasswordField, requiredFieldValidation, validateConfirmValidation } from 'utilities/methods/form';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type NameAndPasswordFields = React.FC<NameAndPasswordFieldsProps>;
type NameAndPasswordFieldsProps = {
    passwordInput: string;
};

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
const NameAndPasswordFields: NameAndPasswordFields = ({ passwordInput }) => {
    /***** RENDER *****/
    return (
        <>
            <div className="form__row">
                <div className="form__column half">
                    <Field
                        label="First Name"
                        name="firstname"
                        component={RenderField}
                        type="text"
                        validate={[requiredFieldValidation]}
                        className="form__textfield"
                    />
                </div>
                <div className="form__column half">
                    <Field
                        label="Last Name"
                        name="lastname"
                        component={RenderField}
                        type="text"
                        validate={[requiredFieldValidation]}
                        className="form__textfield"
                    />
                </div>
            </div>
            <div className="form__row">
                <div className="form__column full">
                    <Field
                        label="Password"
                        name="password"
                        component={RenderRevealPasswordField}
                        validate={[requiredFieldValidation, validateDedicatedEmailsRequirements]}
                        className="form__textfield"
                        parent={this}
                    />
                </div>
            </div>
            <div className="form__row">
                <div className="form__column full">
                    <PasswordChecker input={passwordInput} requirements={dedicatedEmailsRequirements} />
                </div>
            </div>
            <div className="form__row">
                <div className="form__column full">
                    <Field
                        label="Confirm Password"
                        name="password_confirmation"
                        component={RenderRevealPasswordField}
                        validate={[requiredFieldValidation, validateConfirmValidation]}
                        className="form__textfield"
                    />
                </div>
            </div>
        </>
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

export default NameAndPasswordFields;

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { recursivelyWalkObjectInReverseToFindKeyByPath } from 'utilities/methods/recursion/recursivelyWalkObjectInReverseToFindKeyByPath/recursivelyWalkObjectInReverseToFindKeyByPath';
import { recursivelyWalkObjectToFindKeyByPath } from 'utilities/methods/recursion/recursivelyWalkObjectToFindKeyByPath/recursivelyWalkObjectToFindKeyByPath';

/**********************************************************************************************************
 *   TYPE IMPORTS
 **********************************************************************************************************/
import type { KatanaFormFieldValidationParams } from 'containers/katana/formFields/methods/validations/types';
import type { KatanaNamespace } from 'containers/katana/types';

export function findValidationObjectByPath({ formValues, property }: KatanaFormFieldValidationParams) {
    return function findObjectByPath(validation: KatanaNamespace.SectionDefinitions.PropertiesModified['validation'][number], validationKey: string) {
        if (!validation.use_raw_path) {
            return recursivelyWalkObjectToFindKeyByPath(formValues, validationKey, property.key);
        }
        return recursivelyWalkObjectInReverseToFindKeyByPath(formValues, validationKey, property.key);
    };
}

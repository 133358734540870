/**********************************************************************************************************
 *   Katana Update Section Order
 **********************************************************************************************************/

import { useMutation } from '@tanstack/react-query';
import { katanaQuery } from 'containers/katana/queries/tanstackTree';
import type { KatanaNamespace } from 'containers/katana/types';
import { KATANA_API } from 'utilities/api/katana';

const mutationKey = ['useReorderSectionsMutation'];
function _useMutation(serviceID: KatanaNamespace.ServiceID, options = {}) {
    async function mutationFn(sectionIDs: KatanaNamespace.SectionID[]) {
        const cancelQueries = [katanaQuery.serviceID.sections.cancelQueries(serviceID), katanaQuery.serviceID.getService.cancelQueries(serviceID)];
        await Promise.allSettled(cancelQueries);

        katanaQuery.serviceID.sections.optimisticSectionSort(serviceID, sectionIDs);
        katanaQuery.serviceID.getService.optimisticSectionSort(serviceID, sectionIDs);
        return KATANA_API.katana.site.service_id.sections.reorder.POST({ serviceID, sectionIDs });
    }

    /***** HOOK RESULTS *****/
    return useMutation({
        mutationFn,
        mutationKey,
        onSuccess: () => {
            katanaQuery.serviceID.sections.invalidateQueries(serviceID);
        },
        ...options
    });
}

/**********************************************************************************************************
 *   HOOK END
 **********************************************************************************************************/

export const reorderSections = Object.freeze({
    useMutation: _useMutation,
    mutationKey
});

import axios from 'axios';

export let CancelPreview;

export const API = {
    /**********************************************************************************************************
     *   AU DIRECT API
     **********************************************************************************************************/
    auDirect: {
        GET: {
            entitledTo: () => axios.get(`/api/domain/au-direct/entitlements`),

            auDirectStatus: (id) => axios.get(`/api/domain/${id}/au-direct`),

            // get lists for manage contested domains page
            contestedDomains: () => axios.get(`/api/domain/au-direct/contention-domains`)
        },

        POST: {
            register: (domains) =>
                axios.post(`/api/domain/au-direct/register`, {
                    domains
                }),

            // gets contention domains for a single .au domain
            contentionDomains: (directAuDomain) =>
                axios.post(`/api/domain/au-direct/contention-domains`, {
                    domain: directAuDomain
                }),

            apply: (payload) => axios.post(`/api/domain/au-direct/submit-application`, payload),

            updateApplication: (domainId, payload) => axios.post(`/api/domain/${domainId}/au-direct/update`, payload)
        }
    }
};

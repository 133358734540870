/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { Tooltip } from '@mui/material';
import React from 'react';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import { PhosphorIcons } from 'components/Icons/Phosphor';
import { Flex } from 'components/Utils/Flex';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import { ViewPortControlActive } from 'components/Lightboxes/BrowserBox/viewPortControlActive';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import './_ViewPortControlButton.scss';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * @type {React.FC<NBrowserBox.ViewportState>}
 */
const BrowserBoxViewPortControls = ({ viewportMode, setViewportMode }) => (
    <Flex className="BrowserBox__viewportControls" gap={2}>
        <Tooltip title="Desktop" arrow placement="top">
            <button
                className="ViewPortControlButton"
                type="button"
                onClick={(e) => {
                    e.stopPropagation();
                    setViewportMode('desktop');
                }}
            >
                <PhosphorIcons.Desktop.DuoTone gray={viewportMode !== 'desktop'} size={32} />
                <ViewPortControlActive isActive={viewportMode === 'desktop'} />
            </button>
        </Tooltip>
        <Tooltip title="Tablet" arrow placement="top">
            <button
                className="ViewPortControlButton"
                type="button"
                onClick={(e) => {
                    e.stopPropagation();
                    setViewportMode('tablet');
                }}
            >
                <PhosphorIcons.DeviceTablet.DuoTone gray={viewportMode !== 'tablet'} size={32} />
                <ViewPortControlActive isActive={viewportMode === 'tablet'} />
            </button>
        </Tooltip>
        <Tooltip title="Mobile" arrow placement="top">
            <button
                className="ViewPortControlButton"
                type="button"
                onClick={(e) => {
                    e.stopPropagation();
                    setViewportMode('mobile');
                }}
            >
                <PhosphorIcons.DeviceMobile.DuoTone gray={viewportMode !== 'mobile'} size={32} />
                <ViewPortControlActive isActive={viewportMode === 'mobile'} />
            </button>
        </Tooltip>
    </Flex>
);
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
export default BrowserBoxViewPortControls;

/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import classNames from 'classnames';
import React from 'react';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import './_NXBoxFooter.scss';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
/**
 * @typedef {object} NXBoxFooterProps
 * @property {React.ReactNode} children The content of the footer
 * @property {string} [className] Additional class names to apply to the footer
 */

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * A fixed height grey Box footer
 * @param {NXBoxFooterProps} props
 */
function NXBoxFooter({ children, className }) {
    const classes = classNames('NXBoxFooter', className);

    /***** RENDER *****/
    return <div className={classes}>{children}</div>;
}

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

export default NXBoxFooter;

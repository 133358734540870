/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { createRoute, Outlet, redirect } from '@tanstack/react-router';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import { SupportSubNav } from './components/subnav';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import RequestLoader from 'components/Loaders/Request';
import Transition from 'components/Transition';

/**********************************************************************************************************
 *   ROUTE IMPORTS
 **********************************************************************************************************/
import { AppRoute } from 'App';
import { routerMiddleware } from 'router/utils/middleware';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import './_support.scss';

/**********************************************************************************************************
 *   ROUTE COMPONENT START
 **********************************************************************************************************/
export const SupportRoute = createRoute({
    getParentRoute: () => AppRoute,
    path: '/support',
    pendingComponent: RequestLoader,
    beforeLoad(opts) {
        routerMiddleware.business(this, opts);
        routerMiddleware.authentication('user', opts);
        if (['/support', '/support/'].includes(opts.location.pathname)) {
            throw redirect({ to: '/support/support-centre' });
        }
    },
    component: SupportRouteComponent
});

/**********************************************************************************************************
 *   ROUTE COMPONENT START
 **********************************************************************************************************/
function SupportRouteComponent() {
    return (
        <div className="support">
            <SupportSubNav />
            <Transition className="support__section">
                <Outlet />
            </Transition>
        </div>
    );
}

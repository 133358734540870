import Actions from 'components/NXTable/Actions';
import Body from 'components/NXTable/Body';
import ErrorMessage from 'components/NXTable/ErrorMessage';
import Footer from 'components/NXTable/Footer';
import Header from 'components/NXTable/Header';
import Row from 'components/NXTable/Row';
import SortableButtonWrapper from 'components/NXTable/SortableButtonWrapper';
import TableAccordion from 'components/NXTable/TableAccordion';
import Table from './Table';

const NXTable = Object.assign(Table, {
    Accordion: TableAccordion,
    Actions,
    Body,
    ErrorMessage,
    Footer,
    Header,
    Row,
    SortableButtonWrapper
});

export default NXTable;

import Text from 'components/Utils/Text';
import { keys } from 'lodash';
import React, { useMemo } from 'react';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
import type { NText } from 'components/Utils/Text/_Text/types';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const _AccordionTitle: React.FC<NText.Props> = ({ children, ...textProps }) => {
    /***** RENDER HELPERS *****/
    const textPropKeysJoined = useMemo(() => keys(textProps).join(' '), [textProps]);
    const finalProps = useMemo(() => {
        return {
            'size--xss': textPropKeysJoined.includes('size--') ? false : true,
            'lead--1': textPropKeysJoined.includes('lead--') ? false : true,
            'uppercase': true,
            'bold': true,
            ...textProps
        };
    }, [textProps]);

    /***** RENDER *****/
    return (
        <Text {...finalProps} className="accordion__title">
            {children}
        </Text>
    );
};

import { useLocation } from '@tanstack/react-router';
import classNames from 'classnames';
import Anchor from 'components/Anchor';
import { PhosphorIcons } from 'components/Icons/Phosphor';
import { useStaffMenu } from 'components/StaffMenu/useStaffMenu';
import { testRoutesStore } from 'components/TestRoutes/const';
import ParentRoute from 'components/TestRoutes/parentRoute';
import Gradient from 'components/Utils/Gradient';
import _, { keys } from 'lodash';
import { useTanstackStore } from 'utilities/hooks/tanstack-store/useTanstackStore';
import './_TestRoutes.scss';
import { useEffect } from 'react';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type TestRouteRecord = Record<string, JSX.Element[]>;

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/

const TestRoutes: React.FC = () => {
    /***** STATE *****/
    const [routes] = useTanstackStore(testRoutesStore);

    /***** HOOKS *****/
    const { pathname } = useLocation();
    const splitPathName = pathname.split('/');
    const { active, toggle } = useStaffMenu();

    /***** EFFECTS *****/
    useEffect(() => {
        if (active) {
            toggle();
        }
    }, [pathname]);

    const availableRoutes: TestRouteRecord = Array.from(routes).reduce<TestRouteRecord>((acc, { title, path, parent = 'spare' }) => {
        if (!Array.isArray(acc[parent])) {
            acc[parent] = [];
        }

        const splitPath = path.split('/').map((path, i) => (path.includes(':') ? splitPathName[i] || path : path));
        const newPath = splitPath.join('/');

        acc[parent].push(<Anchor to={newPath}>{title}</Anchor>);

        return acc;
    }, {});

    const hasAvailableRoutes = !!keys(availableRoutes).length;
    /***** RENDER *****/
    if (!hasAvailableRoutes) return null;
    const { spare, ...rest } = availableRoutes;
    return (
        <Gradient notice className={classNames('TestRoutes', { TestRoutes__open: active })}>
            <button type="button" onClick={toggle}>
                <PhosphorIcons.Link.Bold size={28} white />
            </button>

            {active && (
                <div className="TestRoutes__links">
                    {_.entries(rest).map(([parent, links]) => {
                        return <ParentRoute key={parent} parent={parent} links={links} />;
                    })}
                    {Boolean(spare?.length) && spare}
                </div>
            )}
        </Gradient>
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

export default TestRoutes;

/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React, { useEffect } from 'react';
import store from 'store/store';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import Grid from 'components/Grid';
import Sidebar from 'components/Sidebar';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import SideBarPageWrapper from 'components/SideBarPage/_SideBarPageWrapper';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { useAppViewport } from 'utilities/hooks/useAppViewport/useAppViewport';
import { resetScrollEvents } from 'utilities/methods/commonActions';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import { getSidebarLinks, useGetSidebarLinks } from 'components/SideBarPage/utilities';
import './_SideBarPage.scss';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type TSidebarPage = React.FC<{
    title?: string;
    icon?: string;
    children: React.ReactNode;
    sidebarList: Readonly<Array<MobileSideBar.LinkItem>>;
    className?: string;
}>;

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
const _SideBarPage: TSidebarPage = ({ title, icon, children, sidebarList, className }) => {
    /***** HOOKS *****/
    const isMobile = useAppViewport(['xs', 'sm']);
    const isTablet = useAppViewport('md');

    /***** RENDER HELPERS *****/
    function renderSideBar() {
        return <Sidebar title={title} icon={icon} links={sidebarList} />;
    }

    function getColumns() {
        if (isMobile) {
            return '100%';
        }
        if (isTablet) {
            return '190px minmax(100px, 1fr)';
        }
        return '300px minmax(100px, 1fr)';
    }

    /***** EFFECTS *****/
    useEffect(() => {
        return () => {
            resetScrollEvents()(store.dispatch);
        };
    }, []);

    /***** RENDER *****/
    if (window.ccpIsAppWebview) {
        return <>{children}</>;
    }

    return (
        <SideBarPageWrapper className={className}>
            {/* Render Mobile Size */}
            {isMobile ? renderSideBar() : ''}

            <div className="SideBarPage__content">
                <Grid columns={getColumns()} gap={6} alignItems--start>
                    {isMobile ? '' : renderSideBar()}

                    {children}
                </Grid>
            </div>
        </SideBarPageWrapper>
    );
};

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
const SideBarPage = Object.assign(window.ccpIsAppWebview ? SideBarPageWrapper : _SideBarPage, {
    Wrapper: SideBarPageWrapper,
    getSidebarLinks,
    useGetSidebarLinks
});

export default SideBarPage;

/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { useMutation, useQuery } from '@tanstack/react-query';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { PROMOTIONS } from 'config/containers/promotions/api';
import { MINUTE } from 'utilities/consts';
import { createQueryBoilerPlate } from 'utilities/methods/tanstack';

/**
 * Required service types to pass to promotion all services query
 */
export const megaMayAllServiesQueryRequiredServiceTypes = ['hosting', 'vps'];

/**
 * Retrieve the number of available spins, used spins and spin results for the current user
 */
const megaMayAvailableSpinsBoilerPlate = createQueryBoilerPlate({
    service: 'mega-may',
    serviceKey: 'MEGA_MAY_AVAILABLE_SPINS',
    APIRoute: () => () => PROMOTIONS.GET.megamay.availableSpins(),
    defaultOptions: {
        select: () => (data) => data.data,
        staleTime: MINUTE * 5
    }
});

const {
    getDefaults,
    invalidate: invalidateMegaMayAvailableSpinsQuery,
    setQueryData: setMegaMayAvailableSpinsQueryData,
    fetchQuery: fetchMegaMayAvailableSpinsQuery
} = megaMayAvailableSpinsBoilerPlate;

export function useMegaMayAvaliableSpinsQuery() {
    return useQuery({
        ...getDefaults()
    });
}

/**
 * Get the result for one spin of the wheel
 */
export function useMegaMaySpinWheelMutation() {
    return useMutation({
        mutationFn: PROMOTIONS.POST.megamay.drawPrize,
        onSuccess: () => {
            // Optimistically set the data to what it should be, and then actually fetch it
            setMegaMayAvailableSpinsQueryData(undefined, (oldData) => {
                if (typeof oldData?.data?.remaining_spins !== 'number') {
                    return null;
                }

                const newData = { ...oldData, data: { ...oldData.data, remaining_spins: oldData?.data?.remaining_spins - 1 } };

                return newData;
            });
            fetchMegaMayAvailableSpinsQuery();
        }
    });
}

export { invalidateMegaMayAvailableSpinsQuery };

/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React from 'react';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import './_NXBoxBottom.scss';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * Provides the grey background that often appears at the bottom of Box instances
 * @type {React.FC<{
 *      children: React.ReactNode;
 * }>}
 */
const _NXBoxBottom = ({ children }) => (
    <div className="NXBoxBottom">
        <div className="NXBoxBottom__container">{children}</div>
    </div>
);

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

export default _NXBoxBottom;

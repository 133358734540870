/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { useRef, useState } from 'react';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { activePromotion } from 'utilities/methods/commonActions';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
const promoActionButtonWidth = 85;
const normalActionButtonWidth = 120;

/**
 * Used to control with width of all buttons within the actions column, and the total width of the actions column. Can reduce it to 85px during promo's where there are extra buttons so we need smaller buttons.
 */
export const actionButtonWidth = activePromotion('megamay2024') ? promoActionButtonWidth : normalActionButtonWidth;

/**********************************************************************************************************
 *   HOOK START
 **********************************************************************************************************/
/**
 * @typedef {Object} TableActionsHookProps
 * @property {string} columns
 * @property {string} columnsFinal
 * @property {Function} setColumnsFinal
 * @property {React.MutableRefObject<any>} tableRef
 */
/**
 * Custom Hook that handles table actions and updates the final column definition
 * @param {TableActionsHookProps} props
 * @returns {[
 *   Record<string, Record<string, unknown>>,
 *   React.Dispatch<React.SetStateAction<Record<string, unknown>>>
 * ]} [allActions, setAllActions]
 */
function useTableActions({ columns, setColumnsFinal, columnsFinal, tableRef }) {
    /***** STATE *****/
    const [allActions, setAllActions] = useState({});
    const _columnsFinal = useRef(columnsFinal);

    /***** FUNCTIONS *****/
    /**
     * Calculates the actions width
     * @param {number} count
     * @returns {string}
     */
    function calculateActionsWidth(count) {
        const spacing = 10;

        // "--actionButtonWidth" variable is defined on .ServicePage, so any table that is not a services list table will have the default button width of 120px. Services list tables will have smaller buttons based on "--actionButonWidth" when it's a promo period.
        let thisTableActionButtonWidth = normalActionButtonWidth;
        try {
            const tableRefComputedStyle = window.getComputedStyle(tableRef?.current);
            const actionButtonWidthCssVar = tableRefComputedStyle?.getPropertyValue('--actionButtonWidth') || null;
            const calculatedActionButtonWidth = Number(actionButtonWidthCssVar.replace('px', ''));

            if (typeof calculatedActionButtonWidth === 'number' && !isNaN(calculatedActionButtonWidth)) {
                thisTableActionButtonWidth = calculatedActionButtonWidth;
            }
        } catch (_) {
            // Just catch the error
        }

        return `${count <= 1 ? thisTableActionButtonWidth : thisTableActionButtonWidth * count + spacing * (count - 1)}px`;
    }

    const maxActions = Math.max(...Object.values(allActions), 0);
    const replacedColumns = columns.includes('NXActions') ? columns.replace('NXActions', calculateActionsWidth(maxActions)) : columns;
    if (_columnsFinal.current === columnsFinal && columnsFinal !== replacedColumns) {
        _columnsFinal.current = replacedColumns;
        setColumnsFinal(replacedColumns);
    }

    /***** RETURN *****/
    return [allActions, setAllActions];
}
/**********************************************************************************************************
 *   HOOK END
 **********************************************************************************************************/

export default useTableActions;

/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React from 'react';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import Text from 'components/Utils/Text';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import KatanaServiceListEntryActionButton from 'containers/services/modules/katanaServicesList/katanaServiceListEntryActionButton';
import KatanaServiceListEntryTag from 'containers/services/modules/katanaServicesList/katanaServiceListEntryTag';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 *
 * @type {React.FC<{
 *      katanaSiteData: Artah.Katana.GET._200['data'][number]
 * }>}
 */
const KatanaServiceListEntry = ({ katanaSiteData }) => {
    const { attributes } = katanaSiteData;

    /***** RENDER *****/
    return (
        <>
            <Text.TableContent bold black>
                {attributes?.name}
            </Text.TableContent>
            <KatanaServiceListEntryTag status={attributes?.status} site_status={attributes?.site_status} />
            <KatanaServiceListEntryActionButton katanaSiteData={katanaSiteData} />
        </>
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

export { KatanaServiceListEntry };

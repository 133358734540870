/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import classNames from 'classnames';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import Icons from 'components/Icons';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import './_PromoCodePill.scss';

type PromoCodePillProps = {
    /**
     * The promo code to display in the pill
     */
    code: React.ReactNode;

    /**
     * Function to call when the "X" button is clicked
     */
    removeButtonOnClick?: () => void;

    /**
     * Whether the promo code can be remove or not
     */
    isRemoveable: boolean;
};

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const PromoCodePill: React.FC<PromoCodePillProps> = ({ code, removeButtonOnClick, isRemoveable }) => {
    /***** RENDER *****/
    return (
        <div className={classNames('PromoCodePill', { 'PromoCodePill--removeable': isRemoveable })}>
            <div className="PromoCodePill__text">{code}</div>
            {isRemoveable ? (
                <button className="PromoCodePill__remove" type="button" onClick={() => removeButtonOnClick?.()}>
                    <Icons.Close />
                </button>
            ) : (
                ''
            )}
        </div>
    );
};

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

export default PromoCodePill;

/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import _ from 'lodash';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { typedSetClone } from 'utilities/methods/objectMethods';

/**********************************************************************************************************
 *   TYPE IMPORTS
 **********************************************************************************************************/
import type { Store } from '@tanstack/react-store';
import type { NXUtils } from 'utilities/NXUtils';

/**
 * Higher order function that returns a function that updates a store key if the value is different. This is useful for avoiding updating
 * the store if the value is the same.
 *
 * Note: This function specifically relates to a tanstack store, this function will not function correctly if used with another type of store.
 * If you are using this to update a key in an object, the typedSet function is exported for this purpose.
 */
export const createUpdateChangedStoreKey = <TState extends object>(store: Store<TState, (state: NoInfer<TState>) => NoInfer<TState>>) => {
    /**
     * Will update the store key if the value is different. This function does nothing if there is no change in value
     */
    return <K extends NXUtils.Path<TState>>(key: K, value: NXUtils.Choose<TState, K>) => {
        if (_.get(store.state, key) === value) {
            return;
        }

        store.setState((state) => typedSetClone(state, key, value));
    };
};

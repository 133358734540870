/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { createRoute } from '@tanstack/react-router';
import { ventraSynMigrationBanners } from 'config/config';
import { useRef } from 'react';
import { useSelector } from 'react-redux';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import Invoices from 'containers/billing/modules/invoices';
import VentraSynBanner from 'containers/ventraSynMigration/modules/banner';
import { Account } from './Components/account';
import Tickets from './Components/etickets';
import { ExpressServiceManager } from './Components/expressServiceManager';
import OverdueInvoices from './Components/overdueInvoices';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import FetchPageError from 'components/Errors/FetchPageError';
import RequestLoader from 'components/Loaders/Request';
import { Page } from 'components/Page';

/**********************************************************************************************************
 *   ROUTE IMPORTS
 **********************************************************************************************************/
import { AppRoute } from 'App';
import { routerMiddleware } from 'router/utils/middleware';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import './_dashboard.scss';

/*   ACTIONS
 *****************************************************/
import { checkRequiresMigration } from 'containers/ventraSynMigration/action';

/***** MEGAMAY24 START *****/
/***** MEGAMAY24 END *****/

/**********************************************************************************************************
 *   ROUTE START
 **********************************************************************************************************/
export const DashboardRoute = createRoute({
    getParentRoute: () => AppRoute,
    path: 'dashboard',
    loader({ cause, context }) {
        if (cause === 'enter' && ventraSynMigrationBanners) {
            checkRequiresMigration()(context.store.dispatch);
        }
    },
    beforeLoad(opts) {
        routerMiddleware.business(this, opts);
        routerMiddleware.authentication('user', opts);
    },
    component: Dashboard
});

/**********************************************************************************************************
 *   ROUTE COMPONENT START
 **********************************************************************************************************/
function Dashboard() {
    /***** SELECTORS *****/
    const app_user_status = useSelector((state: any) => state.app.app_user_status);

    /***** HOOKS *****/
    const promoDealsRef = useRef();

    /***** RENDER *****/
    switch (app_user_status) {
        case 'error':
            return <FetchPageError />;

        case 'success':
            return (
                <Page className="dashboard">
                    <VentraSynBanner />
                    <Account promoDealsRef={promoDealsRef} />
                    <OverdueInvoices />
                    <ExpressServiceManager />
                    <Tickets />
                    <Invoices />
                </Page>
            );

        case 'loading':
        default:
            return <RequestLoader />;
    }
}

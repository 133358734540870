/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React, { Component } from 'react';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import SolidButton from 'components/Buttons/SolidButton';
import RequestLoader from 'components/Loaders/Request';
import OverlayLightbox from '..';

/**********************************************************************************************************
 *   OVERLAY INVOICE COMPONENT START
 **********************************************************************************************************/
class OverlayConfirm extends Component {
    /*   RENDER COMPONENT
     **********************************************************************************************************/
    render() {
        const { confirm, color } = this.props;

        switch (confirm.loading) {
            case 'loading':
            case 'pending':
                return (
                    <div className="OverlayInvoice">
                        <RequestLoader />
                    </div>
                );

            default:
                return (
                    <div className="OverlayConfirm__container">
                        <div className="OverlayConfirm__description inline">{confirm.desc}</div>
                        <SolidButton
                            color={color ? color : 'primary'}
                            className="OverlayConfirm__button"
                            type="onClick"
                            onClick={confirm.buttonAction}
                        >
                            {confirm.buttonText}
                        </SolidButton>
                        {confirm.closeAction && typeof confirm.closeText === 'string' ? (
                            <OverlayLightbox.BackLink onClick={confirm.closeAction} text={confirm.closeText.toUpperCase()} />
                        ) : (
                            ''
                        )}
                    </div>
                );
        }
    }
}

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
export default OverlayConfirm;

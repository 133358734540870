/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { ErrorBoundary } from '@sentry/react';
import classNames from 'classnames';
import React, { useRef, useState } from 'react';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import FetchComponentError from 'components/Errors/FetchComponentError';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import useTableActions from './hooks/useTableActions';
import { checkContextOverrideProps } from './methods';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import { NXTableContext } from './NXTableContext';
import './_NXTable.scss';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/

/**
 * The main table wrapper
 * @param {{
 *      columns: string;
 *      onSort?: (sort: NXTableSortValue)=> void;
 *      children: import('react').ReactNode | import('react').ReactNode[];
 *      className?: string;
 * }} props
 */
const Table = ({ children, columns, onSort, className }) => {
    /***** HOOKS *****/
    const tableRef = useRef(null);

    /***** STATE *****/
    const [currentSort, setCurrentSort] = useState({ sortKey: null, sortMethod: false });
    const [columnsFinal, setColumnsFinal] = useState(columns);
    const [allActions, setAllActions] = useTableActions({ columns, setColumnsFinal, columnsFinal, tableRef });

    /***** FUNCTIONS *****/
    /**
     * Function that you need to call when you are performing sort so that the table context can keep record of what is actively being sorted
     * @param {NXTableSortValue} sort
     */
    function _onSort(sort) {
        setCurrentSort(sort);
        onSort?.(sort);
    }

    /***** RENDER HELPERS *****/
    /**
     * The context data that get's passed down the context object
     */
    const context = {
        columnsDefinition: columns,
        columns: columnsFinal,
        _onSort,
        _currentSort: currentSort,
        allActions,
        setAllActions,
        setColumns: setColumnsFinal
    };

    // Override child props
    const overriddenChildren = checkContextOverrideProps({ children, context });

    /***** RENDER *****/
    return (
        <div ref={tableRef} className={classNames('NXTable', className)}>
            <ErrorBoundary fallback={<FetchComponentError />}>
                <NXTableContext.Provider value={context}>{overriddenChildren}</NXTableContext.Provider>
            </ErrorBoundary>
        </div>
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

export default Table;

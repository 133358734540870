/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React from 'react';
import { useDispatch } from 'react-redux';
import { useVPSTableSelector } from '../own';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import SelectDropdown from 'components/Dropdowns/SelectDropdown';
import Search from 'components/Search';
import Text from 'components/Utils/Text';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import { resetVPSSearch, vpsSearch } from 'containers/services/action';
import { useVPSOverviewContext } from '../own';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { generateCreateShowDropdownOption } from 'containers/services/modules/helpers';
import { cancelSearchVPS } from 'utilities/api/services';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
/**
 * @typedef {import('../types').TVPSOverview.TVPSTableTitleBar} TVPSTableTitleBar
 */

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * Title bar component for the VPS table.
 *
 * @private
 * @type {TVPSTableTitleBar}
 */
export const VPSTableTitleBar = ({ title }) => {
    /***** HOOKS *****/
    const { vps_list_meta, vps_list_status } = useVPSTableSelector((state) => state.services);
    const { setNumberOfRows, setSearch } = useVPSOverviewContext();
    const dispatch = useDispatch();

    /***** FUNCTIONS *****/
    const createShowDropdownOption = generateCreateShowDropdownOption(vps_list_meta?.total, setNumberOfRows);

    /***** RENDER *****/
    return (
        <div className="VPSTableHeader">
            {/* Title */}
            <Text primary size--xl semiBold>
                {title}
            </Text>

            {/* Filters */}
            <div className="VPSTableHeader__filters">
                <Search
                    className="VPSTableHeader__search"
                    functions={{
                        cancel: cancelSearchVPS,
                        search: (search) => vpsSearch(search)(dispatch),
                        reset: () => {
                            resetVPSSearch()(dispatch);
                            setSearch();
                        }
                    }}
                    render={{ placeholder: 'Search for a virtual private server' }}
                    helpers={{ keyword: setSearch }}
                />
                <div className="VPSTableHeader__actions">
                    <SelectDropdown
                        options={[
                            createShowDropdownOption('10'),
                            createShowDropdownOption('20'),
                            createShowDropdownOption('50'),
                            createShowDropdownOption('All')
                        ]}
                        noSelectionLabel="Show"
                        disabled={vps_list_status === 'loading'}
                    />
                </div>
            </div>
        </div>
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

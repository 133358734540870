import { domainQuery } from 'containers/domain/queries/queryTree';
import { useApplyDNSPresetMutation } from 'containers/domain/queries/useApplyDNSPresetMutation';
import {
    katanaTrialStateSimplifiedLaunch,
    simplifiedFlowLoadingMessage
} from 'containers/katana/containers/ContentEditorLightbox/formHandlers/consts';
import { createPresetPreviewData } from 'containers/katana/containers/ContentEditorLightbox/formHandlers/launchFormHandler/createPresetPreviewData';
import { useKatanaNextPage } from 'containers/katana/hooks/useKatanaNextPage';
import { useKatanaParams } from 'containers/katana/hooks/useSetupEditorRouteParams';
import { useEnableKatanaSiteMutation } from 'containers/katana/queries/mutations';
import { katanaQuery } from 'containers/katana/queries/tanstackTree';
import type { KatanaNamespace } from 'containers/katana/types';
import { useMemo } from 'react';
import { API } from 'utilities/api/domain';

/**********************************************************************************************************
 *   HOOK START
 **********************************************************************************************************/
export const useHandleLaunchSubmit = (subpageRouteData: KatanaNamespace.SubPageRouteData) => {
    /***** HOOKS *****/
    const { katanaServiceId, page } = useKatanaParams();
    const { performRouting } = useKatanaNextPage(subpageRouteData);
    /***** QUERIES *****/
    const { data: check_enable_site_data } = katanaQuery.serviceID.domainServiceDetails.useQuery(katanaServiceId);
    const { data: get_dns_data } = katanaQuery.serviceID.dns.useQuery(katanaServiceId);
    const { mutateAsync: mutateAsyncEnableKatanaSite } = useEnableKatanaSiteMutation(katanaServiceId);
    const { mutateAsync: mutatePutDomainDNSType } = domainQuery.domainID.configureDNSMode.useMutation();

    const applyDNSPresetMutationOptions = useMemo(() => {
        return page === 'setup' ? { onSuccess: null } : {};
    }, [page]);
    const presetPreviewData = check_enable_site_data ? createPresetPreviewData(check_enable_site_data) : {};

    const { mutateAsync: mutateAsyncApplyDNSPreset } = useApplyDNSPresetMutation(presetPreviewData, applyDNSPresetMutationOptions);

    const handleSimplifiedLaunch = async () => {
        if (check_enable_site_data?.domain_id) {
            simplifiedFlowLoadingMessage.setState(() => 'Changing DNS Settings...');
            await mutatePutDomainDNSType({
                id: check_enable_site_data.domain_id,
                attributes: { dns_type: 'free_dns' }
            });
            simplifiedFlowLoadingMessage.setState(() => 'Getting updated DNS info...');
            const serviceDetailsData = await katanaQuery.serviceID.domainServiceDetails.fetchQuery(katanaServiceId);
            if (serviceDetailsData.status !== 200) {
                simplifiedFlowLoadingMessage.setState(() => '');
                return;
            }
            simplifiedFlowLoadingMessage.setState(() => 'Applying DNS Presets...');
            await API.domain.POST.presetApply(createPresetPreviewData(serviceDetailsData.data));
        }

        simplifiedFlowLoadingMessage.setState(() => '🚀 Launching...');
        await mutateAsyncEnableKatanaSite({ finalize: true });
        katanaTrialStateSimplifiedLaunch.setState(() => false);
    };

    const handleRegularLaunch = async () => {
        if (!get_dns_data?.is_dns_configured && check_enable_site_data?.domain_id) {
            await mutateAsyncApplyDNSPreset();
        }
        await mutateAsyncEnableKatanaSite({ finalize: true });
    };

    return async function handleSubmit() {
        if (katanaTrialStateSimplifiedLaunch.state) {
            await handleSimplifiedLaunch();
        } else {
            try {
                await handleRegularLaunch();
            } catch (e) {
                return;
            }
        }

        simplifiedFlowLoadingMessage.setState(() => '');

        performRouting();
    };
};

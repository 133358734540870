/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { Store } from '@tanstack/react-store';
import type { PictureInPicture as PictureInPictureNamespace } from 'components/PictureInPicture/types';
import { createContext } from 'react';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/

const possibleStates = ['hidden', 'visible', 'minimised', 'maximised'];

export function createPictureInPictureState<TIdentifier extends string>(
    defaultState: PictureInPictureNamespace.States = 'hidden',
    identifier?: TIdentifier
): TIdentifier extends string ? PictureInPictureNamespace.CreateStateData : PictureInPictureNamespace.CreateStateDataStore {
    const store = new Store(defaultState);

    if (identifier) {
        const UUID = `storable__${identifier}`;
        const commit: PictureInPictureNamespace.CommitStateData = (value) => {
            localStorage.setItem(UUID, JSON.stringify(value));
        };

        const retrieve: PictureInPictureNamespace.RetrieveStateData = () => {
            const storedValue = localStorage.getItem(UUID);
            if (storedValue === null) {
                return null;
            }
            const value: PictureInPictureNamespace.States = JSON.parse(storedValue);
            return possibleStates.includes(value) ? value : null;
        };

        return { store, commit, retrieve } as any;
    }

    return { store } as any;
}

export const PictureInPictureContext = createContext<PictureInPictureNamespace.ContextData>({});

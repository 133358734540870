/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React from 'react';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import HoverTooltip from 'components/Tooltip/HoverTooltip';
import Text from 'components/Utils/Text';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { formatLuxonOrdinal, getCurrentDate, toLuxonDate } from 'utilities/methods/commonActions';
import { getInformationFromData } from './helpers';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
/**
 * @typedef {import('../../types').NSSLTable.TSSLExpiry} TSSLExpiry
 */

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * @private - For use in the _TableBody component.
 * @type {TSSLExpiry}
 */
export const OwnRenderExpiryInfo = ({ data }) => {
    /***** RENDER HELPERS *****/
    const { invoice, status, valid_until } = getInformationFromData(data);

    const expiryDateLuxon = toLuxonDate(valid_until, 'yyyy-MM-dd');
    const expiry = parseInt(String(getCurrentDate().diff(expiryDateLuxon, 'days').toObject()['days']));
    const expiryFormat = formatLuxonOrdinal(expiryDateLuxon.toFormat('EEEE, MMM {d} yyyy'));

    /***** RENDER *****/
    switch (true) {
        case invoice?.attributes.status === 'unpaid':
            return (
                <Text secondary size--xss>
                    Payment Required
                </Text>
            );
        case status === 'pending validation':
            return (
                <Text secondary size--xss>
                    Validation Required
                </Text>
            );
        case status === 'expired':
            return (
                <Text warn size--xss bold>
                    Expired
                </Text>
            );
        case !valid_until:
            return <div></div>;
        case expiry !== 0 && !expiry:
            return (
                <Text secondary size--xss>
                    Configuration Required
                </Text>
            );
        case expiry === -1:
            return (
                <HoverTooltip content={expiryFormat} className="SSLOverviewBody__tooltip">
                    <Text warn size--xss bold>
                        Expires in 1 day
                    </Text>
                </HoverTooltip>
            );
        case expiry === 0:
            return (
                <HoverTooltip content={expiryFormat} className="SSLOverviewBody__tooltip">
                    <Text warn size--xss bold>
                        Expires Today
                    </Text>
                </HoverTooltip>
            );
        default: {
            const isWarn = expiry > -90 && expiry < 0;
            const isOne = Math.round(expiry).toString().substring(1) === '1';
            return (
                <HoverTooltip content={expiryFormat} className="SSLOverviewBody__tooltip">
                    <Text warn={isWarn} secondary={!isWarn} size--xss bold={isWarn}>
                        Expires in {Math.round(expiry).toString().substring(1)} day{isOne ? '' : 's'}
                    </Text>
                </HoverTooltip>
            );
        }
    }
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { DateTime } from 'luxon';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import { orderedTiers } from 'containers/vipRewards/consts';

/**
 * Gets a list the tiers with their corresponding account age requirements in days
 */
export function getTiersWithRequiredAccountAgeDays(rewardsTiers) {
    const tiers = orderedTiers
        .map((tierName) => {
            const requiredAccountAgeInYears = rewardsTiers?.[tierName]?.account_age;

            if (typeof requiredAccountAgeInYears !== 'number') {
                return null;
            }

            // work out how many leap years have occurred in last X years
            const xYearsAgo = DateTime.now().minus({ years: requiredAccountAgeInYears });
            const days = Math.ceil(Math.abs(xYearsAgo.diffNow(['days']).days));

            return {
                name: tierName,
                days
            };
        })
        .filter((tier) => tier);

    return tiers;
}

/**
 * Checks if a given tier is above the current tier of the logged in account
 */
export function isTierLocked(timelineTiers, currentTier, tierToCheck) {
    const currentTierIndex = timelineTiers.findIndex(({ name }) => currentTier === name);
    const tierToCheckIndex = timelineTiers.findIndex(({ name }) => tierToCheck === name);

    return tierToCheckIndex > currentTierIndex;
}

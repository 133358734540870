/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { cloneDeep, keys } from 'lodash';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { transformAttachmentDataToAttachment, transformAttachmentDataToLink } from 'containers/katana/queries/methods/attachmentData';
import { findSectionDefinitionPropertyByPath } from 'containers/katana/queries/methods/findSectionDefinitionPropertyByPath';
import { isImageAttachmentIDFromValue } from 'containers/katana/queries/methods/isImageAttachmentIDFromValue';

/**
 * @param {{
 *      sectionDefinitionData: KatanaAPI.Katana.Site.ServiceID.Meta.Sections.GET.SectionData;
 *      properties: Record<string, any>;
 *      path?: Array<string>;
 * }} params
 */
export function recursivelyTransformPropertyAttachmentData({ sectionDefinitionData, properties, path = [] }) {
    if (!sectionDefinitionData || typeof properties !== 'object') {
        return properties;
    }

    const clonedProperties = cloneDeep(properties);
    keys(clonedProperties).forEach((key) => {
        const property = clonedProperties[key];
        if (typeof property !== 'object') {
            return;
        }

        // for each entry in the sectionDefinitionData, check if the path matches the property key, if so, check if the type is the same as `'file'`
        const propertyDefinition = findSectionDefinitionPropertyByPath({ sectionDefinitionData, path: [...path, key] });
        if (propertyDefinition?.type === 'file') {
            if (isImageAttachmentIDFromValue(property)) {
                clonedProperties[key] = transformAttachmentDataToAttachment(property);
            } else {
                clonedProperties[key] = transformAttachmentDataToLink(property);
            }
            return;
        }

        if (Array.isArray(property)) {
            const mappedProperty = property.map((subProperty, i) => {
                return recursivelyTransformPropertyAttachmentData({
                    sectionDefinitionData,
                    properties: subProperty,
                    path: [...path, key]
                });
            });

            clonedProperties[key] = mappedProperty;
            return;
        }

        if (typeof property === 'object') {
            clonedProperties[key] = recursivelyTransformPropertyAttachmentData({
                sectionDefinitionData,
                properties: property,
                path: [...path, key]
            });
        }
    });

    return clonedProperties;
}

/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import React from 'react';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import './_ToolBarToolTip.scss';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
interface ToolBarToolTipProps {
    ariaLabel: string;
    children: React.ReactNode;
    className?: string;
}

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const ToolBarToolTip: React.FC<ToolBarToolTipProps> = ({ ariaLabel, children, className }) => (
    <Tooltip
        title={ariaLabel}
        placement="top"
        slotProps={{
            popper: {
                className: 'ToolBarToolTip__Popper',
                sx: {
                    [`&.${tooltipClasses.popper}[data-popper-placement*="top"] .${tooltipClasses.tooltip}`]: {
                        marginBottom: '3px'
                    }
                }
            }
        }}
    >
        <span className={className}>{children}</span>
    </Tooltip>
);
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { useState } from 'react';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type UseServiceLightboxEffect = (id: number | null) => void;

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * Provides a simple abstraction on useState for managing the state of a lightbox. This includes
 * the state ({ show, id }) and a function to set the id and show/hide the lightbox.
 *
 * Providing id as null will set the show property to false, effectively hiding the lightbox while
 * setting id to a number set the show value to true and update the ID.
 *
 * A sideEffect callback can also be provided as the first parameter which will run after the state is updated.
 * This is useful for triggering some action when the lightbox is closed or open (ie. fetching data when changing the lightbox state)
 */
export const useServiceLightboxState = (sideEffect?: UseServiceLightboxEffect) => {
    /***** STATE *****/
    const [serviceLightbox, _setServiceLightbox] = useState<{ show: boolean; id: null | number }>({
        show: false,
        id: null
    });

    /***** FUNCTIONS *****/
    /**
     * @param id - invoice ID to open lightbox or null to close
     */
    const setServiceLightbox = (id: number | null = null) => {
        _setServiceLightbox({
            show: !!id,
            id: id
        });
        sideEffect?.(id);
    };

    /***** RENDER *****/
    return [serviceLightbox, setServiceLightbox] as const;
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

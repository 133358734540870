/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import { ValidationMessage } from 'components/Form/ValidationMessage';
import { WrapperBox } from 'components/Form/WrapperBox/WrapperBox';
import Grid from 'components/Grid';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import { EntryCreationBehaviourHandlerWrapper } from 'containers/katana/components/EntryCreationBehaviourHandler/wrapper';
import { SortableRepeatedWrapper } from 'containers/katana/formFields/repeated/SortableRepeatedWrapper/SortableRepeatedWrapper';
import { _InlineListFieldArrayChild as InlineListFieldArrayChild } from 'containers/katana/formFields/repeated/inputTypes/inline_list/_InlineListFieldArrayChild';
import { useFieldArrayMetaOverrideOnSubmitButtonClick } from 'containers/katana/formFields/repeated/methods/useFieldArrayMetaOverrideOnSubmitButtonClick';

/**********************************************************************************************************
 *   TYPE IMPORTS
 **********************************************************************************************************/
import type { RepeatedFieldArrayRenderer } from 'containers/katana/formFields/repeated/types';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const _InlineListFieldArrayRenderer: RepeatedFieldArrayRenderer.Component = (props) => {
    const { fields, properties, property, meta } = props;

    const metaOverride = useFieldArrayMetaOverrideOnSubmitButtonClick(meta);
    const sortable = true;

    /***** RENDER *****/
    return (
        <WrapperBox error={metaOverride.error && !metaOverride.pristine} fullWidth>
            <Grid columns="100%" className="InlineFieldArrayRenderer" justify-children--stretch alignItems--end>
                <EntryCreationBehaviourHandlerWrapper {...{ property, properties, fields }}>
                    {sortable ? (
                        <SortableRepeatedWrapper fields={fields} property={property}>
                            {({ filteredResults }) => {
                                return filteredResults.map(({ member, index, fields, _sortableID }) => {
                                    return (
                                        <InlineListFieldArrayChild
                                            key={_sortableID}
                                            {...{ member, index, fields, properties, property }}
                                            sortable={sortable}
                                        />
                                    );
                                });
                            }}
                        </SortableRepeatedWrapper>
                    ) : (
                        fields.map((member, index, fields) => {
                            return <InlineListFieldArrayChild key={index} {...{ member, index, fields, properties, property }} sortable={sortable} />;
                        })
                    )}
                </EntryCreationBehaviourHandlerWrapper>
            </Grid>
            <ValidationMessage.ReduxForm.FieldArray {...metaOverride} />
        </WrapperBox>
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

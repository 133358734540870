/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React from 'react';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import OutlineButton from 'components/Buttons/OutlineButton';
import SolidButton from 'components/Buttons/SolidButton';
import { Modal } from 'components/Lightboxes/Modal';
import { Flex } from 'components/Utils/Flex';
import Padding from 'components/Utils/Padding';
import Text from 'components/Utils/Text';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { katanaServicePages } from 'containers/katana/consts';
import useKatanaURL from 'containers/katana/hooks/useKatanaURL';
import { useKatanaParams } from 'containers/katana/hooks/useSetupEditorRouteParams';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/

/**
 * @type {React.FC<{
 *      open: boolean;
 *      setOpen: React.Dispatch<React.SetStateAction<boolean>>;
 *      onConfirm: () => void;
 * }>}
 */
const DeleteSectionConfirmationLightbox = ({ open, setOpen, onConfirm }) => {
    /***** HOOKS *****/
    const { katanaServiceId } = useKatanaParams();
    const { getKatanaDestination } = useKatanaURL(katanaServiceId);

    /***** FUNCTIONS *****/
    function close() {
        setOpen(false);
    }

    /***** RENDER *****/
    return (
        <Modal.Compact isOpen={open} onClose={close} ariaLabel="Unsaved Changes Modal">
            <Text.Heading h2 align--center>
                Delete section
            </Text.Heading>
            <Padding y={4}>
                <Text size--m secondary align--center>
                    Are you sure you want to delete this section?
                </Text>
            </Padding>
            <Flex justify="center">
                <OutlineButton type="onClick" onClick={close}>
                    Cancel
                </OutlineButton>
                <SolidButton color="warn" to={getKatanaDestination(katanaServicePages.OVERVIEW.BASIC_DETAILS)} onClick={onConfirm}>
                    Delete
                </SolidButton>
            </Flex>
        </Modal.Compact>
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

export { DeleteSectionConfirmationLightbox };


import {
    VSM_INITIAL_STATE,
    VSM_START_OVER,
    VSM_REQUIRES_MIGRATION_REQUEST,
    VSM_REQUIRES_MIGRATION_SUCCESS,
    VSM_REQUIRES_MIGRATION_ERROR,
    VSM_RESELLER_LIST_REQUEST,
    VSM_RESELLER_LIST_SUCCESS,
    VSM_RESELLER_LIST_ERROR,
    VSM_SUBACCOUNT_LIST_REQUEST,
    VSM_SUBACCOUNT_LIST_SUCCESS,
    VSM_SUBACCOUNT_LIST_ERROR,
    VSM_PREVIEW_REQUEST,
    VSM_PREVIEW_SUCCESS,
    VSM_PREVIEW_ERROR,
    VSM_MIGRATE_REQUEST,
    VSM_MIGRATE_SUCCESS,
    VSM_MIGRATE_ERROR,
    VSM_SYNERGY_LOGIN_REQUEST,
    VSM_SYNERGY_LOGIN_SUCCESS,
    VSM_SYNERGY_LOGIN_ERROR,
    VSM_SYNERGY_REGISTER_REQUEST,
    VSM_SYNERGY_REGISTER_SUCCESS,
    VSM_SYNERGY_REGISTER_ERROR,
    VSM_PCI_CSV_REQUEST,
    VSM_PCI_CSV_SUCCESS,
    VSM_PCI_CSV_ERROR,
    VSM_MIGRATION_DATES_REQUEST,
    VSM_MIGRATION_DATES_SUCCESS,
    VSM_MIGRATION_DATES_ERROR
} from './action';

const initialState = {
    vsm_requires_migration_status: null,
    vsm_requires_migration_data: null,
    vsm_requires_migration_error: null,
    vsm_reseller_list_status: null,
    vsm_reseller_list_data: null,
    vsm_reseller_list_error: null,
    vsm_subaccount_list_status: null,
    vsm_subaccount_list_data: null,
    vsm_subaccount_list_error: null,
    vsm_preview_status: null,
    vsm_preview_data: null,
    vsm_preview_error: null,
    vsm_migrate_status: null,
    vsm_migrate_data: null,
    vsm_migrate_error: null,
    vsm_synergy_login_status: null,
    vsm_synergy_login_data: null,
    vsm_synergy_login_error: null,
    vsm_synergy_register_status: null,
    vsm_synergy_register_data: null,
    vsm_synergy_register_error: null,
    vsm_pci_csv_status: null,
    vsm_pci_csv_data: null,
    vsm_pci_csv_error: null,
    vsm_migration_dates_status: null,
    vsm_migration_dates_data: null
};

const vsmReducer = (state = initialState, action) => {
    switch (action.type) {
        case VSM_INITIAL_STATE:
            return {
                ...initialState
            };

        // Keep requires migration data so that the form still knows if they have a linked account or not
        case VSM_START_OVER:
            return {
                ...initialState,
                vsm_requires_migration_status: state.vsm_requires_migration_status,
                vsm_requires_migration_data: state.vsm_requires_migration_data,
                vsm_requires_migration_error: state.vsm_requires_migration_error
            };

        /**********************************************************************************************************
         *   VSM REQUIRES MIGRATION
         **********************************************************************************************************/
        case VSM_REQUIRES_MIGRATION_REQUEST:
            return {
                ...state,
                vsm_requires_migration_status: 'loading',
                vsm_requires_migration_data: null,
                vsm_requires_migration_error: null
            };

        case VSM_REQUIRES_MIGRATION_SUCCESS:
            return {
                ...state,
                vsm_requires_migration_status: 'success',
                vsm_requires_migration_data: action.vsm_requires_migration_data
            };

        case VSM_REQUIRES_MIGRATION_ERROR:
            return {
                ...state,
                vsm_requires_migration_status: 'error',
                vsm_requires_migration_error: action.vsm_requires_migration_error
            };

        /**********************************************************************************************************
         *   VSM RESELLER LIST
         **********************************************************************************************************/
        case VSM_RESELLER_LIST_REQUEST:
            return {
                ...state,
                vsm_reseller_list_status: 'loading',
                vsm_reseller_list_data: null,
                vsm_reseller_list_error: null
            };

        case VSM_RESELLER_LIST_SUCCESS:
            return {
                ...state,
                vsm_reseller_list_status: 'success',
                vsm_reseller_list_data: action.vsm_reseller_list_data
            };

        case VSM_RESELLER_LIST_ERROR:
            return {
                ...state,
                vsm_reseller_list_status: 'error',
                vsm_reseller_list_error: action.vsm_reseller_list_error
            };

        /**********************************************************************************************************
         *   VSM SUBACCOUNT LIST
         **********************************************************************************************************/
        case VSM_SUBACCOUNT_LIST_REQUEST:
            return {
                ...state,
                vsm_subaccount_list_status: 'loading',
                vsm_subaccount_list_data: null,
                vsm_subaccount_list_error: null
            };

        case VSM_SUBACCOUNT_LIST_SUCCESS:
            return {
                ...state,
                vsm_subaccount_list_status: 'success',
                vsm_subaccount_list_data: action.vsm_subaccount_list_data
            };

        case VSM_SUBACCOUNT_LIST_ERROR:
            return {
                ...state,
                vsm_subaccount_list_status: 'error',
                vsm_subaccount_list_error: action.vsm_subaccount_list_error
            };

        /**********************************************************************************************************
         *   VSM PREVIEW
         **********************************************************************************************************/
        case VSM_PREVIEW_REQUEST:
            return {
                ...state,
                vsm_preview_status: 'loading',
                vsm_preview_data: null,
                vsm_preview_error: null
            };

        case VSM_PREVIEW_SUCCESS:
            return {
                ...state,
                vsm_preview_status: 'success',
                vsm_preview_data: action.vsm_preview_data
            };

        case VSM_PREVIEW_ERROR:
            return {
                ...state,
                vsm_preview_status: 'error',
                vsm_preview_error: action.vsm_preview_error
            };

        /**********************************************************************************************************
         *   VSM MIGRATE
         **********************************************************************************************************/
        case VSM_MIGRATE_REQUEST:
            return {
                ...state,
                vsm_migrate_status: 'loading',
                vsm_migrate_data: null,
                vsm_migrate_error: null
            };

        case VSM_MIGRATE_SUCCESS:
            return {
                ...state,
                vsm_migrate_status: 'success',
                vsm_migrate_data: action.vsm_migrate_data
            };

        case VSM_MIGRATE_ERROR:
            return {
                ...state,
                vsm_migrate_status: 'error',
                vsm_migrate_error: action.vsm_migrate_error
            };

        /**********************************************************************************************************
         *   VSM SYNERGY LOGIN
         **********************************************************************************************************/
        case VSM_SYNERGY_LOGIN_REQUEST:
            return {
                ...state,
                vsm_synergy_login_status: 'loading',
                vsm_synergy_login_data: null,
                vsm_synergy_login_error: null
            };

        case VSM_SYNERGY_LOGIN_SUCCESS:
            return {
                ...state,
                vsm_synergy_login_status: 'success',
                vsm_synergy_login_data: action.vsm_synergy_login_data
            };

        case VSM_SYNERGY_LOGIN_ERROR:
            return {
                ...state,
                vsm_synergy_login_status: 'error',
                vsm_synergy_login_error: action.vsm_synergy_login_error
            };

        /**********************************************************************************************************
         *   VSM SYNERGY REGISTER
         **********************************************************************************************************/
        case VSM_SYNERGY_REGISTER_REQUEST:
            return {
                ...state,
                vsm_synergy_register_status: 'loading',
                vsm_synergy_register_data: null,
                vsm_synergy_register_error: null
            };

        case VSM_SYNERGY_REGISTER_SUCCESS:
            return {
                ...state,
                vsm_synergy_register_status: 'success',
                vsm_synergy_register_data: action.vsm_synergy_register_data
            };

        case VSM_SYNERGY_REGISTER_ERROR:
            return {
                ...state,
                vsm_synergy_register_status: 'error',
                vsm_synergy_register_error: action.vsm_synergy_register_error
            };

        /**********************************************************************************************************
         *   VSM DOWNLOAD PCI CSV
         **********************************************************************************************************/
        case VSM_PCI_CSV_REQUEST:
            return {
                ...state,
                vsm_pci_csv_status: 'loading',
                vsm_pci_csv_data: null,
                vsm_pci_csv_error: null
            };

        case VSM_PCI_CSV_SUCCESS:
            return {
                ...state,
                vsm_pci_csv_status: 'success',
                vsm_pci_csv_data: action.vsm_pci_csv_data
            };

        case VSM_PCI_CSV_ERROR:
            return {
                ...state,
                vsm_pci_csv_status: 'error',
                vsm_pci_csv_error: action.vsm_pci_csv_error
            };

        /**********************************************************************************************************
         *   VSM MIGRATION DATES
         **********************************************************************************************************/
        case VSM_MIGRATION_DATES_REQUEST:
            return {
                ...state,
                vsm_migration_dates_status: 'loading',
                vsm_migration_dates_data: null
            };

        case VSM_MIGRATION_DATES_SUCCESS:
            return {
                ...state,
                vsm_migration_dates_status: 'success',
                vsm_migration_dates_data: action.vsm_migration_dates_data
            };

        case VSM_MIGRATION_DATES_ERROR:
            return {
                ...state,
                vsm_migration_dates_status: 'error'
            };

        default:
            return state;
    }
};

export default vsmReducer;

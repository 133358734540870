export const TOAST_NOTIFICATION = 'toast';
export const TRANSITION_DURATION = 300;
export const DEFAULT_TIMEOUT = 8000;

export const toastStates = /** @type {const} */ ({
    ACTIVE: 'active',
    INACTIVE: 'inactive',
    EXITING: 'exiting'
});

export const toastStatuses = /** @type {const} */ ({
    SUCCESS: 'success',
    NOTICE: 'notice',
    WARN: 'warn',
    INFO: 'info'
});

export const notificationScopes = /** @type {const} */ ({
    GUEST: 'guest',
    USER: 'user',
    GLOBAL: 'global'
});

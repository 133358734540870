/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React from 'react';

export const SignupSuccessModalContext = React.createContext({
    openSuccessModal: () => void 0
});

export const useSignupSuccessModalContext = () => React.useContext(SignupSuccessModalContext);

/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React from 'react';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import Anchor from 'components/Anchor';
import Grid from 'components/Grid';
import { PhosphorIcons } from 'components/Icons/Phosphor';
import { Flex } from 'components/Utils/Flex';
import Padding from 'components/Utils/Padding';
import Text from 'components/Utils/Text';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { useKatanaParams } from 'containers/katana/hooks/useSetupEditorRouteParams';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import './_tutorialVideo.scss';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
interface TutorialVideoProps {
    tutorial: string;
}

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const TutorialVideo: React.FC<TutorialVideoProps> = ({ tutorial }) => {
    const { page } = useKatanaParams();
    if (page === 'editor') {
        return;
    }
    return (
        <div className="tutorialVideoBox__container">
            <Padding xy={3}>
                <Grid columns="1fr auto">
                    <Text>
                        <Text size--s bold className="tutorialVideoBox__text">
                            Need help?
                        </Text>{' '}
                        <Text size--s secondary>
                            Follow along with a VIPsites website builder tutorial
                        </Text>
                    </Text>
                    <Anchor href={tutorial} target="_blank">
                        <Flex direction="row" gap={1}>
                            <PhosphorIcons.Video.Light info />
                            <Text size--s>Watch tutorial</Text>
                        </Flex>
                    </Anchor>
                </Grid>
            </Padding>
        </div>
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

/* eslint-disable react/jsx-pascal-case */
/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React from 'react';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import { _Modal } from 'components/Lightboxes/Modal/_Modal';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import './_CompactModal.scss';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * @param {BaseModalProps} props
 */
function _CompactModal({ isOpen, onClose, children, ariaLabel }) {
    /***** RENDER *****/
    return (
        <_Modal className="CompactModal" isOpen={isOpen} onClose={onClose} ariaLabel={ariaLabel} preventScrollBlock>
            {children}
        </_Modal>
    );
}
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
export { _CompactModal };

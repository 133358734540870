/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import classNames from 'classnames';
import React from 'react';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import BackgroundImage from 'components/BackgroundImage';
import Grid from 'components/Grid';
import { NXSquare } from 'components/Utils/NXSquare';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import TitleDescriptionBlock from 'containers/katana/components/titleDescriptionBlock';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import { getKatanaSectionIDColour } from 'containers/katana/methods';
import './_ImageTitleDescriptionBlock.scss';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * @type {React.FC<{
 *      imageUrl: string;
 *      title: React.ReactNode;
 *      description: React.ReactNode;
 *      sectionDefinitionID: number;
 * }>}
 */
function ImageTitleDescriptionBlock({ imageUrl, title, description, sectionDefinitionID }) {
    /***** RENDER *****/
    return (
        <Grid
            columns="1fr 3fr"
            gap={5}
            className={classNames('ImageTitleDescriptionBlock', `ImageTitleDescriptionBlock--${getKatanaSectionIDColour(sectionDefinitionID)}`)}
        >
            <NXSquare>
                <BackgroundImage imageURL={imageUrl} contain fill />
            </NXSquare>
            <TitleDescriptionBlock title={title} description={description} />
        </Grid>
    );
}
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

export default ImageTitleDescriptionBlock;

import { createOptimisticConcatMethod } from 'utilities/methods/tanstack/createOptimisticConcatMethod';
import { createOptimisticFilterMethod } from 'utilities/methods/tanstack/createOptimisticFilterMethod';
import { createOptimisticMergeMethod } from 'utilities/methods/tanstack/createOptimisticMergeMethod';
import { createOptimisticSetMethod } from 'utilities/methods/tanstack/createOptimisticSetMethod';
import type { NXQueryUtils } from 'utilities/methods/tanstack/types';

/**
 * Creates optimistic update methods for Setting, Merging and Filtering data at specific layers of the query data object
 *
 * Each function factory returns a function
 * - when the function is called the data in the tanstack query store is updated.
 * - the called function then returns an object with a method `restore` on it, allowing the consumer to "undo" their optimistic update.
 */
export function createOptimisticMethods<TParams extends any, TData extends NXQueryUtils.ApiData>(
    setQueryData: NXQueryUtils.SetQueryDataMethod<TParams, TData>
) {
    const set = createOptimisticSetMethod(setQueryData);
    const filter = createOptimisticFilterMethod(setQueryData);
    const merge = createOptimisticMergeMethod(setQueryData);
    const concat = createOptimisticConcatMethod(setQueryData);

    /***** RESULTS *****/
    return Object.assign(set, {
        filter,
        set,
        merge,
        concat
    });
}

/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { createContext } from 'react';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type TStaffMenuContext = {
    active: string;
    setActive: React.Dispatch<React.SetStateAction<string>>;
    isOpen: boolean;
};

/**********************************************************************************************************
 *   CONTEXT START
 **********************************************************************************************************/
export const StaffMenuContext = createContext<TStaffMenuContext>({
    setActive: () => {
        throw new Error('StaffMenuContext MUST be used within the StaffMenu');
    },
    active: '',
    isOpen: false
});

import { combineReducers } from 'redux';
import { reducer as reduxFormReducer } from 'redux-form';

import account from 'containers/account/reducer';
import auDirect from 'containers/auDirect/reducer';
import billing from 'containers/billing/reducer';
import dashboard from 'containers/dashboard/reducer';
import domain from 'containers/domain/reducer';
import email from 'containers/email/reducer';
import gsuite from 'containers/gsuite/reducer';
import hosting from 'containers/hosting/state/reducer';
import login from 'containers/login/reducer';
import ms365 from 'containers/ms365/reducer';
import servicesBulk from 'containers/services/Bulk/reducer';
import services from 'containers/services/reducer';
import ssl from 'containers/ssl/reducer';
import support from 'containers/support/reducer';
import vsm from 'containers/ventraSynMigration/reducer';
import rewards from 'containers/vipRewards/reducer';
import vps from 'containers/vps/reducer';
import common from 'utilities/methods/commonReducer';
import app from '../App/reducer';

import promotion from 'config/containers/promotions/reducer';

import autocomplete from 'components/AutocompleteAddress/reducer';
import cancel from 'components/Cancel/reducer';
import sidebar from 'components/Sidebar/reducer';

export default combineReducers({
    app,
    common,
    login,
    account,
    auDirect,
    billing,
    services,
    dashboard,
    domain,
    hosting,
    promotion,
    email,
    servicesBulk,
    ssl,
    support,
    autocomplete,
    cancel,
    sidebar,
    vps,
    gsuite,
    ms365,
    vsm,
    rewards,
    form: reduxFormReducer
});

import axios from 'axios';
import { nxAxios } from 'store/nxAxios';

export let cancelSearchEmail;

export const API = {
    /**********************************************************************************************************
     *   EMAIL API
     **********************************************************************************************************/

    email: {
        get: {
            products: (optionalPromoCode = null) => {
                return axios.post(`/api/email-hosting/products/list`, { promo_code: optionalPromoCode });
            },

            mailboxes: (id, params) => {
                if (params) {
                    return axios.get(`/api/email-hosting/${id}`, {
                        params
                    });
                }

                return axios.get(`/api/email-hosting/${id}`);
            },

            allMailboxes: () => {
                return axios.get(`/api/email-hosting/mailboxes`);
            },

            overview: (id) => {
                return axios.get(`/api/email-hosting/${id}/single-detail`);
            },

            /**
             * Gets the details for the group of mailboxes using the same domain
             */
            domainGroupDetail: (id) => {
                return axios.get(`/api/email-hosting/${id}/service-detail`);
            },

            aliases: (id) => {
                return axios.get(`/api/email-hosting/${id}/alias`);
            },

            diskblocks: (id) => {
                return axios.get(`/api/email-hosting/${id}/disk-blocks`);
            },

            usage: (id) => {
                return axios.get(`/api/email-hosting/mailbox/${id}/usage`);
            },

            credentials: (id) => {
                return axios.get(`/api/email-hosting/mailbox/${id}/credential`);
            },

            billing: {
                pricing: (id) => {
                    return axios.get(`/api/email-hosting/${id}/billing-cycle/`);
                },

                current: (id) => {
                    return axios.get(`/api/email-hosting/${id}/billing-cycle/show`);
                }
            },

            activeSync: (id) => {
                return axios.get(`/api/email-hosting/mailbox/${id}/activesync`);
            },

            restore: (id) => {
                return axios.get(`/api/email-hosting/mailbox/${id}/restore`);
            },

            spamFiltering: (id: string): Promise<{ data: { attributes: { action: string } } }> => {
                return nxAxios.get(`/api/email-hosting/mailbox/${id}/spam-filtering`);
            }
        },

        post: {
            mailbox: (payload) => {
                return axios.post(`/api/email-hosting/mailbox`, payload);
            },

            update: (id, attributes) => {
                return axios.post(`/api/email-hosting/mailbox/${id}/email`, {
                    data: {
                        ...attributes
                    }
                });
            },

            send: (id) => {
                return axios.post(`/api/email-hosting/mailbox/${id}/email`);
            },

            setup: (id, attributes) => {
                return axios.post(`/api/email-hosting/${id}/setup`, {
                    type: 'email-hosting',
                    id: id,
                    attributes
                });
            },

            alias: (id, attributes) => {
                return axios.post(`/api/email-hosting/${id}/alias`, {
                    type: 'email-hosting',
                    attributes
                });
            },

            aliasDelete: (id, attributes) => {
                return axios.post(`/api/email-hosting/${id}/alias/delete`, {
                    type: 'email-hosting',
                    attributes
                });
            },

            billing: (id, attributes) => {
                return axios.post(`/api/email-hosting/${id}/billing-cycle/update`, {
                    attributes
                });
            },

            upgrade: (id, attributes) => {
                return axios.post(`/api/email-hosting/mailbox/${id}/upgrade`, {
                    type: 'email-hosting',
                    id: id,
                    attributes
                });
            },

            upgradeDetails: (id, attributes) => {
                return axios.post(`/api/email-hosting/mailbox/${id}/upgrade/details`, {
                    type: 'email-hosting',
                    id,
                    attributes
                });
            },

            downgrade: (id, attributes) => {
                return axios.post(`/api/email-hosting/mailbox/${id}/downgrade`, {
                    type: 'email-hosting',
                    id: id,
                    attributes
                });
            },

            restore: (id) => axios.post(`/api/email-hosting/mailbox/${id}/restore`),

            diskblocks: {
                add: (id, addon, attributes) => {
                    return axios.post(`/api/email-hosting/${id}/disk-blocks/purchase/${addon}`, {
                        type: 'disk-block',
                        attributes
                    });
                },

                delete: (id, addon) => {
                    return axios.post(`/api/email-hosting/${id}/disk-blocks/remove/${addon}`);
                }
            },

            validate: (emailAddress) =>
                axios.post(`/api/email-hosting/validate`, {
                    type: 'email-hosting',
                    attributes: {
                        email: emailAddress
                    }
                })
        },

        put: {
            credentials: (id, attributes) => {
                return axios.put(`/api/email-hosting/mailbox/${id}/credential`, {
                    type: 'email-hosting',
                    id: id,
                    attributes
                });
            },

            activeSync: (id, attributes) => {
                return axios.put(`/api/email-hosting/mailbox/${id}/activesync`, {
                    type: 'email-hosting',
                    id: id,
                    attributes
                });
            }
        },

        delete: {
            alias: (id, aliasid) => {
                return axios.delete(`/api/email-hosting/${id}/alias/${aliasid}`);
            }
        }
    }
};

/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React from 'react';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import BaseButton from '../_BaseButton';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import './_RightBorderButton.scss';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * that renders a button with the RightBorderButton variant
 * @param {ButtonPropsTypeDef & LoadingButtonPropsTypeDef} props
 */
const RightBorderButton = (props) => {
    /***** RENDER *****/
    return <BaseButton variant="RightBorderButton" {...props} />;
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

export default RightBorderButton;

import { getCountries } from 'App/action';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';

type AppCountriesData = Artah.Country.GET._200['data'];
type CheckCountriesState = {
    app?: {
        app_countries_status: null | 'loading' | 'success' | 'error';
        app_countries_data: null | AppCountriesData;
    };
};

export function useCountriesData() {
    /***** HOOKS *****/
    const selectorData = useSelector((state: CheckCountriesState) => ({
        app_countries_status: state.app.app_countries_status,
        app_countries_data: state.app.app_countries_data ?? ([] as AppCountriesData)
    }));

    /***** EFFECTS *****/
    useEffect(() => {
        if (!selectorData.app_countries_data.length && selectorData.app_countries_status !== 'loading') {
            getCountries();
        }
    }, []);

    /***** HOOK RESULTS *****/
    return selectorData;
}

import React from 'react';

// prettier-ignore
/**
 * Make sure to wrap this in a Suspense boundary
 */
export const _LazyHookFormDevTool =
    process.env.NODE_ENV === 'production'
        ? () => null // Render nothing in production
        : React.lazy(() =>
            // Lazy load in development
            import('components/Form/HookForm/_HookFormDevToolWrapper').then((res) => ({
                default: res._HookFormDevToolWrapper
            }))
        );

/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import classNames from 'classnames';
import React from 'react';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import './_SectionOrganiserChildBaseBar.scss';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * @type {React.FC<{
 *      children: React.ReactNode;
 *      className?: string;
 *      style?: React.CSSProperties;
 * }>}
 */
const SectionOrganiserChildBaseBar = ({ children, className, style }) => (
    <div className={classNames('SectionOrganiserChildBaseBar', className)} style={style}>
        {children}
    </div>
);
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
export { SectionOrganiserChildBaseBar };

import classNames from 'classnames';
import { DevTools } from 'components/DevTools';
import { DevelopmentModuleFilterButton } from 'components/DevelopmentModuleFilter/_DevelopmentModuleFilterButton';
import StaffTag from 'components/Staff';
import { SuperUser } from 'components/StaffMenu/SuperUser';
import { DeveloperTheme } from 'components/StaffMenu/Theme';
import { staffMenuDevPanelRefStore } from 'components/StaffMenu/consts';
import { StaffMenuContext } from 'components/StaffMenu/context';
import { DeveloperItem, StaffItem } from 'components/StaffMenu/filter';
import TestRoutes from 'components/TestRoutes';
import { Flex } from 'components/Utils/Flex';
import { useState } from 'react';
import { createPortal } from 'react-dom';
import { useSelector } from 'react-redux';
import './_StaffMenu.scss';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const StaffMenu = () => {
    const staffMenuState = localStorage.getItem('staffMenuState');
    const defaultStaffMenuState = typeof staffMenuState === 'string' ? JSON.parse(staffMenuState) : true;

    /***** STATE *****/
    const [isOpen, setIsOpen] = useState(defaultStaffMenuState);
    const [activeMenuItem, setActiveMenuItem] = useState<string>('');

    /***** HOOKS *****/
    const { app_check_token_data } = useSelector((state: any) => state.app);

    /***** FUNCTIONS *****/
    const closeMenu = () => {
        setIsOpen(false);
        setActiveMenuItem('');

        localStorage.setItem('staffMenuState', 'false');
    };

    const openMenu = () => {
        setIsOpen(true);
        localStorage.setItem('staffMenuState', 'true');
    };

    function handleDevPanelRef(element: HTMLDivElement | null) {
        if (staffMenuDevPanelRefStore.state !== element) {
            staffMenuDevPanelRefStore.setState(() => element);
        }
    }

    /***** RENDER HELPERS *****/
    const className = classNames('StaffMenu', {
        'StaffMenu--open': isOpen
    });

    /***** RENDER *****/
    if (app_check_token_data?.is_vpn) {
        return createPortal(
            <StaffMenuContext.Provider value={{ isOpen, active: activeMenuItem, setActive: setActiveMenuItem }}>
                <Flex direction="column" className={className} gap={2}>
                    <StaffItem component={StaffTag} />
                    <StaffItem component={SuperUser} />
                    <StaffItem component={TestRoutes} />

                    <DeveloperItem component={DevelopmentModuleFilterButton} />
                    <DeveloperItem component={DeveloperTheme} />

                    <div className="StaffMenu__displayToggle">
                        <DevTools.DisplayToggle isOpen={isOpen} handleOpen={openMenu} handleClose={closeMenu} />
                    </div>
                </Flex>
                <div className="StaffMenu__devPanels" ref={handleDevPanelRef} />
            </StaffMenuContext.Provider>,
            document.body
        );
    }
    return null;
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

import {
    SUPPORT_ALL_SERVICES_ERROR,
    SUPPORT_ALL_SERVICES_REQUEST,
    SUPPORT_ALL_SERVICES_SUCCESS,
    SUPPORT_DOWNLOAD_ATTACHMENT_ERROR,
    SUPPORT_DOWNLOAD_ATTACHMENT_REQUEST,
    SUPPORT_DOWNLOAD_ATTACHMENT_SUCCESS,
    SUPPORT_FEEDBACK_FORM_ERROR,
    SUPPORT_FEEDBACK_FORM_REQUEST,
    SUPPORT_FEEDBACK_FORM_SUCCESS,
    SUPPORT_INDIVIDUAL_TICKET_ERROR,
    SUPPORT_INDIVIDUAL_TICKET_REQUEST,
    SUPPORT_INDIVIDUAL_TICKET_SUCCESS,
    SUPPORT_INITIAL_STATE,
    SUPPORT_KNOWLEDGE_BASE_ALL,
    SUPPORT_KNOWLEDGE_BASE_CATEGORY_REFS,
    SUPPORT_KNOWLEDGE_BASE_ERROR,
    SUPPORT_KNOWLEDGE_BASE_REQUEST,
    SUPPORT_KNOWLEDGE_BASE_SUCCESS,
    SUPPORT_MIGRATION_MIGRATION_DATES_ERROR,
    SUPPORT_MIGRATION_MIGRATION_DATES_REQUEST,
    SUPPORT_MIGRATION_MIGRATION_DATES_SUCCESS,
    SUPPORT_QUESTION_LIST_ERROR,
    SUPPORT_QUESTION_LIST_REQUEST,
    SUPPORT_QUESTION_LIST_SUCCESS,
    SUPPORT_RESOLVED_SURVEY_ERROR,
    SUPPORT_RESOLVED_SURVEY_LOAD_MORE_ERROR,
    SUPPORT_RESOLVED_SURVEY_LOAD_MORE_REQUEST,
    SUPPORT_RESOLVED_SURVEY_LOAD_MORE_SUCCESS,
    SUPPORT_RESOLVED_SURVEY_REQUEST,
    SUPPORT_RESOLVED_SURVEY_SUCCESS,
    SUPPORT_RESOLVED_TICKETS_SEARCH_ERROR,
    SUPPORT_RESOLVED_TICKETS_SEARCH_REQUEST,
    SUPPORT_RESOLVED_TICKETS_SEARCH_RESET,
    SUPPORT_RESOLVED_TICKETS_SEARCH_SUCCESS,
    SUPPORT_SERVICE_STATUS_ERROR,
    SUPPORT_SERVICE_STATUS_REQUEST,
    SUPPORT_SERVICE_STATUS_SUCCESS,
    SUPPORT_SERVICE_STATUS_UPDATE_ERROR,
    SUPPORT_SERVICE_STATUS_UPDATE_REQUEST,
    SUPPORT_SERVICE_STATUS_UPDATE_SUCCESS,
    SUPPORT_SUBMIT_MIGRATION_DETAILS_CHECK_SERVICE_ERROR,
    SUPPORT_SUBMIT_MIGRATION_DETAILS_CHECK_SERVICE_REQUEST,
    SUPPORT_SUBMIT_MIGRATION_DETAILS_CHECK_SERVICE_RESET,
    SUPPORT_SUBMIT_MIGRATION_DETAILS_CHECK_SERVICE_SUCCESS,
    SUPPORT_SUBMIT_MIGRATION_REQUEST_FORM_ERROR,
    SUPPORT_SUBMIT_MIGRATION_REQUEST_FORM_REQUEST,
    SUPPORT_SUBMIT_MIGRATION_REQUEST_FORM_SUCCESS,
    SUPPORT_TICKETS_OPEN_ERROR,
    SUPPORT_TICKETS_OPEN_REQUEST,
    SUPPORT_TICKETS_OPEN_SUCCESS,
    SUPPORT_TICKET_CLOSE_ERROR,
    SUPPORT_TICKET_CLOSE_REQUEST,
    SUPPORT_TICKET_CLOSE_SUCCESS,
    SUPPORT_TICKET_FEEDBACK_ERROR,
    SUPPORT_TICKET_FEEDBACK_REQUEST,
    SUPPORT_TICKET_FEEDBACK_SUCCESS,
    SUPPORT_TICKET_UPDATE_FEEDBACK_STATUS_ERROR,
    SUPPORT_TICKET_UPDATE_FEEDBACK_STATUS_REQUEST,
    SUPPORT_TICKET_UPDATE_FEEDBACK_STATUS_SUCCESS,
    SUPPORT_TICKET_WAIT_TIME_ERROR,
    SUPPORT_TICKET_WAIT_TIME_REQUEST,
    SUPPORT_TICKET_WAIT_TIME_SUCCESS
} from './action';

export const initialState = {
    support_knowledge_base_category_refs: {},
    support_knowledge_base_status: null,
    support_knowledge_base_data: null,
    support_knowledge_base_all_posts_data: null,
    support_knowledge_base_error: null,
    support_knowledge_base_article: null,
    support_knowledge_base_category: null,
    support_knowledge_base_sub_category: null,
    support_tickets_open_status: null,
    support_tickets_open_data: null,
    support_tickets_open_error: null,
    support_individual_ticket_status: null,
    support_individual_ticket_data: null,
    support_individual_ticket_error: null,
    support_individual_ticket_meta: null,
    support_tickets_reply_status: null,
    support_tickets_reply_error: null,
    support_ticket_update_feedback_status_status: null,
    support_ticket_update_feedback_status_error: null,
    support_tickets_close_status: null,
    support_tickets_close_data: null,
    support_tickets_close_error: null,
    support_ticket_wait_time_status: null,
    support_ticket_wait_time_data: null,
    support_ticket_wait_time_error: null,
    support_submit_new_ticket_status: null,
    support_download_attachment_status: null,
    support_all_services_status: null,
    support_all_services_data: null,
    support_all_services_error: null,
    support_question_list_status: null,
    support_question_list_data: null,
    support_question_list_error: null,
    support_questions_selection_data: { isSupport: false, selection: 'feedback' },
    support_resolved_survey_status: null,
    support_resolved_survey_data: null,
    support_resolved_survey_error: null,
    support_resolved_survey_meta: null,
    support_resolved_survey_load_more_status: null,
    support_resolved_survey_load_more_error: null,
    support_resolved_ticket_search_status: null,
    support_resolved_ticket_search_data: null,
    support_resolved_ticket_search_error: null,
    support_submit_migration_request_status: null,
    support_submit_migration_request_data: null,
    support_submit_migration_request_error: null,
    support_submit_migration_details_check_service_status: null,
    support_submit_migration_details_check_service_data: null,
    support_feedback_form_status: null,
    support_feedback_form_data: null,
    support_feedback_form_error: null,
    support_service_status_status: null,
    support_service_status_data: null,
    support_service_status_error: null,
    support_service_status_update_status: null,
    support_service_status_update_data: null,
    support_service_status_update_error: null,
    support_migration_migration_dates_status: null,
    support_migration_migration_dates_data: null
};

const supportReducer = (state = initialState, action) => {
    switch (action.type) {
        /**********************************************************************************************************
         *   RESET
         **********************************************************************************************************/
        case SUPPORT_INITIAL_STATE:
            return initialState;

        /**********************************************************************************************************
         *   KNOWLEDGE BASE
         **********************************************************************************************************/
        case SUPPORT_KNOWLEDGE_BASE_CATEGORY_REFS: {
            const newRef = action.support_knowledge_base_category_refs;
            const newCategoryRefs = { ...state.support_knowledge_base_category_refs };
            newCategoryRefs[newRef.category] = newRef.ref;
            return {
                ...state,
                support_knowledge_base_category_refs: newCategoryRefs
            };
        }
        case SUPPORT_KNOWLEDGE_BASE_REQUEST:
            return {
                ...state,
                support_knowledge_base_status: 'loading',
                support_knowledge_base_data: null,
                support_knowledge_base_error: null
            };

        case SUPPORT_KNOWLEDGE_BASE_SUCCESS:
            return {
                ...state,
                support_knowledge_base_status: 'success',
                support_knowledge_base_data: action.support_knowledge_base_data,
                support_knowledge_base_all_posts_data: action.support_knowledge_base_all_posts_data
            };

        case SUPPORT_KNOWLEDGE_BASE_ERROR:
            return {
                ...state,
                support_knowledge_base_status: 'error',
                support_knowledge_base_error: action.support_knowledge_base_error
            };

        case SUPPORT_KNOWLEDGE_BASE_ALL:
            return {
                ...state,
                support_knowledge_base_article: action.support_knowledge_base_article,
                support_knowledge_base_sub_category: action.support_knowledge_base_sub_category,
                support_knowledge_base_category: action.support_knowledge_base_category
            };

        /**********************************************************************************************************
         *   ETICKETS
         **********************************************************************************************************/
        case SUPPORT_TICKETS_OPEN_REQUEST:
            return {
                ...state,
                support_tickets_open_status: 'loading',
                support_tickets_open_data: null,
                support_tickets_open_error: null
            };

        case SUPPORT_TICKETS_OPEN_SUCCESS:
            return {
                ...state,
                support_tickets_open_status: 'success',
                support_tickets_open_data: action.support_tickets_open_data
            };

        case SUPPORT_TICKETS_OPEN_ERROR:
            return {
                ...state,
                support_tickets_open_status: 'error',
                support_tickets_open_error: action.support_tickets_open_error
            };

        case SUPPORT_INDIVIDUAL_TICKET_REQUEST:
            return {
                ...state,
                support_individual_ticket_status: 'loading',
                support_individual_ticket_data: null,
                support_individual_ticket_error: null,
                support_individual_ticket_meta: null
            };

        case SUPPORT_INDIVIDUAL_TICKET_SUCCESS:
            return {
                ...state,
                support_individual_ticket_status: 'success',
                support_individual_ticket_data: action.support_individual_ticket_data,
                support_individual_ticket_meta: action.support_individual_ticket_meta
            };

        case SUPPORT_INDIVIDUAL_TICKET_ERROR:
            return {
                ...state,
                support_individual_ticket_status: 'error',
                support_individual_ticket_error: action.support_individual_ticket_error
            };

        case SUPPORT_TICKET_UPDATE_FEEDBACK_STATUS_REQUEST:
            return {
                ...state,
                support_ticket_update_feedback_status_status: 'loading',
                support_ticket_update_feedback_status_error: null
            };

        case SUPPORT_TICKET_UPDATE_FEEDBACK_STATUS_SUCCESS:
            return {
                ...state,
                support_ticket_update_feedback_status_status: 'success'
            };

        case SUPPORT_TICKET_UPDATE_FEEDBACK_STATUS_ERROR:
            return {
                ...state,
                support_ticket_update_feedback_status_status: 'error',
                support_ticket_update_feedback_status_error: action.support_ticket_update_feedback_status_error
            };

        case SUPPORT_TICKET_CLOSE_REQUEST:
            return {
                ...state,
                support_tickets_close_status: 'loading',
                support_tickets_close_data: null,
                support_tickets_close_error: null
            };

        case SUPPORT_TICKET_CLOSE_SUCCESS:
            return {
                ...state,
                support_tickets_close_status: 'success',
                support_tickets_close_data: action.support_tickets_close_data
            };

        case SUPPORT_TICKET_CLOSE_ERROR:
            return {
                ...state,
                support_tickets_close_status: 'error',
                support_tickets_close_error: action.support_tickets_close_error
            };

        case SUPPORT_TICKET_WAIT_TIME_REQUEST:
            return {
                ...state,
                support_ticket_wait_time_status: 'loading',
                support_ticket_wait_time_data: null,
                support_ticket_wait_time_error: null
            };

        case SUPPORT_TICKET_WAIT_TIME_SUCCESS:
            return {
                ...state,
                support_ticket_wait_time_status: 'success',
                support_ticket_wait_time_data: action.support_ticket_wait_time_data
            };

        case SUPPORT_TICKET_WAIT_TIME_ERROR:
            return {
                ...state,
                support_ticket_wait_time_status: 'error',
                support_ticket_wait_time_error: action.support_ticket_wait_time_error
            };

        case SUPPORT_ALL_SERVICES_REQUEST:
            return {
                ...state,
                support_all_services_status: 'loading',
                support_all_services_data: null,
                support_all_services_error: null
            };

        case SUPPORT_ALL_SERVICES_SUCCESS:
            return {
                ...state,
                support_all_services_status: 'success',
                support_all_services_data: action.support_all_services_data
            };

        case SUPPORT_ALL_SERVICES_ERROR:
            return {
                ...state,
                support_all_services_status: 'error',
                support_all_services_error: action.support_all_services_error
            };

        case SUPPORT_QUESTION_LIST_REQUEST:
            return {
                ...state,
                support_question_list_status: 'loading',
                support_question_list_data: null,
                support_question_list_error: null
            };

        case SUPPORT_QUESTION_LIST_SUCCESS:
            return {
                ...state,
                support_question_list_status: 'success',
                support_question_list_data: action.support_question_list_data
            };

        case SUPPORT_QUESTION_LIST_ERROR:
            return {
                ...state,
                support_question_list_status: 'error',
                support_question_list_error: action.support_question_list_error
            };

        case SUPPORT_TICKET_FEEDBACK_REQUEST:
            return {
                ...state,
                support_ticket_feedback_status: 'loading',
                support_ticket_feedback_data: null,
                support_ticket_feedback_error: null
            };

        case SUPPORT_TICKET_FEEDBACK_SUCCESS:
            return {
                ...state,
                support_ticket_feedback_status: 'success',
                support_ticket_feedback_data: action.support_ticket_feedback_data
            };

        case SUPPORT_TICKET_FEEDBACK_ERROR:
            return {
                ...state,
                support_ticket_feedback_status: 'error',
                support_ticket_feedback_error: action.support_ticket_feedback_error
            };

        case SUPPORT_RESOLVED_SURVEY_REQUEST:
            return {
                ...state,
                support_resolved_survey_status: 'loading',
                support_resolved_survey_data: null,
                support_resolved_survey_error: null,
                support_resolved_survey_meta: null
            };

        case SUPPORT_RESOLVED_SURVEY_SUCCESS:
            return {
                ...state,
                support_resolved_survey_status: 'success',
                support_resolved_survey_data: action.support_resolved_survey_data,
                support_resolved_survey_meta: action.support_resolved_survey_meta
            };

        case SUPPORT_RESOLVED_SURVEY_ERROR:
            return {
                ...state,
                support_resolved_survey_status: 'error',
                support_resolved_survey_error: action.support_resolved_survey_error
            };

        case SUPPORT_RESOLVED_SURVEY_LOAD_MORE_REQUEST:
            return {
                ...state,
                support_resolved_survey_load_more_status: 'loading',
                support_resolved_survey_load_more_error: null
            };

        case SUPPORT_RESOLVED_SURVEY_LOAD_MORE_SUCCESS: {
            const newData = [...state.support_resolved_survey_data, ...action.support_resolved_survey_data];
            return {
                ...state,
                support_resolved_survey_load_more_status: 'success',
                support_resolved_survey_data: newData,
                support_resolved_survey_load_more_error: null,
                support_resolved_survey_meta: action.support_resolved_survey_meta
            };
        }
        case SUPPORT_RESOLVED_SURVEY_LOAD_MORE_ERROR:
            return {
                ...state,
                support_resolved_survey_load_more_status: 'error',
                support_resolved_survey_load_more_error: action.support_resolved_survey_load_more_error
            };

        case SUPPORT_RESOLVED_TICKETS_SEARCH_REQUEST:
            return {
                ...state,
                support_resolved_ticket_search_status: 'loading',
                support_resolved_ticket_search_data: null,
                support_resolved_ticket_search_error: null
            };

        case SUPPORT_RESOLVED_TICKETS_SEARCH_SUCCESS:
            return {
                ...state,
                support_resolved_ticket_search_status: 'success',
                support_resolved_ticket_search_data: action.support_resolved_ticket_search_data
            };

        case SUPPORT_RESOLVED_TICKETS_SEARCH_ERROR:
            return {
                ...state,
                support_resolved_ticket_search_status: 'error',
                support_resolved_ticket_search_error: action.support_resolved_ticket_search_error
            };

        case SUPPORT_RESOLVED_TICKETS_SEARCH_RESET:
            return {
                ...state,
                support_resolved_ticket_search_status: null,
                support_resolved_ticket_search_data: null,
                support_resolved_ticket_search_error: null
            };

        case SUPPORT_DOWNLOAD_ATTACHMENT_REQUEST:
            return {
                ...state,
                support_download_attachment_status: 'loading'
            };

        case SUPPORT_DOWNLOAD_ATTACHMENT_SUCCESS:
            return {
                ...state,
                support_download_attachment_status: 'success'
            };

        case SUPPORT_DOWNLOAD_ATTACHMENT_ERROR:
            return {
                ...state,
                support_download_attachment_status: 'error'
            };

        /**********************************************************************************************************
         *   MIGRATION
         **********************************************************************************************************/
        case SUPPORT_MIGRATION_MIGRATION_DATES_REQUEST:
            return {
                ...state,
                support_migration_migration_dates_status: 'loading',
                support_migration_migration_dates_data: null
            };

        case SUPPORT_MIGRATION_MIGRATION_DATES_SUCCESS:
            return {
                ...state,
                support_migration_migration_dates_status: 'success',
                support_migration_migration_dates_data: action.support_migration_migration_dates_data
            };

        case SUPPORT_MIGRATION_MIGRATION_DATES_ERROR:
            return {
                ...state,
                support_migration_migration_dates_status: 'error'
            };

        case SUPPORT_SUBMIT_MIGRATION_DETAILS_CHECK_SERVICE_RESET:
            return {
                ...state,
                support_submit_migration_details_check_service_status: null,
                support_submit_migration_details_check_service_data: null
            };

        case SUPPORT_SUBMIT_MIGRATION_DETAILS_CHECK_SERVICE_REQUEST:
            return {
                ...state,
                support_submit_migration_details_check_service_status: 'loading'
            };

        case SUPPORT_SUBMIT_MIGRATION_DETAILS_CHECK_SERVICE_SUCCESS:
            return {
                ...state,
                support_submit_migration_details_check_service_status: 'success',
                support_submit_migration_details_check_service_data: action.support_submit_migration_details_check_service_data
            };

        case SUPPORT_SUBMIT_MIGRATION_DETAILS_CHECK_SERVICE_ERROR:
            return {
                ...state,
                support_submit_migration_details_check_service_status: 'error'
            };

        case SUPPORT_SUBMIT_MIGRATION_REQUEST_FORM_REQUEST:
            return {
                ...state,
                support_submit_migration_request_form_status: 'loading',
                support_submit_migration_request_form_data: null
            };

        case SUPPORT_SUBMIT_MIGRATION_REQUEST_FORM_SUCCESS:
            return {
                ...state,
                support_submit_migration_request_form_status: 'success',
                support_submit_migration_request_form_data: action.support_submit_migration_request_form_data
            };

        case SUPPORT_SUBMIT_MIGRATION_REQUEST_FORM_ERROR:
            return {
                ...state,
                support_submit_migration_request_form_status: 'error'
            };
        /**********************************************************************************************************
         *   SERVICE STATUS
         **********************************************************************************************************/
        case SUPPORT_SERVICE_STATUS_REQUEST:
            return {
                ...state,
                support_service_status_status: 'loading',
                support_service_status_data: null,
                support_service_status_error: null
            };

        case SUPPORT_SERVICE_STATUS_SUCCESS:
            return {
                ...state,
                support_service_status_status: 'success',
                support_service_status_data: action.support_service_status_data
            };

        case SUPPORT_SERVICE_STATUS_ERROR:
            return {
                ...state,
                support_service_status_status: 'error',
                support_service_status_error: action.support_service_status_error
            };

        case SUPPORT_SERVICE_STATUS_UPDATE_REQUEST:
            return {
                ...state,
                support_service_status_update_status: 'loading',
                support_service_status_update_data: null,
                support_service_status_update_error: null
            };

        case SUPPORT_SERVICE_STATUS_UPDATE_SUCCESS:
            return {
                ...state,
                support_service_status_update_status: 'success',
                support_service_status_update_data: action.support_service_status_update_data
            };

        case SUPPORT_SERVICE_STATUS_UPDATE_ERROR:
            return {
                ...state,
                support_service_status_update_status: 'error',
                support_service_status_update_error: action.support_service_status_update_error
            };

        /**********************************************************************************************************
         *   FEEDBACK
         **********************************************************************************************************/
        case SUPPORT_FEEDBACK_FORM_REQUEST:
            return {
                ...state,
                support_feedback_form_status: 'loading',
                support_feedback_form_data: null,
                support_feedback_form_error: null
            };

        case SUPPORT_FEEDBACK_FORM_SUCCESS:
            return {
                ...state,
                support_feedback_form_status: 'success',
                support_feedback_form_data: action.support_feedback_form_data
            };

        case SUPPORT_FEEDBACK_FORM_ERROR:
            return {
                ...state,
                support_feedback_form_status: 'error',
                support_feedback_error: action.support_feedback_form_error
            };

        default:
            return state;
    }
};

export default supportReducer;

/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import htmr from 'htmr';
import { useSelector } from 'react-redux';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import SolidNotification from 'components/Notifications/SolidNotification';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * Alert Banner that exists at the top level of the application (above the router). Fetching of data
 * for this component is fetched using `checkAlertBanner` in the App component under certain conditions.
 */
export const ApplicationAlertBanner = () => {
    /***** HOOKS *****/
    const app_alert_banner_data = useSelector((state: any) => state.app.app_alert_banner_data);

    /***** RENDER *****/
    return (
        <>
            {app_alert_banner_data?.enabled && (
                <SolidNotification className="alertBanner" dismissible={false} color="warning">
                    {htmr(app_alert_banner_data.message)}
                </SolidNotification>
            )}
        </>
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

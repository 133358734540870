/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { createRoute } from '@tanstack/react-router';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import { GoogleMailboxRoute } from 'containers/gsuite/routes/mailbox.$mailboxId';
import { routerMiddleware } from 'router/utils/middleware';
import { lazyFN } from 'utilities/methods/tanstack/router/lazyFn';

/**********************************************************************************************************
 *   ROUTE START
 **********************************************************************************************************/
export const GoogleMailboxManageRoute = createRoute({
    getParentRoute: () => GoogleMailboxRoute,
    path: 'manage',
    beforeLoad(opts) {
        routerMiddleware.business(this, opts);
        routerMiddleware.authentication('user', opts);
    }
}).lazy(lazyFN(() => import('./manage.lazy'), 'LazyGoogleMailboxManageRoute'));

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import './_NXTableAccordion.scss';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * Standard flex wrapper and minor styling for a Child of the NXTableAccordion
 *
 * @param {{ children: React.ReactNode }} props
 */
function Child({ children }) {
    /***** RENDER *****/
    return <div className="NXTableAccordion__body--child">{children}</div>;
}
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

export default Child;

/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import classNames from 'classnames';
import React from 'react';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import { PhosphorIcons } from 'components/Icons/Phosphor';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import './_DragHandle.scss';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * @type {React.FC<{
 *      divProps?: React.HTMLProps<HTMLDivElement>;
 *      fullHeight?:boolean;
 * }>}
 */
const __DragHandle = ({ divProps, fullHeight }) => (
    <div
        className={classNames('DragHandle HoverBlocker', {
            'DragHandle--fullHeight': fullHeight
        })}
        {...divProps}
    >
        <PhosphorIcons.DotsSixVertical.Bold size={21} />
    </div>
);
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
const _DragHandle = Object.assign(__DragHandle, {
    width: 31
});
export { _DragHandle };

/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import * as Sentry from '@sentry/react';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { isRichTextData } from 'containers/katana/containers/ContentEditorLightbox/methods/processSectionProperties/isRichTextData';
import type { MinMaxValidationValue} from 'utilities/methods/validators';
import { validateMaximum } from 'utilities/methods/validators';

export function getRepeatedValidationMaxTypeMessage(value: MinMaxValidationValue, maxValue: number) {
    if (typeof value === 'string') {
        return `Maximum character limit of ${maxValue} reached.`;
    }

    if (typeof value === 'number') {
        return `Maximum is ${maxValue}.`;
    }

    if (Array.isArray(value)) {
        return `Must not have more than ${maxValue} entries.`;
    }

    if (value === null || value === undefined) {
        return undefined;
    }

    if (isRichTextData(value)) {
        return `Maximum rich text character limit of ${maxValue} reached.`;
    }

    const message = `value type not supported for max type validation, value: ${JSON.stringify(value)}, ${value?.constructor?.name}, ${typeof value}, maxValue: ${maxValue}`;
    console.error(message);
    Sentry.captureMessage(message);

    return undefined;
}

export function validationMaximum(value: MinMaxValidationValue, maxValue: number) {
    const textResult = getRepeatedValidationMaxTypeMessage(value, maxValue);
    const result = validateMaximum(value, maxValue);
    return result ? undefined : textResult;
}

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import Carousel from 'components/Carousel';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import './_vipRewardsMoreInfoRewardsCarousel.scss';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type VipRewardsMoreInfoRewardsCarousel = React.FC<VipRewardsMoreInfoRewardsCarouselProps>;
type VipRewardsMoreInfoRewardsCarouselProps = {
    slides: React.ReactNode[];
};

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const VipRewardsMoreInfoRewardsCarousel: VipRewardsMoreInfoRewardsCarousel = ({ slides }) => {
    /***** RENDER *****/
    return (
        <div className="vipRewardsMoreInfoRewardsCarousel">
            <Carousel slides={slides} />
        </div>
    );
};

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

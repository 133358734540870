/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import { PropertiesRenderer } from 'containers/katana/components/dynamicFormFieldRenderer/propertiesRenderer';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
export const DynamicFormFieldRendererContextValues = {
    PropertiesRenderer
};

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { requiredFieldValidation } from 'utilities/methods/form';
import { validationString } from 'utilities/methods/form/validations/validationString';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import { formFieldsInRuleParser } from 'containers/katana/formFields/methods/validations/ruleParsers/formFieldsInRuleParser';
import { formFieldsMaxRuleParser } from 'containers/katana/formFields/methods/validations/ruleParsers/formFieldsMaxRuleParser';
import { formFieldsMinRuleParser } from 'containers/katana/formFields/methods/validations/ruleParsers/formFieldsMinRuleParser';
import { formFieldsRequiredIfRuleParser } from 'containers/katana/formFields/methods/validations/ruleParsers/formFieldsRequiredIfRuleParser';
import { formFieldsRequiredUnlessRuleParser } from 'containers/katana/formFields/methods/validations/ruleParsers/formFieldsRequiredUnlessRuleParser';
import { formFieldUrlRuleParser } from 'containers/katana/formFields/methods/validations/ruleParsers/formFieldUrlRuleParser';
import { validationRuleTypes } from 'containers/katana/formFields/validationRuleTypes';

/**********************************************************************************************************
 *   TYPE IMPORTS
 **********************************************************************************************************/
import type { KatanaFormFieldValidationParams } from 'containers/katana/formFields/methods/validations/types';
import type { KatanaNamespace } from 'containers/katana/types';

const { STRING, URL, REQUIRED, IN, MIN, MAX, REQUIRED_IF, REQUIRED_UNLESS } = validationRuleTypes;

export const getKatanaFormFieldValidation = ({
    formValues,
    property
}: KatanaFormFieldValidationParams): KatanaNamespace.SectionDefinitions.ValidationFunctions => {
    return property.validation

        .map((validation: KatanaNamespace.SectionDefinitions.PropertiesModified['validation'][number]) => {
            switch (validation.name) {
                case STRING: {
                    return validationString;
                }
                case REQUIRED:
                    return requiredFieldValidation;

                case REQUIRED_IF: {
                    return formFieldsRequiredIfRuleParser({ validation, formValues, property });
                }

                case REQUIRED_UNLESS: {
                    return formFieldsRequiredUnlessRuleParser({ validation, formValues, property });
                }

                case MIN:
                    return formFieldsMinRuleParser(validation);

                case MAX:
                    return formFieldsMaxRuleParser(validation);

                case IN:
                    return formFieldsInRuleParser(validation);

                case URL:
                    return formFieldUrlRuleParser(validation);

                default: {
                    return undefined;
                }
            }
        })
        .filter(Boolean);
};

/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import classNames from 'classnames';
import React, { Component, Fragment } from 'react';
import Fade from 'react-reveal/Fade';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import Label from 'components/Label';
import RequestLoader from 'components/Loaders/Request';
import SolidTag from 'components/Tags/SolidTag';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import './_Search.scss';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * @extends {Component<{
 *   validate?: (value: string) => string | undefined,
 *   slim?: boolean,
 *   render: {
 *     status: string,
 *     placeholder: string,
 *     list: Object[] | React.ReactNode
 *   } | {
 *     placeholder: string
 *   },
 *   functions: {
 *     cancel?: () => void,
 *     search: (keyword: string) => void,
 *     reset: () => void
 *   },
 *   helpers?: {
 *     keyword: (keyword: string) => void
 *   },
 *   className?: string,
 *   onClickAway?: () => void,
 *   onClickSearchInput?: () => void,
 *   labelAfterInput?: string,
 *   onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void
 * }>}
 */
class Search extends Component {
    constructor(props) {
        super(props);
        this.onClickOutSide = this.onClickOutSide.bind(this);
        this.onChange = this.onChange.bind(this);
        this.validateSearchInput = this.validateSearchInput.bind(this);
        this.onClickSearchInput = this.onClickSearchInput.bind(this);
        this.resetSearchTerm = this.resetSearchTerm.bind(this);
        this.currentRef = null;
        this.inputRef = null;

        this.state = {
            error: undefined
        };
    }

    onChange(e) {
        const { helpers, functions, conditions, validate } = this.props;
        const { validateSearchInput, resetSearchTerm } = this;

        functions?.cancel?.();

        const keyword = e.currentTarget.value;
        helpers?.keyword?.(keyword);

        if (validate) {
            const error = validateSearchInput();
            this.setState({
                error
            });
            // don't perform the search if there's a validation error
            if (error) return;
        }

        if (keyword) {
            conditions && conditions.serviceID ? functions?.search?.(conditions.serviceID, keyword) : functions?.search?.(keyword);
        } else {
            resetSearchTerm();
        }
    }

    onClickOutSide(e) {
        const { onClickAway } = this.props;

        if (!this.currentRef.contains(e.target)) {
            onClickAway?.();
        }
    }

    validateSearchInput() {
        const { validate } = this.props;

        if (!this.inputRef?.value) return undefined;

        let error = undefined;
        if (Array.isArray(validate)) {
            validate.some((func) => {
                const currentError = func(this.inputRef.value);
                if (currentError) {
                    error = currentError;
                    return true;
                }
                return false;
            });
        } else {
            error = validate(this.inputRef.value);
        }

        return error;
    }

    resetSearchTerm() {
        const { validate, functions } = this.props;

        functions?.reset?.();

        // if validation is applied, also clear out any error when resetting search term
        if (validate) {
            this.setState({
                error: undefined
            });
        }
    }

    onClickSearchInput() {
        const { onClickSearchInput } = this.props;
        if (this.inputRef.value !== '') {
            onClickSearchInput?.();
        }
    }

    /************** LIFECYCLE METHODS **************/
    componentDidMount() {
        const { onClickOutSide } = this;
        document.addEventListener('click', onClickOutSide);
    }

    componentWillUnmount() {
        const { render } = this.props;
        const { onClickOutSide, resetSearchTerm } = this;
        document.removeEventListener('click', onClickOutSide);

        if (render.status === 'success') {
            resetSearchTerm();
            this.inputRef.value = '';
        }
    }

    render() {
        const { render, helpers, className, slim, labelAfterInput, onKeyDown } = this.props;
        const { error } = this.state;
        const { onChange, resetSearchTerm, onClickSearchInput } = this;

        const outerClasses = classNames('search__container', className, {
            error: !!error
        });

        /*   RENDER COMPONENT
         **********************************************************************************************************/
        return (
            <div
                ref={(el) => {
                    this.currentRef = el;
                }}
                className={outerClasses}
            >
                <Fragment>
                    <div
                        className="search__form"
                        onSubmit={(e) => {
                            e.preventDefault();
                        }}
                    >
                        <input
                            ref={(el) => {
                                this.inputRef = el;
                            }}
                            onChange={onChange}
                            onKeyDown={onKeyDown ?? null}
                            onClick={onClickSearchInput}
                            type="text"
                            placeholder={render.placeholder}
                        />
                        {labelAfterInput ? <Label>{labelAfterInput}</Label> : ''}
                        {this.inputRef?.value || error ? (
                            <button
                                type="onclick"
                                onClick={() => {
                                    resetSearchTerm();
                                    this.inputRef.value = '';
                                    helpers?.keyword?.('');
                                }}
                            >
                                <SolidTag color="info">Clear</SolidTag>
                            </button>
                        ) : (
                            <button
                                type="submit"
                                onClick={(e) => {
                                    e.preventDefault();
                                }}
                            >
                                <span className="icon icon-search"></span>
                            </button>
                        )}
                    </div>
                    {slim ? (
                        ''
                    ) : (
                        <div
                            className={`search__results ${render.status === 'loading' ? `loading` : ``} ${
                                render.status === 'success' ? `complete` : ``
                            }`}
                        >
                            {render.status === 'loading' ? <RequestLoader /> : render.list}
                        </div>
                    )}
                </Fragment>
                {/* @deprecated use `Revealer` instead */}
                {error && (
                    <Fade bottom collapse>
                        <span className="error">{error}</span>
                    </Fade>
                )}
            </div>
        );
    }
}

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
export default Search;

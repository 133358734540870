/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import classNames from 'classnames';
import { useCallback } from 'react';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import InteractiveGoogleMapsIframe from 'components/InteractiveGoogleMapsIframe';
import NXBox from 'components/NXBox';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import { DisabledInputFieldCaveatRenderer } from 'containers/katana/components/DisabledInputFieldCaveatRenderer';
import { getServiceInfoDataFromPath } from 'containers/katana/components/dynamicFormFieldRenderer/methods';
import { IndentFlush } from 'containers/katana/components/indentFlush/indentFlush';
import { SocialsLoginCaveatCondition } from 'containers/katana/formFields/caveats/SocialsLoginCaveatCondition';
import KatanaDescription from 'containers/katana/formFields/description';
import { _PropertyRenderContentDefinitionFormItem as PropertyRenderContentDefinitionFormItem } from './propertyRendererContentDefinitionFormItem';
import { _PropertyRenderNXBoxDescription as PropertyRenderNXBoxDescription } from './propertyRendererNXBoxDescription';
import { _PropertyRenderText as PropertyRenderText } from './propertyRendererText';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { useGetCaveat } from 'containers/katana/formFields/methods/useGetCaveat';
import { useContentEditorFormValues } from 'containers/katana/hooks/useContentEditorFormValues';
import { useKatanaParams } from 'containers/katana/hooks/useSetupEditorRouteParams';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import { CaveatTypes } from 'containers/katana/formFields/caveatTypes';
import { contentDefinitionPropertyFormByType } from 'containers/katana/formFields/contentDefinitionPropertyFormByType';
import './_propertyRenderer.scss';

/**********************************************************************************************************
 *   TYPE IMPORTS
 **********************************************************************************************************/
import { useGetShowPropertyWhenKeyHasValueCaveat } from 'containers/katana/formFields/methods/useGetShowPropertyWhenKeyHasValueCaveat';
import type { KatanaNamespace } from 'containers/katana/types';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/

const _PropertyRenderer: KatanaNamespace.PropertyComponent = ({ property }) => {
    const { type, name, caveats, description } = property;

    /***** HOOKS *****/
    const { katanaServiceId } = useKatanaParams();
    const formValues = useContentEditorFormValues();

    /***** RENDER HELPERS *****/
    const boxTitle = useGetCaveat(CaveatTypes.BOX_TITLE, caveats);
    const boxDescription = useGetCaveat(CaveatTypes.BOX_DESCRIPTION, caveats);
    const disabledInputField = useGetCaveat(CaveatTypes.DISABLED_INPUT_FIELD, caveats);

    const interactiveGoogleMaps = useGetCaveat(CaveatTypes.INTERACTIVE_GOOGLE_MAPS, caveats);
    const googleMapsAddressSource = getServiceInfoDataFromPath(katanaServiceId, interactiveGoogleMaps);
    const showGoogleMapsMessage = Boolean(googleMapsAddressSource !== undefined && !googleMapsAddressSource);

    const indentFlush = useGetCaveat(CaveatTypes.INDENT_FLUSH, caveats);

    /***** Show Property When Key Has Value *****/

    const { showPropertyWhenKeyHasValue } = useGetShowPropertyWhenKeyHasValueCaveat({
        caveats,
        property,
        formValues: formValues.values
    });

    /***** RENDER HELPERS *****/
    const hideOnSectionStyle = useGetCaveat(CaveatTypes.HIDE_ON_SECTION_STYLE, caveats);
    const shouldHideOnSectionStyle = hideOnSectionStyle?.includes(formValues.values?.style);
    const isHiddenCaveat = useGetCaveat(CaveatTypes.HIDDEN, caveats);
    const socialLoginCaveat = useGetCaveat(CaveatTypes.SOCIAL_LOGIN, caveats);

    const renderPropertyContent = useCallback(
        function RenderPropertyContent() {
            if (shouldHideOnSectionStyle || !showPropertyWhenKeyHasValue) {
                return null;
            }

            return (
                <>
                    <div
                        className={classNames('PropertyWrapper', {
                            hidden: isHiddenCaveat
                        })}
                    >
                        <PropertyRenderText property={property} />
                        {!!boxTitle && <NXBox.Title title={boxTitle} />}
                        <PropertyRenderNXBoxDescription property={property} />
                        <PropertyRenderContentDefinitionFormItem property={property} FormComponent={contentDefinitionPropertyFormByType[type]} />
                        {!!googleMapsAddressSource && <InteractiveGoogleMapsIframe title={name} address={googleMapsAddressSource} />}
                        {showGoogleMapsMessage ? null : <KatanaDescription caveats={caveats}>{description}</KatanaDescription>}
                        {!!disabledInputField && <DisabledInputFieldCaveatRenderer {...disabledInputField} />}
                    </div>
                    <SocialsLoginCaveatCondition property={property} />
                </>
            );
        },
        [
            shouldHideOnSectionStyle,
            showPropertyWhenKeyHasValue,
            isHiddenCaveat,
            property,
            boxTitle,
            boxDescription,
            interactiveGoogleMaps,
            disabledInputField,
            socialLoginCaveat
        ]
    );

    /***** RENDER *****/
    switch (true) {
        case indentFlush:
            return <IndentFlush>{renderPropertyContent()}</IndentFlush>;

        default:
            return renderPropertyContent();
    }
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
export { _PropertyRenderer };

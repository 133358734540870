/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { useContext } from 'react';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import { RefContext } from 'components/Utils/RefProvider/consts';

/**********************************************************************************************************
 *   HOOK START
 **********************************************************************************************************/
/**
 * @type {RefProvider.UseRefContext}
 */
export function useRefContext(param) {
    /***** HOOKS *****/
    const refContextResult = useContext(RefContext);
    const { useGetRef } = refContextResult;
    const getRefResult = useGetRef?.(param);

    /***** HOOK RESULTS *****/
    if (param) {
        return /** @type {any} */ (getRefResult);
    }
    return /** @type {any} */ (refContextResult);
}
/**********************************************************************************************************
 *   HOOK END
 **********************************************************************************************************/

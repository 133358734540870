/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import Anchor from 'components/Anchor';
import SolidButton from 'components/Buttons/SolidButton';
import Padding from 'components/Utils/Padding';
import Text from 'components/Utils/Text';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import { RewardCard } from 'containers/vipRewards/modules/rewardCard/new';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import MoneyPattern from 'assets/images/dashboard/referral/money-pattern.png';
import ReferralImage from 'assets/images/promo/referralCard/50To75Referral.svg';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const ReferralRewardCard = () => {
    /***** RENDER *****/
    return (
        <RewardCard>
            <RewardCard.Image>
                <div className="rewardCard__bg rewardCard__bg--referral">
                    <img src={MoneyPattern} alt="Background" />
                    <img src={ReferralImage} alt="referral" />
                </div>
            </RewardCard.Image>
            <RewardCard.Body
                title="Refer a Friend and earn $75 CASH*"
                description="We've increased your referral reward from $50 to $75."
                subDescription={
                    <Padding top={1}>
                        <Anchor
                            color="secondary"
                            underline
                            href="https://ventraip.com.au/support-centre/everything-about-ventraip-australias-referral-program/"
                        >
                            <Text italic span size--xs>
                                *Terms and conditions apply.
                            </Text>
                        </Anchor>
                    </Padding>
                }
            >
                <SolidButton color="primary" to="/account/general?referral=true#referral">
                    Refer a Friend
                </SolidButton>
            </RewardCard.Body>
        </RewardCard>
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { formatPhoneNumberSynergy } from 'components/Form/PhoneInput/methods';
import SolidTag from 'components/Tags/SolidTag';

export function getCoRDisplayPrice(domain_get_cor_price_data) {
    return domain_get_cor_price_data?.price || '19.95';
}

export const getCCTLD = (domain?: string) => {
    if (domain?.endsWith('.au') || domain?.endsWith('.nz') || domain?.endsWith('.uk')) {
        return {
            au: domain?.endsWith('.au'),
            nz: domain?.endsWith('.nz'),
            uk: domain?.endsWith('.uk')
        };
    }

    return false;
};

export const renderTag = (type) => {
    switch (type) {
        case 'html_redirect':
            return <SolidTag color="record__pink">HTML</SolidTag>;
        case 'permanent_redirect':
            return <SolidTag color="record__orange">301</SolidTag>;
        case 'temporary_redirect':
            return <SolidTag color="record__violet">302</SolidTag>;
        case 'cloak':
            return <SolidTag color="record__blue">Cloak</SolidTag>;
        default:
            break;
    }
};

export function formatContactDetails({
    firstname,
    lastname,
    phone,
    postcode,
    state,
    country,
    suburb = '',
    city = '',
    company = '',
    organisation = '',
    address1,
    address2 = '',
    email
}) {
    return {
        firstname,
        lastname,
        email,
        address1,
        address2,
        organisation: organisation || company,
        suburb: suburb || city,
        state,
        country,
        postcode,
        phone: formatPhoneNumberSynergy(phone)
    };
}

/**
 * @template {import('containers/domain/types').DomainNamespace.Pages['subpage']} TPage
 * @template {Extract<import('containers/domain/types').DomainNamespace.Pages,{ subpage: TPage}>['section']} TSection
 * @template {number} TID
 * @param {TPage} subpage
 * @param {TSection} section
 * @param {TID} id
 * @returns {`/my-services/domains/${TPage}/${TSection}/${TID}`}
 */
export function domainURL(subpage, section, id) {
    return `/my-services/domains/${id}/${subpage}/${section}`;
}

import {
    SSL_BEACON_ERROR,
    SSL_BEACON_REQUEST,
    SSL_BEACON_SUCCESS,
    SSL_CONFIGURATION_ERROR,
    SSL_CONFIGURATION_REQUEST,
    SSL_CONFIGURATION_SUCCESS,
    SSL_CREATE_ERROR,
    SSL_CREATE_REQUEST,
    SSL_CREATE_SUCCESS,
    SSL_CSR_CLEAR_ERROR,
    SSL_CSR_CLEAR_REQUEST,
    SSL_CSR_CLEAR_SUCCESS,
    SSL_CSR_INFORMATION_ERROR,
    SSL_CSR_INFORMATION_REQUEST,
    SSL_CSR_INFORMATION_SUCCESS,
    SSL_CSR_SUBMISSION_ERROR,
    SSL_CSR_SUBMISSION_REQUEST,
    SSL_CSR_SUBMISSION_SUCCESS,
    SSL_DOWNLOAD_ERROR,
    SSL_DOWNLOAD_REQUEST,
    SSL_DOWNLOAD_SUCCESS,
    SSL_HOSTING_SEARCH_ERROR,
    SSL_HOSTING_SEARCH_REQUEST,
    SSL_HOSTING_SEARCH_SUCCESS,
    SSL_INFORMATION_ERROR,
    SSL_INFORMATION_REQUEST,
    SSL_INFORMATION_SUCCESS,
    SSL_INITIAL_STATE,
    SSL_INSTALL_ERROR,
    SSL_INSTALL_REQUEST,
    SSL_INSTALL_SUCCESS,
    SSL_VALIDATION_ERROR,
    SSL_VALIDATION_REQUEST,
    SSL_VALIDATION_SUCCESS
} from './action';

const initialState = {
    ssl_configuration_status: null,
    ssl_configuration_data: null,
    ssl_configuration_error: null,
    ssl_csr_information_data: null,
    ssl_csr_information_error: null,
    ssl_csr_information_status: null,
    ssl_csr_submission_data: null,
    ssl_csr_submission_status: null,
    ssl_csr_submission_error: null,
    ssl_csr_clear_data: null,
    ssl_csr_clear_status: null,
    ssl_csr_clear_error: null,
    ssl_install_status: null,
    ssl_install_data: null,
    ssl_install_error: null,
    ssl_hosting_search_status: null,
    ssl_hosting_search_data: null,
    ssl_hosting_search_error: null,
    ssl_information_data: null,
    ssl_information_error: null,
    ssl_information_status: null,
    ssl_create_data: null,
    ssl_create_status: null,
    ssl_create_error: null,
    ssl_download_data: null,
    ssl_download_status: null,
    ssl_download_error: null,
    ssl_validate_status: null,
    ssl_validate_data: null,
    ssl_validate_error: null,
    ssl_beacon_status: null,
    ssl_beacon_data: null,
    ssl_beacon_error: null,
    ssl_validation_status: null,
    ssl_validation_data: null,
    ssl_validation_error: null
};

function sslReducer(state = initialState, action) {
    switch (action.type) {
        case SSL_INITIAL_STATE:
            return initialState;

        /**********************************************************************************************************
         *   SSL STATES
         **********************************************************************************************************/
        case SSL_CONFIGURATION_REQUEST:
            return {
                ssl_configuration_data: null,
                ssl_configuration_error: null,
                ssl_configuration_status: 'loading'
            };

        case SSL_CONFIGURATION_SUCCESS:
            return {
                ssl_configuration_status: 'success',
                ssl_configuration_data: action.ssl_configuration_data
            };

        case SSL_CONFIGURATION_ERROR:
            return {
                ssl_configuration_status: 'error',
                ssl_configuration_error: action.ssl_configuration_error
            };

        /**********************************************************************************************************
         *   SSL CSR STATES
         **********************************************************************************************************/
        case SSL_CSR_INFORMATION_REQUEST:
            return {
                ...state,
                ssl_csr_information_data: null,
                ssl_csr_information_error: null,
                ssl_csr_information_status: 'loading'
            };

        case SSL_CSR_INFORMATION_SUCCESS:
            return {
                ...state,
                ssl_csr_information_data: action.ssl_csr_information_data,
                ssl_csr_information_status: 'success'
            };

        case SSL_CSR_INFORMATION_ERROR:
            return {
                ...state,
                ssl_csr_information_status: 'error',
                ssl_csr_information_error: action.ssl_csr_information_error
            };

        case SSL_CSR_SUBMISSION_REQUEST:
            return {
                ssl_csr_submission_data: null,
                ssl_csr_submission_error: null,
                ssl_csr_submission_status: 'loading'
            };

        case SSL_CSR_SUBMISSION_SUCCESS:
            return {
                ssl_csr_submission_data: action.ssl_csr_submission_data,
                ssl_csr_submission_status: 'success'
            };

        case SSL_CSR_SUBMISSION_ERROR:
            return {
                ssl_csr_submission_status: 'error',
                ssl_csr_submission_error: action.ssl_csr_submission_error
            };

        case SSL_CSR_CLEAR_REQUEST:
            return {
                ssl_csr_clear_data: null,
                ssl_csr_clear_error: null,
                ssl_csr_clear_status: 'loading'
            };

        case SSL_CSR_CLEAR_SUCCESS:
            return {
                ssl_csr_clear_data: action.ssl_csr_clear_data,
                ssl_csr_clear_status: 'success'
            };

        case SSL_CSR_CLEAR_ERROR:
            return {
                ssl_csr_clear_status: 'error',
                ssl_csr_clear_error: action.ssl_csr_clear_error
            };

        /**********************************************************************************************************
         *   DETAIL STATES
         **********************************************************************************************************/
        case SSL_INFORMATION_REQUEST:
            return {
                ...state,
                ssl_information_data: null,
                ssl_information_error: null,
                ssl_information_status: 'loading'
            };

        case SSL_INFORMATION_SUCCESS:
            return {
                ...state,
                ssl_information_status: 'success',
                ssl_information_data: action.ssl_information_data
            };

        case SSL_INFORMATION_ERROR:
            return {
                ...state,
                ssl_information_status: 'error',
                ssl_information_error: action.ssl_information_error
            };

        /**********************************************************************************************************
         *   SSL INSTALL
         **********************************************************************************************************/
        case SSL_INSTALL_REQUEST:
            return {
                ...state,
                ssl_install_status: 'loading',
                ssl_install_data: null,
                ssl_install_error: null
            };

        case SSL_INSTALL_SUCCESS:
            return {
                ...state,
                ssl_install_status: 'success',
                ssl_install_data: action.ssl_install_data
            };

        case SSL_INSTALL_ERROR:
            return {
                ...state,
                ssl_install_status: 'error',
                ssl_install_error: action.ssl_install_error
            };

        /**********************************************************************************************************
         *   SSL INSTALL
         **********************************************************************************************************/
        case SSL_HOSTING_SEARCH_REQUEST:
            return {
                ...state,
                ssl_hosting_search_status: 'loading',
                ssl_hosting_search_data: null,
                ssl_hosting_search_error: null
            };

        case SSL_HOSTING_SEARCH_SUCCESS:
            return {
                ...state,
                ssl_hosting_search_status: 'success',
                ssl_hosting_search_data: action.ssl_hosting_search_data
            };

        case SSL_HOSTING_SEARCH_ERROR:
            return {
                ...state,
                ssl_hosting_search_status: 'error',
                ssl_hosting_search_error: action.ssl_hosting_search_error
            };

        /**********************************************************************************************************
         *   SSL DOWNLOAD
         **********************************************************************************************************/
        case SSL_CREATE_REQUEST:
            return {
                ...state,
                ssl_create_status: 'loading',
                ssl_create_data: null,
                ssl_create_error: null
            };

        case SSL_CREATE_SUCCESS:
            return {
                ...state,
                ssl_create_status: 'success',
                ssl_create_data: action.ssl_create_data
            };

        case SSL_CREATE_ERROR:
            return {
                ...state,
                ssl_create_status: 'error',
                ssl_create_error: action.ssl_create_error
            };

        case SSL_DOWNLOAD_REQUEST:
            return {
                ...state,
                ssl_download_status: 'loading',
                ssl_download_data: null,
                ssl_download_error: null
            };

        case SSL_DOWNLOAD_SUCCESS:
            return {
                ...state,
                ssl_download_status: 'success',
                ssl_download_data: action.ssl_download_data
            };

        case SSL_DOWNLOAD_ERROR:
            return {
                ...state,
                ssl_download_status: 'error',
                ssl_download_error: action.ssl_download_error
            };

        /**********************************************************************************************************
         *   SSL BEACON
         **********************************************************************************************************/
        case SSL_BEACON_REQUEST:
            return {
                ...state,
                ssl_beacon_status: 'loading',
                ssl_beacon_data: null,
                ssl_beacon_error: null
            };

        case SSL_BEACON_SUCCESS:
            return {
                ...state,
                ssl_beacon_status: 'success',
                ssl_beacon_data: action.ssl_beacon_data
            };

        case SSL_BEACON_ERROR:
            return {
                ...state,
                ssl_beacon_status: 'error',
                ssl_beacon_error: action.ssl_beacon_error
            };

        /**********************************************************************************************************
         *   SSL VALIDATION
         **********************************************************************************************************/
        case SSL_VALIDATION_REQUEST:
            return {
                ...state,
                ssl_validation_status: 'loading',
                ssl_validation_data: null,
                ssl_validation_error: null
            };

        case SSL_VALIDATION_SUCCESS:
            return {
                ...state,
                ssl_validation_status: 'success',
                ssl_validation_data: action.ssl_validation_data
            };

        case SSL_VALIDATION_ERROR:
            return {
                ...state,
                ssl_validation_status: 'error',
                ssl_validation_error: action.ssl_validation_error
            };

        default:
            return state;
    }
}

export default sslReducer;

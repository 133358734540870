/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import classNames from 'classnames';
import React, { useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import { useBoolean } from 'usehooks-ts';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import './_HoverTooltip.scss';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * @deprecated Use `Tooltip` from `import { Tooltip } from '@mui/material';` instead
 *
 * For the sake of sanity, this component is now in __*Component Prison*__, The `Tooltip` from `'@mui/material'` has the best API and `HoverTooltip` is just a monster of a component. just don't use it.
 *
 * @type {React.FC<{
 *      children: React.ReactNode;
 *      content: React.ReactNode | string;
 *      color?: 'black';
 *      alignment?: 'left' | 'right' | 'center';
 *      below?: boolean;
 *      inline?: boolean;
 *      className?: string;
 *      usePortal?: boolean;
 *      noWrap?: boolean;
 *      preventHover?: boolean;
 * }>}
 */
const HoverTooltip = ({ children, content, color, alignment, below, inline, className, usePortal, noWrap, preventHover }) => {
    /***** STATE *****/
    const [hoverTooltipContentStyling, setHoverTooltipContentStyling] = useState({});
    const { value: isHoveringTooltip, setTrue: hoveringTooltip, setFalse: notHoveringTooltip } = useBoolean(false);

    /***** HOOKS *****/
    const hoverTooltipRef = useRef();
    const tooltipContentRef = useRef();

    /***** FUNCTIONS *****/
    /**
     * Position the tooltip content based on the HoverTooltip's position
     */
    const positionTooltipContent = () => {
        // @ts-ignore
        const hoverTooltipRect = hoverTooltipRef.current?.getBoundingClientRect();
        setHoverTooltipContentStyling({
            top: hoverTooltipRect.top,
            left: ~~(hoverTooltipRect.left + (hoverTooltipRect.width ?? 0) / 2)
        });
    };

    // @ts-ignore
    window.setHoverTooltipContentStyling = setHoverTooltipContentStyling;

    /***** EFFECTS *****/
    useEffect(() => {
        if (usePortal) {
            positionTooltipContent();
        }
    }, [isHoveringTooltip, usePortal, content]);

    useEffect(() => {
        const handleResize = () => {
            if (usePortal) {
                positionTooltipContent();
            }
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [isHoveringTooltip]);

    useEffect(() => {
        if (preventHover) {
            notHoveringTooltip();
        }
    }, [preventHover, isHoveringTooltip]);

    /***** RENDER HELPERS *****/
    const hoverTooltipClassNames = classNames('HoverTooltip', className, {
        [`HoverTooltip--${color}`]: color,
        [`HoverTooltip--${alignment}Aligned`]: alignment,
        'HoverTooltip--inline': inline,
        'HoverTooltip--noWrap': noWrap,
        'HoverTooltip--below': below,
        'HoverTooltip--preventHover': preventHover
    });

    const tooltipContent = (
        <div
            ref={tooltipContentRef}
            className={classNames('HoverTooltip__content', {
                'HoverTooltip__content--usePortal': usePortal,
                'HoverTooltip__content--isHoveringTooltip': isHoveringTooltip && !preventHover
            })}
            style={hoverTooltipContentStyling}
        >
            {content}
        </div>
    );

    /***** RENDER *****/
    return (
        <div
            ref={hoverTooltipRef}
            className={hoverTooltipClassNames}
            onFocus={hoveringTooltip}
            onMouseOver={hoveringTooltip}
            onBlur={notHoveringTooltip}
            onMouseOut={notHoveringTooltip}
        >
            <div className="HoverTooltip__children">
                {children}
                {usePortal ? ReactDOM.createPortal(tooltipContent, document.body) : tooltipContent}
            </div>
        </div>
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

export default HoverTooltip;

/**
 * Takes in a tld and its availability data and returns the value of that tld (tries to get renewal price, and falls back to register price)
 */
export function getTldValue(tldToFind, domain_availability_data) {
    const tldInfo = domain_availability_data?.find(({ tld }) => tld === tldToFind);

    const oneYearPrice =
        tldInfo?.price?.find(({ name, type }) => name === '1 Year' && type === 'renew') ||
        tldInfo?.price?.find(({ name, type }) => name === '1 Year' && type === 'register');

    const tldValue = oneYearPrice?.price || '';

    return tldValue;
}

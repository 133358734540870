/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import ContactForm from 'containers/domain/forms/contacts';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import InactiveButton from 'components/Buttons/InactiveButton';
import SolidButton from 'components/Buttons/SolidButton';
import { BUTTON_COLORS } from 'components/Buttons/_BaseButton';
import { ReduxFormButton } from 'components/Form/Button/reduxForm';
import OverlayLightbox from 'components/Lightboxes/OverlayLightbox';
import RequestLoader from 'components/Loaders/Request';
import SimpleTable from 'components/SimpleTable';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import Text from 'components/Utils/Text';

/*   ACTIONS
 *****************************************************/
import { bulkContact } from '../../action';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import { contactTypesAll } from 'containers/domain/const';
import './_contact.scss';

const formName = 'profileserviceContactForm';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type Service = {
    id: string;
    attributes: {
        domain: string;
    };
};

type ContactProps = {
    selectedServices: Array<Service>;
};

type Contact = React.FC<ContactProps>;

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
let Contact: Contact = ({ selectedServices, ...props }) => {
    const { domain_bulk_contact_action_status } = props as any;

    /***** STATE *****/
    const [confirmLightboxOpen, setConfirmLightboxOpen] = useState(false);

    /***** HOOKS *****/
    const formValues = useRef();

    /***** FUNCTIONS *****/
    function handleSubmit(values: any) {
        formValues.current = values;
        setConfirmLightboxOpen(true);
    }

    function confirmApplyContactDetails() {
        const contacts: Record<string, any> = {};

        contactTypesAll.forEach((contactType) => {
            contacts[contactType] = formValues.current;
        });

        const bulkContactInfoArray = selectedServices.map((service) => {
            return {
                id: service.id
            };
        });

        const finalData = {
            contacts,
            domain_id: bulkContactInfoArray
        };

        bulkContact(finalData);
    }

    /***** EFFECTS *****/
    useEffect(() => {
        if (domain_bulk_contact_action_status === 'success') {
            setConfirmLightboxOpen(false);
        }
    }, [domain_bulk_contact_action_status]);

    /***** RENDER HELPERS *****/
    function renderSubmitButton() {
        if (selectedServices.length === 0) return <InactiveButton>Please Select at least one domain</InactiveButton>;

        return (
            <ReduxFormButton form={formName} color={BUTTON_COLORS.CONFIRM}>
                Apply Contact Details
            </ReduxFormButton>
        );
    }

    /***** RENDER *****/
    return (
        <>
            <div className="bulkContact service__contacts">
                <div className="contacts__container">
                    <ContactForm contact="profile" resetContactDetailsOnLoad onSubmit={handleSubmit} submitButton={renderSubmitButton} />
                </div>
            </div>
            {confirmLightboxOpen ? (
                <OverlayLightbox
                    className="bulkContact__confirmLightbox"
                    title="Confirm Apply Contact Details"
                    onOpen
                    onClose={() => {
                        setConfirmLightboxOpen(false);
                    }}
                >
                    {domain_bulk_contact_action_status === 'loading' ? (
                        <RequestLoader />
                    ) : (
                        <>
                            <Text size--m>The contact details will be applied to all contact types for the following domain names:</Text>

                            <SimpleTable
                                className="bulkContact__confirmTable"
                                rows={selectedServices.map(({ attributes: { domain } }, i) => {
                                    return (
                                        <Text medium size--s key={i}>
                                            {domain}
                                        </Text>
                                    );
                                })}
                            />

                            <SolidButton type="onClick" onClick={confirmApplyContactDetails}>
                                Apply Contact Details
                            </SolidButton>
                        </>
                    )}
                </OverlayLightbox>
            ) : (
                ''
            )}
        </>
    );
};

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
const mapStateToProps = (state: any) => {
    return {
        domain_bulk_contact_action_status: state.servicesBulk.domain_bulk_contact_action_status
    };
};

Contact = connect(mapStateToProps)(Contact);

export default Contact;

/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { queryOptions, useQuery } from '@tanstack/react-query';
import { katanaQueryKeys } from 'containers/katana/queries/katanaQueryKeys';
import type { KatanaNamespace } from 'containers/katana/types';
import queryClient from 'store/queryClient';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { KATANA_API } from 'utilities/api/katana';
import { handleDefaultErrorNotification } from 'utilities/methods/commonActions';

function createQueryKey(serviceID: KatanaNamespace.ServiceID) {
    return katanaQueryKeys.katana.meta.fonts();
}

function createQueryOptions(serviceID: KatanaNamespace.ServiceID) {
    return queryOptions({
        queryKey: createQueryKey(serviceID),
        queryFn: () =>
            KATANA_API.katana.site.service_id.meta.fonts.GET(serviceID).catch((e) => {
                handleDefaultErrorNotification(e);
                throw e;
            }),
        enabled: Boolean(serviceID),
        staleTime: Infinity,
        select: (data) => {
            if (data?.status === 200) {
                return data.data;
            }
        }
    });
}

function prefetchQuery(serviceID: KatanaNamespace.ServiceID) {
    return queryClient.prefetchQuery(createQueryOptions(serviceID));
}

/**********************************************************************************************************
 *   HOOK START
 **********************************************************************************************************/
/**
 * Gets the fonts meta data for the site
 */
function _useQuery(serviceID: KatanaNamespace.ServiceID) {
    return useQuery(createQueryOptions(serviceID));
}
/**********************************************************************************************************
 *   HOOK END
 **********************************************************************************************************/

export const getFonts = Object.freeze({
    useQuery: _useQuery,
    prefetchQuery,
    createQueryKey,
    createQueryOptions
});

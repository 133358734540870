/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { useCurrentEditor } from '@tiptap/react';
import type { MouseEventHandler } from 'react';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import { PhosphorIcons } from 'components/Icons/Phosphor';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import { ToolbarButton } from 'components/Form/RichTextEditor/plugins/Toolbar/ToolbarButton/ToolbarButton';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const FormatBoldAction = () => {
    /***** HOOKS *****/
    const { editor } = useCurrentEditor();

    /***** FUNCTIONS *****/
    const onClick: MouseEventHandler<HTMLButtonElement> = (e) => {
        e.preventDefault();
        editor?.chain().focus().toggleBold().run();
    };

    /***** RENDER HELPERS *****/
    const isActive = editor?.isActive('bold');

    /***** RENDER *****/
    return (
        <ToolbarButton ariaLabel="Format Bold" onClick={onClick} isActive={isActive}>
            <PhosphorIcons.TextBold.Bold size={23} black={isActive} />
        </ToolbarButton>
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

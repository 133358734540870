/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React from 'react';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import Anchor from 'components/Anchor';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import './_ModalBackLink.scss';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
interface Props {
    children: string;
    onClick: () => void;
}

/**
 * "GO BACK" link that goes under the "Confirm" button in most lightboxes
 */
export const _ModalBackLink: React.FC<Props> = ({ children = 'GO BACK', onClick }) => {
    /***** RENDER *****/
    return (
        <Anchor className="ModalBackLink" color="primary" onClick={onClick}>
            {children}
        </Anchor>
    );
};

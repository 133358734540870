/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import Anchor from 'components/Anchor';
import SolidButton from 'components/Buttons/SolidButton';
import Text from 'components/Utils/Text';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { appEvents } from 'utilities/events';
import EVENT from 'utilities/events/states';
import { useHistory } from 'utilities/hooks/router/useHistory';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import TwoFactorLockIcon from 'assets/images/dashboard/2faLock.svg';
import { enableTwoFactorLightboxSearchParam } from 'containers/account/modules/2fa';
import './_enableTwoFactorContent.scss';

export const REMINDER_KEY = 'remind_later_2fa';
export const DISMISS_KEY = 'dismiss_2fa_prompt';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type EnableTwoFactorContent = React.FC<EnableTwoFactorContentProps>;
type EnableTwoFactorContentProps = {
    markSelfAsComplete: () => void;
};
/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * Prompts the user to enable two factor authentication. Users who do not have 2fa enabled, and have not dismissed the prompt must complete this step
 */
export const EnableTwoFactorContent: EnableTwoFactorContent = ({ markSelfAsComplete }) => {
    /***** HOOKS *****/
    const history = useHistory();

    /***** FUNCTIONS *****/
    function closePrompt() {
        localStorage.setItem(DISMISS_KEY, 'true');
        markSelfAsComplete();
    }

    function remindLater() {
        localStorage.setItem(REMINDER_KEY, Date.now().toString());
        closePrompt();
    }

    /***** RENDER *****/
    return (
        <div className="enableTwoFactorContent">
            <button className="enableTwoFactorContent__closeButton icon icon-x" onClick={closePrompt}></button>
            <div className="enableTwoFactorContent__container">
                <img src={TwoFactorLockIcon} alt="Two-Factor Authentication" />
                <Text className="enableTwoFactorContent__title" size--xl semiBold lead--xs align--center>
                    <h3>{`We've noticed that you don't have Two-Factor Authentication Enabled`}</h3>
                </Text>
                <Text secondary size--s lead--s align--center>
                    2FA is your best defence against hackers trying to access your account and we recommend you enable this security feature
                    immediately.
                </Text>
                <SolidButton
                    className="enableTwoFactorContent__enableButton"
                    type="onClick"
                    onClick={() => {
                        markSelfAsComplete();
                        history.push(`/account/security?${enableTwoFactorLightboxSearchParam}=true#two-factor`);
                    }}
                >
                    Enable Two-Factor Authentication
                </SolidButton>
            </div>
            <Anchor onClick={remindLater}>Remind me later</Anchor>
        </div>
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

appEvents.on(EVENT.LOGIN.AUTHENTICATION.REQUEST, () => {
    /**
     * Only clear the dismiss key if there actually is any otherwise we clog up local storage unnecessarily
     */
    if (localStorage.getItem(DISMISS_KEY)) {
        localStorage.removeItem(DISMISS_KEY);
    }
});

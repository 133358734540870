/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React from 'react';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import Anchor from 'components/Anchor';
import SolidButton from 'components/Buttons/SolidButton';
import Grid from 'components/Grid';
import InfoNotification from 'components/Notifications/InfoNotification';
import Text from 'components/Utils/Text';

/**********************************************************************************************************
 *   QUERIES
 **********************************************************************************************************/
import { getAvailablePaymentMethodsBoilerPlate } from 'containers/billing/queries/useGetAvailablePaymentMethodsQuery';
import { getUserPaymentMethodDataListBoilerPlate } from 'containers/billing/queries/useGetUserPaymentMethodDataListQuery';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type PaymentMethodsErrorProps = {
    invoiceID: string | number;
};

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/

export const PaymentMethodsError: React.FC<PaymentMethodsErrorProps> = ({ invoiceID }) => (
    <InfoNotification type="error">
        <Grid columns="1fr 90px">
            <Text>
                Unable to load payment methods. Please try again or{' '}
                <Anchor color="white" to={`/support/tickets/submit/accounts-billing?invoice=${invoiceID}`}>
                    <Text underline>contact our Billing team for assistance</Text>
                </Anchor>
            </Text>

            <SolidButton
                color="white"
                type="onClick"
                size="small"
                onClick={() => {
                    getAvailablePaymentMethodsBoilerPlate.invalidate();
                    getUserPaymentMethodDataListBoilerPlate.invalidate();
                }}
            >
                <Text warn>Retry</Text>
            </SolidButton>
        </Grid>
    </InfoNotification>
);
/**********************************************************************************************************
 *   HOOK END
 **********************************************************************************************************/

/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { useSortable } from '@dnd-kit/sortable';
import React from 'react';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import { _DragHandle as DragHandle } from 'components/DragHandle/_DragHandle';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * @type {React.FC<{
 *      id: string | number;
 *      fullHeight?:boolean;
 * }>}
 */
const _DragAndDropSortableDragHandler = ({ id, fullHeight }) => {
    /***** HOOKS *****/
    const { attributes, listeners } = useSortable({ id });
    /***** RENDER *****/
    return <DragHandle divProps={{ ...attributes, ...listeners }} fullHeight={fullHeight} />;
};

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
export { _DragAndDropSortableDragHandler };

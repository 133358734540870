import RequestLoader from 'components/Loaders/Request';
import { KatanaHookFormHandler } from 'containers/katana/components/HookFormHandler';
import { useFinaliseDefaultFormValues } from 'containers/katana/containers/ContentEditorLightbox/formHandlers/finaliseFormHandler/useFinaliseDefaultFormValues';
import { useFinaliseSubmit } from 'containers/katana/containers/ContentEditorLightbox/formHandlers/finaliseFormHandler/useFinaliseSubmit';
import type { KatanaNamespace } from 'containers/katana/types';
import { requiredFieldErrorMessage } from 'utilities/methods/form';
import { z } from 'zod';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
const finaliseZodSchema = z
    .object({
        title: z.string({ message: requiredFieldErrorMessage }),
        description: z.string({ message: requiredFieldErrorMessage }),
        favicon: z.union([z.instanceof(File).nullable().optional(), z.string().nullable().optional()]),
        favIconOpen: z.boolean().nullable().optional()
    })
    .superRefine(({ favIconOpen, favicon }, context) => {
        if (favIconOpen && !favicon) {
            context.addIssue({
                code: 'custom',
                message: requiredFieldErrorMessage,
                path: ['favicon']
            });
        }
    });

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type Props = {
    subpageRouteData: KatanaNamespace.SubPageRouteData;
};

export const FinaliseFormHandler: React.FC<Props> = ({ subpageRouteData }) => {
    /***** HOOKS *****/
    const defaultValues = useFinaliseDefaultFormValues(subpageRouteData);
    const handleFinaliseSubmit = useFinaliseSubmit(subpageRouteData);

    /***** RENDER *****/
    if (!defaultValues) {
        return <RequestLoader message="Loading Basic Details..." />;
    }

    return (
        <KatanaHookFormHandler defaultValues={defaultValues} zodSchema={finaliseZodSchema} handleOnSubmit={handleFinaliseSubmit}>
            {subpageRouteData.modules}
        </KatanaHookFormHandler>
    );
};

import { _RequestLoader as RequestLoader } from 'components/Loaders/Request/_requestLoader';
import React, { Suspense } from 'react';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type Props = NRequestLoader.Props & {
    children: React.ReactNode;
};

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const _RequestLoaderSuspenseBoundary: React.FC<Props> = ({ children, ...restProps }) => (
    <Suspense fallback={<RequestLoader {...restProps} />}>{children}</Suspense>
);

import axios from 'axios';

export let CancelPreview;

export const API = {
    /**********************************************************************************************************
     *   VIP REWARDS API
     **********************************************************************************************************/
    vipRewards: {
        GET: {
            tiers: () => axios.get('/api/user/rewards/list')
        },

        POST: {
            signUp: () => axios.get('/api/user/rewards/signup')
        }
    }
};

/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import classNames from 'classnames';
import React from 'react';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import { Flex } from 'components/Utils/Flex';
/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import './_ControlCircle.scss';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * @type {React.FC<ControlCircle.Props>}
 */
const ControlCircle = ({ ControlIcon, hoverColour, onClick, children }) => {
    const size = 11;
    return (
        <Flex inject justify="center" items="center">
            <button
                type="button"
                className={classNames('ControlCircle', {
                    [`ControlCircle--hoverColour-${hoverColour}`]: hoverColour
                })}
                onClick={onClick}
            >
                {children ? children({ size, black: true }) : <ControlIcon size={size} black />}
            </button>
        </Flex>
    );
};

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

export { ControlCircle };

import { queryOptions, useQuery } from '@tanstack/react-query';
import { katanaQueryKeys } from 'containers/katana/queries/katanaQueryKeys';
import type { KatanaNamespace } from 'containers/katana/types';
import queryClient from 'store/queryClient';
import { KATANA_API } from 'utilities/api/katana';
import type { KatanaAPI } from 'utilities/api/katana/types';

function createQueryKey(params: KatanaAPI.Katana.ServiceID.Preview.Site.POST.Params) {
    return katanaQueryKeys.katana.service.ID.preview.site(params);
}

function createQueryOptions({ serviceID, attributes }: KatanaAPI.Katana.ServiceID.Preview.Site.POST.Params, enabled = true) {
    return queryOptions({
        queryKey: createQueryKey({ serviceID, attributes }),
        queryFn: ({ signal }) => KATANA_API.katana.service_id.preview.site.POST({ serviceID, attributes }, signal),
        staleTime: Infinity,
        enabled: Boolean(serviceID) && enabled,
        select: (data) => {
            if (typeof data === 'string') {
                return data;
            }
        }
    });
}

function prefetchQuery(data: KatanaAPI.Katana.ServiceID.Preview.Site.POST.Params) {
    return queryClient.prefetchQuery(createQueryOptions(data));
}

// TODO: invalidate this query when any of the service data has changed
function invalidateQueries(serviceID: KatanaNamespace.ServiceID) {
    return queryClient.invalidateQueries({ queryKey: createQueryKey({ serviceID }) });
}
/**********************************************************************************************************
 *   HOOK START
 **********************************************************************************************************/
function _useQuery(data: KatanaAPI.Katana.ServiceID.Preview.Site.POST.Params, enabled?: boolean) {
    return useQuery(createQueryOptions(data, enabled));
}
/**********************************************************************************************************
 *   HOOK END
 **********************************************************************************************************/

export const getSitePreview = Object.freeze({
    useQuery: _useQuery,
    prefetchQuery,
    invalidateQueries,
    createQueryKey,
    createQueryOptions
});

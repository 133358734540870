/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { useCallback } from 'react';
import { useTanstackStore } from 'utilities/hooks/tanstack-store/useTanstackStore';

/**********************************************************************************************************
 *   TYPE IMPORTS
 **********************************************************************************************************/
import type { Store } from '@tanstack/react-store';

/**********************************************************************************************************
 *   HOOK START
 **********************************************************************************************************/
/**
 * Automatically updates the value of the state when the original store input value changes.
 */
export function useBooleanTanstackStore(store: Store<boolean>) {
    /***** STATE *****/
    const [storeState, setState, updateStore] = useTanstackStore(store);

    /***** FUNCTIONS *****/
    const setTrue = useCallback(() => setState(true), []);
    const setFalse = useCallback(() => setState(false), []);
    const toggle = useCallback(() => updateStore((v) => !v), []);

    /***** HOOK RESULTS *****/
    return {
        value: storeState,
        setTrue,
        setFalse,
        toggle,
        setValue: setState
    };
}
/**********************************************************************************************************
 *   HOOK END
 **********************************************************************************************************/

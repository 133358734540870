/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { useSelector } from 'react-redux';

/**
 * Intended for use within the StaffMenu component.
 * This component will only render the component when it is in development mode.
 */
export const DeveloperItem = ({ component: Component }: { component: React.ElementType }) => {
    if (!import.meta.env.DEV) {
        return null;
    }

    return <Component />;
};

/**
 * Intended for use within the StaffMenu component.
 * This component will only render the component when the user is connected to the VPN.
 */
export const StaffItem = ({ component: Component }: { component: React.ElementType }) => {
    /***** HOOKS *****/
    const { app_check_token_data } = useSelector((state: any) => state.app);

    if (app_check_token_data?.is_vpn) {
        return <Component />;
    }

    return null;
};

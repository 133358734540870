/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React, { useState } from 'react';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import Anchor from 'components/Anchor';
import BackgroundImage from 'components/BackgroundImage';
import BannerCarousel from 'components/Promo/Carousel/Banner';
import WithAnimationLoop from 'components/Promo/Carousel/Banner/WithAnimationLoop';
import Text from 'components/Utils/Text';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { useAppViewport } from 'utilities/hooks/useAppViewport/useAppViewport';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import { megaMayBlogPostLink } from '../deals';

import Arrow from '../../assets/arrow.svg';
import BackgroundMobile from '../../assets/dashboard-banner-background-mobile.png';
import BackgroundTablet from '../../assets/dashboard-banner-background-tablet.png';
import Background from '../../assets/dashboard-banner-background.svg';
import Logo from '../../assets/dashboard-banner-logo.svg';
import Tape from '../../assets/tape-green-services-banner.svg';

import './_megamay24ServicesBanner.scss';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export function MegaMayServicesBanner({ panels }) {
    /***** STATE *****/
    // Just used in "triggerNewLocalStorageRead"
    const [, forceRerender] = useState({});

    /***** HOOKS *****/
    const isMobile = useAppViewport(['sm', 'xs']);
    const isTablet = useAppViewport(['md']);

    /***** FUNCTIONS *****/
    /**
     * This banner needs to render nothing if all its panels are not being shown (which is dictated by a combo of "showCondition" and if it's dismissed in local storage). But for the whole banner to disappear when the last panel gets dismissed, we need to read the new local storage values every time one of them changes. This state is just to trigger a rerender "onDismiss" of any of the panels, so that we can immediately derive the new number of showing panels.
     */
    function triggerNewLocalStorageRead() {
        // Need to set timeout before forcing rerender in order to get the updated local storage read after dismiss
        setTimeout(() => {
            forceRerender({});
        });
    }

    /***** RENDER HELPERS *****/
    function getBackgroundImage() {
        if (isMobile) {
            return BackgroundMobile;
        }
        if (isTablet) {
            return BackgroundTablet;
        }
        return Background;
    }

    /***** RENDER *****/
    const numOfNonDismissedPanels = panels.filter(({ showCondition, dismissKey }) => showCondition && !localStorage.getItem(dismissKey)).length;

    if (numOfNonDismissedPanels <= 0) {
        return null;
    }

    return (
        <div>
            <BackgroundImage className="mmServicesBanner" imageURL={getBackgroundImage()} cover>
                <div className="mmServicesBanner__content">
                    <img className="mmServicesBanner__logo" src={Logo} alt="MegaMay Spin to Win" />
                    {isMobile && numOfNonDismissedPanels > 1 ? (
                        <div className="mmServicesBanner__swipeInstructions">
                            <img src={Arrow} alt="Previous" />
                            <Text white size--s bold>
                                Swipe cards to see more offers
                            </Text>
                            <img src={Arrow} alt="Next" />
                        </div>
                    ) : (
                        ''
                    )}
                    <div className="mmServicesBanner__tape">
                        <img className="mmServicesBanner__tapeImg" src={Tape} alt="Background color green" />
                        <WithAnimationLoop>
                            {(elapsed, start, pause, resume, restart) => (
                                <BannerCarousel
                                    initialBanners={panels}
                                    elapsed={elapsed}
                                    start={start}
                                    pause={pause}
                                    resume={resume}
                                    restart={restart}
                                    dots
                                    arrows={!isMobile}
                                    onDismiss={() => triggerNewLocalStorageRead()}
                                />
                            )}
                        </WithAnimationLoop>
                    </div>
                </div>
            </BackgroundImage>
            <Text className="mmServicesBanner__tandc" secondary italic size--xs align--right lead--s>
                *Terms and Conditions apply. Check out our{' '}
                <Anchor href={megaMayBlogPostLink} target="_blank">
                    blog post
                </Anchor>{' '}
                for more information.
            </Text>
        </div>
    );
}
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

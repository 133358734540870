import { pushNotification } from 'components/Toast/functions';
import { API, CancelPreview } from 'utilities/api/ventraSynMigration';
import { createDownloadFile, getDataFromSuccessResponse, getErrorFromFailResponse } from 'utilities/methods/commonActions';

export const VSM_INITIAL_STATE = 'vsm/VSM_INITIAL_STATE';
export const VSM_START_OVER = 'vsm/VSM_START_OVER';

export const VSM_REQUIRES_MIGRATION_REQUEST = 'vsm/VSM_REQUIRES_MIGRATION_REQUEST';
export const VSM_REQUIRES_MIGRATION_SUCCESS = 'vsm/VSM_REQUIRES_MIGRATION_SUCCESS';
export const VSM_REQUIRES_MIGRATION_ERROR = 'vsm/VSM_REQUIRES_MIGRATION_ERROR';

export const VSM_RESELLER_LIST_REQUEST = 'vsm/VSM_RESELLER_LIST_REQUEST';
export const VSM_RESELLER_LIST_SUCCESS = 'vsm/VSM_RESELLER_LIST_SUCCESS';
export const VSM_RESELLER_LIST_ERROR = 'vsm/VSM_RESELLER_LIST_ERROR';

export const VSM_SUBACCOUNT_LIST_REQUEST = 'vsm/VSM_SUBACCOUNT_LIST_REQUEST';
export const VSM_SUBACCOUNT_LIST_SUCCESS = 'vsm/VSM_SUBACCOUNT_LIST_SUCCESS';
export const VSM_SUBACCOUNT_LIST_ERROR = 'vsm/VSM_SUBACCOUNT_LIST_ERROR';

export const VSM_PREVIEW_REQUEST = 'vsm/VSM_PREVIEW_REQUEST';
export const VSM_PREVIEW_SUCCESS = 'vsm/VSM_PREVIEW_SUCCESS';
export const VSM_PREVIEW_ERROR = 'vsm/VSM_PREVIEW_ERROR';

export const VSM_MIGRATE_REQUEST = 'vsm/VSM_MIGRATE_REQUEST';
export const VSM_MIGRATE_SUCCESS = 'vsm/VSM_MIGRATE_SUCCESS';
export const VSM_MIGRATE_ERROR = 'vsm/VSM_MIGRATE_ERROR';

export const VSM_SYNERGY_LOGIN_REQUEST = 'vsm/VSM_SYNERGY_LOGIN_REQUEST';
export const VSM_SYNERGY_LOGIN_SUCCESS = 'vsm/VSM_SYNERGY_LOGIN_SUCCESS';
export const VSM_SYNERGY_LOGIN_ERROR = 'vsm/VSM_SYNERGY_LOGIN_ERROR';

export const VSM_SYNERGY_REGISTER_REQUEST = 'vsm/VSM_SYNERGY_REGISTER_REQUEST';
export const VSM_SYNERGY_REGISTER_SUCCESS = 'vsm/VSM_SYNERGY_REGISTER_SUCCESS';
export const VSM_SYNERGY_REGISTER_ERROR = 'vsm/VSM_SYNERGY_REGISTER_ERROR';

export const VSM_PCI_CSV_REQUEST = 'vsm/VSM_PCI_CSV_REQUEST';
export const VSM_PCI_CSV_SUCCESS = 'vsm/VSM_PCI_CSV_SUCCESS';
export const VSM_PCI_CSV_ERROR = 'vsm/VSM_PCI_CSV_ERROR';

export const VSM_MIGRATION_DATES_REQUEST = 'vsm/VSM_MIGRATION_DATES_REQUEST';
export const VSM_MIGRATION_DATES_SUCCESS = 'vsm/VSM_MIGRATION_DATES_SUCCESS';
export const VSM_MIGRATION_DATES_ERROR = 'vsm/VSM_MIGRATION_DATES_ERROR';

/**********************************************************************************************************
 *   VSM START OVER
 **********************************************************************************************************/

export const clearVsmState = () => {
    return (dispatch) => {
        dispatch({ type: VSM_START_OVER });
    };
};

/**********************************************************************************************************
 *   VSM REQUIRES MIGRATION
 **********************************************************************************************************/

export const checkRequiresMigration = () => {
    return (dispatch) => {
        dispatch({
            type: VSM_REQUIRES_MIGRATION_REQUEST
        });
        API.vsMigration.GET.requires()
            .then((response) => {
                const vsm_requires_migration_data = getDataFromSuccessResponse(response);
                dispatch({
                    type: VSM_REQUIRES_MIGRATION_SUCCESS,
                    vsm_requires_migration_data
                });
            })
            .catch((error) => {
                const vsm_requires_migration_error = getErrorFromFailResponse(error);
                dispatch({
                    type: VSM_REQUIRES_MIGRATION_ERROR,
                    vsm_requires_migration_error
                });
            });
    };
};

/**********************************************************************************************************
 *   VSM RESELLER LIST
 **********************************************************************************************************/

export const getMigratableResellerAccounts = () => {
    return (dispatch) => {
        dispatch({
            type: VSM_RESELLER_LIST_REQUEST
        });
        API.vsMigration.GET.resellers()
            .then((response) => {
                const vsm_reseller_list_data = getDataFromSuccessResponse(response);
                dispatch({
                    type: VSM_RESELLER_LIST_SUCCESS,
                    vsm_reseller_list_data
                });
            })
            .catch((error) => {
                const vsm_reseller_list_error = getErrorFromFailResponse(error);
                dispatch({
                    type: VSM_RESELLER_LIST_ERROR,
                    vsm_reseller_list_error
                });
            });
    };
};

/**********************************************************************************************************
 *   VSM SUBACCOUNT LIST
 **********************************************************************************************************/

export const getResellerSubaccounts = (resellerAccountId, force) => {
    return (dispatch) => {
        dispatch({
            type: VSM_SUBACCOUNT_LIST_REQUEST
        });
        API.vsMigration.GET.subaccounts(resellerAccountId, force)
            .then((response) => {
                const vsm_subaccount_list_data = getDataFromSuccessResponse(response);
                dispatch({
                    type: VSM_SUBACCOUNT_LIST_SUCCESS,
                    vsm_subaccount_list_data
                });
            })
            .catch((error) => {
                const vsm_subaccount_list_error = getErrorFromFailResponse(error);
                dispatch({
                    type: VSM_SUBACCOUNT_LIST_ERROR,
                    vsm_subaccount_list_error
                });
            });
    };
};

/**********************************************************************************************************
 *   VSM PREVIEW
 **********************************************************************************************************/

export const getMigrationCostPreview = (services) => {
    return (dispatch) => {
        if (CancelPreview) CancelPreview();
        dispatch({
            type: VSM_PREVIEW_REQUEST
        });
        API.vsMigration.POST.preview(services)
            .then((response) => {
                const resellerAccountsArray = getDataFromSuccessResponse(response);

                dispatch({
                    type: VSM_PREVIEW_SUCCESS,
                    vsm_preview_data: {
                        services: resellerAccountsArray,
                        total_cost: resellerAccountsArray
                            .map((item) => item.accounts.reduce((prev, curr) => Number(prev) + Number(curr.new_price), 0))
                            .reduce((curr, prev) => prev + curr, 0)
                    }
                });
            })
            .catch((error) => {
                const vsm_preview_error = getErrorFromFailResponse(error);
                if (vsm_preview_error.details !== 'Response Cancelled') {
                    dispatch({
                        type: VSM_PREVIEW_ERROR,
                        vsm_preview_error
                    });
                }
            });
    };
};

/**********************************************************************************************************
 *   VSM MIGRATE
 **********************************************************************************************************/

export const migrateServices = (attributes) => {
    return (dispatch) => {
        dispatch({
            type: VSM_MIGRATE_REQUEST
        });
        const { services, additional_info, delete_data, email_reconfig, migration_date, pci_compliant } = attributes;
        const payload = {
            services,
            additional_info,
            delete_data,
            email_reconfig,
            migration_date,
            pci_compliant
        };
        API.vsMigration.POST.migrate(payload)
            .then((response) => {
                const vsm_migrate_data = getDataFromSuccessResponse(response);
                dispatch({
                    type: VSM_MIGRATE_SUCCESS,
                    vsm_migrate_data
                });
            })
            .catch((error) => {
                const vsm_migrate_error = getErrorFromFailResponse(error);
                dispatch({
                    type: VSM_MIGRATE_ERROR,
                    vsm_migrate_error
                });
            });
    };
};

/**********************************************************************************************************
 *   VSM SYNERGY LOGIN
 **********************************************************************************************************/

export const synergyLogin = (credentials, migrateAfter, attributes) => {
    return (dispatch) => {
        dispatch({
            type: VSM_SYNERGY_LOGIN_REQUEST
        });
        API.vsMigration.POST.login(credentials)
            .then((response) => {
                const vsm_synergy_login_data = getDataFromSuccessResponse(response);
                dispatch({
                    type: VSM_SYNERGY_LOGIN_SUCCESS,
                    vsm_synergy_login_data
                });
                // If its coming from the lightbox on the create/link account page, process migration after
                if (migrateAfter) dispatch(migrateServices(attributes));
            })
            .catch((error) => {
                const vsm_synergy_login_error = getErrorFromFailResponse(error);
                vsm_synergy_login_error.credentials = credentials;
                pushNotification(vsm_synergy_login_error);
                dispatch({
                    type: VSM_SYNERGY_LOGIN_ERROR,
                    vsm_synergy_login_error
                });
            });
    };
};

/**********************************************************************************************************
 *   VSM SYNERGY REGISTER
 **********************************************************************************************************/

export const synergyRegister = (attributes) => {
    const { services, username, password, additional_info, delete_data, email_reconfig, migration_date, pci_compliant } = attributes;

    return (dispatch) => {
        dispatch({
            type: VSM_SYNERGY_REGISTER_REQUEST
        });
        // First register for a new synergy account
        API.vsMigration.POST.register({ username, password })
            .then((response) => {
                // Then process migration if registration successful, before setting register status to success so that create account page stays as loading
                const payload = {
                    services,
                    additional_info,
                    delete_data,
                    email_reconfig,
                    migration_date,
                    pci_compliant
                };
                dispatch(migrateServices(payload));
                const vsm_synergy_register_data = getDataFromSuccessResponse(response);
                dispatch({
                    type: VSM_SYNERGY_REGISTER_SUCCESS,
                    vsm_synergy_register_data
                });
            })
            .catch((error) => {
                const vsm_synergy_register_error = getErrorFromFailResponse(error);
                pushNotification(vsm_synergy_register_error);
                dispatch({
                    type: VSM_SYNERGY_REGISTER_ERROR,
                    vsm_synergy_register_error
                });
            });
    };
};

/**********************************************************************************************************
 *   VSM DOWNLOAD PCI CSV
 **********************************************************************************************************/

export const getPciComplianceCsv = (services) => {
    return (dispatch) => {
        dispatch({
            type: VSM_PCI_CSV_REQUEST
        });
        API.vsMigration.POST.pciCsv(services)
            .then((response) => {
                const vsm_pci_csv_data = getDataFromSuccessResponse(response);
                dispatch({
                    type: VSM_PCI_CSV_SUCCESS,
                    vsm_pci_csv_data
                });
                createDownloadFile(vsm_pci_csv_data, 'affected_emails.csv');
            })
            .catch((error) => {
                const vsm_pci_csv_error = getErrorFromFailResponse(error);
                pushNotification({ status: 401, details: 'Failed to download CSV file. Try again later or contact support.' });
                dispatch({
                    type: VSM_PCI_CSV_SUCCESS,
                    vsm_pci_csv_error
                });
            });
    };
};

/**********************************************************************************************************
 *   VSM MIGRATION DATES
 **********************************************************************************************************/

export const getMigrationDates = () => {
    return (dispatch) => {
        dispatch({
            type: VSM_MIGRATION_DATES_REQUEST
        });
        API.vsMigration.GET.migrationDates()
            .then((response) => {
                const vsm_migration_dates_data = getDataFromSuccessResponse(response);
                dispatch({
                    type: VSM_MIGRATION_DATES_SUCCESS,
                    vsm_migration_dates_data
                });
            })
            .catch((error) => {
                dispatch({
                    type: VSM_MIGRATION_DATES_ERROR
                });
            });
    };
};

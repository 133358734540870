/*   ACTIONS
 *****************************************************/
import { checkPromoCodeStatus } from './action';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { getIncludedObjBasedOnType, textLowerCase, toLuxonDate } from 'utilities/methods/commonActions';

/**
 * Check a yearly domain promo-code
 * @param {string} promo_code
 * @param {string} tld
 */
export function checkYearPromoCode(promo_code, tld) {
    const checkCodeData = {
        promo_code,
        tld,
        billing_cycle_id: 8,
        invoice_type: 'new'
    };
    checkPromoCodeStatus(checkCodeData);
}

/**
 * Checks if the promo code is eligible to be used.
 * @param {string} promo_code
 * @param {object} promotion_promo_code_status_request_data
 * @returns {boolean}
 */
export function checkPromoCodeEligibility(promo_code, promotion_promo_code_status_request_data) {
    if (
        promotion_promo_code_status_request_data?.[promo_code]?.status === 'success' &&
        (promotion_promo_code_status_request_data?.[promo_code]?.response_data?.uses_remaining === 'unlimited' ||
            Number(promotion_promo_code_status_request_data?.[promo_code]?.response_data?.uses_remaining) > 0)
    ) {
        return true;
    }

    return false;
}

/**********************************************************************************************************
 *   Service Check Helper Functions
 **********************************************************************************************************/
/**
 * Checks if the provided service is a reseller
 * @param {Object} service
 */
export function isServiceReseller(service) {
    return (
        service?.attributes?.is_reseller === true ||
        service?.attributes?.product?.service_type?.includes('reseller') === true ||
        service?.included?.some((included_service) => included_service?.attributes?.is_reseller === true)
    );
}

/**
 * Checks if the service is a specific type
 * @param {Object} service
 * @param {string} type
 * @returns {boolean}
 */
export function checkProductIsType(service, type) {
    const serviceType = service?.attributes?.product?.service_type?.toLowerCase();
    const testType = type.toLowerCase();
    const isType = serviceType === testType;
    return isType;
}

/**
 * Checks if the service is a specific status
 * @param {Object} service
 * @param {string} status
 * @returns {boolean}
 */
export function checkWebHostingProductStatus(service, status) {
    return service?.attributes?.domain_status?.toLowerCase() === status.toLowerCase();
}

/**
 * Checks if the service renewal is before a certain date
 * @param {Object} service
 * @param {import('luxon').DateTime} dateObject - Luxon DateTime object
 * @returns
 */
export function isServiceRenewalBeforeDate(service, dateObject) {
    // check service expiry
    const expiryDate = service?.expiry || service?.expiry_date || service?.attributes?.expiry || service?.attributes?.expiry_date;
    if (expiryDate) {
        const parsedDate = toLuxonDate(expiryDate);
        const isBeforeDate = parsedDate < dateObject;
        return isBeforeDate;
    }

    // check service due date
    const dueDate = service?.next_due_date || service?.attributes?.next_due_date;
    if (dueDate) {
        const parsedDate = toLuxonDate(dueDate);
        const isBeforeDate = parsedDate < dateObject;
        return isBeforeDate;
    }

    return false;
}

/**
 * Checks if the service is a select hosting
 * @param {Object} service
 * @returns
 */
export function checkIsSelectHosting(service) {
    return textLowerCase(getIncludedObjBasedOnType(service?.included, 'product')?.attributes?.name) === 'select hosting';
}

/**
 * Checks if the service is a VPS
 * @param {Object} service
 * @returns
 */
export function checkIsVps(service) {
    return textLowerCase(getIncludedObjBasedOnType(service?.included, 'product')?.attributes?.service_type) === 'vps';
}

/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import store from 'store/store';

/*   ACTIONS
 *****************************************************/
import { domainSearch, resetDomainSearch } from 'containers/services/action';
import { cancelSearchDomain } from 'utilities/api/services';
import { bulkListDomains } from '../../action';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import Anchor from 'components/Anchor';
import RequestLoader from 'components/Loaders/Request';
import Search from 'components/Search';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import DomainSearchList from '../domainSearchList';
import { DomainSelectListItem } from './listItem';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { filterDomainBulkListData } from '../../methods';

/**********************************************************************************************************
 *   TYPE IMPORTS
 **********************************************************************************************************/
import type { DomainSelectListProps } from 'containers/services/Bulk/Components/domainSelectList/types';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
let DomainSelectList: React.FC<DomainSelectListProps> = ({
    preselected = [],
    onUpdateSelectedServices,
    servicesFilter,
    disabledServices,
    /**
     * Redux Props
     */
    domain_bulk_list_status,
    domain_bulk_list_data,
    domain_search_status
}) => {
    const { dispatch } = store;

    /***** STATE *****/
    const [filteredDomainServicesList, setFilteredDomainServicesList] = useState([]);
    const [selectedServicesIDList, setSelectedServicesIDList] = useState(preselected);

    const selectedServices = filteredDomainServicesList.filter((service) => selectedServicesIDList.includes(service.id));
    const unselectedServices = filteredDomainServicesList.filter((service) => !selectedServicesIDList.includes(service.id));

    /***** FUNCTIONS *****/
    function toggleIsServiceSelected(id) {
        const newSelectedServicesIDList = [...selectedServicesIDList];
        if (newSelectedServicesIDList.includes(id)) {
            newSelectedServicesIDList.splice(selectedServicesIDList.indexOf(id), 1);
        } else {
            newSelectedServicesIDList.push(id);
        }
        setSelectedServicesIDList(newSelectedServicesIDList);
    }

    function removeAll() {
        setFilteredDomainServicesList(filterDomainBulkListData(domain_bulk_list_data));
        setSelectedServicesIDList([]);
    }

    function addSearchResult(id) {
        const searchService = filteredDomainServicesList.find((service) => service.id === id);

        if (!searchService) {
            return;
        }
        toggleIsServiceSelected(searchService.id);
    }

    function searchDomainList(keyword) {
        if (cancelSearchDomain) {
            cancelSearchDomain();
        }

        dispatch(domainSearch(keyword));
    }

    /***** EFFECTS *****/
    useEffect(() => {
        dispatch(bulkListDomains());
    }, []);

    useEffect(() => {
        onUpdateSelectedServices?.(selectedServices);
    }, [filteredDomainServicesList, selectedServicesIDList]);

    useEffect(() => {
        if (!disabledServices) {
            return;
        }

        const filteredSelected = filteredDomainServicesList.filter((service) => disabledServices.some(({ condition }) => condition(service)));
        const newSelectedServicesIDs = selectedServicesIDList.filter((selectedID) => !filteredSelected.find(({ id }) => id === selectedID));

        setSelectedServicesIDList(newSelectedServicesIDs);
    }, [disabledServices, filteredDomainServicesList]);

    useEffect(() => {
        if (domain_bulk_list_status !== 'success' || !domain_bulk_list_data) return;

        // filter out .au domains that are either pending contention or pending application details
        const filteredDomainServices = filterDomainBulkListData(domain_bulk_list_data);
        setFilteredDomainServicesList(filteredDomainServices);
    }, [domain_bulk_list_status, domain_bulk_list_data]);

    /***** RENDER HELPERS *****/
    function renderUnselectedServices() {
        if (unselectedServices?.length > 0) {
            return (
                <div className="bulkActions__serviceList--available">
                    {unselectedServices.map((service, index) => (
                        <DomainSelectListItem
                            isChecked={false}
                            key={service.id}
                            service={service}
                            index={index}
                            disabledServices={disabledServices}
                            onClick={toggleIsServiceSelected}
                        />
                    ))}
                </div>
            );
        }
        return '';
    }

    function renderSelectedServices() {
        if (selectedServices?.length > 0) {
            return (
                <>
                    <div className="bulkActions__serviceList--topActions">
                        <div className="amount">{`${selectedServices.length} domain${selectedServices.length > 1 ? 's' : ''} selected`}</div>
                        <div className="action">
                            <Anchor onClick={removeAll}>Clear</Anchor>
                        </div>
                    </div>
                    <div className="bulkActions__serviceList--selected">
                        {selectedServices.map((service, index) => (
                            <DomainSelectListItem
                                isChecked={true}
                                key={service.id}
                                service={service}
                                index={index}
                                onClick={toggleIsServiceSelected}
                            />
                        ))}
                    </div>
                </>
            );
        }
        return '';
    }

    /***** RENDER *****/
    return (
        <>
            {renderSelectedServices()}
            <div className={`bulkActions__serviceList--search ${selectedServices && selectedServices.length > 0 ? '' : 'noDomains'}`}>
                <Search
                    render={{
                        status: domain_search_status,
                        placeholder: 'Search for a domain name',
                        list: (
                            <DomainSearchList
                                selectedServices={selectedServices}
                                resultItemOnClick={addSearchResult}
                                servicesFilter={servicesFilter}
                                disabledServices={disabledServices}
                            />
                        )
                    }}
                    onClickAway={() => dispatch(resetDomainSearch())}
                    functions={{
                        cancel: cancelSearchDomain,
                        search: searchDomainList,
                        reset: () => dispatch(resetDomainSearch())
                    }}
                />
            </div>

            {domain_bulk_list_status === 'loading' && !domain_bulk_list_data ? (
                <RequestLoader message="Loading Domains" />
            ) : (
                renderUnselectedServices()
            )}
        </>
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

const mapStateToProps = (state: any) => ({
    domain_bulk_list_status: state.servicesBulk.domain_bulk_list_status,
    domain_bulk_list_data: state.servicesBulk.domain_bulk_list_data,
    domain_search_status: state.services.domain_search_status
});

DomainSelectList = connect(mapStateToProps)(DomainSelectList);

export default DomainSelectList;

/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { useEffect } from 'react';
import { useSelector } from 'react-redux';

/*   ACTIONS
 *****************************************************/
import { getLocalStates } from 'App/action';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/

type Option = {
    label: string;
    value: string;
};
type LocalStatesState = {
    app?: {
        app_local_states_data: {
            rawStatesData: Artah.LocalState.ID.GET._200['data'];
            processedOptions: {
                au: Option[];
                nz: Option[];
            };
        };
        app_local_states_status: 'loading' | 'success' | 'error' | null;
    };
};

/**********************************************************************************************************
 *   HOOK START
 **********************************************************************************************************/
export function useLocalStates() {
    /***** HOOKS *****/
    const app_local_states_data = useSelector((state: LocalStatesState) => state.app?.app_local_states_data);
    const app_local_states_status = useSelector((state: LocalStatesState) => state.app?.app_local_states_status);

    /***** EFFECTS *****/
    useEffect(() => {
        if (app_local_states_status !== 'loading' && !app_local_states_data) {
            getLocalStates();
        }
    }, [app_local_states_status, app_local_states_data]);

    /***** HOOK RESULTS *****/
    return { app_local_states_status, app_local_states_data };
}

/**********************************************************************************************************
 *   HOOK END
 **********************************************************************************************************/

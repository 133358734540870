/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import store from 'store/store';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { pushNotification } from 'components/Toast/functions';
import { API as emailAPI } from 'utilities/api/email';
import { API as servicesAPI } from 'utilities/api/services';
import { API, cancelSearchResolvedTickets } from 'utilities/api/support';
import {
    createDownloadFile,
    getDataFromSuccessResponse,
    getErrorFromFailResponse,
    getMetaFromSuccessResponse
} from 'utilities/methods/commonActions';

/**********************************************************************************************************
 *   DECLARATIONS
 **********************************************************************************************************/
export const SUPPORT_INITIAL_STATE = 'support/SUPPORT_INITIAL_STATE';

export const SUPPORT_KNOWLEDGE_BASE_CATEGORY_REFS = 'support/SUPPORT_KNOWLEDGE_BASE_CATEGORY_REFS';

export const SUPPORT_KNOWLEDGE_BASE_REQUEST = 'support/SUPPORT_KNOWLEDGE_BASE_REQUEST';
export const SUPPORT_KNOWLEDGE_BASE_SUCCESS = 'support/SUPPORT_KNOWLEDGE_BASE_SUCCESS';
export const SUPPORT_KNOWLEDGE_BASE_ERROR = 'support/SUPPORT_KNOWLEDGE_BASE_ERROR';

export const SUPPORT_KNOWLEDGE_BASE_ALL = 'support/SUPPORT_KNOWLEDGE_BASE_ALL';

export const SUPPORT_TICKETS_OPEN_REQUEST = 'support/SUPPORT_TICKETS_OPEN_REQUEST';
export const SUPPORT_TICKETS_OPEN_SUCCESS = 'support/SUPPORT_TICKETS_OPEN_SUCCESS';
export const SUPPORT_TICKETS_OPEN_ERROR = 'support/SUPPORT_TICKETS_OPEN_ERROR';

export const SUPPORT_INDIVIDUAL_TICKET_REQUEST = 'support/SUPPORT_INDIVIDUAL_TICKET_REQUEST';
export const SUPPORT_INDIVIDUAL_TICKET_SUCCESS = 'support/SUPPORT_INDIVIDUAL_TICKET_SUCCESS';
export const SUPPORT_INDIVIDUAL_TICKET_ERROR = 'support/SUPPORT_INDIVIDUAL_TICKET_ERROR';

export const SUPPORT_TICKET_UPDATE_FEEDBACK_STATUS_REQUEST = 'support/SUPPORT_TICKET_UPDATE_FEEDBACK_STATUS_REQUEST';
export const SUPPORT_TICKET_UPDATE_FEEDBACK_STATUS_SUCCESS = 'support/SUPPORT_TICKET_UPDATE_FEEDBACK_STATUS_SUCCESS';
export const SUPPORT_TICKET_UPDATE_FEEDBACK_STATUS_ERROR = 'support/SUPPORT_TICKET_UPDATE_FEEDBACK_STATUS_ERROR';

export const SUPPORT_TICKET_CLOSE_REQUEST = 'support/SUPPORT_TICKET_CLOSE_REQUEST';
export const SUPPORT_TICKET_CLOSE_SUCCESS = 'support/SUPPORT_TICKET_CLOSE_SUCCESS';
export const SUPPORT_TICKET_CLOSE_ERROR = 'support/SUPPORT_TICKET_CLOSE_ERROR';

export const SUPPORT_TICKET_WAIT_TIME_REQUEST = 'support/SUPPORT_TICKET_WAIT_TIME_REQUEST';
export const SUPPORT_TICKET_WAIT_TIME_SUCCESS = 'support/SUPPORT_TICKET_WAIT_TIME_SUCCESS';
export const SUPPORT_TICKET_WAIT_TIME_ERROR = 'support/SUPPORT_TICKET_WAIT_TIME_ERROR';

export const SUPPORT_ALL_SERVICES_REQUEST = 'support/SUPPORT_ALL_SERVICES_REQUEST';
export const SUPPORT_ALL_SERVICES_SUCCESS = 'support/SUPPORT_ALL_SERVICES_SUCCESS';
export const SUPPORT_ALL_SERVICES_ERROR = 'support/SUPPORT_ALL_SERVICES_ERROR';

export const SUPPORT_QUESTION_LIST_REQUEST = 'support/SUPPORT_QUESTION_LIST_REQUEST';
export const SUPPORT_QUESTION_LIST_SUCCESS = 'support/SUPPORT_QUESTION_LIST_SUCCESS';
export const SUPPORT_QUESTION_LIST_ERROR = 'support/SUPPORT_QUESTION_LIST_ERROR';

export const SUPPORT_TICKET_FEEDBACK_REQUEST = 'support/SUPPORT_TICKET_FEEDBACK_REQUEST';
export const SUPPORT_TICKET_FEEDBACK_SUCCESS = 'support/SUPPORT_TICKET_FEEDBACK_SUCCESS';
export const SUPPORT_TICKET_FEEDBACK_ERROR = 'support/SUPPORT_TICKET_FEEDBACK_ERROR';

export const SUPPORT_RESOLVED_SURVEY_REQUEST = 'support/SUPPORT_RESOLVED_SURVEY_REQUEST';
export const SUPPORT_RESOLVED_SURVEY_SUCCESS = 'support/SUPPORT_RESOLVED_SURVEY_SUCCESS';
export const SUPPORT_RESOLVED_SURVEY_ERROR = 'support/SUPPORT_RESOLVED_SURVEY_ERROR';

export const SUPPORT_RESOLVED_SURVEY_LOAD_MORE_REQUEST = 'support/SUPPORT_RESOLVED_SURVEY_LOAD_MORE_REQUEST';
export const SUPPORT_RESOLVED_SURVEY_LOAD_MORE_SUCCESS = 'support/SUPPORT_RESOLVED_SURVEY_LOAD_MORE_SUCCESS';
export const SUPPORT_RESOLVED_SURVEY_LOAD_MORE_ERROR = 'support/SUPPORT_RESOLVED_SURVEY_LOAD_MORE_ERROR';

export const SUPPORT_RESOLVED_TICKETS_SEARCH_REQUEST = 'support/SUPPORT_RESOLVED_TICKETS_SEARCH_REQUEST';
export const SUPPORT_RESOLVED_TICKETS_SEARCH_SUCCESS = 'support/SUPPORT_RESOLVED_TICKETS_SEARCH_SUCCESS';
export const SUPPORT_RESOLVED_TICKETS_SEARCH_ERROR = 'support/SUPPORT_RESOLVED_TICKETS_SEARCH_ERROR';
export const SUPPORT_RESOLVED_TICKETS_SEARCH_RESET = 'support/SUPPORT_RESOLVED_TICKETS_SEARCH_RESET';

export const SUPPORT_DOWNLOAD_ATTACHMENT_REQUEST = 'support/SUPPORT_DOWNLOAD_ATTACHMENT_REQUEST';
export const SUPPORT_DOWNLOAD_ATTACHMENT_SUCCESS = 'support/SUPPORT_DOWNLOAD_ATTACHMENT_SUCCESS';
export const SUPPORT_DOWNLOAD_ATTACHMENT_ERROR = 'support/SUPPORT_DOWNLOAD_ATTACHMENT_ERROR';

export const SUPPORT_MIGRATION_MIGRATION_DATES_REQUEST = 'support/SUPPORT_MIGRATION_MIGRATION_DATES_REQUEST';
export const SUPPORT_MIGRATION_MIGRATION_DATES_SUCCESS = 'support/SUPPORT_MIGRATION_MIGRATION_DATES_SUCCESS';
export const SUPPORT_MIGRATION_MIGRATION_DATES_ERROR = 'support/SUPPORT_MIGRATION_MIGRATION_DATES_ERROR';

export const SUPPORT_SUBMIT_MIGRATION_REQUEST_FORM_REQUEST = 'support/SUPPORT_SUBMIT_MIGRATION_REQUEST_FORM_REQUEST';
export const SUPPORT_SUBMIT_MIGRATION_REQUEST_FORM_SUCCESS = 'support/SUPPORT_SUBMIT_MIGRATION_REQUEST_FORM_SUCCESS';
export const SUPPORT_SUBMIT_MIGRATION_REQUEST_FORM_ERROR = 'support/SUPPORT_SUBMIT_MIGRATION_REQUEST_FORM_ERROR';

export const SUPPORT_SUBMIT_MIGRATION_DETAILS_CHECK_SERVICE_RESET = 'support/SUPPORT_SUBMIT_MIGRATION_DETAILS_CHECK_SERVICE_RESET';
export const SUPPORT_SUBMIT_MIGRATION_DETAILS_CHECK_SERVICE_REQUEST = 'support/SUPPORT_SUBMIT_MIGRATION_DETAILS_VALIDATION_REQUEST';
export const SUPPORT_SUBMIT_MIGRATION_DETAILS_CHECK_SERVICE_SUCCESS = 'support/SUPPORT_SUBMIT_MIGRATION_DETAILS_VALIDATION_SUCCESS';
export const SUPPORT_SUBMIT_MIGRATION_DETAILS_CHECK_SERVICE_ERROR = 'support/SUPPORT_SUBMIT_MIGRATION_DETAILS_VALIDATION_ERROR';

export const SUPPORT_FEEDBACK_FORM_REQUEST = 'support/SUPPORT_FEEDBACK_FORM_REQUEST';
export const SUPPORT_FEEDBACK_FORM_SUCCESS = 'support/SUPPORT_FEEDBACK_FORM_SUCCESS';
export const SUPPORT_FEEDBACK_FORM_ERROR = 'support/SUPPORT_FEEDBACK_FORM_ERROR';

export const SUPPORT_SERVICE_STATUS_REQUEST = 'support/SUPPORT_SERVICE_STATUS_REQUEST';
export const SUPPORT_SERVICE_STATUS_SUCCESS = 'support/SUPPORT_SERVICE_STATUS_SUCCESS';
export const SUPPORT_SERVICE_STATUS_ERROR = 'support/SUPPORT_SERVICE_STATUS_ERROR';

export const SUPPORT_SERVICE_STATUS_UPDATE_REQUEST = 'support/SUPPORT_SERVICE_STATUS_UPDATE_REQUEST';
export const SUPPORT_SERVICE_STATUS_UPDATE_SUCCESS = 'support/SUPPORT_SERVICE_STATUS_UPDATE_SUCCESS';
export const SUPPORT_SERVICE_STATUS_UPDATE_ERROR = 'support/SUPPORT_SERVICE_STATUS_UPDATE_ERROR';

/**********************************************************************************************************
 *   RESET
 **********************************************************************************************************/
export const resetSupport = () => {
    return (dispatch) => {
        dispatch({
            type: SUPPORT_INITIAL_STATE
        });
    };
};

/**********************************************************************************************************
 *   KNOWLEDGE BASE
 **********************************************************************************************************/
export const registerCategoryRef = (category, ref) => {
    return (dispatch) => {
        dispatch({
            type: SUPPORT_KNOWLEDGE_BASE_CATEGORY_REFS,
            support_knowledge_base_category_refs: { category, ref }
        });
    };
};

export const setKnowledgeBaseAll = (category, subcategory, article) => {
    return (dispatch) => {
        dispatch({
            type: SUPPORT_KNOWLEDGE_BASE_ALL,
            support_knowledge_base_article: article || null,
            support_knowledge_base_sub_category: subcategory || null,
            support_knowledge_base_category: category || null
        });
    };
};

export const getKnowledgeBaseData = (eTicketsPage) => {
    return (dispatch) => {
        if (!eTicketsPage) {
            // Reset data to make sure everything works when the user navigates directly to an article or category url
            dispatch({
                type: SUPPORT_INITIAL_STATE
            });
        }

        dispatch({
            type: SUPPORT_KNOWLEDGE_BASE_REQUEST
        });
        API.support.POST.knowledge
            .data()
            .then((response) => {
                const support_knowledge_base_data = getDataFromSuccessResponse(response);
                const baseFAQPath = '/support/support-centre';

                /******************************************************************************************
                 *
                 *   Removes unnecessary data from each category, subcategory and post
                 *
                 *******************************************************************************************/
                const sanitizedData = [];
                const allPosts = [];
                const allPostsTitles = {};

                if (support_knowledge_base_data?.length > 0) {
                    support_knowledge_base_data.forEach((cat) => {
                        const categoryPosts = [];
                        const categoryPostsTitles = {};
                        const subCategories = [];

                        if (cat.posts?.length > 0) {
                            cat.posts.forEach((article) => {
                                const { ID, guid, post_name, post_date, post_content, post_title } = article;

                                if (ID && guid && post_name && post_date && post_content && post_title) {
                                    const catPost = {
                                        id: ID,
                                        guid,
                                        link: `${baseFAQPath}/${cat.slug}/${post_name}`,
                                        post_content,
                                        post_date,
                                        post_name,
                                        post_title
                                    };

                                    // Remove duplicates
                                    if (!categoryPostsTitles[catPost.post_title]) {
                                        categoryPosts.push(catPost);
                                        categoryPostsTitles[catPost.post_title] = true;
                                    }

                                    // Remove duplicates
                                    if (!allPostsTitles[catPost.post_title]) {
                                        allPosts.push(catPost);
                                        allPostsTitles[catPost.post_title] = true;
                                    }
                                }
                            });
                        }

                        if (cat.subcategories?.length > 0) {
                            cat.subcategories.forEach((subcat) => {
                                const subCatPosts = [];
                                const subCatPostsTitles = {};

                                if (subcat.posts?.length > 0) {
                                    subcat.posts.forEach(function (subcatarticle) {
                                        const { ID, guid, post_name, post_content, post_date, post_title } = subcatarticle;

                                        if (ID && guid && post_name && post_date && post_content && post_title) {
                                            const subcatPost = {
                                                id: ID,
                                                guid,
                                                link: `${baseFAQPath}/${cat.slug}/${subcat.slug}/${post_name}`,
                                                post_content,
                                                post_date,
                                                post_name,
                                                post_title
                                            };

                                            // Remove duplicates
                                            if (!subCatPostsTitles[subcatPost.post_title]) {
                                                subCatPosts.push(subcatPost);
                                                subCatPostsTitles[subcatPost.post_title] = true;
                                            }

                                            // Remove duplicates
                                            if (!allPostsTitles[subcatPost.post_title]) {
                                                allPosts.push(subcatPost);
                                                allPostsTitles[subcatPost.post_title] = true;
                                            }
                                        }
                                    });
                                }

                                subCategories.push({
                                    id: subcat.term_id,
                                    title: subcat.name,
                                    description: subcat.description,
                                    posts: subCatPosts,
                                    link: `${baseFAQPath}/${cat.slug}/${subcat.slug}`,
                                    slug: subcat.slug
                                });
                            });
                        }

                        sanitizedData.push({
                            id: cat.term_id,
                            icon: cat.icon_slug,
                            title: cat.name,
                            posts: categoryPosts,
                            link: `${baseFAQPath}/${cat.slug}`,
                            slug: cat.slug,
                            subcategories: subCategories
                        });
                    });
                }

                dispatch({
                    type: SUPPORT_KNOWLEDGE_BASE_SUCCESS,
                    support_knowledge_base_data: sanitizedData,
                    support_knowledge_base_all_posts_data: allPosts
                });
            })
            .catch((error) => {
                const support_knowledge_base_error = getErrorFromFailResponse(error);
                if (!eTicketsPage) pushNotification(support_knowledge_base_error);
                dispatch({
                    type: SUPPORT_KNOWLEDGE_BASE_ERROR,
                    support_knowledge_base_error
                });
            });
    };
};

/**********************************************************************************************************
 *   ETICKETS
 **********************************************************************************************************/
export const getOpenTickets = () => {
    return (dispatch) => {
        dispatch({
            type: SUPPORT_TICKETS_OPEN_REQUEST
        });
        API.support.GET.tickets
            .active()
            .then((response) => {
                const support_tickets_open_data = getDataFromSuccessResponse(response);
                dispatch({
                    type: SUPPORT_TICKETS_OPEN_SUCCESS,
                    support_tickets_open_data
                });
            })
            .catch((error) => {
                const support_tickets_open_error = getErrorFromFailResponse(error);
                dispatch({
                    type: SUPPORT_TICKETS_OPEN_ERROR,
                    support_tickets_open_error
                });
            });
    };
};

export const getIndividualTicket = (id) => {
    return (dispatch) => {
        dispatch({
            type: SUPPORT_INDIVIDUAL_TICKET_REQUEST
        });
        API.support.GET.tickets
            .messages(id)
            .then((response) => {
                const support_individual_ticket_data = getDataFromSuccessResponse(response);
                const support_individual_ticket_meta = getMetaFromSuccessResponse(response);
                dispatch({
                    type: SUPPORT_INDIVIDUAL_TICKET_SUCCESS,
                    support_individual_ticket_data,
                    support_individual_ticket_meta
                });
            })
            .catch((error) => {
                const support_individual_ticket_error = getErrorFromFailResponse(error);
                dispatch({
                    type: SUPPORT_INDIVIDUAL_TICKET_ERROR,
                    support_individual_ticket_error
                });
            });
    };
};

export const updateTicketFeedbackStatus = (id, status = 'completed') => {
    return (dispatch) => {
        dispatch({
            type: SUPPORT_TICKET_UPDATE_FEEDBACK_STATUS_REQUEST
        });
        API.support.POST.tickets
            .feedback(id, status)
            .then(() => {
                pushNotification({
                    status: 200,
                    details: 'Thank you for your feedback.'
                });
                dispatch({
                    type: SUPPORT_TICKET_UPDATE_FEEDBACK_STATUS_SUCCESS
                });
            })
            .catch((error) => {
                const support_ticket_update_feedback_status_error = getErrorFromFailResponse(error);
                dispatch({
                    type: SUPPORT_TICKET_UPDATE_FEEDBACK_STATUS_ERROR,
                    support_ticket_update_feedback_status_error
                });
            });
    };
};

export const closeTicket = (id) => {
    return (dispatch) => {
        dispatch({
            type: SUPPORT_TICKET_CLOSE_REQUEST
        });
        API.support.POST.tickets
            .close(id)
            .then((response) => {
                const support_tickets_close_data = getDataFromSuccessResponse(response);
                pushNotification(support_tickets_close_data);
                dispatch({
                    type: SUPPORT_TICKET_CLOSE_SUCCESS,
                    support_tickets_close_data
                });
            })
            .catch((error) => {
                const support_tickets_close_error = getErrorFromFailResponse(error);
                pushNotification(support_tickets_close_error);
                dispatch({
                    type: SUPPORT_TICKET_CLOSE_ERROR,
                    support_tickets_close_error
                });
            });
    };
};

export const getTicketWaitTime = () => {
    return (dispatch) => {
        dispatch({
            type: SUPPORT_TICKET_WAIT_TIME_REQUEST
        });
        API.support.GET.tickets
            .waitTime()
            .then((response) => {
                const support_ticket_wait_time_data = getDataFromSuccessResponse(response);
                dispatch({
                    type: SUPPORT_TICKET_WAIT_TIME_SUCCESS,
                    support_ticket_wait_time_data
                });
            })
            .catch((error) => {
                const support_ticket_wait_time_error = getErrorFromFailResponse(error);
                dispatch({
                    type: SUPPORT_TICKET_WAIT_TIME_ERROR,
                    support_ticket_wait_time_error
                });
            });
    };
};

export const getSupportQuestionList = () => {
    return (dispatch) => {
        dispatch({
            type: SUPPORT_QUESTION_LIST_REQUEST
        });
        API.support.GET.tickets
            .questions()
            .then((response) => {
                const support_question_list_data = getDataFromSuccessResponse(response);
                dispatch({
                    type: SUPPORT_QUESTION_LIST_SUCCESS,
                    support_question_list_data
                });
            })
            .catch((error) => {
                const support_question_list_error = getErrorFromFailResponse(error);
                dispatch({
                    type: SUPPORT_QUESTION_LIST_ERROR,
                    support_question_list_error
                });
            });
    };
};

export const getResolvedSurvey = (page) => {
    return (dispatch) => {
        if (!page || page === 1) {
            dispatch({
                type: SUPPORT_RESOLVED_SURVEY_REQUEST
            });
            API.support.GET.tickets
                .resolved(page)
                .then((response) => {
                    const support_resolved_survey_data = getDataFromSuccessResponse(response);
                    const support_resolved_survey_meta = getMetaFromSuccessResponse(response);
                    dispatch({
                        type: SUPPORT_RESOLVED_SURVEY_SUCCESS,
                        support_resolved_survey_data,
                        support_resolved_survey_meta
                    });
                })
                .catch((error) => {
                    const support_resolved_survey_error = getErrorFromFailResponse(error);
                    dispatch({
                        type: SUPPORT_RESOLVED_SURVEY_ERROR,
                        support_resolved_survey_error
                    });
                });
        } else {
            dispatch({
                type: SUPPORT_RESOLVED_SURVEY_LOAD_MORE_REQUEST
            });
            API.support.GET.tickets
                .resolved(page)
                .then((response) => {
                    const support_resolved_survey_data = getDataFromSuccessResponse(response);
                    const support_resolved_survey_meta = getMetaFromSuccessResponse(response);
                    dispatch({
                        type: SUPPORT_RESOLVED_SURVEY_LOAD_MORE_SUCCESS,
                        support_resolved_survey_data,
                        support_resolved_survey_meta
                    });
                })
                .catch((error) => {
                    const support_resolved_survey_load_more_error = getErrorFromFailResponse(error);
                    dispatch({
                        type: SUPPORT_RESOLVED_SURVEY_LOAD_MORE_ERROR,
                        support_resolved_survey_load_more_error
                    });
                });
        }
    };
};

export const searchResolvedTickets = (keyword) => {
    return (dispatch) => {
        dispatch({
            type: SUPPORT_RESOLVED_TICKETS_SEARCH_REQUEST
        });
        if (cancelSearchResolvedTickets) {
            cancelSearchResolvedTickets();
        }
        API.support.GET.tickets.search
            .resolved(keyword)
            .then((response) => {
                const support_resolved_ticket_search_data = getDataFromSuccessResponse(response);
                dispatch({
                    type: SUPPORT_RESOLVED_TICKETS_SEARCH_SUCCESS,
                    support_resolved_ticket_search_data
                });
            })
            .catch((error) => {
                const support_resolved_ticket_search_error = getErrorFromFailResponse(error);
                if (error.response) {
                    dispatch({
                        type: SUPPORT_RESOLVED_TICKETS_SEARCH_ERROR,
                        support_resolved_ticket_search_error
                    });
                }
            });
    };
};

export const resetSearchResolvedTickets = () => {
    return (dispatch) => {
        dispatch({
            type: SUPPORT_RESOLVED_TICKETS_SEARCH_RESET
        });
    };
};

export const getAllServices = () => {
    return (dispatch) => {
        dispatch({
            type: SUPPORT_ALL_SERVICES_REQUEST
        });

        const nameResult = (name) => (apiResult) => ({ name, apiResult });
        const MAX_RECORD_PER_PAGE = { record_per_page: 999 };

        Promise.allSettled([
            servicesAPI.hosting.list(MAX_RECORD_PER_PAGE).then(nameResult('webHosting')),
            servicesAPI.domain.list(MAX_RECORD_PER_PAGE).then(nameResult('domains')),
            servicesAPI.vps.list(MAX_RECORD_PER_PAGE).then(nameResult('vps')),
            servicesAPI.gsuite.list(MAX_RECORD_PER_PAGE).then(nameResult('gsuite')),
            servicesAPI.ms365.list(MAX_RECORD_PER_PAGE).then(nameResult('ms365')),
            servicesAPI.ssl.list(MAX_RECORD_PER_PAGE).then(nameResult('ssl')),
            emailAPI.email.get.allMailboxes(MAX_RECORD_PER_PAGE).then(nameResult('emailHosting'))
        ])
            .then((values) => {
                const support_all_services_data = values.reduce((accumulator, { status, value }) => {
                    if (status === 'fulfilled') {
                        const { name, apiResult } = value;
                        accumulator[name] = getDataFromSuccessResponse(apiResult);
                    }
                    return accumulator;
                }, {});

                dispatch({
                    type: SUPPORT_ALL_SERVICES_SUCCESS,
                    support_all_services_data
                });
            })
            .catch((error) => {
                const support_all_services_error = getErrorFromFailResponse(error);
                dispatch({
                    type: SUPPORT_ALL_SERVICES_ERROR,
                    support_all_services_error
                });
            });
    };
};

export const downloadAttachment = (ticketMask, attachmentId, attachmentName) => {
    return (dispatch) => {
        dispatch({
            type: SUPPORT_DOWNLOAD_ATTACHMENT_REQUEST
        });
        API.support.GET.tickets
            .attachment(ticketMask, attachmentId)
            .then((response) => {
                dispatch({
                    type: SUPPORT_DOWNLOAD_ATTACHMENT_SUCCESS
                });
                createDownloadFile(response.data, attachmentName, true);
            })
            .catch((error) => {
                const support_download_attachment_error = getErrorFromFailResponse(error);
                dispatch({
                    type: SUPPORT_DOWNLOAD_ATTACHMENT_ERROR
                });
                pushNotification(support_download_attachment_error);
            });
    };
};

/**********************************************************************************************************
 *   MIGRATION
 **********************************************************************************************************/
export const getMigrationDates = () => {
    return (dispatch) => {
        dispatch({
            type: SUPPORT_MIGRATION_MIGRATION_DATES_REQUEST
        });
        API.support.GET.migration
            .migrationDates()
            .then((response) => {
                const support_migration_migration_dates_data = getDataFromSuccessResponse(response);
                dispatch({
                    type: SUPPORT_MIGRATION_MIGRATION_DATES_SUCCESS,
                    support_migration_migration_dates_data
                });
            })
            .catch(() => {
                dispatch({
                    type: SUPPORT_MIGRATION_MIGRATION_DATES_ERROR
                });
            });
    };
};

export function resetMigrationDetailsCheckService() {
    const { dispatch } = store;
    dispatch({
        type: SUPPORT_SUBMIT_MIGRATION_DETAILS_CHECK_SERVICE_RESET
    });
}

export function submitMigrationDetailsCheckService(serviceData) {
    const { dispatch } = store;
    dispatch({
        type: SUPPORT_SUBMIT_MIGRATION_DETAILS_CHECK_SERVICE_REQUEST
    });

    const { service, service_id, values } = serviceData;
    const requestData = {
        type: 'migration',
        attributes: {
            service,
            service_id,
            ...values
        }
    };

    return API.support.POST.migration
        .checkService(requestData)
        .then((response) => {
            const support_submit_migration_details_check_service_data = getDataFromSuccessResponse(response);

            dispatch({
                type: SUPPORT_SUBMIT_MIGRATION_DETAILS_CHECK_SERVICE_SUCCESS,
                support_submit_migration_details_check_service_data
            });
        })
        .catch((error) => {
            const support_submit_migration_details_check_service_error = getErrorFromFailResponse(error);
            dispatch({
                type: SUPPORT_SUBMIT_MIGRATION_DETAILS_CHECK_SERVICE_ERROR
            });
            pushNotification(support_submit_migration_details_check_service_error);
        });
}

export function submitMigrationRequestForm(values) {
    const { dispatch } = store;
    dispatch({
        type: SUPPORT_SUBMIT_MIGRATION_REQUEST_FORM_REQUEST
    });

    let migrationData;
    if (values.service === 'cpanel') {
        const service_credentials = [
            {
                username: values.username,
                password: values.password,
                domain: values.domain
            }
        ];

        delete values.username;
        delete values.password;
        delete values.domain;

        migrationData = {
            type: 'migration',
            attributes: {
                service_credentials,
                ...values
            }
        };
    } else {
        migrationData = {
            type: 'migration',
            attributes: {
                ...values
            }
        };
    }

    return API.support.POST.migration
        .submit(migrationData)
        .then((response) => {
            const support_submit_migration_request_form_data = getDataFromSuccessResponse(response);

            dispatch({
                type: SUPPORT_SUBMIT_MIGRATION_REQUEST_FORM_SUCCESS,
                support_submit_migration_request_form_data
            });
        })
        .catch((error) => {
            dispatch({
                type: SUPPORT_SUBMIT_MIGRATION_REQUEST_FORM_ERROR
            });
        });
}

/**********************************************************************************************************
 *   FEEDBACK
 **********************************************************************************************************/

export const sendFeedback = (attributes) => {
    return (dispatch) => {
        dispatch({
            type: SUPPORT_FEEDBACK_FORM_REQUEST
        });
        API.support.POST.tickets
            .new(attributes)
            .then((response) => {
                const support_feedback_form_data = getDataFromSuccessResponse(response);
                dispatch({
                    type: SUPPORT_FEEDBACK_FORM_SUCCESS,
                    support_feedback_form_data
                });
            })
            .catch((error) => {
                const support_feedback_form_error = getErrorFromFailResponse(error);
                dispatch({
                    type: SUPPORT_FEEDBACK_FORM_ERROR,
                    support_feedback_form_error
                });
            });
    };
};

/**********************************************************************************************************
 *   SERVICE STATUS
 **********************************************************************************************************/

export const getServiceStatus = (bypass) => {
    return (dispatch) => {
        if (!bypass)
            dispatch({
                type: SUPPORT_SERVICE_STATUS_REQUEST
            });
        API.support.GET.status()
            .then((response) => {
                const support_service_status_data = getDataFromSuccessResponse(response);
                if (bypass) {
                    dispatch(bypass);
                    dispatch({
                        type: SUPPORT_SERVICE_STATUS_SUCCESS,
                        support_service_status_data
                    });
                } else {
                    dispatch({
                        type: SUPPORT_SERVICE_STATUS_SUCCESS,
                        support_service_status_data
                    });
                }
            })
            .catch((error) => {
                const support_service_status_error = getErrorFromFailResponse(error);
                dispatch({
                    type: SUPPORT_SERVICE_STATUS_ERROR,
                    support_service_status_error
                });
            });
    };
};

export const updateServiceStatus = (action, type) => {
    return (dispatch) => {
        dispatch({
            type: SUPPORT_SERVICE_STATUS_UPDATE_REQUEST
        });
        API.support.POST.status[action ? 'disable' : 'enable'](type)
            .then((response) => {
                const support_service_status_update_data = getDataFromSuccessResponse(response);
                pushNotification(support_service_status_update_data);
                dispatch(
                    getServiceStatus({
                        type: SUPPORT_SERVICE_STATUS_UPDATE_SUCCESS,
                        support_service_status_update_data
                    })
                );
            })
            .catch((error) => {
                const support_service_status_update_error = getErrorFromFailResponse(error);
                pushNotification(support_service_status_update_error);
                dispatch({
                    type: SUPPORT_SERVICE_STATUS_UPDATE_ERROR,
                    support_service_status_update_error
                });
            });
    };
};

/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import OverlayLightbox from 'components/Lightboxes/OverlayLightbox';
import { Component } from 'react';
import { Field, reduxForm } from 'redux-form';
import { withRouter } from 'utilities/methods/tanstack/router/withRouter';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import { RenderField, RenderSelectField, renderButton, requiredFieldValidation } from 'utilities/methods/form';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
class InfoForm extends Component {
    render() {
        const { pristine, submitting, valid, handleSubmit, closeLightbox } = this.props;

        return (
            <form className="domainActionForm" onSubmit={handleSubmit}>
                <div className="domainActionForm__container">
                    <div className="domainActionForm__input">
                        <Field
                            label="Eligibility Type"
                            name="type"
                            component={RenderSelectField}
                            type="select"
                            validate={[requiredFieldValidation]}
                            className="form__dropdown"
                            options={[
                                {
                                    label: 'ABN',
                                    value: 'ABN'
                                },
                                {
                                    label: 'ACN',
                                    value: 'ACN'
                                },
                                {
                                    label: 'Trademark',
                                    value: 'Trademark'
                                },
                                {
                                    label: 'NSW BN',
                                    value: 'NSW BN'
                                },
                                {
                                    label: 'NT BN',
                                    value: 'NT BN'
                                },
                                {
                                    label: 'QLD BN',
                                    value: 'QLD BN'
                                },
                                {
                                    label: 'SA BN',
                                    value: 'SA BN'
                                },
                                {
                                    label: 'TAS BN',
                                    value: 'TAS BN'
                                },
                                {
                                    label: 'VIC BN',
                                    value: 'VIC BN'
                                },
                                {
                                    label: 'WA BN',
                                    value: 'WA BN'
                                },
                                {
                                    label: 'ACT BN',
                                    value: 'ACT BN'
                                }
                            ]}
                        />
                    </div>
                    <div className="domainActionForm__input">
                        <Field
                            label="Eligibility Number"
                            name="eligibility"
                            component={RenderField}
                            type="text"
                            validate={[requiredFieldValidation]}
                            className="form__textfield"
                        />
                    </div>
                    <div className="domainActionForm__action">{renderButton(pristine, submitting, valid, 'Submit')}</div>
                    <OverlayLightbox.BackLink text="NO, GO BACK" onClick={closeLightbox} />
                </div>
            </form>
        );
    }
}

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
InfoForm = reduxForm({
    form: 'eligibility'
})(InfoForm);

export default withRouter(InfoForm);

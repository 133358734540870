/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { useQuery } from '@tanstack/react-query';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { API as DOMAIN_API } from 'utilities/api/domain';
import { createQueryBoilerPlate } from 'utilities/methods/tanstack';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
const tppDomainsListBoilerPlate = createQueryBoilerPlate({
    service: 'legacy-domain',
    serviceKey: 'TPP_DOMAIN',
    APIRoute: () => () => DOMAIN_API.tppDomains.GET.list(),
    defaultOptions: {
        staleTime: 60_000 * 10
    }
});

const { getDefaults } = tppDomainsListBoilerPlate;

/**********************************************************************************************************
 *   HOOK START
 **********************************************************************************************************/
/**
 * Gets a list of all TPP domains
 */
export function useTppDomainsListQuery(options = {}) {
    return useQuery({
        ...getDefaults(),
        ...options
    });
}
/**********************************************************************************************************
 *   HOOK END
 **********************************************************************************************************/

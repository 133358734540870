/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React from 'react';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import { ServiceOverviewBanner } from 'components/ServiceOverviewBanner/';
import Text from 'components/Utils/Text';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import upgradeBackgroundImage from 'assets/images/hosting/hosting_banner_background.png';
import upgradeImage from 'assets/images/hosting/hosting_banner_icon.svg';
import { getPurchaseLink } from 'containers/services/consts';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const HostingBanner = () => (
    <ServiceOverviewBanner src={upgradeBackgroundImage} alt="Purchase Hosting">
        <ServiceOverviewBanner.ContentWrapper>
            <ServiceOverviewBanner.Heading>Need more web hosting?</ServiceOverviewBanner.Heading>
            <ServiceOverviewBanner.Description>
                Simple, Secure and Australian! Powerful hosting backed by 24x7 local support.
            </ServiceOverviewBanner.Description>
            <ServiceOverviewBanner.Button href={getPurchaseLink('Web Hosting', { utmMedium: 'service-banner' })} target="_blank">
                <Text medium>View Pricing</Text>
            </ServiceOverviewBanner.Button>
        </ServiceOverviewBanner.ContentWrapper>
        <ServiceOverviewBanner.Image src={upgradeImage} alt="Purchase Hosting" />
    </ServiceOverviewBanner>
);
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

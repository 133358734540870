/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React from 'react';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import Anchor from 'components/Anchor';

/**********************************************************************************************************
 *   QUERIES
 **********************************************************************************************************/
import { useGetInvoiceBoilerPlate } from 'containers/billing/queries/invoice/useGetInvoiceQuery';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { toLuxonDate } from 'utilities/methods/commonActions';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import { INVOICE_STATUSES } from 'utilities/consts';

export function getActionDropDownData({ id, attributes: { status, invoice_items, can_split, can_cancel } }) {
    function prefetchInvoice() {
        useGetInvoiceBoilerPlate.prefetch(id);
    }

    if ([INVOICE_STATUSES.PAID, INVOICE_STATUSES.CANCELLED, INVOICE_STATUSES.REFUNDED].includes(status)) {
        return {
            label: 'View',
            to: `/billing/invoice/${id}`,
            onMouseOver: prefetchInvoice
        };
    }

    const list = [
        {
            label: 'View Invoice',
            to: `/billing/invoice/${id}`,
            onMouseOver: prefetchInvoice
        }
    ];

    if (can_cancel) {
        list.push({
            label: 'Cancel Invoice',
            to: `/billing/invoice/${id}/cancel`
        });
    } else {
        list.push({
            label: 'Cancel Invoice',
            type: 'onClick',
            disabled: true,
            disabledTooltip: 'Only pending orders can be cancelled.'
        });
    }

    if (invoice_items?.length > 1) {
        if (can_split) {
            list.push({
                label: 'Split Invoice',
                to: `/billing/split-invoice/${id}`
            });
        } else {
            list.push({
                label: 'Split Invoice',
                type: 'onClick',
                disabled: true,
                disabledTooltip: '(Pending orders, service restoration) invoice cannot be split.'
            });
        }
    }

    return {
        title: 'Pay',
        to: `/billing/invoice/${id}/pay`,
        onMouseOver: prefetchInvoice,
        list
    };
}

export function buildTooltipInfo({ id, attributes: { invoice_items } }) {
    // Base tooltip info
    const tooltipInfoBase = <p>Invoice # {id}</p>;

    // If for some reason there are no invoice items, just show the id
    if (!Array.isArray(invoice_items) || invoice_items.length <= 0) return tooltipInfoBase;

    // If there are multiple invoice items, show the id and a message saying there are multiple
    if (invoice_items.length > 1) {
        const multipleServicesMessage = (
            <p>
                This invoice contains multiple services.{' '}
                <Anchor className="accountInvoices__invoiceInfoBold" color="white" to={`/billing/invoice/${id}`}>
                    View
                </Anchor>{' '}
                the invoice to see associated services.
            </p>
        );
        return (
            <>
                {tooltipInfoBase}
                {multipleServicesMessage}
            </>
        );
    }

    // If there's only one invoice item, show id, description and dates if it has them
    const { period_start, period_end, description } = invoice_items[0];

    const startDate = toLuxonDate(period_start);
    const endDate = toLuxonDate(period_end);

    const dates =
        startDate.isValid && endDate.isValid ? (
            <p>
                ({startDate.toFormat('d/L/y')} - {endDate.toFormat('d/L/y')})
            </p>
        ) : (
            ''
        );

    return (
        <>
            {tooltipInfoBase}
            {description}
            {dates}
        </>
    );
}

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import HoverTooltip from 'components/Tooltip/HoverTooltip';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import './_serviceSelectItemNotAllowed.scss';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type ServiceSelectItemNotAlloweProps = {
    /**
     * React Children
     */
    children: React.ReactNode;

    /**
     * Wether the Component is having to show the hover tooltip
     */
    disabled: boolean;

    /**
     * The content for the tooltip
     */
    content: string;
};

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const ServiceSelectItemNotAllowed: React.FC<ServiceSelectItemNotAlloweProps> = ({ children, disabled, content }) => {
    if (disabled) {
        return (
            <HoverTooltip usePortal className="ServiceSelectItemNotAllowed" content={content ?? 'This domain cannot be used with the current tool'}>
                {children}
            </HoverTooltip>
        );
    }

    return children;
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

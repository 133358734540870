/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { useContext } from 'react';
import { change } from 'redux-form';
import store from 'store/store';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import { ContentEditorRenderModulesContext } from 'containers/katana/containers/ContentEditorLightbox/contentEditorRenderModules/consts';

/**********************************************************************************************************
 *   HOOK START
 **********************************************************************************************************/
export function useDynamicFormActions() {
    /***** HOOKS *****/
    const { form } = useContext(ContentEditorRenderModulesContext);

    /***** FUNCTIONS *****/
    /**
     * @param {string} field
     * @param {any} value
     */
    function dynamicChange(field, value) {
        store.dispatch(change(form, field, value));
    }

    /***** HOOK RESULTS *****/
    return {
        dynamicChange
    };
}

/**********************************************************************************************************
 *   HOOK END
 **********************************************************************************************************/

/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import type { KatanaNamespace } from 'containers/katana/types';
import { createContext } from 'react';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
export const SECTION_DEFINITION_PROPERTY_TYPES = {
    BOOLEAN: 'boolean',
    CHOICE: 'choice',
    FILE: 'file',
    GROUP: 'group',
    NUMBER: 'number',
    REPEATED: 'repeated',
    TEXT: 'text'
} as const;

export const SectionDefinitionContext = createContext<KatanaNamespace.SectionDefinitions.ValuesModifiedPropertiesAndValidations>({});

/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { useContext } from 'react';
import { useSelector } from 'react-redux';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import { selectorEqualityCheckValues, selectorValues } from 'components/Form/Button/reduxForm';
import { ContentEditorRenderModulesContext } from 'containers/katana/containers/ContentEditorLightbox/contentEditorRenderModules/consts';

/**********************************************************************************************************
 *   HOOK START
 **********************************************************************************************************/
export function useContentEditorFormValues() {
    /***** HOOKS *****/
    const { form } = useContext(ContentEditorRenderModulesContext);
    /***** RENDER *****/
    return useSelector(selectorValues(form), { equalityFn: selectorEqualityCheckValues });
}
/**********************************************************************************************************
 *   HOOK END
 **********************************************************************************************************/

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import NXBox from 'components/NXBox';
import { Component } from 'react';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * ModuleErrorComponent - Fallback error component for module routes. This simply calls the NXBox with an initialStatus of "error",
 * However, since this takes no props, it can be passed directly to a route without being in a TSX file.
 *
 * In addition, since this uses the NXBox internally, if the design of the NXBox changes (or error from initialStatus), this will
 * automatically reflect those changes without needing to change the route to match.
 */
export class ModuleErrorBoundary extends Component<{ children: React.ReactNode }, { hasError: boolean }> {
    constructor(props) {
        super(props);
        this.state = { hasError: false };

        this.resetHasError = this.resetHasError.bind(this);
    }

    resetHasError() {
        this.setState({ hasError: false });
    }

    static getDerivedStateFromError() {
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    }

    componentDidCatch(error, errorInfo) {
        // Consider A manual sentry alert if this crashes
    }

    render() {
        if (this.state.hasError) {
            return (
                <NXBox initialStatus="error" onError={<NXBox.RefreshableError title="" onRefresh={this.resetHasError} />}>
                    {' '}
                </NXBox>
            );
        }

        return this.props.children;
    }
}
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

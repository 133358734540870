import { Store } from '@tanstack/react-store';
import RequestLoader from 'components/Loaders/Request';
import { getContentDefinitionFormName } from 'containers/katana/components/contentDefinitionForm/methods';
import { SectionDefinitionRenderer } from 'containers/katana/components/contentDefinitionForm/sectionDefinitionRenderer';
import ImageTitleDescriptionBlock from 'containers/katana/components/imageTitleDescriptionBlock';
import { ContentEditorRenderModulesContext } from 'containers/katana/containers/ContentEditorLightbox/contentEditorRenderModules/consts';
import {
    katanaContentEditorLightboxHasChanges,
    katanaContentEditorLightboxSkipTanstackStore
} from 'containers/katana/containers/ContentEditorLightbox/stores';
import { TutorialVideo } from 'containers/katana/containers/contentEditorModules/tutorialVideo';
import ValidFormSubmitButton from 'containers/katana/containers/contentEditorModules/validFormSubmitButton/validFormSubmitButton';
import { tutorialVideoTimestamps } from 'containers/katana/data/tutorialVideoData';
import { CaveatTypes } from 'containers/katana/formFields/caveatTypes';
import { useGetCaveat } from 'containers/katana/formFields/methods/useGetCaveat';
import { useKatanaParams } from 'containers/katana/hooks/useSetupEditorRouteParams';
import { katanaQuery } from 'containers/katana/queries/tanstackTree';
import type { KatanaNamespace } from 'containers/katana/types';
import React, { useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import type { InjectedFormProps } from 'redux-form';
import { reduxForm } from 'redux-form';
import { useBooleanTanstackStore } from 'utilities/hooks/tanstack-store/useBooleanStore';
import './_contentDefinitionForm.scss';

const { LayoutAndContent } = tutorialVideoTimestamps;

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type ContentDefinitionFormProps = {
    sectionDefinition: KatanaNamespace.SectionDefinitions.ValuesModifiedPropertiesAndValidations;
} & InjectedFormProps;

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
const _ContentDefinitionForm: React.FC<ContentDefinitionFormProps> = (props) => {
    const {
        sectionDefinition,
        /**
         * Redux Props
         */
        initialValues,
        handleSubmit,
        pristine,
        submitting,
        valid,
        anyTouched,
        form
    } = props;

    const submitButtonClickEventRef = useRef(
        new Store(/** @type {React.MouseEvent<Element, MouseEvent> | React.KeyboardEvent<Element> | null} */ null)
    );

    const { katanaServiceId } = useKatanaParams();
    const { id: sectionDefinitionID } = sectionDefinition;
    const { data: get_katana_section_definitions_data, isLoading: isKatanaSectionDefinitionsLoading } =
        katanaQuery.serviceID.meta.getSectionDefinitions.useQuery(katanaServiceId);
    const { setTrue: allowSkipSection, setFalse: disableSkipSection } = useBooleanTanstackStore(katanaContentEditorLightboxSkipTanstackStore);

    const { setTrue: setHasChanges, setFalse: setNoChanges } = useBooleanTanstackStore(katanaContentEditorLightboxHasChanges);
    // Use `id` to select header image + some text.
    // use `name` & `description` to make box title + description
    // use `properties` to render the individual fields inside the box.

    const context = {
        pristine,
        submitting,
        valid,
        anyTouched,
        form,
        submitButtonClickEvent: submitButtonClickEventRef.current
    };
    /***** EFFECTS *****/
    useEffect(() => {
        if (anyTouched || !pristine) {
            //check that initialValues has been populated
            if (Object.keys(initialValues).length > 0) {
                setHasChanges();
                disableSkipSection();
            }
        } else {
            setNoChanges();
            allowSkipSection();
        }
    }, [anyTouched, pristine]);

    useEffect(() => {
        allowSkipSection();

        return () => {
            allowSkipSection();
            setNoChanges();
        };
    }, []);

    /***** RENDER HELPERS *****/
    const sectionConfigDescription = useGetCaveat(CaveatTypes.SECTION_CONFIGURATION_DESCRIPTION, sectionDefinition?.caveats);

    /***** RENDER *****/
    return (
        <ContentEditorRenderModulesContext.Provider value={context}>
            <form className="ContentDefinitionForm" data-sentry-un-mask onSubmit={handleSubmit}>
                {isKatanaSectionDefinitionsLoading ? (
                    <RequestLoader message="Loading Section Description..." />
                ) : (
                    <ImageTitleDescriptionBlock
                        imageUrl={get_katana_section_definitions_data?.[sectionDefinitionID]?.thumbnail}
                        title={get_katana_section_definitions_data?.[sectionDefinitionID]?.name}
                        description={sectionConfigDescription ?? get_katana_section_definitions_data?.[sectionDefinitionID]?.description}
                        sectionDefinitionID={sectionDefinitionID}
                    />
                )}

                <TutorialVideo tutorial={LayoutAndContent} />
                <SectionDefinitionRenderer {...sectionDefinition} />

                <ValidFormSubmitButton />
            </form>
        </ContentEditorRenderModulesContext.Provider>
    );
};

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

const ReduxFormContentDefinitionForm = reduxForm({
    enableReinitialize: true,
    keepDirtyOnReinitialize: true
})(_ContentDefinitionForm);

const mapStateToProps = (state, props) => {
    const { sectionDefinition, initialValues } = props;
    const formName = getContentDefinitionFormName(sectionDefinition?.id);

    return {
        form: formName,
        initialValues
    };
};

const ContentDefinitionForm = connect(mapStateToProps)(ReduxFormContentDefinitionForm);

export default ContentDefinitionForm;

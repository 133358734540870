/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { Component } from 'react';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
class WithAnimationLoop extends Component {
    constructor(props) {
        super(props);

        this.state = {
            lastTime: 0,
            elapsed: 0,
            paused: false
        };

        this.execute = this.execute.bind(this);
        this.start = this.start.bind(this);
        this.pause = this.pause.bind(this);
        this.resume = this.resume.bind(this);
        this.restart = this.restart.bind(this);
    }

    execute(timestamp) {
        const { lastTime, elapsed, paused } = this.state;
        const { execute } = this;

        if (!lastTime) {
            return this.setState(
                {
                    lastTime: timestamp
                },
                () => requestAnimationFrame(execute)
            );
        }

        const delta = timestamp - lastTime;

        this.setState(
            {
                lastTime: timestamp,
                elapsed: paused ? elapsed : elapsed + delta
            },
            () => requestAnimationFrame(execute)
        );
    }

    start() {
        const { execute } = this;

        requestAnimationFrame(execute);
    }

    pause() {
        this.setState({
            paused: true
        });
    }

    resume() {
        this.setState({
            paused: false
        });
    }

    restart() {
        this.setState({
            elapsed: 0
        });
    }

    render() {
        const { children } = this.props;
        const { elapsed } = this.state;
        const { start, pause, resume, restart } = this;

        /*  RENDER COMPONENT
         **********************************************************************************************************/
        return children(elapsed, start, pause, resume, restart);
    }
}

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
export default WithAnimationLoop;

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import './_NXBoxOverviewRow.scss';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type NXBoxOverviewRow = React.FC<NXBoxOverviewRowProps>;
type NXBoxOverviewRowProps = {
    children: React.ReactNode;
};

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * Provides flex styling to its children, and additional styling to an NXBoxInfoPair__title inside of it
 */
export const NXBoxOverviewRow: NXBoxOverviewRow = ({ children }) => {
    /***** RENDER *****/
    return <div className="NXBoxOverviewRow">{children}</div>;
};

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { useQuery } from '@tanstack/react-query';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { API as ACCOUNT_API } from 'utilities/api/account';
import { getCurrentDate } from 'utilities/methods/commonActions';
import { createQueryBoilerPlate } from 'utilities/methods/tanstack';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
/**
 * @deprecated use v5 format, see katanaQuery
 */
const useOverdueInvoicesQueryBoilerPlate = createQueryBoilerPlate({
    service: 'dashboard',
    serviceKey: 'OVERDUE_INVOICES',
    APIRoute: () => () => {
        const params = {
            // TODO: Write test case
            'start_date': getCurrentDate().minus({ years: 3 }).toFormat('yyyy-MM-dd'),
            'end_date': getCurrentDate().minus({ day: 1 }).toFormat('yyyy-MM-dd'),
            'filter_by': 'due_date',
            'statuses[]': 'unpaid',
            'record_per_page': '999'
        };
        return ACCOUNT_API.user.invoice.queriedList.GET(params);
    },
    defaultOptions: {
        staleTime: 1000 * 60 * 5
    }
});

const { getDefaults } = useOverdueInvoicesQueryBoilerPlate;

/**********************************************************************************************************
 *   HOOK START
 **********************************************************************************************************/
/**
 * @deprecated use v5 format, see katanaQuery
 * Overdue invoices query
 * Fetches all user invoices that are overdue, and the due date in less than 3 years old
 */
function useOverdueInvoicesQuery(filters, options = {}) {
    return useQuery({
        ...getDefaults(filters),
        ...options
    });
}

/**********************************************************************************************************
 *   HOOK END
 **********************************************************************************************************/

export { useOverdueInvoicesQuery, useOverdueInvoicesQueryBoilerPlate };

/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React from 'react';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import Anchor from 'components/Anchor';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import Contact from './Components/contact';
import Renew from './Components/renew';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import { application } from 'config/config';

// Priority based array, more important messages are on top.
const contactServiceNotAllowed = [
    {
        condition: (service) => service?.attributes?.domain?.endsWith('.uk'),
        content: (
            <>
                You cannot edit the contact details
                <br />
                of .uk domains in {application}.
                <br />
                Please visit{' '}
                <Anchor color="white" href="https://secure.nominet.org.uk/auth/login.html" target="_blank">
                    Nominet portal
                </Anchor>
            </>
        )
    },
    {
        condition: (service) => service?.attributes?.id_protection,
        content: (
            <>
                You cannot edit the contact details
                <br />
                of ID-protected domains.
            </>
        )
    }
];

export const bulkDomainTools = {
    renew: {
        title: 'Domain Renewal',
        render: ({ selectedServices, preappliedPromoCode }) => <Renew selectedServices={selectedServices} preappliedPromoCode={preappliedPromoCode} />
    },

    contacts: {
        title: 'Update domain contacts',
        render: ({ selectedServices }) => <Contact selectedServices={selectedServices} />,
        disabledServices: contactServiceNotAllowed
    }
};

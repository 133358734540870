/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import './_AvatarPlaceholder.scss';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type AvatarProps = {
    /**
     * Class Name
     */
    className: string;

    /**
     * Full name to dissect
     */
    fullname: string;

    /**
     * The size of the avatar circle
     */
    size: number;

    /**
     * The size of the font inside the avatar circle
     */
    fontSize: number;

    /**
     * background styling for the avatar circle
     */
    background: string;
};
/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * Avatar with initials circle
 */
const Avatar: React.FC<AvatarProps> = (props) => {
    const { className, fullname, size, fontSize, background } = props;
    const getInitials = () => {
        let i = null;
        if (fullname) {
            i = fullname.split(' ');
            if (i && i.length > 1) {
                i = i[0].substr(0, 1) + i[i.length - 1].substr(0, 1);
            }
        }
        return i;
    };
    const initials = getInitials();
    if (initials) {
        const avatarSize = size || 95;
        return (
            <div
                style={{
                    width: avatarSize,
                    height: avatarSize,
                    minWidth: avatarSize,
                    minHeight: avatarSize,
                    fontSize: fontSize ? fontSize : 30,
                    background: background
                }}
                className={`Avatar${className ? ' ' + className : ''}`}
            >
                {initials}
            </div>
        );
    } else {
        return '';
    }
};

export default Avatar;
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
export const contentionTypes = {
    SINGLE_CONTENTION: 'SINGLE_CONTENTION',
    MULTI_CONTENTION: 'MULTI_CONTENTION'
};

const applicationNeedsRenewingTextShort = 'You need to renew your application if you wish to continue contesting this domain.';
export const APPLICATION_NEEDS_RENEWING_TEXT = {
    SHORT: applicationNeedsRenewingTextShort,
    LONG: `${applicationNeedsRenewingTextShort} Click the button below to renew your application for 1 year.`
};

export const IN_PROGRESS_DOMAIN_INFO = {
    FAQ_URL: 'https://ventraip.com.au/faq/article/renewing-a-au-priority-application/',
    NOTE: 'Your domain name application is currently contested.'
};

/**
 * Capitalize first letter of string
 * @private
 * @type {<T extends string>(str: T) => Capitalize<T>}
 */
export function capitalize(str) {
    return /** @type {Capitalize<typeof str>} */ (str.charAt(0).toUpperCase() + str.substr(1));
}

/**
 * Clamp position between a range
 * @param  {number} value - Value to be clamped
 * @param  {number} min - Minimum value in range
 * @param  {number} max - Maximum value in range
 * @return {number} - Clamped value
 */
export function clamp(value, min, max) {
    return Math.min(Math.max(value, min), max);
}

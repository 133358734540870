import { calculateAverageBrightness } from 'components/ImageSelectControl/methods/calculateAverageBrightness';

// Function to determine the recommended background color
export function determineBackgroundColor(imageDataUri) {
    return new Promise((resolve, reject) => {
        // Create a new image element
        const image = new Image();
        // image.crossOrigin = 'Anonymous'; // Enable CORS to avoid tainted canvas
        image.onload = () => {
            // Create a canvas element
            const canvas = document.createElement('canvas');
            const ctx = canvas.getContext('2d');

            // Set the canvas dimensions to match the image
            canvas.width = image.width;
            canvas.height = image.height;

            // Draw the image onto the canvas
            ctx.drawImage(image, 0, 0);

            // Get the image data
            const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);

            // Calculate average brightness of the image
            const averageBrightness = calculateAverageBrightness(imageData);

            // Determine background color based on average brightness
            const backgroundColor = averageBrightness > 255 * 0.666 ? 'black' : 'white';

            resolve(backgroundColor);
        };

        image.onerror = (error) => {
            reject(error);
        };

        // Set the image source to the provided Data URI
        image.src = imageDataUri;
    });
}

/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { Field, reduxForm } from 'redux-form';
import { withRouter } from 'utilities/methods/tanstack/router/withRouter';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import OverlayLightbox from 'components/Lightboxes/OverlayLightbox';
import { RenderField, renderButton, requiredFieldValidation } from 'utilities/methods/form';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
let EppForm = ({ pristine, submitting, valid, handleSubmit, closeLightbox }) => (
    <form className="domainActionForm" onSubmit={handleSubmit}>
        <div className="domainActionForm__container">
            <div className="domainActionForm__input">
                <Field
                    label="EPP Password"
                    name="epp"
                    component={RenderField}
                    type="text"
                    validate={[requiredFieldValidation]}
                    className="form__textfield"
                />
            </div>
            <div className="domainActionForm__action">{renderButton(pristine, submitting, valid, 'Submit')}</div>
            <OverlayLightbox.BackLink onClick={closeLightbox} />
        </div>
    </form>
);

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
EppForm = reduxForm({
    form: 'eppcode'
})(EppForm);

export default withRouter(EppForm);

/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { useEffect } from 'react';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import RequestLoader from 'components/Loaders/Request';

/*   ACTIONS
 *****************************************************/
import { useNavigate, useSearch } from '@tanstack/react-router';
import { loginTokenCheck, requestAccounts } from '../../action';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 *
 * @param {{
 *      resendPushNotificationCount: number;
 *     changeView: (view: string) => void;
 * }} props
 */
function VipcAppTwoFactorPolling({ resendPushNotificationCount, changeView }) {
    const navigate = useNavigate();
    const { ref } = useSearch({ strict: false });

    /***** EFFECTS *****/
    useEffect(() => {
        function checkToken() {
            loginTokenCheck({
                successCallback: async () => {
                    clearInterval(intervalId);
                    clearTimeout(timeoutId);

                    // change to loading and state and navigate when user has single account.
                    changeView('loading');
                    requestAccounts(
                        false,
                        () => navigate({ to: ref ?? '/dashboard' }),
                        () => changeView('accountlist')
                    );
                }
            });
        }

        const intervalId = setInterval(() => {
            checkToken();
        }, 10000);

        const timeoutId = setTimeout(() => clearInterval(intervalId), 300000);

        return () => {
            clearTimeout(timeoutId);
            clearInterval(intervalId);
        };
    }, [resendPushNotificationCount]);

    /***** RENDER *****/
    return <RequestLoader />;
}
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

export default VipcAppTwoFactorPolling;

/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import type { QueryKey } from '@tanstack/react-query';
import queryClient from 'store/queryClient';
import type { NXQueryUtils } from 'utilities/methods/tanstack/types';

export function createGetQueryStateMethod<TParams extends any, TData extends NXQueryUtils.ApiData>(createQueryKey: (params: TParams) => QueryKey) {
    return function getQueryState(params: TParams) {
        return queryClient.getQueryState<TData>(createQueryKey(params));
    };
}

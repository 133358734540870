/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React from 'react';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import { _ValidationMessage as ValidationMessage } from 'components/Form/ValidationMessage/_ValidationMessage';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * @type {React.FC<
 *      Pick<import('redux-form').WrappedFieldMetaProps, 'error' | 'warning' | 'touched' | 'initial'>
 *      & { validated?: { value: string, valid:boolean }}
 * >}
 */
const _ValidationMessageReduxFormDefault = (props) => {
    const { touched, error, warning, validated, initial } = props;

    /***** FUNCTIONS *****/
    function getMessage() {
        if (error) {
            return error;
        }
        if (warning) {
            return warning;
        }
        if (validated?.value) {
            return validated.value;
        }
        return null;
    }

    /***** RENDER HELPERS *****/
    const message = getMessage();
    const warn = (error && !validated) || (validated && !validated.valid);
    const notInitial = Array.isArray(initial) ? !initial.length : !initial;
    const notTouchedAndNotInitial = !touched && notInitial && initial !== '';
    const open = Boolean(!notTouchedAndNotInitial && message);

    /***** RENDER *****/
    return (
        <ValidationMessage confirm={validated?.valid} warn={warn} open={open}>
            {message}
        </ValidationMessage>
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
export { _ValidationMessageReduxFormDefault };

/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import {
    RenderField,
    RenderRevealPasswordField,
    emailFieldValidation,
    renderButton,
    renderCheckboxField,
    requiredFieldValidation
} from 'utilities/methods/form';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
class Form extends Component {
    render() {
        const { handleSubmit, submitting } = this.props;

        /*  RENDER COMPONENT
         **********************************************************************************************************/
        return (
            <form className="login__form" onSubmit={handleSubmit}>
                <div className="form__row">
                    <div className="form__column full login__textfield">
                        <Field
                            label="Email Address"
                            name="email"
                            component={RenderField}
                            type="email"
                            validate={[requiredFieldValidation, emailFieldValidation]}
                            className="form__textfield"
                            autoComplete="email"
                        />
                    </div>

                    <div className="form__column full login__textfield">
                        <Field
                            label="Password"
                            name="password"
                            component={RenderRevealPasswordField}
                            type="password"
                            validate={[requiredFieldValidation]}
                            className="form__textfield"
                            autoComplete="current-password"
                        />
                    </div>

                    <div className="form__column full login__checkboxfield login__rememberButton">
                        <Field
                            label="Keep me logged in for 30 days"
                            name="remember"
                            component={renderCheckboxField}
                            type="checkbox"
                            autoComplete="current-remember"
                        />
                    </div>
                </div>

                {renderButton(false, submitting, true, 'Login', 'primary', 'loginAuth__button')}
            </form>
        );
    }
}

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
Form = reduxForm({
    form: 'loginform'
})(Form);

const mapStateToProps = (state, props) => {
    const emailAddress = props.email ? props.email : null;

    return {
        initialValues: {
            email: emailAddress
        }
    };
};

export default connect(mapStateToProps, null)(Form);

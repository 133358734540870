/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import classNames from 'classnames';
import React from 'react';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import { _RequestLoader as RequestLoader } from 'components/Loaders/Request/_requestLoader';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import './_RequestLoaderWithBackground.scss';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * Shows a loading animation with an optional message
 */
export const _RequestLoaderWithBackground: React.FC<NRequestLoader.Props> = (props) => (
    <RequestLoader {...props} className={classNames(props?.className, 'RequestLoaderWithBackground')} />
);
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

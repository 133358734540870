/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import classNames from 'classnames';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { createAppliedStylingClasses } from 'components/Utils/methods';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import './__NXCard.scss';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type NXCardProps = {
    className?: string;
    children: React.ReactNode;
    onClick?: () => void;
    border?: boolean;
    elevated?: boolean;
    highlighted?: boolean;
    disabled?: boolean;
};

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const _NXCard: React.FC<NXCardProps> = (props) => {
    const { className, children, onClick, disabled } = props;

    /***** RENDER HELPERS *****/
    const appliedStylingClasses = createAppliedStylingClasses({
        props,
        keyBoundary: ['border', 'elevated', 'highlighted', 'disabled'],
        componentName: 'NXCard',
        delimiter: '--'
    });

    const nxCardClassNames = classNames('NXCard', appliedStylingClasses, className);

    const renderContent = () => <div className="NXCard__wrapper">{children}</div>;

    /***** RENDER *****/
    if (onClick)
        return (
            <button type="button" onClick={disabled ? undefined : onClick} className={nxCardClassNames}>
                {renderContent()}
            </button>
        );

    return <div className={nxCardClassNames}>{renderContent()}</div>;
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

import { _RichTextEditor } from 'components/Form/RichTextEditor/_RichTextEditor';
import { _RichTextEditorTextArea } from 'components/Form/RichTextEditor/_RichTextEditorTextArea';
import { _RichTextEditorTextAreaReduxForm } from 'components/Form/RichTextEditor/_RichTextEditorTextArea/wrappers/reduxform/Default';

/**
 * Using Tip Tap under the hood: https://tiptap.dev/docs/editor/extensions/overview
 */
export const RichTextEditor = Object.assign(_RichTextEditor, {
    TextArea: Object.assign(_RichTextEditorTextArea, {
        ReduxForm: _RichTextEditorTextAreaReduxForm
    })
});

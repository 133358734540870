/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { isFileData } from 'containers/katana/queries/methods/attachmentData';

export function mergeWithAttributeObjectPicker(objValue, srcValue) {
    if (isFileData(srcValue)) {
        return srcValue;
    }
}

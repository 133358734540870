/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import type { QueryKey } from '@tanstack/react-query';
import queryClient from 'store/queryClient';
import type { NXQueryUtils } from 'utilities/methods/tanstack/types';

export function createSetQueryDataMethod<TParams extends any, TData extends NXQueryUtils.ApiData>(createQueryKey: (params: TParams) => QueryKey) {
    return function setQueryData(params: TParams, updater: (oldData: TData) => TData | undefined) {
        queryClient.setQueryData(createQueryKey(params), updater);
    };
}

/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React from 'react';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import RequestLoader from 'components/Loaders/Request';
import Text from 'components/Utils/Text';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { useHandleFileLoading } from './hooks';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * @type {React.FC<{
 *      file: File
 * }>}
 */
function FileRenderer({ file }) {
    /***** HOOKS *****/
    const { isError, isLoading, loadedData } = useHandleFileLoading(file);

    /***** RENDER *****/
    if (isError) {
        return <Text warn>{isError}</Text>;
    }
    if (isLoading) {
        return <RequestLoader />;
    }
    switch (file.type) {
        case 'image/png':
        case 'image/jpg':
        case 'image/jpeg':
        case 'image/gif':
            return <img src={loadedData} alt={file.name} />;
        default:
            return <div>FileRenderer! {file.type}</div>;
    }
}
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

export default FileRenderer;

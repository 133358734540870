import { notRequiredACNorABNValidationByType } from 'utilities/methods/form';
import { z } from 'zod';

export const contactInformationZodSchema = z
    .object({
        email: z.union([z.string().email().nullable().optional().default(null), z.literal('')]),
        phone: z
            .string()
            .max(255)
            .nullable()
            .optional()
            .default(null)
            .refine(
                (value) => {
                    if (!value?.length) {
                        return true;
                    }
                    return value.match(/\d/);
                },
                {
                    message: 'Invalid Phone Number'
                }
            ),
        address: z.string().nullable().optional().default(null),
        operating_hours: z.string().nullable().optional().default(null),
        business_number_type: z
            .union([z.literal('ABN'), z.literal('ACN')])
            .nullable()
            .optional(),
        business_number: z.string().nullable().optional().default(null)
    })
    .refine(
        ({ business_number_type, business_number }) => {
            if (!business_number_type) return true;
            return !notRequiredACNorABNValidationByType(business_number_type)(business_number);
        },
        {
            message: 'Invalid business number',
            path: ['business_number']
        }
    );

import { useNavigate, useParams } from '@tanstack/react-router';
import { pushNotification } from 'components/Toast/functions';
import { nonDirectAuTLDs } from 'config/config';
import { getAuDirectStatus } from 'containers/auDirect/action';
import { getDomainInformation, getEligibility } from 'containers/domain/action';
import { getCCTLD } from 'containers/domain/methods';
import { DomainModuleStore } from 'containers/domain/moduleConditions';
import { katanaQuery } from 'containers/katana/queries/tanstackTree';
import { getHostingList } from 'containers/services/action';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { genericTerminatedCancelled } from 'utilities/methods/redirects/messageContent';
import { handleRedirect } from 'utilities/methods/redirects/methods';

/**********************************************************************************************************
 *   HOOK START
 **********************************************************************************************************/
export const useDomainManageRouteSelector = () => {
    const domain_information_status = useSelector((state: any) => state.domain.domain_information_status);
    const domain_information_error = useSelector((state: any) => state.domain.domain_information_error);
    const domain_information_data = useSelector((state: any) => state.domain.domain_information_data);
    const hosting_list_status = useSelector((state: any) => state.services.hosting_list_status);
    const hosting_list_data = useSelector((state: any) => state.services.hosting_list_data);

    return {
        domain_information_status,
        domain_information_error,
        domain_information_data,
        hosting_list_status,
        hosting_list_data
    };
};

/**********************************************************************************************************
 *   HOOK START
 **********************************************************************************************************/
export const useHandleDomainInformationEffect = () => {
    /***** HOOKS *****/
    const navigate = useNavigate({ from: '/my-services/domains/$domainId' });
    const { domainId } = useParams({ from: '/my-services/domains/$domainId' });
    const dispatch = useDispatch();
    const { domain_information_data, domain_information_error, domain_information_status } = useDomainManageRouteSelector();

    /***** DERIVED STATE *****/
    const domain = domain_information_data?.attributes?.domain;

    /***** EFFECTS *****/
    useEffect(() => {
        if (!['success', 'error'].includes(domain_information_status)) return;

        if (domain_information_error?.status === 403) {
            navigate({ to: '/my-services/domains' });
            pushNotification(genericTerminatedCancelled);
        } else if (domain_information_data) {
            handleRedirect({
                serviceType: 'domain',
                status: domain_information_data?.attributes?.status,
                domainName: domain,
                history: {
                    push: (url: string) => navigate({ to: url })
                }
            });
        }
    }, [domain_information_status]);

    useEffect(() => {
        if (domain_information_status !== 'success') return;
        if (!domain_information_data?.attributes) return;

        getHostingList();

        // Fetch .au direct availability data if the current domain is a non-direct .au domain
        if (nonDirectAuTLDs.some((nonDirectTld) => domain.endsWith(nonDirectTld))) {
            getAuDirectStatus(domainId);
        }

        // COR components is now being hidden for .id.au domains, and for .au domains with Citizen/Resident eligibility type. So Eligibility data needs to be fetched here
        // Only fetch eligibility data here if its a .au domain and its a direct .au domain
        if (domain.endsWith('au') && nonDirectAuTLDs.every((nonDirectTld) => !domain.endsWith(nonDirectTld))) {
            getEligibility(domainId)(dispatch);
        }
    }, [domain_information_status]);
};

/**********************************************************************************************************
 *   HOOK START
 **********************************************************************************************************/
export const useAUDirectRegisterStatusEffect = () => {
    /***** HOOKS *****/
    const { domainId } = useParams({ from: '/my-services/domains/$domainId' });
    const audirect_register_status = useSelector((state: any) => state.auDirect.audirect_register_status);

    /***** EFFECTS *****/
    useEffect(() => {
        if (audirect_register_status !== 'success') return;

        getDomainInformation();
        getAuDirectStatus(domainId);
    }, [audirect_register_status]);
};

/**********************************************************************************************************
 *   HOOK START
 **********************************************************************************************************/
export const useSyncDomainModuleStoreConditions = () => {
    /***** HOOKS *****/
    const { domain_information_data, hosting_list_data } = useDomainManageRouteSelector();
    const app_check_token_data = useSelector((state: any) => state.app.app_check_token_data);

    /***** RENDER HELPERS *****/
    const domain = domain_information_data?.attributes?.domain;
    const ccTLD = getCCTLD(domain);
    const { data: check_katana_domain_data } = katanaQuery.checkDomain.useQuery(domain, app_check_token_data?.is_vpn);

    const hasCorrespondingHostingService = hosting_list_data?.find(
        (service) => service.attributes?.domain === domain_information_data?.attributes?.domain
    );

    /***** RENDER *****/
    DomainModuleStore.updateChangedStoreKey('general.addHosting.enabled', !hasCorrespondingHostingService);
    DomainModuleStore.updateChangedStoreKey('general.eligibilityInformation.enabled', ccTLD && ccTLD?.au);
    DomainModuleStore.updateChangedStoreKey('general.existingVIPSites.enabled', check_katana_domain_data?.site_exists);
    DomainModuleStore.updateChangedStoreKey('general.newVIPSites.enabled', check_katana_domain_data && !check_katana_domain_data?.site_exists);
    DomainModuleStore.updateChangedStoreKey('security.idProtection.enabled', !ccTLD);
    DomainModuleStore.updateChangedStoreKey('security.transfer.enabled', !ccTLD);
    DomainModuleStore.updateChangedStoreKey('admin.cor.enabled', ccTLD && ccTLD?.au);
};

/* eslint-disable jsx-a11y/alt-text */

/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import classNames from 'classnames';
import React from 'react';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import './_image.scss';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * @type {React.FC<React.ImgHTMLAttributes & { allowMobile?: boolean }>}
 */
export const _ServiceOverviewBannerImage = ({ className, allowMobile = false, ...props }) => {
    /***** RENDER HELPERS *****/
    const _outerClassName = classNames('ServiceOverviewBannerImage__container', className, {
        'ServiceOverviewBannerImage__container--disallowMobile': !allowMobile
    });

    /***** RENDER *****/
    return (
        <div className={_outerClassName}>
            <img {...props} className="ServiceOverviewBannerImage__image" />
        </div>
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { useEffect, useState } from 'react';

/**********************************************************************************************************
 *   HOOK START
 **********************************************************************************************************/
/**
 * @template TValue
 * @param {TValue} value
 * @returns {TValue}
 */
export function useTruthyValue(value) {
    /***** STATE *****/
    const [_value, setValue] = useState(undefined);

    /***** EFFECTS *****/
    useEffect(() => {
        if (value) {
            setValue(value);
        }
    }, [value]);

    /***** HOOK RESULTS *****/
    return _value;
}

/**********************************************************************************************************
 *   HOOK END
 **********************************************************************************************************/

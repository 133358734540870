/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { createRoute, redirect } from '@tanstack/react-router';

/**********************************************************************************************************
 *   ROUTE IMPORTS
 **********************************************************************************************************/
import { SupportRoute } from 'containers/support';
import { routerMiddleware } from 'router/utils/middleware';
import { lazyFN } from 'utilities/methods/tanstack/router/lazyFn';

/**********************************************************************************************************
 *   ROUTE START
 **********************************************************************************************************/
export const TicketsSectionRoute = createRoute({
    getParentRoute: () => SupportRoute,
    path: 'tickets/$section',
    beforeLoad(opts) {
        routerMiddleware.business(this, opts);
        routerMiddleware.authentication('user', opts);

        if (opts.params.section === 'view') {
            throw redirect({ to: '/support/tickets' });
        }
    }
}).lazy(lazyFN(() => import('./index.lazy'), 'LazyTicketsSectionRoute'));

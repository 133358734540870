/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import classNames from 'classnames';
import animationData from 'config/animations/requestAnim.json';
import React from 'react';
import Lottie from 'react-lottie-player';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { createAppliedStylingClasses } from 'components/Utils/methods';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import { appliedStylingPropTypeKeys } from 'components/Loaders/Request/consts';
import './_RequestLoader.scss';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * Shows a loading animation with an optional message
 */
export const _RequestLoader: React.FC<NRequestLoader.Props> = (props) => {
    const { className, fillHeight = 70, fillWidth, height = 70, width = 70, message } = props;

    /***** RENDER HELPERS *****/
    const appliedStylingClasses = createAppliedStylingClasses({
        props,
        keyBoundary: appliedStylingPropTypeKeys,
        componentName: 'requestLoader',
        delimiter: '--'
    });

    const requestLoaderClassNames = classNames('loader', 'requestLoader', className, appliedStylingClasses);

    const fillWrapperStyle = {
        height: fillHeight
    };

    if (fillWidth) {
        fillWrapperStyle.width = fillWidth;
    }

    /***** RENDER *****/
    return (
        <div className={requestLoaderClassNames}>
            <div className="requestLoader__fillWrapper" style={fillWrapperStyle}>
                <Lottie
                    play
                    loop
                    animationData={animationData}
                    style={{
                        height,
                        width
                    }}
                />
                {message ? <div className="loader__text">{message}</div> : ''}
            </div>
        </div>
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

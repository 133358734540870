/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { DateTime } from 'luxon';
import React from 'react';
import { useBoolean } from 'usehooks-ts';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import Anchor from 'components/Anchor';
import OverlayLightbox from 'components/Lightboxes/OverlayLightbox';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import BulkEmailInvoiceForm from 'containers/billing/forms/bulkEmail';

/**********************************************************************************************************
 *   QUERIES
 **********************************************************************************************************/
import { useBulkEmailInvoicesMutation } from 'containers/billing/queries/invoice';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
function BulkEmailInvoices() {
    /***** STATE *****/
    const { value: isBulkLightboxOpen, setTrue: openBulkLightbox, setFalse: closeBulkLightbox } = useBoolean(false);

    /***** QUERIES *****/
    const { mutate: mutateBulkEmailInvoices } = useBulkEmailInvoicesMutation({
        onSuccess: closeBulkLightbox
    });

    /***** FUNCTIONS *****/
    function handleBulkSubmit(values) {
        const attributes = {
            csv: false,
            ...values,
            from_date: DateTime.fromJSDate(values.from_date).toISO(),
            to_date: DateTime.fromJSDate(values.to_date).toISO()
        };

        mutateBulkEmailInvoices(attributes);
    }

    /***** RENDER *****/
    return (
        <>
            <Anchor onClick={openBulkLightbox}>Bulk Email Invoices</Anchor>
            {isBulkLightboxOpen ? (
                <OverlayLightbox title="Bulk Send Invoices" onClose={closeBulkLightbox} onOpen>
                    <BulkEmailInvoiceForm onSubmit={handleBulkSubmit} />
                </OverlayLightbox>
            ) : (
                ''
            )}
        </>
    );
}
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

export default BulkEmailInvoices;

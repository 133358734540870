import { useStore } from '@tanstack/react-store';
import classNames from 'classnames';
import { CheckBoxList } from 'components/Form/CheckBoxList';
import { newActionsStore, SUPER_ACTIONS, useSuperUserActionKey } from 'components/StaffMenu/SuperUser/consts';
import { superUserActionViewedQuery } from 'components/StaffMenu/SuperUser/superUserActionViewedQuery';
import type { SuperUserNamespace } from 'components/StaffMenu/SuperUser/types';
import { Flex } from 'components/Utils/Flex';
import Text from 'components/Utils/Text';
import _ from 'lodash';
import { useEffect, useMemo } from 'react';
import './_SuperUserItem.scss';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type Props = {
    action: SuperUserNamespace.ActionKeys;
};

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const SuperUserItem: React.FC<Props> = ({ action }) => {
    /***** HOOKS *****/
    const { value: superActions, set: setSuperActions } = useSuperUserActionKey(action);
    const shouldDisplay = SUPER_ACTIONS[action]?.useDisplayCondition?.();
    const newActions = useStore(newActionsStore);

    /***** QUERIES *****/
    const { data: viewedSuperActionsData } = superUserActionViewedQuery.useQuery();
    const { mutate: mutateSetViewedAction } = superUserActionViewedQuery.useMutation();

    /***** FUNCTIONS *****/
    function handleViewed() {
        mutateSetViewedAction([...(viewedSuperActionsData ?? []), action]);
    }

    /***** RENDER HELPERS *****/
    const isNew = useMemo(() => !_.includes(viewedSuperActionsData, action), [viewedSuperActionsData, action]);
    const isHidden = useMemo(() => SUPER_ACTIONS[action]?.useDisplayCondition && !shouldDisplay, [shouldDisplay, action]);

    /***** EFFECTS *****/
    useEffect(() => {
        if (isHidden || !isNew) {
            if (newActionsStore.state[action]) {
                newActionsStore.setState((currentNewActions) => {
                    const clonedActions = { ...currentNewActions };
                    delete clonedActions[action];
                    return clonedActions;
                });
            }
            return;
        }

        if (isNew) {
            newActionsStore.setState((currentNewActions) => {
                const clonedActions = { ...currentNewActions };
                clonedActions[action] = action;
                return clonedActions;
            });
        }
    }, [newActions, isNew, isHidden, action]);

    /***** RENDER *****/
    if (isHidden) return;
    return (
        <CheckBoxList.Item
            className={classNames('SuperUserItem', {
                'SuperUserItem--isNew': isNew
            })}
            key={action}
            isChecked={superActions}
            onChange={(isChecked) => {
                setSuperActions(isChecked);
                handleViewed();
            }}
        >
            <Flex className="SuperUserItem__content" items="center" fullWidth>
                <Flex.Child grow>
                    <Text>{SUPER_ACTIONS[action].menuContent}</Text>
                </Flex.Child>
                {isNew && (
                    <Text size--xs white className="SuperUserItem__contentNewText" lead--1>
                        New!
                    </Text>
                )}
            </Flex>
        </CheckBoxList.Item>
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

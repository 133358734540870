/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { isEqual } from 'lodash';
import { useSelector } from 'react-redux';

/**********************************************************************************************************
 *   HOOK START
 **********************************************************************************************************/
function useReduxForm(form: string) {
    return useSelector((state) => state?.form?.[form] ?? {}, { equalityFn: isEqual });
}

/**********************************************************************************************************
 *   HOOK END
 **********************************************************************************************************/

export { useReduxForm };

/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import RequestLoader from 'components/Loaders/Request';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { RenderField, RenderSelectField, domainFieldValidation, renderButton, requiredFieldValidation } from 'utilities/methods/form';

/**********************************************************************************************************
 *   TYPE IMPORTS
 **********************************************************************************************************/
import type { VipRewardsHomePurchaseHostingFormProps } from 'containers/vipRewards/sections/home/purchaseHostingLightbox/types';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import { billingCycles } from 'config/config';

const billingCycleOptions = Object.keys(billingCycles)
    .filter((cycleName) => cycleName !== '1 Year')
    .map((cycleName) => ({
        label: cycleName,
        value: cycleName
    }));

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
let VipRewardsHomePurchaseHostingForm: React.FC<VipRewardsHomePurchaseHostingFormProps> = ({
    children,
    handleSubmit,
    submitting,
    valid,
    domain_add_hosting_calculate_status
}) => {
    /***** RENDER HELPERS *****/
    const isSubmitButtonValid = domain_add_hosting_calculate_status === 'success' && valid;

    /***** RENDER *****/
    return (
        <form className="vipRewardsHomePurchaseHostingForm" onSubmit={handleSubmit}>
            <div className="form__row">
                <div className="form__column full">
                    <Field
                        label="Select Billing Cycle"
                        name="billing_cycle"
                        component={RenderSelectField}
                        validate={[requiredFieldValidation]}
                        options={billingCycleOptions}
                    />
                </div>
            </div>
            <div className="form__row">
                <div className="form__column full">
                    <Field
                        label="Primary Domain Name"
                        name="domain"
                        component={RenderField}
                        type="text"
                        validate={[requiredFieldValidation, domainFieldValidation]}
                    />
                </div>
            </div>

            {children}

            {renderButton(false, submitting, isSubmitButtonValid, submitting ? <RequestLoader /> : 'Confirm and Generate Invoice', 'primary')}
        </form>
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

VipRewardsHomePurchaseHostingForm = reduxForm({
    form: 'vipRewardsHomePurchaseHostingForm'
})(VipRewardsHomePurchaseHostingForm);

VipRewardsHomePurchaseHostingForm = connect((state: any, props) => {
    const initialValues = {
        billing_cycle: 'Monthly'
    };

    if (typeof props.initialPrimaryDomain === 'string') {
        initialValues.domain = props.initialPrimaryDomain;
    }

    return {
        initialValues,
        domain_add_hosting_calculate_status: state.domain.domain_add_hosting_calculate_status
    };
})(VipRewardsHomePurchaseHostingForm);

export default VipRewardsHomePurchaseHostingForm;

/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { useLocation, useNavigate, useRouter } from '@tanstack/react-router';
import { getLoggedInAccountData } from 'containers/dashboard/modules/accounts/methods';
import { useVipRewards } from 'containers/vipRewards/hooks';

/*   ACTIONS
 *****************************************************/
import { useLayoutEffect } from 'react';

/**********************************************************************************************************
 *   TYPE IMPORTS
 **********************************************************************************************************/
import type { RouterType } from 'router/router';
import type { AnyRouteId } from 'router/types';

/**********************************************************************************************************
 *   HOOK START
 **********************************************************************************************************/
export const useAuthenticatedVIPRewardsRoute = (expectsMember: boolean, to: AnyRouteId) => {
    /***** HOOKS *****/
    const { isMember } = useVipRewards();
    const { buildLocation } = useRouter();
    const location = useLocation();
    const navigate = useNavigate();

    /***** EFFECTS *****/
    useLayoutEffect(() => {
        const triggerNavigate = () => navigate(buildLocation({ ...location, to }));

        if (isMember && !expectsMember) {
            triggerNavigate();
        }

        if (!isMember && expectsMember) {
            triggerNavigate();
        }
    }, [isMember]);
};

/**
 * Returns whether or not the logged in user is a VIP member.
 */
export const isVIPMember = (context: RouterType['options']['context']) => {
    const { login_account_list_data } = context.store.getState().login;
    const { app_check_token_data } = context.store.getState().app;

    const loggedInAccount = getLoggedInAccountData(login_account_list_data, app_check_token_data);
    const rewardTier = loggedInAccount?.reward_tier;

    return !!rewardTier;
};

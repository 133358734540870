/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { values } from 'lodash';
import GoogleFontLoader from 'react-google-font';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import { CardSelectGrid } from 'components/Form/CardSelectGrid';
import RequestLoader from 'components/Loaders/Request';
import NXBox from 'components/NXBox';
import BackgroundColour from 'components/Utils/BackgroundColour';
import { Flex } from 'components/Utils/Flex';
import Padding from 'components/Utils/Padding';
import Text from 'components/Utils/Text';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import ContentEditorModuleContent from '../../ContentEditorLightbox/contentEditorModuleContent';

/**********************************************************************************************************
 *   QUERIES
 **********************************************************************************************************/
import { katanaQuery } from 'containers/katana/queries/tanstackTree';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { filterBodyOrHeadingFonts } from 'containers/katana/containers/contentEditorModules/webpageFonts/methods';
import { useKatanaParams } from 'containers/katana/hooks/useSetupEditorRouteParams';
import { useAppViewport } from 'utilities/hooks/useAppViewport/useAppViewport';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import type { CardSelectGridNamespace } from 'components/Form/CardSelectGrid/types';
import { FONT_META_TYPES, FormFieldsEnum } from './consts';
const { PRIMARY_FONT, SECONDARY_FONT } = FormFieldsEnum;

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
const _WebpageFontsContentModule = () => {
    /***** HOOKS *****/
    const { katanaServiceId } = useKatanaParams();
    const isMobile = useAppViewport(['xs', 'sm']);

    /***** QUERIES *****/
    const { data: get_fonts_meta_data, isLoading: isGetFontsMetaLoading } = katanaQuery.serviceID.meta.getFonts.useQuery(Number(katanaServiceId));

    /***** RENDER HELPERS *****/
    const fonts = values(get_fonts_meta_data);

    const headerFontOptions: CardSelectGridNamespace.SelectCard.FontOption[] = fonts
        .filter(({ types }) => types.includes(FONT_META_TYPES.HEADING))
        .map((font) => ({
            type: 'font',
            fontID: font.id,
            fontFamily: font.font_family,
            text: font.font_family
        }));
    const bodyFontOptions: CardSelectGridNamespace.SelectCard.CustomOption[] = fonts
        .filter(({ types }) => types.includes(FONT_META_TYPES.BODY))
        .map((font) => ({
            type: 'custom',
            value: font.id,
            content: (
                <BackgroundColour secondary>
                    <Padding xy={4} paddingOnly>
                        <Flex direction="column">
                            <Text size--s align--left black bold break-word _style={{ fontFamily: font.font_family }}>
                                This font is {font.font_family}
                            </Text>
                            <Text size--s align--left black break-word _style={{ fontFamily: font.font_family }}>
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna
                                aliqua.
                            </Text>
                        </Flex>
                    </Padding>
                </BackgroundColour>
            )
        }));

    const availableFonts = filterBodyOrHeadingFonts(fonts).map(({ name }) => ({ font: name }));

    const multiLoaderData = RequestLoader.MultiLoader.useLoadersData([{ condition: isGetFontsMetaLoading, message: 'Loading Font definitions...' }]);

    /***** RENDER *****/
    return (
        <>
            <GoogleFontLoader fonts={availableFonts} />
            <NXBox.TopAccordion
                title="Website Font Selection"
                description="You have the option to modify the default fonts applied from your chosen template. Select a primary and secondary font to be used across your website."
            >
                <RequestLoader.MultiLoader loaders={multiLoaderData}>
                    <NXBox.DefaultPadding>
                        <ContentEditorModuleContent>
                            <Text black size--m medium>
                                Choose a primary font
                            </Text>
                            <Text secondary size--s italic>
                                Used for large headings and important text
                            </Text>
                            <CardSelectGrid.HookForm
                                name={PRIMARY_FONT}
                                alignItems="stretch"
                                selectionText="Choose this heading font"
                                options={headerFontOptions}
                                itemsPerRow={isMobile ? 1 : 2}
                            />

                            <Text black size--m medium>
                                Choose a secondary font
                            </Text>
                            <Text secondary size--s italic>
                                Used for smaller text like the content on your webpage
                            </Text>
                            <CardSelectGrid.HookForm
                                name={SECONDARY_FONT}
                                alignItems="stretch"
                                selectionText="Choose this secondary font"
                                options={bodyFontOptions}
                                itemsPerRow={isMobile ? 1 : 2}
                            />
                        </ContentEditorModuleContent>
                    </NXBox.DefaultPadding>
                </RequestLoader.MultiLoader>
            </NXBox.TopAccordion>
        </>
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
const WebpageFontsContentModule = Object.assign(_WebpageFontsContentModule, {
    formFields: FormFieldsEnum
});

export default WebpageFontsContentModule;

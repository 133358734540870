/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { useContext } from 'react';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { toTitleCase } from 'utilities/methods/commonActions';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import { SectionDefinitionContext } from 'containers/katana/components/contentDefinitionForm/consts';

/**
 * Id is the id of the section i.e.: katana.v1.contact
 * @param {string} sectionDefinitionID
 */
export function getContentDefinitionFormName(sectionDefinitionID) {
    if (!sectionDefinitionID) {
        return 'ContentDefinitionForm';
    }
    // Replace all "." and "/"" in string with ' '
    const replacedID = sectionDefinitionID.replaceAll('.', ' ').replaceAll('/', ' ');
    const titleCaseId = toTitleCase(replacedID).replaceAll(' ', '');
    return `ContentDefinitionForm${titleCaseId}Form`;
}

export function useSectionDefinitionContext() {
    return useContext(SectionDefinitionContext);
}

/**********************************************************************************************************
 *   TYPE IMPORTS
 **********************************************************************************************************/

import { useParams } from '@tanstack/react-router';
import { getIsLegacyDomainTransferLocked } from 'containers/domain/tppDomains/modules/transferLock/methods';
import { useTppDomainTransferLockQuery } from 'containers/domain/tppDomains/queries/useTppDomainTransferLockQuery';
import { useCallback, useEffect } from 'react';
import { createModuleStore } from 'utilities/methods/createModuleStore';

/**********************************************************************************************************
 *   MODULE STORE START
 **********************************************************************************************************/
/***** EXPORTS *****/
export const LegacyDomainModuleStore = createModuleStore(
    {
        general: {
            migrate: {
                enabled: true,
                label: 'Migrate Domain',
                to: '/my-services/domains/legacy/$legacyDomainId/general',
                hash: 'migrate'
            },
            overview: {
                enabled: true,
                label: 'Domain Overview',
                to: '/my-services/domains/legacy/$legacyDomainId/general',
                hash: 'overview'
            },
            renew: {
                enabled: true,
                label: 'Renew Domain Name',
                to: '/my-services/domains/legacy/$legacyDomainId/general',
                hash: 'renew'
            },
            contacts: {
                enabled: true,
                label: 'Contacts',
                to: '/my-services/domains/legacy/$legacyDomainId/general',
                hash: 'contacts'
            },
            nameservers: {
                enabled: true,
                label: 'Domain Nameservers',
                to: '/my-services/domains/legacy/$legacyDomainId/general',
                hash: 'nameservers'
            }
        },
        security: {
            transferLock: {
                enabled: true,
                label: 'Transfer Lock',
                to: '/my-services/domains/legacy/$legacyDomainId/security',
                hash: 'transfer'
            },
            eppPassword: {
                enabled: true,
                label: 'EPP Password',
                to: '/my-services/domains/legacy/$legacyDomainId/security',
                hash: 'epp'
            }
        }
    },
    LegacyDomainModuleConditions
);

/**********************************************************************************************************
 *   HOOK START
 **********************************************************************************************************/
const useIsLegacyDomainTransferLocked = (legacyDomainId: string) => {
    return useTppDomainTransferLockQuery(legacyDomainId, {
        select: useCallback((data: any) => getIsLegacyDomainTransferLocked(data.data.attributes[0]), [])
    });
};

function LegacyDomainModuleConditions(args: any) {
    const store = createModuleStore.castHookProps(args);

    /***** HOOKS *****/
    const { legacyDomainId } = useParams({ from: '/my-services/domains/legacy/$legacyDomainId' });

    /***** QUERIES *****/
    const { data: isTransferLocked } = useIsLegacyDomainTransferLocked(legacyDomainId);

    /***** EFFECTS *****/
    useEffect(() => {
        store.batch(() => {
            store.updateChangedStoreKey('security.transferLock.enabled', !!isTransferLocked);
        });
    });
}

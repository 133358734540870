/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { useNavigate } from '@tanstack/react-router';
import { useDispatch, useSelector } from 'react-redux';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import Title from 'components/Title';

/*   ACTIONS
 *****************************************************/
import { getHostingProduct, getHostingProductName } from 'containers/hosting/methods';
import { prepStateForSubaccount, whmLogin } from 'containers/hosting/state/accountActions';
import { cpanelSubAccountLogin } from 'containers/hosting/state/resellerActions';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const _HostingSubaccountWithUsernameTitle = () => {
    /***** HOOKS *****/
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const hosting_information_data = useSelector((state: any) => state.hosting.hosting_information_data);
    const hosting_information_subaccount_data = useSelector((state: any) => state.hosting.hosting_information_subaccount_data);

    /***** DERIVED *****/
    const { type, id } = hosting_information_data;
    const product = getHostingProduct(hosting_information_data);
    const productName = getHostingProductName(product);
    const { domain } = hosting_information_data.attributes;

    /***** RENDER *****/
    return (
        <Title
            serviceTitle={domain}
            serviceSubTitle={`${productName} (Reseller)`}
            serviceType={type}
            useSubtitle
            subtitleData={{
                title: hosting_information_subaccount_data.domain,
                action: {
                    label: 'Login to cPanel',
                    onClick: (e) => {
                        e.preventDefault();
                        cpanelSubAccountLogin(id, hosting_information_subaccount_data.username)(dispatch);
                    }
                }
            }}
            action={[
                {
                    className: 'listAccount__action',
                    label: 'List Accounts',
                    type: 'onClick',
                    color: 'secondary',
                    onClick: (e) => {
                        e.preventDefault();
                        prepStateForSubaccount()(dispatch);
                        navigate({
                            to: '/my-services/hosting/$serviceId/reseller',
                            hash: 'subaccounts',
                            params: { serviceId: id }
                        });
                    }
                },
                {
                    className: 'listAccount__action',
                    label: 'WHM Login',
                    type: 'onClick',
                    color: 'notice',
                    onClick: (e) => {
                        e.preventDefault();
                        whmLogin(id)(dispatch);
                    }
                }
            ]}
        />
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import Anchor from 'components/Anchor';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import './_OverlayLightboxBackLink.scss';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type OverlayLightBoxProps = {
    text: string;
    onClick: React.MouseEventHandler<HTMLButtonElement | HTMLAnchorElement>;
};

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * "GO BACK" link that goes under the "Confirm" button in most lightboxes
 */
export const OverlayLightboxBackLink: React.FC<OverlayLightBoxProps> = ({ text = 'GO BACK', onClick }) => {
    /***** RENDER *****/
    return (
        <Anchor className="OverlayLightboxBackLink" color="primary" onClick={onClick}>
            {text}
        </Anchor>
    );
};

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

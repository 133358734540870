/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React from 'react';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import SolidTag from 'components/Tags/SolidTag';
import { DefaultImage } from 'containers/vipRewards/modules/rewardCard/new/image/default';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type ImageComponent = React.FC<{
    tag?: React.ReactNode | string;
    children: React.ReactNode;
}>;

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
const Image: ImageComponent = ({ tag, children }) => {
    /***** RENDER HELPERS *****/
    const renderTag = () => {
        if (!tag) return null;

        if (typeof tag === 'string') {
            return <SolidTag color="black">{tag}</SolidTag>;
        }

        return tag;
    };

    /***** RENDER *****/
    return (
        <div className="rewardCard__top">
            {renderTag()}
            {children}
        </div>
    );
};

/***** EXPORTS *****/
export const RewardCardImage = Object.assign(Image, {
    Default: DefaultImage
});

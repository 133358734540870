import { CheckBoxList } from 'components/Form/CheckBoxList';
import { Input } from 'components/Form/Input';
import { Radio } from 'components/Form/Radio';
import { Select } from 'components/Form/Select';
import RequestLoader from 'components/Loaders/Request';
import NXBox from 'components/NXBox';
import { Revealer } from 'components/Revealer';
import { Flex } from 'components/Utils/Flex';
import Text from 'components/Utils/Text';
import { fieldLabelWithOptional } from 'containers/katana/components/fieldLabelWithOptional';
import { CallToActionType } from 'containers/katana/containers/contentEditorModules/callToAction/consts';
import { useGetMultiLoaderData } from 'containers/katana/containers/contentEditorModules/callToAction/useGetMultiLoaderData';
import { useGetRoutableCallToActionSections } from 'containers/katana/containers/contentEditorModules/callToAction/useGetRoutableCallToActionSections';
import { usePostNavigationMutation } from 'containers/katana/queries/usePostNavigationMutation';
import { useEffect } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import './_CallToActionContentModule.scss';
const { CALL_TO_ACTION } = usePostNavigationMutation.FORM_FIELD_KEYS;

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const CallToActionContentModule = Object.assign(
    function CallToActionContentModule() {
        /***** HOOKS *****/
        const values = useWatch();
        const routableOptions = useGetRoutableCallToActionSections();
        const multiLoaderData = useGetMultiLoaderData();
        const { setValue, clearErrors } = useFormContext();

        /***** RENDER HELPERS *****/
        const isLinkOrSection = [CallToActionType.LINK, CallToActionType.SECTION].includes(values?.[CALL_TO_ACTION]?.type);
        const isShowingCTAContent = Boolean(values?.[CALL_TO_ACTION]?.enabled);

        /***** EFFECTS *****/
        useEffect(() => {
            // Only do dynamic change if the value for type is falsy
            if (!values?.[CALL_TO_ACTION]?.type) {
                setValue(`${CALL_TO_ACTION}.type`, CallToActionType.LINK);
            }
        }, [values]);

        /***** RENDER *****/
        return (
            <NXBox.TopAccordion
                className="CallToActionContentModule"
                title="Customer Engagement Button"
                description="A button on your website that tells the user to take a specified action."
            >
                <NXBox.DefaultPadding>
                    <RequestLoader.MultiLoader loaders={multiLoaderData}>
                        <CheckBoxList>
                            <CheckBoxList.Item.HookForm name={`${CALL_TO_ACTION}.enabled`}>
                                Display customer engagement button
                            </CheckBoxList.Item.HookForm>
                            {isShowingCTAContent && (
                                <Radio.Circle.Button.ActiveRender.HookForm
                                    name={`${CALL_TO_ACTION}.type`}
                                    fullWidth
                                    onSelect={(newSelection) => {
                                        if (newSelection === values?.call_to_action?.type) {
                                            return;
                                        }
                                        setValue(`${CALL_TO_ACTION}.enabled`, true);
                                        setValue(`${CALL_TO_ACTION}.text`, '');
                                        setValue(`${CALL_TO_ACTION}.value`, '');
                                        setValue(`${CALL_TO_ACTION}.section_id`, null);
                                        clearErrors(`${CALL_TO_ACTION}.text`);
                                        clearErrors(`${CALL_TO_ACTION}.value`);
                                        clearErrors(`${CALL_TO_ACTION}.section_id`);
                                    }}
                                >
                                    <Radio.Circle.Button.ActiveRender.Item
                                        color="confirm"
                                        label="A button that takes the user somewhere"
                                        value={
                                            values?.[CALL_TO_ACTION]?.type === CallToActionType.LINK
                                                ? CallToActionType.LINK
                                                : CallToActionType.SECTION
                                        }
                                        isSelected={isLinkOrSection}
                                    >
                                        {({ shouldRenderChildren }) => (
                                            <Revealer isOpen={shouldRenderChildren}>
                                                <CheckBoxList>
                                                    <CheckBoxList>
                                                        <Input.HookForm
                                                            label={
                                                                <Text size--m medium black>
                                                                    What will the button say?{' '}
                                                                    <Text warn bold>
                                                                        *
                                                                    </Text>
                                                                </Text>
                                                            }
                                                            name={`${CALL_TO_ACTION}.text`}
                                                        />

                                                        <Radio.Circle.Button.ActiveRender.HookForm
                                                            name={`${CALL_TO_ACTION}.type`}
                                                            label={
                                                                <Text size--m medium black>
                                                                    Where will it go when clicked?
                                                                </Text>
                                                            }
                                                            onSelect={() => {
                                                                setValue(`${CALL_TO_ACTION}.value`, '');
                                                                setValue(`${CALL_TO_ACTION}.section_id`, null);
                                                                clearErrors(`${CALL_TO_ACTION}.text`);
                                                                clearErrors(`${CALL_TO_ACTION}.value`);
                                                                clearErrors(`${CALL_TO_ACTION}.section_id`);
                                                            }}
                                                        >
                                                            <Radio.Circle.Button.ActiveRender.Item
                                                                color="confirm"
                                                                label={
                                                                    <>
                                                                        Somewhere on my website{' '}
                                                                        <Text warn bold>
                                                                            *
                                                                        </Text>
                                                                    </>
                                                                }
                                                                value={CallToActionType.SECTION}
                                                            >
                                                                <Select.HookForm name={`${CALL_TO_ACTION}.section_id`} options={routableOptions} />
                                                            </Radio.Circle.Button.ActiveRender.Item>

                                                            <Radio.Circle.Button.ActiveRender.Item
                                                                color="confirm"
                                                                label={
                                                                    <>
                                                                        To an external URL{' '}
                                                                        <Text warn bold>
                                                                            *
                                                                        </Text>
                                                                    </>
                                                                }
                                                                value={CallToActionType.LINK}
                                                            >
                                                                <Input.HookForm
                                                                    name={`${CALL_TO_ACTION}.value`}
                                                                    placeholder="E.g. https://ventraip.com.au/"
                                                                />
                                                            </Radio.Circle.Button.ActiveRender.Item>
                                                        </Radio.Circle.Button.ActiveRender.HookForm>
                                                    </CheckBoxList>
                                                </CheckBoxList>
                                            </Revealer>
                                        )}
                                    </Radio.Circle.Button.ActiveRender.Item>

                                    <Radio.Circle.Button.ActiveRender.Item
                                        color="confirm"
                                        label="A button that shows my phone number"
                                        value={CallToActionType.PHONE}
                                    >
                                        {({ shouldRenderChildren }) => (
                                            <Revealer isOpen={shouldRenderChildren}>
                                                <CheckBoxList>
                                                    <CheckBoxList>
                                                        <Flex direction="column">
                                                            <Input.HookForm
                                                                label={
                                                                    <Text black size--m medium>
                                                                        {fieldLabelWithOptional('Pretext')}
                                                                    </Text>
                                                                }
                                                                name={`${CALL_TO_ACTION}.text`}
                                                                placeholder="E.g. Call Us on"
                                                            />
                                                            <Flex direction="column" gap={1}>
                                                                <Text black size--m medium>
                                                                    Business phone number
                                                                </Text>
                                                                <Input.HookForm name={`${CALL_TO_ACTION}.value`} placeholder="04 1234 5678" />
                                                            </Flex>
                                                        </Flex>
                                                    </CheckBoxList>
                                                </CheckBoxList>
                                            </Revealer>
                                        )}
                                    </Radio.Circle.Button.ActiveRender.Item>
                                </Radio.Circle.Button.ActiveRender.HookForm>
                            )}
                        </CheckBoxList>
                    </RequestLoader.MultiLoader>
                </NXBox.DefaultPadding>
            </NXBox.TopAccordion>
        );
    },
    {
        formFields: { CALL_TO_ACTION }
    }
);
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

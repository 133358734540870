/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import classNames from 'classnames';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { createAppliedStylingClasses } from 'components/Utils/methods';

/**********************************************************************************************************
 *   TYPE IMPORTS
 **********************************************************************************************************/
import type { ShapeSquareProps } from 'utilities/consts';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import './_NXSquare.scss';

const NXSquareAppliedStylingProps: ShapeSquareProps = {
    ratio: 1
};

const NXSquareAppliedStylingPropTypeKeys = Object.keys(NXSquareAppliedStylingProps);

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type NXSquareProps = {
    ratio?: number | boolean;
    children?: React.ReactNode;
    className?: String;
};

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const NXSquare: React.FC<NXSquareProps> = (props) => {
    const { ratio = true, children, className } = props;

    /***** RENDER HELPERS *****/
    const appliedStylingClasses = createAppliedStylingClasses({
        props,
        keyBoundary: NXSquareAppliedStylingPropTypeKeys,
        componentName: 'NXSquare',
        delimiter: '--'
    });

    const isSquarePropNumber = typeof ratio === 'number';
    const styles = {
        paddingTop: isSquarePropNumber ? `calc(100% * ${ratio})` : '100%'
    };

    /***** RENDER *****/
    return (
        <div className={classNames('NXSquare', appliedStylingClasses, className)} style={styles}>
            <div className="NXSquare__inner">{children}</div>
        </div>
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import classNames from 'classnames';
import React from 'react';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import './_Switch.scss';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
import type { NSwitch } from 'components/Switch/types';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/

const _Switch: React.FC<NSwitch.Props> = ({ label, className, onClick, disabled, switchvalue, size = 'default', loading }) => {
    let displayValue;
    switch (switchvalue) {
        case '0':
            displayValue = false;
            break;

        case '1':
            displayValue = true;
            break;

        default:
            displayValue = switchvalue;
            break;
    }

    const renderSwitch = () => (
        <button
            type="button"
            className={classNames('Switch', className, displayValue ? 'Switch--on' : 'Switch--off', {
                'Switch--disabled': disabled,
                'Switch--loading': loading,
                [`Switch--size-${size}`]: size
            })}
            onClick={disabled || loading ? null : onClick}
        >
            <div className="Switch__wrapper">
                <div className="Switch__toggle" />
            </div>
        </button>
    );

    if (label) {
        return (
            <div className="Switch__container">
                <p className="Switch__label">{label}</p>
                {renderSwitch()}
            </div>
        );
    }

    return renderSwitch();
};
export default _Switch;
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

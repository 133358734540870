/*   ACTIONS
 *****************************************************/
import { getIncludedObjBasedOnType } from 'utilities/methods/commonActions';

/**
 * @private - For use in the _TableBody component.
 * @param {NonNullable<import("utilities/api/_services").Services.VPS.TListData['data'][number]>} data
 */
export const getVPSInformationFromData = (data) => {
    const { product, invoice } = {
        product: getIncludedObjBasedOnType(data.included, 'product'),
        invoice: getIncludedObjBasedOnType(data.included, 'invoice')
    };

    return {
        domain: data.attributes.domain,
        name: product?.attributes.name || invoice?.attributes.name,
        nextDueDate: data.attributes.next_due_date,
        billingCycle: data.attributes.billing_cycle,
        product,
        invoice
    };
};

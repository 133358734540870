/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

/**********************************************************************************************************
 *   HOOK START
 **********************************************************************************************************/
/**
 * Handles the storing and updating of select hosting configs when using the resource sliders
 */
function useCustomHostingConfigs() {
    /***** HOOKS *****/
    const hosting_resource_config_data = useSelector((state) => state.hosting.hosting_resource_config_data);
    const hosting_resource_config_status = useSelector((state) => state.hosting.hosting_resource_config_status);

    /***** STATE *****/
    const [availableConfigs, setAvailableConfigs] = useState(null);
    const [selectedConfigs, setSelectedConfigs] = useState(null);
    const [labels, setLabels] = useState(null);

    /***** FUNCTIONS *****/
    function initSliders() {
        /**
         * Init slider node labels
         */
        const cpuLabels = {};
        const diskLabels = {};
        const memLabels = {};

        hosting_resource_config_data.attributes['CPU']
            .filter((option) => option.show_order)
            .forEach((resource, index) => (cpuLabels[index] = resource.name));
        hosting_resource_config_data.attributes['Disk Space']
            .filter((option) => option.show_order)
            .forEach((resource, index) => (diskLabels[index] = resource.name));
        hosting_resource_config_data.attributes['Memory']
            .filter((option) => option.show_order)
            .forEach((resource, index) => (memLabels[index] = resource.name));

        setLabels({
            cpu: cpuLabels,
            disk: diskLabels,
            mem: memLabels
        });

        /**
         * Init available configs
         */
        const availableConfigsToSet = {
            cpu: hosting_resource_config_data.attributes['CPU']
                .filter((option) => option.show_order)
                .map((resource, index) => ({ ...resource, orderValue: index })),
            disk: hosting_resource_config_data.attributes['Disk Space']
                .filter((option) => option.show_order)
                .map((resource, index) => ({ ...resource, orderValue: index })),
            mem: hosting_resource_config_data.attributes['Memory']
                .filter((option) => option.show_order)
                .map((resource, index) => ({ ...resource, orderValue: index }))
        };

        setAvailableConfigs(availableConfigsToSet);

        /**
         * Init selected configs
         */
        const selectedConfigsToSet = {
            cpu: {
                ...availableConfigsToSet.cpu[0],
                orderValue: 0
            },
            disk: {
                ...availableConfigsToSet.disk[0],
                orderValue: 0
            },
            mem: {
                ...availableConfigsToSet.mem[0],
                orderValue: 0
            }
        };

        setSelectedConfigs(selectedConfigsToSet);
    }

    /***** EFFECTS *****/
    useEffect(() => {
        if (hosting_resource_config_status === 'success' && hosting_resource_config_data) {
            initSliders();
        }
    }, [hosting_resource_config_status]);

    /***** RETURN *****/
    return {
        selectedConfigs,
        setSelectedConfigs,
        availableConfigs,
        labels
    };
}
/**********************************************************************************************************
 *   HOOK END
 **********************************************************************************************************/

export default useCustomHostingConfigs;

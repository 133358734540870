/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React from 'react';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import Text from 'components/Utils/Text';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { useAppViewport } from 'utilities/hooks/useAppViewport/useAppViewport';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import './_ServiceOverviewBannerDescription.scss';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * @type {React.FC<Partial<NText.Props> & { children: React.ReactNode }>}
 */
const _ServiceOverviewBannerDescription = ({ children, ...props }) => {
    /***** HOOKS *****/
    const isMedium = useAppViewport('md');
    const alignCenter = useAppViewport(['xs', 'sm']);

    /***** RENDER *****/
    return (
        <Text size--l size--m={isMedium} align--center={alignCenter} white medium className="NXServiceOverviewBannerDescription" {...props}>
            {children}
        </Text>
    );
};

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

export { _ServiceOverviewBannerDescription };

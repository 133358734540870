import Fuse from 'fuse.js';

const fuseSearch = {
    data: [],
    fuse: null
};

/**
 * Find the fuse search associated with the pattern.
 * @param data{Array<Object>}
 * @returns {Fuse<unknown>|null}
 */
export function getFuseSearch(data, fuseOptions) {
    if (fuseSearch.fuse && fuseSearch.data === data) {
        return fuseSearch.fuse;
    }

    fuseSearch.data = data;
    const newFuseSearch = new Fuse(data, fuseOptions);
    fuseSearch.fuse = newFuseSearch;

    return newFuseSearch;
}

/**
 * Fuse search a pattern against a particular fuse search.
 * @param _fuseSearch{Fuse<unknown>}
 * @param pattern{string}
 * @returns {*}
 */
export function filterFuseSearch(_fuseSearch, pattern = '') {
    return _fuseSearch.search(pattern);
}

/**
 * Use the fuse search that's made for the data set you are looking to use.
 * @param data{Array<Object>}
 * @param pattern{string}
 * @returns {Array<Object>}
 */
export function filterDefaultFuseSearch(data, pattern = '', fuseOptions) {
    if (!data || !pattern) return [];

    const _fuseSearch = getFuseSearch(data, fuseOptions);
    if (!_fuseSearch) return [];

    return filterFuseSearch(_fuseSearch, pattern);
}
let fuseSearchTimeout = null;

/**
 * Filter fuse but delay it so that you can't call it repeatedly,
 * this requires a callback to pass the search results to.
 * @param data{Array<Object>}
 * @param pattern{string}
 * @param callBack{function}
 */
export function filterDelayedFuseSearch(data, pattern = '', fuseOptions, callBack) {
    clearTimeout(fuseSearchTimeout);
    fuseSearchTimeout = setTimeout(
        () => {
            callBack(filterDefaultFuseSearch(data, pattern, fuseOptions));
        },
        Math.min(pattern.length * 10, 1000)
    );
}

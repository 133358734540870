/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { useContext } from 'react';
import { useSelector } from 'react-redux';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import { selectorEqualityCheckSyncErrors, selectorSyncErrors } from 'components/Form/Button/reduxForm';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import { ContentEditorRenderModulesContext } from 'containers/katana/containers/ContentEditorLightbox/contentEditorRenderModules/consts';

/**********************************************************************************************************
 *   HOOK START
 **********************************************************************************************************/
export function useGetDynamicFormFieldSyncErrors() {
    /***** HOOKS *****/
    const { form } = useContext(ContentEditorRenderModulesContext);

    /***** HOOK RESULTS *****/
    return useSelector(selectorSyncErrors(form), { equalityFn: selectorEqualityCheckSyncErrors });
}
/**********************************************************************************************************
 *   HOOK END
 **********************************************************************************************************/

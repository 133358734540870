import { modifyInitialValues } from 'containers/katana/hooks/useModifiedInitialValues/methods';
import { useKatanaParams } from 'containers/katana/hooks/useSetupEditorRouteParams';
import { katanaQuery } from 'containers/katana/queries/tanstackTree';
import type { KatanaNamespace } from 'containers/katana/types';
import { useMemo } from 'react';

/**********************************************************************************************************
 *   HOOK START
 **********************************************************************************************************/
export function useFinaliseDefaultFormValues(subpageRouteData: KatanaNamespace.SubPageRouteData) {
    /***** HOOKS *****/
    const { katanaServiceId } = useKatanaParams();

    /***** QUERIES *****/
    const { data: get_katana_site_info_data, isLoading: isGetKatanaSiteInfoLoading } = katanaQuery.serviceID.getSiteInfo.useQuery(katanaServiceId);

    /***** HOOK RESULTS *****/
    return useMemo(() => {
        const queryData = {
            ...(get_katana_site_info_data ?? {})
        };

        const modifiedInitialValues = modifyInitialValues({ initialValues: queryData, modules: subpageRouteData.modules });

        if (!isGetKatanaSiteInfoLoading && get_katana_site_info_data) {
            return modifiedInitialValues;
        }

        return undefined;
    }, [get_katana_site_info_data, isGetKatanaSiteInfoLoading]);
}

/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React from 'react';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
function ScamWarningBackground() {
    /*   RENDER COMPONENT
     **********************************************************************************************************/
    return (
        <div className="scamWarning__background">
            <div className="scamWarning__backgroundCircle scamWarning__backgroundCircle--one">
                <div className="scamWarning__backgroundCircle scamWarning__backgroundCircle--two">
                    <div className="scamWarning__backgroundCircle scamWarning__backgroundCircle--three"></div>
                </div>
            </div>
        </div>
    );
}

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
export default ScamWarningBackground;

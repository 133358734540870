/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React from 'react';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import OutlineButton from 'components/Buttons/OutlineButton';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { useGetCaveat } from 'containers/katana/formFields/methods/useGetCaveat';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import { CaveatTypes } from 'containers/katana/formFields/caveatTypes';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * @param {import('containers/katana/components/EntryCreationBehaviourHandler/wrapper/types').EntryCreationBehaviourHandlerCoreProps} props
 */
export function RenderDefaultButton({ property, fields, properties }) {
    /***** FUNCTIONS *****/
    function addNew() {
        fields.push({});
    }

    /***** RENDER HELPERS *****/
    const singularName = useGetCaveat(CaveatTypes.SINGULAR_NAME, property.caveats);

    /***** RENDER *****/
    return (
        <OutlineButton className="KatanaRepeatedFormField__addAnotherButton" type="onClick" onClick={addNew}>
            Add another {singularName ?? property.name}
        </OutlineButton>
    );
}

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

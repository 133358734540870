export const domain_transfer_url =
    'https://ventraip.com.au/domain-names/transfer/?utm_campaign=transfer-domain&utm_source=ccp&utm_medium=service-banner';

const purchaseLinks = /** @type {const} */ ({
    'Domain Names': { link: 'https://ventraip.com.au/domain-names/register/', icon: 'domain-names', utmCampaign: 'register-domain' },
    'Web Hosting': { link: 'https://ventraip.com.au/web-hosting/', icon: 'business-hosting', utmCampaign: 'web-hosting' },
    'Email Hosting': { link: 'https://ventraip.com.au/email-apps/email/', icon: 'email-hosting', utmCampaign: 'email-hosting' },
    'Google Workspace': { link: 'https://ventraip.com.au/email-apps/google-workspace/', icon: 'gsuite', utmCampaign: 'google-workspace' },
    'Microsoft 365': { link: 'https://ventraip.com.au/email-apps/microsoft-365/', icon: 'microsoft', utmCampaign: 'ms365' },
    'VPS': { link: 'https://ventraip.com.au/server/fully-managed-vps/', icon: 'vps', utmCampaign: 'fully-managed-vps' },
    'SSL Certificates': { link: 'https://ventraip.com.au/ssl-certificates/', icon: 'ssl2', utmCampaign: 'ssl-certificate' }
});

/**
 * Use this function to get the ventraip website purchase link for any type of service. Calling the function with only one arg provide the link with no tracking UTM's. Provide the "tracking" arg to add UTM's to the url
 * @param {(keyof typeof purchaseLinks) | null} serviceType
 * @param {Object} [tracking={}] - An optional object containing options.
 * @param {string} tracking.utmMedium - The first property.
 * @param {string} [tracking.utmSource=''] - The second property.
 * @param {string} [tracking.utmCampaign=''] - The third property.
 * @param {string} replaceUrl - Use this if you want to use a different URL to any of the main purchase lins
 */
export function getPurchaseLink(serviceType = null, tracking, replaceUrl = null) {
    if (!serviceType && !replaceUrl) {
        throw new Error('Must provide either "serviceType" or "replaceUrl"');
    }

    const link = replaceUrl || purchaseLinks[serviceType]?.link || '';

    if (!tracking) {
        return link;
    }

    const { utmMedium, utmCampaign = purchaseLinks[serviceType]?.utmCampaign, utmSource = 'ccp' } = tracking;

    return `${link}?utm_campaign=${utmCampaign}&utm_source=${utmSource}&utm_medium=${utmMedium}`;
}

/**
 * Gets the icon for a service type's purchase link
 * @param {keyof typeof purchaseLinks} serviceType
 */
export function getPurchaseLinkIcon(serviceType) {
    return purchaseLinks[serviceType]?.icon || '';
}

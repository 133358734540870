/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import RequestLoader from 'components/Loaders/Request';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import Text from 'components/Utils/Text';

/**********************************************************************************************************
 *   QUERIES
 **********************************************************************************************************/
import { useGetAllServicesQuery } from 'containers/support/queries/useGetAllServicesQuery';

/***** FUNCTIONS *****/
const getServiceName = ({ attributes: { name, domain } }) => name ?? domain;

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * Renders the search results for an invoice search. This search can be done by invoice ID or service name.
 *
 * @param {{ search: string, onClick: (service: string) => void }} props
 */
const RenderSearchResults = ({ search, onClick }) => {
    /***** QUERIES *****/
    const { data, status } = useGetAllServicesQuery();

    // if the search value is a number, don't show the results (as they are searching for an invoice ID)
    if (!isNaN(Number(search))) return null;

    // if the services are still loading, show a loader
    if (status !== 'success') return <RequestLoader />;

    // filter services by keyword
    const filteredServices = Object.values(data)
        .flat()
        .filter((service) => getServiceName(service).includes(search)) // filter by keyword
        .filter((service, index, array) => index === array.findIndex((t) => getServiceName(t) === getServiceName(service))); // remove duplicates

    // if there are no results, show a message
    if (filteredServices.length === 0) {
        return (
            <Text size--s secondary>
                No results found
            </Text>
        );
    }

    // if there is only one result and it matches the current search keyword, don't show the results
    if (filteredServices.length === 1 && getServiceName(filteredServices[0]) === search) return null;

    // show the results
    return (
        <div className="accountInvoices__searchResults">
            {filteredServices.map((service) => (
                <div key={service.id} className="accountInvoices__searchResults__item">
                    <button className="searchResults__item__name" onClick={() => void onClick(getServiceName(service))}>
                        <Text span>{getServiceName(service)}</Text>
                    </button>
                </div>
            ))}
        </div>
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

export default RenderSearchResults;

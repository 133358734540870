/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { noop } from 'lodash';
import { createContext, useContext } from 'react';

/**********************************************************************************************************
 *   TYPE IMPORTS
 **********************************************************************************************************/
import type { AllTokens, ExcludePrimitiveToken } from 'config/tokens/base';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import type { Theme } from 'components/StaffMenu/Theme';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type TDeveloperThemeContext = {
    theme: Theme;
    setTheme: (theme: Theme) => void;
    config: Record<ExcludePrimitiveToken<AllTokens>, string>;
};

export const DeveloperThemeContext = createContext<TDeveloperThemeContext>({
    theme: 'ventra',
    setTheme: noop,
    config: {} as Record<ExcludePrimitiveToken<AllTokens>, string>
});

export const useDeveloperTheme = () => {
    return useContext(DeveloperThemeContext);
};

import { useLocation } from '@tanstack/react-router';
import { _HostingPleskTitle as PleskTitle } from 'containers/hosting/components/title/plesk';
import { _HostingResellerTitle as ResellerTitle } from 'containers/hosting/components/title/reseller';
import { _HostingResellerSubaccountTitle as ResellerSubaccountTitle } from 'containers/hosting/components/title/resellerSubaccount';
import { _HostingSharedTitle as SharedHostingTitle } from 'containers/hosting/components/title/shared';
import { _HostingSubaccountWithUsernameTitle as SubaccountWithUsernameTitle } from 'containers/hosting/components/title/subaccount';
import { useSelector } from 'react-redux';
import { useChildMatchById } from 'utilities/hooks/router/useChildMatchById';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const HostingTitle = () => {
    /***** HOOKS *****/
    const isSubaccount = useChildMatchById('/my-services/hosting/$serviceId/subaccount/$subaccountId');
    const isResellerListAccountsPage = useLocation({ select: ({ hash }) => hash === 'subaccounts' });

    /***** SELECTORS *****/
    const isReseller = useSelector((state: any) => state.hosting.hosting_isReseller);
    const hosting_subaccount_username = useSelector((state: any) => state.hosting.hosting_subaccount_username);
    const isPlesk = useSelector((state: any) => {
        return state.hosting.hosting_information_data?.server_type?.startsWith('plesk');
    });

    /***** RENDER *****/
    switch (true) {
        case hosting_subaccount_username:
            return <SubaccountWithUsernameTitle />;
        case isReseller && (isResellerListAccountsPage || isSubaccount):
            return <ResellerSubaccountTitle />;
        case isReseller:
            return <ResellerTitle />;
        case isPlesk:
            return <PleskTitle />;
        default:
            return <SharedHostingTitle />;
    }
};

import { getDataFromData } from 'utilities/methods/commonActions';

/**
 * @deprecated Just write the select yourself, this shit causes too many problems trying to get the type correct
 */
export function infiniteQuerySelectDefault(response) {
    // Since the response is a page for each request you have to map them to the success response to get the actual data.
    const pagesData = response.pages.map((individualResponse) => getDataFromData(individualResponse));

    return { ...response, pages: pagesData };
}

/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React from 'react';
import { useDispatch } from 'react-redux';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import SelectDropdown from 'components/Dropdowns/SelectDropdown';
import Search from 'components/Search';
import Border from 'components/Utils/Border';
import Text from 'components/Utils/Text';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { generateCreateShowDropdownOption } from 'containers/services/modules/helpers';
import { cancelSearchSSL } from 'utilities/api/services';
import { useSSLOverviewContext, useSSLTableSelector } from '../own';

/*   ACTIONS
 *****************************************************/
import { resetSSLSearch, sslSearch } from 'containers/services/action';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
/**
 * @typedef {import('../types').NSSLTable.TSSLTableTitleBar} TSSLTableTitleBar
 */

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * Title bar component for the SSLOverview table.
 *
 * @private
 * @type {TSSLTableTitleBar}
 */
export const SSLTableTitleBar = ({ title }) => {
    /***** HOOKS *****/
    const { ssl_list_more_status, ssl_list_meta } = useSSLTableSelector((state) => state.services);
    const { setNumberOfRows, setShowExpiredSSLs, setSearch } = useSSLOverviewContext();
    const dispatch = useDispatch();

    /***** FUNCTIONS *****/
    const createShowDropdownOption = generateCreateShowDropdownOption(ssl_list_meta?.total_all_records, setNumberOfRows);

    /**
     * @template {string} T
     * @param {T} value
     * @param {boolean} show - Whether or not to show expired certificates
     * @returns {{
     *   label: T,
     *   onClick: () => void,
     *   value: T
     * }}
     */
    const createShowExpiredCertsDropdownOption = (value, show) => ({
        label: value,
        value: value,
        onClick: () => setShowExpiredSSLs(show)
    });

    /***** RENDER *****/
    return (
        <div className="SSLOverviewHeader">
            {/* Title */}
            <Text primary size--xl semiBold>
                {title}
            </Text>

            {/* Filters */}
            <div className="SSLOverviewHeader__filters">
                <Search
                    className="SSLOverviewHeader__search"
                    functions={{
                        cancel: cancelSearchSSL,
                        search: (search) => sslSearch(search)(dispatch),
                        reset: () => {
                            resetSSLSearch()(dispatch);
                            setSearch();
                        }
                    }}
                    render={{ placeholder: 'Search for an SSL Certificate' }}
                    helpers={{ keyword: setSearch }}
                />
                <div className="SSLOverviewHeader__actions">
                    <SelectDropdown
                        options={[
                            createShowExpiredCertsDropdownOption('Hide Expired SSLs', false),
                            createShowExpiredCertsDropdownOption('Show Expired SSLs', true)
                        ]}
                        noSelectionLabel="Display Options"
                        disabled={ssl_list_more_status === 'loading'}
                        className="SSLOverviewHeader__displayOptions"
                    />
                    <Border left>
                        <SelectDropdown
                            options={[
                                createShowDropdownOption('10'),
                                createShowDropdownOption('20'),
                                createShowDropdownOption('50'),
                                createShowDropdownOption('All')
                            ]}
                            noSelectionLabel="Show"
                            disabled={ssl_list_more_status === 'loading'}
                        />
                    </Border>
                </div>
            </div>
        </div>
    );
};

/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import classNames from 'classnames';
import React, { useCallback } from 'react';
import { connect } from 'react-redux';
import { formValueSelector } from 'redux-form';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import { Radio } from 'components/Form/Radio';
import RequestLoader from 'components/Loaders/Request';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import SavedCreditCardInvoiceForm, { savedCreditCardInvoiceFormName } from '../../forms/savedCreditCardInvoiceForm';

/**********************************************************************************************************
 *   QUERIES
 **********************************************************************************************************/
import { usePayInvoiceMutation } from 'containers/billing/queries/invoice';
import { useGetUserPaymentMethodDataListQuery } from 'containers/billing/queries/useGetUserPaymentMethodDataListQuery';

/**********************************************************************************************************
 *   TYPE IMPORTS
 **********************************************************************************************************/
import type { SelectedPaymentMethodProps } from 'components/Lightboxes/OverlayLightbox/Components/invoice/paymentMethods/types';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import { PAYMENT_METHODS } from './consts';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/

export let SavedCreditCardPaymentMethod: React.FC<SelectedPaymentMethodProps> = ({
    selectedPaymentMethod,
    setSelectedPaymentMethod,
    invoiceID,
    mutationOptions,
    /**
     * Redux Props
     */
    saved_credit_card_id
}) => {
    /***** QUERIES *****/
    const { data: get_user_payment_method_data_list_data, isLoading: isGetUserPaymentMethodDataListLoading } = useGetUserPaymentMethodDataListQuery();

    const { mutateAsync: mutatePayInvoice } = usePayInvoiceMutation(mutationOptions);

    /***** FUNCTIONS *****/
    const handleSavedCreditCardForm = useCallback(() => {
        return mutatePayInvoice({
            invoiceID,
            payment_method_data_id: saved_credit_card_id
        });
    }, [invoiceID, saved_credit_card_id]);

    if (isGetUserPaymentMethodDataListLoading || !get_user_payment_method_data_list_data?.length) return '';

    /***** RENDER HELPERS *****/
    const initialValues = {
        cc_id: get_user_payment_method_data_list_data[0].id
    };
    const isSavedCreditCardSelected = selectedPaymentMethod === PAYMENT_METHODS.SAVED_CREDIT_CARD;

    /***** RENDER *****/
    return (
        <div key="saved" className={classNames('method', 'saved_credit_card', { 'method--selected': isSavedCreditCardSelected })}>
            <Radio.Circle.Button
                className="method__top"
                isActive={isSavedCreditCardSelected}
                color="primary"
                onClick={() => setSelectedPaymentMethod(PAYMENT_METHODS.SAVED_CREDIT_CARD)}
            >
                <div className="method__top--title">
                    <div className="label">Use Saved Credit Card</div>
                </div>
            </Radio.Circle.Button>
            {isSavedCreditCardSelected ? (
                <div className="method__bottom">
                    {isGetUserPaymentMethodDataListLoading ? (
                        <RequestLoader />
                    ) : (
                        <SavedCreditCardInvoiceForm onSubmit={handleSavedCreditCardForm} initialValues={initialValues} />
                    )}
                </div>
            ) : (
                ''
            )}
        </div>
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

const mapStateToProps = (state) => {
    const selector = formValueSelector(savedCreditCardInvoiceFormName);
    const saved_credit_card_id = selector(state, 'cc_id');
    return {
        saved_credit_card_id
    };
};

SavedCreditCardPaymentMethod = connect(mapStateToProps)(SavedCreditCardPaymentMethod);

export default SavedCreditCardPaymentMethod;

/**********************************************************************************************************
 *   IMPORTS
 **********************************************************************************************************/
import { activePromotion } from 'utilities/methods/commonActions';

/*   FORMAT MAILBOX FORM VALUES
 **********************************************************************************************************/
export const formatMailboxFormValues = (formValues, emailFieldName = 'email_address') => {
    const formattedValues = {
        ...formValues,
        [emailFieldName]: `${formValues?.username || ''}@${(formValues?.domain_name || '').toLowerCase()}`
    };

    delete formattedValues.domain_name;
    delete formattedValues.username;

    return formattedValues;
};

/*   FORMAT MAILBOX PURCHASE VALUES
 **********************************************************************************************************/
export const formatMailboxPurchaseValues = (values = []) => {
    const mailboxes = values.map((mailbox) => {
        const newAttributes = { ...mailbox.attributes };

        delete newAttributes.emailAddressValidateKey;

        return {
            type: 'purchase',
            attributes: newAttributes
        };
    });

    const payload = {
        mailboxes,
        /***** MEGAMAY24 START *****/
        promo_code: activePromotion('megamay2024') ? 'MAY24EMAIL' : null
        /***** MEGAMAY24 END *****/
    };

    return payload;
};

export function getProductDiscountOrBasePrice(pricingObject) {
    const { base_price, discounted_price } = pricingObject || {};

    return discounted_price || base_price;
}

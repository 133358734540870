/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import classNames from 'classnames';
import React from 'react';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import OutlineTag from 'components/Tags/OutlineTag';
import Text from 'components/Utils/Text';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { useAppViewport } from 'utilities/hooks/useAppViewport/useAppViewport';
import { getInformationFromData } from './helpers';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
/**
 * @typedef {import('../../types').NSSLTable.TSSLStatusTag} TSSLStatusTag
 */

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * @private - For use in the _TableBody component.
 * @type {TSSLStatusTag}
 */
export const OwnRenderServiceStatus = ({ data }) => {
    /***** HOOKS *****/
    const isMobile = useAppViewport(['xs', 'sm']);

    /***** RENDER HELPERS *****/
    const { invoice, status } = getInformationFromData(data);
    const isUnpaid = invoice?.attributes?.status === 'unpaid';
    const getTagStatus = () => {
        if (isUnpaid) {
            return 'notice';
        }

        switch (status) {
            case 'validated':
            case 'active':
                return 'confirm';
            case 'expiring soon':
                return 'warn';
            case 'expired':
                return 'error';
            case 'pending':
            case 'pending validation':
            case 'in progress':
                return 'secondary';
            default:
                return 'notice';
        }
    };

    const classes = {
        status: classNames('SSLOverviewBody__statusTitle', {
            'SSLOverviewBody__statusTitle--mobile': isMobile
        }),
        tag: classNames('SSLOverviewBody__tag', {
            'SSLOverviewBody__tag--mobile': isMobile
        })
    };

    /***** RENDER *****/
    return (
        <>
            {isMobile && (
                <Text className={classes.status} lead--1 secondary bold size--xss align--left uppercase>
                    Status
                </Text>
            )}
            <OutlineTag color={getTagStatus()} className={classes.tag}>
                {!isUnpaid ? status || 'Not Available' : 'unpaid'}
            </OutlineTag>
        </>
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { createRoute } from '@tanstack/react-router';

/**********************************************************************************************************
 *   ROUTE IMPORTS
 **********************************************************************************************************/
import { VIPSitesContentLightboxLayoutRoute } from 'containers/katana/routes/vip-sites.$katanaServiceId/_content-editor-lightbox';
import { routerMiddleware } from 'router/utils/middleware';
import { lazyFN } from 'utilities/methods/tanstack/router/lazyFn';

/**********************************************************************************************************
 *   ROUTE START
 **********************************************************************************************************/
export const VIPSitesEditorRoute = createRoute({
    getParentRoute: () => VIPSitesContentLightboxLayoutRoute,
    path: 'editor',
    beforeLoad(opts) {
        routerMiddleware.business(this, opts);
        routerMiddleware.authentication('user', opts);
    }
}).lazy(lazyFN(() => import('./index.lazy'), 'LazyVIPSitesEditorRoute'));

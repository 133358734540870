/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import classNames from 'classnames';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import BrainTreeDropIn from 'components/BrainTreeDropIn';
import { Radio } from 'components/Form/Radio';
import RequestLoader from 'components/Loaders/Request';

/**********************************************************************************************************
 *   QUERIES
 **********************************************************************************************************/
import { useGetInvoiceQuery } from 'containers/billing/queries/invoice/useGetInvoiceQuery';
import { useGetAvailablePaymentMethodsQuery } from 'containers/billing/queries/useGetAvailablePaymentMethodsQuery';

/**********************************************************************************************************
 *   TYPE IMPORTS
 **********************************************************************************************************/
import type { SelectedPaymentMethodProps } from 'components/Lightboxes/OverlayLightbox/Components/invoice/paymentMethods/types';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import paypalImage from 'assets/images/paypal.png';
import { PAYMENT_METHODS } from './consts';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const PayPalPaymentMethod: React.FC<SelectedPaymentMethodProps> = ({
    selectedPaymentMethod,
    setSelectedPaymentMethod,
    invoiceID,
    mutationOptions
}) => {
    /***** QUERIES *****/
    const { isLoading: isGetInvoiceLoading } = useGetInvoiceQuery(invoiceID);
    const { isError: isGetAvailablePaymentMethodsError } = useGetAvailablePaymentMethodsQuery({
        retryOnMount: false
    });

    /***** RENDER HELPERS *****/
    const isPaypalSelected = selectedPaymentMethod === PAYMENT_METHODS.PAYPAL;

    /***** RENDER *****/
    if (isGetAvailablePaymentMethodsError) {
        return;
    }
    if (isGetInvoiceLoading) {
        return <RequestLoader />;
    }
    return (
        <div key="paypal" className={classNames('method', { 'method--selected': isPaypalSelected })}>
            <Radio.Circle.Button
                className="method__top"
                isActive={isPaypalSelected}
                color="primary"
                onClick={() => setSelectedPaymentMethod(PAYMENT_METHODS.PAYPAL)}
            >
                <div className="method__top--title">
                    <div className="label">PayPal</div>
                </div>
                <div className="method__top--img">
                    <img className="cc_icon paypal" src={paypalImage} alt="paypal" />
                </div>
            </Radio.Circle.Button>
            {isPaypalSelected ? (
                <div className="method__bottom">
                    <div className="paypal__wrapper">
                        <BrainTreeDropIn invoiceID={invoiceID} mutationOptions={mutationOptions} />
                    </div>
                </div>
            ) : (
                ''
            )}
        </div>
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

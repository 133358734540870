import RequestLoader from 'components/Loaders/Request';
import { KatanaHookFormHandler } from 'containers/katana/components/HookFormHandler';
import { useHandleLaunchSubmit } from 'containers/katana/containers/ContentEditorLightbox/formHandlers/launchFormHandler/useHandleLaunchSubmit';
import { useKatanaParams } from 'containers/katana/hooks/useSetupEditorRouteParams';
import { katanaQuery } from 'containers/katana/queries/tanstackTree';
import type { KatanaNamespace } from 'containers/katana/types';
import { requiredFieldErrorMessage } from 'utilities/methods/form';
import { z } from 'zod';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type Props = {
    subpageRouteData: KatanaNamespace.SubPageRouteData;
};

const launchZodSchema = z.object({
    remove_conflicting: z.literal(true, { message: requiredFieldErrorMessage, invalid_type_error: requiredFieldErrorMessage })
});

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const LaunchFormHandler: React.FC<Props> = ({ subpageRouteData }) => {
    /***** HOOKS *****/
    const { katanaServiceId } = useKatanaParams();
    const handleBasicDetailsSubmit = useHandleLaunchSubmit(subpageRouteData);
    const { isLoading: isCheckKatanaServiceDetailsLoading } = katanaQuery.serviceID.domainServiceDetails.useQuery(katanaServiceId);
    const { isLoading: isGetDNSLoading } = katanaQuery.serviceID.dns.useQuery(katanaServiceId);

    /***** RENDER HELPERS *****/
    const multiLoaderData = RequestLoader.MultiLoader.useLoadersData([
        { condition: isCheckKatanaServiceDetailsLoading, message: 'Checking Service Details...' },
        { condition: isGetDNSLoading, message: 'Loading DNS Records...' }
    ]);

    /***** RENDER *****/
    return (
        <RequestLoader.MultiLoader loaders={multiLoaderData}>
            <KatanaHookFormHandler defaultValues={{}} zodSchema={launchZodSchema} handleOnSubmit={handleBasicDetailsSubmit}>
                {subpageRouteData.modules}
            </KatanaHookFormHandler>
        </RequestLoader.MultiLoader>
    );
};

import { queryOptions, useQuery } from '@tanstack/react-query';
import { katanaQueryKeys } from 'containers/katana/queries/katanaQueryKeys';
import type { KatanaNamespace } from 'containers/katana/types';
import queryClient from 'store/queryClient';
import { KATANA_API } from 'utilities/api/katana';
import type { KatanaAPI } from 'utilities/api/katana/types';
import { MINUTE } from 'utilities/consts';
import { handleDefaultErrorNotification } from 'utilities/methods/commonActions';
import { createOptimisticMethods } from 'utilities/methods/tanstack/createOptimisticMethods';
import { createSetQueryDataMethod } from 'utilities/methods/tanstack/createSetQueryDataMethod';
import type { NXQueryUtils } from 'utilities/methods/tanstack/types';

type TData = Awaited<KatanaAPI.Katana.ServiceID.Info.GET.ReturnType>;

function createQueryKey(serviceID: KatanaNamespace.ServiceID) {
    return katanaQueryKeys.katana.service.ID.info(serviceID);
}

function createQueryOptions(serviceID: KatanaNamespace.ServiceID) {
    return queryOptions({
        queryKey: createQueryKey(serviceID),
        queryFn: () =>
            KATANA_API.katana.service_id.info.GET(serviceID).catch((e) => {
                handleDefaultErrorNotification(e);
                throw e;
            }),
        staleTime: MINUTE * 2.5,
        enabled: Boolean(serviceID),
        select: (data) => {
            if (data?.status === 200) {
                return data.data;
            }
        }
    });
}

const setQueryData = createSetQueryDataMethod<KatanaNamespace.ServiceID, NXQueryUtils.ApiData200<TData>>(createQueryKey);

export function cancelQueries(serviceID: KatanaNamespace.ServiceID) {
    return queryClient.cancelQueries({
        queryKey: createQueryKey(serviceID)
    });
}

function resetQueries(serviceID: KatanaNamespace.ServiceID) {
    return queryClient.resetQueries({
        queryKey: createQueryKey(serviceID)
    });
}

function prefetchQuery(serviceID: KatanaNamespace.ServiceID) {
    return queryClient.prefetchQuery(createQueryOptions(serviceID));
}

function invalidateQueries(serviceID: KatanaNamespace.ServiceID) {
    return queryClient.invalidateQueries({ queryKey: createQueryKey(serviceID) });
}

/**********************************************************************************************************
 *   HOOK START
 **********************************************************************************************************/
/**
 * Gets the site info
 */
function _useQuery(serviceID: KatanaNamespace.ServiceID) {
    return useQuery(createQueryOptions(serviceID));
}

/**********************************************************************************************************
 *   HOOK END
 **********************************************************************************************************/

export const getSiteInfo = Object.freeze({
    useQuery: _useQuery,
    setQueryData,
    cancelQueries,
    resetQueries,
    prefetchQuery,
    invalidateQueries,
    optimistic: createOptimisticMethods(setQueryData),
    createQueryKey,
    createQueryOptions
});

/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import classNames from 'classnames';
import React from 'react';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { useRevealer } from 'components/Revealer/hooks/useRevealer';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type TRevealerListProps = {
    children: React.ReactNode;
    childHolderRef: React.RefObject<HTMLDivElement>;
    overflow?: 'hidden' | 'visible';
};

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/

/**
 * List component for the dropdown.
 */
const _List: React.FC<TRevealerListProps> = ({ children, childHolderRef, overflow = 'hidden' }) => {
    /***** HOOKS *****/
    const { isOpen } = useRevealer();

    /***** RENDER *****/
    return (
        <div
            className={classNames('RevealerListContainer', {
                'RevealerListContainer--open': isOpen
            })}
        >
            <div className="RevealerListContainer__childHolder" style={{ overflow }} ref={childHolderRef}>
                {children}
            </div>
        </div>
    );
};

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
export { _List };

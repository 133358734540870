/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { useNavigate } from '@tanstack/react-router';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import RequestLoader from 'components/Loaders/Request';
import { pushNotification } from 'components/Toast/functions';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { handleRedirect } from 'utilities/methods/redirects/methods';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * Shows a loader while the SSL information is being fetched. This component DOES NOT make any API requests as this is
 * handled by the router
 */
export const SSLLoaderHandler = ({ children }) => {
    /***** SELECTORS *****/
    const ssl_information_error = useSelector((state: any) => state.ssl.ssl_information_error);
    const ssl_information_data = useSelector((state: any) => state.ssl.ssl_information_data);
    const ssl_information_status = useSelector((state: any) => state.ssl.ssl_information_status);

    /***** HOOKS *****/
    const navigate = useNavigate();

    /***** EFFECTS *****/
    useEffect(() => {
        if (ssl_information_error?.status === 403) {
            navigate({ to: '/my-services/ssl' });
            pushNotification(ssl_information_error);
        } else if (ssl_information_data) {
            handleRedirect({
                serviceType: 'ssl',
                status: ssl_information_data?.attributes?.status,
                domainName: ssl_information_data?.attributes?.domain,
                history: {
                    push: (to) => navigate({ to })
                }
            });
        }
    }, [ssl_information_data, ssl_information_error]);

    /***** RENDER *****/
    if (['loading', 'error', null].includes(ssl_information_status)) {
        return <RequestLoader />;
    }

    return children;
};

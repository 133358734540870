/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import _ from 'lodash';

/**********************************************************************************************************
 *   QUERIES
 **********************************************************************************************************/
import { katanaQuery } from 'containers/katana/queries/tanstackTree';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { getSectionNameRouteKey } from 'containers/katana/hooks/methods';
import { useKatanaParams } from 'containers/katana/hooks/useSetupEditorRouteParams';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
export const useDynamicChildPageData = () => {
    /***** HOOKS *****/
    const { katanaServiceId, childPage } = useKatanaParams();

    /***** QUERIES *****/
    const { data: get_katana_site_sections_data } = katanaQuery.serviceID.sections.useQuery(katanaServiceId);
    const { data: get_katana_section_definitions_data } = katanaQuery.serviceID.meta.getSectionDefinitions.useQuery(katanaServiceId);

    const isChildPageID = _.isNumber(childPage);
    const sectionRouteID = get_katana_site_sections_data?.find(({ id }) => id === childPage)?.section_id;

    const routeKey =
        isChildPageID && sectionRouteID ? getSectionNameRouteKey(get_katana_section_definitions_data?.[sectionRouteID]?.name) : childPage;
    const sectionDefinitionID = katanaQuery.serviceID.meta.getSectionDefinitions.useDefinitionIDbyRouteKey(katanaServiceId, routeKey);

    const {
        sectionID,
        query: { isLoading: isGetKatanaSiteSectionLoading }
    } = katanaQuery.serviceID.sections.useGetIDByDefinitionID(katanaServiceId, sectionDefinitionID);

    /***** HOOK RESULTS *****/
    return {
        sectionID,
        isGetKatanaSiteSectionLoading
    };
};

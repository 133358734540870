/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { useContext } from 'react';
import { useTanstackStore } from 'utilities/hooks/tanstack-store/useTanstackStore';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import { PhosphorIcons } from 'components/Icons/Phosphor';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import { PictureInPictureIconControlButton } from 'components/PictureInPicture/controls/ControlIconButton';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import { PictureInPictureContext } from 'components/PictureInPicture/consts';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * @param {{
 *      text?:string,
 * }} props
 */
function _PictureInPictureShrinkButton({ text = 'Shrink' }) {
    /***** HOOKS *****/
    const { stateStoreData } = useContext(PictureInPictureContext);
    const [pictureState, setPictureState] = useTanstackStore(stateStoreData.store);

    if (pictureState !== 'maximised') {
        return;
    }
    /***** RENDER *****/
    return (
        <PictureInPictureIconControlButton
            text={text}
            Icon={PhosphorIcons.CornersIn}
            onClick={() => {
                setPictureState('visible');
            }}
        />
    );
}

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
export { _PictureInPictureShrinkButton };


import Grid from 'components/Grid';
import Text from 'components/Utils/Text';
import React from 'react';

function TitleDescriptionBlock({ title, description }) {
    return (
        <Grid
            columns="100%"
            gap={2}
        >
            <Text.Heading h2>{title}</Text.Heading>

            <Text
                secondary
                size--m
            >
                {description}
            </Text>
        </Grid>
    );
}

export default TitleDescriptionBlock;

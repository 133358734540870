/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import _ from 'lodash';
import { useEffect, useMemo } from 'react';
import { useController } from 'react-hook-form';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import { CheckBoxList } from 'components/Form/CheckBoxList';
import RequestLoader from 'components/Loaders/Request';
import Text from 'components/Utils/Text';

/**********************************************************************************************************
 *   QUERIES
 **********************************************************************************************************/
import { katanaQuery } from 'containers/katana/queries/tanstackTree';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { navigationLabelsFormStore } from 'containers/katana/containers/ContentEditorLightbox/formHandlers/navigationFormHandler/consts';
import { useNavigationPreviewOptions } from 'containers/katana/containers/contentEditorModules/navigationLabels/useNavigationPreviewOptions';
import { useKatanaParams } from 'containers/katana/hooks/useSetupEditorRouteParams';
import { useGetNavigationItemNavTitleAndName } from 'containers/katana/modules/navigationAndSocials/useGetNavigationItemNavTitleAndName';
import { NavigationOrganiser } from 'containers/katana/modules/presetContent/components/sectionOrganiser/navigationOrganiser';
import { sortByMenuIDs } from 'containers/katana/modules/presetContent/components/sectionOrganiser/sortByMenuIds';
import type { KatanaNamespace } from 'containers/katana/types';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type Props = {
    name: string;
};

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const NavLabelsFieldHookForm: React.FC<Props> = ({ name }) => {
    /***** HOOKS *****/
    const { field } = useController({ name });
    const { value, onChange } = field;
    const { katanaServiceId } = useKatanaParams();
    const navigationLabels = useGetNavigationItemNavTitleAndName();

    /***** QUERIES *****/
    const { isLoading: isGetKatanaSiteInfoLoading } = katanaQuery.serviceID.getSiteInfo.useQuery(katanaServiceId);
    const { data: get_section_definitions_data, isLoading: isGetKatanaSectionDefinitionsLoading } =
        katanaQuery.serviceID.meta.getSectionDefinitions.useQuery(katanaServiceId);
    const { data: get_navigation_items_data, isLoading: isGetNavigationItemsLoading } =
        katanaQuery.serviceID.getNavigationItems.useQuery(katanaServiceId);
    const { data: get_katana_site_sections, isLoading: isGetKatanaSiteSectionsLoading } = katanaQuery.serviceID.sections.useQuery(katanaServiceId);

    /***** RENDER HELPERS *****/
    const multiLoaderData = RequestLoader.MultiLoader.useLoadersData([
        { condition: isGetKatanaSiteInfoLoading, message: 'Loading Site Info...' },
        { condition: isGetKatanaSectionDefinitionsLoading, message: 'Loading section definitions...' },
        { condition: isGetNavigationItemsLoading, message: 'Loading Navigation Items...' },
        { condition: isGetKatanaSiteSectionsLoading, message: 'Loading Site Sections...' }
    ]);

    const options = useMemo(() => {
        if (!get_navigation_items_data?.section_ids) {
            return [];
        }

        return (
            get_katana_site_sections
                ?.filter((section) => _.has(get_section_definitions_data, section.section_id))
                ?.sort((a, b) => (get_section_definitions_data[a.section_id].order < get_section_definitions_data[b.section_id].order ? -1 : 1))
                ?.sort(sortByMenuIDs(value))
                ?.map((section) => {
                    const sectionNavbarTitle = get_section_definitions_data[section.section_id].navbar_title;
                    const navLabel = navigationLabels?.find(({ navBarTitle }) => navBarTitle === sectionNavbarTitle);

                    if (!navLabel) {
                        return sectionNavbarTitle;
                    }
                    function getLabel() {
                        const { name, navBarTitle } = navLabel;
                        if (name) {
                            return (
                                <>
                                    {name} <Text secondary>({navBarTitle})</Text>
                                </>
                            );
                        }

                        return navBarTitle;
                    }
                    return {
                        label: getLabel(),
                        value: section.id
                    };
                }) ?? []
        );
    }, [get_katana_site_sections, get_section_definitions_data, get_navigation_items_data, navigationLabels]);

    const navOptions = useNavigationPreviewOptions(value);

    useEffect(() => {
        const filtered = navOptions.filter((option): option is KatanaNamespace.NavigationLabel => !!option.name);
        navigationLabelsFormStore.setState(() => filtered);
    }, [navOptions]);

    /***** FUNCTIONS *****/
    function setIsDisplayingNavigationLabelsValue() {
        if (value?.length) {
            onChange([]);
        } else {
            onChange(options.map((option) => option.value));
        }
    }

    /***** RENDER *****/
    return (
        <RequestLoader.MultiLoader loaders={multiLoaderData}>
            <CheckBoxList>
                <CheckBoxList.Item isChecked={Boolean(value?.length)} onChange={setIsDisplayingNavigationLabelsValue}>
                    <Text semiBold black size--m>
                        Display navigation labels
                    </Text>
                </CheckBoxList.Item>
                <CheckBoxList>
                    <NavigationOrganiser
                        isEditingNavigationMenu
                        navigationMenuIDs={value || get_navigation_items_data?.section_ids}
                        onNavigationMenuIDsChanged={onChange}
                    />
                </CheckBoxList>
            </CheckBoxList>
        </RequestLoader.MultiLoader>
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

/**********************************************************************************************************
 *   FUNCTION START
 **********************************************************************************************************/
export function injectRouteParams<TPath extends string | Array<string>>(
    path: TPath,
    params?: Record<string, string>
): TPath extends string ? string : Array<string> {
    if (!params || !path) {
        return path as any;
    }

    if (typeof path === 'string') {
        return Object.entries(params).reduce<string>((acc, [param, paramValue]) => {
            return acc.replace(`$${param}`, paramValue);
        }, path) as any;
    }

    return path.map((p) => Object.entries(params).reduce((acc, [param, paramValue]) => acc.replace(`$${param}`, paramValue), p)) as any;
}

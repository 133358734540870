/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import classNames from 'classnames';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import React from 'react';
import './_AcknowledgementBox.scss';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type TAcknowledgementBox = {
    title?: string;
    children: React.ReactNode;
    /**
     * Removes the bottom border and the bottom margin
     */
    flush?: boolean;
    className?: string;
    /**
     * Removes the left padding of the title
     */
    titleNoPadding?: boolean;
};

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
const _AcknowledgementBox: React.FC<TAcknowledgementBox> = (props) => {
    const { title = 'Acknowledgement', children, flush, className, titleNoPadding } = props;

    /***** RENDER HELPERS *****/
    const containerClasses = classNames('acknowledgementBox__container', className, {
        acknowledgementBox__flush: flush,
        acknowledgementBox__titleNoPadding: titleNoPadding
    });

    /***** RENDER *****/
    return (
        <div className={containerClasses}>
            <h5 className="acknowledgementBox__heading">{title}</h5>

            <div className="acknowledgementBox__input">{children}</div>
        </div>
    );
};

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
export { _AcknowledgementBox };

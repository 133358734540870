/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { cloneElement } from 'react';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import './_DialogNotificationButtonsContainer.scss';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type DialogNotificationButtonsContainerProps = {
    text: React.ReactNode;
    buttons: Array<React.ReactNode>;
};

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const DialogNotificationButtonsContainer: React.FC<DialogNotificationButtonsContainerProps> = ({ text, buttons }) => {
    /***** RENDER *****/
    return (
        <div className="DialogNotificationButtonsContainer">
            <p className="DialogNotificationButtonsContainer__text">{text}</p>
            <div className="DialogNotificationButtonsContainer__buttons">
                {buttons.map((button) => cloneElement(button, { className: 'DialogNotificationButtonsContainer__button' }))}
            </div>
        </div>
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

import axios from 'axios';
const CancelToken = axios.CancelToken;

export let cancelHostingUpgradeCalculate;

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
/**
 * @typedef {{
 *   type: 'custom-hosting',
 *   attributes: {
 *     "Disk Space": {
 *       name: string,
 *       show_order: boolean,
 *       pricing: Record<string, number>,
 *       option_id: number,
 *       config_id: number,
 *       value: number,
 *       less_than_current_usage?: boolean,
 *     }[]
 *   }
 * }} CustomHostingResourceLimits
 */

export const API = {
    /**********************************************************************************************************
     *   ACCOUNT API
     **********************************************************************************************************/

    account: {
        create: (id, attributes) => {
            return axios.post('/api/user/', {
                type: 'user',
                attributes: {
                    hosting_id: id,
                    email: attributes.email,
                    firstname: attributes.firstname,
                    lastname: attributes.lastname,
                    phone: attributes.phone
                }
            });
        }
    },

    /**********************************************************************************************************
     *   HOSTING API
     **********************************************************************************************************/

    hosting: {
        GET: {
            list: () => {
                return axios.get(`/api/web-hosting`);
            },

            search: () => {
                return axios.get(`/api/web-hosting/search/hosting`);
            },

            products: (type) => {
                return axios.get(`/api/web-hosting/product/list/${type}`);
            },

            information: (id) => {
                return axios.get('/api/web-hosting/' + id);
            },

            diskblocks: (id) => {
                return axios.get(`/api/web-hosting/${id}/disk-blocks/`);
            },

            billingCycle: (id) => {
                return axios.get(`/api/web-hosting/${id}/billing-cycle/`);
            },

            tempurl: (id) => {
                return axios.get(`/api/web-hosting/${id}/temporary-url/`);
            },

            ipCheck: (type) => {
                return axios.get(`https://ipv${type === '4' ? '4' : '6'}.${axios.defaults.baseURL.split('://')[1]}`);
            },

            mysql: (id) => {
                return axios.get(`/api/web-hosting/${id}/external-mysql/`);
            },

            ssh: (id) => {
                return axios.get(`/api/web-hosting/${id}/ssh-access/`);
            },

            ipv6: (id) => {
                return axios.get(`/api/web-hosting/${id}/ipv6`);
            },

            autossl: (id) => {
                return axios.get(`/api/web-hosting/${id}/auto-ssl`);
            },

            fileQuarantine: (id) => {
                return axios.get(`/api/web-hosting/${id}/file-quarantine/check`);
            },

            modSec: (id) => {
                return axios.get(`/api/web-hosting/${id}/mod-sec/check`);
            },

            details: {
                cpanel: (id) => {
                    return axios.get(`/api/web-hosting/${id}/cpanel/details`);
                },

                plesk: (id) => {
                    return axios.get(`/api/web-hosting/${id}/plesk/details`);
                }
            },

            login: {
                whm: (id) => {
                    return axios.get(`/api/web-hosting/${id}/whm/login`);
                },

                /**
                 * @param {{
                 *   id: number | string;
                 *   app?: "monarx_Home" | "cpanel"
                 * }} opts
                 */
                cpanel: ({ id, app }) => {
                    return axios.get(`/api/web-hosting/${id}/cpanel/login${app ? `?app=${app}` : ''}`);
                },

                plesk: (id) => {
                    return axios.get(`/api/web-hosting/${id}/plesk/login`);
                }
            },

            custom: {
                resources: (id) => {
                    return id ? axios.get(`/api/web-hosting/custom-hosting/${id}`) : axios.get(`/api/web-hosting/custom-hosting`);
                },

                configList: (id) => {
                    return id
                        ? axios.get(`/api/web-hosting/custom-hosting/config-list/${id}`)
                        : axios.get(`/api/web-hosting/custom-hosting/config-list`);
                },

                addonList: (id) => {
                    return id
                        ? axios.get(`/api/web-hosting/custom-hosting/addon-list/${id}`)
                        : axios.get(`/api/web-hosting/custom-hosting/addon-list`);
                },

                /**
                 * @param {number} id
                 * @returns {Promise<{
                 *   data: CustomHostingResourceLimits
                 * }>}
                 */
                resourceLimits: (id) => {
                    return axios.get(`/api/web-hosting/custom-hosting/get-resource-limits/${id}`);
                }
            }
        },

        POST: {
            enable: {
                tempurl: (id) => {
                    return axios.post(`/api/web-hosting/${id}/temporary-url/enable`);
                },

                mysql: (id, attributes) => {
                    return axios.post(`/api/web-hosting/${id}/external-mysql/enable`, {
                        type: 'external-mysql',
                        id: id,
                        attributes: {
                            ip_address: attributes.ip_address
                        }
                    });
                },

                ssh: (id, attributes) => {
                    return axios.post(`/api/web-hosting/${id}/ssh-access/enable`, {
                        type: 'ssh-access',
                        id: id,
                        attributes: {
                            ip_address: attributes.ip_address
                        }
                    });
                },

                ipv6: (id) => {
                    return axios.post(`/api/web-hosting/${id}/ipv6/enable`);
                }
            },

            disable: {
                tempurl: (id) => {
                    return axios.post(`/api/web-hosting/${id}/temporary-url/disable`);
                },

                mysql: (id, attributes) => {
                    return axios.post(`/api/web-hosting/${id}/external-mysql/disable`, {
                        type: 'external-mysql',
                        id: id,
                        attributes: {
                            ip_address: attributes.ip_address
                        }
                    });
                },

                ssh: (id, attributes) => {
                    return axios.post(`/api/web-hosting/${id}/ssh-access/disable`, {
                        type: 'ssh-access',
                        id: id,
                        attributes: {
                            ip_address: attributes.ip_address
                        }
                    });
                },

                ipv6: (id) => {
                    return axios.post(`/api/web-hosting/${id}/ipv6/disable`);
                }
            },

            send: (id) => {
                return axios.post(`/api/web-hosting/${id}/email`);
            },

            prorata: (id, attributes) => {
                return axios.post(`/api/web-hosting/${id}/product/upgrade/details`, {
                    type: 'purchase-hosting',
                    id: id,
                    attributes
                });
            },

            upgradeHosting: (id, attributes) => {
                return axios.post(`/api/web-hosting/${id}/product/upgrade`, {
                    type: 'purchase-hosting',
                    id: id,
                    attributes
                });
            },

            upgradeLegacy: (id, attributes) => {
                return axios.post(`/api/web-hosting/${id}/product/legacy-upgrade`, {
                    type: 'purchase-hosting',
                    id: id,
                    attributes
                });
            },

            resetPassword: (id) => {
                return axios.post(`/api/web-hosting/${id}/cpanel/login/password/reset`);
            },

            billingCycle: (id, attributes) => {
                return axios.post(`/api/web-hosting/${id}/billing-cycle/update`, {
                    attributes
                });
            },

            autossl: (id) => {
                return axios.post(`/api/web-hosting/${id}/auto-ssl`);
            },

            permissions: (id) => {
                return axios.post(`/api/web-hosting/${id}/fix-permission/`);
            },

            purchaseDiskBlock: (id, diskblock, attributes) => {
                return axios.post(`/api/web-hosting/${id}/disk-blocks/purchase/${diskblock}`, {
                    attributes
                });
            },

            cancelRemoveDiskBlock: (id, diskblock) => {
                return axios.post(`/api/web-hosting/${id}/disk-blocks/cancel-removal/${diskblock}`);
            },

            checkFirewall: (id, attributes) => {
                return axios.post(`/api/web-hosting/${id}/firewall/check`, {
                    type: 'firewall',
                    id: id,
                    attributes: {
                        ip_address: attributes.ip_address
                    }
                });
            },

            unblockFirewall: (id, attributes) => {
                return axios.post(`/api/web-hosting/${id}/firewall/unblock`, {
                    type: 'firewall',
                    id: id,
                    attributes: {
                        ip_address: attributes.ip_address
                    }
                });
            },

            custom: {
                calculateCost: (id, attributes) => {
                    return axios({
                        method: 'post',
                        url: `/api/web-hosting/custom-hosting/calculate-cost/${id}`,
                        data: {
                            attributes
                        },
                        cancelToken: new CancelToken(function executor(c) {
                            cancelHostingUpgradeCalculate = c;
                        })
                    });
                },

                change: (id, attributes) => {
                    return axios.post(`/api/web-hosting/custom-hosting/change/${id}`, {
                        attributes
                    });
                },

                removeChange: (id) => {
                    return axios.post(`/api/web-hosting/custom-hosting/remove-request/${id}`);
                },

                purchaseAddons: (id, attributes) => {
                    return axios.post(`/api/web-hosting/custom-hosting/purchase-addon/${id}`, {
                        attributes
                    });
                },

                removeAddon: (id, attributes) => {
                    return axios.post(`/api/web-hosting/custom-hosting/remove-addon/${id}`, {
                        attributes
                    });
                },

                keepAddon: (id, attributes) => {
                    return axios.post(`/api/web-hosting/custom-hosting/keep-addon/${id}`, {
                        attributes
                    });
                }
            }
        },

        PUT: {
            primary: (id, attributes) => {
                return axios.put(`/api/web-hosting/${id}/cpanel/primary-domain`, {
                    type: 'web-hosting',
                    id: id,
                    attributes
                });
            },

            cpanel: (id, attributes) => {
                return axios.put(`/api/web-hosting/${id}/cpanel/login/username`, {
                    type: 'web-hosting-login',
                    id: id,
                    attributes
                });
            }
        },

        subaccount: {
            GET: {
                list: (id) => {
                    return axios.get(`/api/web-hosting/${id}/cpanel/sub-account/`);
                },

                details: (id, username) => {
                    return axios.get(`/api/web-hosting/${id}/cpanel/sub-account/details/${username}`);
                },

                cpanel: (id, username) => {
                    return axios.get(`/api/web-hosting/${id}/cpanel/login/sub-account/${username}`);
                },

                tempurl: (id, username) => {
                    return axios.get(`/api/web-hosting/${id}/temporary-url/sub-account/${username}`);
                },

                mysql: (id, username) => {
                    return axios.get(`/api/web-hosting/${id}/external-mysql/sub-account/${username}`);
                },

                ssh: (id, username) => {
                    return axios.get(`/api/web-hosting/${id}/ssh-access/sub-account/${username}`);
                },

                ipv6: (id, username) => {
                    return axios.get(`/api/web-hosting/${id}/ipv6/sub-account/${username}`);
                },

                autossl: (id, username) => {
                    return axios.get(`/api/web-hosting/${id}/auto-ssl/sub-account/${username}`);
                },

                modSec: (id, username) => {
                    return axios.get(`/api/web-hosting/${id}/mod-sec/sub-account/${username}`);
                },

                fileQuarantine: (id, username) => {
                    return axios.get(`/api/web-hosting/${id}/file-quarantine/sub-account/${username}`);
                }
            },

            POST: {
                enable: {
                    tempurl: (id, username) => {
                        return axios.post(`/api/web-hosting/${id}/temporary-url/sub-account/${username}/enable`);
                    },

                    mysql: (id, attributes, username) => {
                        return axios.post(`/api/web-hosting/${id}/external-mysql/sub-account/${username}/enable`, {
                            type: 'external-mysql',
                            id: id,
                            attributes: {
                                ip_address: attributes.ip_address
                            }
                        });
                    },

                    ssh: (id, attributes, username) => {
                        return axios.post(`/api/web-hosting/${id}/ssh-access/sub-account/${username}/enable`, {
                            type: 'ssh-access',
                            id: id,
                            attributes: {
                                ip_address: attributes.ip_address
                            }
                        });
                    },

                    ipv6: (id, username) => {
                        return axios.post(`/api/web-hosting/${id}/ipv6/sub-account/${username}/enable`);
                    }
                },

                disable: {
                    tempurl: (id, username) => {
                        return axios.post(`/api/web-hosting/${id}/temporary-url/sub-account/${username}/disable`);
                    },

                    mysql: (id, attributes, username) => {
                        return axios.post(`/api/web-hosting/${id}/external-mysql/sub-account/${username}/disable`, {
                            type: 'external-mysql',
                            id: id,
                            attributes: {
                                ip_address: attributes.ip_address
                            }
                        });
                    },

                    ssh: (id, attributes, username) => {
                        return axios.post(`/api/web-hosting/${id}/ssh-access/sub-account/${username}/disable`, {
                            type: 'ssh-access',
                            id: id,
                            attributes: {
                                ip_address: attributes.ip_address
                            }
                        });
                    },

                    ipv6: (id, username) => {
                        return axios.post(`/api/web-hosting/${id}/ipv6/sub-account/${username}/disable`);
                    }
                },

                autossl: (id, username) => {
                    return axios.post(`/api/web-hosting/${id}/auto-ssl/sub-account/${username}`);
                },

                permissions: (id, username) => {
                    return axios.post(`/api/web-hosting/${id}/fix-permission/sub-account/${username}`);
                }
            },

            PUT: {
                username: (id, attributes, username) => {
                    return axios.put(`/api/web-hosting/${id}/cpanel/login/username/${username}`, {
                        type: 'web-hosting-login',
                        id: id,
                        attributes
                    });
                },

                primary: (id, attributes, username) => {
                    return axios.put(`/api/web-hosting/${id}/cpanel/primary-domain/sub-account/${username}`, {
                        type: 'web-hosting',
                        id: id,
                        attributes
                    });
                }
            }
        }
    }
};

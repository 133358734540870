/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { useEffect } from 'react';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { useSelector } from 'react-redux';
import { createModuleStore } from 'utilities/methods/createModuleStore';

/**********************************************************************************************************
 *   FUNCTION START
 **********************************************************************************************************/
export const MicrosoftModuleStore = createModuleStore(
    {
        manage: {
            overview: {
                enabled: true,
                label: 'Overview',
                to: '/my-services/microsoft-365/$microsoftId/manage',
                hash: 'overview'
            },
            upgrade: {
                enabled: true,
                label: 'Upgrade/Downgrade',
                to: '/my-services/microsoft-365/$microsoftId/manage',
                hash: 'upgrade'
            },
            manageSeats: {
                enabled: true,
                label: 'Manage Seats',
                to: '/my-services/microsoft-365/$microsoftId/manage',
                hash: 'manage-seats'
            }
        },
        admin: {
            delete: {
                enabled: true,
                label: 'Delete Subscription',
                to: '/my-services/microsoft-365/$microsoftId/admin',
                hash: 'delete-subscriptions'
            }
        }
    },
    MicrosoftModuleConditions
);

/**********************************************************************************************************
 *   CONDITION START
 **********************************************************************************************************/
function MicrosoftModuleConditions(args: any) {
    const store = createModuleStore.castHookProps<typeof MicrosoftModuleStore>(args);

    /***** HOOKS *****/
    const hasUpgradeData = useSelector((state: any) => !!state.ms365.ms365_upgrade_paths_data);

    /***** EFFECTS *****/
    useEffect(() => {
        store.batch(() => {
            store.updateChangedStoreKey('manage.upgrade.enabled', hasUpgradeData);
        });
    });
}

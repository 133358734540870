/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import { planNames } from './consts';
import { hostingTypes } from './consts';

export function getLowerCasePlanNameFromProduct(product) {
    if (typeof product?.attributes?.name !== 'string') {
        return '';
    }

    return product.attributes.name.toLowerCase();
}

export function getInitiallySelectedPlanName(initiallySelectedHostingType) {
    switch (initiallySelectedHostingType) {
        case hostingTypes.CUSTOM:
            return planNames.CUSTOM;
        case hostingTypes.BUSINESS:
        default:
            return planNames.FREEDOM_PLUS;
    }
}

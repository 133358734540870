/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import Tooltip from '@mui/material/Tooltip';
import _ from 'lodash';
import React, { useLayoutEffect, useMemo, useState } from 'react';
import { useDebounceValue } from 'usehooks-ts';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import Anchor from 'components/Anchor';
import SolidButton from 'components/Buttons/SolidButton';
import FetchComponentError from 'components/Errors/FetchComponentError';
import { CardSelectGrid } from 'components/Form/CardSelectGrid';
import { Input } from 'components/Form/Input';
import Grid from 'components/Grid';
import { PhosphorIcons } from 'components/Icons/Phosphor';
import RequestLoader from 'components/Loaders/Request';
import NXBox from 'components/NXBox';
import { Shimmer } from 'components/Shimmer';
import { Flex } from 'components/Utils/Flex';
import { NXSquare } from 'components/Utils/NXSquare';
import Padding from 'components/Utils/Padding';
import Text from 'components/Utils/Text';

/**********************************************************************************************************
 *   QUERIES
 **********************************************************************************************************/
import { katanaQuery } from 'containers/katana/queries/tanstackTree';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { useKatanaParams } from 'containers/katana/hooks/useSetupEditorRouteParams';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import UnsplashHorizontalLogo from 'assets/images/Unsplash_Logo_Full.png';
import { sectionDefinitionUnsplashDownloadLinks } from 'containers/katana/containers/ContentEditorLightbox/formHandlers/consts';
import './_dropzone_or_stock_photo.scss';

/**********************************************************************************************************
 *   TYPE IMPORTS
 **********************************************************************************************************/
import type { CardSelectGridNamespace } from 'components/Form/CardSelectGrid/types';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/

type KatanaFileFormFieldChooseStockPhotoDecoupledProps = {
    fieldName: string;
    onChange: (value: CardSelectGridNamespace.SelectCard.OnChangeValue) => void;
    value?: any;
};

const unsplashPhotoLimit = 10;

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const KatanaFileFormFieldChooseStockPhotoDecoupled: React.FC<KatanaFileFormFieldChooseStockPhotoDecoupledProps> = ({
    fieldName,
    onChange,
    value
}) => {
    /***** STATE *****/
    const [totalPages, setTotalPages] = useState(1);
    const [inputValue, setInputValue] = useState('');
    const [pageIndex, setPageIndex] = useState(1);

    /***** HOOKS *****/
    const [searchValue, setSearchValue] = useDebounceValue('', 500);
    const { katanaServiceId } = useKatanaParams();

    /***** QUERIES *****/
    const { data: katana_service_data } = katanaQuery.serviceID.getService.useQuery(katanaServiceId);

    /***** RENDER HELPERS *****/
    const hasSelectedBusinessCategories = Boolean(katana_service_data?.attributes?.business_categories?.length);
    const finalSearchQuery = useMemo(() => {
        const finalSearchQuery = [];

        if (searchValue) {
            finalSearchQuery.push(searchValue);
        } else if (hasSelectedBusinessCategories) {
            finalSearchQuery.push(...katana_service_data.attributes.business_categories);
        } else {
            finalSearchQuery.push('Random');
        }

        return finalSearchQuery.join(' ');
    }, [searchValue, pageIndex, katana_service_data]);

    /***** QUERIES *****/
    const {
        data: unsplash_search_data,
        isFetching: isFetchingUnsplashPhotos,
        isLoading: isSearchUnsplashLoading,
        isSuccess: isSearchUnsplashSuccess,
        isError: isSearchUnsplashError
    } = katanaQuery.searchUnsplash.useQuery(katanaServiceId, {
        search: finalSearchQuery,
        page: pageIndex,
        per_page: unsplashPhotoLimit
    });

    /***** EFFECTS *****/
    useLayoutEffect(() => {
        setSearchValue(inputValue);
        setPageIndex(1);
    }, [inputValue]);

    useLayoutEffect(() => {
        if (_.isNumber(unsplash_search_data?.total_pages)) {
            setTotalPages(unsplash_search_data?.total_pages);
        }
    }, [unsplash_search_data?.total_pages]);

    /***** RENDER HELPERS *****/
    const hasImages = useMemo(() => Boolean(unsplash_search_data?.results?.length), [unsplash_search_data]);
    const mappedPhotos = useMemo(() => {
        if (isFetchingUnsplashPhotos) {
            return Array.from({ length: unsplashPhotoLimit }, (_, i) => ({
                type: 'custom',
                value: 'custom_shimmer',
                content: (
                    <NXSquare ratio={0.6}>
                        <Shimmer />
                    </NXSquare>
                )
            })) as CardSelectGridNamespace.SelectCard.CustomOption[];
        }

        if (isSearchUnsplashSuccess) {
            return _.map(unsplash_search_data.results, (result) => ({
                type: 'image',
                url: result.urls.full,
                thumb: result.urls.small,
                onSelect: () => {
                    sectionDefinitionUnsplashDownloadLinks.setState((prev) => ({ ...prev, [fieldName]: result.links.download_location }));
                },
                extra_content: (
                    <div className="KatanaFileFormFieldNXDropZoneOrStockPhoto__SelectCardExtraContent">
                        <Text size--xs break-word black>
                            Photo by{' '}
                            <Anchor
                                href={result.user.links.html}
                                inheritSize
                                color="black"
                                hoverColor="primary"
                                target="_blank"
                                onClick={(e) => {
                                    e.stopPropagation();
                                }}
                            >
                                <Text bold>{result.user?.name ?? 'Unsplash'}</Text>
                            </Anchor>
                        </Text>
                    </div>
                )
            })) as CardSelectGridNamespace.SelectCard.ImageOption[];
        }
        return [];
    }, [unsplash_search_data, isSearchUnsplashSuccess, fieldName]);

    function renderSearchErrorOrLoader() {
        if (isFetchingUnsplashPhotos) {
            return <RequestLoader />;
        }

        return (
            <Text italic warn align--center>
                {`No images found for "${searchValue}"`}
            </Text>
        );
    }

    /***** RENDER *****/
    if (isSearchUnsplashError) {
        return <FetchComponentError />;
    }

    return (
        <>
            <Grid columns="1fr auto" alignItems--center gap={1}>
                <NXBox.Title title="Choose a Stock Photo" />
                <Padding top={4} bottom={2}>
                    <a
                        href="https://unsplash.com/?utm_source=Katana&utm_medium=referral"
                        target="_blank"
                        rel="noreferrer"
                        className="KatanaFileFormFieldNXDropZoneOrStockPhoto__UnsplashLogoLink"
                    >
                        <img width="auto" height="24" src={UnsplashHorizontalLogo} alt="Unsplash" />
                    </a>
                </Padding>
            </Grid>
            <br />
            <Text secondary semiBold size--s>
                {hasSelectedBusinessCategories
                    ? 'We have a stock photography library for you to pick from. These have been curated for you from the industry you selected earlier.'
                    : 'We have a stock photography library for you to pick from.'}
            </Text>
            <Padding top={2} bottom={4}>
                <Input
                    name="search"
                    placeholder='e.g. "Barista" or "Helping Hands"'
                    value={inputValue}
                    intrinsicProps={{
                        onChange: (e) => {
                            setInputValue(e.target.value);
                        },
                        onKeyDown: (e) => {
                            if (e.key === 'Enter') {
                                e.preventDefault();
                            }
                        }
                    }}
                />
            </Padding>
            <CardSelectGrid onChange={onChange} options={mappedPhotos} isHidingDisabled disabled={isFetchingUnsplashPhotos} value={value} />
            <Padding top={2} bottom={4}>
                {hasImages ? (
                    <Flex justify="between" items="center">
                        <Tooltip title="Previous Page" arrow>
                            <SolidButton
                                className="KatanaFileFormFieldNXDropZoneOrStockPhoto__StockImageNavigationButton"
                                type="onClick"
                                color="primary"
                                onClick={() => {
                                    setPageIndex((index) => Math.max(--index, 1));
                                }}
                                disabled={isSearchUnsplashLoading || pageIndex === 1}
                                onMouseOver={() => {
                                    katanaQuery.searchUnsplash.prefetchQuery(katanaServiceId, {
                                        search: finalSearchQuery,
                                        page: Math.max(pageIndex - 1, 1),
                                        per_page: unsplashPhotoLimit
                                    });
                                }}
                            >
                                <PhosphorIcons.Arrow.Left.Bold size={20} />
                            </SolidButton>
                        </Tooltip>
                        <Text size--s>
                            {pageIndex === 1 && isSearchUnsplashLoading ? (
                                <RequestLoader />
                            ) : (
                                <>
                                    Page {pageIndex} of {totalPages}
                                </>
                            )}
                        </Text>
                        <Tooltip title="Next Page" arrow>
                            <SolidButton
                                className="KatanaFileFormFieldNXDropZoneOrStockPhoto__StockImageNavigationButton"
                                type="onClick"
                                color="primary"
                                disabled={isSearchUnsplashLoading || pageIndex >= totalPages}
                                onClick={() => {
                                    setPageIndex((index) => ++index);
                                }}
                                onMouseOver={() => {
                                    katanaQuery.searchUnsplash.prefetchQuery(katanaServiceId, {
                                        search: finalSearchQuery,
                                        page: pageIndex + 1,
                                        per_page: unsplashPhotoLimit
                                    });
                                }}
                            >
                                <PhosphorIcons.Arrow.Right.Bold size={20} />
                            </SolidButton>
                        </Tooltip>
                    </Flex>
                ) : (
                    renderSearchErrorOrLoader()
                )}
            </Padding>
            <Text secondary size--s italic>
                This image is provided by Unsplash. By using it, you agree to comply with
                <Anchor href="https://unsplash.com/terms?utm_source=Katana&utm_medium=referral" target="_blank">
                    <Text size--s italic>
                        {` Unsplash's Terms. `}
                    </Text>
                </Anchor>
                <Anchor href="https://ventraip.com.au/support-centre/unsplash-images-and-vipsites/" target="_blank">
                    <Text size--s italic>
                        Learn More.
                    </Text>
                </Anchor>
            </Text>
        </>
    );
};

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

/*   ACTIONS
 *****************************************************/
import { pushNotification } from 'components/Toast/functions';

/**********************************************************************************************************
 *   DECLARATIONS
 **********************************************************************************************************/
export const AUTOCOMPLETE_ADDRESS_SUGGESTIONS_REQUEST = 'autocomplete/AUTOCOMPLETE_ADDRESS_SUGGESTIONS_REQUEST';
export const AUTOCOMPLETE_ADDRESS_SUGGESTIONS_SUCCESS = 'autocomplete/AUTOCOMPLETE_ADDRESS_SUGGESTIONS_SUCCESS';
export const AUTOCOMPLETE_ADDRESS_SUGGESTIONS_ERROR = 'autocomplete/AUTOCOMPLETE_ADDRESS_SUGGESTIONS_ERROR';
export const AUTOCOMPLETE_ADDRESS_DETAILS_REQUEST = 'autocomplete/AUTOCOMPLETE_ADDRESS_DETAILS_REQUEST';
export const AUTOCOMPLETE_ADDRESS_DETAILS_SUCCESS = 'autocomplete/AUTOCOMPLETE_ADDRESS_DETAILS_SUCCESS';
export const AUTOCOMPLETE_ADDRESS_DETAILS_ERROR = 'autocomplete/AUTOCOMPLETE_ADDRESS_DETAILS_ERROR';

/**********************************************************************************************************
 *   AUTOCOMPLETE SUGGESTIONS
 **********************************************************************************************************/
export const getAutocompleteSuggestions = (form, sessionToken, input, countryRestrictions, biasAUAndNZ) => {
    return (dispatch) => {
        dispatch({
            type: AUTOCOMPLETE_ADDRESS_SUGGESTIONS_REQUEST
        });
        const autocomplete = new window.google.maps.places.AutocompleteService();

        const options = { input };

        if (sessionToken) options.sessionToken = sessionToken;
        if (countryRestrictions) options.componentRestrictions = { country: countryRestrictions };
        if (biasAUAndNZ) {
            options.location = new window.google.maps.LatLng(-37.63717861251742, 144.83865187243075);
            options.radius = 3500000;
        }

        autocomplete.getPlacePredictions(options, (predictions, status) => {
            if (status !== window.google.maps.places.PlacesServiceStatus.OK || !predictions) {
                return dispatch({
                    type: AUTOCOMPLETE_ADDRESS_SUGGESTIONS_ERROR,
                    autocomplete_address_suggestions_error: 'Failed to get suggestions.'
                });
            }
            const autocomplete_address_suggestions_data = { predictions, status };
            autocomplete_address_suggestions_data.form = form;
            dispatch({
                type: AUTOCOMPLETE_ADDRESS_SUGGESTIONS_SUCCESS,
                autocomplete_address_suggestions_data
            });
        });
    };
};

/**********************************************************************************************************
 *   PLACE DETAILS
 **********************************************************************************************************/
export const getAddressDetails = (form, sessionToken, placeId, selectedDescription, dummyMapElement, callback) => {
    return (dispatch) => {
        dispatch({
            type: AUTOCOMPLETE_ADDRESS_DETAILS_REQUEST
        });
        const placesService = new window.google.maps.places.PlacesService(dummyMapElement);

        const request = {
            placeId,
            fields: ['address_component']
        };

        if (sessionToken) request.sessionToken = sessionToken;

        placesService.getDetails(request, (place, status) => {
            if (callback) callback();
            if (status !== window.google.maps.places.PlacesServiceStatus.OK || !place) {
                return pushNotification({ details: 'Failed to get address details', status: 400 });
            }
            const autocomplete_address_details_data = place;
            autocomplete_address_details_data.form = form;
            autocomplete_address_details_data.selectedDescription = selectedDescription;
            dispatch({
                type: AUTOCOMPLETE_ADDRESS_DETAILS_SUCCESS,
                autocomplete_address_details_data
            });
        });
    };
};

/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { Link } from '@tanstack/react-router';
import classNames from 'classnames';
import React from 'react';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import './_Anchor.scss';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
import type { NAnchor } from 'components/Anchor/types';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
const Anchor: React.FC<NAnchor.Props> = (props) => {
    const { children, onClick, className, target, href, to, color = 'base', hoverColor, underline, inheritSize, inheritFontWeight } = props;

    const anchorClassNames = classNames('Anchor', className, {
        [`Anchor--${color}`]: color,
        [`Anchor--hoverColor-${hoverColor}`]: hoverColor,
        'Anchor--underline': underline,
        'Anchor--inherit-size': inheritSize,
        'Anchor--inherit-fontWeight': inheritFontWeight
    });

    if (to) {
        if (typeof onClick === 'function') {
            return (
                <Link className={anchorClassNames} to={to} onClick={onClick}>
                    {children}
                </Link>
            );
        }

        return (
            <Link className={anchorClassNames} to={to}>
                {children}
            </Link>
        );
    }

    if (target && href && !onClick) {
        return (
            <a className={anchorClassNames} target={target} href={href}>
                {children}
            </a>
        );
    }

    // Sometimes you need to prevent propagation when clicking an anchor tag inside of a button
    if (target && href && onClick) {
        return (
            <a className={anchorClassNames} target={target} href={href} onClick={onClick}>
                {children}
            </a>
        );
    }

    if (href && !onClick) {
        return (
            <a className={anchorClassNames} href={href}>
                {children}
            </a>
        );
    }

    return (
        <button type="button" className={anchorClassNames} onClick={onClick}>
            {children}
        </button>
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

export default Anchor;

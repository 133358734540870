/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React from 'react';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import { _RequestLoader as RequestLoader } from 'components/Loaders/Request/_requestLoader';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { useMultiLoaderCurrentMessage } from 'components/Loaders/Request/hooks/useLoaderCurrentMessage';
import { useLoadersData } from 'components/Loaders/Request/hooks/useLoadersData';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * Renders the request loader if there's ongoing loading state, otherwise renders the children
 */
const __RequestLoaderMultiLoader: React.FC<NRequestLoader.MultiLoader.Props> = ({ loaders, children, ...props }) => {
    /***** RENDER HELPERS *****/
    const currentLoader = useMultiLoaderCurrentMessage(loaders);
    /***** RENDER *****/
    if (!currentLoader) {
        return children;
    }
    /***** RENDER *****/
    return <RequestLoader {...props} message={currentLoader.message} />;
};

export const _RequestLoaderMultiLoader = Object.assign(__RequestLoaderMultiLoader, {
    useLoadersData,
    useCurrentMessage: useMultiLoaderCurrentMessage
});

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

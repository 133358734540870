/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { queryOptions, useQuery } from '@tanstack/react-query';

/**********************************************************************************************************
 *   QUERIES
 **********************************************************************************************************/
import queryClient from 'store/queryClient';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { KATANA_API } from 'utilities/api/katana';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import { MINUTE } from 'utilities/consts';

/**********************************************************************************************************
 *   TYPE IMPORTS
 **********************************************************************************************************/
import { katanaQueryKeys } from 'containers/katana/queries/katanaQueryKeys';
import type { KatanaAPI } from 'utilities/api/katana/types';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type Params = KatanaAPI.Katana.ServiceID.Uploads.UploadID.file.GET.Params;

function createQueryKey(params: Params) {
    return katanaQueryKeys.katana.file.ID(params);
}

function createQueryOptions(params: Params) {
    return queryOptions({
        queryKey: createQueryKey(params),
        queryFn: () => KATANA_API.katana.service_id.uploads.uploadId.GET(params),
        staleTime: MINUTE * 2.5,
        enabled: Boolean(params.serviceID && params.uploadID),
        select: (data) => {
            if (data?.status === 200) {
                return data.data;
            }
        }
    });
}

function invalidateQueries(params: Params) {
    return queryClient.invalidateQueries({ queryKey: createQueryKey(params) });
}

/**********************************************************************************************************
 *   HOOK START
 **********************************************************************************************************/
function _useQuery(params: Params) {
    return useQuery(createQueryOptions(params));
}
/**********************************************************************************************************
 *   HOOK END
 **********************************************************************************************************/

export const getSingleFileInfo = Object.freeze({
    useQuery: _useQuery,
    invalidateQueries,
    createQueryKey,
    createQueryOptions
});

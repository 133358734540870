/**********************************************************************************************************
 *   IMPORTS
 **********************************************************************************************************/
import { billingCycles } from 'config/config';

/**********************************************************************************************************
 *   ADDONS
 **********************************************************************************************************/
export const addonMethods = {
    processActiveAddonData: (addon, addonProducts) => {
        const { id, product_id, pending_cancellation, billing_cycle_id } = addon;
        const productData = addonProducts?.find((addonProduct) => addonProduct.id === product_id);
        const billingCycle = productData?.pricing_list?.find((price) => price.billing_cycle_id === billing_cycle_id);
        const price = billingCycle?.price ? `$${billingCycle.price}${billingCycles[billingCycle?.billing_cycle_name || 'Monthly']}` : '';

        return {
            id: id || null,
            name: productData?.name || '',
            description: productData?.description?.replace(/<[^>]*>/g, '') || productData?.name || '',
            price,
            pendingCancellation: pending_cancellation
        };
    },

    processPurchasableAddonData: (addonProduct, serviceBillingCycle) => {
        const { id, name, description, pricing_list, config_list } = addonProduct;
        const billingCycle = pricing_list?.find((price) => price?.billing_cycle_name === serviceBillingCycle);
        const price = billingCycle?.price ? `$${billingCycle.price}${billingCycles[billingCycle?.billing_cycle_name || 'Monthly']}` : '';

        return {
            id: id || null,
            name: name || '',
            description: description?.replace(/<[^>]*>/g, '') || name || '',
            config_list,
            isFree: Number(billingCycle?.price || 0) <= 0,
            price
        };
    },

    processConfigOptionData: (configOption, serviceBillingCycle, addonProductId) => {
        const { name, options, id } = configOption;

        if (!id || !addonProductId || !options) return null;

        const nameAtt = addonProductId + '__' + id;
        const fieldOptions = options.map((option) => ({
            label: `${option.name} - $${option.pricing_list?.find((item) => item.billing_cycle_name === serviceBillingCycle)?.price}${
                billingCycles[serviceBillingCycle]
            }`,
            value: option.id
        }));

        return {
            name: name || '',
            nameAtt,
            fieldOptions
        };
    },

    processAddonsForPurchase: (values, billingCycleId) => {
        const newValues = {};

        // get all ordered addons
        Object.keys(values)
            .filter((key) => !key.includes('__'))
            .forEach((key) => (newValues[key] = []));
        // populate config of each addon
        Object.entries(values).forEach(([key, option_id]) => {
            if (key.includes('__')) {
                const [addonProductId, config_id] = key.split('__');
                if (values[addonProductId]) newValues[addonProductId].push({ config_id: Number(config_id), option_id });
            }
        });

        return Object.entries(newValues).map(([id, config]) => ({ id: Number(id), config, billing_cycle_id: billingCycleId }));
    }
};

/**
 *
 * @param {Artah.WebHosting.ID.GET._200['data']} hosting_information_data
 * @returns {Artah.WebHosting.ID.GET._200['data']['included'][0] | undefined} product
 */
export function getHostingProduct(hosting_information_data) {
    const product = Array.isArray(hosting_information_data?.included)
        ? hosting_information_data.included.find((inc) => inc.type === 'product')
        : undefined;

    return product;
}

/**
 *
 * @param {Artah.WebHosting.ID.GET._200['data']['included'][1] | undefined} product
 * @returns {string} productName
 */
export function getHostingProductName(product) {
    return typeof product?.attributes?.name === 'string' ? product?.attributes?.name : '';
}

/**
 *
 * @param {*} productName
 * @returns {boolean} isVictoriaEducationHosting
 */
export function checkIsVictoriaEducationHosting(productName) {
    return productName === 'Victoria Education Hosting';
}

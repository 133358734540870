/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import store from 'store/store';
import { cancelBulkInfoRequest } from 'utilities/api/domain';

/*   ACTIONS
 *****************************************************/
import { bulkInfo, bulkInfoReset } from './action';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { getCurrentDate, toLuxonDate } from 'utilities/methods/commonActions';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import { CLEAR_PROMO_CODE } from './Components/renew/consts';

export function calculateBulkDomainPrice(actionArray = []) {
    let newSubtotal = 0;
    let newTotal = 0;

    actionArray.forEach(({ attributes: { price, promo_applies, base_price, discount_price } } = {}) => {
        if (promo_applies) {
            newTotal += Number(discount_price);
            newSubtotal += Number(base_price);
        } else {
            newTotal += Number(price);
            newSubtotal += Number(price);
        }
    });

    const discount = newSubtotal - newTotal;

    return {
        total: newTotal.toFixed(2),
        subtotal: newSubtotal.toFixed(2),
        discount: discount ? discount?.toFixed(2) : null
    };
}

export function renderBulkServiceExpiry(expiry_date) {
    const expiryDateLuxon = toLuxonDate(expiry_date, 'yyyy-MM-dd');
    const expiry = parseInt(getCurrentDate().diff(expiryDateLuxon, 'days').toObject()['days']);
    const status = getCurrentDate().startOf('day') < expiryDateLuxon.startOf('day') ? 'Active' : 'Expired';

    if (expiry > 1 && status === 'Expired') return `Expired ${expiry} days ago`;
    if (expiry === -1) return `Expires in 1 day`;
    if (expiry === 0) return `Expires Today`;
    if (expiry === 1) return `Expired ${expiry} day ago`;
    if (status === 'Redemption') return `Domain in Redemption. Please contact support to renew`;
    return `Expires in ${expiry.toString().substring(1)} days`;
}

export function filterDomainBulkListData(domain_bulk_list_data) {
    return domain_bulk_list_data.filter((domain) => {
        if (!domain.attributes) return false;

        const { pending_application, status } = domain.attributes;
        if (['Action Required', 'In Progress', 'Application Expiring', 'Pending Transfer'].includes(status) || pending_application) return false;

        return true;
    });
}

/**
 * @param {{
 *   selectedServices: Array,
 *   promoCode: string,
 *   appliedPromo: string
 * }} props 
 * 
 * promoCode takes precedence over appliedPromo & promoCode can be set to CLEAR_PROMO_CODE to clear the promo code
 */
export function getRenewData({ selectedServices, promoCode, appliedPromo }) {
    const { dispatch } = store;
    if (selectedServices?.length > 0) {
        const data = selectedServices.map((service) => ({
            type: 'domain',
            id: service.id
        }));

        let newPromoCode;
        // if promoCode has a value of "CLEAR_PROMO_CODE" set the new promo code to null
        if (promoCode === CLEAR_PROMO_CODE) newPromoCode = null;
        // if promoCode has a value other than "CLEAR_PROMO_CODE" then set the new promo code to whatever the value is
        else if (promoCode) newPromoCode = promoCode;
        // if promoCode is falsy then it means this method has been called by adding or removing a domain from the list, in which case use the already applied promo code
        else newPromoCode = appliedPromo;

        dispatch(bulkInfo(data, newPromoCode));
    } else if (selectedServices?.length === 0) {
        if (cancelBulkInfoRequest) {
            cancelBulkInfoRequest();
        }
        bulkInfoReset();
    }
}

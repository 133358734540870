import { pushNotification } from 'components/Toast/functions';
import { API } from 'utilities/api/hosting';
import { getDataFromSuccessResponse, getErrorFromFailResponse, getMetaFromSuccessResponse } from 'utilities/methods/commonActions';

export const HOSTING_CONFIGURATION_INITIAL_STATE = 'hosting/HOSTING_CONFIGURATION_INITIAL_STATE';
export const HOSTING_CONFIGURATION_REQUEST_RESET = 'hosting/HOSTING_CONFIGURATION_REQUEST_RESET';

export const HOSTING_TEMPURL_CHECK_REQUEST = 'hosting/HOSTING_TEMPURL_CHECK_REQUEST';
export const HOSTING_TEMPURL_CHECK_SUCCESS = 'hosting/HOSTING_TEMPURL_CHECK_SUCCESS';
export const HOSTING_TEMPURL_CHECK_FAIL = 'hosting/HOSTING_TEMPURL_CHECK_FAIL';

export const HOSTING_TEMPURL_REQUEST = 'hosting/HOSTING_TEMPURL_REQUEST';
export const HOSTING_TEMPURL_SUCCESS = 'hosting/HOSTING_TEMPURL_SUCCESS';
export const HOSTING_TEMPURL_FAIL = 'hosting/HOSTING_TEMPURL_FAIL';

export const HOSTING_IPV6_CHECK_REQUEST = 'hosting/HOSTING_IPV6_CHECK_REQUEST';
export const HOSTING_IPV6_CHECK_SUCCESS = 'hosting/HOSTING_IPV6_CHECK_SUCCESS';
export const HOSTING_IPV6_CHECK_FAIL = 'hosting/HOSTING_IPV6_CHECK_FAIL';

export const HOSTING_IPV6_REQUEST = 'hosting/HOSTING_IPV6_REQUEST';
export const HOSTING_IPV6_SUCCESS = 'hosting/HOSTING_IPV6_SUCCESS';
export const HOSTING_IPV6_FAIL = 'hosting/HOSTING_IPV6_FAIL';

export const HOSTING_CHECK_IP_REQUEST = 'hosting/HOSTING_CHECK_IP_REQUEST';
export const HOSTING_CHECK_IP_SUCCESS = 'hosting/HOSTING_CHECK_IP_SUCCESS';

export const HOSTING_DATABASE_LIST_REQUEST = 'hosting/HOSTING_DATABASE_LIST_REQUEST';
export const HOSTING_DATABASE_LIST_SUCCESS = 'hosting/HOSTING_DATABASE_LIST_SUCCESS';
export const HOSTING_DATABASE_LIST_FAIL = 'hosting/HOSTING_DATABASE_LIST_FAIL';

export const HOSTING_DATABASE_REQUEST = 'hosting/HOSTING_DATABASE_REQUEST';
export const HOSTING_DATABASE_SUCCESS = 'hosting/HOSTING_DATABASE_SUCCESS';
export const HOSTING_DATABASE_FAIL = 'hosting/HOSTING_DATABASE_FAIL';

export const HOSTING_SSHACCESS_LIST_REQUEST = 'hosting/HOSTING_SSHACCESS_LIST_REQUEST';
export const HOSTING_SSHACCESS_LIST_SUCCESS = 'hosting/HOSTING_SSHACCESS_LIST_SUCCESS';
export const HOSTING_SSHACCESS_LIST_FAIL = 'hosting/HOSTING_SSHACCESS_LIST_FAIL';

export const HOSTING_SSHACCESS_REQUEST = 'hosting/HOSTING_SSHACCESS_REQUEST';
export const HOSTING_SSHACCESS_SUCCESS = 'hosting/HOSTING_SSHACCESS_SUCCESS';
export const HOSTING_SSHACCESS_FAIL = 'hosting/HOSTING_SSHACCESS_FAIL';

/**********************************************************************************************************
 *   RESET
 **********************************************************************************************************/

export const resetHostingConfiguration = () => {
    return (dispatch) => {
        dispatch({
            type: HOSTING_CONFIGURATION_INITIAL_STATE
        });
    };
};

export const resetHostingConfigurationRequests = () => {
    return (dispatch) => {
        dispatch({
            type: HOSTING_CONFIGURATION_REQUEST_RESET
        });
    };
};

/**********************************************************************************************************
 *   TEMPORARY URL
 **********************************************************************************************************/

export const checkTempURL = (id, username) => {
    if (username) {
        return (dispatch) => {
            dispatch({
                type: HOSTING_TEMPURL_CHECK_REQUEST
            });
            API.hosting.subaccount.GET.tempurl(id, username)
                .then((response) => {
                    const hosting_tempurl_check_data = getDataFromSuccessResponse(response);
                    dispatch({
                        type: HOSTING_TEMPURL_CHECK_SUCCESS,
                        hosting_tempurl_check_data
                    });
                })
                .catch((error) => {
                    const response = getErrorFromFailResponse(error);
                    dispatch({
                        type: HOSTING_TEMPURL_CHECK_FAIL,
                        hosting_tempurl_check_error: response
                    });
                });
        };
    }

    return (dispatch) => {
        dispatch({
            type: HOSTING_TEMPURL_CHECK_REQUEST
        });
        API.hosting.GET.tempurl(id)
            .then((response) => {
                const hosting_tempurl_check_data = getDataFromSuccessResponse(response);
                dispatch({
                    type: HOSTING_TEMPURL_CHECK_SUCCESS,
                    hosting_tempurl_check_data
                });
            })
            .catch((error) => {
                const response = getErrorFromFailResponse(error);
                dispatch({
                    type: HOSTING_TEMPURL_CHECK_FAIL,
                    hosting_tempurl_check_error: response
                });
            });
    };
};

export const enableTempURL = (id, username) => {
    if (username) {
        return (dispatch) => {
            dispatch({
                type: HOSTING_TEMPURL_REQUEST
            });
            API.hosting.subaccount.POST.enable
                .tempurl(id, username)
                .then((response) => {
                    const hosting_tempurl_data = getDataFromSuccessResponse(response);
                    pushNotification(getDataFromSuccessResponse(response));
                    dispatch({
                        type: HOSTING_TEMPURL_SUCCESS,
                        hosting_tempurl_data
                    });
                    dispatch(checkTempURL(id, username));
                })
                .catch((error) => {
                    const response = getErrorFromFailResponse(error);
                    pushNotification(getErrorFromFailResponse(error));
                    dispatch({
                        type: HOSTING_TEMPURL_FAIL,
                        hosting_tempurl_error: response
                    });
                });
        };
    }

    return (dispatch) => {
        dispatch({
            type: HOSTING_TEMPURL_REQUEST
        });
        API.hosting.POST.enable
            .tempurl(id)
            .then((response) => {
                const hosting_tempurl_data = getDataFromSuccessResponse(response);
                pushNotification(getDataFromSuccessResponse(response));
                dispatch({
                    type: HOSTING_TEMPURL_SUCCESS,
                    hosting_tempurl_data
                });
                dispatch(checkTempURL(id));
            })
            .catch((error) => {
                const response = getErrorFromFailResponse(error);
                pushNotification(getErrorFromFailResponse(error));
                dispatch({
                    type: HOSTING_TEMPURL_FAIL,
                    hosting_tempurl_error: response
                });
            });
    };
};

export const disableTempURL = (id, username) => {
    if (username) {
        return (dispatch) => {
            dispatch({
                type: HOSTING_TEMPURL_REQUEST
            });
            API.hosting.subaccount.POST.disable
                .tempurl(id, username)
                .then((response) => {
                    const hosting_tempurl_data = getDataFromSuccessResponse(response);
                    pushNotification(getDataFromSuccessResponse(response));
                    dispatch({
                        type: HOSTING_TEMPURL_SUCCESS,
                        hosting_tempurl_data
                    });
                    dispatch(checkTempURL(id, username));
                })
                .catch((error) => {
                    const response = getErrorFromFailResponse(error);
                    pushNotification(getErrorFromFailResponse(error));
                    dispatch({
                        type: HOSTING_TEMPURL_FAIL,
                        hosting_tempurl_error: response
                    });
                });
        };
    }

    return (dispatch) => {
        dispatch({
            type: HOSTING_TEMPURL_REQUEST
        });
        API.hosting.POST.disable
            .tempurl(id)
            .then((response) => {
                const hosting_tempurl_data = getDataFromSuccessResponse(response);
                pushNotification(getDataFromSuccessResponse(response));
                dispatch({
                    type: HOSTING_TEMPURL_SUCCESS,
                    hosting_tempurl_data
                });
                dispatch(checkTempURL(id));
            })
            .catch((error) => {
                const response = getErrorFromFailResponse(error);
                pushNotification(getErrorFromFailResponse(error));
                dispatch({
                    type: HOSTING_TEMPURL_FAIL,
                    hosting_tempurl_error: response
                });
            });
    };
};

/**********************************************************************************************************
 *   IPV6 MANAGEMENT
 **********************************************************************************************************/

export const checkipv6 = (id, username) => {
    if (username) {
        return (dispatch) => {
            dispatch({
                type: HOSTING_IPV6_CHECK_REQUEST
            });
            API.hosting.subaccount.GET.ipv6(id, username)
                .then((response) => {
                    const hosting_ipv6_check_data = getDataFromSuccessResponse(response);
                    dispatch({
                        type: HOSTING_IPV6_CHECK_SUCCESS,
                        hosting_ipv6_check_data
                    });
                })
                .catch((error) => {
                    const response = getErrorFromFailResponse(error);
                    dispatch({
                        type: HOSTING_IPV6_CHECK_FAIL,
                        hosting_ipv6_check_error: response
                    });
                });
        };
    }

    return (dispatch) => {
        dispatch({
            type: HOSTING_IPV6_CHECK_REQUEST
        });
        API.hosting.GET.ipv6(id)
            .then((response) => {
                const hosting_ipv6_check_data = getDataFromSuccessResponse(response);
                dispatch({
                    type: HOSTING_IPV6_CHECK_SUCCESS,
                    hosting_ipv6_check_data
                });
            })
            .catch((error) => {
                const response = getErrorFromFailResponse(error);
                dispatch({
                    type: HOSTING_IPV6_CHECK_FAIL,
                    hosting_ipv6_check_error: response
                });
            });
    };
};

export const enableipv6 = (id, username) => {
    if (username) {
        return (dispatch) => {
            dispatch({
                type: HOSTING_IPV6_REQUEST
            });
            API.hosting.subaccount.POST.enable
                .ipv6(id, username)
                .then((response) => {
                    const hosting_ipv6_data = getDataFromSuccessResponse(response);
                    pushNotification(getDataFromSuccessResponse(response));
                    dispatch({
                        type: HOSTING_IPV6_SUCCESS,
                        hosting_ipv6_data
                    });
                    dispatch(checkipv6(id, username));
                })
                .catch((error) => {
                    const response = getErrorFromFailResponse(error);
                    pushNotification(getErrorFromFailResponse(error));
                    dispatch({
                        type: HOSTING_IPV6_FAIL,
                        hosting_ipv6_error: response
                    });
                });
        };
    }

    return (dispatch) => {
        dispatch({
            type: HOSTING_IPV6_REQUEST
        });
        API.hosting.POST.enable
            .ipv6(id)
            .then((response) => {
                const hosting_ipv6_data = getDataFromSuccessResponse(response);
                pushNotification(getDataFromSuccessResponse(response));
                dispatch({
                    type: HOSTING_IPV6_SUCCESS,
                    hosting_ipv6_data
                });
                dispatch(checkipv6(id));
            })
            .catch((error) => {
                const response = getErrorFromFailResponse(error);
                pushNotification(getErrorFromFailResponse(error));
                dispatch({
                    type: HOSTING_IPV6_FAIL,
                    hosting_ipv6_error: response
                });
            });
    };
};

export const disableipv6 = (id, username) => {
    if (username) {
        return (dispatch) => {
            dispatch({
                type: HOSTING_IPV6_REQUEST
            });
            API.hosting.subaccount.POST.disable
                .ipv6(id, username)
                .then((response) => {
                    const hosting_ipv6_data = getDataFromSuccessResponse(response);
                    pushNotification(getDataFromSuccessResponse(response));
                    dispatch({
                        type: HOSTING_IPV6_SUCCESS,
                        hosting_ipv6_data
                    });
                    dispatch(checkipv6(id, username));
                })
                .catch((error) => {
                    const response = getErrorFromFailResponse(error);
                    pushNotification(getErrorFromFailResponse(error));
                    dispatch({
                        type: HOSTING_IPV6_FAIL,
                        hosting_ipv6_error: response
                    });
                });
        };
    }

    return (dispatch) => {
        dispatch({
            type: HOSTING_IPV6_REQUEST
        });
        API.hosting.POST.disable
            .ipv6(id)
            .then((response) => {
                const hosting_ipv6_data = getDataFromSuccessResponse(response);
                pushNotification(getDataFromSuccessResponse(response));
                dispatch({
                    type: HOSTING_IPV6_SUCCESS,
                    hosting_ipv6_data
                });
                dispatch(checkipv6(id));
            })
            .catch((error) => {
                const response = getErrorFromFailResponse(error);
                pushNotification(getErrorFromFailResponse(error));
                dispatch({
                    type: HOSTING_IPV6_FAIL,
                    hosting_ipv6_error: response
                });
            });
    };
};

/**********************************************************************************************************
 *   CHECK IP TYPE
 **********************************************************************************************************/

export const checkIpType = () => {
    return (dispatch) => {
        dispatch({ type: HOSTING_CHECK_IP_REQUEST });
        Promise.allSettled([API.hosting.GET.ipCheck('4'), API.hosting.GET.ipCheck('6')])
            .then(([ipv4, ipv6]) => {
                dispatch({
                    type: HOSTING_CHECK_IP_SUCCESS,
                    hosting_check_ip_data: {
                        ipv4: ipv4.status === 'fulfilled' ? getDataFromSuccessResponse(ipv4.value) : undefined,
                        ipv6: ipv6.status === 'fulfilled' ? getDataFromSuccessResponse(ipv6.value) : undefined
                    }
                });
            })
            .catch((err) => {
                console.error('err', err);
            });
    };
};

/**********************************************************************************************************
 *   DATABASE ACCESS
 **********************************************************************************************************/

export const listDatabaseAccess = (id, username) => {
    if (username) {
        return (dispatch) => {
            dispatch({
                type: HOSTING_DATABASE_LIST_REQUEST
            });
            API.hosting.subaccount.GET.mysql(id, username)
                .then((response) => {
                    const hosting_database_list_data = getDataFromSuccessResponse(response);
                    const hosting_database_list_meta = getMetaFromSuccessResponse(response);
                    dispatch({
                        type: HOSTING_DATABASE_LIST_SUCCESS,
                        hosting_database_list_data,
                        hosting_database_list_meta
                    });
                })
                .catch((error) => {
                    const response = getErrorFromFailResponse(error);
                    dispatch({
                        type: HOSTING_DATABASE_LIST_FAIL,
                        hosting_database_list_error: response
                    });
                });
        };
    }

    return (dispatch) => {
        dispatch({
            type: HOSTING_DATABASE_LIST_REQUEST
        });
        API.hosting.GET.mysql(id)
            .then((response) => {
                const hosting_database_list_data = getDataFromSuccessResponse(response);
                const hosting_database_list_meta = getMetaFromSuccessResponse(response);
                dispatch({
                    type: HOSTING_DATABASE_LIST_SUCCESS,
                    hosting_database_list_data,
                    hosting_database_list_meta
                });
            })
            .catch((error) => {
                const response = getErrorFromFailResponse(error);
                dispatch({
                    type: HOSTING_DATABASE_LIST_FAIL,
                    hosting_database_list_error: response
                });
            });
    };
};

export const enableDatabaseAccess = (id, attributes, username) => {
    if (username) {
        return (dispatch) => {
            dispatch({
                type: HOSTING_DATABASE_REQUEST
            });
            API.hosting.subaccount.POST.enable
                .mysql(id, attributes, username)
                .then((response) => {
                    const hosting_database_data = getDataFromSuccessResponse(response);
                    pushNotification(getDataFromSuccessResponse(response));
                    dispatch({
                        type: HOSTING_DATABASE_SUCCESS,
                        hosting_database_data
                    });
                    dispatch(listDatabaseAccess(id, username));
                })
                .catch((error) => {
                    const response = getErrorFromFailResponse(error);
                    pushNotification(getErrorFromFailResponse(error));
                    dispatch({
                        type: HOSTING_DATABASE_FAIL,
                        hosting_database_error: response
                    });
                });
        };
    }

    return (dispatch) => {
        dispatch({
            type: HOSTING_DATABASE_REQUEST
        });
        API.hosting.POST.enable
            .mysql(id, attributes)
            .then((response) => {
                const hosting_database_data = getDataFromSuccessResponse(response);
                pushNotification(getDataFromSuccessResponse(response));
                dispatch({
                    type: HOSTING_DATABASE_SUCCESS,
                    hosting_database_data
                });
                dispatch(listDatabaseAccess(id));
            })
            .catch((error) => {
                const response = getErrorFromFailResponse(error);
                pushNotification(getErrorFromFailResponse(error));
                dispatch({
                    type: HOSTING_DATABASE_FAIL,
                    hosting_database_error: response
                });
            });
    };
};

export const disableDatabaseAccess = (id, attributes, username) => {
    if (username) {
        return (dispatch) => {
            dispatch({
                type: HOSTING_DATABASE_REQUEST
            });
            API.hosting.subaccount.POST.disable
                .mysql(id, attributes, username)
                .then((response) => {
                    const hosting_database_data = getDataFromSuccessResponse(response);
                    pushNotification(getDataFromSuccessResponse(response));
                    dispatch({
                        type: HOSTING_DATABASE_SUCCESS,
                        hosting_database_data
                    });
                    dispatch(listDatabaseAccess(id, username));
                })
                .catch((error) => {
                    const response = getErrorFromFailResponse(error);
                    pushNotification(getErrorFromFailResponse(error));
                    dispatch({
                        type: HOSTING_DATABASE_FAIL,
                        hosting_database_error: response
                    });
                });
        };
    }

    return (dispatch) => {
        dispatch({
            type: HOSTING_DATABASE_REQUEST
        });
        API.hosting.POST.disable
            .mysql(id, attributes)
            .then((response) => {
                const hosting_database_data = getDataFromSuccessResponse(response);
                pushNotification(getDataFromSuccessResponse(response));
                dispatch({
                    type: HOSTING_DATABASE_SUCCESS,
                    hosting_database_data
                });
                dispatch(listDatabaseAccess(id));
            })
            .catch((error) => {
                const response = getErrorFromFailResponse(error);
                pushNotification(getErrorFromFailResponse(error));
                dispatch({
                    type: HOSTING_DATABASE_FAIL,
                    hosting_database_error: response
                });
            });
    };
};

/**********************************************************************************************************
 *   SSH ACCESS
 **********************************************************************************************************/

export const listSshAccess = (id, username) => {
    if (username) {
        return (dispatch) => {
            dispatch({
                type: HOSTING_SSHACCESS_LIST_REQUEST
            });
            API.hosting.subaccount.GET.ssh(id, username)
                .then((response) => {
                    const hosting_sshaccess_list_data = getDataFromSuccessResponse(response);
                    const hosting_sshaccess_list_meta = getMetaFromSuccessResponse(response);
                    dispatch({
                        type: HOSTING_SSHACCESS_LIST_SUCCESS,
                        hosting_sshaccess_list_data,
                        hosting_sshaccess_list_meta
                    });
                })
                .catch((error) => {
                    const response = getErrorFromFailResponse(error);
                    dispatch({
                        type: HOSTING_SSHACCESS_LIST_FAIL,
                        hosting_sshaccess_list_error: response
                    });
                });
        };
    }

    return (dispatch) => {
        dispatch({
            type: HOSTING_SSHACCESS_LIST_REQUEST
        });
        API.hosting.GET.ssh(id)
            .then((response) => {
                const hosting_sshaccess_list_data = getDataFromSuccessResponse(response);
                const hosting_sshaccess_list_meta = getMetaFromSuccessResponse(response);
                dispatch({
                    type: HOSTING_SSHACCESS_LIST_SUCCESS,
                    hosting_sshaccess_list_data,
                    hosting_sshaccess_list_meta
                });
            })
            .catch((error) => {
                const response = getErrorFromFailResponse(error);
                dispatch({
                    type: HOSTING_SSHACCESS_LIST_FAIL,
                    hosting_sshaccess_list_error: response
                });
            });
    };
};

export const enableSshAccess = (id, attributes, username) => {
    if (username) {
        return (dispatch) => {
            dispatch({
                type: HOSTING_SSHACCESS_REQUEST
            });
            API.hosting.subaccount.POST.enable
                .ssh(id, attributes, username)
                .then((response) => {
                    const hosting_sshaccess_data = getDataFromSuccessResponse(response);
                    pushNotification(getDataFromSuccessResponse(response));
                    dispatch({
                        type: HOSTING_SSHACCESS_SUCCESS,
                        hosting_sshaccess_data
                    });
                    dispatch(listSshAccess(id, username));
                })
                .catch((error) => {
                    const response = getErrorFromFailResponse(error);
                    pushNotification(getErrorFromFailResponse(error));
                    dispatch({
                        type: HOSTING_SSHACCESS_FAIL,
                        hosting_sshaccess_error: response
                    });
                });
        };
    }

    return (dispatch) => {
        dispatch({
            type: HOSTING_SSHACCESS_REQUEST
        });
        API.hosting.POST.enable
            .ssh(id, attributes)
            .then((response) => {
                const hosting_sshaccess_data = getDataFromSuccessResponse(response);
                pushNotification(getDataFromSuccessResponse(response));
                dispatch({
                    type: HOSTING_SSHACCESS_SUCCESS,
                    hosting_sshaccess_data
                });
                dispatch(listSshAccess(id));
            })
            .catch((error) => {
                const response = getErrorFromFailResponse(error);
                pushNotification(getErrorFromFailResponse(error));
                dispatch({
                    type: HOSTING_SSHACCESS_FAIL,
                    hosting_sshaccess_error: response
                });
            });
    };
};

export const disableSshAccess = (id, attributes, username) => {
    if (username) {
        return (dispatch) => {
            dispatch({
                type: HOSTING_SSHACCESS_REQUEST
            });
            API.hosting.subaccount.POST.disable
                .ssh(id, attributes, username)
                .then((response) => {
                    const hosting_sshaccess_data = getDataFromSuccessResponse(response);
                    pushNotification(getDataFromSuccessResponse(response));
                    dispatch({
                        type: HOSTING_SSHACCESS_SUCCESS,
                        hosting_sshaccess_data
                    });
                    dispatch(listSshAccess(id, username));
                })
                .catch((error) => {
                    const response = getErrorFromFailResponse(error);
                    pushNotification(getErrorFromFailResponse(error));
                    dispatch({
                        type: HOSTING_SSHACCESS_FAIL,
                        hosting_sshaccess_error: response
                    });
                });
        };
    }

    return (dispatch) => {
        dispatch({
            type: HOSTING_SSHACCESS_REQUEST
        });
        API.hosting.POST.disable
            .ssh(id, attributes)
            .then((response) => {
                const hosting_sshaccess_data = getDataFromSuccessResponse(response);
                pushNotification(getDataFromSuccessResponse(response));
                dispatch({
                    type: HOSTING_SSHACCESS_SUCCESS,
                    hosting_sshaccess_data
                });
                dispatch(listSshAccess(id));
            })
            .catch((error) => {
                const response = getErrorFromFailResponse(error);
                pushNotification(getErrorFromFailResponse(error));
                dispatch({
                    type: HOSTING_SSHACCESS_FAIL,
                    hosting_sshaccess_error: response
                });
            });
    };
};

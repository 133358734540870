/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React from 'react';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import RequestLoader from 'components/Loaders/Request';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * Table body
 * @param {{ children: React.ReactNode, isLoading?: boolean }} props
 */
function Body({ children, isLoading = false }) {
    /***** RENDER *****/
    return <div className="NXTableBody">{isLoading ? <RequestLoader /> : children}</div>;
}
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
export default Body;

/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { useNavigate, useParams } from '@tanstack/react-router';
import { useSelector } from 'react-redux';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import { CustomDropdown } from 'components/Dropdowns/CustomDropdown';
import SelectOptions from 'components/Dropdowns/SelectDropdown/Options';
import Title from 'components/Title';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { getTicketSubmitUrl } from 'containers/support/methods';
import { useSelectableSelector } from 'utilities/hooks/redux/useSelectableSelector';
import { getIncludedObjBasedOnType } from 'utilities/methods/commonActions';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const SSLTitle = () => {
    /***** SELECTORS *****/
    const ssl_information_status = useSelector((state: any) => state.ssl.ssl_information_status);
    const { domain, product } = useSelectableSelector((state: any) => state.ssl.ssl_information_data, {
        select: ({ included, attributes = {} }) => ({
            product: getIncludedObjBasedOnType(included, 'product')?.attributes.name,
            domain: attributes.domain
        })
    });

    /***** HOOKS *****/
    const navigate = useNavigate();
    const { sslId } = useParams({ from: '/my-services/ssl/$sslId' });

    /***** RENDER *****/
    return (
        <>
            {ssl_information_status === 'success' && (
                <Title
                    serviceTitle={product}
                    serviceSubTitle={domain}
                    serviceType="ssl"
                    action={[
                        <CustomDropdown
                            className="TitleShared__singleDropdown"
                            label={({ labelRef, dropdownTitleOnClick }) => (
                                <button
                                    className="TitleShared__singleDropdownButton"
                                    ref={labelRef}
                                    type="button"
                                    onClick={() => dropdownTitleOnClick()}
                                >
                                    SSL Certificate Help
                                    <i className="item__icon icon icon-chevron-down" />
                                </button>
                            )}
                            key="dropdown"
                            render={() => (
                                <SelectOptions
                                    options={[
                                        {
                                            label: 'SSL Certificate Help Articles',
                                            onSelect: () => navigate({ to: '/support/support-centre/ssl-certificates' })
                                        },
                                        {
                                            label: 'Submit a Technical Support eTicket for this Service',
                                            onSelect: () => navigate({ to: getTicketSubmitUrl({ department: 'technical-support', service: sslId }) })
                                        },
                                        {
                                            label: 'Submit a Billing eTicket for this Service',
                                            onSelect: () => navigate({ to: getTicketSubmitUrl({ department: 'accounts-billing', service: sslId }) })
                                        }
                                    ]}
                                />
                            )}
                        />
                    ]}
                />
            )}
        </>
    );
};

import { _List } from 'components/Revealer/_List';
import { _Revealer } from 'components/Revealer/_Revealer';
import { _GridHide } from 'components/Revealer/wrappers/GridHide';

export const Revealer = Object.assign(_Revealer, {
    List: _List,
    Wrappers: {
        GridHide: _GridHide
    }
});

import { createRoute } from '@tanstack/react-router';
import { AppRoute } from 'App';
import { MyServicesRoute } from 'containers/services';
import { routerMiddleware } from 'router/utils/middleware';

/** Creates a route on my-services that should be redirected by the route config */
function createLegacyServiceRedirect<TPath extends string>(path: TPath) {
    /** Legacy ${/my-services/TPath} route */
    return createRoute({
        getParentRoute: () => MyServicesRoute,
        path: path,
        beforeLoad(opts: any) {
            routerMiddleware.business(this, opts);
        }
    });
}

/** Creates a route on / that should be redirected by the route config */
function createLegacyAppRedirect<TPath extends string>(path: TPath) {
    /** Legacy ${/TPath} route */
    return createRoute({
        getParentRoute: () => AppRoute,
        path: path,
        beforeLoad(opts: any) {
            routerMiddleware.business(this, opts);
        }
    });
}

export const LegacyEmailHostingRoute = createLegacyServiceRedirect('/email-hosting/$section/$module/$id');
export const LegacyEmailHostingMailboxRoute = createLegacyServiceRedirect('/email-hosting/$section/$module/$id/mailbox/$mailboxId');
export const LegacyWorkspaceRoute = createLegacyServiceRedirect('/google/$section/$module/$id');
export const LegacyMicrosoftRoute = createLegacyServiceRedirect('/microsoft-365/$section/$module/$id');
export const LegacyVPSRoute = createLegacyServiceRedirect('/vps/$section/$module/$id');
export const LegacySSLRoute = createLegacyServiceRedirect('/ssl/$section/$module/$id');
export const LegacyKatanaRoute = createLegacyServiceRedirect('/vip-sites/$section/$module/$id');
export const LegacyAccountRoute = createLegacyAppRedirect('/home/account/$section/$module');


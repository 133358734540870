/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { useEffect, useState } from 'react';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
const NXTransitionDelayedUnmount = ({ children, delay = 300 }) => {
    /***** STATE *****/
    const [activeChild, setActiveChild] = useState(children);

    /***** EFFECTS *****/
    useEffect(() => {
        if (!children) return;

        const timeout = setTimeout(() => {
            setActiveChild(children);
        }, delay);

        return () => clearTimeout(timeout);
    }, [children, delay]);

    /***** HOOK RESULTS *****/
    return activeChild;
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
export { NXTransitionDelayedUnmount };

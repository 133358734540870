/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import classNames from 'classnames';
import React from 'react';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import './_NXTableFooter.scss';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * @type {React.FC<{
 *   children: React.ReactNode,
 *   className?: string
 * }>}
 */
const _NXTableFooter = ({ children, className }) => <div className={classNames('NXTableFooter', className)}>{children}</div>;

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

export default _NXTableFooter;

/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { createRoute } from '@tanstack/react-router';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import PageNotFound from 'components/Errors/PageNotFound';

/**********************************************************************************************************
 *   ROUTE IMPORTS
 **********************************************************************************************************/
import { DomainManageRoute } from 'containers/domain';
import { routerMiddleware } from 'router/utils/middleware';
import { lazyFN } from 'utilities/methods/tanstack/router/lazyFn';
import { z } from 'zod';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
const searchSchema = z.object({
    open: z.boolean().optional()
});

/**********************************************************************************************************
 *   ROUTE START
 **********************************************************************************************************/
export const DomainAdminRoute = createRoute({
    getParentRoute: () => DomainManageRoute,
    path: 'admin',
    notFoundComponent: PageNotFound,
    beforeLoad(opts) {
        routerMiddleware.business(this, opts);
        routerMiddleware.authentication('user', opts);
    },
    validateSearch: searchSchema
}).lazy(lazyFN(() => import('./admin.lazy'), 'LazyDomainAdminRoute'));

import _ from 'lodash';
import type { NXQueryUtils } from 'utilities/methods/tanstack/types';
import type { NXUtils } from 'utilities/NXUtils';

/**
 * Creates an optimistic filter method for modifying query cache data by setting the value of a specific path.
 *
 * the query data at time of setting the new value can be restored using the returned restore method, useful if an error occurs or the filtered data needs to be reverted.
 */
export function createOptimisticSetMethod<TParams extends any, TData extends NXQueryUtils.ApiData>(
    setQueryData: NXQueryUtils.SetQueryDataMethod<TParams, TData>
) {
    function optimisticSet<TKey extends NXUtils.Path<TDataObject>>(
        params: TParams,
        setPath: TKey,
        valueToSetPathWith: NXUtils.Choose<TDataObject, TKey>
    ) {
        let previousData: TDataObject | undefined;
        setQueryData(params, (oldData) => {
            if (oldData?.status !== 200) {
                return;
            }

            const clonedData = _.cloneDeep(oldData);
            if (!_.has(clonedData, setPath)) {
                return;
            }

            previousData = oldData;

            _.set(clonedData, setPath, valueToSetPathWith);
            return clonedData;
        });

        return {
            restore: () => {
                if (!previousData) {
                    return;
                }
                setQueryData(params, () => previousData);
            }
        };
    }
    return optimisticSet;
}

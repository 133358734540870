/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { useParams } from '@tanstack/react-router';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import { IndividualMailboxTitle } from 'containers/email/components/title/group';
import { GroupTitle } from 'containers/email/components/title/mailbox';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const EmailHostingTitle = () => {
    /***** HOOKS *****/
    const { mailboxId } = useParams({ strict: false });

    /***** RENDER *****/
    if (mailboxId) {
        return <IndividualMailboxTitle />;
    }

    return <GroupTitle />;
};

/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React from 'react';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import katanaLogoWide from 'assets/images/katana/vipsites_banner_logo.svg';

import './_katanaLoaderImage.scss';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
const KatanaLoaderImage = () => <img src={katanaLogoWide} alt="VIPsites Logo" className="KatanaLoaderImage" width="auto" height="100" />;

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

export default KatanaLoaderImage;

/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React from 'react';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import { SideBarChildMenuItemWrapper } from 'containers/katana/containers/ContentEditorLightbox/contentEditorSidebar/sideBarChildMenuItem/sideBarChildMenuItemWrapper';
import { SideBarChildMenuItem } from './sideBarChildMenuItem';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * @type {React.FC<{
 *      options: import('containers/katana/containers/ContentEditorLightbox/contentEditorSidebar/types').NSideBarChildMenu.Option[];
 *      isLoading: boolean;
 * }>}
 */
const SideBarChildMenu = ({ options, isLoading }) => (
    <div className="ContentEditorSidebar__childMenu">
        {Boolean(options.length) &&
            options.map(({ title, routeKey, identifier }, i) => {
                return <SideBarChildMenuItemWrapper routeKey={routeKey} title={title} key={i} identifier={identifier} />;
            })}
        {isLoading && (
            <>
                <SideBarChildMenuItem.StyledLoader />
                <SideBarChildMenuItem.StyledLoader />
                <SideBarChildMenuItem.StyledLoader />
                <SideBarChildMenuItem.StyledLoader />
                <SideBarChildMenuItem.StyledLoader />
            </>
        )}
    </div>
);
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

export default SideBarChildMenu;

export const sortByMenuIDs = (/** @type {number[]} */ menuIDs = []) => {
    return (/** @type {{ id: number; }} */ a, /** @type {{ id: number; }} */ b) => {
        const aIndex = menuIDs?.indexOf(a.id);
        const bIndex = menuIDs?.indexOf(b.id);
        if (aIndex === -1) {
            return 1;
        }
        return aIndex < bIndex ? -1 : 1;
    };
};

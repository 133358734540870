/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React, { useContext } from 'react';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import { SortableButton } from '../SortableButton';
import { NXTableContext } from './NXTableContext';

/*   ACTIONS
 *****************************************************/
import { applyChildrenProps } from './methods';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * A wrapper component for the sortable button to easily integrate it into the lifecycle of the table context
 *
 * @param {{ children: React.ReactNode }} props
 */
function SortableButtonWrapper({ children }) {
    /***** HOOKS *****/
    const { _onSort, _currentSort } = useContext(NXTableContext);

    if (!_onSort || !_currentSort) {
        throw new Error('SortableButtonWrapper must be used within a NXTableContext');
    }

    /***** FUNCTIONS *****/
    function onSort(sortData) {
        _onSort(sortData);
    }

    /***** RENDER HELPERS *****/
    const sortableProps = {
        onSort,
        currentSort: _currentSort
    };

    const childrenSortable = applyChildrenProps(children, sortableProps, SortableButton);

    /***** RENDER *****/
    return <>{childrenSortable}</>;
}
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

export default SortableButtonWrapper;

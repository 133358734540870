/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import classNames from 'classnames';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import { PhosphorIcons } from 'components/Icons/Phosphor';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import './_ProgressStepsNode.scss';

export const nodeStates = {
    NOT_STARTED: 'not_started',
    IN_PROGRESS: 'in_progress',
    COMPLETE: 'complete'
};

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type ProgressSteps = {
    state: typeof nodeStates;
};

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * A single step in the "ProgressSteps" component
 */
export const ProgressStepsNode: React.FC<ProgressSteps> = ({ state }) => {
    /***** RENDER *****/
    return (
        <div
            className={classNames('ProgressStepsNode', {
                'ProgressStepsNode--complete': state === nodeStates.COMPLETE,
                'ProgressStepsNode--current': state === nodeStates.IN_PROGRESS
            })}
        >
            {state === nodeStates.COMPLETE ? <PhosphorIcons.CheckFat.Fill white size={16} /> : ''}
        </div>
    );
};

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

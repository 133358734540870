/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { auDirectLandingPageUrl } from 'config/config';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
// import AuApplicationForm from 'containers/auDirect/forms/auApplicationForm';
import AuResubmitDetailsForm from 'containers/auDirect/forms/auResubmitDetailsForm';
import AuRenewApplicationForm from 'containers/auDirect/forms/auRenewApplicationForm';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import OverlayLightbox from 'components/Lightboxes/OverlayLightbox';
import Anchor from 'components/Anchor';
import SolidButton from 'components/Buttons/SolidButton';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
class WithAuApplicationForm extends Component {
    constructor(props) {
        super(props);

        this.state = {
            mode: modes.APPLICATION,
            showApplicationLightbox: false,
            auDirectDomain: null,
            auDirectDomainId: null,
            showInvoiceLightbox: false,
            invoiceId: null
        };

        this.toggleApplicationLightbox = this.toggleApplicationLightbox.bind(this);
        this.toggleInvoiceLightbox = this.toggleInvoiceLightbox.bind(this);
    }

    toggleApplicationLightbox(auDirectDomain = null, auDirectDomainId = null, mode = modes.APPLICATION) {
        this.setState({
            showApplicationLightbox: !this.state.showApplicationLightbox,
            auDirectDomain,
            auDirectDomainId,
            mode
        });
    }

    toggleInvoiceLightbox(invoiceId = null) {
        this.setState({
            showInvoiceLightbox: !this.state.showInvoiceLightbox,
            invoiceId,
            showApplicationLightbox: false
        });
    }

    /************** LIFECYCLE METHODS **************/
    componentDidUpdate(prevProps) {
        const { audirect_apply_status, audirect_apply_data, audirect_update_application_status, domain_form_status } = this.props;
        const { showApplicationLightbox } = this.state;
        const { toggleInvoiceLightbox, toggleApplicationLightbox } = this;

        if (audirect_apply_status === 'success' && prevProps.audirect_apply_status === 'loading') {
            toggleInvoiceLightbox(audirect_apply_data?.invoice_id);
        }

        if (audirect_update_application_status === 'success' && prevProps.audirect_update_application_status === 'loading') {
            toggleApplicationLightbox();
        }

        if (domain_form_status === 'success' && prevProps.domain_form_status === 'loading') {
            if (showApplicationLightbox) toggleApplicationLightbox();
        }
    }

    render() {
        const { children } = this.props;
        const { mode, showApplicationLightbox, auDirectDomain, auDirectDomainId, showInvoiceLightbox, invoiceId } = this.state;
        const { toggleApplicationLightbox, toggleInvoiceLightbox } = this;

        const getDesc = () => {
            if (mode === modes.INVALID_ELIGIBILITY) return '';
            if (mode === modes.RENEW_APPLICATION) return '';
            return (
                <>
                    The priority application period for .au direct names has now ended. You can place a pre-order for your reserved domain name via
                    the{' '}
                    <Anchor href={auDirectLandingPageUrl} target="_blank">
                        VentraIP website
                    </Anchor>
                    . Learn more about VentraIP&apos;s pre-order process{' '}
                    <Anchor href="https://ventraip.com.au/faq/article/au-domain-pre-order-process/" target="_blank">
                        here
                    </Anchor>
                    .
                </>
            );
        };

        const getTitle = () => {
            if (mode === modes.APPLICATION) return 'Contested .au Application';
            if (mode === modes.INVALID_APPLICATION) return 'Re-submit Application Details';
            if (mode === modes.RENEW_APPLICATION) return 'Renew .au Application';
            return 'Re-submit Eligibility Details';
        };

        const getAuLightboxForm = () => {
            if (mode === modes.INVALID_ELIGIBILITY) {
                return <AuResubmitDetailsForm mode={mode} auDirectDomain={auDirectDomain} auDirectDomainId={auDirectDomainId} />;
            }

            if (mode === modes.RENEW_APPLICATION) {
                return (
                    <AuRenewApplicationForm
                        auDirectDomain={auDirectDomain}
                        auDirectDomainId={auDirectDomainId}
                        toggleApplicationLightbox={toggleApplicationLightbox}
                    />
                );
            }

            return (
                <SolidButton className="auApplyForm__goBackButton" type="onClick" onClick={() => toggleApplicationLightbox()}>
                    Go Back
                </SolidButton>
            );
        };

        /*  RENDER COMPONENT
         **********************************************************************************************************/
        return (
            <>
                {children(toggleApplicationLightbox)}

                {showApplicationLightbox ? (
                    <OverlayLightbox
                        onOpen={showApplicationLightbox}
                        onClose={toggleApplicationLightbox}
                        title={getTitle()}
                        // desc={mode === modes.APPLICATION ? `We are currently accepting applications until ${auDirectApplicationClose} for priority access to .au direct domain names. Please follow the prompts below to proceed with your priority application.` : ''}
                        desc={getDesc()}
                        loading="success"
                        goBackLink={() => toggleApplicationLightbox()}
                    >
                        {/* {mode === modes.APPLICATION
                            ? <AuApplicationForm auDirectDomain={auDirectDomain} auDirectDomainId={auDirectDomainId} toggleApplicationLightbox={toggleApplicationLightbox} />
                            : <AuResubmitDetailsForm mode={mode} auDirectDomain={auDirectDomain} auDirectDomainId={auDirectDomainId} />
                        } */}
                        {getAuLightboxForm()}
                    </OverlayLightbox>
                ) : (
                    ''
                )}

                {showInvoiceLightbox && (
                    <OverlayLightbox
                        title={'Pay Invoice #' + invoiceId}
                        invoiceid={invoiceId}
                        onOpen={showInvoiceLightbox}
                        onClose={() => toggleInvoiceLightbox()}
                        onSuccessClose={() => toggleInvoiceLightbox()}
                    />
                )}
            </>
        );
    }
}

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

export default connect((state) => ({
    audirect_apply_status: state.auDirect.audirect_apply_status,
    audirect_apply_data: state.auDirect.audirect_apply_data,
    audirect_update_application_status: state.auDirect.audirect_update_application_status,
    domain_form_status: state.services.domain_form_status
}))(WithAuApplicationForm);

export const modes = {
    APPLICATION: 'APPLICATION',
    INVALID_APPLICATION: 'INVALID_APPLICATION',
    INVALID_ELIGIBILITY: 'INVALID_ELIGIBILITY',
    RENEW_APPLICATION: 'RENEW_APPLICATION'
};

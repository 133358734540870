/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { createContext, useContext } from 'react';
import { useSelector as _useSelector } from 'react-redux';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
/**
 * @typedef {import('./types').TVPSOverview.TDefaultContext} TContext
 * @typedef {import('./types').TVPSOverview.TStore} TStore
 * @typedef {import('react-redux').TypedUseSelectorHook<TStore>} TUseSelector
 */

const defaultContext = /** @type {TContext} */ ({
    isExpressServiceManager: false,
    numberOfRows: null,
    search: {
        isSearching: false,
        keyword: null,
        status: null
    },
    setKeepService: () => {},
    setSearch: () => {},
    setNumberOfRows: () => {},
    initialized: false
});

export const VPSOverviewContext = createContext(defaultContext);
export const useVPSTableSelector = /** @type {TUseSelector} */ (_useSelector);
export const useVPSOverviewContext = () => useContext(VPSOverviewContext);

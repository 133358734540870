/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React from 'react';
import classNames from 'classnames';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import './_HelpBox.scss';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type THelpBox = {
    isOpen: boolean;
    title: string;
    desc: string;
    bullet?: string[];
};

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * The HelpBox component provides a standardized box that should be used to provide help text to the user.
 * This is in the format of a title, description and a list of bullet points, similar to the NXBox but with bullet points.
 */
const HelpBox: React.FC<THelpBox> = ({ isOpen, title, desc, bullets }) => {
    /***** RENDER *****/
    return (
        <div className={classNames('HelpBox', { 'HelpBox--open': isOpen })}>
            <div className="HelpBox__triangle">
                <div className="HelpBox__triangleInner"></div>
            </div>
            {title ? <div className="HelpBox__title">{title}</div> : ''}
            {desc ? <div className="HelpBox__desc">{desc}</div> : ''}
            {bullets ? (
                <ul className="HelpBox__bullets">
                    {bullets.map((bullet, index) => (
                        <li key={index}>{bullet}</li>
                    ))}
                </ul>
            ) : (
                ''
            )}
        </div>
    );
};

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

export default HelpBox;

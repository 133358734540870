/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { billingCycles } from 'config/config';
import { Field } from 'redux-form';

/*   ACTIONS
 *****************************************************/
import { RenderRadioGroup, requiredFieldValidation } from 'utilities/methods/form';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type BillingCycleKey = keyof typeof billingCycles;

type PlanFieldProps = {
    /**
     * Whether or not to disable to "domain" field (should be disabled if it is already prefilled)
     */
    options: {
        planName: string;
        id: string;
        pricing: Partial<Record<BillingCycleKey, { base_price: number; discounted_price?: number }>>;
        desc: string;
    }[];

    /**
     * The currently selected biling cycle, used to show the pricing of the plans
     */
    billingCycle: BillingCycleKey;
};

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const PlanField: React.FC<PlanFieldProps> = ({ options, billingCycle }) => {
    /***** RENDER HELPERS *****/
    const getOptions = () =>
        options.map(({ planName, id, pricing, desc }) => {
            const getDesc = () => {
                if (pricing[billingCycle]?.discounted_price) {
                    return (
                        <>
                            <s>${pricing[billingCycle]?.base_price}</s> ${pricing[billingCycle]?.discounted_price} {billingCycles[billingCycle]} AUD
                        </>
                    );
                }
                return `$${pricing[billingCycle]?.base_price} ${billingCycles[billingCycle]} AUD`;
            };

            return {
                label: (
                    <>
                        <p>{planName}</p>
                        <small>{desc}</small>
                    </>
                ),
                value: id,
                desc: getDesc()
            };
        });

    /***** RENDER *****/
    return (
        <div className="form__row mailboxPlanFields">
            <div className="form__column full">
                <Field
                    label="Email Hosting Plan"
                    name="product_id"
                    outlined={true}
                    component={RenderRadioGroup}
                    validate={requiredFieldValidation}
                    options={getOptions()}
                />
            </div>
        </div>
    );
};

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

export default PlanField;

/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { useHistory } from 'utilities/hooks/router/useHistory';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import Border from 'components/Utils/Border';
import Text from 'components/Utils/Text';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import './_showMore.scss';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * Show more footer to show when a table is present on the express service manager and has more than 5 total rows.
 *
 * @param {{
 *   isExpressServiceManager: boolean;
 *   isLoading: boolean;
 *   totalRows: number;
 *   link: `/my-services/${string}`;
 *   title: string;
 * }} props
 */
export const ShowMore = ({ isExpressServiceManager, isLoading, totalRows, link, title }) => {
    /***** HOOKS *****/
    const history = useHistory();

    /***** RENDER *****/
    if (!isExpressServiceManager) return null;
    if (isLoading) return null;
    if (totalRows <= 5) return null;

    return (
        <Border top>
            <button onClick={() => history.push(link)} className="expressServiceManager__showMore">
                <Text primary align--center uppercase size--s medium>
                    {title}
                </Text>
            </button>
        </Border>
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

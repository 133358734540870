/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/

import { iconWrap } from 'components/Icons/methods';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/

/** @deprecated Use `PhosphorIcons` instead */
const Icons = Object.freeze({
    /** @deprecated Use `PhosphorIcons` instead */
    AmexOutline: iconWrap('amex-outline'),
    /** @deprecated Use `PhosphorIcons` instead */
    Arrow: iconWrap('arrow'),
    /** @deprecated Use `PhosphorIcons` instead */
    Close: iconWrap('blog-close'),
    /** @deprecated Use `PhosphorIcons` instead */
    Calendar: iconWrap('caldav'),
    /** @deprecated Use `PhosphorIcons` instead */
    Check: iconWrap('check'),
    /** @deprecated Use `PhosphorIcons` instead */
    CheckCircle: iconWrap('check-circle'),
    /** @deprecated Use `PhosphorIcons` instead */
    ChevronDown: iconWrap('chevron-down'),
    /** @deprecated Use `PhosphorIcons` instead */
    ChevronLeft: iconWrap('chevron-left'),
    /** @deprecated Use `PhosphorIcons` instead */
    ChevronRight: iconWrap('chevron-right'),
    /** @deprecated Use `PhosphorIcons` instead */
    ChevronUp: iconWrap('chevron-up'),
    /** @deprecated Use `PhosphorIcons` instead */
    Clock: iconWrap('clock'),
    /** @deprecated Use `PhosphorIcons` instead */
    Coin: iconWrap('sw-use-credit'),
    /** @deprecated Use `PhosphorIcons` instead */
    CPU: iconWrap('cpu'),
    /** @deprecated Use `PhosphorIcons` instead */
    CreditCard: iconWrap('credit-card'),
    /** @deprecated Use `PhosphorIcons` instead */
    Lock: iconWrap('lock'),
    /** @deprecated Use `PhosphorIcons` instead */
    MastercardOutline: iconWrap('mastercard-outline'),
    /** @deprecated Use `PhosphorIcons` instead */
    Memory: iconWrap('memory'),
    /** @deprecated Use `PhosphorIcons` instead */
    Mobile: iconWrap('mobile'),
    /** @deprecated Use `PhosphorIcons` instead */
    PlusFaq: iconWrap('plus-faq'),
    /** @deprecated Use `PhosphorIcons` instead */
    SSDStorage: iconWrap('ssd-storage'),
    /** @deprecated Use `PhosphorIcons` instead */
    TickSolid: iconWrap('tick-solid'),
    /** @deprecated Use `PhosphorIcons` instead */
    Valid: iconWrap('valid'),
    /** @deprecated Use `PhosphorIcons` instead */
    VisaOutline: iconWrap('visa-outline'),
    /** @deprecated Use `PhosphorIcons` instead */
    WordpressHosting: iconWrap('icon-wordpress')
});

export default Icons;

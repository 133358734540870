/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import classNames from 'classnames';
import React from 'react';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { useRichTextEditorContext } from 'components/Form/RichTextEditor/hooks/useRichTextEditorContext';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import './_RichTextEditor.scss';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type RichTextEditorContentProps = {
    className?: string;
    style?: React.CSSProperties;
    children: React.ReactNode;
};

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const RichTextEditorContent: React.FC<RichTextEditorContentProps> = ({ className, style, children }) => {
    /***** HOOKS *****/
    const [rootElement, setRootElement] = useRichTextEditorContext('rootElement');

    /***** FUNCTIONS *****/
    const handleRef = (node: HTMLDivElement | null) => {
        const editorElement = node?.querySelector('.ProseMirror');
        if (editorElement && editorElement !== rootElement) {
            setRootElement(editorElement);
        }
    };

    /***** RENDER *****/
    return (
        <div className={classNames('RichTextEditor', className)} style={style} ref={handleRef}>
            {children}
        </div>
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { createRoute } from '@tanstack/react-router';

/**********************************************************************************************************
 *   ROUTE IMPORTS
 **********************************************************************************************************/
import { EmailManageRoute } from 'containers/email';
import { routerMiddleware } from 'router/utils/middleware';
import { lazyFN } from 'utilities/methods/tanstack/router/lazyFn';

/*   ACTIONS
 *****************************************************/
import { getEmailHostingDomainGroupDetails, getMailboxList } from 'containers/email/action';

/**********************************************************************************************************
 *   ROUTE START
 **********************************************************************************************************/
export const EmailHostingGeneralRoute = createRoute({
    getParentRoute: () => EmailManageRoute,
    path: 'general',
    loader({ params, context, cause }) {
        const mailboxId = (params as any).mailboxId;

        if (cause === 'enter') {
            /***** MAILBOX DETAILS *****/
            getEmailHostingDomainGroupDetails(mailboxId ?? params.groupId)(context.store.dispatch);
            getMailboxList(params.groupId)(context.store.dispatch);
        }
    },
    beforeLoad(opts) {
        routerMiddleware.business(this, opts);
        routerMiddleware.authentication('user', opts);
    }
}).lazy(lazyFN(() => import('./general.lazy'), 'LazyEmailHostingGeneralRouteComponent'));

/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React from 'react';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * @description The DisabledEvent wrapper provides a wrapper to catch bubbled click and key events
 * on a disabled element (typically an element that has a button or link role).
 *
 * This functionality is especially useful in the case where you want to trigger an event
 * when the child is disabled while having the child handle the positive event case when it is active.
 *
 * Use caution with this, with the way html propagates events, if this wraps a disabled button,
 * the event will never propagate to this wrapper.
 *
 * @link {https://github.com/jsx-eslint/eslint-plugin-jsx-a11y/blob/main/docs/rules/no-static-element-interactions.md#:~:text=Case%3A%20The%20event%20handler%20is%20only%20being%20used%20to%20capture%20bubbled%20events}
 *
 * @param {{
 *   onDisabledClick: (e: React.MouseEvent<HTMLDivElement, MouseEvent> | React.KeyboardEvent<HTMLDivElement>) => void;
 *   active: boolean;
 *   children: React.ReactNode;
 * }} props
 */
export const DisabledEvent = ({ onDisabledClick, active = true, children }) => {
    /***** FUNCTIONS *****/
    const handleInteraction = (e) => {
        e?.preventDefault();
        onDisabledClick(e);
    };

    /***** RENDER *****/
    if (!active) {
        return children;
    }

    return (
        // eslint-disable-next-line jsx-a11y/no-static-element-interactions
        <div className="InactiveButton__clickableDiv" onClick={handleInteraction} onKeyDown={handleInteraction}>
            {children}
        </div>
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React from 'react';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import Text from 'components/Utils/Text';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * @param {React.ReactNode} label
 */
const fieldLabelWithOptional = (label) => (
    <Text semiBold>
        {label}{' '}
        <Text medium italic span>
            (optional)
        </Text>
    </Text>
);
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

export { fieldLabelWithOptional };

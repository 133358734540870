/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React from 'react';
import { useBoolean } from 'usehooks-ts';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import Anchor from 'components/Anchor';
import Grid from 'components/Grid';
import { PhosphorIcons } from 'components/Icons/Phosphor';
import { Revealer } from 'components/Revealer';
import { Flex } from 'components/Utils/Flex';
import Padding from 'components/Utils/Padding';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type ParentRouteProps = {
    parent: string;
    links: JSX.Element[];
};
/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
const ParentRoute: React.FC<ParentRouteProps> = ({ parent, links }) => {
    /***** STATE *****/
    const { value: isOpen, toggle: toggleOpen } = useBoolean(false);

    /***** RENDER *****/
    return (
        <Flex direction="column" items="start" gap={1}>
            <Anchor color="confirm" onClick={toggleOpen}>
                <Grid columns="1fr auto" fullWidth>
                    {parent} <PhosphorIcons.Chevron size={14} state={isOpen ? 'up' : 'down'} />
                </Grid>
            </Anchor>
            <Revealer isOpen={isOpen} onClick={toggleOpen}>
                <Padding left={4}>
                    <Flex direction="column" items="start" gap={1}>
                        {React.Children.toArray(links)}
                    </Flex>
                </Padding>
            </Revealer>
        </Flex>
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
export default ParentRoute;

import axios from 'axios';

export const API = {
    /**********************************************************************************************************
     *   TOKEN API
     **********************************************************************************************************/

    token: {
        check: () => {
            return axios.get('/api/check-token');
        }
    },

    /**********************************************************************************************************
     *   LOGIN API
     **********************************************************************************************************/

    login: {
        submit: (attributes) => {
            return axios.post('/api/login', {
                type: 'login',
                attributes
            });
        },

        forgot: {
            password: (attributes) => {
                return axios.post('/api/forgot/password', {
                    type: 'forgot',
                    attributes
                });
            },

            email: (attributes) => {
                return axios.post('/api/forgot/email', {
                    type: 'forgot',
                    attributes
                });
            }
        },

        twoFactor: {
            requestDefault: () => {
                return axios.get('/api/two-factor/create');
            },

            requestAlternate: (value) => {
                return axios.get('/api/two-factor/create?method=' + value);
            },

            listMethods: () => {
                return axios.get('/api/user/two-factor');
            },

            submit: (value) => {
                return axios.post('/api/two-factor/', {
                    type: 'two-factor-authentication',
                    attributes: {
                        two_factor_token: value
                    }
                });
            }
        },

        recovery: {
            submit: (attributes) => {
                return axios.post('/api/user/recovery', {
                    type: 'recovery',
                    attributes
                });
            }
        }
    },

    /**********************************************************************************************************
     *   USER API
     **********************************************************************************************************/

    user: {
        get: () => {
            return axios.get('/api/user');
        },

        updateEmail: (email) => {
            return axios.put('/api/user/email', {
                type: 'user',
                attributes: {
                    email
                }
            });
        },

        account: {
            list: () => {
                return axios.get(`/api/user/account-list`);
            },

            set: (id) => {
                return axios.post(`/api/user/select-account/${id}`);
            }
        }
    }
};

/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React from 'react';
import { useDispatch } from 'react-redux';
import { touch } from 'redux-form';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import { FormItem } from 'components/Form/FormItem';
import { FormItemInner } from 'components/Form/FormItemInner';
import { FormLabel } from 'components/Form/FormLabel';
import { IsDataUpdatingOverlay } from 'components/IsDataUpdatingOverlay';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import { _NXDropZone as NXDropZone } from 'components/Form/NXDropZone/_NXDropZone';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { useKatanaFileUpload } from 'components/Form/NXDropZone/wrappers/withKatanaFileUpload/useKatanaFileUpload';

/**********************************************************************************************************
 *   TYPE IMPORTS
 **********************************************************************************************************/
import type { NXDropZoneNamespace } from 'components/Form/NXDropZone/types';
import type { UseKatanaFileUpload } from 'components/Form/NXDropZone/wrappers/withKatanaFileUpload/types';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const _NXDropZoneReduxFormWithKatanaFileUpload: React.FC<NXDropZoneNamespace.ReduxForm.WithKatanaFileUpload.Props> = ({
    serviceID,
    className,
    ...baseProps
}) => {
    const { input, meta, disabled, label } = baseProps;
    const { form } = meta;
    const { name, onChange } = input;

    /***** HOOKS *****/
    const dispatch = useDispatch();

    function handleOnChange(files: UseKatanaFileUpload.Values) {
        dispatch(touch(form, name));
        onChange(files);
    }

    /***** QUERIES *****/
    const { isUploading, onChangeTransform } = useKatanaFileUpload(serviceID, handleOnChange);

    /***** RENDER *****/
    return (
        <FormItem disabled={disabled} className={className}>
            <FormLabel htmlFor={name || ''}>{label}</FormLabel>
            <FormItemInner meta={meta} noBackground fullWidth>
                <IsDataUpdatingOverlay.StableDomStructure noBorder isDataUpdating={isUploading} message="Uploading File...">
                    <NXDropZone {...baseProps} onChange={onChangeTransform} />
                </IsDataUpdatingOverlay.StableDomStructure>
            </FormItemInner>
        </FormItem>
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

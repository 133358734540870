import { has } from 'lodash';

/**
 * @param {{
 *      sectionDefinitionData: KatanaAPI.Katana.Site.ServiceID.Meta.Sections.GET.SectionData;
 *      path?: Array<string | number>;
 * }} params
 * */
export function findSectionDefinitionPropertyByPath({ sectionDefinitionData, path }) {
    if (!sectionDefinitionData || !path) {
        return null;
    }

    /**
     *
     * @param {*} array
     * @param {Array<string | number>} keyPath
     * @returns
     */
    function findValueByKeyPath(array, keyPath) {
        const key = keyPath.shift();
        for (const obj of array) {
            if (!keyPath.length && obj.key === key) {
                return obj;
            }

            if (has(obj, 'properties')) {
                const value = findValueByKeyPath(obj.properties, [...keyPath]);
                if (value !== undefined) {
                    return value;
                }
            }
        }
    }

    return findValueByKeyPath(sectionDefinitionData.properties, path);
}

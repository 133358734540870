// https://icon-sets.iconify.design/ph/

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import Chevron from 'components/Icons/Chevron';
import { Iconify } from 'components/Icons/Iconify';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { preloadIcons } from 'components/Icons/methods';

const Arrow = /** @type {const} */ ({
    Left: Object.assign(Iconify('ph:arrow-left'), {
        Bold: Iconify('ph:arrow-left-bold')
    }),
    Right: {
        Bold: Iconify('ph:arrow-right-bold')
    }
});

const Caret = /** @type {const} */ ({
    Up: Object.assign(Iconify('ph:caret-up'), {
        Bold: Iconify('ph:caret-up-bold')
    }),
    Down: Object.assign(Iconify('ph:caret-down'), {
        Bold: Iconify('ph:caret-down-bold')
    }),
    Left: Object.assign(Iconify('ph:caret-left'), {
        Bold: Iconify('ph:caret-left-bold'),
        Fill: Iconify('ph:caret-left-fill')
    }),
    Right: Object.assign(Iconify('ph:caret-right'), {
        Bold: Iconify('ph:caret-right-bold'),
        Fill: Iconify('ph:caret-right-fill')
    }),
    UpDown: Object.assign(Iconify('ph:caret-up-down'), {
        Fill: Iconify('ph:caret-up-down-fill')
    })
});

const PhosphorIcons = Object.freeze({
    Amex: Iconify('formkit:amex'),
    Arrow,
    ArrowElbowDownRight: {
        Bold: Iconify('ph:arrow-elbow-down-right-bold')
    },
    ArrowArcLeft: {
        Bold: Iconify('ph:arrow-arc-left-bold')
    },
    ArrowArcRight: {
        Bold: Iconify('ph:arrow-arc-right-bold')
    },
    ArrowLineLeft: Iconify('ph:arrow-line-left'),
    ArrowLineRight: Iconify('ph:arrow-line-right'),
    ArrowsCounterClockwise: Object.assign(Iconify('ph:arrows-counter-clockwise'), {
        Bold: Iconify('ph:arrows-counter-clockwise-bold')
    }),

    ArrowSquareOut: Iconify('ph:arrow-square-out'),
    Backspace: Iconify('ph:backspace'),
    Caret,
    ChatCircleDots: Iconify('ph:chat-circle-dots'),
    Check: Object.assign(Iconify('ph:check'), {
        Bold: Iconify('ph:check-bold')
    }),
    CheckCircle: Object.assign(Iconify('ph:check-circle'), {
        Fill: Iconify('ph:check-circle-fill'),
        Bold: Iconify('ph:check-circle-bold')
    }),
    CheckFat: {
        Fill: Iconify('ph:check-fat-fill')
    },
    Chevron,
    ClipboardText: Object.assign(Iconify('ph:clipboard-text'), {
        Bold: Iconify('ph:clipboard-text-bold')
    }),
    Copy: Iconify('ph:copy'),
    CornersIn: Object.assign(Iconify('ph:corners-in'), {
        Fill: Iconify('ph:corners-in-fill')
    }),
    CornersOut: Object.assign(Iconify('ph:corners-out'), {
        Fill: Iconify('ph:corners-out-fill')
    }),
    CreditCard: Iconify('ph:credit-card'),
    Desktop: {
        DuoTone: Iconify('ph:desktop-duotone')
    },
    DeviceMobile: {
        DuoTone: Iconify('ph:device-mobile-duotone')
    },
    DeviceTablet: {
        DuoTone: Iconify('ph:device-tablet-duotone')
    },
    DotsSixVertical: {
        Bold: Iconify('ph:dots-six-vertical-bold')
    },
    DotsThree: {
        Bold: Iconify('ph:dots-three-bold')
    },
    Eye: Iconify('ph:eye'),
    FastForward: Iconify('ph:fast-forward'),
    Gift: Object.assign(Iconify('ph:gift'), {
        Fill: Iconify('ph:gift-fill')
    }),
    Image: Object.assign(Iconify('ph:image'), {
        Fill: Iconify('ph:image-fill')
    }),
    Info: Object.assign(Iconify('ph:info'), {
        Bold: Iconify('ph:info-bold')
    }),
    Layout: Object.assign(Iconify('ph:layout'), {
        Bold: Iconify('ph:layout-bold')
    }),
    Link: {
        Bold: Iconify('ph:link-bold')
    },
    LinkBreak: {
        Bold: Iconify('ph:link-break-bold')
    },
    ListBullets: {
        Bold: Iconify('ph:list-bullets-bold')
    },
    ListNumbers: {
        Bold: Iconify('ph:list-numbers-bold')
    },
    LockKey: {
        Fill: Iconify('ph:lock-key-fill')
    },
    LockSimple: Iconify('ph:lock-simple'),
    LockSimpleOpen: Iconify('ph:lock-simple-open'),
    LucideCheckCircle: Iconify('lucide:check-circle'),
    MaskHappy: Iconify('ph:mask-happy'),
    MaskSad: Iconify('ph:mask-sad'),
    MasterCard: Iconify('formkit:mastercard'),
    Megaphone: Iconify('ph:megaphone'),
    MoonStars: Iconify('ph:moon-stars'),
    Minus: {
        Bold: Iconify('ph:minus-bold')
    },
    NavigationArrow: Object.assign(Iconify('ph:navigation-arrow'), {
        Bold: Iconify('ph:navigation-arrow-bold')
    }),
    NotePencil: Iconify('ph:note-pencil'),
    PaintBucket: Object.assign(Iconify('ph:paint-bucket'), {
        Fill: Iconify('ph:paint-bucket-fill')
    }),
    Palette: Object.assign(Iconify('ph:palette'), {
        Bold: Iconify('ph:palette-bold')
    }),
    PhoneVibrate: Iconify('bi:phone-vibrate'),
    PencilSimple: Object.assign(Iconify('ph:pencil-simple'), {
        Fill: Iconify('ph:pencil-simple-fill')
    }),
    Plus: Object.assign(Iconify('ph:plus'), {
        Bold: Iconify('ph:plus-bold')
    }),
    Question: Object.assign(Iconify('ph:question'), {
        Bold: Iconify('ph:question-bold')
    }),
    RocketLaunch: Object.assign(Iconify('ph:rocket-launch'), {
        Bold: Iconify('ph:rocket-launch-bold')
    }),
    ShieldCheckered: {
        Fill: Iconify('ph:shield-checkered-fill')
    },
    Sun: Iconify('ph:sun'),
    Ticket: Iconify('ph:ticket'),
    TerminalWindow: Iconify('ph:terminal-window'),
    Trash: Object.assign(Iconify('ph:trash'), {
        Bold: Iconify('ph:trash-bold'),
        Fill: Iconify('ph:trash-fill')
    }),
    TextAa: Iconify('ph:text-aa'),
    TextBold: {
        Bold: Iconify('ph:text-b-bold')
    },
    TextItalic: {
        Bold: Iconify('ph:text-italic-bold')
    },
    TextStrikeThrough: {
        Bold: Iconify('ph:text-strikethrough-bold')
    },
    TextUnderline: {
        Bold: Iconify('ph:text-underline-bold')
    },
    UploadSimple: Iconify('ph:upload-simple'),
    Visa: Iconify('formkit:visa'),
    Video: {
        Light: Iconify('ph:video-light')
    },
    WarningCircle: Object.assign(Iconify('ph:warning-circle'), {
        Bold: Iconify('ph:warning-circle-bold')
    }),
    WarningDiamond: Iconify('ph:warning-diamond'),
    X: Object.assign(Iconify('ph:x'), {
        Bold: Iconify('ph:x-bold')
    })
});

preloadIcons();

export { PhosphorIcons };

/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { useEffect, useState } from 'react';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { handleImageLoading } from './methods';

/**********************************************************************************************************
 *   HOOK START
 **********************************************************************************************************/
/**
 * @param {File} file
 */
export function useHandleFileLoading(file) {
    /***** STATE *****/
    const [loadedData, setLoadedData] = useState('');
    const [isError, setIsError] = useState(null);
    const [isLoading, setIsLoading] = useState(true);

    /***** EFFECTS *****/
    useEffect(() => {
        if (!(file instanceof File)) {
            return setIsError('File provided is not an instance of File');
        }

        switch (file.type) {
            case 'image/png':
            case 'image/jpg':
            case 'image/jpeg':
            case 'image/gif':
                setIsLoading(true);
                handleImageLoading(file, setLoadedData);
                break;

            default:
                setLoadedData(`Can't render ${file.type}`);
        }
    }, [file]);

    useEffect(() => {
        setIsLoading(false);
    }, [loadedData]);

    useEffect(() => {
        if (isLoading) {
            setIsError(null);
        }
    }, [isLoading]);

    /***** HOOK RESULTS *****/
    return { isError, isLoading, loadedData };
}
/**********************************************************************************************************
 *   HOOK END
 **********************************************************************************************************/

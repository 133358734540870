/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { createRoute } from '@tanstack/react-router';

/**********************************************************************************************************
 *   ROUTE IMPORTS
**********************************************************************************************************/
import { EmailHostingMailboxRoute } from 'containers/email/routes/email-hosting.$mailboxId/mailbox.$mailboxId';
import { routerMiddleware } from 'router/utils/middleware';
import { lazyFN } from 'utilities/methods/tanstack/router/lazyFn';

/*   ACTIONS
 *****************************************************/
import { getEmailBillingCycles, getEmailHostingDomainGroupDetails, getEmailProducts } from 'containers/email/action';

/**********************************************************************************************************
 *   ROUTE START
 **********************************************************************************************************/
export const EmailHostingGeneralMailboxRoute = createRoute({
    getParentRoute: () => EmailHostingMailboxRoute,
    path: 'general',
    loader({ params, context, cause }) {
        const { email_domain_group_details_status, email_information_mailbox_data } = context.store.getState().email;

        if (cause === 'enter') {
            /***** MAILBOX DETAILS *****/
            if (email_domain_group_details_status !== 'loading') {
                // may have been triggered by parent route
                getEmailHostingDomainGroupDetails(params.mailboxId ?? params.groupId)(context.store.dispatch);
            }

            /***** DISK BLOCKS *****/
            const name = email_information_mailbox_data?.included?.find(({ type }: any) => type === 'product')?.attributes?.name;

            /***** EMAIL UPDATE *****/
            if (name !== 'AXIGEN-PLUS') {
                // handles data prefetch for upgrade component
                getEmailProducts(Number(params.mailboxId))(context.store.dispatch);
            }

            /***** EMAIL USAGE *****/
            if (email_information_mailbox_data?.id) {
                getEmailBillingCycles(Number(params.mailboxId))(context.store.dispatch);
            }
        }
    },
    beforeLoad(opts) {
        routerMiddleware.business(this, opts);
        routerMiddleware.authentication('user', opts);
    }
}).lazy(lazyFN(() => import('./general.lazy'), 'LazyEmailHostingGeneralMailboxRoute'));

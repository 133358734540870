import type { ParsedLocation } from '@tanstack/react-router';
import { useLocation, useMatch, useNavigate, useRouter, useSearch } from '@tanstack/react-router';
import { createElement, type ComponentType } from 'react';
import { useHistory } from 'utilities/hooks/router/useHistory';

type History = {
    push: (to: string, state?: any) => void;
};

type InjectedProps = {
    history: History;
    location: ParsedLocation<{}>;
    match: ReturnType<typeof useMatch>;
};

/**
 * @deprecated - Legacy method to provide features from React Router V5 to classComponents, use hooks.
 */
export const withRouter = <P,>(component: ComponentType<P & InjectedProps>) => {
    const InternalComponent = (props: Omit<P, keyof InjectedProps>) => {
        const location = useLocation();
        const history = useHistory();
        const router = useRouter();
        const _search = useSearch({ strict: false });
        const navigate = useNavigate({ strict: false });

        // @ts-ignore
        const match = useMatch({ strict: false });

        const search = Object.entries(location.search).reduce((acc, [key, value]) => {
            return `${acc}${key}=${value}&`;
        }, '?');

        return createElement(
            component,
            //@ts-ignore
            {
                ...props,
                search: _search,
                history,
                location: { ...location, search },
                navigate,
                match,
                _router: router
            },
            (props as any).children
        );
    };

    return InternalComponent;
};

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { isAttachmentDataEmbed, isAttachmentDataLink } from 'containers/katana/queries/methods/attachmentData';
import { isKatanaFileAttachment } from 'components/Form/NXDropZone/wrappers/withKatanaFileUpload/isKatanaFileAttachment';

export function getMediaFeatureType(value: any) {
    if (isKatanaFileAttachment(value)) {
        return 'upload_image';
    } else if (isAttachmentDataLink(value)) {
        return 'stock_image';
    } else if (isAttachmentDataEmbed(value)) {
        return 'embed_video';
    }
}

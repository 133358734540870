/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React from 'react';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import Anchor from 'components/Anchor';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
const InvoiceLoadingError = () => {
    /***** RENDER *****/
    return (
        <div className="OverlayInvoice">
            <div className="OverlayInvoice__error">
                We&apos;re unable to retrieve the requested invoice details. Please try again later. If you believe there may be an issue and require
                assistance, feel free to submit a{' '}
                <Anchor className="support-link" to="/support/tickets/submit">
                    support ticket
                </Anchor>
            </div>
        </div>
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

export default InvoiceLoadingError;

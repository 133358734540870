/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import classNames from 'classnames';
import React from 'react';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import Text from 'components/Utils/Text';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import './_FormLabel.scss';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
import type { NFormLabel } from 'components/Form/FormLabel/types';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/

export const FormLabel: React.FC<NFormLabel.Props> = ({ children, htmlFor, noMargin }) => {
    /***** RENDER *****/
    if (children) {
        return (
            <div
                className={classNames('FormLabel form__title', {
                    'FormLabel--noMargin': noMargin
                })}
            >
                <Text secondary size--xss lead--1={noMargin}>
                    <label htmlFor={htmlFor}>{children}</label>
                </Text>
            </div>
        );
    }
};

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

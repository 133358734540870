/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React from 'react';
import { useController } from 'react-hook-form';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import { FormItem } from 'components/Form/FormItem';
import { FormItemInner } from 'components/Form/FormItemInner';
import { FormLabel } from 'components/Form/FormLabel';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import { _Select as Select } from 'components/Form/Select/_Select';

/**********************************************************************************************************
 *   TYPE IMPORTS
 **********************************************************************************************************/
import type { SelectNamespace } from 'components/Form/Select/types';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
const _SelectHookFormDefault: React.FC<SelectNamespace.HookForm.Props> = ({ label, name, options, size, onSelect }) => {
    /***** HOOKS *****/
    const { field } = useController({ name });

    /***** FUNCTIONS *****/
    function handleOnChange(value?: string | null) {
        field.onChange(value);
        onSelect?.(value);
    }

    /***** RENDER *****/
    return (
        <FormItem size={size}>
            <FormLabel htmlFor={name}>{label}</FormLabel>
            <FormItemInner.HookForm name={name}>
                <Select value={field.value} onChange={handleOnChange} options={options} />
            </FormItemInner.HookForm>
        </FormItem>
    );
};

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
export { _SelectHookFormDefault };

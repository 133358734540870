/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React from 'react';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import Anchor from 'components/Anchor';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { getSidebarState, scrollToRef } from 'utilities/methods/commonActions';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
/**
 *  @typedef {{
 *    searchParamKey: string
 *    searchParamValue: string | boolean
 * }} TOpenDropdownItem
 */

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * When clicked, will scroll the page to the component who's path matches "pathname" prop, and navigate to that pathname
 *
 * @param {{
 *      children: React.ReactNode
 *      pathname: string
 *      openDropdown?: TOpenDropdownItem | Array<TOpenDropdownItem>
 *      hash: string
 * }} props
 */
export const _ScrollableComponentAnchor = ({ children, pathname, hash, openDropdown = { searchParamKey: 'open', searchParamValue: true } }) => {
    /***** FUNCTIONS *****/
    const getParams = () => {
        if (Array.isArray(openDropdown)) {
            return openDropdown.map((item, i) => `${i !== 0 ? '&' : ''}${item.searchParamKey}=${item.searchParamValue}`).join('');
        }

        return `${openDropdown.searchParamKey}=${openDropdown.searchParamValue}`;
    };

    /***** RENDER HELPERS *****/
    const url = `${pathname}${openDropdown ? `?${getParams()}` : ''}${hash ? `#${hash}` : ''}`;
    const urlWithoutQueryParams = `${pathname}${hash ? `#${hash}` : ''}`;

    /***** RENDER *****/
    return (
        <Anchor to={url} onClick={() => scrollToRef(getSidebarState()[urlWithoutQueryParams])}>
            {children}
        </Anchor>
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React, { cloneElement, createContext } from 'react';

/** @type {SectionModuleContext} */
export const SectionModuleContext = createContext({
    path: null
});

export const useSectionModuleContext = () => React.useContext(SectionModuleContext);

/**
 * Takes an array of modules and adds "key" and "path" props to each one, based on their sidebar link address
 * @param {Readonly<import('components/DevelopmentModuleFilter/types').NDevelopmentModuleFilter.Module[]>} modules
 */
export const renderSectionModules = (modules) => {
    return (
        modules
            ?.filter?.(({ condition }) => condition)
            ?.map?.(({ component, sidebarLink, identifier }, index) => {
                const moduleContext = {
                    path: sidebarLink?.link ?? null
                };

                return (
                    <SectionModuleContext.Provider key={identifier} value={moduleContext}>
                        {cloneElement(component, { key: index, path: sidebarLink?.link ?? null })}
                    </SectionModuleContext.Provider>
                );
            }) ?? []
    );
};

/**
 * Generates a section of the sidebar based on the section name
 * @param {import('components/DevelopmentModuleFilter/types').NDevelopmentModuleFilter.ReadOnlyModules} modules - array of all modules
 * @param {string} sectionName - the name of the section that you want to generate a sidebar section for
 */
export const handleSideMenuOptions = (modules, sectionName) =>
    modules[sectionName]
        .filter(({ sidebarLink }) => sidebarLink)
        .filter(({ condition, sidebarLink }) => condition && sidebarLink.label)
        .map(({ sidebarLink }) => (Array.isArray(sidebarLink?.link) ? { ...sidebarLink, link: sidebarLink.link[0] } : sidebarLink));

/**
 * Decides if the current url is valid based on the list of available urls on the page\
 * @param {import("components/SideBarPage/utilities").UseGetSidebarLinks.Links} links - array of all links
 * @param {string} currentUrl - the current url
 */
export const isURLValid = (links, currentUrl) =>
    Object.values(links).some((section) =>
        Object.values(section).some((link) => {
            if (typeof link === 'string') {
                return link === currentUrl.trim();
            }

            return link.some(({ link }) => link.includes(currentUrl.trim()));
        })
    );

/**********************************************************************************************************
 * DEVELOPMENT SPECIFIC FUNCTIONS
 **********************************************************************************************************/

/**
 * provides an object who's keys correlate with specific module names and values represent whether they should be displayed.
 *
 * This can be used to filter out modules that are hidden using the Developer sidebar tool
 *
 * @param {import('components/DevelopmentModuleFilter/types').NDevelopmentModuleFilter.LocalStorageIdentifier} localStorageIdentifier
 * @returns {Record<string, boolean>}
 */
export const getSidebarFromLocalStorage = (localStorageIdentifier) => {
    try {
        const localStorageString = localStorage.getItem(localStorageIdentifier);

        //get current local storage
        const local = localStorageString ? JSON.parse(localStorageString) : {};

        return local;
    } catch (error) {
        console.error(error);
        return {};
    }
};

/**
 * Provides a function that can be used to update the local storage for the developer sidebar tool
 *
 * @param {{
 *      localStorageIdentifier: import('components/DevelopmentModuleFilter/types').NDevelopmentModuleFilter.LocalStorageIdentifier;
 *      label: string;
 *      checked: boolean;
 *      callback: () => void;
 * }} props
 */
export const updateSidebarLocalStorage = ({ localStorageIdentifier, label, checked, callback }) => {
    try {
        const local = getSidebarFromLocalStorage(localStorageIdentifier);

        //update
        local[label] = checked;

        //set new local storage
        localStorage.setItem(localStorageIdentifier, JSON.stringify(local));

        callback();
    } catch (error) {
        console.error(error);
    }
};

/**
 * Standard function for creating modules object. The original modules object is preserved and returned as well as a duplicate that is not readonly
 * and is filtered based on the developer sidebar tool if in development mode.
 *
 * Use this function for generating the modules object as it provides type information
 *
 * @param {import('components/DevelopmentModuleFilter/types').NDevelopmentModuleFilter.ReadOnlyModules} modules
 * @param {import('components/DevelopmentModuleFilter/types').NDevelopmentModuleFilter.LocalStorageIdentifier} localStorageIdentifier
 */
export const createModules = (modules, localStorageIdentifier) => {
    const _modules = /** @type {import('components/DevelopmentModuleFilter/types').NDevelopmentModuleFilter.Modules} */ ({ ...modules });

    if (import.meta.env.MODE === 'development') {
        filterModules(_modules, localStorageIdentifier);
    }

    return {
        modules: _modules,
        originalModules: modules
    };
};

/**
 * This function modifies the passed "modules" object and does not act as a pure function. With this in mind,
 * the function provides a generic handler for filtering out modules based on local storage set from the DevelopmentModuleFilter component
 * and should only be used in development mode.
 *
 * @param {import('components/DevelopmentModuleFilter/types').NDevelopmentModuleFilter.Modules} modules
 * @param {import('components/DevelopmentModuleFilter/types').NDevelopmentModuleFilter.LocalStorageIdentifier} localStorageIdentifier
 */
export const filterModules = (modules, localStorageIdentifier) => {
    const disableSidebarState = getSidebarFromLocalStorage(localStorageIdentifier);

    Object.entries(disableSidebarState).forEach(([label, active]) => {
        if (!active) {
            //remove it from modules
            Object.entries(modules).forEach(([section, _modules]) => {
                modules[section] = _modules?.filter((module) => module.sidebarLink.label !== label);
            });
        }
    });
};

/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { useContext, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Field } from 'redux-form';
import { useBoolean } from 'usehooks-ts';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import SolidButton from 'components/Buttons/SolidButton';
import Grid from 'components/Grid';
import NXBox from 'components/NXBox';
import NXCard from 'components/NXCard';
import Text from 'components/Utils/Text';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import ContentEditorModuleContent from 'containers/katana/containers/ContentEditorLightbox/contentEditorModuleContent';
import { SectionSelectionGridReduxForm } from 'containers/katana/containers/contentEditorModules/chooseWebLayout/sectionSelectionGridReduxForm';
import { ThemePresetGridReduxForm } from 'containers/katana/containers/contentEditorModules/chooseWebLayout/themePresetGridReduxForm';
import ValidFormSubmitButton from 'containers/katana/containers/contentEditorModules/validFormSubmitButton/validFormSubmitButton';
import { TutorialVideo } from '../tutorialVideo';

/**********************************************************************************************************
 *   QUERIES
 **********************************************************************************************************/
import { katanaQuery } from 'containers/katana/queries/tanstackTree';
import { useCreateKatanaThemeMutation } from 'containers/katana/queries/useCreateKatanaThemeMutation';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { useKatanaParams } from 'containers/katana/hooks/useSetupEditorRouteParams';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import { ContentEditorRenderModulesContext } from 'containers/katana/containers/ContentEditorLightbox/contentEditorRenderModules/consts';
import { tutorialVideoTimestamps } from 'containers/katana/data/tutorialVideoData';
const { PRESET_ID, SECTIONS } = useCreateKatanaThemeMutation.FORM_FIELD_KEYS;

const validateMinimumOneSection = (v) => (v?.length ? undefined : 'Please select at least one section');

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
const ChooseWebLayoutContentModule = function ChooseWebLayoutContentModule() {
    /***** HOOKS *****/
    const { katanaServiceId } = useKatanaParams();
    const { value: showingLayoutRefinement, setTrue: showLayoutRefinement, setFalse: hideLayoutRefinement } = useBoolean(false);

    const { form } = useContext(ContentEditorRenderModulesContext);
    const formValues = useSelector((state) => state?.form?.[form]?.values);

    const { LayoutAndContent } = tutorialVideoTimestamps;

    /***** QUERIES *****/
    const { data: get_katana_theme_presets_data } = katanaQuery.serviceID.meta.getThemePresets.useQuery(katanaServiceId);

    /***** EFFECTS *****/
    useEffect(() => {
        document.querySelector('.KatanaContentEditorLightbox__modal').scrollTo(0, 0);
    }, [showingLayoutRefinement]);

    /***** RENDER *****/
    return (
        <>
            {showingLayoutRefinement ? (
                ''
            ) : (
                <>
                    <Text.Heading h2>Start with a Template</Text.Heading>
                    <Text secondary size--m>
                        {`Select a template that best matches the appearance you'd like for your website.`}
                        <br />
                        <br />
                        {`You can hover over the one you're interested in and choose "Preview" to see how each template looks. Don't worry, if the template isn't completely right for you, you adjust each section later.`}
                    </Text>
                </>
            )}
            <TutorialVideo tutorial={LayoutAndContent} />
            <ContentEditorModuleContent>
                <NXBox.Title title="Choose your website template" />
                {showingLayoutRefinement ? (
                    <NXCard border>
                        <NXCard.Actions>
                            <NXCard.Actions align--start column>
                                <Text medium secondary size--s>
                                    Current template
                                </Text>
                                <Text medium black size--m>
                                    {get_katana_theme_presets_data?.[formValues?.[PRESET_ID]]?.name || 'Template'}
                                </Text>
                            </NXCard.Actions>
                            <Grid columns="1fr">
                                <SolidButton onClick={hideLayoutRefinement}>Change Template</SolidButton>
                            </Grid>
                        </NXCard.Actions>
                    </NXCard>
                ) : (
                    <Field name={PRESET_ID} component={ThemePresetGridReduxForm} showLayoutRefinement={showLayoutRefinement} />
                )}
            </ContentEditorModuleContent>

            {showingLayoutRefinement && (
                <>
                    <Text.Heading h2>What sections would you like to include?</Text.Heading>
                    <Text secondary size--m>
                        {`Great choice on the template! Now, you can select the sections you'd like to display on your website. You can also add additional sections to what's already included for your chosen template.`}
                    </Text>

                    <Field name={SECTIONS} component={SectionSelectionGridReduxForm} validate={[validateMinimumOneSection]} />

                    <ValidFormSubmitButton />
                </>
            )}
        </>
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

export default ChooseWebLayoutContentModule;

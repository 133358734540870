import type { SocialsMetaInputNamespace } from 'containers/katana/containers/contentEditorModules/socialsMetaInput/types';
import _ from 'lodash';

export const socialFormatFilter = (social: string): SocialsMetaInputNamespace.InputField => {
    const titleCaseSocial = _.upperFirst(social);
    function getURLFromSocial() {
        switch (social) {
            case 'twitter':
                return ['x', 'twitter'];

            default:
                return social;
        }
    }
    function getSocialTitle() {
        switch (social) {
            case 'twitter':
                return 'Twitter/X';

            default:
                return titleCaseSocial;
        }
    }

    function getPlaceholderURL() {
        const domain = getURLFromSocial();
        const domainValue = _.isArray(domain) ? domain[0] : domain;
        switch (social) {
            case 'linkedin':
                return `https://www.${domainValue}.com/company/ventraip`;

            case 'tiktok':
                return `https://www.${domainValue}.com/@ventraip`;
            case 'youtube':
                return `https://www.${domainValue}.com/c/ventraipau/videos`;
            default:
                return `https://www.${domainValue}.com/ventraip`;
        }
    }

    return {
        type: social,
        label: getSocialTitle(),
        placeholder: `E.g. ${getPlaceholderURL()}`,
        description: `Please paste the ${titleCaseSocial} URL for your account. This can be found in the search bar of your web browser.`
    };
};

/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { zodResolver } from '@hookform/resolvers/zod';
import { HookForm } from 'components/Form/HookForm';
import RequestLoader from 'components/Loaders/Request';
import { KatanaPreviewFormDataExtractor } from 'containers/katana/components/HookFormHandler/KatanaPreviewFormDataExtractor';
import {
    katanaContentEditorLightboxHasChanges,
    katanaContentEditorLightboxSkipTanstackStore
} from 'containers/katana/containers/ContentEditorLightbox/stores';

import { useEffect } from 'react';
import type { DefaultValues, FieldValues, SubmitHandler } from 'react-hook-form';
import { FormProvider, useForm } from 'react-hook-form';
import { useBooleanTanstackStore } from 'utilities/hooks/tanstack-store/useBooleanStore';
import type { z } from 'zod';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type KatanaHookFormHandlerProps<TFieldValues extends FieldValues, TZodSchema extends z.Schema<any, any>> = {
    defaultValues: DefaultValues<TFieldValues>;
    zodSchema: TZodSchema;
    children: React.ReactNode;
    handleOnSubmit: SubmitHandler<TFieldValues>;
};

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const KatanaHookFormHandler = <TFieldValues extends FieldValues, TZodSchema extends z.Schema<any, any>>({
    defaultValues,
    zodSchema,
    children,
    handleOnSubmit
}: KatanaHookFormHandlerProps<TFieldValues, TZodSchema>) => {
    /***** HOOKS *****/
    const { setValue: setCanSkipSection } = useBooleanTanstackStore(katanaContentEditorLightboxSkipTanstackStore);
    const { setValue: setHasChanges } = useBooleanTanstackStore(katanaContentEditorLightboxHasChanges);

    const form = useForm({
        defaultValues,
        resolver: zodResolver(zodSchema),
        mode: 'all'
    });

    /***** FUNCTIONS *****/
    const checkKeyDown: React.KeyboardEventHandler<HTMLFormElement> = (e) => {
        if (e.key !== 'Enter') {
            return;
        }
        // When enter is pressed on an input field, it submits the form it's in, we don't want to do that. but we still want to be able to create a new line in a textarea, therefore we are only preventing default when it's an `Input` element
        if (e.target instanceof HTMLInputElement) {
            e.preventDefault();
        }
    };

    /***** EFFECTS *****/
    useEffect(() => {
        setCanSkipSection(!form.formState.isDirty);
        setHasChanges(form.formState.isDirty);
    }, [form.formState.isDirty]);

    useEffect(() => {
        return () => {
            setCanSkipSection(true);
            setHasChanges(false);
        };
    }, []);

    /***** RENDER *****/
    return (
        <FormProvider {...form}>
            <form onSubmit={form.handleSubmit(handleOnSubmit)} onKeyDown={checkKeyDown}>
                {children}
            </form>
            <KatanaPreviewFormDataExtractor />
            <RequestLoader.Suspense>
                <HookForm.DevTool />
            </RequestLoader.Suspense>
        </FormProvider>
    );
};

/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { createRoute } from '@tanstack/react-router';

/**********************************************************************************************************
 *   ROUTE IMPORTS
 **********************************************************************************************************/
import { InvoiceRoute } from 'containers/billing/routes/invoice';
import { routerMiddleware } from 'router/utils/middleware';

/**********************************************************************************************************
 *   ROUTE START
 **********************************************************************************************************/
/**
 * Renders nothing, simply exists as a route that the payment lightbox can render at.
 * 
 * The `InvoiceUrlLightbox` component will render at these routes.
 */
export const InvoicePayRoute = createRoute({
    getParentRoute: () => InvoiceRoute,
    path: '$invoiceId/pay',
    beforeLoad(opts) {
        routerMiddleware.business(this, opts);
        routerMiddleware.authentication('user', opts);
    }
});

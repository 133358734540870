/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import './_FreeDomainTile.scss';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type FreeDomainTileProp = {
    /**
     * The main text to display on the tile
     */
    text: string;

    /**
     * The button to display on the tile
     */
    button: React.ReactNode;

    /**
     * The time until expiry in days, hours and minutes
     */
    timeUntilExpiry: string;
};

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const FreeDomainTile: React.FC<FreeDomainTileProp> = ({ text, button, timeUntilExpiry }) => {
    /***** RENDER *****/
    return (
        <div className="FreeDomainTile">
            <div className="FreeDomainTile__top">
                <h4 className="FreeDomainTile__title">{text}</h4>
                {button}
            </div>
            {timeUntilExpiry ? <p className="FreeDomainTile__footer">Offer expires in {timeUntilExpiry}</p> : ''}
        </div>
    );
};

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

export default FreeDomainTile;

/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import classNames from 'classnames';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { createAppliedStylingClasses } from 'components/Utils/methods';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import './__Icon.scss';

/**********************************************************************************************************
 *   TYPE IMPORTS
 **********************************************************************************************************/
import type { _IconProps } from 'components/Icons/_Icon/types';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * @deprecated Use `PhosphorIcons` instead
 */
function _Icon(props: _IconProps) {
    const { name, className, primary, secondary, confirm, warn, info, notice, white, black } = props;

    /***** RENDER HELPERS *****/
    const appliedStylingClasses = createAppliedStylingClasses({
        props: { primary, secondary, confirm, warn, info, notice, white, black },
        componentName: '_Icon'
    });
    const _IconClassNames = classNames('_Icon', 'icon', 'icon-' + name, appliedStylingClasses, className);
    return <i className={_IconClassNames} />;
}

export default _Icon;

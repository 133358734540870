/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import store from 'store/store';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { pushNewSidebarRefs } from 'components/Sidebar/action';
import { getSidebarState, scrollToComponent } from 'utilities/methods/commonActions';

/**
 * Simply registers the component so the component can be scrolled to via sidenav or on load
 * @param {{
 *      props: {
 *          path:string,
 *          location: import('react-router').RouteChildrenProps['location']
 *      },
 *      scrollRef: HTMLElement
 * }} element
 * @param {boolean} status
 * 
 * @deprecated - use {@link ScrollableComponent} instead.
 */
export const registerScrollEvents = (element, status) => {
    const { dispatch } = store;

    const sidebarRefs = { ...getSidebarState() };

    const { path, location } = element.props;
    const { pathname } = location;
    const { scrollRef } = element;

    // if an element has more than 1 path, push them all onto sidebar refs
    if (Array.isArray(path)) {
        let pathThatMatchesPathname;
        let anyChanges;
        path.forEach((item) => {
            if (!sidebarRefs[item] && scrollRef) {
                sidebarRefs[item] = scrollRef;
                anyChanges = true;
            }
            if (item === pathname) {
                pathThatMatchesPathname = item;
            }
        });
        if (anyChanges) dispatch(pushNewSidebarRefs(sidebarRefs));
        if (status && pathThatMatchesPathname === pathname) {
            dispatch(scrollToComponent(scrollRef, pathname));
        }
        return;
    }

    if (!sidebarRefs[path] && scrollRef) {
        // Need to push the new sidebar references to the state to allow navigation scrolling
        sidebarRefs[path] = scrollRef;
        dispatch(pushNewSidebarRefs(sidebarRefs));
    }

    if (status && path === pathname) {
        dispatch(scrollToComponent(scrollRef, pathname));
    }
};

import { type AnyRouter, type RegisteredRouter, type RoutePaths, type Redirect, redirect } from '@tanstack/react-router';
import type { AnyRouteId, GetOptionTypeFromRouteId } from 'router/types';

/**********************************************************************************************************
 *   FUNCTION START
 **********************************************************************************************************/
type From<TRouteId extends AnyRouteId> = { from: TRouteId; opts: GetOptionTypeFromRouteId<TRouteId, 'beforeLoad'> };
export function redirectMatch<
    TRouteId extends AnyRouteId,
    TTo extends string,
    TRouter extends AnyRouter = RegisteredRouter,
    TFrom extends RoutePaths<TRouter['routeTree']> | string = string,
    TMaskFrom extends RoutePaths<TRouter['routeTree']> | string = TFrom,
    TMaskTo extends string = ''
>({ from, opts: optsFrom }: From<TRouteId>, toOptions: Redirect<TRouter, TFrom, TTo, TMaskFrom, TMaskTo>) {
    const { pathname } = optsFrom.buildLocation({
        to: from as string,
        search: optsFrom.search,
        params: optsFrom.params
    });

    if (optsFrom.cause === 'enter' && optsFrom.location.pathname === pathname) {
        throw redirect(toOptions);
    }
}

/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import _ from 'lodash';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/

/**********************************************************************************************************
 *   TYPE IMPORTS
 **********************************************************************************************************/
import type { AnyRouteOptions, Params, ResolvedRouteConfig } from 'router/types';
import type { PathObject } from 'router/utils';
import type { getRouteConfig } from 'router/utils/getRouteConfig';

/**********************************************************************************************************
 *   FUNCTION START
 **********************************************************************************************************/
/**
 * Casts a route config object to a full object route config object. This is useful for ensuring that all properties are present
 * and that default / non-required properties are set to a default value. It also guarantees that it is not a boolean value.
 *
 * Note: By default, if authenticated is not provided, it will default to true.
 */
export function resolveRouteConfig<This extends AnyRouteOptions | PathObject = PathObject>(
    config: ReturnType<typeof getRouteConfig>,
    params: Params<This>
): ResolvedRouteConfig {
    if (typeof config === 'boolean') {
        return {
            type: 'notFound',
            notFound: true,
            active: config
        };
    }

    if (!('notFound' in config) && !('redirect' in config)) {
        // Likely a route with hashes but no specific handler provided.
        return {
            type: 'notFound',
            notFound: true,
            ..._.omit(config, 'notFound')
        };
    }

    if (config.notFound) {
        return {
            type: 'notFound',
            notFound: true,
            ..._.omit(config, 'notFound')
        };
    }

    if (typeof config.redirect === 'function') {
        return {
            type: 'redirect',
            redirect: config.redirect(params),
            ..._.omit(config, 'redirect')
        };
    }

    return {
        type: 'redirect',
        redirect: config.redirect ?? '/dashboard',
        ..._.omit(config, 'redirect')
    };
}

/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import classNames from 'classnames';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import Title from './Title';
import { SortableTitle } from './sortableTitle';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import { useNXTableContext } from '../NXTableContext';
import './_NXTableHeader.scss';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * Heading bar for the table
 *
 * @type {NXTable.Header.THeader}
 */
const Header = ({ children, className, gap = 2, columns, headings }) => {
    /***** HOOKS *****/
    const nxTableContext = useNXTableContext();
    const finalColumns = nxTableContext?.columns || columns;

    /***** RENDER HELPERS *****/
    const headerClassNames = classNames('NXTableHeader', 'NXTableHeader__alignItems--left', className);

    const rowStyle = {
        gridTemplateColumns: finalColumns,
        gap: Number(gap) >= 0 ? `calc(5px * ${gap})` : ''
    };

    /***** RENDER *****/
    return (
        <div className={headerClassNames} style={rowStyle}>
            {headings ? headings.map(([key, title]) => <Title key={key}>{title}</Title>) : children}
        </div>
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

export default Object.assign(Header, {
    Title,
    SortableTitle
});

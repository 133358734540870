/**
 *
 * Usage: oxfordCommaStyle(['some','string','enjoyer'], 'or');
 * output: "some, string, and enjoyer"
 * @param {string[]} arr
 * @param {string} conjunction
 * @param {string} ifEmpty
 * @returns
 */
export function oxfordCommaStyle(arr, conjunction, ifEmpty = '') {
    if (!arr.length) return ifEmpty;
    if (arr.length < 2) return arr[0];
    if (arr.length < 3) return arr.join(` ${conjunction} `);
    arr = arr.slice();
    arr[arr.length - 1] = `${conjunction} ${arr[arr.length - 1]}`;
    return arr.join(', ');
}

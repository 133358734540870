/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React from 'react';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import { VentraUserRouteTemplate } from 'App/components/templates/ventra-user';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { useIsAuthenticated } from 'utilities/hooks/redux/useIsAuthenticated';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type AppRouteTemplateComponent = React.FC<{
    template: 'VIPcontrol' | (string & {}); // config/config - application
    children: React.ReactNode; // Outlet at the moment
}>;

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * Expects an Outlet as children for clarity on where the outlet is being rendered. I think it is clearer when you
 * can clearly see the Outlet directly in the route component so you don't have to go looking for the outlet at each layer of the app.
 * (Lleyton's highly volatile opinion - 26/07/2024)
 */
export const AppRouteTemplate: AppRouteTemplateComponent = ({ template, children }) => {
    /***** HOOKS *****/
    const isAuthenticated = useIsAuthenticated();

    /***** RENDER *****/
    switch (template) {
        case 'VIPcontrol': {
            if (isAuthenticated) {
                return <VentraUserRouteTemplate outlet={children} />;
            } else {
                return children;
            }
        }
        /*
        case 'Intaserve': {
            if (isAuthenticated) {
                return <IntaserveUserRouteTemplate outlet={children} />; 
            } else {
                return children;
            }
        },
        */
        default: {
            return children;
        }
    }
};

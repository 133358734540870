/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { get, isEqual } from 'lodash';
import React from 'react';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import SolidTag from 'components/Tags/SolidTag';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { useGetDynamicFormFieldValues } from 'containers/katana/components/dynamicFormFieldRenderer/hooks/useGetDynamicFormFieldValue';
import { useTargetKeyToPropertyPath } from 'containers/katana/components/dynamicFormFieldRenderer/hooks/useTargetKeyToPropertyPath';
import { useGetCaveat } from 'containers/katana/formFields/methods/useGetCaveat';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import { CaveatTypes } from 'containers/katana/formFields/caveatTypes';
import './_ComplexLabel.scss';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * Can only be used inside the propertyRenderer component inside a katana service as it relies on the formValues and serviceID
 * @type {React.FC<{
 *      property: import('containers/katana/types').KatanaNamespace.SectionDefinitions.PropertiesModified;
 *      children: React.ReactNode;
 * }>}
 */
export const ComplexLabel = ({ property, children }) => {
    /**
     * @type {{
     *      on_hover?: {
     *          colour: string;
     *          label:  string;
     *      };
     *      if_current_value_in_key?: {
     *          key:   string;
     *          label: string;
     *      };
     *      primary_label?: string;
     *  } | undefined}
     */
    const complexLabel = useGetCaveat(CaveatTypes.COMPLEX_LABEL, property.caveats);
    const newPropertyAccessor = useTargetKeyToPropertyPath(property?.key, complexLabel?.if_current_value_in_key?.key);

    /***** HOOKS *****/
    const formValues = useGetDynamicFormFieldValues();

    /***** RENDER HELPERS *****/
    const currentValue = get(formValues.values, property?.key);
    const valueInTargetKey = get(formValues.values, newPropertyAccessor);

    const isValueInKey = isEqual(currentValue, valueInTargetKey);

    /***** FUNCTIONS *****/
    function renderComplexLabelContent() {
        switch (true) {
            case complexLabel?.primary_label === 'if_current_value_in_key' && isValueInKey:
                return <SolidTag color="black">{complexLabel?.if_current_value_in_key?.label}</SolidTag>;

            default:
                return (
                    <SolidTag color={complexLabel?.on_hover?.colour ?? 'black'} className="ComplexLabel__onHoverDisplay">
                        {complexLabel?.on_hover?.label}
                    </SolidTag>
                );
        }
    }

    /***** RENDER *****/
    return (
        <div className="ComplexLabel">
            {children}
            {renderComplexLabelContent()}
        </div>
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { useEffect, useState } from 'react';

/**********************************************************************************************************
 *   HOOK START
 **********************************************************************************************************/
/**
 * Automatically updates the value of the state when the original input value changes.
 * useful in places where the original input value takes precedence over the state value.
 *
 * @template T=unknown
 * @param {T} value
 * @returns {[T, React.Dispatch<T>]}
 */
export function useStateEffect(value) {
    /***** STATE *****/
    const [_value, _setValue] = useState(value);

    /***** EFFECTS *****/
    useEffect(() => {
        _setValue(value);
    }, [value]);

    /***** HOOK RESULTS *****/
    return [_value, _setValue];
}
/**********************************************************************************************************
 *   HOOK END
 **********************************************************************************************************/

const attachmentKeys = ['name', 'file', 'mime_type', 'id', 'url'];

/**
 * @param {unknown} value
 * @returns {value is KatanaAPI.Katana.ServiceID.Uploads.POST.AttachmentData}
 */
export function isImageAttachmentIDFromValue(value) {
    if (typeof value !== 'object' || value === null) {
        return false;
    }
    return attachmentKeys.every((key) => key in value);
}

/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { useContext } from 'react';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import { RevealerContext } from 'components/Revealer/consts';

/**********************************************************************************************************
 *   HOOK START
 **********************************************************************************************************/
/**
 * Custom hook to access the dropdown context.
 */
export const useRevealer = () => useContext(RevealerContext);
/**********************************************************************************************************
 *   HOOK END
 **********************************************************************************************************/

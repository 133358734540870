import { postLoginChecklistItems } from 'config/config';
import { CollectMissingDetailsCheck, CollectMissingDetailsContent } from 'containers/dashboard/Components/postLoginChecklist/collectMissingDetails';
import { EnableTwoFactorCheck } from 'containers/dashboard/Components/postLoginChecklist/enableTwoFactor';
import { EnableTwoFactorContent } from 'containers/dashboard/Components/postLoginChecklist/enableTwoFactor/content';

// If more items are added to this list, they also need to be added to "postLoginChecklistItems" in "base.config.js"
export const possibleChecklistItems = [
    {
        label: 'Customer details',
        name: postLoginChecklistItems.USER_DETAILS.name,
        UIComponent: CollectMissingDetailsContent,
        CheckIsRequiredComponent: CollectMissingDetailsCheck,
        isRequired: false,
        isComplete: false
    },
    {
        label: 'Enable 2FA',
        name: postLoginChecklistItems.ENABLE_TWO_FACTOR.name,
        UIComponent: EnableTwoFactorContent,
        CheckIsRequiredComponent: EnableTwoFactorCheck,
        isRequired: false,
        isComplete: false
    }
];

/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { useNavigate, useParams } from '@tanstack/react-router';
import { useSelector } from 'react-redux';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import { CustomDropdown } from 'components/Dropdowns/CustomDropdown';
import SelectOptions from 'components/Dropdowns/SelectDropdown/Options';
import Title from 'components/Title';
import { pushNotification } from 'components/Toast/functions';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { getTicketSubmitUrl } from 'containers/support/methods';
import { API } from 'utilities/api/hosting';
import { createPopup, getDataFromSuccessResponse, getErrorFromFailResponse } from 'utilities/methods/commonActions';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const _HostingSharedTitle = () => {
    /***** HOOKS *****/
    const navigate = useNavigate();
    const hosting_information_data = useSelector((state: any) => state.hosting.hosting_information_data);
    const { serviceId } = useParams({ from: '/my-services/hosting/$serviceId' });

    /***** DERIVED *****/
    const { type, included } = hosting_information_data ?? {};
    const { domain } = hosting_information_data?.attributes ?? {};
    const [product] = included?.filter(({ type }) => type === 'product') ?? [];

    /***** FUNCTIONS *****/
    const handleLogin = () => {
        const { closePopup, goToTargetUrl } = createPopup();

        API.hosting.GET.login
            .cpanel({ id: serviceId })
            .then((response) => {
                const data = getDataFromSuccessResponse(response);
                goToTargetUrl(data.attributes.url);
            })
            .catch((error) => {
                pushNotification(getErrorFromFailResponse(error));
                closePopup();
            });
    };

    /***** RENDER *****/
    return (
        <Title
            serviceTitle={domain}
            serviceSubTitle={`${product?.attributes?.name} (Shared)`}
            serviceType={type}
            action={[
                {
                    label: 'cPanel Login',
                    type: 'onClick',
                    color: 'notice',
                    onClick: (e) => {
                        e.preventDefault();
                        handleLogin();
                    }
                },
                <CustomDropdown
                    className="TitleShared__singleDropdown"
                    key="dropdown"
                    label={({ labelRef, dropdownTitleOnClick }) => (
                        <button className="TitleShared__singleDropdownButton" ref={labelRef} type="button" onClick={() => dropdownTitleOnClick()}>
                            Web Hosting Help
                            <i className="item__icon icon icon-chevron-down" />
                        </button>
                    )}
                    render={() => (
                        <SelectOptions
                            options={[
                                {
                                    label: 'Web Hosting Help Articles',
                                    onSelect: () => navigate({ to: '/support/support-centre/web-hosting' })
                                },
                                {
                                    label: 'Submit a Technical Support eTicket for this Service',
                                    onSelect: () => navigate({ to: getTicketSubmitUrl({ department: 'technical-support', service: serviceId }) })
                                },
                                {
                                    label: 'Submit a Billing eTicket for this Service',
                                    onSelect: () => navigate({ to: getTicketSubmitUrl({ department: 'accounts-billing', service: serviceId }) })
                                }
                            ]}
                        />
                    )}
                />
            ]}
        />
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

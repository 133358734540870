/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { createRoute } from '@tanstack/react-router';

/**********************************************************************************************************
 *   ROUTE IMPORTS
 **********************************************************************************************************/
import { SupportRoute } from 'containers/support';
import { routerMiddleware } from 'router/utils/middleware';
import { lazyFN } from 'utilities/methods/tanstack/router/lazyFn';
import { z } from 'zod';
import { redirectMiddleware } from '../../../../methods';

const searchSchema = z.object({
    invoice: z.coerce.string().optional(),
    service: z.coerce.string().optional()
});

/**********************************************************************************************************
 *   ROUTE START
 **********************************************************************************************************/
export const TicketsParam4Route = createRoute({
    getParentRoute: () => SupportRoute,
    path: 'tickets/$section/$param4',
    beforeLoad(opts) {
        routerMiddleware.business(this, opts);
        routerMiddleware.authentication('user', opts);
        redirectMiddleware(opts);
    },
    validateSearch: searchSchema
}).lazy(lazyFN(() => import('./index.lazy'), 'LazyTicketsSectionParam4Route'));

/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { has } from 'lodash';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import { application } from 'config/config';
const acronyms = ['VPS', 'SSL', 'SLA', 'VIP', 'FAQ', 'DNS', 'API', 'FTP', 'SSL', 'PHP', 'SSH', 'SSL', 'TLS'];
const replacers = {
    'vip-sites': 'VIPsites',
    'vip-rewards': 'VIPrewards'
};

export function getPageTitle(location: string) {
    // Remove leading and trailing slashes, then split the location string, filter any id's out
    const locationArr = location
        .trim()
        .replace(/^\/+|\/+$/g, '')
        .split('/')
        .filter((entry) => isNaN(Number(entry)));

    if (!locationArr.length) {
        return;
    }

    const modifiedLocationList = locationArr.map((path) => {
        if (has(replacers, path)) {
            return replacers[path];
        }

        let updatedPath = path;
        // Handle strings containing dashes
        if (updatedPath.includes('-')) {
            const stringArr = updatedPath.split('-');
            updatedPath = stringArr.map((str) => str.charAt(0).toUpperCase() + str.substring(1)).join(' ');
        }

        // Handle acronyms
        const acronym = acronyms.find((acr) => acr === updatedPath.toUpperCase());
        updatedPath = acronym ?? updatedPath.charAt(0).toUpperCase() + updatedPath.substring(1);

        return updatedPath;
    });

    return `${modifiedLocationList.join(' - ')} | ${application}`;
}

/**
 * Removes specified keys from an object.
 * @param {Object} options - The options object.
 * @param {Object} options.props - The object from which keys should be removed.
 * @param {string[]} [options.keysToRemove=[]] - The keys to remove from the object.
 * @returns {Object} - The object with the specified keys removed.
 */
export function removeKeysFromObject({ props, keysToRemove = [] }) {
    if (!keysToRemove.length) return props;
    const newProps = {};
    Object.assign(newProps, props);
    keysToRemove.forEach((key) => delete newProps[key]);
    return newProps;
}

/**
 * Removes keys from an object that are not within the specified key boundary.
 * @param {Object} options - The options object.
 * @param {Object} options.props - The object from which keys should be removed.
 * @param {string[]} [options.keysToRemove=[]] - The keys to remove from the object.
 * @param {Readonly<string[]>} [options.keyBoundary=[]] - The boundary of keys to preserve.
 * @returns {Object} - The object with the specified keys removed.
 */
export function removeKeysWithBoundary({ props, keysToRemove = [], keyBoundary = [] }) {
    const newProps = {};
    Object.assign(newProps, props);
    const propKeys = Object.keys(props);

    if (keyBoundary?.length) {
        const keysNotInBoundary = propKeys.filter((propKey) => !keyBoundary.includes(propKey));
        keysNotInBoundary.forEach((propKey) => {
            delete newProps[propKey];
        });
    }

    return removeKeysFromObject({ props: newProps, keysToRemove });
}

/**
 * Creates BEM (Block Element Modifier) classes for the specified component.
 * @param {Object} options - The options object.
 * @param {string} options.componentName - The name of the component.
 * @param {string[]} options.keys - The keys used to create BEM classes.
 * @param {string} [options.delimiter='__'] - The delimiter used between component name and keys.
 * @returns {string[]} - An array of BEM classes.
 */
function createBEMClasses({ componentName, keys, delimiter = '__' }) {
    return keys.map((key) => `${componentName}${delimiter}${key}`);
}

/**
 * Creates an array of class names for the non-excluded prop keys based on the provided options.
 * @param {Object} options - The options object.
 * @param {Object} options.props - The props object.
 * @param {string[]} [options.keysToRemove=[]] - The keys to remove from the props object.
 * @param {Readonly<string[]>} [options.keyBoundary=[]] - The boundary of keys to preserve.
 * @param {string} options.componentName - The name of the component.
 * @param {string} [options.delimiter='__'] - The delimiter used between component name and keys.
 * @returns {string[]} - An array of applied styling classes.
 * @throws {Error} - If both `keyBoundary` and `keysToRemove` are used.
 */
export function createAppliedStylingClasses({ props, keysToRemove = [], keyBoundary = [], componentName, delimiter = '__' }) {
    if (keyBoundary.length && keysToRemove.length)
        throw new Error('Cannot use both `keyBoundary` and `keysToRemove` in createAppliedStylingClasses, choose one');

    const newProps = removeKeysWithBoundary({ props, keysToRemove, keyBoundary });
    const appliedStylingKeys = Object.keys(newProps);
    const filteredAppliedStylingKeys = appliedStylingKeys.filter((key) => Boolean(newProps[key]));
    return createBEMClasses({ componentName, keys: filteredAppliedStylingKeys, delimiter });
}

/**
 * Creates an array of class names for the non-excluded prop keys based on the provided options but keys where the values have `important` will get an additional class for important for example `Text--primary Text--primary--important`.
 * @param {Object} options - The options object.
 * @param {Object} options.props - The props object.
 * @param {string[]} [options.keysToRemove=[]] - The keys to remove from the props object.
 * @param {Readonly<string[]>} [options.keyBoundary=[]] - The boundary of keys to preserve.
 * @param {string} options.componentName - The name of the component.
 * @param {string} [options.delimiter='__'] - The delimiter used between component name and keys.
 * @returns {string[]} - An array of applied styling classes.
 * @throws {Error} - If both `keyBoundary` and `keysToRemove` are used.
 */
export function createAppliedStylingClassesWithImportant({ props, keysToRemove = [], keyBoundary = [], componentName, delimiter = '__' }) {
    if (keyBoundary.length && keysToRemove.length)
        throw new Error('Cannot use both `keyBoundary` and `keysToRemove` in createAppliedStylingClasses, choose one');

    const newProps = removeKeysWithBoundary({ props, keysToRemove, keyBoundary });
    const appliedStylingKeys = Object.keys(newProps);
    const filteredAppliedStylingKeys = appliedStylingKeys
        .filter((key) => Boolean(newProps[key]))
        .flatMap((key) => (newProps[key] === 'important' ? [key, `${key}--important`] : key));
    return createBEMClasses({ componentName, keys: filteredAppliedStylingKeys, delimiter });
}

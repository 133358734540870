/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { Link } from '@tanstack/react-router';
import classNames from 'classnames';
import React, { useRef, useState } from 'react';
import { isElement } from 'react-is';
import Tooltip from 'react-tooltip-lite';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import { PhosphorIcons } from 'components/Icons/Phosphor';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { useClickAway } from 'utilities/hooks/useClickAway';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import './_BaseDropdown.scss';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * This component is only used to provide basic functionality to more advanced dropdowns.
 * This component does not have any styling, and should not be used on it's own.
 * If you need a new dropdown type use this as it's base.
 * @param {{
 *      variant: 'SolidDropdown' | 'OutlineDropdown';
 *      className?: string;
 *      list: Array<import('react').ReactNode | DropdownListItemDataObject>;
 *      size?: 'small' | 'medium';
 *      title: import('react').ReactNode;
 *      titleOnClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
 *      to?: string;
 *      color?: 'primary' | 'secondary' | 'success' | 'confirm' | 'error' | 'warn' | 'warning' | 'notice' | 'gray';
 *      onMouseOver?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
 * }} props
 */
function BaseDropdown({ variant, className, list = [], size, title, titleOnClick, to, color = 'primary', onMouseOver }) {
    /***** STATE *****/
    const [isDropdownMenuOpen, setIsDropdownMenuOpen] = useState(false);

    /***** HOOKS *****/
    // Ref
    const dropdownRef = useRef(null);

    // Click away
    useClickAway([dropdownRef], () => setIsDropdownMenuOpen(false));

    /***** FUNCTIONS *****/
    const toggleDropdownMenu = () => {
        setIsDropdownMenuOpen((prev) => !prev);
    };

    /***** RENDER HELPERS *****/
    const renderList = () => {
        if (list.length <= 0) return '';

        return list.map((item, index) => {
            if (isElement(item)) {
                const { onClick, ...restItemProps } = item?.props || {};
                const handleItemOnClick = (e) => {
                    toggleDropdownMenu();
                    if (!item) {
                        return;
                    }

                    onClick?.(e);
                };

                const modifiedProps = {
                    ...restItemProps,
                    onClick: handleItemOnClick
                };

                return React.cloneElement(item, modifiedProps);
            }

            const { label, link, onClick, type, to, onMouseOver } = /** @type {DropdownListItemDataObject} */ (item);

            const interactionProps = {
                onMouseOver,
                onFocus: onMouseOver
            };

            function renderListItemAnchor(_to) {
                return (
                    <li key={index}>
                        <Link to={_to} {...interactionProps}>
                            {label}
                        </Link>
                    </li>
                );
            }

            if (to) return renderListItemAnchor(to);

            switch (type) {
                case 'anchor':
                case 'internal':
                    return renderListItemAnchor(link);

                case 'external':
                    return (
                        <li key={index}>
                            <a href={link} {...interactionProps}>
                                {label}
                            </a>
                        </li>
                    );

                case 'onClick': {
                    const { disabled = false, disabledTooltip, className: itemClass } = item;

                    const listItemOnClick = (e) => {
                        if (!disabled) {
                            onClick(e);
                            toggleDropdownMenu();
                        }
                    };

                    const renderItemButton = () => (
                        <li key={index}>
                            <button
                                {...interactionProps}
                                className={classNames(`${variant}__list--button`, itemClass, { disabled })}
                                onClick={listItemOnClick}
                            >
                                {label}
                            </button>
                        </li>
                    );

                    return disabled && disabledTooltip && disabledTooltip.length > 0 ? (
                        <Tooltip
                            className="BaseDropdown__disabledListItemTooltip"
                            direction="up"
                            distance={10}
                            arrowSize={5}
                            content={<div className="SolidDropdown__tooltip">{disabledTooltip}</div>}
                        >
                            {' '}
                            {renderItemButton()}{' '}
                        </Tooltip>
                    ) : (
                        renderItemButton()
                    );
                }

                default:
                    return '';
            }
        });
    };

    const renderDropdownMenu = () => {
        if (!isDropdownMenuOpen) {
            return '';
        }

        return (
            <div className={`BaseDropdown__bottom ${variant}__bottom`}>
                <ul className={`BaseDropdown__list ${variant}__list`}>{renderList()}</ul>
            </div>
        );
    };

    const renderMainButton = () => {
        const interactionProps = {
            onMouseOver,
            onFocus: onMouseOver
        };

        if (to) {
            return (
                <Link className="title" to={to} {...interactionProps}>
                    {title}
                </Link>
            );
        }

        return (
            <button type="button" onClick={titleOnClick} className="title" {...interactionProps}>
                {title}
            </button>
        );
    };

    function getDotsMenuColour() {
        if ((color === 'gray' && variant === 'SolidDropdown') || (color === 'primary' && variant !== 'OutlineDropdown')) {
            return 'white';
        }
        return color;
    }

    const dotsMenuColour = getDotsMenuColour();

    const baseDropdownClasses = classNames('BaseDropdown', variant, className, size, color, {
        nolist: !list || list.length === 0
    });

    /***** RENDER *****/
    return (
        <div ref={dropdownRef} className={baseDropdownClasses}>
            <div className={`BaseDropdown__top ${variant}__top  top`}>
                {renderMainButton()}
                <button
                    type="button"
                    className={`menu ${isDropdownMenuOpen ? 'open' : ''}`}
                    onClick={(e) => {
                        e.preventDefault();
                        toggleDropdownMenu();
                    }}
                >
                    <div className="dots">
                        <PhosphorIcons.DotsThree.Bold size={24} {...{ [dotsMenuColour]: dotsMenuColour }} />
                    </div>
                </button>
            </div>

            {renderDropdownMenu()}
        </div>
    );
}

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
export const baseDropDownColourOptions = ['primary', 'secondary', 'success', 'confirm', 'error', 'warn', 'warning', 'notice', 'gray'];

export default BaseDropdown;

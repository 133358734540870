/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import classNames from 'classnames';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import { PropInjector } from 'components/Utils/PropInjector';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import './_Padding.scss';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/

/**
 * Just adds padding to its direct child
 * @param {PaddingProps} props
 */
function Padding({ x = 0, y = 0, left, right, top, bottom, xy, children, className, paddingOnly, fullWidth, inject }) {
    /***** RENDER *****/
    const paddingClasses = classNames('Padding', className, {
        [`Padding--x-${xy ?? x}`]: xy ?? x,
        [`Padding--y-${xy ?? y}`]: xy ?? y,
        [`Padding--left-${left}`]: left,
        [`Padding--right-${right}`]: right,
        [`Padding--top-${top}`]: top,
        [`Padding--bottom-${bottom}`]: bottom,
        'Padding--paddingOnly': paddingOnly,
        'Padding--fullWidth': fullWidth
    });

    return (
        <PropInjector inject={inject} injectableProps={{ className: paddingClasses }} injectable={children}>
            <div className={paddingClasses}>{children}</div>
        </PropInjector>
    );
}

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

export default Padding;

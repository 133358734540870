/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { useEffect, useState } from 'react';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { determineBackgroundColor } from 'components/ImageSelectControl/methods/determineBackgroundColor';
import { urlToDataUri } from 'components/ImageSelectControl/methods/urlToDataUri';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import './_useImageColourContrastBackgroundColour.scss';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * This wrapper injects styling based on the imageURL
 * The child component can then have a background colour that contrasts with the image,
 * The child component should be able to accept a className prop
 * @param {ImageSelectControl.ImageDataOrFile} imageURL
 */
function useImageColourContrastBackgroundColour(imageURL) {
    /***** STATE *****/
    const [requiresContrast, setRequiresContrast] = useState(false);

    /***** EFFECTS *****/
    useEffect(() => {
        if (!imageURL || typeof imageURL !== 'string') {
            return;
        }

        urlToDataUri(imageURL)
            .then((dataUri) => {
                return determineBackgroundColor(dataUri);
            })
            .then((backgroundColor) => {
                setRequiresContrast(backgroundColor === 'black');
            })
            .catch((error) => {
                console.error('Error:', error);
            });
    }, [imageURL]);

    /***** HOOK RESULTS *****/
    return { requiresContrast, classes: { backgroundContrast: requiresContrast ? 'useImageColourContrastBackgroundColour__background' : '' } };
}

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
export { useImageColourContrastBackgroundColour };

// Example usage:

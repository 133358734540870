import {
    DOMAIN_BULK_INITIAL_STATE,
    DOMAIN_BULK_ACTION_REQUEST,
    DOMAIN_BULK_ACTION_SUCCESS,
    DOMAIN_BULK_ACTION_FAIL,
    DOMAIN_BULK_LIST_REQUEST,
    DOMAIN_BULK_LIST_SUCCESS,
    DOMAIN_BULK_LIST_FAIL,
    DOMAIN_BULK_INFO_RESET,
    DOMAIN_BULK_INFO_REQUEST,
    DOMAIN_BULK_INFO_SUCCESS,
    DOMAIN_BULK_INFO_FAIL,
    DOMAIN_BULK_CONTACT_ACTION_REQUEST,
    DOMAIN_BULK_CONTACT_ACTION_SUCCESS,
    DOMAIN_BULK_CONTACT_ACTION_FAIL
} from './action';

const initialState = {
    domain_bulk_action_status: null,
    domain_bulk_action_data: null,
    domain_bulk_action_error: null,
    domain_bulk_info_status: null,
    domain_bulk_info_data: null,
    domain_bulk_info_error: null,
    domain_bulk_info_meta: null,
    domain_bulk_list_status: null,
    domain_bulk_list_data: null,
    domain_bulk_list_error: null
};

const bulkReducer = (state = initialState, action) => {
    switch (action.type) {
        /**********************************************************************************************************
         *   RESET
         **********************************************************************************************************/
        case DOMAIN_BULK_INITIAL_STATE:
            return initialState;

        /**********************************************************************************************************
         *   BULK ACTION
         **********************************************************************************************************/
        case DOMAIN_BULK_LIST_REQUEST:
            return {
                ...state,
                domain_bulk_list_status: 'loading',
                domain_bulk_list_error: null
            };

        case DOMAIN_BULK_LIST_SUCCESS:
            return {
                ...state,
                domain_bulk_list_status: 'success',
                domain_bulk_list_data: action.domain_bulk_list_data
            };

        case DOMAIN_BULK_LIST_FAIL:
            return {
                ...state,
                domain_bulk_list_status: 'error',
                domain_bulk_list_error: action.domain_bulk_list_error
            };

        /**********************************************************************************************************
         *   BULK INFO
         **********************************************************************************************************/
        case DOMAIN_BULK_INFO_RESET:
            return {
                ...state,
                domain_bulk_info_status: null,
                domain_bulk_info_data: null,
                domain_bulk_info_error: null
            };

        case DOMAIN_BULK_INFO_REQUEST:
            return {
                ...state,
                domain_bulk_info_status: 'loading',
                domain_bulk_info_data: null,
                domain_bulk_info_error: null
            };

        case DOMAIN_BULK_INFO_SUCCESS:
            return {
                ...state,
                domain_bulk_info_status: 'success',
                domain_bulk_info_data: action.domain_bulk_info_data
            };

        case DOMAIN_BULK_INFO_FAIL:
            return {
                ...state,
                domain_bulk_info_status: 'error',
                domain_bulk_info_error: action.domain_bulk_info_error,
                domain_bulk_info_meta: action.domain_bulk_info_meta
            };

        /**********************************************************************************************************
         *   BULK ACTION
         **********************************************************************************************************/
        case DOMAIN_BULK_ACTION_REQUEST:
            return {
                ...state,
                domain_bulk_action_status: 'loading',
                domain_bulk_action_data: null,
                domain_bulk_action_error: null
            };

        case DOMAIN_BULK_ACTION_SUCCESS:
            return {
                ...state,
                domain_bulk_action_status: 'success',
                domain_bulk_action_data: action.domain_bulk_action_data
            };

        case DOMAIN_BULK_ACTION_FAIL:
            return {
                ...state,
                domain_bulk_action_status: 'error',
                domain_bulk_action_error: action.domain_bulk_action_error
            };

        case DOMAIN_BULK_CONTACT_ACTION_REQUEST:
            return {
                ...state,
                domain_bulk_contact_action_status: 'loading',
                domain_bulk_contact_action_data: null
            };

        case DOMAIN_BULK_CONTACT_ACTION_SUCCESS:
            return {
                ...state,
                domain_bulk_contact_action_status: 'success',
                domain_bulk_contact_action_data: action.domain_bulk_contact_action_data
            };

        case DOMAIN_BULK_CONTACT_ACTION_FAIL:
            return {
                ...state,
                domain_bulk_contact_action_status: 'error'
            };

        default:
            return state;
    }
};

export default bulkReducer;

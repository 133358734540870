/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React from 'react';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { getTldValue } from 'components/Promo/FreeDomain/ClaimForm/methods';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import { RewardCard } from '../new';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import XyzText from 'assets/images/vipRewards/xyz-white-logo.svg';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type DotXYZReferralCardComponent = React.FC<{
    availabilityData: unknown;
}>;

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const DotXyzReferralCard: DotXYZReferralCardComponent = ({ availabilityData }) => {
    /***** RENDER HELPERS *****/
    const tldValue = getTldValue('.xyz', availabilityData);
    const description = `You're eligible for a FREE .xyz domain name registration valued at $${tldValue}.`;

    /***** RENDER *****/
    return (
        <RewardCard>
            <RewardCard.Image tag="FREE">
                <RewardCard.Image.Default className="rewardCard__bg--dotXyz" src={XyzText} alt=".xyz" />
            </RewardCard.Image>
            <RewardCard.Body title="Claim your FREE .xyz domain name!*" description={description}>
                <RewardCard.Button searchParam="free-domain=xyz">Claim Now</RewardCard.Button>
            </RewardCard.Body>
        </RewardCard>
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

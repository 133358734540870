/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import classNames from 'classnames';
import React from 'react';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import SolidButton from 'components/Buttons/SolidButton';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import './_validFormSubmitButton.scss';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * @type {React.FC<{
 *      children: React.ReactNode;
 *      onClick: () => void;
 *      disabled?: boolean;
 * }>}
 */
const ValidFormSubmitButtonControlled = ({ children, onClick, disabled }) => (
    <div className={classNames('ValidFormSubmitButton')}>
        <SolidButton type="onClick" onClick={onClick} disabled={disabled}>
            {children}
        </SolidButton>
    </div>
);

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

export { ValidFormSubmitButtonControlled };

/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { useQuery } from '@tanstack/react-query';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { API as ACCOUNT_API } from 'utilities/api/account';
import { handleDefaultErrorNotification } from 'utilities/methods/commonActions';
import { createQueryBoilerPlate } from 'utilities/methods/tanstack';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import { MINUTE } from 'utilities/consts';

/**
 * @deprecated use v5 format, see katanaQuery
 */
const useGetInvoiceBoilerPlate = createQueryBoilerPlate({
    service: 'billing',
    serviceKey: 'GET_INVOICE',
    APIRoute: (invoiceID) => () => ACCOUNT_API.user.invoice.single.GET(invoiceID),
    defaultOptions: {
        enabled: (invoiceID) => !!invoiceID,
        onError: () => handleDefaultErrorNotification,
        staleTime: MINUTE * 5
    }
});

const { getDefaults } = useGetInvoiceBoilerPlate;

/**********************************************************************************************************
 *   HOOK START
 **********************************************************************************************************/
/**
 * @deprecated use v5 format, see katanaQuery
 * Get Single Invoice
 */
function useGetInvoiceQuery(invoiceID, options = {}) {
    return useQuery({
        ...getDefaults(invoiceID),
        ...options
    });
}
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

export { useGetInvoiceBoilerPlate, useGetInvoiceQuery };

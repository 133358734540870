/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import _ from 'lodash';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { findValidationObjectByPath } from 'containers/katana/formFields/methods/validations/findValidationObjectByPath';
import { getFormFieldValidationValue } from 'containers/katana/formFields/methods/validations/getFormFieldValidationValue';

/**********************************************************************************************************
 *   TYPE IMPORTS
 **********************************************************************************************************/
import type { KatanaRuleParserValidationParams } from 'containers/katana/formFields/methods/validations/types';

export function getFormFieldIsRequired({ validation, formValues, property }: KatanaRuleParserValidationParams) {
    if (!(_.has(validation, 'value.0') && _.has(validation, 'value.1') && _.has(formValues, 'properties'))) {
        return;
    }

    const findObjectByPath = findValidationObjectByPath({ formValues, property });

    const validationKey: string = validation.value[0];
    const validationValue = validation?.value?.[1];

    // Because Laravel is silly we have to do a boolean or string manipulation here.
    const parsedValidationValue = getFormFieldValidationValue(validationValue);

    const foundObject = findObjectByPath(validation, validationKey);
    const foundValue = _.get(foundObject, validationKey);
    return Array.isArray(validationValue) ? validationValue.includes(foundValue) : foundValue === parsedValidationValue;
}

/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import store from 'store/store';

/**********************************************************************************************************
 *   FUNCTION START
 **********************************************************************************************************/
/**
 * Returns a boolean representing whether the user is authenticated or not. This is simply a light abstraction on the
 * app_user_data in the redux store.
 */
export function isAuthenticated() {
    return !!store.getState().app.app_user_data;
}

/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import type { Store } from '@tanstack/react-store';
import { useCallback, useMemo } from 'react';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { useTanstackStore } from 'utilities/hooks/tanstack-store/useTanstackStore';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type TReturnType<TState, TKey extends keyof TState> = {
    value: TState[TKey];
    set: (newValue: TState[TKey]) => void;
    update: (setterMethod: (e: TState[TKey]) => TState[TKey]) => void;
};

/**********************************************************************************************************
 *   HOOK START
 **********************************************************************************************************/
export function useTanstackStoreKey<TState, TKey extends keyof TState>(store: Store<TState>, key: TKey): TReturnType<TState, TKey> {
    /***** HOOKS *****/
    const [value, , updateStoreData] = useTanstackStore(store, (state) => state[key]);

    /***** FUNCTIONS *****/
    const setValue = useCallback(
        (newValue: TState[TKey]) => {
            updateStoreData((currentStoreData) => ({
                ...currentStoreData,
                [key]: newValue
            }));
        },
        [value]
    );

    const updateValue = useCallback(
        (setterMethod: (e: TState[TKey]) => TState[TKey]) => {
            updateStoreData((currentStoreData) => ({
                ...currentStoreData,
                [key]: setterMethod(currentStoreData[key])
            }));
        },
        [value]
    );

    /***** HOOK RESULTS *****/
    const hookResult = useMemo(
        () => ({
            value,
            set: setValue,
            update: updateValue
        }),
        [value, setValue, updateValue]
    );

    return hookResult;
}
/**********************************************************************************************************
 *   HOOK END
 **********************************************************************************************************/

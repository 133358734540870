/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React from 'react';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import './_sideBarChildMenuItemStyledLoader.scss';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
function _SideBarChildMenuItemStyledLoader() {
    /***** RENDER *****/
    return <div className="SideBarChildMenuItemStyledLoader" />;
}
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

export default _SideBarChildMenuItemStyledLoader;

/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { useStore, type Store } from '@tanstack/react-store';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type Updater<TState> = (currentState: NoInfer<TState>) => NoInfer<TState>;
type Selector<TState, TSelected = NoInfer<TState>> = (state: NoInfer<TState>) => TSelected;
type Result<TState, TSelected = NoInfer<TState>> = [
    state: TSelected,
    setState: (state: TState) => void,
    updateState: (updater: Updater<TState>) => void
];

/**********************************************************************************************************
 *   HOOK START
 **********************************************************************************************************/
//prettier-ignore
/**
 * A light abstraction on useStore from tanstack, that provides identical functionality to the original svelte/store hook
 * created in CCP. This should be used in existing code that used the svelte/store and for normal access, consider using
 * useStore instead.
 */
export const useTanstackStore = <
    TState, 
    TSelected = NoInfer<TState>
>(store: Store<TState, Updater<TState>>, selector?: Selector<TState, TSelected>): Result<TState, TSelected> => {
    /***** HOOKS *****/
    const state = useStore(store, selector);

    /***** HOOK RESULTS *****/
    return [
        state,
        (state) => store.setState(() => state),
        store.setState
    ];
};

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import NXBox from 'components/NXBox';
import Padding from 'components/Utils/Padding';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type NXBoxOverviewProps = {
    title: string;
    rows: Array<React.ReactNode>;
};

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * Renders a Box title, and a list of any UI, usually NXBoxOverviewRow
 */
export const NXBoxOverview: React.FC<NXBoxOverviewProps> = ({ title, rows }) => {
    /***** RENDER *****/
    return (
        <Padding x={6} y={5}>
            <div className="NXBoxOverview">
                <NXBox.Title title={title} />
                {rows}
            </div>
        </Padding>
    );
};

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

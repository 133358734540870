export const hostingTypes = {
    BUSINESS: 'Business Web Hosting',
    CUSTOM: 'Custom Web Hosting',
    WORDPRESS: 'WordPress Web Hosting'
};

export const planNames = {
    STARTER_PLUS: 'starter+',
    FREEDOM_PLUS: 'freedom+',
    PREMIER_PLUS: 'premier+',
    CUSTOM: 'custom',
    WORDPRESS: 'wordpress'
};

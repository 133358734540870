import { _CustomDropdown } from 'components/Dropdowns/CustomDropdown/_CustomDropdown';
import { _BillingCyclePrefab } from 'components/Dropdowns/CustomDropdown/BillingCyclePrefab';
import { _DefaultLabel } from 'components/Dropdowns/CustomDropdown/DefaultLabel';

const CustomDropdown = Object.assign(_CustomDropdown, {
    DefaultLabel: _DefaultLabel,
    BillingCyclePrefab: _BillingCyclePrefab
});

export { CustomDropdown };

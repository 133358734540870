import classNames from 'classnames';
import { _Text as Text } from 'components/Utils/Text/_Text';
import { createAppliedStylingClasses, removeKeysFromObject } from 'components/Utils/methods';
import _ from 'lodash';

/**********************************************************************************************************
 *   TYPE IMPORTS
 **********************************************************************************************************/
import type { NXUtils } from 'utilities/types';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import { TEXT_PROP_KEYS } from '../_Text/consts';

import type { TextProps } from 'components/Utils/Text/_Text/types';
import './__Heading.scss';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type HeadingProps = {
    h2?: true | NXUtils.Falsy;
    h4?: true | NXUtils.Falsy;
};

const { MEDIUM, SEMI_BOLD, BLACK, SIZE_M, SIZE_XXXL, LEAD_XS } = TEXT_PROP_KEYS;

const HEADINGS = {
    HEADING_2: 'h2',
    HEADING_4: 'h4'
};

const { HEADING_2, HEADING_4 } = HEADINGS;

const HEADING_TYPES_PROPS = {
    [HEADING_2]: [MEDIUM, BLACK, SIZE_XXXL, LEAD_XS],
    [HEADING_4]: [SEMI_BOLD, BLACK, SIZE_M]
};

const HeadingProps: HeadingProps = {
    /** Different Types of Headings */
    h2: true,
    h4: true
};
const HeadingPropsKeys = Object.keys(HeadingProps);

function createTruthyProps(propArray: Array<string>, props: Record<string, unknown>) {
    return propArray
        .filter((propKey) => !_.has(props, propKey))
        .reduce((obj, propKey) => {
            return {
                ...obj,
                [propKey]: 1
            };
        }, {});
}

function getTruthyPropsPropData(key: string, props: Record<string, unknown>) {
    return createTruthyProps(HEADING_TYPES_PROPS[key], props);
}

function getHeadingTextProps(props: Record<string, unknown>) {
    if (_.has(props, HEADING_2)) return getTruthyPropsPropData(HEADING_2, props);
    if (_.has(props, HEADING_4)) return getTruthyPropsPropData(HEADING_4, props);
    return {};
}

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type CombinedHeadingProps = TextProps & typeof HeadingProps;

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const _Heading: React.FC<CombinedHeadingProps> = (props) => {
    const { children, className } = props;

    const textProps = getHeadingTextProps(props);
    const strippedHeadingProps = removeKeysFromObject({ props, keysToRemove: HeadingPropsKeys });

    // Need to allow props to override existing values
    const propKeys = _.keys(props);
    const textKeys = _.keys(textProps);
    if (textKeys.some((key) => key.includes('lead')) && propKeys.some((key) => key.includes('lead'))) {
        const leadKey = textKeys.find((key) => key.includes('lead'));
        _.unset(textProps, leadKey);
    }

    const appliedStylingClasses = createAppliedStylingClasses({
        props,
        keyBoundary: HeadingPropsKeys,
        componentName: 'Heading',
        delimiter: '--'
    });

    const headingProps = {
        ...strippedHeadingProps,
        ...textProps,
        className: classNames(className, 'Heading', appliedStylingClasses)
    };

    function renderCorrectTag() {
        if (_.has(props, HEADING_2)) return <h2>{children}</h2>;
        if (_.has(props, HEADING_4)) return <h4>{children}</h4>;
    }

    return <Text {...headingProps}>{renderCorrectTag()}</Text>;
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

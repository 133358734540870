/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import classNames from 'classnames';
import React from 'react';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { createAppliedStylingClasses } from 'components/Utils/methods';

/**********************************************************************************************************
 *   TYPE IMPORTS
 **********************************************************************************************************/
import type { NXCardActions } from 'components/NXCard/_Actions/types';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import { NXCardActionsAppliedStylingPropTypeKeys } from 'components/NXCard/_Actions/types';
import './__NXCardActions.scss';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const _NXCardActions: React.FC<NXCardActions> = (props) => {
    const { children, className, onClick } = props;

    /***** RENDER HELPERS *****/
    const appliedStylingClasses = createAppliedStylingClasses({
        props,
        keyBoundary: NXCardActionsAppliedStylingPropTypeKeys,
        componentName: 'NXCardActions',
        delimiter: '--'
    });

    const nxCardActionsClassNames = classNames('NXCardActions', appliedStylingClasses, className);

    /***** RENDER *****/
    if (onClick)
        return (
            <button type="button" onClick={onClick} className={nxCardActionsClassNames}>
                {children}
            </button>
        );
    return <div className={nxCardActionsClassNames}>{children}</div>;
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { QueryClient } from '@tanstack/react-query';
import { MINUTE } from 'utilities/consts';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { getDataFromData, handleDefaultErrorNotification, handleDefaultSuccessNotification } from 'utilities/methods/commonActions';

/**
 * Initialise React Query Client
 */
const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
            retry: false,
            /**
             * By Default the delay should cap out at 6 times exponential, around 32 seconds per retry attempt
             * @param {number} attempt
             */
            retryDelay: (attempt) => Math.min(500 * 2 ** attempt, 500 * 2 ** 6),
            select: getDataFromData,
            staleTime: MINUTE * 5
        },
        mutations: {
            onError: handleDefaultErrorNotification,
            onSuccess: handleDefaultSuccessNotification
        }
    }
});

export default queryClient;

/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { createRoute } from '@tanstack/react-router';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import PageNotFound from 'components/Errors/PageNotFound';

/**********************************************************************************************************
 *   ROUTE IMPORTS
 **********************************************************************************************************/
import { AccountRoute } from 'containers/account';
import { routerMiddleware } from 'router/utils/middleware';
import { lazyFN } from 'utilities/methods/tanstack/router/lazyFn';
import { z } from 'zod';

const searchSchema = z.object({
    open: z.coerce.boolean().optional()
});

/**********************************************************************************************************
 *   ROUTE START
 **********************************************************************************************************/
export const AccountSecurityRoute = createRoute({
    getParentRoute: () => AccountRoute,
    path: 'security',
    notFoundComponent: PageNotFound,
    beforeLoad(opts) {
        routerMiddleware.business(this, opts);
        routerMiddleware.authentication('user', opts);
    },
    validateSearch: searchSchema
}).lazy(lazyFN(() => import('./security.lazy'), 'LazyAccountSecurityRoute'));

/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React from 'react';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import { SocialsLoginCaveat } from 'containers/katana/formFields/caveats/SocialsLoginCaveat';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { useGetCaveat } from 'containers/katana/formFields/methods/useGetCaveat';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import { CaveatTypes } from 'containers/katana/formFields/caveatTypes';

/**********************************************************************************************************
 *   TYPE IMPORTS
 **********************************************************************************************************/
import type { KatanaNamespace } from 'containers/katana/types';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const SocialsLoginCaveatCondition: React.FC<{ property: KatanaNamespace.SectionDefinitions.PropertiesModified }> = ({ property }) => {
    const { caveats } = property;

    /***** HOOKS *****/
    const socialLoginCaveat = useGetCaveat(CaveatTypes.SOCIAL_LOGIN, caveats);

    /***** RENDER *****/
    if (!socialLoginCaveat) {
        return null;
    }

    return <SocialsLoginCaveat socialType={socialLoginCaveat} property={property} />;
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

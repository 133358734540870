import _ from 'lodash';
import { createOptimisticFilterRestoreMethod } from 'utilities/methods/tanstack/createOptimisticFilterRestoreMethod';
import type { NXQueryUtils } from 'utilities/methods/tanstack/types';
import type { NXUtils } from 'utilities/NXUtils';

/**
 * Creates an optimistic filter method for modifying query cache data by filtering out entries based on a provided predicate at a specified path.
 *
 * Removed entries can be restored using the returned restore method, useful if an error occurs or the filtered data needs to be reverted.
 */
export function createOptimisticFilterMethod<TParams extends any, TData extends NXQueryUtils.ApiData>(
    setQueryData: NXQueryUtils.SetQueryDataMethod<TParams, TData>
) {
    function optimisticFilter<
        TKey extends NXUtils.Path<TData>,
        TFilterValue extends NXUtils.Choose<TData, TKey> extends Array<any> ? NXUtils.Choose<TData, TKey>[number] : never
    >(params: TParams, filterPath: TKey, filterPredicate: (value: TFilterValue) => boolean) {
        const previousEntriesDictionary: Record<number, TFilterValue> = {};

        setQueryData(params, (oldData) => {
            if (oldData?.status !== 200) {
                return;
            }

            if (!_.has(oldData, filterPath)) {
                return;
            }

            const clonedData = _.cloneDeep(oldData);

            const value = _.get(clonedData, filterPath);
            if (!_.isArray(value)) {
                return;
            }

            const filteredValue = value.filter((valueEntry: TFilterValue, i) => {
                const filterResult = filterPredicate(valueEntry);
                // If the filter result is negative that means we're removing the data from the array, we need to store this somewhere to be able to add it back in
                if (!filterResult) {
                    previousEntriesDictionary[i] = valueEntry;
                }
                return filterResult;
            });

            _.set(clonedData, filterPath, filteredValue);

            return clonedData;
        });

        // Restore the removed entries
        return {
            restore: createOptimisticFilterRestoreMethod(setQueryData, params, filterPath, previousEntriesDictionary)
        };
    }
    return optimisticFilter;
}

/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React from 'react';
import classNames from 'classnames';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import './_SelectOption.scss';

export type SelectOptionProps = {
    label: React.ReactNode;
    /**
     * The option value
     */
    value: string;
    /**
     * Whether or not the option is currently active
     */
    isActive: boolean;
    /**
     * Function to call when option is selected
     */
    onSelect: () => void;
};

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
function SelectOption({ label, value, isActive, onSelect }: SelectOptionProps) {
    /***** RENDER *****/
    return (
        <li className={classNames('SelectOption', { 'SelectOption--active': isActive })}>
            <button
                onClick={() => {
                    onSelect();
                }}
            >
                {label || value}
            </button>
        </li>
    );
}
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

export default SelectOption;

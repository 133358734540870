/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { Icon } from '@iconify-icon/react';
import classNames from 'classnames';
import React from 'react';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { registerIconsForPreload } from 'components/Icons/methods';
import { createAppliedStylingClassesWithImportant } from 'components/Utils/methods';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import { phosphorIconPropKeys } from 'components/Icons/Phosphor/consts';
import './_IconifyIcon.scss';

/**********************************************************************************************************
 *   TYPE IMPORTS
 **********************************************************************************************************/
import type { IconifyIconNamespace } from 'components/Icons/types';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
const IconifyIcon: React.FC<IconifyIconNamespace.Props> = (props) => {
    const { className, size = 20, icon, svgProps } = props;

    /***** RENDER HELPERS *****/
    const appliedStylingClasses = createAppliedStylingClassesWithImportant({
        props,
        keyBoundary: phosphorIconPropKeys,
        componentName: 'Icon',
        delimiter: '--'
    });
    const _IconClassNames = classNames('Icon', `Icon--${icon}`, appliedStylingClasses, className, svgProps?.className);

    /***** RENDER *****/
    return <Icon icon={icon} {...svgProps} className={_IconClassNames} width={size} height={size} />;
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

/**
 * If loading the names and preloading ends up causing display issues we can always resort to manual import of the svg data using icon data:
 * @link https://www.npmjs.com/package/@iconify-icon/react#using-icon-data
 */
const Iconify = (icon: string) => {
    const IconifyIconWrapper: React.FC<IconifyIconNamespace.Props> = (props) => {
        return <IconifyIcon icon={icon} {...props} />;
    };

    registerIconsForPreload(icon);

    return IconifyIconWrapper;
};

export { Iconify, IconifyIcon };

// Function to calculate the average brightness of an image
export function calculateAverageBrightness(imageData) {
    const data = imageData.data;

    // Calculate the total brightness and total alpha values
    let totalBrightness = 0;
    let totalAlpha = 0;
    for (let i = 0; i < data.length; i += 4) {
        const alpha = data[i + 3]; // Alpha value (transparency)
        const brightness = (data[i] + data[i + 1] + data[i + 2]) / 3; // Average brightness

        // Weight the brightness by the alpha value
        totalBrightness += brightness * alpha;
        totalAlpha += alpha;
    }

    // Calculate the weighted average brightness
    const averageBrightness = totalBrightness / totalAlpha;

    return averageBrightness;
}

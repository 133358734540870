import axios from 'axios';

const CancelToken = axios.CancelToken;

export let cancelGetBillingCycle;
export let cancelGetSolusVPSInfo;
export let cancelGetAstroVPSInfo;
export let cancelReverseDNS;

export const API = {
    getSingleVPSDetails: (id) => {
        return axios.get(`/api/vps/${id}`);
    },

    astroVPSInfo: (id) => {
        return axios({
            method: 'get',
            url: `/api/vps/${id}/astro/info`,
            cancelToken: new CancelToken(function executor(c) {
                cancelGetAstroVPSInfo = c;
            })
        });
    },

    solusVPSInfo: (id) => {
        return axios({
            method: 'get',
            url: `/api/vps/${id}/solus/info`,
            cancelToken: new CancelToken(function executor(c) {
                cancelGetSolusVPSInfo = c;
            })
        });
    },

    getUserInfo: (id) => {
        return axios.get(`/api/vps/${id}/user-info`);
    },

    /**********************************************************************************************************
     *   ACCOUNT API
     **********************************************************************************************************/
    getBillingCycles: (id) => {
        return axios({
            method: 'get',
            url: `/api/vps/${id}/billing-cycle`,
            cancelToken: new CancelToken(function executor(c) {
                cancelGetBillingCycle = c;
            })
        });
    },

    updateBillingCycle: (id, attributes) => {
        return axios.put(`/api/vps/${id}/billing-cycle`, {
            type: 'vps',
            id,
            attributes
        });
    },

    submitServerJob: (id, details) => {
        return axios.post(`/api/vps/${id}/server-job/submit`, {
            attributes: {
                details
            }
        });
    },

    getWHMLink: (id) => {
        return axios.get(`/api/vps/${id}/whm/login`);
    },

    products: (type) => {
        return axios.get(`/api/web-hosting/product/list/${type}`);
    },

    upgrade: (id, attributes) => {
        return axios.post(`/api/vps/${id}/upgrade`, {
            type: 'vps-upgrade',
            id: id,
            attributes
        });
    },

    powerState: (id) => {
        return axios.get(`/api/vps/${id}/power`);
    },

    shutdown: (id) => {
        return axios.post(`/api/vps/${id}/power/shutdown`);
    },

    reboot: (id) => {
        return axios.post(`/api/vps/${id}/power/reboot`);
    },

    startup: (id) => {
        return axios.post(`/api/vps/${id}/power/startup`);
    },

    getVNCInfo: (id) => {
        return axios.get(`/api/vps/${id}/vnc`);
    },

    createAstroVNC: (id) => {
        return axios.get(`/api/vps/${id}/astro/vnc/session`);
    },

    changeVNCPassword: (id, attributes) => {
        return axios.post(`/api/vps/${id}/vnc/change-password`, {
            type: 'vps',
            id,
            attributes
        });
    },

    getReverseDNS: (id) => {
        return axios({
            method: 'get',
            url: `/api/vps/${id}/ptr`,
            cancelToken: new CancelToken(function executor(c) {
                cancelReverseDNS = c;
            })
        });
    },

    getLegacyAddon: (id) => {
        return axios.get(`/api/vps/${id}/addon/legacy`);
    },

    updateReverseDNS: (id, attributes) => {
        return axios.post(`/api/vps/${id}/ptr`, {
            id,
            type: 'vps',
            attributes
        });
    },

    getOperatingSystemList: (id) => {
        return axios.get(`/api/vps/${id}/os-list`);
    },

    formatVPS: (id, template) => {
        return axios.post(`/api/vps/${id}/format`, {
            attributes: {
                template
            }
        });
    },

    cancelVPS: (id, attributes) => {
        return axios.post(`/api/vps/${id}/cancel/service`, {
            attributes
        });
    },

    removeCancelVPS: (id) => {
        return axios.post(`/api/vps/${id}/cancel/request`);
    },

    getAddons: (id) => {
        return axios.get(`/api/vps/${id}/addon`);
    },

    cancelAddon: (id, attributes) => {
        return axios.post(`/api/vps/${id}/addon/cancel`, {
            id,
            type: 'vps-addon',
            attributes
        });
    },

    getHardwareAddonPricing: (id) => {
        return axios.get(`/api/vps/${id}/addon/pricing/hardware`);
    },

    getSoftwareAddonPricing: (id) => {
        return axios.get(`/api/vps/${id}/addon/pricing/software`);
    },

    addHardwareAddon: (id, attributes) => {
        return axios.post(`/api/vps/${id}/addon/hardware`, {
            type: 'vps-addon-order',
            attributes
        });
    },

    addSoftwareAddon: (id, attributes) => {
        return axios.post(`/api/vps/${id}/addon/software`, {
            type: 'vps-addon-order',
            attributes
        });
    }
};

/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import classNames from 'classnames';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import './_SimpleTable.scss';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type SimpleTableProps = {
    /**
     * the row that show the content of the table which must include a key i.e.:
     * [<p key="foo">foo</p>,<p key="bar">bar</p>]
     */
    rows: React.ReactNode[];
    alignRowItems?: 'start';
    className?: string;
};

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
const SimpleTable: React.FC<SimpleTableProps> = (props) => {
    const { rows, alignRowItems, className } = props;

    /*  RENDER COMPONENT
     **********************************************************************************************************/
    return (
        <ul
            className={classNames(
                'SimpleTable',
                {
                    [`SimpleTable--align-row-items--${alignRowItems}`]: alignRowItems
                },
                className
            )}
        >
            {rows.map((columns, i) => {
                return (
                    <li key={i} className="SimpleTable__row">
                        {columns}
                    </li>
                );
            })}
        </ul>
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

export default SimpleTable;

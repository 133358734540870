/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import SideBarChildMenu from 'containers/katana/containers/ContentEditorLightbox/contentEditorSidebar/sideBarChildMenu';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { useKatanaParams } from 'containers/katana/hooks/useSetupEditorRouteParams';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import { katanaSubPageEnums } from 'containers/katana/consts';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
import type { NSideBarChildMenu } from 'containers/katana/containers/ContentEditorLightbox/contentEditorSidebar/types';
import { useKatanaSubpage } from 'containers/katana/hooks/useKatanaSubpage';
import type { KatanaSetupEditorRouteParams } from 'containers/katana/types';

const { CONTACT_INFORMATION, BASIC_DETAILS } = katanaSubPageEnums;

const menuRestrictions: Array<KatanaSetupEditorRouteParams['subpage']> = ['contact-information', 'basic-details'];

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
const BasicDetailsMenu = () => {
    /***** HOOKS *****/
    const { childPage } = useKatanaParams();
    const subpage = useKatanaSubpage();

    /***** RENDER HELPERS *****/
    const childMenuOptions: NSideBarChildMenu.Option[] = [
        {
            routeKey: BASIC_DETAILS,
            title: 'Website Information',
            identifier: subpage
        },
        {
            routeKey: CONTACT_INFORMATION,
            title: 'Contact Information',
            identifier: subpage
        }
    ];

    /***** RENDER *****/
    return childPage || menuRestrictions.includes(subpage) ? <SideBarChildMenu options={childMenuOptions} isLoading={false} /> : null;
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
export default BasicDetailsMenu;

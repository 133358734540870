/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React from 'react';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import './_stickyButtonWrapper.scss';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
const StickyButtonWrapper = ({ children }) => <div className="StickyButtonWrapper">{children}</div>;
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
export default StickyButtonWrapper;

import { _AcknowledgementBox } from 'components/AcknowledgementBox/_AcknowledgementBox';
import { _CompactAcknowledgementBox } from 'components/AcknowledgementBox/_CompactAcknowledgementBox';
import { _AcknowledgementBoxFlushBox } from 'components/AcknowledgementBox/_FlushBox';

const AcknowledgementBox = Object.assign(_AcknowledgementBox, {
    FlushBox: _AcknowledgementBoxFlushBox,
    Compact: _CompactAcknowledgementBox
});

export { AcknowledgementBox };

/**********************************************************************************************************
 *   BASE IMPORTS
 **********************************************************************************************************/
import store from 'store/store';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { API, cancelCalculateCashOut, cancelGetSLAEvents, cancelGetSLAServices, cancelGetWalletTransactions } from 'utilities/api/account';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import { pushNotification } from 'components/Toast/functions';
import { getDataFromSuccessResponse, getErrorFromFailResponse, getMetaFromSuccessResponse } from 'utilities/methods/commonActions';

/**********************************************************************************************************
 *   DECLARATIONS
 **********************************************************************************************************/
export const ACCOUNT_SERVICES_ALIGNMENT_REQUEST = 'account/ACCOUNT_SERVICES_ALIGNMENT_REQUEST';
export const ACCOUNT_SERVICES_ALIGNMENT_SUCCESS = 'account/ACCOUNT_SERVICES_ALIGNMENT_SUCCESS';
export const ACCOUNT_SERVICES_ALIGNMENT_ERROR = 'account/ACCOUNT_SERVICES_ALIGNMENT_ERROR';
export const ACCOUNT_SERVICES_ALIGNMENT_PREVIEW_REQUEST = 'account/ACCOUNT_SERVICES_ALIGNMENT_PREVIEW_REQUEST';
export const ACCOUNT_SERVICES_ALIGNMENT_PREVIEW_SUCCESS = 'account/ACCOUNT_SERVICES_ALIGNMENT_PREVIW_SUCCESS';
export const ACCOUNT_SERVICES_ALIGNMENT_PREVIEW_ERROR = 'account/ACCOUNT_SERVICES_ALIGNMENT_PREVIEW_ERROR';
export const ACCOUNT_SERVICES_ALIGN_REQUEST = 'account/ACCOUNT_SERVICES_ALIGN_REQUEST';
export const ACCOUNT_SERVICES_ALIGN_SUCCESS = 'account/ACCOUNT_SERVICES_ALIGN_SUCCESS';
export const ACCOUNT_SERVICES_ALIGN_ERROR = 'account/ACCOUNT_SERVICES_ALIGN_ERROR';
export const ACCOUNT_SLA_CLAIM_SERVICES_REQUEST = 'account/ACCOUNT_SLA_CLAIM_SERVICES_REQUEST';
export const ACCOUNT_SLA_CLAIM_SERVICES_SUCCESS = 'account/ACCOUNT_SLA_CLAIM_SERVICES_SUCCESS';
export const ACCOUNT_SLA_CLAIM_SERVICES_ERROR = 'account/ACCOUNT_SLA_CLAIM_SERVICES_ERROR';
export const ACCOUNT_SLA_CLAIM_EVENTS_REQUEST = 'account/ACCOUNT_SLA_CLAIM_EVENTS_REQUEST';
export const ACCOUNT_SLA_CLAIM_EVENTS_SUCCESS = 'account/ACCOUNT_SLA_CLAIM_EVENTS_SUCCESS';
export const ACCOUNT_SLA_CLAIM_EVENTS_ERROR = 'account/ACCOUNT_SLA_CLAIM_EVENTS_ERROR';
export const ACCOUNT_SLA_CLAIM_SUBMIT_REQUEST = 'account/ACCOUNT_SLA_CLAIM_SUBMIT_REQUEST';
export const ACCOUNT_SLA_CLAIM_SUBMIT_SUCCESS = 'account/ACCOUNT_SLA_CLAIM_SUBMIT_SUCCESS';
export const ACCOUNT_SLA_CLAIM_SUBMIT_ERROR = 'account/ACCOUNT_SLA_CLAIM_SUBMIT_ERROR';

export const ACCOUNT_WALLET_REQUEST = 'account/ACCOUNT_WALLET_REQUEST';
export const ACCOUNT_WALLET_SUCCESS = 'account/ACCOUNT_WALLET_SUCCESS';
export const ACCOUNT_WALLET_ERROR = 'account/ACCOUNT_WALLET_ERROR';

export const ACCOUNT_WALLET_TRANSACTIONS_REQUEST = 'account/ACCOUNT_WALLET_TRANSACTIONS_REQUEST';
export const ACCOUNT_WALLET_TRANSACTIONS_SUCCESS = 'account/ACCOUNT_WALLET_TRANSACTIONS_SUCCESS';
export const ACCOUNT_WALLET_TRANSACTIONS_ERROR = 'account/ACCOUNT_WALLET_TRANSACTIONS_ERROR';

export const ACCOUNT_WALLET_TRANSACTIONS_SEARCH_REQUEST = 'account/ACCOUNT_WALLET_TRANSACTIONS_SEARCH_REQUEST';
export const ACCOUNT_WALLET_TRANSACTIONS_SEARCH_SUCCESS = 'account/ACCOUNT_WALLET_TRANSACTIONS_SEARCH_SUCCESS';
export const ACCOUNT_WALLET_TRANSACTIONS_SEARCH_ERROR = 'account/ACCOUNT_WALLET_TRANSACTIONS_SEARCH_ERROR';
export const ACCOUNT_WALLET_TRANSACTIONS_SEARCH_RESET = 'account/ACCOUNT_WALLET_TRANSACTIONS_SEARCH_RESET';

export const ACCOUNT_WALLET_ADD_CREDIT_REQUEST = 'account/ACCOUNT_WALLET_ADD_CREDIT_REQUEST';
export const ACCOUNT_WALLET_ADD_CREDIT_SUCCESS = 'account/ACCOUNT_WALLET_ADD_CREDIT_SUCCESS';
export const ACCOUNT_WALLET_ADD_CREDIT_ERROR = 'account/ACCOUNT_WALLET_ADD_CREDIT_ERROR';

export const ACCOUNT_CALCULATE_WALLET_CASH_OUT_REQUEST = 'account/ACCOUNT_CALCULATE_WALLET_CASH_OUT_REQUEST';
export const ACCOUNT_CALCULATE_WALLET_CASH_OUT_SUCCESS = 'account/ACCOUNT_CALCULATE_WALLET_CASH_OUT_SUCCESS';
export const ACCOUNT_CALCULATE_WALLET_CASH_OUT_ERROR = 'account/ACCOUNT_CALCULATE_WALLET_CASH_OUT_ERROR';

export const ACCOUNT_WALLET_CASH_OUT_REQUEST = 'account/ACCOUNT_WALLET_CASH_OUT_REQUEST';
export const ACCOUNT_WALLET_CASH_OUT_SUCCESS = 'account/ACCOUNT_WALLET_CASH_OUT_SUCCESS';
export const ACCOUNT_WALLET_CASH_OUT_ERROR = 'account/ACCOUNT_WALLET_CASH_OUT_ERROR';

/**********************************************************************************************************
 *   ACTIONS - BILLING
 **********************************************************************************************************/
export const getServicesAlignment = () => {
    return (dispatch) => {
        dispatch({
            type: ACCOUNT_SERVICES_ALIGNMENT_REQUEST
        });
        API.account.GET.service
            .align()
            .then((response) => {
                const account_services_alignment_data = getDataFromSuccessResponse(response);
                dispatch({
                    type: ACCOUNT_SERVICES_ALIGNMENT_SUCCESS,
                    account_services_alignment_data
                });
            })
            .catch((error) => {
                const account_services_alignment_error = getErrorFromFailResponse(error);
                dispatch({
                    type: ACCOUNT_SERVICES_ALIGNMENT_ERROR,
                    account_services_alignment_error
                });
            });
    };
};

export const previewAlignServices = (attributes) => {
    return (dispatch) => {
        dispatch({
            type: ACCOUNT_SERVICES_ALIGNMENT_PREVIEW_REQUEST
        });
        API.account.POST.service
            .alignPreview(attributes)
            .then((response) => {
                const account_services_alignment_preview_data = getDataFromSuccessResponse(response);
                dispatch({
                    type: ACCOUNT_SERVICES_ALIGNMENT_PREVIEW_SUCCESS,
                    account_services_alignment_preview_data
                });
            })
            .catch((error) => {
                const account_services_alignment_preview_error = getErrorFromFailResponse(error);
                dispatch({
                    type: ACCOUNT_SERVICES_ALIGNMENT_PREVIEW_ERROR,
                    account_services_alignment_preview_error
                });
                pushNotification(account_services_alignment_preview_error);
            });
    };
};

export const alignServices = (attributes) => {
    return (dispatch) => {
        dispatch({
            type: ACCOUNT_SERVICES_ALIGN_REQUEST
        });
        API.account.POST.service
            .align(attributes)
            .then((response) => {
                const account_services_align_data = getDataFromSuccessResponse(response);
                dispatch({
                    type: ACCOUNT_SERVICES_ALIGN_SUCCESS,
                    account_services_align_data
                });
                dispatch(getServicesAlignment());
            })
            .catch((error) => {
                const account_services_align_error = getErrorFromFailResponse(error);
                pushNotification(account_services_align_error);
                dispatch({
                    type: ACCOUNT_SERVICES_ALIGN_ERROR,
                    account_services_align_error
                });
            });
    };
};

export const getSLAClaimServices = (event_id) => {
    return (dispatch) => {
        if (cancelGetSLAServices) {
            cancelGetSLAServices();
        }
        dispatch({
            type: ACCOUNT_SLA_CLAIM_SERVICES_REQUEST
        });
        API.account.GET.sla
            .services(event_id)
            .then((response) => {
                const account_sla_claim_services_data = getDataFromSuccessResponse(response);
                dispatch({
                    type: ACCOUNT_SLA_CLAIM_SERVICES_SUCCESS,
                    account_sla_claim_services_data
                });
            })
            .catch((error) => {
                const account_sla_claim_services_error = getErrorFromFailResponse(error);
                dispatch({
                    type: ACCOUNT_SLA_CLAIM_SERVICES_ERROR,
                    account_sla_claim_services_error
                });
            });
    };
};

export const getSLAClaimEvents = () => {
    const { dispatch } = store;
    if (cancelGetSLAEvents) {
        cancelGetSLAEvents();
    }
    dispatch({
        type: ACCOUNT_SLA_CLAIM_EVENTS_REQUEST
    });
    API.account.GET.sla
        .event()
        .then((response) => {
            const account_sla_claim_events_data = getDataFromSuccessResponse(response);
            dispatch({
                type: ACCOUNT_SLA_CLAIM_EVENTS_SUCCESS,
                account_sla_claim_events_data
            });
        })
        .catch((error) => {
            const account_sla_claim_events_error = getErrorFromFailResponse(error);
            dispatch({
                type: ACCOUNT_SLA_CLAIM_EVENTS_ERROR,
                account_sla_claim_events_error
            });
        });
};

export const submitSLAClaim = (attributes) => {
    const { dispatch } = store;
    dispatch({
        type: ACCOUNT_SLA_CLAIM_SUBMIT_REQUEST
    });
    API.account.POST.claim(attributes)
        .then((response) => {
            const account_sla_claim_submit_data = getDataFromSuccessResponse(response);
            pushNotification(account_sla_claim_submit_data);
            dispatch({
                type: ACCOUNT_SLA_CLAIM_SUBMIT_SUCCESS,
                account_sla_claim_submit_data
            });
        })
        .catch((error) => {
            const account_sla_claim_submit_error = getErrorFromFailResponse(error);
            pushNotification(account_sla_claim_submit_error);
            dispatch({
                type: ACCOUNT_SLA_CLAIM_SUBMIT_ERROR,
                account_sla_claim_submit_error
            });
        });
};

/**********************************************************************************************************
 *   WALLET BALANCES
 **********************************************************************************************************/
export const getWalletBalances = () => {
    return (dispatch) => {
        dispatch({ type: ACCOUNT_WALLET_REQUEST });
        API.account.GET.wallet
            .balances()
            .then((response) => {
                const account_wallet_data = getDataFromSuccessResponse(response);
                dispatch({
                    type: ACCOUNT_WALLET_SUCCESS,
                    account_wallet_data
                });
            })
            .catch((error) => {
                dispatch({ type: ACCOUNT_WALLET_ERROR });
            });
    };
};

/**********************************************************************************************************
 *   WALLET TRANSACTIONS
 **********************************************************************************************************/
export const getWalletTransactions = (params, prevPage) => {
    if (cancelGetWalletTransactions) cancelGetWalletTransactions();

    return (dispatch) => {
        dispatch({ type: ACCOUNT_WALLET_TRANSACTIONS_REQUEST });
        API.account.GET.wallet
            .transactions(params)
            .then((response) => {
                const account_wallet_transactions_data = getDataFromSuccessResponse(response);
                const account_wallet_transactions_meta = getMetaFromSuccessResponse(response);

                dispatch({
                    type: ACCOUNT_WALLET_TRANSACTIONS_SUCCESS,
                    account_wallet_transactions_data: prevPage
                        ? [...prevPage, ...account_wallet_transactions_data]
                        : account_wallet_transactions_data,
                    account_wallet_transactions_meta
                });
            })
            .catch((error) => {
                if (error.response) dispatch({ type: ACCOUNT_WALLET_TRANSACTIONS_ERROR });
            });
    };
};

/**********************************************************************************************************
 *   WALLET TRANSACTIONS SEARCH
 **********************************************************************************************************/
export const searchWalletTransactions = (type, term) => {
    return (dispatch) => {
        dispatch({ type: ACCOUNT_WALLET_TRANSACTIONS_SEARCH_REQUEST });
        API.account.GET.wallet
            .searchTransactions(type, term)
            .then((response) => {
                const account_wallet_transactions_search_data = getDataFromSuccessResponse(response);
                dispatch({
                    type: ACCOUNT_WALLET_TRANSACTIONS_SEARCH_SUCCESS,
                    account_wallet_transactions_search_data
                });
            })
            .catch((error) => {
                if (error.response) dispatch({ type: ACCOUNT_WALLET_TRANSACTIONS_SEARCH_ERROR });
            });
    };
};

/**********************************************************************************************************
 *   WALLET ADD CREDIT
 **********************************************************************************************************/
export const walletAddCredit = (payload) => {
    return (dispatch) => {
        dispatch({ type: ACCOUNT_WALLET_ADD_CREDIT_REQUEST });
        API.account.POST.wallet
            .addCredit(payload)
            .then((response) => {
                const account_wallet_add_credit_data = getDataFromSuccessResponse(response);
                pushNotification(account_wallet_add_credit_data);
                dispatch({
                    type: ACCOUNT_WALLET_ADD_CREDIT_SUCCESS,
                    account_wallet_add_credit_data
                });
            })
            .catch((error) => {
                pushNotification(getErrorFromFailResponse(error));
                dispatch({ type: ACCOUNT_WALLET_ADD_CREDIT_ERROR });
            });
    };
};

/**********************************************************************************************************
 *   WALLET CASH OUT
 **********************************************************************************************************/
export const calculateWalletCashOut = (type = 'add-credit', customAmount, validRequest) => {
    return (dispatch) => {
        if (cancelCalculateCashOut) cancelCalculateCashOut();
        if (!validRequest) return;
        dispatch({ type: ACCOUNT_CALCULATE_WALLET_CASH_OUT_REQUEST });
        API.account.GET.wallet
            .cashOut(type, customAmount)
            .then((response) => {
                const account_calculate_wallet_cash_out_data = getDataFromSuccessResponse(response);
                dispatch({
                    type: ACCOUNT_CALCULATE_WALLET_CASH_OUT_SUCCESS,
                    account_calculate_wallet_cash_out_data
                });
            })
            .catch((error) => {
                if (error.response) {
                    pushNotification(getErrorFromFailResponse(error));
                    dispatch({ type: ACCOUNT_CALCULATE_WALLET_CASH_OUT_ERROR });
                }
            });
    };
};

export const walletCashOut = (payload) => {
    return (dispatch) => {
        dispatch({ type: ACCOUNT_WALLET_CASH_OUT_REQUEST });
        API.account.POST.wallet
            .cashOut(payload)
            .then((response) => {
                const account_wallet_cash_out_data = getDataFromSuccessResponse(response);
                pushNotification(account_wallet_cash_out_data);
                dispatch({
                    type: ACCOUNT_WALLET_CASH_OUT_SUCCESS,
                    account_wallet_cash_out_data
                });
            })
            .catch((error) => {
                pushNotification(getErrorFromFailResponse(error));
                dispatch({ type: ACCOUNT_WALLET_CASH_OUT_ERROR });
            });
    };
};

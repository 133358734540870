/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React from 'react';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import { PhosphorIcons } from 'components/Icons/Phosphor';
import { Flex } from 'components/Utils/Flex';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import './_bigPlus.scss';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
const BigPlus = () => (
    <Flex justify="center" items="center" inject>
        <div className="BigPlus">
            <PhosphorIcons.Plus.Bold size={48} white />
        </div>
    </Flex>
);
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
export default BigPlus;

/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React from 'react';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
interface ISubsetProps<TData> {
    children: (data: TData, index?: number, array?: TData[]) => React.ReactNode;
    data?: TData[];
    range: Readonly<[number, number] | [number] | [undefined | null, number] | [number, undefined | null]>;
}

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * Renders a subset of the data passed to this component by invoking the children. This means that the subset & map step can
 * be combined and invoked with a simple JSX syntax.
 */
export const Subset = <TData,>({ children, range, data }: ISubsetProps<TData>) => {
    const [start, end] = range;

    /***** RENDER *****/
    return <>{data?.slice(start ?? 0, Number(end ?? undefined) < data.length ? end ?? undefined : data.length)?.map(children)}</>;
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { useEffect } from 'react';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import { CollectMissingDetailsContent } from './content';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { checkIsAccountHolder } from 'containers/account/methods';
import { useCheckTokenData } from 'utilities/hooks/redux/useCheckTokenData';
import { useIsVPN } from 'utilities/hooks/redux/useIsVPN';
import { useUserData } from 'utilities/hooks/redux/useUserData';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type CollectMissingDetailsCheck = React.FC<CollectMissingDetailsCheckProps>;
type CollectMissingDetailsCheckProps = {
    markSelfAsRequired: () => void;
};

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * Runs the logic to see whether the user is required to complete the missing details form, and marks itself as "required" if so
 */
export const CollectMissingDetailsCheck: CollectMissingDetailsCheck = ({ markSelfAsRequired }) => {
    /***** HOOKS *****/
    const app_user_data = useUserData();
    const isVPN = useIsVPN();
    const { is_security_detail_completed, is_user_detail_completed, do_detail_completed_check } = useCheckTokenData();

    /***** EFFECTS *****/
    useEffect(() => {
        if (isVPN || !checkIsAccountHolder(app_user_data)) return;
        const isMissingDetailsCollectionRequired = do_detail_completed_check && (!is_security_detail_completed || !is_user_detail_completed);
        if (isMissingDetailsCollectionRequired) {
            markSelfAsRequired();
        }
    }, []);

    /***** RENDER *****/
    return null;
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

export { CollectMissingDetailsContent };

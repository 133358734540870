/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { z } from 'zod';

/**********************************************************************************************************
 *   TYPE IMPORTS
 **********************************************************************************************************/
import type { AnyRouteId } from 'router/types';

export const routeConfigSchema = z.object({
    getParentRoute: z.function(),
    path: z.string().optional(),
    id: z.string().optional()
});

export const parentConfigSchema = z
    .union([
        z.object({
            id: z.string()
        }),
        z.object({
            fullPath: z.string()
        })
    ])
    .transform((data) => {
        if ('fullPath' in data) {
            return data.fullPath as AnyRouteId;
        } else {
            return data.id as AnyRouteId;
        }
    });

import _NXTableFooter from 'components/NXTable/Footer/_NXTableFooter';
import _NXTableFooterTanstackListShowButton from 'components/NXTable/Footer/wrappers/Tanstack/ListShowButton';

const NXTableFooter = Object.assign(_NXTableFooter, {
    Tanstack: {
        ListShowButton: _NXTableFooterTanstackListShowButton
    }
});

export default NXTableFooter;

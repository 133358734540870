import {
    GSUITE_INITIAL_STATE,
    GSUITE_CURRENT_STEP,
    GSUITE_SERVICE_REQUEST,
    GSUITE_SERVICE_SUCCESS,
    GSUITE_SERVICE_ERROR,
    GSUITE_MAILBOX_REQUEST,
    GSUITE_MAILBOX_SUCCESS,
    GSUITE_MAILBOX_ERROR,
    GSUITE_DELETE_MAILBOX_REQUEST,
    GSUITE_DELETE_MAILBOX_SUCCESS,
    GSUITE_DELETE_MAILBOX_ERROR,
    GSUITE_LOGIN_REQUEST,
    GSUITE_LOGIN_SUCCESS,
    GSUITE_LOGIN_ERROR,
    GSUITE_CUSTOMER_CHECK_REQUEST,
    GSUITE_CUSTOMER_CHECK_SUCCESS,
    GSUITE_CUSTOMER_CHECK_ERROR,
    GSUITE_CUSTOMER_DATA_REQUEST,
    GSUITE_CUSTOMER_DATA_SUCCESS,
    GSUITE_CUSTOMER_DATA_ERROR,
    GSUITE_SEAT_PRICING_REQUEST,
    GSUITE_SEAT_PRICING_SUCCESS,
    GSUITE_SEAT_PRICING_ERROR,
    GSUITE_VERIFICATION_REQUEST,
    GSUITE_VERIFICATION_SUCCESS,
    GSUITE_VERIFICATION_ERROR,
    GSUITE_VERIFICATION_STATUS_REQUEST,
    GSUITE_VERIFICATION_STATUS_SUCCESS,
    GSUITE_VERIFICATION_STATUS_ERROR,
    GSUITE_VERIFY_RECORD_REQUEST,
    GSUITE_VERIFY_RECORD_SUCCESS,
    GSUITE_VERIFY_RECORD_ERROR,
    GSUITE_USER_ACCOUNTS_REQUEST,
    GSUITE_USER_ACCOUNTS_SUCCESS,
    GSUITE_USER_ACCOUNTS_ERROR,
    GSUITE_USER_DATA_REQUEST,
    GSUITE_USER_DATA_SUCCESS,
    GSUITE_USER_DATA_ERROR,
    GSUITE_USER_CREATE_REQUEST,
    GSUITE_USER_CREATE_SUCCESS,
    GSUITE_USER_CREATE_ERROR,
    GSUITE_USER_DELETE_REQUEST,
    GSUITE_USER_DELETE_SUCCESS,
    GSUITE_USER_DELETE_ERROR,
    GSUITE_PRODUCT_PRICING_REQUEST,
    GSUITE_PRODUCT_PRICING_SUCCESS,
    GSUITE_PRODUCT_PRICING_ERROR,
    GSUITE_PURCHASE_REQUEST,
    GSUITE_PURCHASE_SUCCESS,
    GSUITE_PURCHASE_ERROR,
    GSUITE_UPGRADE_REQUEST,
    GSUITE_UPGRADE_SUCCESS,
    GSUITE_UPGRADE_ERROR,
    GSUITE_BILLING_CYCLE_ERROR,
    GSUITE_BILLING_CYCLE_SUCCESS,
    GSUITE_BILLING_CYCLE_REQUEST,
    GSUITE_UPDATE_BILLING_CYCLE_REQUEST,
    GSUITE_UPDATE_BILLING_CYCLE_SUCCESS,
    GSUITE_UPDATE_BILLING_CYCLE_ERROR
} from './action';

const initialState = {
    gsuite_current_step: null,
    gsuite_service_status: null,
    gsuite_service_data: null,
    gsuite_service_error: null,
    gsuite_mailbox_status: null,
    gsuite_mailbox_data: null,
    gsuite_mailbox_error: null,
    gsuite_delete_mailbox_status: null,
    gsuite_delete_mailbox_data: null,
    gsuite_delete_mailbox_error: null,
    gsuite_login_status: null,
    gsuite_login_data: null,
    gsuite_login_error: null,
    gsuite_customer_check_status: null,
    gsuite_customer_check_data: null,
    gsuite_customer_check_error: null,
    gsuite_customer_data_status: null,
    gsuite_customer_data_data: null,
    gsuite_customer_data_error: null,
    gsuite_seat_pricing_status: null,
    gsuite_seat_pricing_data: null,
    gsuite_seat_pricing_error: null,
    gsuite_verification_status: null,
    gsuite_verification_data: null,
    gsuite_verification_error: null,
    gsuite_verification_status_status: null,
    gsuite_verification_status_data: null,
    gsuite_verification_status_error: null,
    gsuite_verify_record_status: null,
    gsuite_verify_record_data: null,
    gsuite_verify_record_error: null,
    gsuite_user_accounts_status: null,
    gsuite_user_accounts_data: null,
    gsuite_user_accounts_error: null,
    gsuite_user_data_status: null,
    gsuite_user_data_data: null,
    gsuite_user_data_error: null,
    gsuite_user_create_status: null,
    gsuite_user_create_data: null,
    gsuite_user_create_error: null,
    gsuite_user_delete_status: null,
    gsuite_user_delete_data: null,
    gsuite_user_delete_error: null,
    gsuite_product_pricing_status: null,
    gsuite_product_pricing_data: null,
    gsuite_product_pricing_error: null,
    gsuite_purchase_status: null,
    gsuite_purchase_data: null,
    gsuite_purchase_error: null,
    gsuite_upgrade_status: null,
    gsuite_upgrade_data: null,
    gsuite_upgrade_error: null
};

const gsuiteReducer = (state = initialState, action) => {
    switch (action.type) {
        /**********************************************************************************************************
         *   RESET
         **********************************************************************************************************/
        case GSUITE_INITIAL_STATE:
            return initialState;

        /**********************************************************************************************************
         *   GSUITE SERVICE
         **********************************************************************************************************/
        case GSUITE_SERVICE_REQUEST:
            return {
                ...state,
                gsuite_service_status: 'loading',
                gsuite_service_data: null,
                gsuite_service_error: null
            };

        case GSUITE_SERVICE_SUCCESS:
            return {
                ...state,
                gsuite_service_status: 'success',
                gsuite_service_data: action.gsuite_service_data
            };

        case GSUITE_SERVICE_ERROR:
            return {
                ...state,
                gsuite_service_status: 'error',
                gsuite_service_error: action.gsuite_service_error
            };

        /**********************************************************************************************************
         *   GSUITE MAILBOX
         **********************************************************************************************************/
        case GSUITE_MAILBOX_REQUEST:
            return {
                ...state,
                gsuite_mailbox_status: 'loading',
                gsuite_mailbox_data: null,
                gsuite_mailbox_error: null
            };

        case GSUITE_MAILBOX_SUCCESS:
            return {
                ...state,
                gsuite_mailbox_status: 'success',
                gsuite_mailbox_data: action.gsuite_mailbox_data
            };

        case GSUITE_MAILBOX_ERROR:
            return {
                ...state,
                gsuite_mailbox_status: 'error',
                gsuite_mailbox_error: action.gsuite_mailbox_error
            };

        case GSUITE_DELETE_MAILBOX_REQUEST:
            return {
                ...state,
                gsuite_delete_mailbox_status: 'loading',
                gsuite_delete_mailbox_data: null,
                gsuite_delete_mailbox_error: null
            };

        case GSUITE_DELETE_MAILBOX_SUCCESS:
            return {
                ...state,
                gsuite_delete_mailbox_status: 'success',
                gsuite_delete_mailbox_data: action.gsuite_delete_mailbox_data
            };

        case GSUITE_DELETE_MAILBOX_ERROR:
            return {
                ...state,
                gsuite_delete_mailbox_status: 'error',
                gsuite_delete_mailbox_error: action.gsuite_delete_mailbox_error
            };

        /**********************************************************************************************************
         *   GSUITE LOGIN
         **********************************************************************************************************/
        case GSUITE_LOGIN_REQUEST:
            return {
                ...state,
                gsuite_login_status: 'loading',
                gsuite_login_data: null,
                gsuite_login_error: null
            };

        case GSUITE_LOGIN_SUCCESS:
            return {
                ...state,
                gsuite_login_status: 'success',
                gsuite_login_data: action.gsuite_login_data
            };

        case GSUITE_LOGIN_ERROR:
            return {
                ...state,
                gsuite_login_status: 'error',
                gsuite_login_error: action.gsuite_login_error
            };

        /**********************************************************************************************************
         *   GSUITE CUSTOMER DATA
         **********************************************************************************************************/
        case GSUITE_CUSTOMER_CHECK_REQUEST:
            return {
                ...state,
                gsuite_customer_check_status: 'loading',
                gsuite_customer_check_data: null,
                gsuite_customer_check_error: null
            };

        case GSUITE_CUSTOMER_CHECK_SUCCESS:
            return {
                ...state,
                gsuite_customer_check_status: 'success',
                gsuite_customer_check_data: action.gsuite_customer_check_data
            };

        case GSUITE_CUSTOMER_CHECK_ERROR:
            return {
                ...state,
                gsuite_customer_check_status: 'error',
                gsuite_customer_check_error: action.gsuite_customer_check_error
            };

        case GSUITE_CUSTOMER_DATA_REQUEST:
            return {
                ...state,
                gsuite_customer_data_status: 'loading',
                gsuite_customer_data_data: null,
                gsuite_customer_data_error: null
            };

        case GSUITE_CUSTOMER_DATA_SUCCESS:
            return {
                ...state,
                gsuite_customer_data_status: 'success',
                gsuite_customer_data_data: action.gsuite_customer_data_data
            };

        case GSUITE_CUSTOMER_DATA_ERROR:
            return {
                ...state,
                gsuite_customer_data_status: 'error',
                gsuite_customer_data_error: action.gsuite_customer_data_error
            };

        /**********************************************************************************************************
         *   GSUITE MAILBOX
         **********************************************************************************************************/
        case GSUITE_CURRENT_STEP:
            return {
                ...state,
                gsuite_current_step: action.gsuite_current_step
            };

        /**********************************************************************************************************
         *   GSUITE PRICING
         **********************************************************************************************************/
        case GSUITE_SEAT_PRICING_REQUEST:
            return {
                ...state,
                gsuite_seat_pricing_status: 'loading',
                gsuite_seat_pricing_data: null,
                gsuite_seat_pricing_error: null
            };

        case GSUITE_SEAT_PRICING_SUCCESS:
            return {
                ...state,
                gsuite_seat_pricing_status: 'success',
                gsuite_seat_pricing_data: action.gsuite_seat_pricing_data
            };

        case GSUITE_SEAT_PRICING_ERROR:
            return {
                ...state,
                gsuite_seat_pricing_status: 'error',
                gsuite_seat_pricing_error: action.gsuite_seat_pricing_error
            };

        /**********************************************************************************************************
         *   GSUITE VERIFICATION
         **********************************************************************************************************/
        case GSUITE_VERIFICATION_REQUEST:
            return {
                ...state,
                gsuite_verification_status: 'loading',
                gsuite_verification_data: null,
                gsuite_verification_error: null
            };

        case GSUITE_VERIFICATION_SUCCESS:
            return {
                ...state,
                gsuite_verification_status: 'success',
                gsuite_verification_data: action.gsuite_verification_data
            };

        case GSUITE_VERIFICATION_ERROR:
            return {
                ...state,
                gsuite_verification_status: 'error',
                gsuite_verification_error: action.gsuite_verification_error
            };

        case GSUITE_VERIFICATION_STATUS_REQUEST:
            return {
                ...state,
                gsuite_verification_status_status: 'loading',
                gsuite_verification_status_data: null,
                gsuite_verification_status_error: null
            };

        case GSUITE_VERIFICATION_STATUS_SUCCESS:
            return {
                ...state,
                gsuite_verification_status_status: 'success',
                gsuite_verification_status_data: action.gsuite_verification_status_data
            };

        case GSUITE_VERIFICATION_STATUS_ERROR:
            return {
                ...state,
                gsuite_verification_status_status: 'error',
                gsuite_verification_status_error: action.gsuite_verification_status_error
            };

        case GSUITE_VERIFY_RECORD_REQUEST:
            return {
                ...state,
                gsuite_verify_record_status: 'loading',
                gsuite_verify_record_data: null,
                gsuite_verify_record_error: null
            };

        case GSUITE_VERIFY_RECORD_SUCCESS:
            return {
                ...state,
                gsuite_verify_record_status: 'success',
                gsuite_verify_record_data: action.gsuite_verify_record_data
            };

        case GSUITE_VERIFY_RECORD_ERROR:
            return {
                ...state,
                gsuite_verify_record_status: 'error',
                gsuite_verify_record_error: action.gsuite_verify_record_error
            };

        /**********************************************************************************************************
         *   GSUITE USER ACCOUNTS
         **********************************************************************************************************/
        case GSUITE_USER_ACCOUNTS_REQUEST:
            return {
                ...state,
                gsuite_user_accounts_status: 'loading',
                gsuite_user_accounts_data: null,
                gsuite_user_accounts_error: null
            };

        case GSUITE_USER_ACCOUNTS_SUCCESS:
            return {
                ...state,
                gsuite_user_accounts_status: 'success',
                gsuite_user_accounts_data: action.gsuite_user_accounts_data
            };

        case GSUITE_USER_ACCOUNTS_ERROR:
            return {
                ...state,
                gsuite_user_accounts_status: 'error',
                gsuite_user_accounts_error: action.gsuite_user_accounts_error
            };

        case GSUITE_USER_DATA_REQUEST:
            return {
                ...state,
                gsuite_user_data_status: 'loading',
                gsuite_user_data_data: null,
                gsuite_user_data_error: null
            };

        case GSUITE_USER_DATA_SUCCESS:
            return {
                ...state,
                gsuite_user_data_status: 'success',
                gsuite_user_data_data: action.gsuite_user_data_data
            };

        case GSUITE_USER_DATA_ERROR:
            return {
                ...state,
                gsuite_user_data_status: 'error',
                gsuite_user_data_error: action.gsuite_user_data_error
            };

        case GSUITE_USER_CREATE_REQUEST:
            return {
                ...state,
                gsuite_user_create_status: 'loading',
                gsuite_user_create_data: null,
                gsuite_user_create_error: null
            };

        case GSUITE_USER_CREATE_SUCCESS:
            return {
                ...state,
                gsuite_user_create_status: 'success',
                gsuite_user_create_data: action.gsuite_user_create_data
            };

        case GSUITE_USER_CREATE_ERROR:
            return {
                ...state,
                gsuite_user_create_status: 'error',
                gsuite_user_create_error: action.gsuite_user_create_error
            };

        case GSUITE_USER_DELETE_REQUEST:
            return {
                ...state,
                gsuite_user_delete_status: 'loading',
                gsuite_user_delete_data: null,
                gsuite_user_delete_error: null
            };

        case GSUITE_USER_DELETE_SUCCESS:
            return {
                ...state,
                gsuite_user_delete_status: 'success',
                gsuite_user_delete_data: action.gsuite_user_delete_data
            };

        case GSUITE_USER_DELETE_ERROR:
            return {
                ...state,
                gsuite_user_delete_status: 'error',
                gsuite_user_delete_error: action.gsuite_user_delete_error
            };

        /**********************************************************************************************************
         *   GSUITE PRODUCT PRICING
         **********************************************************************************************************/
        case GSUITE_PRODUCT_PRICING_REQUEST:
            return {
                ...state,
                gsuite_product_pricing_status: 'loading',
                gsuite_product_pricing_data: null,
                gsuite_product_pricing_error: null
            };

        case GSUITE_PRODUCT_PRICING_SUCCESS:
            return {
                ...state,
                gsuite_product_pricing_status: 'success',
                gsuite_product_pricing_data: action.gsuite_product_pricing_data
            };

        case GSUITE_PRODUCT_PRICING_ERROR:
            return {
                ...state,
                gsuite_product_pricing_status: 'error',
                gsuite_product_pricing_error: action.gsuite_product_pricing_error
            };

        /**********************************************************************************************************
         *   GSUITE PURCHASE
         **********************************************************************************************************/
        case GSUITE_PURCHASE_REQUEST:
            return {
                ...state,
                gsuite_purchase_status: 'loading',
                gsuite_purchase_data: null,
                gsuite_purchase_error: null
            };

        case GSUITE_PURCHASE_SUCCESS:
            return {
                ...state,
                gsuite_purchase_status: 'success',
                gsuite_purchase_data: action.gsuite_purchase_data
            };

        case GSUITE_PURCHASE_ERROR:
            return {
                ...state,
                gsuite_purchase_status: 'error',
                gsuite_purchase_error: action.gsuite_purchase_error
            };

        /**********************************************************************************************************
         *   GSUITE UPGRADE
         **********************************************************************************************************/
        case GSUITE_UPGRADE_REQUEST:
            return {
                ...state,
                gsuite_upgrade_status: 'loading',
                gsuite_upgrade_data: null,
                gsuite_upgrade_error: null
            };

        case GSUITE_UPGRADE_SUCCESS:
            return {
                ...state,
                gsuite_upgrade_status: 'success',
                gsuite_upgrade_data: action.gsuite_upgrade_data
            };

        case GSUITE_UPGRADE_ERROR:
            return {
                ...state,
                gsuite_upgrade_status: 'error',
                gsuite_upgrade_error: action.gsuite_upgrade_error
            };

        /**
         * BILLING CYCLE
         */
        case GSUITE_BILLING_CYCLE_REQUEST:
            return {
                ...state,
                gsuite_billing_cycle_status: 'loading',
                gsuite_billing_cycle_data: null
            };

        case GSUITE_BILLING_CYCLE_SUCCESS:
            return {
                ...state,
                gsuite_billing_cycle_status: 'success',
                gsuite_billing_cycle_data: action.gsuite_billing_cycle_data
            };

        case GSUITE_BILLING_CYCLE_ERROR:
            return {
                ...state,
                gsuite_billing_cycle_status: 'error'
            };

        case GSUITE_UPDATE_BILLING_CYCLE_REQUEST:
            return {
                ...state,
                gsuite_update_billing_cycle_status: 'loading',
                gsuite_update_billing_cycle_data: null
            };

        case GSUITE_UPDATE_BILLING_CYCLE_SUCCESS:
            return {
                ...state,
                gsuite_update_billing_cycle_status: 'success',
                gsuite_update_billing_cycle_data: action.gsuite_update_billing_cycle_data
            };

        case GSUITE_UPDATE_BILLING_CYCLE_ERROR:
            return {
                ...state,
                gsuite_update_billing_cycle_status: 'error'
            };

        default:
            return state;
    }
};

export default gsuiteReducer;

/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { useNavigate } from '@tanstack/react-router';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import { CustomDropdown } from 'components/Dropdowns/CustomDropdown';
import SelectOptions from 'components/Dropdowns/SelectDropdown/Options';
import Title from 'components/Title';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { getTicketSubmitUrl } from 'containers/support/methods';
import { useSelectableSelector } from 'utilities/hooks/redux/useSelectableSelector';
import { createPopup, getIncludedObjBasedOnType } from 'utilities/methods/commonActions';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import { ms365PortalUrl } from 'containers/ms365/consts';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const MicrosoftTitle = () => {
    /***** HOOKS *****/
    const navigate = useNavigate();
    const { id, name, productName } = useSelectableSelector((state: any) => state.ms365.ms365_information_data, {
        select({ id, included, attributes = {}, ...rest }) {
            const name = attributes.name;
            const productName = getIncludedObjBasedOnType(included, 'product')?.attributes.name;

            return {
                id,
                name,
                productName,
                ...rest
            };
        }
    });

    /***** RENDER *****/
    if (!id) return null;

    return (
        <Title
            serviceTitle={name}
            serviceSubTitle={productName}
            serviceType="microsoft-365"
            action={[
                {
                    status: null,
                    label: 'Microsoft Azure Management Portal',
                    color: 'notice',
                    type: 'onClick',
                    onClick: (e) => {
                        e.preventDefault();
                        createPopup('https://portal.azure.com/VmeBcSqWnCUgeCbg.onmicrosoft.com');
                    }
                },
                {
                    status: null,
                    label: 'Microsoft 365 Management Portal',
                    color: 'notice',
                    type: 'onClick',
                    onClick: (e) => {
                        e.preventDefault();
                        createPopup(ms365PortalUrl);
                    }
                },
                <CustomDropdown
                    className="TitleShared__singleDropdown"
                    label={({ labelRef, dropdownTitleOnClick }) => (
                        <button className="TitleShared__singleDropdownButton" ref={labelRef} type="button" onClick={() => dropdownTitleOnClick()}>
                            Microsoft 365 Help
                            <i className="item__icon icon icon-chevron-down" />
                        </button>
                    )}
                    key="dropdown"
                    render={() => (
                        <SelectOptions
                            options={[
                                {
                                    label: 'Microsoft 365 Help Articles',
                                    onSelect: () => navigate({ to: '/support/support-centre/$', params: { _splat: 'microsoft-365' } })
                                },
                                {
                                    label: 'Submit a Technical Support eTicket for this Service',
                                    onSelect: () => navigate({ to: getTicketSubmitUrl({ department: 'technical-support', service: id }) })
                                },
                                {
                                    label: 'Submit a Billing eTicket for this Service',
                                    onSelect: () => navigate({ to: getTicketSubmitUrl({ department: 'accounts-billing', service: id }) })
                                }
                            ]}
                        />
                    )}
                />
            ]}
        />
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

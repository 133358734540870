/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { useRecursiveContext } from 'utilities/hooks/useRecursiveContext';

/**********************************************************************************************************
 *   HOOK START
 **********************************************************************************************************/
/**
 *
 * @returns {import('containers/katana/components/dynamicFormFieldRenderer/propertiesRenderer').PropertiesRenderer}
 */
function useRecursivePropertiesRenderer() {
    /***** HOOKS *****/
    /**
     * @type {{
     *      PropertiesRenderer?: import('containers/katana/components/dynamicFormFieldRenderer/propertiesRenderer').PropertiesRenderer
     * }}
     */
    const { PropertiesRenderer } = useRecursiveContext();
    if (!PropertiesRenderer)
        throw new Error(`
          Unable to find PropertiesRenderer.
          are you using this hook in the right context?
          PropertiesRenderer is available in RecursiveContextProvider
      `);

    return PropertiesRenderer;
}
/**********************************************************************************************************
 *   HOOK END
 **********************************************************************************************************/

export default useRecursivePropertiesRenderer;

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import { defaultAccepts } from 'components/Form/NXDropZone/consts';

/**
 * @param {string[]} mimeTypes
 * @returns {Record<string,string[]>}
 */
export function createAcceptMimeTypes(mimeTypes = []) {
    return mimeTypes.reduce((accept, mimeType) => {
        const extension = defaultAccepts[mimeType];
        if (extension) {
            accept[mimeType] = extension;
        }
        return accept;
    }, {});
}

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import HoverTooltip from 'components/Tooltip/HoverTooltip';
import Text from 'components/Utils/Text';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { getExpiryInfo } from 'containers/services/modules/domains/methods';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type DomainsListExpiry = React.FC<DomainsListExpiryProps>;
type DomainsListExpiryProps = {
    expiryDate: string;
};

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * The expiry date as displayed in the domains list
 */
export const DomainsListExpiry: DomainsListExpiry = ({ expiryDate }) => {
    // TEMP-START
    expiryDate = expiryDate.split('T')[0];
    // TEMP-END

    /***** RENDER HELPERS *****/
    const expiryInfo = getExpiryInfo(expiryDate);
    const isExpiring = expiryInfo.days < 90;

    /***** RENDER *****/
    return (
        <div className="ServiceOverview__expiryDate">
            <HoverTooltip content={expiryInfo.formattedExpiryDate}>
                <Text size--xss warn={isExpiring} bold={isExpiring} secondary={!isExpiring}>
                    <p>
                        {expiryInfo.partOne} {expiryInfo.partTwo}
                    </p>
                </Text>
            </HoverTooltip>
        </div>
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import htmr from 'htmr';
import { Component, createRef } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Field, change, reduxForm } from 'redux-form';
import { withRouter } from 'utilities/methods/tanstack/router/withRouter';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import VipcAppTwoFactorPolling from './vipcAppTwoFactorPolling';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import Anchor from 'components/Anchor';

/*   ACTIONS
 *****************************************************/
import { generateTwoFactorBasedOnMethod } from '../../action';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { RenderField, renderButton, requiredFieldValidation } from 'utilities/methods/form';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import { application } from 'config/config';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
class TwoFactor extends Component {
    constructor(props) {
        super(props);
        this.state = {
            primaryMethod: null,
            twoFactorDetails: {
                heading: 'Two-Factor Authentication',
                desc: `In order to continue with your login to ${application}, you'll need to complete the authentication process.`
            },
            // This count gets passed as a useEffect dependency to the VipcAppTwoFactorPolling component so that it can reset the polling timeout when the user clicks "resend push notification"
            resendPushNotificationCount: 0
        };
        this.resendCode = this.resendCode.bind(this);
        this.getPrimaryMethod = this.getPrimaryMethod.bind(this);
        this.twoFAInputRef = createRef();
        this.webOTPAbortController = null;
    }

    resendCode(isPushNotification) {
        const { generateTwoFactorBasedOnMethod } = this.props;
        const { primaryMethod, resendPushNotificationCount } = this.state;

        generateTwoFactorBasedOnMethod(primaryMethod);

        if (isPushNotification) {
            this.setState({
                resendPushNotificationCount: resendPushNotificationCount + 1
            });
        }
    }

    getPrimaryMethod(primaryMethod) {
        const { backupMethod } = this.props;

        if (primaryMethod && primaryMethod.length > 0) {
            let methodValue = '';
            primaryMethod.forEach((item) => {
                const { attributes } = item;
                const { is_primary, method, method_value } = attributes;
                let check = method;
                if (!is_primary && !backupMethod) {
                    return false;
                } else if (backupMethod) {
                    check = backupMethod;
                }

                if (check === method) {
                    methodValue = method_value;
                }

                switch (check) {
                    case 'SMS':
                        this.setState({
                            primaryMethod: check,
                            twoFactorDetails: {
                                heading: 'Mobile Two-Factor Authentication',
                                desc: htmr(
                                    `Your verification code has been sent to <span class="destination">${methodValue}</span>. Please enter it below to log into ${application}`
                                )
                            }
                        });
                        break;
                    case 'EMAIL':
                        this.setState({
                            primaryMethod: check,
                            twoFactorDetails: {
                                heading: 'Email Two-Factor Authentication',
                                desc: htmr(
                                    `Your verification code has been sent to <span class="destination">${methodValue}</span>. Please enter it below to log into ${application}`
                                )
                            }
                        });
                        break;
                    case 'PUSH':
                        this.setState({
                            primaryMethod: check,
                            twoFactorDetails: {
                                heading: `${application} App Authentication`,
                                desc: htmr(
                                    `We sent a notification to your device from the ${application} app. Tap ACCEPT when requested to complete the verification request and confirm it's really you.`
                                )
                            }
                        });
                        break;
                    case 'GAUTH':
                    default:
                        this.setState({
                            primaryMethod: check,
                            twoFactorDetails: {
                                heading: 'Two-Factor Authentication',
                                desc: `In order to continue with your login to ${application}, you'll need to complete the authentication process.`
                            }
                        });
                        break;
                }
            });
        } else {
            this.setState({
                primaryMethod: false
            });
        }
    }

    componentDidMount() {
        const { login_backup_data, change } = this.props;
        const { getPrimaryMethod, twoFAInputRef } = this;

        if (login_backup_data) {
            getPrimaryMethod(login_backup_data);
        }

        // Web OTP
        if ('OTPCredential' in window) {
            if (!twoFAInputRef?.current) return;

            this.webOTPAbortController = new AbortController();

            navigator.credentials
                .get({
                    otp: { transport: ['sms'] },
                    signal: this.webOTPAbortController.signal
                })
                .then((otp) => {
                    const twoFACode = otp.code;
                    change('twofactorcode', twoFACode);
                    this.props.onSubmit({ twofactorcode: twoFACode });
                    if (this.webOTPAbortController?.abort) this.webOTPAbortController.abort();
                })
                .catch((error) => {
                    console.error(error);
                });
        }
    }

    componentDidUpdate(prevProps) {
        const { login_backup_data, login_backup_status } = this.props;
        const { getPrimaryMethod } = this;

        if (login_backup_status === 'success' && prevProps.login_backup_status === 'loading') {
            getPrimaryMethod(login_backup_data);
        }
    }

    componentWillUnmount() {
        if (this.webOTPAbortController?.abort) this.webOTPAbortController.abort('Component Unmounting, State has changed.');
    }

    render() {
        const { handleSubmit, navigation, pristine, submitting, valid } = this.props;
        const { primaryMethod, twoFactorDetails, resendPushNotificationCount } = this.state;
        const { resendCode, twoFAInputRef } = this;

        return (
            <div className="twoFactor">
                <div className="twoFactor__title">
                    <div className="heading">{twoFactorDetails.heading}</div>
                    <div className="description">{twoFactorDetails.desc}</div>
                </div>
                {primaryMethod === 'PUSH' ? (
                    <VipcAppTwoFactorPolling resendPushNotificationCount={resendPushNotificationCount} changeView={navigation} />
                ) : (
                    <>
                        <form className="twoFactor__form" onSubmit={handleSubmit}>
                            <div className="form__row">
                                <div className="form__column full login__textfield">
                                    <Field
                                        inputRef={twoFAInputRef}
                                        label="Enter Authentication Code"
                                        name="twofactorcode"
                                        component={RenderField}
                                        type="text"
                                        placeholder="Two-Factor Code"
                                        validate={[requiredFieldValidation]}
                                        className="form__textfield"
                                        autoComplete="one-time-code"
                                        inputMode="numeric"
                                    />
                                </div>
                            </div>
                            {renderButton(pristine, submitting, valid, 'Verify and Login', 'primary')}
                        </form>
                    </>
                )}
                <div className="login__link-container">
                    <Anchor
                        className="login__link"
                        onClick={(e) => {
                            e.preventDefault();
                            navigation('backup');
                        }}
                    >
                        More Two-Factor Options
                    </Anchor>
                    {primaryMethod !== 'GAUTH' ? (
                        <Anchor
                            className="login__link"
                            onClick={(e) => {
                                e.preventDefault();
                                resendCode(primaryMethod === 'PUSH');
                            }}
                        >
                            {primaryMethod === 'PUSH' ? 'Resend Verification Push Notification' : 'Resend Verification Code'}
                        </Anchor>
                    ) : (
                        ''
                    )}
                    <Anchor
                        className="login__link"
                        onClick={(e) => {
                            e.preventDefault();
                            navigation('login');
                        }}
                    >
                        Back to Login Page
                    </Anchor>
                </div>
            </div>
        );
    }
}
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
TwoFactor = reduxForm({
    form: 'twoFactorForm' // a unique identifier for this form,
})(TwoFactor);

const mapStateToProps = (state) => {
    return {
        login_backup_status: state.login.login_backup_status,
        login_backup_data: state.login.login_backup_data
    };
};

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            generateTwoFactorBasedOnMethod,
            change
        },
        dispatch
    );

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(TwoFactor));

/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import classNames from 'classnames';
import { has } from 'lodash';
import { DateTime } from 'luxon';
import { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'utilities/methods/tanstack/router/withRouter';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import Anchor from 'components/Anchor';
import { CustomDropdown } from 'components/Dropdowns/CustomDropdown';
import SelectDropdown from 'components/Dropdowns/SelectDropdown';
import RequestLoader from 'components/Loaders/Request';
import NXBox from 'components/NXBox';
import Search from 'components/Search';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import InvoiceFilterForm from '../../forms/invoiceFilter';
import BulkEmailInvoices from './bulkEmailInvoices';
import MergeInvoicesAction from './mergeInvoicesAction';
import RenderSearchResults from './searchResults';
import InvoicesTable from './tables/invoicesTable';

/**********************************************************************************************************
 *   QUERIES
 **********************************************************************************************************/
import { useGetFilteredAndPaginatedInvoiceListInfiniteQuery } from 'containers/billing/queries/invoice/useGetFilteredAndPaginatedInvoiceListInfiniteQuery';
import { useSearchInvoiceByKeywordQuery } from 'containers/billing/queries/invoice/useSearchInvoiceByKeywordQuery';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import Text from 'components/Utils/Text';
import { registerScrollEvents } from 'utilities/methods/commonActions/registerScrollEvents';
import { invoicesTableContentComponents } from './tables/invoicesTable/methods';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import './_invoices.scss';
import { mobileTableSizes } from './tables/invoicesTable/consts';

export const filterOptions = {
    show: {
        ALL: 'all',
        HIDE_CANCELLED: 'hide_cancelled'
    },
    filter_by: {
        DUE: 'due_date',
        PAID: 'date_paid'
    }
};

const defaultFilterData = {
    page: 1,
    hide_cancelled: 0
};

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
let Invoices = (props) => {
    const {
        enableBoxTopActions = true,
        NXBoxTopTitle = 'Invoices',
        NXBoxTopDescription = '',
        tableContentComponentsOverride,
        /**
         * Redux Props
         */
        app_viewport
    } = props;
    /***** STATE *****/
    const [currentSearchKeyword, setCurrentSearchKeyword] = useState(null);
    const [currentSearchValue, setCurrentSearchValue] = useState(null);
    const [filters, setFilters] = useState(defaultFilterData);
    const [isSearchClosed, setIsSearchClosed] = useState(true);

    /***** HOOKS *****/
    const scrollRef = useRef(null);
    const searchInputRef = useRef(null);

    /***** QUERIES *****/
    const {
        status: get_filtered_and_paginated_invoice_list_status,
        data: get_filtered_and_paginated_invoice_list_data,
        isLoading: isGetFilteredAndPaginatedInvoiceListLoading,
        hasNextPage: hasGetFilteredAndPaginatedInvoiceListNextPage,
        fetchNextPage: fetchNextPageGetFilteredAndPaginatedInvoiceList,
        isFetchingNextPage: isFetchingNextPageGetFilteredAndPaginatedInvoiceList
    } = useGetFilteredAndPaginatedInvoiceListInfiniteQuery(filters);

    const {
        status: search_invoice_by_keyword_status,
        data: search_invoice_by_keyword_data,
        hasNextPage: hasSearchInvoiceByKeywordNextPage,
        fetchNextPage: fetchNextPageSearchInvoiceByKeyword,
        isFetchingNextPage: isFetchingNextPageSearchInvoiceByKeyword
    } = useSearchInvoiceByKeywordQuery({ ...filters, filter: currentSearchKeyword });

    const hasNextPage = currentSearchKeyword ? hasSearchInvoiceByKeywordNextPage : hasGetFilteredAndPaginatedInvoiceListNextPage;
    const isFetchingNextPage = currentSearchKeyword ? isFetchingNextPageSearchInvoiceByKeyword : isFetchingNextPageGetFilteredAndPaginatedInvoiceList;
    const fetchNextPage = currentSearchKeyword ? fetchNextPageSearchInvoiceByKeyword : fetchNextPageGetFilteredAndPaginatedInvoiceList;

    const allInvoices =
        (currentSearchKeyword ? search_invoice_by_keyword_data?.pages?.flat() : get_filtered_and_paginated_invoice_list_data?.pages?.flat()) ?? [];
    const invoicesStatus = currentSearchKeyword ? search_invoice_by_keyword_status : get_filtered_and_paginated_invoice_list_status;

    /***** FUNCTIONS *****/
    function changeFilters(newFilters) {
        // Remove null keys to prevent error in query without from/to dates
        for (const key in newFilters) {
            if (newFilters[key] === null) {
                delete newFilters[key];
            }
        }

        if (newFilters.end_date === 'Invalid DateTime') delete newFilters.end_date;
        if (newFilters.start_date === 'Invalid DateTime') delete newFilters.start_date;

        setFilters(newFilters);
    }

    function submitDateFilterForm(data) {
        const { start_date, end_date, filter_by } = data;

        const newFilters = {
            ...filters,
            start_date: DateTime.fromJSDate(start_date).toFormat('yyyy-MM-dd'),
            end_date: DateTime.fromJSDate(end_date).toFormat('yyyy-MM-dd'),
            filter_by
        };

        changeFilters(newFilters);
    }

    function clearDateFilterForm() {
        // When we call this method we need to remove the 3 keys that the date form adds so we can refetch the invoices without those.

        const newFilters = {
            ...filters
        };

        const removeKeys = ['start_date', 'end_date', 'filter_by'];
        removeKeys.forEach((key) => {
            if (has(newFilters, key)) {
                delete newFilters[key];
            }
        });

        changeFilters(newFilters);
    }

    function handleSort({ sortKey, sortMethod }) {
        const newFilters = {
            ...filters,
            sort_by: sortKey,
            sort: sortMethod
        };

        if (sortMethod === false) {
            delete newFilters.sort;
            delete newFilters.sort_by;
        }

        changeFilters(newFilters);
    }

    const handleResetSearchInvoiceByKeyword = () => {
        setCurrentSearchKeyword(null);
        setIsSearchClosed(true);
    };

    const handleSetCurrentSearchValue = (value) => {
        setCurrentSearchValue(value);
        if (value) setIsSearchClosed(false);
    };

    const HandleSearchKeyboardEvents = (e) => {
        switch (e?.key) {
            case 'Enter': {
                setCurrentSearchKeyword(currentSearchValue);
                setIsSearchClosed(true);
                break;
            }
            case 'Escape': {
                setIsSearchClosed(true);
                break;
            }
            default:
                break;
        }
    };

    /***** EFFECTS *****/
    useEffect(() => {
        registerScrollEvents({ props, scrollRef: scrollRef.current }, get_filtered_and_paginated_invoice_list_status === 'success');
    }, [get_filtered_and_paginated_invoice_list_status]);

    /***** RENDER HELPERS *****/
    function renderFooterContent() {
        if (isFetchingNextPage)
            return (
                <NXBox.Footer>
                    <RequestLoader />
                </NXBox.Footer>
            );
        if (hasNextPage) {
            return (
                <NXBox.Footer>
                    <Anchor onClick={fetchNextPage} className="accountInvoices__footerButton">
                        Show More
                    </Anchor>
                </NXBox.Footer>
            );
        }
        if (!hasNextPage && !isGetFilteredAndPaginatedInvoiceListLoading)
            return (
                <NXBox.Footer>
                    <Text align--center size--s secondary>
                        No more invoices to fetch
                    </Text>
                </NXBox.Footer>
            );

        return '';
    }

    /***** RENDER *****/
    return (
        <div ref={scrollRef}>
            <NXBox className={classNames('accountInvoices', { 'accountInvoices--mobileTable': mobileTableSizes.includes(app_viewport) })}>
                <NXBox.Top title={NXBoxTopTitle} description={NXBoxTopDescription}>
                    {enableBoxTopActions && allInvoices ? (
                        <>
                            <BulkEmailInvoices />
                            <MergeInvoicesAction />
                        </>
                    ) : (
                        ''
                    )}
                </NXBox.Top>
                <NXBox.DefaultPadding>
                    <div className="accountInvoices__controls">
                        <Search
                            slim={isSearchClosed}
                            render={{
                                status: 'success',
                                placeholder: 'Search by invoice number or service name',
                                list: (
                                    <RenderSearchResults
                                        onClick={(service) => {
                                            setIsSearchClosed(true);
                                            setCurrentSearchKeyword(service);
                                            setCurrentSearchValue(service);
                                            searchInputRef.current.inputRef.value = service;
                                        }}
                                        search={currentSearchValue}
                                    />
                                )
                            }}
                            functions={{
                                reset: handleResetSearchInvoiceByKeyword
                            }}
                            helpers={{
                                keyword: handleSetCurrentSearchValue
                            }}
                            ref={searchInputRef}
                            onKeyDown={HandleSearchKeyboardEvents}
                            onClickAway={() => setIsSearchClosed(true)}
                            onClickSearchInput={() => setIsSearchClosed(false)}
                        />
                        <div className="accountInvoices__controlButtons">
                            <SelectDropdown
                                noSelectionLabel="Show"
                                className="accountInvoices__show"
                                options={[
                                    {
                                        label: 'Show all invoices',
                                        onClick: () => {
                                            document.querySelector('.accountInvoices__show')?.focus();
                                            if (filters.show !== filterOptions.show.ALL) {
                                                changeFilters({
                                                    ...filters,
                                                    hide_cancelled: 0
                                                });
                                            }
                                        }
                                    },
                                    {
                                        label: 'Hide cancelled invoices',
                                        onClick: () => {
                                            document.querySelector('.accountInvoices__show')?.focus();
                                            if (filters.show !== filterOptions.show.HIDE_CANCELLED) {
                                                changeFilters({
                                                    ...filters,
                                                    hide_cancelled: 1
                                                });
                                            }
                                        }
                                    }
                                ]}
                            />
                            <CustomDropdown
                                label="Filter by date range"
                                className="accountInvoices__filterDropdown"
                                disabled={currentSearchValue?.length > 0}
                                render={(closeDropdown, showDropdown) => (
                                    <div className="accountInvoices__filter">
                                        <InvoiceFilterForm
                                            closeDropdown={closeDropdown}
                                            showDropdown={showDropdown}
                                            currentFilters={filters}
                                            onSubmit={submitDateFilterForm}
                                            onClear={clearDateFilterForm}
                                        />
                                    </div>
                                )}
                            />
                        </div>
                    </div>
                </NXBox.DefaultPadding>

                <InvoicesTable
                    invoicesData={allInvoices}
                    currentSearchKeyword={currentSearchKeyword}
                    handleSort={handleSort}
                    status={invoicesStatus}
                    contentComponents={tableContentComponentsOverride ?? invoicesTableContentComponents}
                />

                {renderFooterContent()}
            </NXBox>
        </div>
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

const mapStateToProps = (state) => {
    return {
        app_viewport: state.app.app_viewport
    };
};

Invoices = connect(mapStateToProps)(Invoices);

Invoices = withRouter(Invoices);

export default Invoices;

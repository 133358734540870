/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import classNames from 'classnames';
import React from 'react';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { createAppliedStylingClasses } from 'components/Utils/methods';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import error404image from 'assets/images/404_page.png';

import './_BackgroundImage.scss';

const BackgroundImageStyledPropTypeKeys = ['center-top', 'contain', 'cover', 'border', 'fill', 'transition'];

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * @type {React.FC<NBackgroundImage.Props>}
 */
const BackgroundImage = (props) => {
    const { imageURL, children, className } = props;

    /***** RENDER HELPERS *****/
    const styles = {
        backgroundImage: `url(${imageURL ?? error404image})`
    };

    const appliedStylingClasses = createAppliedStylingClasses({
        props,
        keyBoundary: BackgroundImageStyledPropTypeKeys,
        componentName: 'BackgroundImage',
        delimiter: '--'
    });

    /***** RENDER *****/
    return (
        <div className={classNames('BackgroundImage', appliedStylingClasses, className)} style={styles}>
            {children}
        </div>
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

export default BackgroundImage;

/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React from 'react';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import { FormItem } from 'components/Form/FormItem';
import { FormItemInner } from 'components/Form/FormItemInner';
import { FormLabel } from 'components/Form/FormLabel';
import { _Input as Input } from 'components/Form/Input/_Input';

/**********************************************************************************************************
 *   TYPE IMPORTS
 **********************************************************************************************************/
import type { ChangeEvent } from 'react';
import type { WrappedFieldProps } from 'redux-form';
import type { NXInputNamespace } from 'components/Form/Input/types';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * NXInput ReduxForm Wrapper
 */
export const _InputReduxFormDefault: React.FC<WrappedFieldProps & NXInputNamespace.ReduxForm.Props> = (props) => {
    const {
        input,
        meta,
        label,
        disabled,
        placeholder,
        isPlaceholderItalic,
        prepend,
        validated,
        readOnly,
        intrinsicProps,
        inputRef,
        onChangeTransform,
        valueTransform
    } = props;
    const { name, value, onChange, onBlur } = input;

    /***** FUNCTIONS *****/
    function handleOnChange(e: ChangeEvent<HTMLInputElement>) {
        onChange(onChangeTransform ? onChangeTransform(e.target.value) : e);
    }

    function handleOnBlur(e: ChangeEvent<HTMLInputElement>) {
        if (value === '' && onChangeTransform) {
            return;
        }
        onBlur(onChangeTransform ? onChangeTransform(e.target.value) : e);
    }

    /***** RENDER HELPERS *****/
    const _value = valueTransform ? valueTransform(value) : value;

    /***** RENDER *****/
    return (
        <FormItem disabled={disabled} name={name}>
            <FormLabel htmlFor={name || ''}>{label}</FormLabel>
            <FormItemInner meta={meta} prepend={prepend} validated={validated} noBackground disabled={disabled}>
                <Input
                    disabled={disabled}
                    value={_value}
                    name={name}
                    readOnly={readOnly}
                    placeholder={placeholder}
                    isPlaceholderItalic={isPlaceholderItalic}
                    inputRef={inputRef}
                    intrinsicProps={{ ...intrinsicProps, onChange: handleOnChange, onBlur: handleOnBlur }}
                />
            </FormItemInner>
        </FormItem>
    );
};

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

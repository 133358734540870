/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import store from 'store/store';

/*   ACTIONS
 *****************************************************/
import { pushNotification } from 'components/Toast/functions';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { API } from 'utilities/api/auDirect';
import { getDataFromSuccessResponse, getErrorFromFailResponse } from 'utilities/methods/commonActions';

export const AUDIRECT_REGISTER_REQUEST = 'audirect/AUDIRECT_REGISTER_REQUEST';
export const AUDIRECT_REGISTER_SUCCESS = 'audirect/AUDIRECT_REGISTER_SUCCESS';
export const AUDIRECT_REGISTER_ERROR = 'audirect/AUDIRECT_REGISTER_ERROR';
export const AUDIRECT_REGISTER_RESET = 'audirect/AUDIRECT_REGISTER_RESET';

export const AUDIRECT_STATUS_REQUEST = 'audirect/AUDIRECT_STATUS_REQUEST';
export const AUDIRECT_STATUS_SUCCESS = 'audirect/AUDIRECT_STATUS_SUCCESS';
export const AUDIRECT_STATUS_ERROR = 'audirect/AUDIRECT_STATUS_ERROR';
export const AUDIRECT_STATUS_RESET = 'audirect/AUDIRECT_STATUS_RESET';

export const AUDIRECT_CONTENTION_DOMAINS_REQUEST = 'auDirect/AUDIRECT_CONTENTION_DOMAINS_REQUEST';
export const AUDIRECT_CONTENTION_DOMAINS_SUCCESS = 'auDirect/AUDIRECT_CONTENTION_DOMAINS_SUCCESS';
export const AUDIRECT_CONTENTION_DOMAINS_ERROR = 'auDirect/AUDIRECT_CONTENTION_DOMAINS_ERROR';

export const AUDIRECT_CONTESTED_DOMAINS_REQUEST = 'auDirect/AUDIRECT_CONTESTED_DOMAINS_REQUEST';
export const AUDIRECT_CONTESTED_DOMAINS_SUCCESS = 'auDirect/AUDIRECT_CONTESTED_DOMAINSS_SUCCESS';
export const AUDIRECT_CONTESTED_DOMAINS_ERROR = 'auDirect/AUDIRECT_CONTESTED_DOMAINS_ERROR';

export const AUDIRECT_APPLY_REQUEST = 'auDirect/AUDIRECT_APPLY_REQUEST';
export const AUDIRECT_APPLY_SUCCESS = 'auDirect/AUDIRECT_APPLY_SUCCESS';
export const AUDIRECT_APPLY_ERROR = 'auDirect/AUDIRECT_APPLY_ERROR';

export const AUDIRECT_UPDATE_APPLICATION_REQUEST = 'auDirect/AUDIRECT_UPDATE_APPLICATION_REQUEST';
export const AUDIRECT_UPDATE_APPLICATION_SUCCESS = 'auDirect/AUDIRECT_UPDATE_APPLICATION_SUCCESS';
export const AUDIRECT_UPDATE_APPLICATION_ERROR = 'auDirect/AUDIRECT_UPDATE_APPLICATION_ERROR';

/**********************************************************************************************************
 *   REGISTER SINGLE CONTESTED
 **********************************************************************************************************/
export const resetRegisterState = () => {
    return (dispatch) => {
        dispatch({
            type: AUDIRECT_REGISTER_RESET
        });
    };
};

export const registerDomains = (domains) => {
    return (dispatch) => {
        dispatch({
            type: AUDIRECT_REGISTER_REQUEST
        });
        const payloadDomains = domains.filter((domain) => domain.register).map(({ domain, auto_renew }) => ({ domain, auto_renew }));
        API.auDirect.POST.register(payloadDomains)
            .then((response) => {
                const audirect_register_data = getDataFromSuccessResponse(response);
                pushNotification({ status: 200, details: 'Registration request successfully submitted.' });
                dispatch({
                    type: AUDIRECT_REGISTER_SUCCESS,
                    audirect_register_data
                });
            })
            .catch((error) => {
                const audirect_register_error = getErrorFromFailResponse(error);
                pushNotification(audirect_register_error);
                dispatch({
                    type: AUDIRECT_REGISTER_ERROR,
                    audirect_register_error: { ...audirect_register_error, previousFormData: domains }
                });
            });
    };
};

/**********************************************************************************************************
 *   DOMAIN AU DIRECT STATUS
 **********************************************************************************************************/
export const resetAuDirectStatusState = () => {
    const { dispatch } = store;
    dispatch({
        type: AUDIRECT_STATUS_RESET
    });
};

export const getAuDirectStatus = (id) => {
    return (dispatch) => {
        dispatch({
            type: AUDIRECT_STATUS_REQUEST
        });
        API.auDirect.GET.auDirectStatus(id)
            .then((response) => {
                const audirect_status_data = getDataFromSuccessResponse(response);
                dispatch({
                    type: AUDIRECT_STATUS_SUCCESS,
                    audirect_status_data
                });
            })
            .catch((error) => {
                dispatch({
                    type: AUDIRECT_STATUS_ERROR
                });
            });
    };
};

/**********************************************************************************************************
 *   GET CONTESTED DOMAINS LISTS
 **********************************************************************************************************/
export const getContestedDomainsLists = (payload) => {
    return (dispatch) => {
        dispatch({
            type: AUDIRECT_CONTESTED_DOMAINS_REQUEST
        });
        API.auDirect.GET.contestedDomains(payload)
            .then((response) => {
                const audirect_contested_domains_data = getDataFromSuccessResponse(response);
                dispatch({
                    type: AUDIRECT_CONTESTED_DOMAINS_SUCCESS,
                    audirect_contested_domains_data
                });
            })
            .catch((error) => {
                dispatch({
                    type: AUDIRECT_CONTESTED_DOMAINS_ERROR
                });
            });
    };
};

/**********************************************************************************************************
 *   GET MULTI CONTESTED CONTENTION DOMAINS
 **********************************************************************************************************/
export const getContentionDomains = (directAuDomain) => {
    return (dispatch) => {
        dispatch({
            type: AUDIRECT_CONTENTION_DOMAINS_REQUEST
        });
        API.auDirect.POST.contentionDomains(directAuDomain)
            .then((response) => {
                const data = getDataFromSuccessResponse(response);

                const eligibility_options = data.custom_fields
                    ?.find(({ field_name }) => field_name === 'Eligibility Type')
                    ?.field_options?.map((option) => ({ label: option, value: option }));

                const audirect_contention_domains_data = {
                    vip_primary_domain: data.vip_primary_domain,
                    registered_domains: data.registered_domains,
                    pricing: data.pricing,
                    au_direct_domain: data.au_direct_domain,
                    eligibility_options
                };

                dispatch({
                    type: AUDIRECT_CONTENTION_DOMAINS_SUCCESS,
                    audirect_contention_domains_data
                });
            })
            .catch((error) => {
                dispatch({
                    type: AUDIRECT_CONTENTION_DOMAINS_ERROR
                });
            });
    };
};

/**********************************************************************************************************
 *   SUBMIT MULTI CONTESTED APPLICATION
 **********************************************************************************************************/
export const submitApplication = (payload) => {
    return (dispatch) => {
        dispatch({
            type: AUDIRECT_APPLY_REQUEST
        });
        API.auDirect.POST.apply(payload)
            .then((response) => {
                const audirect_apply_data = getDataFromSuccessResponse(response);
                pushNotification({ status: 200, details: 'Application successfully submitted.' });
                dispatch({
                    type: AUDIRECT_APPLY_SUCCESS,
                    audirect_apply_data
                });
            })
            .catch((error) => {
                const audirect_apply_error = getErrorFromFailResponse(error);
                pushNotification(audirect_apply_error);
                dispatch({
                    type: AUDIRECT_APPLY_ERROR
                });
            });
    };
};

/**********************************************************************************************************
 *   UPDATE MULTI CONTESTED APPLICATION DETAILS
 **********************************************************************************************************/
export const updateApplicationDetails = (domainId, payload) => {
    return (dispatch) => {
        dispatch({
            type: AUDIRECT_UPDATE_APPLICATION_REQUEST
        });
        API.auDirect.POST.updateApplication(domainId, payload)
            .then((response) => {
                const audirect_update_application_data = getDataFromSuccessResponse(response);
                pushNotification({ status: 200, details: 'Details successfully updated.' });
                dispatch({
                    type: AUDIRECT_UPDATE_APPLICATION_SUCCESS,
                    audirect_update_application_data
                });
            })
            .catch((error) => {
                const audirect_update_application_error = getErrorFromFailResponse(error);
                pushNotification(audirect_update_application_error);
                dispatch({
                    type: AUDIRECT_UPDATE_APPLICATION_ERROR
                });
            });
    };
};

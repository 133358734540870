/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import classNames from 'classnames';
import React, { useState } from 'react';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import RequestLoader from 'components/Loaders/Request';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import { _NXImage as NXImage } from 'components/NXImage/_NXImage';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import './_NXImageWithLoader.scss';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * @type {React.FC<TNXImage.Props>}
 */
const _NXImageWithLoader = (props) => {
    /***** STATE *****/
    const [isLoading, setIsLoading] = useState(true);

    /***** RENDER HELPERS *****/
    const className = classNames('NXImageWithLoader', {
        'NXImageWithLoader--cover': props.cover,
        'NXImageWithLoader--contain': props.contain
    });

    /***** RENDER *****/
    return (
        <div className={className}>
            <NXImage src={props.src} alt={props.alt} onLoad={() => setIsLoading(false)} {...props} />
            {isLoading && <RequestLoader />}
        </div>
    );
};

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

export { _NXImageWithLoader };

/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import classNames from 'classnames';
import React from 'react';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import './_NXTableActions.scss';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * @type {NXTable.Actions.TBase}
 */
const Base = ({ children, className }) => <div className={classNames('NXTableActions', className)}>{children}</div>;

export default Base;

/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/

import React from 'react';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import { _Accordion as Accordion } from 'components/Accordion/_Accordion';
import { DragHandle } from 'components/DragHandle';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import { SortableItem } from 'components/SortableItem';
import './_AccordionWithDragHandle.scss';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * @type {React.FC<NAccordion.Props>}
 */
const _AccordionWithDragHandle = (props) => (
    <SortableItem id={props.id}>
        <Accordion className="AccordionWithDragHandle" {...props} preTitleContent={<DragHandle.DNDKit id={props.id} />} />
    </SortableItem>
);
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
export { _AccordionWithDragHandle };

import { useMutation } from '@tanstack/react-query';
import { getService } from 'containers/katana/queries/serviceID/getService';
import { KATANA_API } from 'utilities/api/katana';
import type { KatanaAPI } from 'utilities/api/katana/types';
import { handleDefaultErrorNotification, handleDefaultSuccessNotification } from 'utilities/methods/commonActions';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type Params = KatanaAPI.Katana.Site.ServiceID.Pages.PageID.Sections.Reorder.POST.Params;
type MainParams = Omit<Params, 'attributes'>;

/**********************************************************************************************************
 *   HOOK START
 **********************************************************************************************************/
function _useMutation(params: MainParams) {
    function handleMutation(attributes: Params['attributes']) {
        return KATANA_API.katana.site.service_id.pages.page_id.sections.reorder.POST({ ...params, attributes });
    }

    return useMutation({
        mutationFn: handleMutation,
        onSuccess: handleDefaultSuccessNotification,
        onError: handleDefaultErrorNotification,

        onSettled: () => {
            getService.invalidateQueries(params.serviceID);
        }
    });
}

export const reorderPageSections = Object.freeze({
    useMutation: _useMutation
});

import { SiteSetupStageOrder } from 'containers/katana/consts';

/**
 * @param {import('containers/katana/types').katanaLaunchCheckListEnumValues[]} launch_checklist
 * @returns {import('containers/katana/types').katanaLaunchCheckListEnumValues | null}
 */
export function getKatanaServiceCurrentSetupStage(launch_checklist = []) {
    for (const setupStage of SiteSetupStageOrder) {
        if (!launch_checklist.includes(setupStage)) {
            return setupStage;
        }
    }
    return null;
}

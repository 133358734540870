/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import { Input } from 'components/Form/Input';
import { TextArea } from 'components/Form/TextArea';
import NXBox from 'components/NXBox';
import Text from 'components/Utils/Text';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import ContentEditorModuleContent from 'containers/katana/containers/ContentEditorLightbox/contentEditorModuleContent';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { useUpdateKatanaSiteInfoMutation } from 'containers/katana/queries/useUpdateKatanaSiteInfoMutation';

const { TITLE, DESCRIPTION } = useUpdateKatanaSiteInfoMutation.FORM_FIELD_KEYS;

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
const _SearchEngineOptimisationContentModule = () => {
    /***** RENDER *****/
    return (
        <NXBox.TopAccordion
            title="SEO (Search Engine Optimisation)"
            description="SEO helps potential customers find your website in search engines like Google."
        >
            <NXBox.DefaultPadding>
                <ContentEditorModuleContent>
                    <Input.HookForm name={TITLE} label="Site Title" placeholder="E.g. John Smith's Cabinet Making" isPlaceholderItalic />
                    <Text secondary size--s italic>
                        This is the title for your website as it will appear in search engine results.
                    </Text>

                    <TextArea.HookForm
                        name={DESCRIPTION}
                        label="Description"
                        placeholder="E.g. Expert cabinet maker located in Melbourne. More than 15 years of experience in custom wardrobes and kitchens."
                        isPlaceholderItalic
                        rows={5}
                    />
                    <Text secondary size--s italic>
                        A description will be shown on the search engine result for your website. It helps your customers understand what your website
                        is about. We recommend having between 50-160 characters.
                    </Text>
                </ContentEditorModuleContent>
            </NXBox.DefaultPadding>
        </NXBox.TopAccordion>
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
const SearchEngineOptimisationContentModule = Object.assign(_SearchEngineOptimisationContentModule, {
    formFields: { TITLE, DESCRIPTION }
});

export { SearchEngineOptimisationContentModule };

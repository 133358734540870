/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { useLayoutEffect, useState } from 'react';

/**********************************************************************************************************
 *   HOOK START
 **********************************************************************************************************/
/**
 * hook returning a boolean based on the current viewport size. If no min or max is provided, it will always return true
 *
 * @param {{
 *   min?: number;
 *   max?: number;
 * }} props
 */
export const useMatchMedia = ({ min, max }) => {
    /***** STATE *****/
    const [isMatch, setIsMatch] = useState(false);

    /***** EFFECTS *****/
    useLayoutEffect(() => {
        const queries = [];
        min && queries.push(`(min-width: ${min}px)`);
        max && queries.push(`(max-width: ${max}px)`);

        const mediaQuery = window.matchMedia(queries.join(' and '));

        setIsMatch(mediaQuery.matches);

        const listener = () => {
            setIsMatch(mediaQuery.matches);
        };

        const legacySafariListener = (e) => {
            setIsMatch(!!e.matches);
        };

        /**
         * Safari 13 still uses ".addListener" instead of ".addEventListener" https://developer.mozilla.org/en-US/docs/Web/API/MediaQueryList/addListener
         */
        if (typeof mediaQuery?.addEventListener === 'function') {
            mediaQuery.addEventListener('change', listener);
        } else if (typeof mediaQuery?.addListener === 'function') {
            mediaQuery.addListener(legacySafariListener);
        }

        return () => {
            if (typeof mediaQuery?.removeEventListener === 'function') {
                mediaQuery.removeEventListener('change', listener);
            } else if (typeof mediaQuery?.removeListener === 'function') {
                mediaQuery.removeListener(legacySafariListener);
            }
        };
    }, [min, max]);

    /***** HOOK RESULTS *****/
    if (!min && !max) {
        return true;
    }

    return isMatch;
};
/**********************************************************************************************************
 *   HOOK END
 **********************************************************************************************************/

/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { loadIcons } from 'iconify-icon';
import React from 'react';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import Icon from './_Icon';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/

/**
 * @deprecated Use `PhosphorIcons` instead
 */
export function iconWrap(iconName) {
    function IconWrap(props) {
        return <Icon name={iconName} {...props} />;
    }

    return IconWrap;
}

/**
 * @type {Set<string>}
 */
const iconPreLoadList = new Set();

/**
 * @param {string} icon
 */
export function registerIconsForPreload(icon) {
    iconPreLoadList.add(icon);
}

export function preloadIcons() {
    loadIcons(Array.from(iconPreLoadList));
}

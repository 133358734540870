import type { KatanaAPI } from 'utilities/api/katana/types';

export function createPresetPreviewData(check_enable_site_data: KatanaAPI.Katana.ServiceID.ServiceDetails.GET._200Modified['data']) {
    return {
        id: check_enable_site_data?.domain_id,
        preset: 'katana' as const,
        attributes: {
            remove_conflicting: true,
            replacements: {
                hostname: check_enable_site_data?.hostname,
                server_hostname: check_enable_site_data?.server_hostname
            }
        }
    };
}

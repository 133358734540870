/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { get, has } from 'lodash';

/**
 * @description This function is used to recursively walk an object in reverse to find a key by path.
 * it will return the object that has the key if it is found, otherwise it will return undefined.
 * This function only works in a direct path and does not continue to find the key in every possible route
 */
export function recursivelyWalkObjectInReverseToFindKeyByPath(object: Record<string, unknown>, keyToFind: string, path: string) {
    const splitPath = path.split('.');

    const foundValue = get(object, path);
    if (foundValue && has(foundValue, keyToFind)) {
        return foundValue;
    }

    if (!foundValue && path.length === 0) {
        const mainObjectFind = get(object, keyToFind);

        if (mainObjectFind) {
            return object;
        }

        return undefined;
    }

    splitPath.pop();

    const newPath = splitPath.join('.');

    return recursivelyWalkObjectInReverseToFindKeyByPath(object, keyToFind, newPath);
}

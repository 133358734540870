import { parsePhoneNumber } from 'libphonenumber-js';

/**
 * Formats the phone number string to synergy format: +61.4XXXXXXXX
 * @param {string} value
 */
export function formatPhoneNumberSynergy(value) {
    try {
        const phoneNumber = parsePhoneNumber(value);
        if (phoneNumber) {
            const { countryCallingCode, nationalNumber } = phoneNumber;
            return `+${countryCallingCode}.${nationalNumber}`;
        }
    } catch {
        return value;
    }
}

export function generateSortedCountryOptions(options) {
    return [...options].sort((a, b) => {
        /**
         * International on top
         */
        if (!a.value) return -1;
        if (!b.value) return 1;

        /**
         * AU NZ Priority sort
         */
        if (a.value === 'AU') return -1;
        if (b.value === 'AU') return 1;
        if (a.value === 'NZ') return -1;
        if (b.value === 'NZ') return 1;

        /**
         * Fallback sort
         */
        if (['AU', 'NZ'].includes(a.value) && !['AU', 'NZ'].includes(b.value)) return -1;
        if (['AU', 'NZ'].includes(b.value) && !['AU', 'NZ'].includes(a.value)) return 1;

        return 0;
    });
}

export function getCountryCallingCode(parsedPhoneNumber, countryID) {
    return parsedPhoneNumber.metadata.country_calling_codes[countryID][0];
}

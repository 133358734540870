/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import classNames from 'classnames';
import React, { useRef } from 'react';
import 'react-calendar/dist/Calendar.css';
import 'react-clock/dist/Clock.css';
import DateTimePicker from 'react-datetime-picker';
import 'react-datetime-picker/dist/DateTimePicker.css';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import { FormItem } from 'components/Form/FormItem';
import { FormItemInner } from 'components/Form/FormItemInner';
import { FormLabel } from 'components/Form/FormLabel';

/*   ACTIONS
 *****************************************************/
import { useResetDateTimeValue } from 'components/Form/ControlledDateTimePicker/useResetDateTimeValue';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import './_renderDateTimePicker.scss';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * The redux form DateTimePicker render
 * @param {import('redux-form').BaseFieldProps & import('redux-form').WrappedFieldProps & {
 *      label: string,
 *      className: string,
 *      dateFormat: string,
 * }} props
 */
function RenderDateTimePicker(props) {
    const { label, meta, className, dateFormat, input, name } = props;
    const { value } = input;
    const formItemProps = { meta, className: classNames(className, 'form__datePicker') };

    /***** HOOKS *****/
    /**@type {React.MutableRefObject<HTMLDivElement>} */
    const formItemRef = useRef(null);
    const dateTimeValue = useResetDateTimeValue(formItemRef, value, formItemRef?.current?.closest('form'), ['clearFilter']);

    /***** RENDER HELPERS *****/
    const unWantedDateTimePickerProps = ['onFocus', 'onBlur', ...Object.keys(formItemProps)];
    const dateTimePickerProps = { ...input, ...props, format: dateFormat, value: dateTimeValue };

    unWantedDateTimePickerProps.forEach((key) => delete dateTimePickerProps[key]);

    /***** RENDER *****/
    return (
        <div ref={formItemRef}>
            <FormItem {...formItemProps}>
                <FormLabel htmlFor={name}>{label}</FormLabel>
                <FormItemInner meta={meta}>
                    <DateTimePicker {...dateTimePickerProps} />
                </FormItemInner>
            </FormItem>
        </div>
    );
}
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

export default RenderDateTimePicker;

/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { createRoute } from '@tanstack/react-router';
import { katanaQuery } from 'containers/katana/queries/tanstackTree';

/**********************************************************************************************************
 *   ROUTE IMPORTS
 **********************************************************************************************************/
import { VIPSitesEditorRoute } from 'containers/katana/routes/vip-sites.$katanaServiceId/_content-editor-lightbox/editor';
import { routerMiddleware } from 'router/utils/middleware';
import { lazyFN } from 'utilities/methods/tanstack/router/lazyFn';

/**********************************************************************************************************
 *   ROUTE START
 **********************************************************************************************************/
export const VIPSitesEditorBasicDetailsRoute = createRoute({
    getParentRoute: () => VIPSitesEditorRoute,
    path: 'basic-details',
    loader({ params }) {
        katanaQuery.serviceID.getBusinessInfo.prefetchQuery(+params.katanaServiceId);
        katanaQuery.serviceID.getSiteInfo.prefetchQuery(+params.katanaServiceId);
    },
    beforeLoad(opts) {
        routerMiddleware.business(this, opts);
        routerMiddleware.authentication('user', opts);
    }
}).lazy(lazyFN(() => import('./basic-details.lazy'), 'LazyVIPSitesEditorBasicDetailsRoute'));

/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import { useNavigate, useSearch } from '@tanstack/react-router';
import Avatar from 'components/Placeholders/Avatar';
import SolidTag from 'components/Tags/SolidTag';
import React from 'react';
import { useDispatch } from 'react-redux';
import { selectAccount } from '../action';

const backgrounds = [
    'linear-gradient(90deg, #0a9fe5, #10c5ef)',
    'linear-gradient(90deg, #5956d7, #c544fb)',
    'linear-gradient(90deg, #0dd41a, #86fc6f)',
    'linear-gradient(90deg, #c32888, #a01e54)',
    'linear-gradient(90deg, #f4ad26, #ca3c78)',
    'linear-gradient(90deg, #0575e6, #0054a8)',
    'linear-gradient(90deg, #f6d365, #fda085)',
    'linear-gradient(90deg, #84fab0, #8fd3f4)'
];

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type AccountSelect = React.FC<{
    accountList: Array<{
        attributes: {
            firstname: string;
            lastname: string;
            company: string;
            role: string;
        };
        id: string;
    }>;
}>;

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
// eslint-disable-next-line no-redeclare, @typescript-eslint/no-redeclare
const AccountSelect: AccountSelect = ({ accountList }) => {
    /***** HOOKS *****/
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { ref } = useSearch({ strict: false });

    /***** FUNCTIONS *****/
    const handleSelect = (id: any) => {
        const push = (to: any) => navigate({ to });
        return () => selectAccount(id, { push }, ref)(dispatch);
    };

    /***** RENDER *****/
    return (
        <div className="accountSelect">
            {!!accountList.length &&
                accountList.map(({ attributes: { firstname, lastname, company, role }, id }, index) => (
                    <button key={index} className="accountSelect__account" onClick={handleSelect(id)}>
                        <div className="accountSelect__container">
                            <div className="accountSelect__container--section">
                                <div className="accountSelect__initial">
                                    <Avatar fullname={`${firstname} ${lastname}`} size={45} fontSize={14} background={backgrounds[index]} />
                                </div>
                                <div
                                    className={`accountSelect__type--${company ? 'business' : 'personal'} ${role !== 'account holder' ? 'full' : ''}`}
                                >
                                    <div className="accountSelect__company">{company}</div>
                                    <div className="accountSelect__name">{`${firstname} ${lastname}`}</div>
                                    {role === 'account holder' && <SolidTag>Your account</SolidTag>}
                                </div>
                            </div>
                        </div>
                    </button>
                ))}
        </div>
    );
};

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

export default AccountSelect;

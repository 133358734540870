import { pushNotification } from 'components/Toast/functions';
import { contactInformationZodSchema } from 'containers/katana/containers/ContentEditorLightbox/formHandlers/contactInformationFormHandler/consts';
import { useSiteDataPreviewValues } from 'containers/katana/containers/ContentEditorLightbox/hooks/useSiteDataPreviewValues';
import { useKatanaNextPage } from 'containers/katana/hooks/useKatanaNextPage';
import { useKatanaParams } from 'containers/katana/hooks/useSetupEditorRouteParams';
import { useUpdateKatanaSiteBusinessInfoMutation } from 'containers/katana/queries/useUpdateKatanaSiteBusinessInfoMutation';
import type { KatanaNamespace } from 'containers/katana/types';
import _ from 'lodash';

/**********************************************************************************************************
 *   HOOK START
 **********************************************************************************************************/
export function useHandleContactInformationSubmit(subpageRouteData: KatanaNamespace.SubPageRouteData) {
    /***** HOOKS *****/
    const { katanaServiceId, page } = useKatanaParams();
    const { submitSiteData } = useSiteDataPreviewValues();
    const { performRouting } = useKatanaNextPage(subpageRouteData);

    /***** QUERIES *****/
    const { mutateAsync: mutateUpdateKatanaSiteBusinessInfo } = useUpdateKatanaSiteBusinessInfoMutation(katanaServiceId, { onSuccess: _.noop });

    /***** HOOK RESULTS *****/
    return async function handleSubmit(values: Record<string, any>) {
        const clonedValues = _.cloneDeep(values);
        const parseRes = contactInformationZodSchema.safeParse(clonedValues);
        if (!parseRes.data) {
            pushNotification({ status: 400, message: 'Invalid form data.' });
            return;
        }
        const promises = [mutateUpdateKatanaSiteBusinessInfo(parseRes.data)];

        submitSiteData(promises);

        return Promise.all(promises).then((e) => {
            if (page !== 'setup') {
                pushNotification({ status: 200, message: 'Successfully updated site details.' });
            }
            performRouting();
            return e;
        });
    };
}

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { getRepeatedValidationMaxTypeMessage } from 'utilities/methods/form/validations/validationMaximum';
import { validateMaximum } from 'utilities/methods/validators';

/**********************************************************************************************************
 *   TYPE IMPORTS
 **********************************************************************************************************/
import type { KatanaNamespace } from 'containers/katana/types';

export function formFieldsMaxRuleParser(validation: KatanaNamespace.SectionDefinitions.PropertiesModified['validation'][number]) {
    return (value: any) => {
        const textResult = getRepeatedValidationMaxTypeMessage(value, validation?.value);
        const result = validateMaximum(value, validation?.value);
        return result ? undefined : textResult;
    };
}

/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { animateScroll as scroll } from 'react-scroll';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import NXBox from 'components/NXBox';
import { ServiceOverviewBanner } from 'components/ServiceOverviewBanner/';
import { Flex } from 'components/Utils/Flex';
import Padding from 'components/Utils/Padding';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import PurchasePopup from 'containers/email/dropdowns/purchaseDropdown';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { useAppViewport } from 'utilities/hooks/useAppViewport/useAppViewport';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import upgradeImage from 'assets/images/email/email_banner_icon.svg';
import upgradeBackgroundImage from 'assets/images/email/email_hosting_banner_background.png';
import { application } from 'config/config';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const EmailHostingBanner = ({ handleMailboxSubmit }) => {
    /***** STATE *****/
    const [showDropdown, setShowDropdown] = React.useState(false);
    const purchaseRef = React.useRef(null);

    /***** HOOKS *****/
    const { email_purchase_mailbox_status, email_purchase_mailbox_data, email_purchase_mailbox_error } = useSelector(({ email }) => email);
    const isMobile = useAppViewport(['xs', 'sm']);

    /***** EFFECTS *****/
    useEffect(() => {
        const purchase = new URLSearchParams(window.location.search).get('purchase');

        if (purchase) {
            setShowDropdown(true);
            scroll.scrollTo(purchaseRef.current.getBoundingClientRect().top);
        }
    }, []);

    /***** RENDER *****/
    return (
        <div ref={purchaseRef}>
            <ServiceOverviewBanner src={upgradeBackgroundImage}>
                <ServiceOverviewBanner.ContentWrapper>
                    <ServiceOverviewBanner.Heading>Add Email Hosting?</ServiceOverviewBanner.Heading>
                    <ServiceOverviewBanner.Description>
                        You can purchase additional email hosting services through your {application} account.
                    </ServiceOverviewBanner.Description>
                    <ServiceOverviewBanner.Button type="onClick" onClick={() => setShowDropdown(!showDropdown)}>
                        Get Started
                    </ServiceOverviewBanner.Button>
                </ServiceOverviewBanner.ContentWrapper>
                <ServiceOverviewBanner.Image src={upgradeImage} alt="Purchase Hosting" />
            </ServiceOverviewBanner>
            {showDropdown && (
                <Padding top={isMobile && 5}>
                    <NXBox>
                        <NXBox.Dropdown embedded isOpen={showDropdown}>
                            <NXBox.DefaultPadding>
                                <Flex justify="between">
                                    <NXBox.Title title="Mailbox details" />
                                    <NXBox.Dropdown.CloseButton onClose={() => setShowDropdown(false)} />
                                </Flex>
                                <PurchasePopup
                                    // @ts-ignore
                                    submitRequest={handleMailboxSubmit}
                                    disable={{ billing: false }}
                                    statuses={{
                                        status: email_purchase_mailbox_status,
                                        data: email_purchase_mailbox_data,
                                        error: email_purchase_mailbox_error
                                    }}
                                    setShowDropdown={setShowDropdown}
                                />
                            </NXBox.DefaultPadding>
                        </NXBox.Dropdown>
                    </NXBox>
                </Padding>
            )}
        </div>
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

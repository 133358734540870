/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { createRoute } from '@tanstack/react-router';

/**********************************************************************************************************
 *   ROUTE IMPORTS
 **********************************************************************************************************/
import { HostingSubaccountRoute } from 'containers/hosting/routes/subaccount.$subaccountId';
import { routerMiddleware } from 'router/utils/middleware';
import { lazyFN } from 'utilities/methods/tanstack/router/lazyFn';

/**********************************************************************************************************
 *   ROUTE START
 **********************************************************************************************************/
export const HostingSubaccountSecurityRoute = createRoute({
    getParentRoute: () => HostingSubaccountRoute,
    path: 'security',
    beforeLoad(opts) {
        routerMiddleware.business(this, opts);
        routerMiddleware.authentication('user', opts);
    }
}).lazy(lazyFN(() => import('./security.lazy'), 'LazyHostingSubaccountSecurityRoute'));

import NXBox from 'components/NXBox';
import { NavLabelsFieldHookForm } from 'containers/katana/containers/contentEditorModules/navigationLabels/navLabelsFieldHookForm';
import { usePostNavigationItemsMutation } from 'containers/katana/queries/usePostNavigationItemsMutation';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
const { SECTION_IDS } = usePostNavigationItemsMutation.FORM_FIELD_KEYS;

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
const _NavigationLabelsContentModule = () => (
    <NXBox.TopAccordion title="Menu Labels" description="Enable your website visitors to navigate to different sections of your website.">
        <NXBox.DefaultPadding>
            <NavLabelsFieldHookForm name={SECTION_IDS} />
        </NXBox.DefaultPadding>
    </NXBox.TopAccordion>
);

const NavigationLabelsContentModule = Object.assign(_NavigationLabelsContentModule, {
    formFields: { SECTION_IDS }
});

export { NavigationLabelsContentModule };

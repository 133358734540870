export const tiers = /** @type {const} */ ({
    VIP: 'vip',
    BRONZE: 'bronze',
    SILVER: 'silver',
    GOLD: 'gold',
    PLATINUM: 'platinum'
});

export const orderedTiers = [tiers.VIP, tiers.BRONZE, tiers.SILVER, tiers.GOLD, tiers.PLATINUM];

export const vipRewardsReadableName = 'VIPrewards';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import PromoCodePill from '../';
import Icons from 'components/Icons';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import './_PromoCodePillApplied.scss';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const PromoCodePillApplied = () => {
    /***** RENDER *****/
    return (
        <>
            <div className="PromoCodePillApplied">
                <div className="PromoCodePillApplied__background" />
                <PromoCodePill
                    code={
                        <div>
                            <Icons.TickSolid />
                            Applied
                        </div>
                    }
                    isRemoveable={false}
                />
            </div>
        </>
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

export default PromoCodePillApplied;

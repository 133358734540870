/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React from 'react';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import Text from 'components/Utils/Text';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * @type {React.FC<{
 *      children: React.ReactNode;
 * }>}
 */
const _NXBoxSubHeading = ({ children }) => (
    <Text secondary size--m semiBold lead--l>
        {children}
    </Text>
);

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
export default _NXBoxSubHeading;

/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { createRoute } from '@tanstack/react-router';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { lazyFN } from 'utilities/methods/tanstack/router/lazyFn';

/**********************************************************************************************************
 *   ROUTE IMPORTS
 **********************************************************************************************************/
import { ManageSSLRoute } from 'containers/ssl';
import { routerMiddleware } from 'router/utils/middleware';

/**********************************************************************************************************
 *   ROUTE START
 **********************************************************************************************************/
export const SSLConfigureRoute = createRoute({
    getParentRoute: () => ManageSSLRoute,
    path: 'configure',
    beforeLoad(opts) {
        routerMiddleware.business(this, opts);
        routerMiddleware.authentication('user', opts);
    }
}).lazy(lazyFN(() => import('./configure.lazy'), 'LazySSLConfigureRoute'));

/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React from 'react';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import SortableButtonWrapper from 'components/NXTable/SortableButtonWrapper';
import { SortableButton } from 'components/SortableButton';
import Title from '../Title';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * @type {NXTable.Header.TSortableTitle}
 */
export const SortableTitle = ({ sortKey, children, disabled }) =>
    disabled ? (
        <Title>{children}</Title>
    ) : (
        <SortableButtonWrapper>
            <SortableButton sortKey={sortKey}>
                <Title>{children}</Title>
            </SortableButton>
        </SortableButtonWrapper>
    );
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

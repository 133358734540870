import { katanaHookFormData } from 'containers/katana/components/HookFormHandler/consts';
import { useEffect } from 'react';
import { useFormState, useWatch } from 'react-hook-form';

/**
 * To be used within a `FormProvider` to extract the values of the form into a store
 * There should never be more than 1 instance of this component running at one time.
 */
export const KatanaPreviewFormDataExtractor = () => {
    /***** HOOKS *****/
    const values = useWatch();
    const { errors } = useFormState();

    /***** EFFECTS *****/
    useEffect(() => {
        const state = {
            values,
            errors
        };

        katanaHookFormData.setState(() => state);
    }, [values, errors]);

    /***** RENDER *****/
    return null;
};

import { z } from 'zod';

const RichTextDataZod = z.object({
    type: z.literal('markdown'),
    content: z.string().nullable()
});

export type RichTextData = z.infer<typeof RichTextDataZod>;

export function isRichTextData(value: unknown): value is RichTextData {
    return RichTextDataZod.safeParse(value).success;
}

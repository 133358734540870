/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React from 'react';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import { ServiceOverviewBanner } from 'components/ServiceOverviewBanner/';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { getPurchaseLink } from 'containers/services/consts';
import { useMatchMedia } from 'utilities/hooks/useMatchMedia';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import upgradeBackgroundImage from 'assets/images/vps/vps_banner_background.jpeg';
import upgradeImage from 'assets/images/vps/vps_banner_icon.svg';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const VPSBanner = () => {
    /***** HOOKS *****/
    const justBeforeMobileThreshold = useMatchMedia({ min: 769, max: 890 });

    /***** RENDER *****/
    return (
        <ServiceOverviewBanner src={upgradeBackgroundImage}>
            <ServiceOverviewBanner.ContentWrapper>
                <ServiceOverviewBanner.Heading size--custom={justBeforeMobileThreshold && 25}>
                    Need a Virtual Private Server?
                </ServiceOverviewBanner.Heading>
                <ServiceOverviewBanner.Description size--custom={justBeforeMobileThreshold && 16}>
                    Fully-Managed and Self-Managed SSD Virtual Private Servers located right here in Australia.
                </ServiceOverviewBanner.Description>
                <ServiceOverviewBanner.Button href={getPurchaseLink('VPS', { utmMedium: 'service-banner' })} target="_blank">
                    View Pricing
                </ServiceOverviewBanner.Button>
            </ServiceOverviewBanner.ContentWrapper>
            <ServiceOverviewBanner.Image src={upgradeImage} alt="Purchase Hosting" />
        </ServiceOverviewBanner>
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

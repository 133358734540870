import React from 'react';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * Wrap your AcknowledgementBox components with this component to have the lines between them be flush.
 * Allows the acknowledgement box to have flush transition in the borders between multiple ones
 * This is a compound private component.
 */
function _AcknowledgementBoxFlushBox({ children }) {
    /***** RENDER *****/
    return <div className="flush_box">{children}</div>;
}

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
export { _AcknowledgementBoxFlushBox };

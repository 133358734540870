import type { NXUtils } from 'utilities/types';

export function createOptimisticResponseObject<TData extends object>(params: NXUtils.TODO): TData {
    return {
        status: 200,
        links: {
            self: 'optimistic_response_object'
        },
        data: params,
        message: 'Optimistic Response Object'
    } as any;
}

/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { useMutation } from '@tanstack/react-query';

/**********************************************************************************************************
 *   QUERIES
 **********************************************************************************************************/
import { katanaQuery } from 'containers/katana/queries/tanstackTree';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { useFormValuesFileUpload } from 'containers/katana/hooks/useFormValuesFileUpload';
import { useKatanaParams } from 'containers/katana/hooks/useSetupEditorRouteParams';
import { filterEndpointAttributeKeys } from 'containers/katana/queries/methods/filterEndpointAttributeKeys';
import { KATANA_API } from 'utilities/api/katana';
import { handleDefaultErrorNotification, handleDefaultSuccessNotification } from 'utilities/methods/commonActions';

/**********************************************************************************************************
 *   TYPE IMPORTS
 **********************************************************************************************************/
import _ from 'lodash';
import type { KatanaAPI } from 'utilities/api/katana/types';

/**********************************************************************************************************
 *   Katana Update site business info
 **********************************************************************************************************/
/**********************************************************************************************************
 *   HOOK START
 **********************************************************************************************************/
/**
 * Gets the site info
 */
function useUpdateKatanaSiteBusinessInfoMutation(serviceID: number, options = {}) {
    /***** HOOKS *****/
    const { page } = useKatanaParams();
    const processAndUploadFiles = useFormValuesFileUpload(serviceID);

    /***** FUNCTIONS *****/
    async function handleMutationAsync(values: KatanaAPI.Katana.ServiceID.Business.POST.Params['attributes']) {
        if (!values) return;
        const filteredAttributes = filterEndpointAttributeKeys(values, useUpdateKatanaSiteBusinessInfoMutation.acceptableKeys);
        const fileRecords = await processAndUploadFiles(filteredAttributes);
        const updatedAttributes = _.merge(filteredAttributes, fileRecords) as KatanaAPI.Katana.ServiceID.Business.POST.Params['attributes'];

        if (!Object.keys(updatedAttributes).length) {
            return Promise.resolve();
        }
        if (_.has(updatedAttributes, 'logo')) {
            const getLogo = () => {
                if (typeof updatedAttributes.logo === 'string') {
                    return updatedAttributes.logo;
                } else if (updatedAttributes.logo?.url) {
                    return updatedAttributes.logo?.url;
                }

                return null;
            };

            const logo = getLogo();

            katanaQuery.serviceID.getBusinessInfo.optimistic(serviceID, 'data.logo', logo);
            katanaQuery.serviceID.getService.optimistic(serviceID, 'data.business.logo', logo);
        }

        if (_.has(updatedAttributes, 'business_categories') && Array.isArray(updatedAttributes.business_categories)) {
            katanaQuery.serviceID.getBusinessInfo.optimistic(serviceID, 'data.business_categories', updatedAttributes.business_categories);
            katanaQuery.serviceID.getService.optimistic(serviceID, 'data.attributes.business_categories', updatedAttributes.business_categories);
        }

        return KATANA_API.katana.service_id.business.POST({ serviceID, attributes: updatedAttributes });
    }

    function handleMutation(values: KatanaAPI.Katana.ServiceID.Business.POST.Params['attributes']) {
        return handleMutationAsync(values);
    }

    /***** HOOK RESULTS *****/
    return useMutation({
        mutationFn: handleMutation,
        onError: handleDefaultErrorNotification,
        onSuccess: page === 'setup' ? undefined : handleDefaultSuccessNotification,
        onSettled: () => {
            katanaQuery.serviceID.getService.invalidateQueries(serviceID);
        },
        ...options
    });
}

/**********************************************************************************************************
 *   HOOK END
 **********************************************************************************************************/

useUpdateKatanaSiteBusinessInfoMutation.FORM_FIELD_KEYS = {
    ADDRESS: 'address',
    BUSINESS_CATEGORIES: 'business_categories',
    BUSINESS_NUMBER: 'business_number',
    BUSINESS_NUMBER_TYPE: 'business_number_type',
    EMAIL: 'email',
    LOGO: 'logo',
    NAME: 'name',
    OPERATING_HOURS: 'operating_hours',
    PHONE: 'phone',
    TAG_LINE: 'tag_line'
} as const;

useUpdateKatanaSiteBusinessInfoMutation.acceptableKeys = Object.values(useUpdateKatanaSiteBusinessInfoMutation.FORM_FIELD_KEYS);

export { useUpdateKatanaSiteBusinessInfoMutation };

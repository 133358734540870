/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { validatorDomainAndSubDomain } from 'utilities/methods/validators';
import { validateHref } from 'utilities/methods/validators/validateHref/validateHref';
import { validateQueryParamDomain } from 'utilities/methods/validators/validateQueryParamDomain/validateQueryParamDomain';

export function validateHrefOptionalScheme(link: string) {
    const validateQueryParam = validateQueryParamDomain({ domainValidator: validatorDomainAndSubDomain, areQueryParamsOptional: true });
    if (validateQueryParam(link)) {
        return true;
    }

    return validateHref(link);
}

/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React from 'react';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import { ServiceOverviewBanner } from 'components/ServiceOverviewBanner/';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { useMatchMedia } from 'utilities/hooks/useMatchMedia';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import upgradeBackgroundImage from 'assets/images/ms365/ms365_banner_background.jpeg';
import upgradeImage from 'assets/images/ms365/ms365_banner_icon.png';
import { getPurchaseLink } from 'containers/services/consts';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const Microsoft365Banner = () => {
    /***** HOOKS *****/
    const justBeforeMobileThreshold = useMatchMedia({ min: 769, max: 890 });

    /***** RENDER *****/
    return (
        <ServiceOverviewBanner src={upgradeBackgroundImage}>
            <ServiceOverviewBanner.ContentWrapper>
                <ServiceOverviewBanner.Heading size--custom={justBeforeMobileThreshold && 26}>
                    Add a Microsoft 365 Service
                </ServiceOverviewBanner.Heading>
                <ServiceOverviewBanner.Description size--custom={justBeforeMobileThreshold && 16}>
                    The all-new Microsoft 365 lets you create, share and collaborate all in one place with your favourite apps.
                </ServiceOverviewBanner.Description>
                <ServiceOverviewBanner.Button href={getPurchaseLink('Microsoft 365', { utmMedium: 'service-banner' })} target="_blank">
                    View Pricing
                </ServiceOverviewBanner.Button>
            </ServiceOverviewBanner.ContentWrapper>
            <ServiceOverviewBanner.Image src={upgradeImage} alt="Purchase Microsoft 365" />
        </ServiceOverviewBanner>
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { queryOptions, useQuery } from '@tanstack/react-query';

/**********************************************************************************************************
 *   QUERIES
 **********************************************************************************************************/
import queryClient from 'store/queryClient';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { KATANA_API } from 'utilities/api/katana';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import { katanaQueryKeys } from 'containers/katana/queries/katanaQueryKeys';
import { MINUTE } from 'utilities/consts';

function createQueryKey(domain: string) {
    return katanaQueryKeys.katana.checkDomain(domain);
}

function createQueryOptions(domain: string, enabled = true) {
    return queryOptions({
        queryKey: createQueryKey(domain),
        queryFn: () => KATANA_API.katana.check.POST(domain),
        staleTime: MINUTE * 5,
        enabled: enabled && Boolean(domain),
        select: (data) => {
            return data.data;
        }
    });
}

function invalidateQueries(domain: string) {
    return queryClient.invalidateQueries({
        queryKey: createQueryKey(domain)
    });
}

/**********************************************************************************************************
 *   HOOK START
 **********************************************************************************************************/
function _useQuery(domain: string, enabled?: boolean) {
    return useQuery(createQueryOptions(domain, enabled));
}
/**********************************************************************************************************
 *   HOOK END
 **********************************************************************************************************/

export const checkDomain = /** @type {const} */ {
    useQuery: _useQuery,
    createQueryKey,
    createQueryOptions,
    invalidateQueries
};

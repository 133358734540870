import { queryOptions, useQuery } from '@tanstack/react-query';
import { katanaQueryKeys } from 'containers/katana/queries/katanaQueryKeys';
import { applyNewSectionSort } from 'containers/katana/queries/methods/applyNewSectionSort';
import { sortSectionIntoCorrectOrder } from 'containers/katana/queries/methods/sortSectionOrder';
import type { KatanaNamespace } from 'containers/katana/types';
import _, { cloneDeep, has } from 'lodash';
import queryClient from 'store/queryClient';
import { KATANA_API } from 'utilities/api/katana';
import type { KatanaAPI } from 'utilities/api/katana/types';
import { SECOND } from 'utilities/consts';
import { handleDefaultErrorNotification } from 'utilities/methods/commonActions';
import { typedSet } from 'utilities/methods/objectMethods';
import { createGetQueryDataMethod } from 'utilities/methods/tanstack/createGetQueryDataMethod';
import { createGetQueryStateMethod } from 'utilities/methods/tanstack/createGetQueryStateMethod';
import { createOptimisticMethods } from 'utilities/methods/tanstack/createOptimisticMethods';
import { createSetQueryDataMethod } from 'utilities/methods/tanstack/createSetQueryDataMethod';
import { createUseQuerySelectorMethod } from 'utilities/methods/tanstack/createUseQuerySelectorMethod';
import type { NXQueryUtils } from 'utilities/methods/tanstack/types';

type TData = KatanaAPI.Katana.Site.ServiceID.Sections.GET.ReturnTypeRaw;

function createQueryKey(serviceID: KatanaNamespace.ServiceID) {
    return katanaQueryKeys.katana.service.ID.sections(serviceID);
}

function createQueryOptions(serviceID: KatanaNamespace.ServiceID) {
    return queryOptions({
        queryKey: createQueryKey(serviceID),
        queryFn: () =>
            KATANA_API.katana.site.service_id.sections.GET(serviceID).catch((e) => {
                handleDefaultErrorNotification(e);
                throw e;
            }),
        staleTime: SECOND * 25,
        enabled: Boolean(serviceID),
        refetchOnMount: false,
        select: (data) => {
            if (data?.status === 200) {
                return data.data;
            }
        }
    });
}
const getQueryData = createGetQueryDataMethod<KatanaNamespace.ServiceID, NXQueryUtils.ApiData200<TData>>(createQueryKey);
const setQueryData = createSetQueryDataMethod<KatanaNamespace.ServiceID, NXQueryUtils.ApiData200<TData>>(createQueryKey);
const getQueryState = createGetQueryStateMethod<KatanaNamespace.ServiceID, NXQueryUtils.ApiData200<TData>>(createQueryKey);

function invalidateQueries(serviceID: KatanaNamespace.ServiceID) {
    return Promise.all([
        queryClient.invalidateQueries({ queryKey: createQueryKey(serviceID) }),
        queryClient.invalidateQueries({ queryKey: katanaQueryKeys.katana.service.ID.preview(serviceID) }),
        queryClient.invalidateQueries({ queryKey: katanaQueryKeys.katana.service.ID(serviceID), exact: true })
    ]);
}

function prefetchQuery(serviceID: KatanaNamespace.ServiceID) {
    return queryClient.prefetchQuery(createQueryOptions(serviceID));
}

function cancelQueries(serviceID: KatanaNamespace.ServiceID) {
    return queryClient.cancelQueries({ queryKey: createQueryKey(serviceID) });
}

function useIsFetching(serviceID: KatanaNamespace.ServiceID) {
    return queryClient.isFetching({ queryKey: createQueryKey(serviceID) });
}

/**********************************************************************************************************
 *   HOOK START
 **********************************************************************************************************/
/**
 * Gets the sections on the site
 */
function _useQuery(serviceID: KatanaNamespace.ServiceID) {
    return useQuery(createQueryOptions(serviceID));
}
const useQuerySelector = createUseQuerySelectorMethod<KatanaNamespace.ServiceID, NXQuery.ApiData200<TData>, typeof createQueryOptions>(
    createQueryOptions
);

function useGetIDByDefinitionID(serviceID: KatanaNamespace.ServiceID, sectionDefinitionID: KatanaNamespace.SectionDefinitionID) {
    const query = _useQuery(serviceID);
    const { data: get_katana_site_sections_data } = query;

    if (!_.isArray(get_katana_site_sections_data)) {
        return { sectionID: null, query };
    }

    const section = get_katana_site_sections_data.find((section) => section.section_id === sectionDefinitionID);

    return { sectionID: section?.id ?? null, query };
}

/**********************************************************************************************************
 *   HOOK END
 **********************************************************************************************************/

/**
 * @deprecated use `optimistic` instead
 */
function optimisticSectionSort(serviceID: KatanaNamespace.ServiceID, sectionIDs: KatanaNamespace.SectionID[]) {
    setQueryData(serviceID, (oldData) => {
        if (oldData?.status !== 200) {
            return;
        }

        const clonedData = cloneDeep(oldData);

        if (!has(clonedData, 'data')) {
            return;
        }

        clonedData.data = applyNewSectionSort(sectionIDs, clonedData.data);

        return clonedData;
    });
}

/**
 * @deprecated use `optimistic` instead
 */
function optimisticNameUpdate(serviceID: KatanaNamespace.ServiceID, sectionID: KatanaNamespace.SectionID, name: string) {
    setQueryData(serviceID, (oldData) => {
        if (oldData?.status !== 200) {
            return;
        }

        const clonedData = cloneDeep(oldData);

        if (!has(clonedData, 'data')) {
            return;
        }

        const section = clonedData.data.find((section) => section.id === sectionID);
        if (!section) {
            return;
        }

        typedSet(section, 'name', name);

        return clonedData;
    });
}

/**
 * @deprecated use `optimistic` instead
 */
function optimisticSectionAddition(serviceID: KatanaNamespace.ServiceID, sectionData: Artah.Katana.Site.ID.Sections.GET._200['data'][number]) {
    setQueryData(serviceID, (oldData) => {
        if (oldData?.status !== 200) {
            return;
        }

        const clonedData = cloneDeep(oldData);

        if (!has(clonedData, 'data')) {
            return;
        }

        clonedData.data.push(sectionData);

        sortSectionIntoCorrectOrder(clonedData.data);

        return clonedData;
    });
}

/**
 * @deprecated use `optimistic` instead
 */
function optimisticSectionPropertiesUpdate(
    serviceID: KatanaNamespace.ServiceID,
    sectionID: KatanaNamespace.SectionID,
    propertiesData: Artah.Katana.Site.ID.Sections.GET._200['data'][number]['properties']
) {
    setQueryData(serviceID, (oldData) => {
        if (oldData?.status !== 200) {
            return;
        }

        const clonedData = cloneDeep(oldData);

        if (!has(clonedData, 'data')) {
            return;
        }

        const section = clonedData.data.find((section) => section.id === sectionID);
        if (!section) {
            return;
        }

        section.properties = propertiesData;

        return clonedData;
    });
}

export const sections = Object.freeze({
    useQuery: _useQuery,
    useGetIDByDefinitionID,
    useIsFetching,
    optimisticSectionAddition,
    optimisticSectionPropertiesUpdate,
    optimisticSectionSort,
    optimisticNameUpdate,
    optimistic: createOptimisticMethods(setQueryData),
    cancelQueries,
    invalidateQueries,
    prefetchQuery,
    getQueryData,
    getQueryState,
    setQueryData,
    createQueryKey,
    useQuerySelector,
    createQueryOptions
});

/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { useMemo } from 'react';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import { CardSelectGrid } from 'components/Form/CardSelectGrid';
import { CheckBoxList } from 'components/Form/CheckBoxList';
import RequestLoader from 'components/Loaders/Request';
import NXBox from 'components/NXBox';
import Text from 'components/Utils/Text';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import ContentEditorModuleContent from 'containers/katana/containers/ContentEditorLightbox/contentEditorModuleContent';

/**********************************************************************************************************
 *   QUERIES
 **********************************************************************************************************/
import { katanaQuery } from 'containers/katana/queries/tanstackTree';
import { usePostNavigationMutation } from 'containers/katana/queries/usePostNavigationMutation';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { useKatanaParams } from 'containers/katana/hooks/useSetupEditorRouteParams';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import type { CardSelectGridNamespace } from 'components/Form/CardSelectGrid/types';
import { getColourStyleSelectContent } from 'containers/katana/containers/ContentEditorLightbox/methods/getColourStyleSelectContent';
const { COLOR, TRANSPARENT, VISIBLE } = usePostNavigationMutation.FORM_FIELD_KEYS;

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
const _NavigationColourThemeContentModule = () => {
    /***** HOOKS *****/
    const { katanaServiceId } = useKatanaParams();

    /***** QUERIES *****/
    const { data: get_site_info_data, isLoading: isGetKatanaSiteInfoLoading } = katanaQuery.serviceID.getSiteInfo.useQuery(katanaServiceId);

    /***** RENDER HELPERS *****/
    const constructedColourOptions: CardSelectGridNamespace.SelectCard.CustomOption[] = useMemo(() => {
        if (!get_site_info_data?.color) {
            return [];
        }
        return [
            { type: 'custom', value: 'white', selectionText: 'Light Contrast', content: getColourStyleSelectContent('light') },
            {
                type: 'custom',
                value: get_site_info_data?.color,
                selectionText: 'Colour Contrast',
                content: getColourStyleSelectContent('standard')
            },
            { type: 'custom', value: 'black', selectionText: 'Dark Contrast', content: getColourStyleSelectContent('dark') }
        ];
    }, [get_site_info_data]);

    const colourOptions = isGetKatanaSiteInfoLoading ? [] : constructedColourOptions;

    /***** RENDER *****/
    return (
        <NXBox.TopAccordion title="Appearence" description="Select a colour that you'd like to use for the menu background.">
            <NXBox.DefaultPadding>
                <ContentEditorModuleContent>
                    {isGetKatanaSiteInfoLoading ? (
                        <RequestLoader message="Loading Navigation Colours..." />
                    ) : (
                        <>
                            <CardSelectGrid.HookForm key={get_site_info_data?.color} name={COLOR} itemsPerRow={3} options={colourOptions} />
                            <CheckBoxList.Item.HookForm name={TRANSPARENT}>
                                <Text semiBold black size--m>
                                    Make my navigation background transparent
                                </Text>
                            </CheckBoxList.Item.HookForm>
                            <Text secondary size--s italic>
                                This feature is currently not compatible with all section and style configurations. It is recommended to be used with
                                either the Hero Banner or About Us sections.
                            </Text>
                            <CheckBoxList.Item.HookForm name={VISIBLE} inverse>
                                <Text semiBold black size--m>
                                    Hide my navigation
                                </Text>
                            </CheckBoxList.Item.HookForm>
                            <Text secondary size--s italic>
                                Enabling this setting will prevent both your navigation and footer from displaying. This can be useful when creating a
                                very simplistic website.
                            </Text>
                        </>
                    )}
                </ContentEditorModuleContent>
            </NXBox.DefaultPadding>
        </NXBox.TopAccordion>
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
export const NavigationColourThemeContentModule = Object.assign(_NavigationColourThemeContentModule, {
    formFields: { COLOR, TRANSPARENT }
});

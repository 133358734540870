/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React from 'react';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import NXTable from 'components/NXTable';
import { Subset } from 'components/Subset';
import Text from 'components/Utils/Text';
import { WrapWithComponent } from 'components/WrapWithComponent';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import { useVPSOverviewContext } from '../../own';
import { DOMAIN_STATUSES, OwnRenderActions } from './actions';
import { OwnRenderRenew } from './renew';
import { OwnRenderStatusTag } from './status';
import { OwnRenderServiceName } from './title';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { useAppViewport } from 'utilities/hooks/useAppViewport/useAppViewport';
import { getIncludedObjBasedOnType } from 'utilities/methods/commonActions';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
/**
 * @typedef {import('../../types').TVPSOverview.TTableBody} TTableBody
 */

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * @type {TTableBody}
 */
export const VPSTableBody = ({ data }) => {
    /***** HOOKS *****/
    const isMobile = useAppViewport(['xs', 'sm']);
    const { isExpressServiceManager, numberOfRows } = useVPSOverviewContext();

    /***** FUNCTIONS *****/
    /**
     * Check if the accordion will render content, logic is based on the OwnRenderActions component logic
     * @param {typeof data[number]} data
     */
    const doesAccordionHaveContent = (data) => {
        const invoice = getIncludedObjBasedOnType(data.included, 'invoice');
        const { domain_status } = data.attributes;

        return /** @type {Array<string>} */ ([DOMAIN_STATUSES.ACTIVE, DOMAIN_STATUSES.PENDING_CANCELLATION]).includes(domain_status) || !!invoice;
    };

    /***** RENDER HELPERS *****/
    const classes = /** @type {const} */ ({
        wrapper: 'VPSTableBody'
    });

    /***** RENDER *****/
    if (data.length === 0) {
        return (
            <Text secondary size--xss align--center className="VPSTableBody__empty">
                No VPS matched your search.
            </Text>
        );
    }

    return (
        <div className={classes.wrapper}>
            <WrapWithComponent component={NXTable.Accordion.ToggleWrapper} wrap={isMobile}>
                <Subset range={isExpressServiceManager ? [0, 5] : [0, numberOfRows]} data={data}>
                    {(data, index) =>
                        isMobile ? (
                            <NXTable.Accordion
                                key={data.id}
                                index={index ?? 0}
                                content={doesAccordionHaveContent(data) && <OwnRenderActions data={data} />}
                            >
                                <OwnRenderServiceName data={data} />
                                <OwnRenderStatusTag data={data} />
                                <OwnRenderRenew data={data} />
                            </NXTable.Accordion>
                        ) : (
                            <NXTable.Row key={data.id}>
                                <OwnRenderServiceName data={data} />
                                <OwnRenderStatusTag data={data} />
                                <OwnRenderRenew data={data} />
                                <OwnRenderActions data={data} />
                            </NXTable.Row>
                        )
                    }
                </Subset>
            </WrapWithComponent>
        </div>
    );
};

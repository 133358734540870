/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React from 'react';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import Text from 'components/Utils/Text';

function TextWrapper({ children }) {
    return children ? <Text bold>{children}</Text> : '';
}

export const genericTerminatedCancelled = { status: 403, message: 'The service you are attempting to access is unavailable or does not exist' };

export const redirectMessages = {
    domain: {
        pending: (domain_name) => (
            <>
                The domain <TextWrapper>{domain_name}</TextWrapper> you are attempting to access is currently in a pending state. Please contact
                support for assistance.
            </>
        ),

        // expired: (domain_name) => (
        //     <>
        //         The domain <TextWrapper>{domain_name}</TextWrapper> you are attempting to access is expired.
        //     </>
        // ),

        pending_transfer: (domain_name) => (
            <>
                The domain <TextWrapper>{domain_name}</TextWrapper> you are attempting to access is currently pending transfer.
            </>
        ),

        redemption: (domain_name) => (
            <>
                The domain <TextWrapper>{domain_name}</TextWrapper> you are attempting to access is currently in the redemption grace period, please
                contact support to restore.
            </>
        ),

        transferred_away: (domain_name) => (
            <>
                The domain <TextWrapper>{domain_name}</TextWrapper> you are attempting to access has been transferred away.
            </>
        ),

        cancelled: (domain_name) => (
            <>
                The domain <TextWrapper>{domain_name}</TextWrapper> you are attempting to access has been cancelled.
            </>
        )
    },

    hosting: {
        pending: (service_primary_domain_name) => (
            <>
                The cPanel hosting service <TextWrapper>{service_primary_domain_name}</TextWrapper> you are attempting to access is currently in a
                pending state. Please contact support for assistance.
            </>
        ),
        pending_cancellation: (service_primary_domain_name) => (
            <>
                The cPanel hosting service <TextWrapper>{service_primary_domain_name}</TextWrapper> you are attempting to access is currently pending
                cancellation.
            </>
        ),
        suspended: (service_primary_domain_name) => (
            <>
                The cPanel hosting service <TextWrapper>{service_primary_domain_name}</TextWrapper> you are attempting to access is currently
                suspended. Please unsuspend or contact support.
            </>
        ),
        cancelled: (service_primary_domain_name) => (
            <>
                The cPanel hosting service <TextWrapper>{service_primary_domain_name}</TextWrapper> you are attempting to access has been cancelled.
            </>
        ),
        terminated: (service_primary_domain_name) => (
            <>
                The cPanel hosting service <TextWrapper>{service_primary_domain_name}</TextWrapper> you are attempting to access has been terminated.
            </>
        )
    },

    vps: {
        pending: (service_primary_domain_name) => (
            <>
                The VPS service <TextWrapper>{service_primary_domain_name}</TextWrapper> you are attempting to access is currently in a pending state.
                Please contact support for assistance.
            </>
        ),
        pending_cancellation: (service_primary_domain_name) => (
            <>
                The VPS service <TextWrapper>{service_primary_domain_name}</TextWrapper> you are attempting to access is currently pending
                cancellation.
            </>
        ),
        suspended: (service_primary_domain_name) => (
            <>
                The VPS service <TextWrapper>{service_primary_domain_name}</TextWrapper> you are attempting to access is currently suspended. Please
                unsuspend or contact support.
            </>
        ),
        cancelled: (service_primary_domain_name) => (
            <>
                The VPS service <TextWrapper>{service_primary_domain_name}</TextWrapper> you are attempting to access has been cancelled.
            </>
        ),
        terminated: (service_primary_domain_name) => (
            <>
                The VPS service <TextWrapper>{service_primary_domain_name}</TextWrapper> you are attempting to access has been terminated.
            </>
        )
    },

    google_workspace: {
        pending_cancellation: (service_primary_domain_name) => (
            <>
                The Google Workspace service <TextWrapper>{service_primary_domain_name}</TextWrapper> you are attempting to access is currently in a
                pending cancellation state. Please contact support for assistance.
            </>
        ),
        suspended: (service_primary_domain_name) => (
            <>
                The Google Workspace service <TextWrapper>{service_primary_domain_name}</TextWrapper> you are attempting to access is currently
                suspended. Please unsuspend or contact support.
            </>
        ),
        cancelled: (service_primary_domain_name) => (
            <>
                The Google Workspace service <TextWrapper>{service_primary_domain_name}</TextWrapper> you are attempting to access has been cancelled.
            </>
        ),
        terminated: (service_primary_domain_name) => (
            <>
                The Google Workspace service <TextWrapper>{service_primary_domain_name}</TextWrapper> you are attempting to access has been
                terminated.
            </>
        )
    },

    email_hosting: {
        pending: (service_primary_domain_name) => (
            <>
                The email hosting service <TextWrapper>{service_primary_domain_name}</TextWrapper> you are attempting to access is currently in a
                pending state. Please contact support for assistance.
            </>
        ),
        suspended: (service_primary_domain_name) => (
            <>
                The email hosting service <TextWrapper>{service_primary_domain_name}</TextWrapper> you are attempting to access is currently
                suspended. Please unsuspend or contact support.
            </>
        ),
        cancelled: (service_primary_domain_name) => (
            <>
                The email hosting service <TextWrapper>{service_primary_domain_name}</TextWrapper> you are attempting to access has been cancelled.
            </>
        ),
        terminated: (service_primary_domain_name) => (
            <>
                The email hosting service <TextWrapper>{service_primary_domain_name}</TextWrapper> you are attempting to access has been terminated.
            </>
        )
    },

    ssl: {
        validation_submitted: (service_primary_domain_name) => (
            <>
                The SSL certificate for <TextWrapper>{service_primary_domain_name}</TextWrapper> you are attempting to access is currently pending
                validation.
            </>
        ),
        expired: (service_primary_domain_name) => (
            <>
                The SSL certificate for <TextWrapper>{service_primary_domain_name}</TextWrapper> you are attempting to access is expired.
            </>
        ),
        cancelled: (service_primary_domain_name) => (
            <>
                The SSL certificate for <TextWrapper>{service_primary_domain_name}</TextWrapper> you are attempting to access has been cancelled.
            </>
        ),
        terminated: (service_primary_domain_name) => (
            <>
                The SSL certificate for <TextWrapper>{service_primary_domain_name}</TextWrapper> you are attempting to access has been terminated.
            </>
        )
    }
};

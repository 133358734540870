import classNames from 'classnames';
import { _Accordion as Accordion } from 'components/Accordion/_Accordion';
import React from 'react';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import './_SlickAccordion.scss';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
import type { NAccordion } from 'components/Accordion/types';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const _SlickAccordion: React.FC<NAccordion.Props> = (props) => (
    <Accordion {...props} className={classNames('SlickAccordion', props.className)} />
);

/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import _ from 'lodash';
import { useMemo } from 'react';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import Text from 'components/Utils/Text';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { useGetDynamicFormFieldValues } from 'containers/katana/components/dynamicFormFieldRenderer/hooks/useGetDynamicFormFieldValue';
import { getComplexValidationMethods } from 'containers/katana/formFields/methods/validations/getComplexValidationMethods';
import { getKatanaFormFieldValidation } from 'containers/katana/formFields/methods/validations/getKatanaFormFieldValidation';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * @param {{
 *      property: import('containers/katana/types').KatanaNamespace.SectionDefinitions.PropertiesModified;
 *      FormComponent: import('containers/katana/formFields/types').ContentDefinitionPropertyFormTypeComponents;
 * }} props
 */
function ContentDefinitionFormItem({ property, FormComponent }) {
    const { type } = property;

    /***** HOOKS *****/
    const formValues = useGetDynamicFormFieldValues();
    const baseValidationFunctions = useMemo(() => getKatanaFormFieldValidation({ formValues: formValues.values, property }), [property, formValues]);
    const complexValidationFunctions = useMemo(
        () => getComplexValidationMethods({ formValues: formValues.values, property }),
        [property, formValues]
    );

    const validationFunctions = useMemo(
        () => _.concat(baseValidationFunctions, complexValidationFunctions),
        [baseValidationFunctions, complexValidationFunctions]
    );

    /***** RENDER *****/
    if (!FormComponent)
        return (
            <Text warn size--m>
                Could not find VIPsites Form Component for property of type: {type}
            </Text>
        );

    return <FormComponent property={{ ...property, validationFunctions }} />;
}
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

export { ContentDefinitionFormItem };

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import { _DevFormTypeChoiceWrapper as DevFormTypeChoiceWrapper } from 'containers/katana/formFields/_DevFormTypeChoiceWrapper/_DevFormTypeChoiceWrapper';
import { KatanaFileFormFieldDisplayOnly } from 'containers/katana/formFields/file/inputTypes/display_only';
import { KatanaFileFormFieldDisplayOnlyMedia } from 'containers/katana/formFields/file/inputTypes/display_only_media';
import { KatanaFileFormFieldNXDropZone } from 'containers/katana/formFields/file/inputTypes/dropzone';
import { KatanaFileFormFieldNXDropZoneOrStockPhoto } from 'containers/katana/formFields/file/inputTypes/dropzone_or_stock_photo';
import { KatanaFileFormFieldMediaReduxForm } from './media/KatanaFileFormFieldMediaReduxForm';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { InvalidInputType } from 'containers/katana/formFields/invalidInputType';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import { SITE_SECTION_FILE_PROPERTY_INPUT_TYPE } from 'containers/katana/formFields/file/consts';

/**********************************************************************************************************
 *   TYPE IMPORTS
 **********************************************************************************************************/
import type { KatanaNamespace } from 'containers/katana/types';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/

export const KatanaFileFormField: KatanaNamespace.PropertyComponent = ({ property }) => {
    const { input_type, name } = property;

    /***** RENDER *****/
    return (
        <DevFormTypeChoiceWrapper value={input_type} options={Object.values(SITE_SECTION_FILE_PROPERTY_INPUT_TYPE)}>
            {({ derivedValue }) => {
                switch (derivedValue) {
                    case SITE_SECTION_FILE_PROPERTY_INPUT_TYPE.DISPLAY_ONLY:
                        return <KatanaFileFormFieldDisplayOnly property={property} />;

                    case SITE_SECTION_FILE_PROPERTY_INPUT_TYPE.DISPLAY_ONLY_MEDIA:
                        return <KatanaFileFormFieldDisplayOnlyMedia property={property} />;

                    case SITE_SECTION_FILE_PROPERTY_INPUT_TYPE.DROPZONE:
                        return <KatanaFileFormFieldNXDropZone property={property} />;

                    case SITE_SECTION_FILE_PROPERTY_INPUT_TYPE.DROPZONE_OR_STOCK_PHOTO:
                        return <KatanaFileFormFieldNXDropZoneOrStockPhoto property={property} />;

                    case SITE_SECTION_FILE_PROPERTY_INPUT_TYPE.MEDIA:
                        return <KatanaFileFormFieldMediaReduxForm property={property} />;

                    default:
                        return <InvalidInputType name={name} inputType={derivedValue} fieldType="File" />;
                }
            }}
        </DevFormTypeChoiceWrapper>
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

import RequestLoader from 'components/Loaders/Request';
import { KatanaHookFormHandler } from 'containers/katana/components/HookFormHandler';
import { socialFormatValidations } from 'containers/katana/containers/ContentEditorLightbox/formHandlers/navigationFormHandler/consts';
import { useHandleNavigationSubmit } from 'containers/katana/containers/ContentEditorLightbox/formHandlers/navigationFormHandler/useHandleNavigationSubmit';
import { useNavigationDefaultFormValues } from 'containers/katana/containers/ContentEditorLightbox/formHandlers/navigationFormHandler/useNavigationDefaultFormValues';
import { CallToActionType } from 'containers/katana/containers/contentEditorModules/callToAction/consts';
import type { KatanaNamespace } from 'containers/katana/types';
import React from 'react';
import { performValidations } from 'utilities/methods/commonActions/performValidation/performValidation';
import { requiredFieldErrorMessage } from 'utilities/methods/form';
import { z } from 'zod';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type Props = {
    subpageRouteData: KatanaNamespace.SubPageRouteData;
};

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
const navigationZodSchema = z
    .object({
        style: z.object({
            color: z.string({ message: requiredFieldErrorMessage }),
            transparent: z.boolean().nullable().optional(),
            visible: z.boolean().nullable().optional()
        }),
        section_ids: z.array(z.number(), { message: requiredFieldErrorMessage }),
        call_to_action: z
            .object({
                enabled: z.boolean(),
                type: z.union([z.literal(CallToActionType.LINK), z.literal(CallToActionType.SECTION), z.literal(CallToActionType.PHONE)]),
                text: z.string().nullable(),
                value: z.string().max(255, { message: 'must contain at most 255 characters' }).nullable(),
                section_id: z.number().nullable()
            })
            .default({ enabled: false, type: CallToActionType.LINK, text: '', value: '', section_id: null })
            .superRefine(({ type, value, text, section_id, enabled }, context) => {
                if (!enabled) return;
                if (type === CallToActionType.PHONE && value !== null && value.length && !value?.match(/\d/)) {
                    context.addIssue({
                        code: 'custom',
                        message: 'Phone number should contain a number',
                        path: ['value']
                    });
                }
                if (type === CallToActionType.LINK && !value) {
                    context.addIssue({
                        code: 'custom',
                        message: requiredFieldErrorMessage,
                        path: ['value']
                    });
                }
                if (type === CallToActionType.SECTION && !section_id) {
                    context.addIssue({
                        code: 'custom',
                        message: requiredFieldErrorMessage,
                        path: ['section_id']
                    });
                }
                if ((type === CallToActionType.LINK || type === CallToActionType.SECTION) && !text) {
                    context.addIssue({
                        code: 'custom',
                        message: requiredFieldErrorMessage,
                        path: ['text']
                    });
                }
            }),
        socials: z
            .array(
                z
                    .object({
                        type: z.string(),
                        url: z.string()
                    })
                    .optional()
                    .superRefine((value, context) => {
                        if (!value) {
                            return;
                        }
                        const { type, url } = value;
                        const validations = socialFormatValidations(type);
                        const res = performValidations(validations, url);

                        if (res) {
                            context.addIssue({
                                code: 'custom',
                                message: res
                            });
                        }
                    })
            )
            .optional(),
        show_socials_in_header: z.boolean().nullable(),
        show_socials_in_footer: z.boolean().nullable()
    })
    .superRefine(({ show_socials_in_footer, show_socials_in_header, socials }, context) => {
        if (show_socials_in_footer || show_socials_in_header) {
            if (!socials) return;
            if (socials?.length < 1) {
                context.addIssue({
                    code: 'custom',
                    message: 'Please add at least one social media link',
                    path: ['socials']
                });
            }
        }
    });

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const NavigationFormHandler: React.FC<Props> = ({ subpageRouteData }) => {
    const defaultValues = useNavigationDefaultFormValues(subpageRouteData);

    const handleNavigationSubmit = useHandleNavigationSubmit(subpageRouteData);

    /***** RENDER *****/
    if (!defaultValues) {
        return <RequestLoader message="Loading Navigation Details..." />;
    }

    return (
        <KatanaHookFormHandler defaultValues={defaultValues} zodSchema={navigationZodSchema} handleOnSubmit={handleNavigationSubmit}>
            {subpageRouteData.modules}
        </KatanaHookFormHandler>
    );
};

/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import classNames from 'classnames';
import htmr from 'htmr';
import React, { useEffect } from 'react';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import Text from 'components/Utils/Text';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import { PhosphorIcons } from 'components/Icons/Phosphor';
import Transition from 'components/Transition';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import './_Toast.scss';
import { toastStates, toastStatuses } from './consts';
import { pullNotification, safelyGetNotification, useNotifications } from './functions';

/** @type {import('./types').TActivateNotification} */
const activateNotification = (identifier) => {
    safelyGetNotification(identifier, (notification) => {
        notification.active = toastStates.ACTIVE;

        // slight reduction in time so that it still shows slider before it completely fades
        // Mostly just resolves issues on extremely short notifications
        const timeout = notification.time + 100;
        setTimeout(() => pullNotification(identifier), timeout);
    });
};

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * New Toast component that uses the new notification system. This component could theoretically allow
 * for multiple notifications to be displayed at once, but the default is to only display one at a time.
 * It is also event based and will automatically display notifications as they are pushed to the state.
 *
 * @type {import('./types').TToastComponent}
 */
const Toast = ({ max = 1 }) => {
    const notifications = useNotifications();

    /***** EFFECTS *****/
    // Update the display notifications whenever the notifications array changes
    useEffect(() => {
        const inactiveNotifications = notifications.filter(({ active }) => active === toastStates.INACTIVE);
        const activeNotifications = notifications.filter(({ active }) => active === toastStates.ACTIVE);

        // if there are no more notifications to push, do nothing
        if (inactiveNotifications.length === 0) return;

        // if the queue is already full, do nothing
        if (activeNotifications.length === max) return;

        //get notifications to push
        const newNotifications = inactiveNotifications.slice(0, max - activeNotifications.length);

        // activate notifications
        newNotifications.forEach(({ identifier }) => activateNotification(identifier));
    }, [notifications]);

    /***** RENDER HELPERS *****/
    const activeNotifications = notifications.filter(({ active }) => {
        switch (active) {
            case toastStates.ACTIVE:
            case toastStates.EXITING:
            case toastStates.ENTERING:
                return true;
            default:
                return false;
        }
    });

    const iconProps = /** @type {const} */ ({
        size: 48,
        white: true
    });

    const icons = {
        [toastStatuses.SUCCESS]: <PhosphorIcons.CheckCircle.Bold {...iconProps} />,
        [toastStatuses.NOTICE]: <PhosphorIcons.Info.Bold {...iconProps} />,
        [toastStatuses.WARN]: <PhosphorIcons.WarningCircle.Bold {...iconProps} />,
        [toastStatuses.INFO]: <PhosphorIcons.Question.Bold {...iconProps} />
    };

    const renderDetails = (/** @type {React.ReactNode} */ details) => {
        if (typeof details === 'string') return htmr(details);

        return details;
    };

    /** @param {import('./types').TAtomicToastState['active']} active */
    const textClassname = (active) => classNames('ToastNotification__item', `ToastNotification__item--${active}`);
    /** @param {import('./types').TAtomicToastState['status']} status */
    const contentClassname = (status) => classNames('ToastNotification__contentWrapper', `ToastNotification__contentWrapper--${status}`);

    /***** RENDER *****/
    return (
        <div className="ToastNotification">
            {/* Active Notification */}
            {activeNotifications.map(({ identifier, details, message, status, active, time }) => (
                <Transition when={active === toastStates.ACTIVE} key={identifier}>
                    <div className={contentClassname(status)}>
                        {/* Icon */}
                        {icons[status]}

                        {/* Message */}
                        <Text size--s white>
                            <div className={textClassname(active)}>{renderDetails(details ?? message)}</div>
                        </Text>
                    </div>

                    {/* Incoming Notice */}
                    {notifications.length > max && (
                        <div className="ToastNotification__remaining">
                            <Text bold white>
                                {notifications.length - max}
                            </Text>
                        </div>
                    )}

                    {/* Close */}
                    <div className="ToastNotification__close">
                        <button onClick={() => pullNotification(identifier)}>
                            <Text size--s white bold>
                                <i className="icon icon-blog-close" />
                            </Text>
                        </button>
                    </div>

                    {/* Progress Bar */}
                    <div className="ToastNotification__progressFrame">
                        <div className="ToastNotification__progressBar" style={{ animationDuration: `${time}ms` }} />
                    </div>
                </Transition>
            ))}
        </div>
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

export default Toast;

export const contactTypesAll = ['registrant', 'tech', 'billing', 'admin'];
export const contactFormDetailsOptions = [
    {
        label: 'My Profile',
        value: 'profile'
    },
    {
        label: 'Registrant Contact',
        value: 'registrant'
    },
    {
        label: 'Technical Contact',
        value: 'tech'
    },
    {
        label: 'Billing Contact',
        value: 'billing'
    },
    {
        label: 'Admin Contact',
        value: 'admin'
    }
];

export const dnsViews = /** @type {const} */ ({
    FREE_DNS: 'free_dns',
    CUSTOM_HOSTING: 'custom_hosting',
    FORWARDING: 'forwarding',
    PARK_DOMAIN: 'park_domain',
    VENTRAIP_HOSTING: 'ventraip_hosting'
});

/**
 * @typedef {typeof dnsViews[keyof typeof dnsViews]} TDnsViews
 */

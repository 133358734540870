import axios from 'axios';
const CancelToken = axios.CancelToken;

export let CancelPreview;

export const API = {
    /**********************************************************************************************************
     *   VENTRA SYNERGY MIGRATION API
     **********************************************************************************************************/
    vsMigration: {
        GET: {
            requires: () => {
                return axios.get(`/api/promotion/synergy-reseller-migration/requires-migration`);
            },

            resellers: () => {
                return axios.get(`/api/promotion/synergy-reseller-migration/services`);
            },

            subaccounts: (id, force) => {
                return axios.get(`/api/promotion/synergy-reseller-migration/services/${id}/accounts${force ? '?force=true' : ''}`);
            },

            migrationDates: () => {
                return axios.get(`/api/promotion/synergy-reseller-migration/dates`);
            }
        },

        POST: {
            preview: (services) => {
                return axios.post(
                    `/api/promotion/synergy-reseller-migration/migrate/preview`,
                    {
                        services
                    },
                    {
                        cancelToken: new CancelToken(function executor(c) {
                            CancelPreview = c;
                        })
                    }
                );
            },

            migrate: (attributes) => {
                return axios.post(`/api/promotion/synergy-reseller-migration/migrate`, attributes);
            },

            login: (credentials) => {
                return axios.post(`/api/promotion/synergy-reseller-migration/login`, credentials);
            },

            register: (credentials) => {
                return axios.post(`/api/promotion/synergy-reseller-migration/register`, credentials);
            },

            pciCsv: (services) => {
                return axios.post(`/api/promotion/synergy-reseller-migration/pci-compliance-csv`, {
                    services
                });
            }
        }
    }
};

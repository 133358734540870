import HoverTooltip from 'components/Tooltip/HoverTooltip';
import Border from 'components/Utils/Border';
import Text from 'components/Utils/Text';
import { getInformationFromData } from 'containers/services/modules/ssl/components/body/helpers';
import type { NSSLTable } from 'containers/services/modules/ssl/types';
import { truncateText } from 'utilities/methods/commonActions';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const SSLPendingName: NSSLTable.TSSLTablePendingName = ({ data }) => {
    /***** RENDER HELPERS *****/
    const { domain } = getInformationFromData(data);

    /***** RENDER *****/
    return (
        <>
            Unconfigured{' '}
            {!!domain && (
                <Text span secondary semiBold size--s className="SSLOverviewBody__pendingTitle--domain">
                    (
                    <HoverTooltip content={`This SSL Certificate is for the domain ${domain}. This can be changed during the configuration process`}>
                        <Border as="span" bottom dotted>
                            {truncateText(domain, 25, '...')}
                        </Border>
                    </HoverTooltip>
                    )
                </Text>
            )}
        </>
    );
};

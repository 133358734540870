/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import classNames from 'classnames';
import React from 'react';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import './_SortableItem.scss';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * @type  {React.FC<{
 *      id: string | number;
 *      children: React.ReactNode | ((props: { isDragging: boolean }) => React.ReactNode);
 * }>}
 *
 * `children` can be a function that receives an object with values from `useSortable` because `useSortable` has a bug where you can't seem to nest it
 */
const SortableItem = ({ id, children }) => {
    /***** HOOKS *****/
    const { setNodeRef, transform, transition, isDragging } = useSortable({ id });

    /***** RENDER HELPERS *****/
    const style = {
        transform: CSS.Translate.toString(transform),
        transition
    };

    /***** RENDER *****/
    return (
        <div
            ref={setNodeRef}
            style={style}
            className={classNames('SortableItem', {
                'SortableItem--isDragging': isDragging
            })}
        >
            {typeof children === 'function' ? children({ isDragging }) : children}
        </div>
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
export { SortableItem };

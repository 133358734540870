/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import axios from 'axios';
import { pushNotification } from 'components/Toast/functions';
import { API, cancelUpdateConfigCalculate } from 'utilities/api/ms365';
import { API as SERVICES } from 'utilities/api/services';
import { getDataFromSuccessResponse, getErrorFromFailResponse } from 'utilities/methods/commonActions';

/**********************************************************************************************************
 *   DECLARATIONS
 **********************************************************************************************************/
export const MS365_INFORMATION_REQUEST = 'ms365/MS365_INFORMATION_REQUEST';
export const MS365_INFORMATION_SUCCESS = 'ms365/MS365_INFORMATION_SUCCESS';
export const MS365_INFORMATION_ERROR = 'ms365/MS365_INFORMATION_ERROR';

export const MS365_PRODUCT_CONFIG_REQUEST = 'ms365/MS365_PRODUCT_CONFIG_REQUEST';
export const MS365_PRODUCT_CONFIG_SUCCESS = 'ms365/MS365_PRODUCT_CONFIG_SUCCESS';
export const MS365_PRODUCT_CONFIG_ERROR = 'ms365/MS365_PRODUCT_CONFIG_ERROR';

export const MS365_UPGRADE_CALCULATE_REQUEST = 'ms365/MS365_UPGRADE_CALCULATE_REQUEST';
export const MS365_UPGRADE_CALCULATE_SUCCESS = 'ms365/MS365_UPGRADE_CALCULATE_SUCCESS';
export const MS365_UPGRADE_CALCULATE_ERROR = 'ms365/MS365_UPGRADE_CALCULATE_ERROR';

export const MS365_UPGRADE_REQUEST = 'ms365/MS365_UPGRADE_REQUEST';
export const MS365_UPGRADE_SUCCESS = 'ms365/MS365_UPGRADE_SUCCESS';
export const MS365_UPGRADE_ERROR = 'ms365/MS365_UPGRADE_ERROR';

export const MS365_UPDATE_CONFIG_CALCULATE_REQUEST = 'ms365/MS365_UPDATE_CONFIG_CALCULATE_REQUEST';
export const MS365_UPDATE_CONFIG_CALCULATE_SUCCESS = 'ms365/MS365_UPDATE_CONFIG_CALCULATE_SUCCESS';
export const MS365_UPDATE_CONFIG_CALCULATE_ERROR = 'ms365/MS365_UPDATE_CONFIG_CALCULATE_ERROR';
export const RESET_MS365_UPDATE_CONFIG_CALCULATE = 'ms365/RESET_MS365_UPDATE_CONFIG_CALCULATE';

export const MS365_UPDATE_CONFIG_REQUEST = 'ms365/MS365_UPDATE_CONFIG_REQUEST';
export const MS365_UPDATE_CONFIG_SUCCESS = 'ms365/MS365_UPDATE_CONFIG_SUCCESS';
export const MS365_UPDATE_CONFIG_ERROR = 'ms365/MS365_UPDATE_CONFIG_ERROR';

export const MS365_UPGRADE_PATHS_REQUEST = 'ms365/MS365_UPGRADE_PATHS_REQUEST';
export const MS365_UPGRADE_PATHS_SUCCESS = 'ms365/MS365_UPGRADE_PATHS_SUCCESS';
export const MS365_UPGRADE_PATHS_ERROR = 'ms365/MS365_UPGRADE_PATHS_ERROR';

/**********************************************************************************************************
 *   MS365 SERVICE
 **********************************************************************************************************/
export const getMs365Service = (id) => (dispatch) => {
    dispatch({ type: MS365_INFORMATION_REQUEST });

    return API.ms365.GET.service(id)
        .then((response) => {
            const ms365_information_data = getDataFromSuccessResponse(response);
            dispatch({
                type: MS365_INFORMATION_SUCCESS,
                ms365_information_data
            });
        })
        .catch(() => {
            dispatch({ type: MS365_INFORMATION_ERROR });
        });
};

/**********************************************************************************************************
 *   MS365 PRODUCT CONFIGS
 **********************************************************************************************************/
export const getMs365ProductConfigs = () => (dispatch) => {
    dispatch({ type: MS365_PRODUCT_CONFIG_REQUEST });
    API.ms365.GET.productConfigs()
        .then((response) => {
            const ms365_product_config_data = getDataFromSuccessResponse(response);
            dispatch({
                type: MS365_PRODUCT_CONFIG_SUCCESS,
                ms365_product_config_data
            });
        })
        .catch(() => {
            dispatch({ type: MS365_PRODUCT_CONFIG_ERROR });
        });
};

/**********************************************************************************************************
 *   MS365 UPGRADE CALCULATE
 **********************************************************************************************************/
export const calculateUpgradeMs365Plan = (id, attributes) => (dispatch) => {
    dispatch({ type: MS365_UPGRADE_CALCULATE_REQUEST });
    API.ms365.POST.upgradeCalculate(id, attributes)
        .then((response) => {
            const ms365_upgrade_calculate_data = getDataFromSuccessResponse(response);
            dispatch({
                type: MS365_UPGRADE_CALCULATE_SUCCESS,
                ms365_upgrade_calculate_data
            });
        })
        .catch(() => {
            dispatch({ type: MS365_UPGRADE_CALCULATE_ERROR });
        });
};

/**********************************************************************************************************
 *   MS365 UPGRADE
 **********************************************************************************************************/
export const upgradeMs365Plan = (id, attributes) => (dispatch) => {
    dispatch({ type: MS365_UPGRADE_REQUEST });
    API.ms365.POST.upgrade(id, attributes)
        .then((response) => {
            const ms365_upgrade_data = getDataFromSuccessResponse(response);
            pushNotification(getDataFromSuccessResponse(response));
            dispatch({
                type: MS365_UPGRADE_SUCCESS,
                ms365_upgrade_data
            });
        })
        .catch((error) => {
            pushNotification(getErrorFromFailResponse(error));
            dispatch({ type: MS365_UPGRADE_ERROR });
        });
};

/**********************************************************************************************************
 *   MS365 UPDATE CONFIG CALCULATE
 **********************************************************************************************************/
export const calculateMs365UpdateConfig = (id, attributes) => (dispatch) => {
    dispatch({ type: MS365_UPDATE_CONFIG_CALCULATE_REQUEST });
    if (cancelUpdateConfigCalculate) cancelUpdateConfigCalculate();
    API.ms365.POST.updateConfigCalculate(id, attributes)
        .then((response) => {
            const ms365_update_config_calculate_data = getDataFromSuccessResponse(response);
            dispatch({
                type: MS365_UPDATE_CONFIG_CALCULATE_SUCCESS,
                ms365_update_config_calculate_data
            });
        })
        .catch((e) => {
            if (!axios.isCancel(e)) {
                dispatch({ type: MS365_UPDATE_CONFIG_CALCULATE_ERROR });
            }
        });
};

/**********************************************************************************************************
 *   MS365 UPDATE CONFIG
 **********************************************************************************************************/
export const updateMs365Config = (id, attributes) => (dispatch) => {
    dispatch({ type: MS365_UPDATE_CONFIG_REQUEST });
    API.ms365.POST.updateConfig(id, attributes)
        .then((response) => {
            const ms365_update_config_data = getDataFromSuccessResponse(response);
            pushNotification(ms365_update_config_data);
            dispatch({
                type: MS365_UPDATE_CONFIG_SUCCESS,
                ms365_update_config_data
            });
        })
        .catch((error) => {
            pushNotification(getErrorFromFailResponse(error));
            dispatch({ type: RESET_MS365_UPDATE_CONFIG_CALCULATE });
            dispatch({ type: MS365_UPDATE_CONFIG_ERROR });
        });
};

/**********************************************************************************************************
 *   MS365 UPGRADE PATHS
 **********************************************************************************************************/
export const getMs365UpgradePaths = (serviceId) => (dispatch) => {
    dispatch({ type: MS365_UPGRADE_PATHS_REQUEST });
    return SERVICES.service.get
        .upgradePaths(serviceId)
        .then((response) => {
            const data = getDataFromSuccessResponse(response);

            if (data.length === 0) return data;

            const mapConfigOptions = (list) =>
                list.map(({ options }) => ({
                    name: options[0].name,
                    monthlyCost: options[0].pricing_list[0].price
                }));

            const mapped = data.map(
                ({
                    attributes: { path_type },
                    target_product: {
                        id,
                        attributes: { name, description, config_list }
                    }
                }) => {
                    return {
                        planName: name,
                        desc: description,
                        planId: id,
                        pathType: path_type,
                        configList: mapConfigOptions(config_list),
                        isCurrentPlan: false
                    };
                }
            );

            const { base_product } = data[0];

            const downgrades = mapped.filter(({ pathType }) => pathType === 'downgrade');
            const upgrades = mapped.filter(({ pathType }) => pathType === 'upgrade');

            const finalList = [
                ...downgrades,
                {
                    planName: base_product.attributes.name,
                    desc: base_product.attributes.description,
                    planId: base_product.id,
                    pathType: null,
                    configList: mapConfigOptions(base_product.attributes.config_list),
                    isCurrentPlan: true
                },
                ...upgrades
            ];

            dispatch({
                type: MS365_UPGRADE_PATHS_SUCCESS,
                ms365_upgrade_paths_data: finalList
            });
        })
        .catch(() => {
            dispatch({ type: MS365_UPGRADE_PATHS_ERROR });
        });
};

/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { Navigate, useLocation, useParams, useRouter } from '@tanstack/react-router';

/**********************************************************************************************************
 *   TYPE IMPORTS
 **********************************************************************************************************/
import type { NavigateOptions } from '@tanstack/react-router';
import type { RoutePaths } from 'router/types';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type RedirectWhenComponent = React.FC<
    NavigateOptions & {
        /**
         * Redirect when the route is matched. If the route has params, they will automatically be filled in.
         */
        when: RoutePaths;

        /**
         * Whether or not to exactly match the route or only partially. This defaults to true.
         */
        exact?: boolean;

        /**
         * Optional children. Sometimes this may feel more intuitive to wrap your components in this component
         * than it would be simply place this alongside your components.
         */
        children?: React.ReactNode;

        /**
         * Optional extra condition that must be met for the redirect to occur.
         */
        condition?: boolean;
    }
>;

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * Useful for providing redirects from specific routes. For example, the route /billing should never be visited
 * directly, but throwing a redirect in the beforeLoad is not always reliable. As such, we can use this component to redirect
 * to the /billing/payment-methods route if the /billing route is visited directly.
 *
 * **Note:** Currently redirecting from beforeLoad seems to cause noticeable lag in the UI. I am not sure why this is
 * so I am using this component as a workaround. In general, once this is not the case, we can move any non-dynamic
 * redirects to the beforeLoad function.
 *
 * **Note:** To simplify obvious redirects within the same section, params can be automatically injected without needing
 * to be passed as a prop where the params should remain the same between both routes.
 */
const RedirectWhen: RedirectWhenComponent = ({ when, exact = true, condition = true, children, ...props }) => {
    /***** HOOKS *****/
    const currentPathname = useLocation({ select: (location) => location.pathname });
    const params = useParams({ strict: false });
    const { buildLocation } = useRouter();

    /***** RENDER HELPERS *****/
    const { pathname } = buildLocation({ to: when, params });
    const shouldRedirect = exact
        ? currentPathname === pathname //
        : currentPathname.includes(pathname);

    /***** RENDER *****/
    if (shouldRedirect && condition) {
        return <Navigate params={params} {...props} />;
    }

    return <>{children}</>;
};

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * The Redirect object contains helper components regarding redirects. The Redirect component itself
 * is simply the Navigate component from @tanstack-router. It is called Redirect to differentiate
 * from the Navigate component from @tanstack.
 */
export const Redirect = Object.assign(Navigate, {
    When: RedirectWhen
});
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { Component } from 'react';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import './_RadioButtonSet.scss';

/**********************************************************************************************************
 *   TYPE IMPORTS
 **********************************************************************************************************/
import type { RadioButtonSetProps } from './types';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * RadioButtonSet shows a set of buttons that can be selected. Only one button can be selected at a time.
 */
class RadioButtonSet extends Component<RadioButtonSetProps> {
    render() {
        const { label, options, activeOption, setActiveOption, color = 'secondary', size = 'large' } = this.props;

        const getColor = () => {
            switch (color) {
                case 'primary':
                case 'secondary':
                    return color;
                default:
                    return 'secondary';
            }
        };

        const getSize = () => {
            switch (size) {
                case 'small':
                case 'large':
                    return size;
                default:
                    return 'large';
            }
        };

        /*  RENDER COMPONENT
         **********************************************************************************************************/
        return (
            <div className={`RadioButtonSet ${getColor()} ${getSize()}`}>
                {label ? <label>{label}</label> : ''}
                <div className="RadioButtonSet__container">
                    {options.map(({ value, label }, index) => (
                        <button
                            key={index}
                            type="button"
                            onClick={() => {
                                if (activeOption !== value) setActiveOption(value);
                            }}
                            className={`RadioButtonSet__button ${activeOption === value ? 'active' : ''}`}
                        >
                            {label}
                        </button>
                    ))}
                </div>
            </div>
        );
    }
}

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
export default RadioButtonSet;

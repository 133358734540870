/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { getIncludedObjBasedOnType } from 'utilities/methods/commonActions';

/**
 * helper function for getting relevant information from the data object
 *
 * @private - For use in the _TableBody component.
 * @param {NonNullable<import("utilities/api/_services").Services.SSL.TListData>['data'][number]} data
 */
export const getInformationFromData = (data) => {
    const { product, invoice } = {
        product: getIncludedObjBasedOnType(data.included, 'product'),
        invoice: getIncludedObjBasedOnType(data.included, 'invoice')
    };

    return {
        domain: data.attributes.domain,
        status: data.attributes.status,
        valid_until: data.attributes.valid_until,
        name: product?.attributes.name,
        id: data.id,
        invoice,
        product
    };
};

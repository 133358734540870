/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { useCheckTokenData } from 'utilities/hooks/redux/useCheckTokenData';

/**********************************************************************************************************
 *   HOOK START
 **********************************************************************************************************/
export function useIsVPN() {
    /***** HOOKS *****/
    const { is_vpn } = useCheckTokenData();

    /***** HOOK RESULTS *****/
    return Boolean(is_vpn);
}
/**********************************************************************************************************
 *   HOOK END
 **********************************************************************************************************/

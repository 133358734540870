export const validate = ({ start_date, end_date }) => {
    if (!start_date && !end_date) {
        return {
            start_date: 'Required',
            end_date: 'Required'
        };
    }

    return {};
};

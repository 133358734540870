import PageNotFound from 'components/Errors/PageNotFound';
import RequestLoader from 'components/Loaders/Request';
import ContentDefinitionForm from 'containers/katana/components/contentDefinitionForm';
import { sectionDefinitionUnsplashDownloadLinks } from 'containers/katana/containers/ContentEditorLightbox/formHandlers/consts';
import { useHandleSectionAddOrUpdate } from 'containers/katana/containers/ContentEditorLightbox/formHandlers/hooks';
import { getDefaultValuesFromProperties } from 'containers/katana/containers/ContentEditorLightbox/formHandlers/methods';
import { useSiteDataPreviewValues } from 'containers/katana/containers/ContentEditorLightbox/hooks/useSiteDataPreviewValues';
import { processSectionProperties } from 'containers/katana/containers/ContentEditorLightbox/methods/processSectionProperties/processSectionProperties';
import { modifyPropertyKeys } from 'containers/katana/formFields/methods/modifyPropertyKeys';
import { SORTABLE_ID_KEY } from 'containers/katana/formFields/repeated/SortableRepeatedWrapper/consts';
import { useKatanaNextPage } from 'containers/katana/hooks/useKatanaNextPage';
import { useKatanaParams } from 'containers/katana/hooks/useSetupEditorRouteParams';
import { newSectionOrganiserData } from 'containers/katana/modules/presetContent/components/sectionOrganiser/newSectionOrganiserData';
import { isAttachmentDataAttachment } from 'containers/katana/queries/methods/attachmentData';
import { katanaQuery } from 'containers/katana/queries/tanstackTree';
import type { KatanaNamespace } from 'containers/katana/types';
import _ from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { deepOmit } from 'utilities/methods/recursion/deepOmit/deepOmit';

const triggerUnsplashDownloadOptions = {
    onSuccess: () => sectionDefinitionUnsplashDownloadLinks.setState(() => {})
};

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
const SectionDefinitionFormHandler: FormHandlerComponent = ({ subpageRouteData }) => {
    /***** STATE *****/
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [initialValues, setInitialValues] = useState({});
    const { submitSiteData } = useSiteDataPreviewValues();

    /***** HOOKS *****/
    const { performRouting } = useKatanaNextPage(subpageRouteData);
    const { katanaServiceId, subpage, childPage } = useKatanaParams();

    const isChildPageNumber = _.isNumber(childPage);
    const isAddSectionSubpage = subpage === 'add-section';

    newSectionOrganiserData.useVisited(isChildPageNumber ? childPage : null);

    /***** QUERIES *****/
    const {
        data: get_katana_section_definitions_by_route_key_data,
        isLoading: isGetKatanaSectionDefinitionLoading,
        isError: isGetKatanaSectionDefinitionError
    } = katanaQuery.serviceID.meta.getSectionDefinitions.useByRouteKeyQuery(katanaServiceId);
    const { data: get_katana_section_definitions_data } = katanaQuery.serviceID.meta.getSectionDefinitions.useQuery(katanaServiceId);

    const {
        data: get_katana_site_sections_data,
        isLoading: isGetKatanaSiteSectionsLoading,
        isError: isGetKatanaSiteSectionsError
    } = katanaQuery.serviceID.sections.useQuery(katanaServiceId);

    const { mutate: triggerUnsplashDownload } = katanaQuery.serviceID.triggerUnsplashDownload.useMutation(
        katanaServiceId,
        triggerUnsplashDownloadOptions
    );
    // If it's a number we need to get the actual section data from the server
    const {
        data: get_katana_single_section_data,
        isLoading: isGetKatanaSingleSectionLoading,
        isError: isGetKatanaSingleSectionError
    } = katanaQuery.serviceID.section.useQuery({ serviceID: katanaServiceId, sectionID: isChildPageNumber ? childPage : null });

    const currentSectionKatanaComponentDefinitionID = (
        isChildPageNumber ? get_katana_single_section_data?.section_id : get_katana_section_definitions_by_route_key_data?.[childPage]?.id
    ) as KatanaNamespace.SectionDefinitions.IDs;

    const isLoading = isGetKatanaSectionDefinitionLoading || isGetKatanaSiteSectionsLoading || isGetKatanaSingleSectionLoading;
    const isError = isGetKatanaSectionDefinitionError || isGetKatanaSiteSectionsError || isGetKatanaSingleSectionError;

    /**
     * Get the current section data from the sites' current sections data.
     */
    const siteSection = useMemo(() => {
        if (isAddSectionSubpage) {
            return {};
        }
        if (isChildPageNumber) {
            return get_katana_single_section_data ?? {};
        }
        return get_katana_site_sections_data?.find?.(({ section_id }) => section_id === currentSectionKatanaComponentDefinitionID) ?? {};
    }, [get_katana_site_sections_data, get_katana_single_section_data]);
    const currentSectionID = siteSection?.id;

    /**
     * Get the initial values for the form.
     * Styles are only shown if there are more than 1 styles for the section definition.
     */
    useEffect(() => {
        if (isSubmitting) {
            return;
        }

        if (isLoading) {
            setInitialValues({});
            return;
        }

        const sectionDefinitionStyleData = get_katana_section_definitions_data?.[currentSectionKatanaComponentDefinitionID]?.styles;
        const sectionDefinitionTotalStyles = _.values(sectionDefinitionStyleData);

        const sectionDefinitionPropertiesData = get_katana_section_definitions_data?.[currentSectionKatanaComponentDefinitionID]?.properties ?? [];
        const sectionDefinitionPropertyDefaultValues = getDefaultValuesFromProperties(sectionDefinitionPropertiesData);
        const processedSiteSectionProperties = processSectionProperties(siteSection.properties, sectionDefinitionPropertiesData);
        const initialValueObject = {
            properties: _.mergeWith(sectionDefinitionPropertyDefaultValues, processedSiteSectionProperties, (targetValue, mergingValue) => {
                // If the value is an attachment data attachment, we don't want to merge it. it needs to replace the current value.
                if (isAttachmentDataAttachment(mergingValue)) {
                    return mergingValue;
                }
            })
        };

        if (sectionDefinitionTotalStyles?.length > 1) {
            initialValueObject.style = sectionDefinitionTotalStyles.find((style) => style?.key === siteSection.style)
                ? siteSection.style
                : sectionDefinitionTotalStyles?.[0]?.key ?? 'standard';
        }

        setInitialValues(initialValueObject);
    }, [get_katana_site_sections_data, siteSection, get_katana_section_definitions_data]);

    useEffect(() => {
        sectionDefinitionUnsplashDownloadLinks.setState(() => ({}));

        return () => {
            sectionDefinitionUnsplashDownloadLinks.setState(() => ({}));
        };
    }, [siteSection?.id]);

    /**
     * Handle the form submission add or update.
     */
    const { submitSectionData } = useHandleSectionAddOrUpdate({
        sectionID: !isAddSectionSubpage && currentSectionID,
        katanaSectionID: currentSectionKatanaComponentDefinitionID
    });

    function handleSubmitSectionData(attributes) {
        const sanitisedAttributes = deepOmit(attributes, [SORTABLE_ID_KEY]);
        const downloadLinkValues = _.values(sectionDefinitionUnsplashDownloadLinks.state);
        if (downloadLinkValues.length) {
            triggerUnsplashDownload(downloadLinkValues);
        }
        const promises = [submitSectionData(sanitisedAttributes)];

        submitSiteData?.(promises);
        setIsSubmitting(true);

        return Promise.all(promises)
            .then(() => {
                performRouting();
            })
            .finally(() => {
                setIsSubmitting(false);
            });
    }

    /***** RENDER *****/
    if (isLoading) {
        return <RequestLoader message="Loading Section Data" />;
    }

    /**
     * Check if the katana section definition has the child page by route key
     */
    if (isError || (!isChildPageNumber && !_.has(get_katana_section_definitions_by_route_key_data, childPage))) {
        return <PageNotFound />;
    }

    const currentSectionDefinition = get_katana_section_definitions_data?.[
        currentSectionKatanaComponentDefinitionID
    ] as KatanaNamespace.SectionDefinitions.Values;
    const modifiedPropertyKeys = modifyPropertyKeys('properties', currentSectionDefinition?.properties);
    const clonedCurrentSectionDefinition = _.merge(_.cloneDeep(currentSectionDefinition), { properties: modifiedPropertyKeys });

    return (
        <ContentDefinitionForm
            key={childPage}
            sectionDefinition={clonedCurrentSectionDefinition}
            initialValues={initialValues}
            onSubmit={handleSubmitSectionData}
        />
    );
};

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
export default SectionDefinitionFormHandler;

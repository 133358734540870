/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { get } from 'lodash';
import React, { useContext } from 'react';
import { Field } from 'redux-form';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import { Input } from 'components/Form/Input';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { getServiceInfoDataFromPath } from 'containers/katana/components/dynamicFormFieldRenderer/methods';
import { getValidationValue } from 'containers/katana/formFields/methods/getValidation';
import { useGetCaveat } from 'containers/katana/formFields/methods/useGetCaveat';
import { useKatanaParams } from 'containers/katana/hooks/useSetupEditorRouteParams';
import { useReduxForm } from 'utilities/hooks/useReduxForm';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import { ContentEditorRenderModulesContext } from 'containers/katana/containers/ContentEditorLightbox/contentEditorRenderModules/consts';
import { CaveatTypes } from 'containers/katana/formFields/caveatTypes';

/**********************************************************************************************************
 *   TYPE IMPORTS
 **********************************************************************************************************/
import type { KatanaNamespace } from 'containers/katana/types';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/

const KatanaTextFormFieldText: React.FC<{
    property: KatanaNamespace.SectionDefinitions.PropertiesModified;
}> = ({ property }) => {
    const { defaultValue, key, originalKey, name, type, caveats, validationFunctions, validation } = property;
    /***** HOOKS *****/
    const { katanaServiceId } = useKatanaParams();
    const { form } = useContext(ContentEditorRenderModulesContext);
    const values = useReduxForm(form);
    const initialValue = get(values?.initial, key);

    /***** RENDER HELPERS *****/
    const disabled = useGetCaveat(CaveatTypes.DISABLED, caveats);
    const valueFromServiceDataPath = useGetCaveat(CaveatTypes.VALUE_FROM_SERVICE_DATA, caveats);
    const valueFromServiceData = getServiceInfoDataFromPath(katanaServiceId, valueFromServiceDataPath);
    const placeholderCaveat = useGetCaveat(CaveatTypes.PLACEHOLDER, caveats);
    const placeholder = originalKey ? placeholderCaveat ?? defaultValue : valueFromServiceData;

    const maxLength = getValidationValue(validation, 'max');
    const minLength = getValidationValue(validation, 'min');

    /***** RENDER *****/
    return (
        <Field
            name={key}
            label={name}
            placeholder={placeholder}
            component={Input.ReduxForm}
            disabled={disabled}
            initialValue={initialValue}
            type={type}
            validate={validationFunctions}
            intrinsicProps={{
                maxLength,
                minLength
            }}
        />
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

export { KatanaTextFormFieldText };


/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React, { useEffect } from 'react';
import { isFragment } from 'react-is';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import { useNXTableContext } from '../NXTableContext';
import Base from './base';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import useRegistryData from 'utilities/hooks/useRegistryData';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * Table Actions wrapper to handle display of buttons
 * @param {Object} props
 * @param {number} [props.force] - Force the number of actions to be registered
 * @param {React.ReactNode} props.children - The child components
 * @param {string} [props.className] - Optional className to add to the component
 */
function Actions({ children, force, className }) {
    /***** HOOKS *****/
    const context = useNXTableContext();

    /**
     * Check if the child is not a fragment, otherwise throw an error
     */
    if (isFragment(children)) {
        throw new Error('The NXTable.Actions component does not accept a Fragment as a child, please use an array or non fragment children');
    }

    if (Object.keys(context).length === 0)
        throw new Error(
            "No NXTableContext props, if you're using this inside the Accordion, use the Base Actions component instead: NXTable.Actions.Base"
        );

    const { columnsDefinition, setAllActions } = context;
    const { updateRegistry } = useRegistryData({ setRegistry: setAllActions });

    /***** EFFECTS *****/
    useEffect(() => {
        if (columnsDefinition?.includes('NXActions')) {
            switch (true) {
                case !!force:
                    return updateRegistry(force);
                case !children:
                    return updateRegistry(0);
                case Array.isArray(children):
                    return updateRegistry(children.length);
                default:
                    return updateRegistry(1);
            }
        }
    }, [columnsDefinition, children]);

    /***** RENDER *****/
    return <Base className={className}>{children}</Base>;
}
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

/***** Compound Components START *****/
Actions.Base = Base;
/***** Compound Components END *****/

export default Actions;

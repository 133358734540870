import _ from 'lodash';
// Fix this to import of the KatanaAPI Type once fully swapped over to typescript
// eslint-disable-next-line no-undef
export function isKatanaFileAttachment(value: any): value is KatanaAPI.Katana.ServiceID.Uploads.POST.AttachmentData {
    return (
        value &&
        _.keys(value).length === 6 &&
        _.has(value, 'file') &&
        _.has(value, 'id') &&
        _.has(value, 'mime_type') &&
        _.has(value, 'name') &&
        _.has(value, 'url') &&
        _.has(value, 'hash')
    );
}

/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { get } from 'lodash';
import { useContext } from 'react';
import { useSelector } from 'react-redux';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import { selectorEqualityCheckValues, selectorValues } from 'components/Form/Button/reduxForm';
import { ContentEditorRenderModulesContext } from 'containers/katana/containers/ContentEditorLightbox/contentEditorRenderModules/consts';

/**********************************************************************************************************
 *   HOOK START
 **********************************************************************************************************/
export function useGetDynamicFormFieldValues() {
    /***** HOOKS *****/
    const { form } = useContext(ContentEditorRenderModulesContext);

    return useSelector(selectorValues(form), { equalityFn: selectorEqualityCheckValues });
}
/**********************************************************************************************************
 *   HOOK END
 **********************************************************************************************************/

/**********************************************************************************************************
 *   HOOK START
 **********************************************************************************************************/
export function useGetDynamicFormFieldValue(path) {
    /***** HOOKS *****/
    const formValues = useGetDynamicFormFieldValues();
    return get(formValues.values, path);
}
/**********************************************************************************************************
 *   HOOK END
 **********************************************************************************************************/

/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React from 'react';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import BackgroundImage from 'components/BackgroundImage';
import SolidButton from 'components/Buttons/SolidButton';
import CheckListItemCircle from 'components/CheckListItemCircle';
import { PhosphorIcons } from 'components/Icons/Phosphor';
import NXCard from 'components/NXCard';
import { NXSquare } from 'components/Utils/NXSquare';
import Text from 'components/Utils/Text';

/**********************************************************************************************************
 *   QUERIES
 **********************************************************************************************************/
import { katanaQuery } from 'containers/katana/queries/tanstackTree';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { useKatanaParams } from 'containers/katana/hooks/useSetupEditorRouteParams';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import './_chooseLayoutCard.scss';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type ChooseLayoutCardProps = React.FC<{
    layoutName: string;
    layoutID: string;
    imageURL?: string;
    selected: boolean;
    onClick: () => void;
    onPreviewClick: (e: React.SyntheticEvent<Element, Event>) => void;
}>;

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
const ChooseLayoutCard: ChooseLayoutCardProps = ({ layoutName, layoutID, imageURL, selected, onClick, onPreviewClick }) => {
    /***** HOOKS *****/
    const { katanaServiceId } = useKatanaParams();

    /***** QUERIES *****/
    const { data: unsplash_search_data } = katanaQuery.searchUnsplash.useQuery(katanaServiceId, { search: layoutName, page: 1, per_page: 8 });

    /***** RENDER *****/
    return (
        <NXCard className="ChooseLayoutCard" border onClick={onClick} elevated={selected} highlighted={selected}>
            <NXCard.Content>
                <NXSquare ratio={0.75}>
                    <BackgroundImage imageURL={imageURL ?? unsplash_search_data.result?.[0]?.urls?.regular} cover fill />
                </NXSquare>
                {layoutID !== 'katana.v1.custom' && (
                    <SolidButton
                        color="black"
                        type="onClick"
                        className="ChooseLayoutCard__previewButton"
                        onClick={(e) => {
                            e.stopPropagation();
                            onPreviewClick(e);
                        }}
                    >
                        <PhosphorIcons.Eye size={20} /> Full Preview
                    </SolidButton>
                )}
            </NXCard.Content>

            <NXCard.Actions>
                <CheckListItemCircle size={20} checkSize={12} checked={selected} />
                <Text medium size--m black align--left>
                    {layoutName}
                </Text>
            </NXCard.Actions>
        </NXCard>
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

export default ChooseLayoutCard;

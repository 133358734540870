/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React from 'react';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import NXBox from 'components/NXBox';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import DynamicFormFieldRenderer from 'containers/katana/components/dynamicFormFieldRenderer';
import SectionStyleData from 'containers/katana/components/sectionStyleData';
import ContentEditorModuleContent from 'containers/katana/containers/ContentEditorLightbox/contentEditorModuleContent';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { useGetCaveat } from 'containers/katana/formFields/methods/useGetCaveat';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import { SectionDefinitionContext } from 'containers/katana/components/contentDefinitionForm/consts';
import { CaveatTypes } from 'containers/katana/formFields/caveatTypes';
import type { Katana } from 'containers/katana/types';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const SectionDefinitionRenderer: React.FC<Katana.SectionDefinitions.ValuesModifiedPropertiesAndValidations> = (props) => {
    const { name, description, styles, properties, caveats } = props;

    /***** RENDER HELPERS *****/
    // const toolTipCaveatText = useGetCaveat(CaveatTypes.TOOL_TIP, caveats);
    const toolTipCaveatText = useGetCaveat(CaveatTypes.SECTION_CONFIGURATION_INFORMATION_TITLE_DESCRIPTION_CAVEAT, caveats);

    /***** RENDER *****/
    return (
        <SectionDefinitionContext.Provider value={props}>
            <NXBox>
                {/* <NXCard.ToolTip>{toolTipCaveatText}</NXCard.ToolTip> */}
                <NXBox.Top title={toolTipCaveatText?.title ?? name} description={toolTipCaveatText?.description ?? description} />
                <NXBox.DefaultPadding>
                    <ContentEditorModuleContent>
                        <SectionStyleData styles={styles} />
                        <DynamicFormFieldRenderer properties={properties} />
                    </ContentEditorModuleContent>
                </NXBox.DefaultPadding>
            </NXBox>
        </SectionDefinitionContext.Provider>
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

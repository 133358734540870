/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import './_NXBoxSplit.scss';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type NXBoxSplit = React.FC<NXBoxSplitProps>;
type NXBoxSplitProps = {
    /**
     * Each section will be flex aligned horizontally and take up equal space in the container. Sections will also be divided by border
     */
    sections: Array<React.ReactNode>;
};

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * Provides flexbox and border styling to a list of its sections, best used with just 2 sections
 */
export const NXBoxSplit: NXBoxSplit = ({ sections }) => {
    /***** RENDER *****/
    return (
        <div className="NXBoxSplit">
            {sections.map((section, index) => (
                <div key={index} className="NXBoxSplit__section">
                    {section}
                </div>
            ))}
        </div>
    );
};

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { Store } from '@tanstack/react-store';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { createModuleRenderer } from 'components/ModuleRenderer';
import { createUpdateChangedStoreKey } from 'utilities/methods/tanstack/store/updatedChangedStoreKey';

/**********************************************************************************************************
 *   TYPE IMPORTS
 **********************************************************************************************************/
import type { CreateModuleStore, NCreateModuleStore } from 'utilities/methods/createModuleStore/types';
import { createUpdateEnabledWithReason } from 'utilities/methods/tanstack/store/updateEnabledWithReason';

/**********************************************************************************************************
 *   FUNCTION START
 **********************************************************************************************************/
const _createModuleStore: CreateModuleStore = (defaultStore, _useConditions) => {
    /***** STORE *****/
    const _Store = new Store(defaultStore);

    /***** FUNCTIONS *****/
    // Casting as any so that we can loosen the type for this function in the createModuleStore definition. This is necessary so that typescript
    // doesn't infer the type of "enabled" on the ModuleObject as the literal true/false instead of a boolean.
    const reset = () => _Store.setState(() => defaultStore);
    const updateChangedStoreKey: any = createUpdateChangedStoreKey(_Store);
    const updateEnabledWithReason: any = createUpdateEnabledWithReason(_Store);
    const ModuleRenderer = createModuleRenderer(_Store);

    const HostingModuleStore = Object.assign(_Store, {
        reset,
        updateChangedStoreKey,
        updateEnabledWithReason,
        ModuleRenderer
    });

    /***** RENDER *****/
    return Object.assign(HostingModuleStore, {
        useConditions: () => _useConditions?.(HostingModuleStore)
    });
};

export const createModuleStore = Object.assign(_createModuleStore, {
    /**
     * Helper type for casting useConditions props to the correct type. This is necessary to perform this operation
     * after accepting any for args because if the args is typed based on the createModuleStore type directly, the
     * type will become circlicly dependant.
     *
     * If module conditions are not defined inline, use this function to cast the hook props to the correct type.
     *
     * @example
     * ```ts
     * const ModuleStore = createModuleStore({ ... }, useModuleConditions);
     *
     * function useModuleConditions(args: any) {
     *   const store = createModuleStore.castHookProps<typeof ModuleStore>(args);
     *   //    ^? correctly typed based on ModuleStore
     * }
     * ```
     */
    castHookProps: <TStore>(store: TStore) => store as NCreateModuleStore.inferHookProps<TStore>
});

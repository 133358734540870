/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React from 'react';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import NXTable from 'components/NXTable';
import { Subset } from 'components/Subset';
import { WrapWithComponent } from 'components/WrapWithComponent';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import { MS365TableRenderActions, STATUS } from './actions';
import { MS365TableRenderSeats } from './seats';
import { MS365TableRenderStatusTag } from './status';
import { MS365TableRenderServiceName } from './title';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { useAppViewport } from 'utilities/hooks/useAppViewport/useAppViewport';
import { useMicrosoft365TableContext } from '../../own';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
/**
 * @typedef {import('../../types').NMicrosoft365Table.TMicrosoft365TableBody} TBody
 */

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * Body component for the Microsoft 365 table. The data passed to this component should have atleast one item
 * in the array.
 *
 * @type {TBody}
 */
export const Microsoft365TableBody = ({ data }) => {
    /***** HOOKS *****/
    const isMobile = useAppViewport(['xs', 'sm']);
    const { isExpressServiceManager, numberOfRows } = useMicrosoft365TableContext();

    /***** FUNCTIONS *****/
    /**
     * Check if the accordion will render content, logic is based on the MS365TableRenderActions component logic
     * @param {typeof data[number]} data
     */
    const doesAccordionHaveContent = (data) => {
        const { status } = data.attributes;

        return /** @type {Array<string>} */ (Object.values(STATUS)).includes(status);
    };

    /***** RENDER HELPERS *****/
    const classes = /** @type {const} */ ({
        wrapper: 'Microsoft365TableBody'
    });

    /***** RENDER *****/
    return (
        <div className={classes.wrapper}>
            <WrapWithComponent component={NXTable.Accordion.ToggleWrapper} wrap={isMobile}>
                <Subset range={isExpressServiceManager ? [0, 5] : [0, numberOfRows]} data={data}>
                    {(data, index) =>
                        isMobile ? (
                            <NXTable.Accordion
                                key={data.id}
                                index={index ?? 0}
                                content={doesAccordionHaveContent(data) && <MS365TableRenderActions data={data} />}
                            >
                                <MS365TableRenderServiceName data={data} />
                                <MS365TableRenderStatusTag data={data} />
                                <MS365TableRenderSeats data={data} />
                            </NXTable.Accordion>
                        ) : (
                            <NXTable.Row key={data.id}>
                                <MS365TableRenderServiceName data={data} />
                                <MS365TableRenderStatusTag data={data} />
                                <MS365TableRenderSeats data={data} />
                                <MS365TableRenderActions data={data} />
                            </NXTable.Row>
                        )
                    }
                </Subset>
            </WrapWithComponent>
        </div>
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

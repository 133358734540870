/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import classNames from 'classnames';
import React from 'react';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import SelectDropdown from 'components/Dropdowns/SelectDropdown';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { serviceNav } from 'config/config';
import { useAppViewport } from 'utilities/hooks/useAppViewport/useAppViewport';
import { useConditionalServiceNavKeys } from 'utilities/hooks/useConditionalServiceNavKeys';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
/**
 * @typedef {<T extends boolean>(isMobile: T) => (item: string) => T extends false ? React.ReactNode : { label: string; onClick: () => void }} TGetOptions
 */

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * @type {React.FC<{
 *   view: NXUtils.ValueOf<typeof serviceNav>['key']
 *   setView: (view: NXUtils.ValueOf<typeof serviceNav>['key']) => void
 * }>}
 */
export const ExpressServiceManagerTabs = ({ view, setView: _setView }) => {
    /***** HOOKS *****/
    const isMobile = useAppViewport(['xs', 'sm']);

    /***** FUNCTIONS *****/
    /**
     * @param {NXUtils.ValueOf<typeof serviceNav>['key']} view
     */
    const setView = (view) => {
        switch (view) {
            case serviceNav['cPanel Hosting'].key:
            case serviceNav['Email Hosting'].key:
            case serviceNav['Google Workspace'].key:
            case serviceNav['VPS'].key:
            case serviceNav['SSL Certificates'].key:
            case serviceNav['Microsoft 365'].key:
            case serviceNav['VIPsites'].key:
            case serviceNav['Domain Names'].key:
                return _setView(view);
            default:
                return _setView(serviceNav['Domain Names'].key);
        }
    };

    /***** RENDER HELPERS *****/
    const serviceNavConfigKeys = useConditionalServiceNavKeys();

    /**
     * @type {TGetOptions}
     */
    const getOptions = (isMobile) => {
        // @ts-ignore
        return function options(item) {
            const className = classNames('expressServiceManager__tabs--option', {
                active: view === serviceNav[item].key
            });

            if (isMobile) {
                return {
                    label: item,
                    onClick: () => setView(serviceNav[item].key)
                };
            }

            return (
                <button type="button" key={serviceNav[item].key} className={className} onClick={() => setView(serviceNav[item].key)}>
                    {item}
                </button>
            );
        };
    };

    /***** RENDER *****/
    return isMobile ? (
        <SelectDropdown
            noSelectionLabel="Domain Management"
            className="expressServiceManager__dropdown"
            options={serviceNavConfigKeys.map(getOptions(true))}
        />
    ) : (
        <div className="expressServiceManager__tabs">{serviceNavConfigKeys.map(getOptions(false))}</div>
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

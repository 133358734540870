/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import classNames from 'classnames';
import React from 'react';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import HoverTooltip from 'components/Tooltip/HoverTooltip';
import Text from 'components/Utils/Text';
import { WrapWithComponent } from 'components/WrapWithComponent';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { useAppViewport } from 'utilities/hooks/useAppViewport/useAppViewport';
import { formatLuxonOrdinal, getCurrentDate, toLuxonDate } from 'utilities/methods/commonActions';
import { getVPSInformationFromData } from './helpers';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
/**
 * @typedef {import('../../types').TVPSOverview.TVPSRenew} TVPSRenew
 */

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * @private - For use in the _TableBody component.
 * @type {TVPSRenew}
 */
export const OwnRenderRenew = ({ data }) => {
    /***** HOOKS *****/
    const isMobile = useAppViewport(['xs', 'sm']);

    /***** RENDER HELPERS *****/
    const { nextDueDate, billingCycle } = getVPSInformationFromData(data);
    const dateLuxon = toLuxonDate(nextDueDate);
    const renew = parseInt(String(getCurrentDate().diff(dateLuxon, 'days').toObject()['days']));
    const renewFormat = formatLuxonOrdinal(dateLuxon.toFormat('EEEE, MMM {d} yyyy'));
    const isNotice = renew > -90 && renew < -1;
    const isWarn = renew >= -1;

    const getText = () => {
        switch (true) {
            case billingCycle === 'One Time':
                return <div></div>;
            case renew > 1:
                return `Overdue by ${renew} days`;
            case renew === 1:
                return 'Overdue yesterday';
            case renew === -1:
                return 'In 1 day';
            case renew === 0:
                return 'Today';
            default:
                return `In ${Math.abs(renew)} days`;
        }
    };

    const classes = {
        renew: classNames('VPSTableBody__renew', {
            'VPSTableBody__renew--mobile': isMobile
        }),
        renewTitle: classNames('VPSTableBody__renewTitle', {
            'VPSTableBody__renewTitle--mobile': isMobile
        })
    };

    /***** RENDER *****/
    if (billingCycle === 'One Time') {
        return getText();
    }

    return (
        <>
            {isMobile && (
                <Text size--xss bold uppercase align--left={isMobile} className={classes.renewTitle}>
                    Renews
                </Text>
            )}
            <WrapWithComponent component={HoverTooltip} wrap={!isMobile} content={renewFormat} usePortal>
                <Text secondary size--xss warn={isWarn} notice={isNotice} bold={isWarn || isNotice} align--left={isMobile} className={classes.renew}>
                    {getText()}
                </Text>
            </WrapWithComponent>
        </>
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
